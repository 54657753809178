import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  CircularProgress,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Chip,
  ListItemText,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import ClearIcon from "@material-ui/icons/Clear";
import EventIcon from "@material-ui/icons/Event";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CancelIcon from "@material-ui/icons/Cancel";
import QRCode from "qrcode";
import { Alert } from "@material-ui/lab";
import { alertAdd, fetchOrganizationStart } from "../../redux/actions";
import { getAxiosInstance } from "../../redux/common";
import Loader from "../../components/loader";
import { EXAMINATIONS_MAP } from "./examinations";
import Divider from "@material-ui/core/Divider";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  root: {},
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.alternative,
    minWidth: "600px",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width: 600px)": {
      padding: "20px",
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: 200,
      height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    // "@media (max-width: 600px)": {
    //   height: "100vh",
    // },
    // height: "70vh",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const PackageModal = ({
  openPackageModal,
  handleClose,
  packageHandlerType,
  packageToEdit,
  setSavedPackage,
}) => {
  const classes = useStyles();

  const [label, setLabel] = useState(packageToEdit.label || "");
  const [hearBoxTestId, setHearBoxTestId] = useState(
    packageToEdit.hearBoxTestId || ""
  );
  const [description, setDescription] = useState(
    packageToEdit.description || ""
  );
  const [allowDoctorDescription, setAllowDoctorDescription] = useState(
    packageToEdit.allowDoctorDescription || false
  );

  const [savingPackage, setSavingPackage] = useState(false);

  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [checked, setChecked] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const [errorMessage, setErrorMessage] = useState("");
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(""), [5000]);
    }
  }, [errorMessage]);
  useEffect(() => {
    if (packageHandlerType === "add") {
      setValid(label && hearBoxTestId && description && right.length > 0);
    } else {
      setValid(label && description && right.length > 0);
    }
  }, [label, hearBoxTestId, description, right]);

  useEffect(() => {
    let examinationsAbbreviation = [];
    Object.keys(EXAMINATIONS_MAP).forEach(function eachKey(key) {
      examinationsAbbreviation.push(key);
    });
    if (packageToEdit && packageHandlerType === "edition") {
      setLeft(
        examinationsAbbreviation.filter(
          (eAbbr) => !packageToEdit?.examinations?.includes(eAbbr)
        )
      );
      setRight(packageToEdit.examinations || []);
    } else {
      setLeft(examinationsAbbreviation);
    }
  }, [packageToEdit]);

  const saveHandler = async () => {
    try {
      setSavedPackage(false);
      setSavingPackage(true);
      let response = null;
      if (packageHandlerType === "edition") {
        response = await getAxiosInstance().put(
          `/api/examination_package/${hearBoxTestId}`,
          {
            allowDoctorDescription,
            label,
            description,
            examinations: right,
          }
        );
      } else {
        response = await getAxiosInstance().post(`/api/examination_package`, {
          allowDoctorDescription,
          label,
          description,
          hearBoxTestId,
          examinations: right,
        });
      }

      if (response) {
        setSavingPackage(false);
        setSavedPackage(true);
        handleClose();
      }

      //   if (response) {
      //     setTimeout(() => {
      //       setSavingPackage(false);
      //       setSavedPackage(true);
      //       handleClose();
      //     }, [5000]);
      //   }
    } catch (e) {
      console.log(e);
      setSavingPackage(false);
      if (e.response.data.message === "hearbox-test-id-exists") {
        setErrorMessage(`Istnieje pakiet o podanym identyfikatorze`);
      } else {
        setErrorMessage(
          `Pakiet nie został ${
            packageHandlerType === "edition" ? "edytowany" : "dodany"
          }`
        );
      }
    }
  };

  const handleDescriptionStatusChange = (e) => {
    if (e.target.value === "true") {
      setAllowDoctorDescription(true);
    } else {
      setAllowDoctorDescription(false);
    }
  };

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleOnDragEnd = (
    { source: { index: oldIndex }, destination: { index: newIndex } },
    side,
    items
  ) => {
    if (!newIndex && newIndex !== 0) {
      return;
    } else if (side === "right") {
      setRight(arrayMove([...items], oldIndex, newIndex));
    }
  };

  const arrayMove = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const customList = (title, items, side) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            color="primary"
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`Wybrano: ${numberOfChecked(items)}/${items.length}`}
      />
      <Divider />
      <DragDropContext
        onDragEnd={(r) => r.destination && handleOnDragEnd(r, side, items)}
      >
        <Droppable droppableId={title}>
          {(provided) => (
            <List
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classes.list}
              style={{
                width: "100%",
                height: 330,
                overflow: "auto",
              }}
              dense
              component="div"
              role="list"
            >
              {items.map((value, index) => {
                const labelId = `transfer-list-all-item-${value}-label`;

                return (
                  <Draggable key={value} draggableId={value} index={index}>
                    {(provided) => (
                      <ListItem
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        role="listitem"
                        button
                        onClick={handleToggle(value)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            color="primary"
                            checked={checked.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${EXAMINATIONS_MAP[value]}`}
                        />
                      </ListItem>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
              <ListItem />
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Card>
  );

  const title = (
    <Box mt={1} style={{ display: "flex" }}>
      <Typography variant="h6" gutterBottom>
        {packageHandlerType === "add"
          ? "Dodaj pakiet"
          : `Edycja pakietu: ${packageToEdit.label}`}
      </Typography>
    </Box>
  );
  const body = (
    <Box>
      {errorMessage && <Alert severity={"error"}>{errorMessage}</Alert>}
      <Box mt={1}>
        <TextField
          label="Nazwa pakietu"
          required
          variant="outlined"
          value={label}
          fullWidth
          onChange={({ target: { value: label } }) => {
            setLabel(label);
          }}
          inputProps={{
            "aria-label": "Nazwa przesiewu",
            maxLength: 255,
          }}
        />
      </Box>
      {packageHandlerType === "add" && (
        <Box mt={1}>
          <TextField
            label="Identyfikator"
            variant="outlined"
            required
            value={hearBoxTestId}
            fullWidth
            onChange={({ target: { value: hearBoxTestId } }) => {
              if (/^([a-z-_0-9]+)$/.test(hearBoxTestId)) {
                setHearBoxTestId(hearBoxTestId);
              } else if (!hearBoxTestId) {
                setHearBoxTestId("");
              }
            }}
            inputProps={{
              "aria-label": "Identyfikator",
              maxLength: 255,
            }}
          />
        </Box>
      )}
      <Box mt={1}>
        <TextField
          label="Opis"
          variant="outlined"
          required
          value={description}
          fullWidth
          onChange={({ target: { value: description } }) => {
            setDescription(description);
          }}
          inputProps={{
            "aria-label": "Opis",
            maxLength: 255,
          }}
        />
      </Box>
      <Box mt={1}>
        <FormControl
          component="status"
          required
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <FormLabel id="radio-buttons-group-label">
            Możliwość opisu badań:
          </FormLabel>
          <RadioGroup
            aria-label="status"
            name="status"
            value={allowDoctorDescription}
            onChange={handleDescriptionStatusChange}
            row
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label="Tak"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label="Nie"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box mt={1}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item style={{ flex: "1" }}>
            {customList("Przypisz badanie do pakietu", left, "left")}
          </Grid>
          <Grid item style={{ flex: "1" }}>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item style={{ flex: "1" }}>
            {customList("Wybrane badania w pakiecie *", right, "right")}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  const foot = (
    <Grid>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant={"contained"}
          disabled={savingPackage}
          onClick={() => handleClose()}
        >
          Anuluj
        </Button>
        &nbsp;&nbsp;&nbsp;{" "}
        <Button
          variant={"contained"}
          color="primary"
          disabled={savingPackage || !valid}
          onClick={saveHandler}
        >
          Zapisz
        </Button>
      </Box>
    </Grid>
  );

  return (
    <Modal open={openPackageModal} className={classes.modalStyle}>
      <Grid container>
        <Grid item xs={12} className={classes.grid}>
          <Paper className={classes.paper}>
            {" "}
            {title} <hr />
            {!savingPackage ? (
              body
            ) : (
              <Loader
                loading={true}
                text="Zapis pakietu - proszę czekać"
              ></Loader>
            )}
            <hr
              style={{
                margin: "24px 0px",
              }}
            />
            {foot}
          </Paper>
        </Grid>
      </Grid>
      {/* 
       
      </Box> */}
    </Modal>
  );
};
export default PackageModal;
