import React, { useRef, useContext } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart, Line, Scatter } from "react-chartjs-2";
import Log2Axis from "./log-2-axis";
import { Box, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { lightTheme } from "../../theme";
import PDFContext from "./pdf-context";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,

  Log2Axis,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const countPowersOf2 = (min, max) => {
  var count = 0;
  let power = Math.floor(Math.log2(min || 1));
  let maxPower = Math.ceil(Math.log2(max || 2));
  while (power <= maxPower) {
    count++;
    power += 1;
  }
  return count;
};

const checkIcon = new Image();
const checkIconYellow = new Image();
const checkIconRed = new Image();
const checkIconBlue = new Image();
checkIcon.src = require("../../assets/checked-icon.svg");
checkIconYellow.src = require("../../assets/checked-icon-yellow.svg");
checkIconRed.src = require("../../assets/checked-icon-red.svg");
checkIconBlue.src = require("../../assets/checked-icon-blue.svg");

const Audiogram = ({ audiometry }) => {
  const isLightTheme =
    useTheme().palette.primary.main === lightTheme.palette.primary.main;
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isPDF = useContext(PDFContext);
  const minIntensity = audiometry.minIntensity ? audiometry.minIntensity : 0;
  const maxIntensity = audiometry.maxIntensity ? audiometry.maxIntensity : 80;

  const frequencies = [
    ...new Set([
      ...audiometry.frequenciesResultRight.map(({ frequency }) => frequency),
      ...audiometry.frequenciesResultLeft.map(({ frequency }) => frequency),
    ]),
  ]
    .sort((a, b) => a - b)
    .map((freq) => freq / 1000);

  if (audiometry.frequenciesAndIntensities === undefined) {
    return null;
  }

  const numberOfPowersOf2 = countPowersOf2(
    frequencies[0],
    frequencies[frequencies.length - 1]
  );

  const widthMultiplier = smallScreen && !isPDF ? 50 : 75;

  const getTestTone = (freq) => {
    return audiometry.frequenciesAndIntensities[freq] === undefined
      ? 20
      : audiometry.frequenciesAndIntensities[freq];
  };

  const options = (ear) => ({
    maintainAspectRatio: false,

    plugins: {
      legend: {
        position: "bottom",
        align: "left",
        font: { size: 10 },
        labels: {
          generateLabels: (chart) => {
            const points = [
              isLightTheme ? checkIcon : checkIconYellow,
              "crossRot",
            ];
            return chart.data.datasets.map((dataset, index) => ({
              text: dataset.label,
              pointStyle: points[index],
              fillStyle: dataset.backgroundColor,
              strokeStyle: isLightTheme ? dataset.borderColor : "#ffff00",
              fontColor: isLightTheme ? ChartJS.defaults.color : "#ffff00",

              lineWidth: 2,
            }));
          },
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text: ear === "RIGHT" ? "UCHO PRAWE" : "UCHO LEWE",
        font: { family: "Raleway", weight: "normal", size: 15 },
        color: isLightTheme ? "#3458A4" : "#ffff00",
      },
    },
    spanGaps: true,
    scales: {
      x: {
        type: "log2",
        grid: {
          color: isLightTheme ? ChartJS.defaults.borderColor : "#ffff00",
        },
        title: {
          color: isLightTheme ? ChartJS.defaults.color : "#ffff00",
          display: true,
          text: "częstotliwość [kHz]",
        },
        ticks: {
          color: isLightTheme ? ChartJS.defaults.color : "#ffff00",
        },
      },
      y: {
        grid: {
          color: isLightTheme ? ChartJS.defaults.borderColor : "#ffff00",
        },
        title: {
          display: true,
          text: "próg słyszenia [dB HL]",
          color: isLightTheme ? ChartJS.defaults.color : "#ffff00",
        },
        min: minIntensity,
        max: maxIntensity,
        reverse: true,
        ticks: {
          color: isLightTheme ? ChartJS.defaults.color : "#ffff00",
          stepSize: 10,
        },
      },
    },
    clip: false,
  });

  const dataLeft = {
    labels: frequencies,
    datasets: [
      {
        label: "Prawidłowe",
        data: frequencies?.map((label) => {
          const threshold = audiometry?.frequenciesResultLeft.find(
            ({ frequency }) => frequency === label * 1000
          )?.answer;
          return threshold !== undefined && threshold !== "" ? threshold : null;
        }),
        borderColor: isLightTheme ? "rgb(0, 245, 0)" : "#ffff00",
        backgroundColor: "rgba(0, 0, 0, 0)",
        pointStyle: isLightTheme ? checkIcon : checkIconYellow,
        borderWidth: 2,
        radius: 10,
      },
      {
        label: "Nieprawidłowe",
        data: frequencies?.map((label) => {
          const threshold = audiometry?.frequenciesResultLeft.find(
            ({ frequency }) => frequency === label * 1000
          )?.answer;
          if (
            (label === 1 || label === 8) &&
            !audiometry?.earlyFinishedEnum &&
            threshold === "none" &&
            audiometry?.frequenciesResultLeft
              .filter(
                ({ frequency }) => frequency !== 1000 && frequency !== 8000
              )
              .every(({ answer }) => answer === "")
          ) {
            return getTestTone(label * 1000) + 20;
          }
          return threshold === "none" ? getTestTone(label * 1000) : null;
        }),
        borderColor: isLightTheme ? "#CF1616" : "#ffff00",
        backgroundColor: "rgba(0, 0, 0, 0)",
        pointStyle: "crossRot",
        borderWidth: 2,
        radius: 10,
      },
    ],
  };

  const dataRight = {
    labels: frequencies,
    datasets: [
      {
        label: "Prawidłowe",
        data: frequencies?.map((label) => {
          const threshold = audiometry?.frequenciesResultRight.find(
            ({ frequency }) => frequency === label * 1000
          )?.answer;
          return threshold !== undefined && threshold !== "" ? threshold : null;
        }),
        borderColor: isLightTheme ? "rgb(0, 245, 0)" : "#ffff00",
        backgroundColor: "rgba(0, 0, 0, 0)",
        pointStyle: isLightTheme ? checkIcon : checkIconYellow,
        borderWidth: 2,
        radius: 10,
      },
      {
        label: "Nieprawidłowe",
        data: frequencies?.map((label) => {
          const threshold = audiometry?.frequenciesResultRight.find(
            ({ frequency }) => frequency === label * 1000
          )?.answer;
          if (
            (label === 1 || label === 8) &&
            !audiometry?.earlyFinishedEnum &&
            threshold === "none" &&
            audiometry?.frequenciesResultRight
              .filter(
                ({ frequency }) => frequency !== 1000 && frequency !== 8000
              )
              .every(({ answer }) => answer === "")
          ) {
            return getTestTone(label * 1000) + 20;
          }
          return threshold === "none" ? getTestTone(label * 1000) : null;
        }),
        borderColor: isLightTheme ? "#CF1616" : "#ffff00",
        backgroundColor: "rgba(0, 0, 0, 0)",
        pointStyle: "crossRot",
        borderWidth: 2,
        radius: 10,
      },
    ],
  };

  if (audiometry) {
    return (
      <Grid container justify="space-evenly" style={{ overflowX: "auto" }}>
        <Grid item>
          <Box
            style={{
              width: `${
                numberOfPowersOf2 * widthMultiplier > 200
                  ? numberOfPowersOf2 * widthMultiplier
                  : 200
              }px`,
              height: `${
                ((maxIntensity - minIntensity) / 20) * widthMultiplier + 150
              }px`,
              display: "inline-block",
            }}
          >
            <Scatter options={options("RIGHT")} data={dataRight} />
          </Box>
        </Grid>
        <Grid item>
          <Box
            style={{
              width: `${
                numberOfPowersOf2 * widthMultiplier > 200
                  ? numberOfPowersOf2 * widthMultiplier
                  : 200
              }px`,
              height: `${
                ((maxIntensity - minIntensity) / 20) * widthMultiplier + 150
              }px`,
              display: "inline-block",
            }}
          >
            <Scatter options={options("LEFT")} data={dataLeft} />
          </Box>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

const AudiogramThreshold = ({ audiometry, basicAudiometry }) => {
  const isLightTheme =
    useTheme().palette.primary.main === lightTheme.palette.primary.main;
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isPDF = useContext(PDFContext);
  const minIntensity = audiometry.minIntensity ? audiometry.minIntensity : 0;
  const maxIntensity = audiometry.maxIntensity ? audiometry.maxIntensity : 80;
  const chartReference = useRef();

  if (audiometry.testFrequencies === undefined) {
    return null;
  }

  const frequencies = audiometry.testFrequencies
    .sort((a, b) => a - b)
    .map((freq) => freq / 1000);

  const numberOfPowersOf2 = countPowersOf2(
    frequencies[0],
    frequencies[frequencies.length - 1]
  );

  const widthMultiplier = smallScreen && !isPDF ? 50 : 100;

  const thresholdExceededIcon = new Image(30, 30);
  if (isLightTheme) {
    thresholdExceededIcon.src = require("../../assets/threshold-exceeded.svg");
  } else {
    thresholdExceededIcon.src = require("../../assets/threshold-exceeded-yellow.svg");
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        align: "left",
        font: { size: 10 },
        labels: {
          generateLabels: (chart) => {
            const points = [
              "circle",
              "crossRot",
              isLightTheme ? checkIconRed : checkIconYellow,
              isLightTheme ? checkIconBlue : checkIconYellow,
            ];
            return chart.data.datasets.map((dataset, index) => ({
              text: dataset.label,
              pointStyle: points[index],
              fillStyle: dataset.backgroundColor,
              strokeStyle: isLightTheme ? dataset.borderColor : "#ffff00",
              fontColor: isLightTheme ? ChartJS.defaults.color : "#ffff00",

              lineWidth: 2,
            }));
          },
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text: "WYNIK PRZESIEWOWEGO BADANIA SŁUCHU",
        font: { family: "Raleway", weight: "normal", size: 15 },
        color: isLightTheme ? "#3458A4" : "#ffff00",
      },
    },
    scales: {
      x: {
        type: "log2",
        title: {
          display: true,
          text: "częstotliwość [kHz]",
          color: isLightTheme ? ChartJS.defaults.color : "#ffff00",
        },
        grid: {
          color: isLightTheme ? ChartJS.defaults.borderColor : "#ffff00",
        },
        ticks: {
          color: isLightTheme ? ChartJS.defaults.color : "#ffff00",
        },
      },
      y: {
        title: {
          display: true,
          text: "próg słyszenia [dB HL]",
          color: isLightTheme ? ChartJS.defaults.color : "#ffff00",
        },
        min: minIntensity,
        max: maxIntensity,
        reverse: true,
        ticks: {
          stepSize: 10,
          color: isLightTheme ? ChartJS.defaults.color : "#ffff00",
        },
        grid: {
          color: isLightTheme ? ChartJS.defaults.borderColor : "#ffff00",
        },
      },
    },
    clip: false,
  };
  const dataRight = frequencies?.map((label) => {
    const threshold = audiometry?.frequenciesResultRight.find(
      ({ frequency }) => frequency === label * 1000
    )?.answer;
    if (threshold === "maxExceeded") {
      return { th: maxIntensity, maxExceeded: true };
    }
    return threshold !== undefined && threshold !== ""
      ? { th: threshold, maxExceeded: false }
      : { th: null, maxExceeded: false };
  });
  const dataLeft = frequencies?.map((label) => {
    const threshold = audiometry?.frequenciesResultLeft.find(
      ({ frequency }) => frequency === label * 1000
    )?.answer;
    if (threshold === "maxExceeded") {
      return { th: maxIntensity, maxExceeded: true };
    }
    return threshold !== undefined && threshold !== ""
      ? { th: threshold, maxExceeded: false }
      : { th: null, maxExceeded: false };
  });

  const dataRightFromBasicAud = frequencies?.map((label) => {
    const threshold = audiometry?.frequenciesResultRight.find(
      ({ frequency }) => frequency === label * 1000
    )?.answer;
    if (threshold === undefined) {
      if (basicAudiometry) {
        const thresholdBasicAud = basicAudiometry.frequenciesResultRight.find(
          ({ frequency }) => frequency === label * 1000
        )?.answer;
        if (thresholdBasicAud !== undefined) {
          return { th: thresholdBasicAud };
        }
      }
    }
    return { th: null, maxExceeded: false };
  });

  const dataLeftFromBasicAud = frequencies?.map((label) => {
    const threshold = audiometry?.frequenciesResultLeft.find(
      ({ frequency }) => frequency === label * 1000
    )?.answer;
    if (threshold === undefined) {
      if (basicAudiometry) {
        const thresholdBasicAud = basicAudiometry.frequenciesResultLeft.find(
          ({ frequency }) => frequency === label * 1000
        )?.answer;
        if (thresholdBasicAud !== undefined) {
          return { th: thresholdBasicAud };
        }
      }
    }
    return { th: null, maxExceeded: false };
  });

  const data = {
    labels: frequencies,
    datasets: [
      {
        label: "Ucho PRAWE",
        data: dataRight.map(({ th }) => th),
        borderColor: isLightTheme ? "rgb(255, 0, 0)" : "#ffff00",
        backgroundColor: "rgba(0, 0, 0, 0)",
        pointStyle: dataRight.map(({ maxExceeded }) =>
          maxExceeded ? thresholdExceededIcon : "circle"
        ),
        borderWidth: 2,
        radius: 10,
      },
      {
        label: "Ucho LEWE",
        data: dataLeft.map(({ th }) => th),
        borderColor: isLightTheme ? "rgb(0, 0, 245)" : "#ffff00",
        backgroundColor: "rgba(0, 0, 0, 0)",
        pointStyle: dataLeft.map(({ maxExceeded }) =>
          maxExceeded ? thresholdExceededIcon : "crossRot"
        ),
        borderWidth: 2,
        radius: 10,
      },
      {
        label: "Ucho PRAWE wynik audiometrii przesiewowej",
        data: dataRightFromBasicAud.map(({ th }) => th),
        borderColor: isLightTheme ? "rgb(255, 0, 0)" : "#ffff00",
        backgroundColor: "rgba(0, 0, 0, 0)",
        pointStyle: isLightTheme ? checkIconRed : checkIconYellow,
        borderWidth: 2,
        radius: 10,
        showLine: false,
      },
      {
        label: "Ucho LEWE wynik audiometrii przesiewowej",
        data: dataLeftFromBasicAud.map(({ th }) => th),
        borderColor: isLightTheme ? "rgb(0, 0, 245)" : "#ffff00",
        backgroundColor: "rgba(0, 0, 0, 0)",
        pointStyle: isLightTheme ? checkIconBlue : checkIconYellow,
        borderWidth: 2,
        radius: 10,
        showLine: false,
      },
    ],
  };
  if (audiometry) {
    return (
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        style={{ overflowX: "auto" }}
      >
        <Grid item>
          <Box
            style={{
              width: `${
                numberOfPowersOf2 * widthMultiplier < 200
                  ? 200
                  : numberOfPowersOf2 * widthMultiplier
              }px`,
              height: `${
                ((maxIntensity - minIntensity) / 20) * widthMultiplier + 150
              }px`,
              display: "inline-block",
            }}
          >
            <Line options={options} data={data} ref={chartReference} />
          </Box>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

export { Audiogram, AudiogramThreshold };
