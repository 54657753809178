import React, { useReducer } from 'react';
import { INITIAL_PAGE, PAGE_CHANGE, ROWS_PER_PAGE_CHANGE } from './constants';

const initialState = {
  page: 1,
  rowsPerPage: 10,
};

const reducer = (state, action) => {
  switch (action.type) {
    case PAGE_CHANGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    case ROWS_PER_PAGE_CHANGE: {
      return {
        ...state,
        page: 1,
        rowsPerPage: action.payload,
      };
    }
    case INITIAL_PAGE: {
      return {
        ...state,
        page: 1,
      };
    }
    default:
      return state;
  }
};

export const usePagination = () => {
  const [paginationState, dispatch] = useReducer(reducer, initialState);

  return {
    paginationState,
    dispatch,
  };
};
