import React from "react";
import { Box, Grid, makeStyles, Switch, withStyles } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  statusContainer: {
    textAlign: "center",
    maxWidth: "50px",
  },
}));

export const colorMapper = {
  TRUE: "green",
  OK: "green",
  NOK: "red",
  FALSE: "red",
  NOT_KNOWN: "red",
  NOT_CONFIGURED: "red",
};

const PropertyStatus = ({ text, value, type = "icon", render }) => {
  const classes = useStyles();

  return (
    <Box mt={1} mb={1} className={classes.flexContainer}>
      <Grid item xs={5} md={4} lg={2}>
        <strong>{text}</strong>
      </Grid>{" "}
      <Grid item xs={1} md={2} lg={1}>
        <Box className={classes.statusContainer}>
          <FiberManualRecordIcon
            fontSize="large"
            style={{ fill: colorMapper[value] }}
          />
        </Box>
      </Grid>
      <Grid item xs={6} md={4} lg={3} style={{ marginLeft: "8px" }}>
        {render && render()}
      </Grid>
    </Box>
  );
};

export default PropertyStatus;
