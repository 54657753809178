import React, { useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import QRCode from "qrcode";
import ReactToPrint from "react-to-print";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFilledFormsExtended,
  fetchFormByScreeningId,
  fetchForm,
  fetchFormTemplate,
  fetchFormData,
  fetchAsDocFormByScreeningId,
  fetchAsDocFilledFormsExtended,
  fetchFilledFormsInfo,
} from "../../redux/actions";
import { UserFilledFormData } from "../filled-form-data/user-filled-form-data";
import { ReactComponent as ArrowDownIcon } from "../../assets/arrowDownIcon.svg";
import { ReactComponent as ArrowDownIconYellow } from "../../assets/arrowDownIcon-yellow.svg";
import Loader from "../loader";
import FilledFormDetails from "../../pages/filled-form-details";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import { ReactComponent as InfoIconYellow } from "../../assets/info-yellow.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialogActions-root": {
      justifyContent: "center",
    },
    "& .MuiDialog-paper": {
      borderRadius: "0px",
      padding: "16px 8px",
    },
  },
  paddingMedium: {
    padding: "16px 32px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  select: {
    "& .MuiInputBase-root": {
      background: "#000",
      color: "#fff",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.text.checked,
    },
    "&.MuiPaper-root": {
      borderRadius: "0px",
      background: theme.palette.background.default,
    },
  },
  selectText: {
    letterSpacing: "1px",
    lineHeight: `${theme.typography.formLabel}px`,
    fontSize: theme.typography.footer,
    color: theme.palette.text.hintText,
    borderBottom: `1px solid ${theme.palette.background.menuItemBorderBottom}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  title: {
    color: theme.palette.text.title,
    fontSize: theme.typography.fontSize,
    textTransform: "uppercase",
    fontWeight: "600",
    letterSpacing: "1.5px",
    textAlign: "center",
  },
  idText: {
    fontSize: theme.typography.text2,
    color: theme.palette.text.id,
    fontWeight: "700",
    textAlign: "center",
    letterSpacing: "1.5px",
  },
  idPin: {
    fontSize: theme.typography.text2,
    color: theme.palette.text.pin,
    fontWeight: "700",
    textAlign: "center",
    letterSpacing: "1.5px",
  },
  text: {
    color: theme.palette.text.buttonTitle,
    fontSize: theme.typography.hintText,
    letterSpacing: "1px",
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: "700",
  },
  locationButton: {
    backgroundColor: theme.palette.background.saveButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `115px`,
    height: "40px",
    marginRight: "15px",
  },
  testButton: {
    backgroundColor: theme.palette.testIcon.primary,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `115px`,
    height: "40px",
    marginRight: "5px",
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "1px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
}));

const SurveyResultsModal = ({
  handleClose,
  open,
  params: { screeningId, id },
  isDoctor = false,
  patient = {},
  hearBoxTestId = "",
  hearBoxScreeningId,
  patientScreeningId,
  screeningType,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const filledFormsExtended = useSelector((s) => s.filledFormsExtended);

  const me = useSelector((s) => s.my);

  const [filledForms, setFilledForms] = useState([]);
  const [rowFormInfo, setRowFormInfo] = useState({});

  const [form, setForm] = useState({});
  const [formData, setFormData] = useState([]);
  const [formTemplate, setFormTemplate] = useState({});

  const [formsToFillInScreening, setFormsToFillInScreening] = useState(false);

  const [fetchingFilledForms, setFetchingFilledForms] = useState(true);
  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";

  useEffect(() => {
    (async () => {
      if (open) {
        setFetchingFilledForms(true);
        let forms = null;
        if (isDoctor) {
          forms = await dispatch(
            fetchAsDocFormByScreeningId(
              screeningId,
              rowFormInfo.mandatory ? null : hearBoxTestId,
              patient.id,
              patientScreeningId
            )
          );
        } else {
          if (id) {
            forms = await dispatch(
              fetchFormByScreeningId(
                screeningId,
                id,
                hearBoxTestId,
                true,
                patientScreeningId
              )
            );
          } else {
            forms = await dispatch(
              fetchFormByScreeningId(
                screeningId,
                null,
                hearBoxTestId,
                true,
                patientScreeningId
              )
            );
          }
        }

        if (isDoctor) {
          setFilledForms(
            forms.map((f) => ({
              formName: f.name,
              formId: f.id,
              mandatory: f.mandatory,
            }))
          );
        } else {
          const formData = await dispatch(
            fetchFilledFormsInfo([
              {
                patientId: id ? id : me.id,
                patientScreeningId,
                screeningId,
                requiredExaminationPackages: [hearBoxTestId],
              },
            ])
          );

          const formsToFill = formData.length
            ? formData[0].examinationPackageFormsToFills[0].formsToFill
            : [];

          setFilledForms(
            forms.filter(({ formId }) => !formsToFill.includes(formId))
          );
        }

        setFormsToFillInScreening(forms.length > 0);
        setFetchingFilledForms(false);
      } else {
        setForm({});
        setFormData([]);
        setFormTemplate({});
      }
    })();
  }, [open]);

  useEffect(() => {
    (async () => {
      setFetchingFilledForms(true);
      if (rowFormInfo.formId) {
        if (!isDoctor) {
          const form = await dispatch(fetchForm(rowFormInfo.formId));
          let formData = null;
          if (id) {
            formData = await dispatch(
              fetchFilledFormsExtended(
                rowFormInfo.formId,
                screeningId,
                rowFormInfo.mandatory ? null : hearBoxTestId,
                hearBoxScreeningId,
                rowFormInfo.mandatory ? null : patientScreeningId,
                id
              )
            );
          } else {
            formData = await dispatch(
              fetchFilledFormsExtended(
                rowFormInfo.formId,
                screeningId,
                rowFormInfo.mandatory ? null : hearBoxTestId,
                hearBoxScreeningId,
                rowFormInfo.mandatory ? null : patientScreeningId
              )
            );
          }

          const template = await dispatch(fetchFormTemplate(form.layoutId));
          setForm(form);
          setFormData(formData.data.length > 0 ? [formData.data[0]] : []);
          setFormTemplate(template);
          setFetchingFilledForms(false);
        } else {
          const form = await dispatch(fetchForm(rowFormInfo.formId));

          let formData = null;
          if (id) {
            formData = await dispatch(
              fetchAsDocFilledFormsExtended(
                rowFormInfo.formId,
                screeningId,
                rowFormInfo.mandatory ? null : hearBoxTestId,
                hearBoxScreeningId,
                patient.id,
                patientScreeningId,
                screeningType
              )
            );
          } else {
            formData = await dispatch(
              fetchAsDocFilledFormsExtended(
                rowFormInfo.formId,
                screeningId,
                rowFormInfo.mandatory ? null : hearBoxTestId,
                hearBoxScreeningId,
                patient.id,
                patientScreeningId,
                screeningType
              )
            );
          }

          const template = await dispatch(fetchFormTemplate(form.layoutId));
          setForm(form);
          setFormData(formData.data.length > 0 ? [formData.data[0]] : []);
          setFormTemplate(template);
          setFetchingFilledForms(false);
        }
      }
    })();
  }, [rowFormInfo]);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const componentRef = useRef();

  const handleSave = () => {};

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth={smallScreen ? "xl" : "lg"}
      className={classes.root}
    >
      <DialogTitle id="qr code">
        <Typography className={classes.title}>Wypełnione ankiety</Typography>
      </DialogTitle>
      <DialogContent id="qr code image">
        {fetchingFilledForms ? (
          <Box p={2}>
            <Loader
              loading={true}
              text="Pobieranie danych - proszę czekać"
            ></Loader>
          </Box>
        ) : (
          <Box>
            <Box className={`${classes.paddingMedium}`}>
              {filledForms.length !== 0 ? (
                <Grid container style={{ width: "100%" }}>
                  <Grid item xs={12} md={10} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel>Wybierz ankietę</InputLabel>
                      <Select
                        // className={classes.select}
                        labelId="select-outlined-label"
                        id="select-outlined"
                        value={rowFormInfo}
                        className={classes.select}
                        classes={{
                          root: classes.color,
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        // fullWidth
                        onChange={(event) => {
                          setRowFormInfo(event.target.value);
                        }}
                        IconComponent={(props) => (
                          <i
                            {...props}
                            className={`material-icons ${props.className}`}
                            style={{ top: "calc(50% - 20px)" }}
                          >
                            {isLightGlobalTheme ? (
                              <ArrowDownIcon />
                            ) : (
                              <ArrowDownIconYellow />
                            )}
                          </i>
                        )}
                        label="Osoba badana"
                      >
                        {filledForms &&
                          filledForms.map((f) => {
                            return (
                              <MenuItem
                                key={f.formId}
                                value={f}
                                className={classes.selectText}
                                style={{ whiteSpace: "normal" }}
                              >
                                {`${f.formName}`}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>{" "}
                  </Grid>{" "}
                </Grid>
              ) : (
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    display="flex"
                    justifyContent="space-evenly"
                    alignItems="center"
                    className={classes.widthBox}
                  >
                    <Typography className={classes.loginTitle}>
                      {formsToFillInScreening
                        ? "Brak wypełnień"
                        : "Brak ankiet w pakiecie"}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            {formData.length > 0 && !fetchingFilledForms ? (
              <FilledFormDetails
                formData={formData}
                form={form}
                formTemplate={formTemplate}
              />
            ) : Object.hasOwn(form, "id") ? (
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  className={classes.widthBox}
                >
                  <Typography className={classes.loginTitle}>
                    Ankieta nie została wypełniona
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.locationButton}
          variant={"contained"}
          onClick={handleClose}
          autoFocus
        >
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SurveyResultsModal;
