import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import "rc-time-picker/assets/index.css";
import TimeField from "react-simple-timefield";
import * as classnames from "classnames";
import s from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useSelector } from "react-redux";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { KeyboardTimePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  fontSizeHintText: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body2.hintText,
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.formLabel,
  },
}));

// dragaMode = either 'clone' or 'move'

const TimeControl = ({
  name,
  id,
  disabled,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  showSelfDescription,
  sidebarMode,
  formCreatorMode,
  removable,
  onRemove,
  rowIndex,
  index,
  setIsDragging,
  filledValue,
  filledFormMode,
  blankedRequiredFields,
  checkBlankedFields,
  displayMode,
}) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.TIME,
      dragMode,
      defaultLabel: "Czas",
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });
  const classes = useStyles();
  const globalTheme = useSelector((s) => s.globalTheme);
  const isJustUser = useSelector((s) => (s) =>
    s?.my?.user?.authorities === "ROLE_USER" || false
  );
  const [value, setValue] = useState("");

  const theme = useTheme();
  useEffect(() => {
    if (filledValue) {
      setValue(filledValue);
    }
  }, [filledValue]);

  const clicked = () =>
    markSelected &&
    markSelected({
      id,
      dataType: ItemSubtypes.TIME,
      configuration,
    });

  const onTimeChange = (event) => {
    setValue(event.target.value);
    if (onChange) {
      onChange({
        id,
        value: event.target.value,
      });
    }
  };

  const updateValue = (value) => {
    setValue(value);

    const s = moment(value).format("HH:mm");

    if (onChange) {
      onChange({
        id,
        value: s,
      });
    }
  };

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Czas"
        />
      </div>
    );
  }

  const placeholder = showSelfDescription
    ? "Czas"
    : (configuration && configuration.text) || "";
  const label = (configuration && configuration.label) || "";

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          {!displayMode && (
            <FontAwesomeIcon
              className={classnames(s.trashIcon, "has-text-grey")}
              onClick={onRemove}
              icon="trash"
              title="Usuń"
            />
          )}
        </div>
      </div>
    );
  }
  if (filledFormMode) {
    return (
      <div>
        {label && <label className={`${classes.label}`}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}

        {!isJustUser && !filledValue ? (
          <div
            className={`${s.hintText} ${classes.fontSize}`}
            style={{ color: globalTheme === "high-contrast" && "yellow" }}
          >
            Pole niewypełnione przez{" "}
            {process.env.REACT_APP_USE_PATIENT === "true"
              ? "pacjenta"
              : "użytkownika"}
          </div>
        ) : (
          // <TextField
          //   // style={{ color: disabled ? "black" : "" }}
          //   fullWidth
          //   variant={"outlined"}
          //   size="small"
          //   disabled={disabled}
          //   type="text"
          //   name={name}
          //   id={id}
          //   value={value}
          // />
          <TextField
            id="time"
            // label="Alarm clock"
            type="time"
            // defaultValue="07:30"
            fullWidth
            disabled={disabled}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            value={value || ""}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={(e) => setValue(e.target.value)}
          />
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {label && (
        <label
          className={`${classes.label}`}
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
        >
          {label}
          {configuration.required && " *"}
        </label>
      )}
      {!label && (
        <label
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
          dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
          className={classnames("label")}
        ></label>
      )}

      <>
        {/* <TimeField
          style={{
            // border: "2px solid #666",
            width: 100,
            padding: "5px 8px",
            // color: "#333",
            borderRadius: 3,
            color: disabled
              ? theme.palette.background.default
              : globalTheme === "high-contrast"
              ? theme.palette.text.primary
              : "",
            border:
              globalTheme === "high-contrast" &&
              `1px solid ${theme.palette.text.primary}`,
            backgroundColor:
              globalTheme === "high-contrast" &&
              theme.palette.background.default,
          }}
          onChange={onTimeChange}
          placeholder={placeholder}
          disabled={disabled}
          className={`${
            checkBlankedFields &&
            blankedRequiredFields.find((fieldId) => fieldId === id)
              ? "input is-danger"
              : "input"
          } ${classes.fontSize}`}
          name={name}
          id={id}
          onClick={clicked}
          value={value || ""}
        /> */}
        <TextField
          id="time"
          // label="Alarm clock"
          type="time"
          // defaultValue="07:30"
          fullWidth
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          value={value || ""}
          inputProps={{
            step: 300, // 5 min
          }}
          onChange={onTimeChange}
        />
        {checkBlankedFields &&
          blankedRequiredFields.find((fieldId) => fieldId === id) && (
            <p
              class={globalTheme !== "high-contrast" && "help is-danger"}
              style={{
                color:
                  globalTheme === "high-contrast" &&
                  `${theme.palette.text.error}`,
              }}
              className={`${classes.fontSize} ${
                globalTheme !== "high-contrast" && "help is-danger"
              }`}
            >
              To pole musi zostać wypełnione
            </p>
          )}
      </>
    </div>
  );
};

export default TimeControl;
