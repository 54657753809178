import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import Answers from "./answers";
import { CombinedResult } from "./results-per-package-table";
import { useStyles } from "./results-per-package-table";
import DateAndResultGrid from "./DateAndResultGrid";

const OneRightAnswerResults = ({ test }) => {
  const classes = useStyles();
  return (
    <Box>
      <DateAndResultGrid startDate={test.startDate} endDate={test.endDate}>
        <CombinedResult
          text="Wynik ogólny:"
          percent={test.data.correctPercent}
          result={test.data.result}
        />
        <Box>
          {test.data.earlyFinishedEnum && (
            <Typography
              component="span"
              align="center"
              className={classes.grey}
            >
              Badanie niedokończone
            </Typography>
          )}
          {test.failureReason === "FANC" && (
            <Typography align="center" className={classes.grey}>
              Podczas badania wystąpiły zakłócenia
            </Typography>
          )}
        </Box>
      </DateAndResultGrid>
      <br />

      <Grid container justify="space-evenly" alignItems="center">
        <Box
          className={`${classes.boxLoginTitle} ${classes.paddingMedium}`}
          style={{ overflowX: "auto" }}
        >
          <Grid item>
            <Typography
              style={{
                color: (theme) => theme.palette.text.alternative2,
              }}
            >
              PRZEBIEG BADANIA
            </Typography>
          </Grid>
          {test.data && (
            <Grid item style={{ overflowX: "auto" }}>
              <TableContainer style={{ maxWidth: 550, mb: 2 }}>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeaderPerExamination}>
                        Nr próby
                      </TableCell>
                      <TableCell className={classes.tableHeaderPerExamination}>
                        Bodziec
                      </TableCell>
                      {!["TPR", "TDW"].includes(test.data.testEnum) && (
                        <TableCell
                          className={classes.tableHeaderPerExamination}
                        >
                          Odpowiedź
                        </TableCell>
                      )}
                      <TableCell className={classes.tableHeaderPerExamination}>
                        Wynik
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {test.data.tries.map((attempt, index) => (
                      <Answers
                        key={index}
                        attempt={attempt}
                        index={index}
                        withAnswer={
                          !["TPR", "TDW"].includes(test.data.testEnum)
                        }
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default OneRightAnswerResults;
