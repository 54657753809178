import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import Can from "../../components/can";
import FilterProperty, {
  FilterPropertyDate,
} from "../../components/filter-property/filter-property";
import Loader from "../../components/loader";
import {
  fetchAssignedScreenings,
  fetchDataAsCSV,
  fetchFilledFormsInfo,
  fetchOrganization,
  fetchPackageInfo,
  fetchProteges,
  fetchScreeningInfo,
} from "../../redux/actions";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import { ReactComponent as InfoIconYellow } from "../../assets/info-yellow.svg";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
  },
  table: {
    minWidth: 1250,
  },
  tableContainer: {
    height: `calc(50vh - 55px)`,
    "@media (min-height: 300px)": {
      height: `calc(54vh - 55px)`,
    },
    "@media (min-height: 350px)": {
      height: `calc(60vh - 55px)`,
    },
    "@media (min-height: 400px)": {
      height: `calc(64vh - 55px)`,
    },
    "@media (min-height: 500px)": {
      height: `calc(70vh - 55px)`,
    },
    "@media (min-height: 600px)": {
      height: `calc(76vh - 55px)`,
    },
    "@media (min-height: 700px)": {
      height: `calc(80vh - 55px)`,
    },
    "@media (min-height: 800px)": {
      height: `calc(82vh - 55px)`,
    },
    "@media (min-height: 900px)": {
      height: `calc(84vh - 55px)`,
    },
  },
  widthBox: {
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  text: {
    fontSize: theme.typography.desc,
    fontWeight: "600",
    letterSpacing: "1.5px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  tableHeader: {
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    borderBottom: "none",
    letterSpacing: "0.7px",
  },
  tableBodyCell: {
    color: theme.palette.text.hintText,
    borderBottom: `0.5px solid ${theme.palette.lockIcon.primary}`,
    fontFamily: "Montserrat",
  },
  testExpired: {
    color: theme.palette.text.error,
  },
  paper: {
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "2px 6px 23px -10px rgba(66, 68, 90, 1)",
  },
  button: {
    width: "240px",
    height: "50px",
    borderRadius: "0px",
  },
  tableDescButton: {
    color: theme.palette.text.alternative2,
    fontWeight: "bold",
    letterSpacing: "0.7px",
    fontSize: theme.typography.footerMobile,
    "&:hover": {
      color: theme.palette.lockIcon.primary,
    },
  },
  checkIcon: {
    color: theme.palette.checkIcon.primary,
  },
}));

const ResultRow = ({
  screening: {
    orgName,
    label,
    activationDate,
    endDate,
    patient,
    ex,
    tenantId,
    screeningId,
    protegeAssigned,
    patientScreeningId,
    examinationPackageFormsToFills,
    // ,
  },
}) => {
  const classes = useStyles();
  const surveyToFill = examinationPackageFormsToFills.filter(
    ({ formsToFill }) => formsToFill.length
  ).length;
  const surveyFilled = examinationPackageFormsToFills.filter(
    ({ formsFilled }) => formsFilled.length
  ).length;

  return (
    <TableRow>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {orgName || "---"}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {label || "---"}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {ex.map((e) => (
          <p key={e.label}>{e.label}</p>
        ))}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {patient.name || "---"}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {activationDate
          ? format(new Date(activationDate), "dd-MM-yyyy")
          : "---"}
      </TableCell>
      <TableCell
        className={`${classes.tableBodyCell} ${
          new Date(endDate) < new Date() && classes.testExpired
        }`}
        style={{ textAlign: "center" }}
      >
        {endDate ? format(new Date(endDate), "dd-MM-yyyy") : "---"}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {surveyToFill ? (
          <Link
            to={{
              pathname: protegeAssigned
                ? `/fill-survey/${screeningId}/${patient.id}`
                : `/fill-survey/${screeningId}`,
              state: {
                tenantId,
                toActiveTests: true,
                hearBoxTestIdArray: ex,
                patientScreeningId,
              },
            }}
          >
            <Button className={`${classes.tableDescButton}`}>Wypełnij</Button>
          </Link>
        ) : surveyFilled ? (
          <CheckCircleIcon className={classes.checkIcon} />
        ) : (
          <span>{"<brak ankiet>"}</span>
        )}
      </TableCell>
    </TableRow>
  );
};

const EmptyTestList = () => {
  const classes = useStyles();
  const history = useHistory();
  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";
  return (
    <Box className={classes.root}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} md={10} lg={8} xl={6}>
          <Paper className={classes.paper}>
            <Box p={3}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Box>
                  {isLightGlobalTheme ? (
                    <InfoIcon />
                  ) : (
                    <InfoIconYellow style={{ cursor: "pointer" }} />
                  )}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  className={classes.widthBox}
                >
                  <Typography className={classes.loginTitle}>
                    Brak przypisanych badań
                  </Typography>
                </Box>
              </Box>

              <Box p={2}>
                <Typography className={classes.text}>
                  Zeskanuj kod qr ze swojego vouchera lub kup i aktywuj dowolne
                  badanie
                </Typography>
              </Box>

              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/products")}
                className={classes.button}
              >
                WYBIERZ BADANIE
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const VouchersActivated = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const assignedScreenings = useSelector((s) => s.assignedScreenings || []);
  const assignedScreeningsFetched = useSelector(
    (s) => s.assignedScreeningsFetched || false
  );
  const proteges = useSelector((s) => s.proteges || []);
  const my = useSelector((s) => s.my || []);

  const [filterQueries, setFilterQueries] = useState({});
  const [screenings, setScreenings] = useState([]);
  const [unFilteredScreenings, setUnFilteredScreenings] = useState([]);

  const [fetchingScreenings, setFetchingScreenings] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = unFilteredScreenings.filter(search, filterQueries);
    setScreenings(result);
  }, [filterQueries]);

  function search(test) {
    return Object.keys(this).every((key) => {
      if (
        key !== "ex" &&
        key !== "patient"
        // &&
        // key !== "activationDate" &&
        // key !== "deactivationDate"
      ) {
        return test[key]
          .toString()
          .toUpperCase()
          .includes(this[key].toUpperCase());
      } else if (key === "ex") {
        const examinations = test.ex.filter((ex) =>
          ex.label.toLowerCase().includes(this[key].toLowerCase())
        );
        console.log("examinations1", examinations);
        return this[key]
          ? test[key].filter((x) =>
              examinations.some((y) => y.labelPackage === x.labelPackage)
            ).length > 0
          : test;
      } else if (key === "patient") {
        return test.patient.name
          .toLowerCase()
          .includes(this[key].toLowerCase());
      }
    });
  }

  useEffect(() => {
    if (assignedScreeningsFetched) {
      fetchScreenings();
    }
  }, [assignedScreeningsFetched]);

  const fetchData = async () => {
    await dispatch(fetchProteges({ page: 0, pageSize: 1000 }));
    await dispatch(fetchAssignedScreenings());
    // await fetchScreenings();
  };

  const fetchScreenings = async () => {
    setFetchingScreenings(true);
    let tmpArray = [];
    let filteredScreenings = assignedScreenings
      .filter(
        (screnning, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.screeningId === screnning.screeningId &&
              t.tenantId === screnning.tenantId
          )
      )
      .map(({ screeningId, tenantId, patientScreeningId }) => ({
        screeningId,
        tenantId,
        patientScreeningId,
      }));
    let filteredPackagesToArray = Array.from(
      new Set(
        Object.values(
          assignedScreenings
            .filter(
              (screnning, index, self) =>
                index ===
                self.findIndex((t) => {
                  const array1 = t.requiredExaminationPackages;
                  const array2 = screnning.requiredExaminationPackages;
                  return (
                    array1.length === array2.length &&
                    array1.every((value, index) => value === array2[index])
                  );
                })
            )
            .map(({ requiredExaminationPackages }) => [
              ...requiredExaminationPackages,
            ])
        ).flat()
      )
    );

    let filteredScreeningsWithDetails = [];
    let filteredPackagesWithDetails = [];
    for (var j = 0; j < filteredScreenings.length; j++) {
      const { label, endDate } = await dispatch(
        fetchScreeningInfo(
          filteredScreenings[j].tenantId,
          filteredScreenings[j].screeningId
        )
      );
      const { orgName } = await dispatch(
        fetchOrganization(filteredScreenings[j].tenantId)
      );
      filteredScreeningsWithDetails.push({
        label,
        endDate,
        orgName,
        screeningId: filteredScreenings[j].screeningId,
        tenantId: filteredScreenings[j].tenantId,
        patientScreeningId: filteredScreenings[j].patientScreeningId,
      });
    }

    for (var j = 0; j < filteredPackagesToArray.length; j++) {
      const { examinations, label: labelPackage } = await dispatch(
        fetchPackageInfo(filteredPackagesToArray[j])
      );

      filteredPackagesWithDetails.push({
        examinations,
        label: labelPackage,
        hearBoxTestId: filteredPackagesToArray[j],
      });
    }

    for (var j = 0; j < assignedScreenings.length; j++) {
      const { orgName, label, endDate } = filteredScreeningsWithDetails.find(
        (filteredScreening) =>
          filteredScreening.screeningId === assignedScreenings[j].screeningId &&
          filteredScreening.tenantId === assignedScreenings[j].tenantId
      );

      let ex = [];
      for (
        let i = 0;
        i < assignedScreenings[j].requiredExaminationPackages.length;
        i++
      ) {
        ex.push(
          filteredPackagesWithDetails.find(
            (filteredPackages) =>
              assignedScreenings[j].requiredExaminationPackages[i] ===
              filteredPackages.hearBoxTestId
          )
        );
      }

      const patient =
        my.id === assignedScreenings[j].patientId
          ? my.name
          : proteges.find((p) => p.id === assignedScreenings[j].patientId);
      tmpArray.push({
        orgName,
        label,
        screeningId: assignedScreenings[j].screeningId,
        requiredExaminationPackages:
          assignedScreenings[j].requiredExaminationPackages,
        tenantId: assignedScreenings[j].tenantId,
        activationDate: assignedScreenings[j].ts,
        patientScreeningId: assignedScreenings[j].patientScreeningId,
        endDate,
        ex,
        patient: {
          name:
            my.id === assignedScreenings[j].patientId
              ? patient
              : `${patient.firstName} ${patient.surname}`,
          id: my.id === assignedScreenings[j].patientId ? my.id : patient.id,
        },
        protegeAssigned:
          my.id === assignedScreenings[j].patientId ? false : true,
      });
    }

    const formsToFill = await dispatch(
      fetchFilledFormsInfo(
        tmpArray.map(
          ({
            patient: { id },
            patientScreeningId,
            screeningId,
            requiredExaminationPackages,
          }) => ({
            patientId: id,
            patientScreeningId,
            screeningId,
            requiredExaminationPackages,
          })
        )
      )
    );

    tmpArray = tmpArray.map((element) => ({
      ...element,
      ...formsToFill?.find((form) => {
        return (
          form.patientId === element.patient.id &&
          form.screeningId === element.screeningId &&
          form.patientScreeningId === element.patientScreeningId
        );
      }),
    }));

    setScreenings(tmpArray);
    setUnFilteredScreenings(tmpArray);
    setFetchingScreenings(false);
  };

  const headerCells = [
    {
      title: "Fundator",
      key: "orgName",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Przesiew",
      key: "label",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Nazwa badania",
      key: "ex",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Osoba badana",
      key: "patient",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Data aktywacji",
      key: "activationDate",
      sortable: false,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Data dezaktywacji",
      key: "endDate",
      sortable: false,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Ankiety",
      key: "survey",
      sortable: false,
      filter: (key) => {},
    },
  ];

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("code");
  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };
  const page = () =>
    fetchingScreenings ? (
      <Loader loading={true} text="Wczytywanie badań" />
    ) : unFilteredScreenings.length > 0 ? (
      <Box p={1}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {headerCells.map((headCell) => (
                  <TableCell
                    key={headCell.key}
                    className={classes.tableHeader}
                    style={{ textAlign: "center" }}
                  >
                    {headCell.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {headerCells.map((headCell) => (
                  <TableCell key={headCell.key} style={{ textAlign: "center" }}>
                    {headCell.filter(headCell.key)}
                  </TableCell>
                ))}
              </TableRow>
              {screenings.map((screening, index) => (
                <ResultRow key={index} screening={screening} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <EmptyTestList />
    );

  const redirect = () => <Redirect to="/" />;

  return <Can permission="active-tests:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VouchersActivated);
