import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withStyles, makeStyles, useMediaQuery } from "@material-ui/core";

const ProgressBar = withStyles((theme) => ({
  root: {
    height: 30,
    margin: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[600]}`,
    borderRadius: 5,
  },
}))(({ classes, ...props }) => {
  return (
    <LinearProgress
      classes={{
        root: classes.root,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
  },
  textContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export default function LinearProgressWithLabel({
  helperText = "",
  text,
  value,
  column = false,
}) {
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection={column ? "column" : "row"}
      alignItems="center"
      maxWidth={350}
    >
      <Box style={{ whiteSpace: "nowrap" }}>{helperText}</Box>
      <Box className={classes.container} mr={1}>
        <Box width="100%">
          <ProgressBar variant="determinate" value={value} />
        </Box>
        <Box minWidth={35} className={classes.textContainer}>
          <Typography
            variant="body2"
            style={{ color: "white", fontSize: smallScreen && "0.6rem" }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
