import { DONE, FAILED, START } from "../../constants";
import { getAxiosInstance } from "../common";

export const UPDATE_CONFIG_GLOBAL = "UPDATE_CONFIG_GLOBAL";
export const UPDATE_TENANT_CONTEXT = "UPDATE_TENANT_CONTEXT";
export const GET_TENANT_CONTEXT = "GET_TENANT_CONTEXT";
export const CHECK_MEDIC_AUTH = "CHECK_MEDIC_AUTH";

export const updateConfigGlobal = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_CONFIG_GLOBAL + START });
  try {
    const result = await getAxiosInstance().put(
      `/api/config_global/1`,
      payload
    );
    dispatch({ type: UPDATE_CONFIG_GLOBAL + DONE, payload: result.data });

    return result.data;
  } catch (payload) {
    dispatch({ type: UPDATE_CONFIG_GLOBAL + FAILED, payload });
    return false;
  }
};
export const tenantContext = (orgContext) => async (dispatch) => {
  dispatch({ type: UPDATE_TENANT_CONTEXT + START });
  try {
    dispatch({ type: UPDATE_TENANT_CONTEXT + DONE, payload: orgContext });

    return orgContext;
  } catch (payload) {
    dispatch({ type: UPDATE_TENANT_CONTEXT + FAILED, payload });
    return false;
  }
};

export const getTenantContext = () => {
  return {
    type: GET_TENANT_CONTEXT,
  };
};

export const checkAuthMedic = () => async (dispatch) => {
  try {
    const my = await getAxiosInstance().get("/medic/v1/auth");
    dispatch({ type: CHECK_MEDIC_AUTH + DONE, payload: my.data });
    return my;
  } catch (e) {
    return false;
  }
};
