import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { lightTheme } from "../../theme";

const UnfinishedTestInfo = () => {
  const isLightTheme =
    useTheme().palette.primary.main === lightTheme.palette.primary.main;

  return (
    <p
      style={{
        textDecoration: `underline overline ${
          isLightTheme ? "#CF1616" : "yellow"
        }`,
        textUnderlineOffset: "3px",
        textAlign: "center",
      }}
    >
      Badanie zostało przedwcześnie zakończone.
    </p>
  );
};

export default UnfinishedTestInfo;
