import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { alertTimeout } from "../../redux/actions";
import Alert from "@material-ui/lab/Alert";
import { drawerWidth } from "../header/header";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { ReactComponent as CheckIcon } from "../../assets/check.svg";

import Link1 from "@material-ui/core/Link";
import ModalAlert from "./modal-alert";

const useStyles = makeStyles(() => ({
  alert: {
    "@media (min-width: 580px)": {
      marginLeft: `${drawerWidth}px`,
      marginRight: "50px",
    },
  },
}));

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.anchor.primary,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
      textDecoration: "underline",
    },
  },
}))(Link1);

const CustomAlert = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    background: theme.palette.background.default,
    borderRadius: "8px",
    color: theme.palette.text.hintText,
    fontWeight: "500",
    fontSize: theme.typography.footerMobile,
  },
}))(Alert);

const Alert2 = ({
  id,
  isError,
  isSuccess,
  isWarning,
  isInfo,
  text,
  redirect,
  customAlert = false,
  customModalAlert = false,
  timeout = 3000,
  timeoutFunc,
  alertTimeout,
  persistent = false,
}) => {
  const [timer, setTimer] = useState();
  const [severity, setSeverity] = useState("info");
  const classes = useStyles();

  const globalTheme = useSelector((s) => s.globalTheme || "light");

  useEffect(() => {
    if (isError) {
      setSeverity("error");
    }
    if (isWarning) {
      setSeverity("warning");
    }
    if (isSuccess) {
      setSeverity("success");
    }
    if (isInfo) {
      setSeverity("info");
    }
  }, [isError, isSuccess, isWarning]);

  useEffect(() => {
    if (timeout && !persistent) {
      setTimeout(() => {
        alertTimeout(id);
      }, timeout);
      setTimer(id);
    }
  }, [timeout]);

  return globalTheme === "high-contrast" && !customModalAlert ? (
    <Alert
      severity={severity}
      onClose={() => alertTimeout(id)}
      style={{
        backgroundColor: "#000000",
        border: "1px solid #ffff00",
        color: "#ffff00",
      }}
      onClick={() => alertTimeout(id)}
    >
      {text}{" "}
      {redirect && (
        <MuiNavLink href={redirect.href}>{redirect.text}</MuiNavLink>
      )}
    </Alert>
  ) : !customAlert && !customModalAlert ? (
    <Alert
      onClose={() => alertTimeout(id)}
      className={classes.alert}
      severity={severity}
      onClick={() => alertTimeout(id)}
    >
      {text}{" "}
      {redirect && (
        <MuiNavLink href={redirect.href}>{redirect.text}</MuiNavLink>
      )}
    </Alert>
  ) : !customModalAlert ? (
    <CustomAlert
      onClose={() => alertTimeout(id)}
      onClick={() => alertTimeout(id)}
      icon={<CheckIcon />}
    >
      {text}{" "}
      {redirect && (
        <MuiNavLink href={redirect.href}>{redirect.text}</MuiNavLink>
      )}
    </CustomAlert>
  ) : (
    <ModalAlert
      onClose={() => alertTimeout(id)}
      text={text}
      redirectPath={redirect?.href ? redirect.href : ""}
      redirectText={redirect?.text ? redirect.text : ""}
    />
  );
};

export default connect(
  () => ({}),
  (dispatch) => ({
    alertTimeout: (payload) => dispatch(alertTimeout(payload)),
  })
)(Alert2);
