import React, { useState, useEffect, useRef, useMemo } from "react";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Button,
  ButtonGroup,
  Tooltip,
  ClickAwayListener,
  TablePagination,
  IconButton,
  Collapse,
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import format from "date-fns/format";
import Can from "../../components/can";
import FilterProperty from "../../components/filter-property/filter-property";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosInstance } from "../../redux/common";
import {
  fetchFilledFormsInfo,
  fetchFormByScreeningId,
  fetchPackages,
  fetchScreeningInfo,
} from "../../redux/actions";
import StopIcon from "@material-ui/icons/Stop";
import { ReactComponent as ArrowDownIcon } from "../../assets/arrowDownIcon.svg";
import { ReactComponent as ArrowDownIconYellow } from "../../assets/arrowDownIcon-yellow.svg";
import Loader from "../../components/loader";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import { ReactComponent as InfoIconYellow } from "../../assets/info-yellow.svg";
import { ReactComponent as WarningIcon } from "../../assets/warning.svg";
import { ReactComponent as WarningYellow } from "../../assets/warning-yellow.svg";
import moment from "moment/moment";
import DescBuyModal from "./description-modal.js/description-buy-modal";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "16px",
  },
  root2: {
    padding: "16px",
  },
  paper: {
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  paper: {
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "2px 6px 23px -10px rgba(66, 68, 90, 1)",
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  boxLoginTitle: {
    background: theme.palette.background.loginBackground,
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingMedium: {
    padding: "16px 32px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  tableHeader: {
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    borderBottom: "none",
    letterSpacing: "0.7px",
  },
  table: {
    minWidth: 1250,
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
  },
  tableContainer: {
    height: `calc(46vh - 55px)`,
    "@media (min-height: 300px)": {
      height: `calc(50vh - 55px)`,
    },
    "@media (min-height: 350px)": {
      height: `calc(56vh - 55px)`,
    },
    "@media (min-height: 400px)": {
      height: `calc(62vh - 55px)`,
    },
    "@media (min-height: 500px)": {
      height: `calc(66vh - 55px)`,
    },
    "@media (min-height: 600px)": {
      height: `calc(72vh - 55px)`,
    },
    "@media (min-height: 700px)": {
      height: `calc(76vh - 55px)`,
    },
    "@media (min-height: 800px)": {
      height: `calc(78vh - 55px)`,
    },
    "@media (min-height: 900px)": {
      height: `calc(80vh - 55px)`,
    },
  },
  select: {
    "& .MuiInputBase-root": {
      background: "#000",
      color: "#fff",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.text.checked,
    },
    "&.MuiPaper-root": {
      borderRadius: "0px",
      background: theme.palette.background.default,
    },
  },
  color: {
    color: theme.palette.text.hintText,
  },
  menuItemFont: {
    letterSpacing: "1px",
    lineHeight: `${theme.typography.formLabel}px`,
    fontSize: theme.typography.footer,
    color: theme.palette.text.hintText,
    textTransform: "uppercase",
    borderBottom: `1px solid ${theme.palette.background.menuItemBorderBottom}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  tableBodyCell: {
    color: theme.palette.text.hintText,
    borderBottom: `0.5px solid ${theme.palette.lockIcon.primary}`,
    fontFamily: "Montserrat",
  },
  tableBodyText: {
    fontFamily: "Montserrat",
  },
  tableDescButton: {
    color: theme.palette.lockIcon.primary,
    letterSpacing: "0.7px",
    fontSize: theme.typography.footerMobile,
    "&:hover": {
      color: theme.palette.text.hintText,
    },
  },
  editionButton: {
    padding: "0px 16px",
    borderRadius: "20px",
    letterSpacing: "0.5px",
    background: theme.palette.lockIcon.primary,
    color: theme.palette.background.default,
    fontSize: theme.typography.footerMobile,
    "&:hover": {
      background: theme.palette.passwordIcon.primary,
    },
  },
  widthBox: {
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
}));

const RESULT_MAP = {
  AMB: "Niejednoznaczny",
  OK: "Poprawny",
  NOK: "Niepoprawny",
};

const styles = (theme) => ({
  tooltip: {
    borderRadius: "10px",
    opacity: "0.5",
    boxShadow: "0px 2px 5px #BEBEBE",
    backgroundColor: theme.palette.background.default,
    padding: "16px",
    fontSize: theme.typography.desc,
    fontFamily: "Raleway",
    color: theme.palette.text.hintText,
    maxWidth: "150px",
    textAlign: "center",
  },
});

const CustomTooltip = withStyles(styles)(Tooltip);

const DescriptionOption = ({
  pck,
  id,
  label,
  patientName,
  handleOrderDesc,
}) => {
  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";
  const history = useHistory();
  const classes = useStyles();

  if (pck.finished === false) {
    return (
      <Box style={{ minWidth: "130px" }}>
        <CustomTooltip
          title="Badanie zostało przedwcześnie zakończone. Musisz wykonać je
  ponownie, aby zamówić opis lub uzyskać opis od lekarza."
        >
          {isLightGlobalTheme ? (
            <InfoIcon />
          ) : (
            <InfoIconYellow style={{ cursor: "pointer" }} />
          )}
        </CustomTooltip>
      </Box>
    );
  } else {
    return pck.exStatus === undefined ? (
      <Box style={{ minWidth: "130px" }}>
        <Typography>Nie udało się uzyskać informacji o opisie.</Typography>
      </Box>
    ) : pck.exStatus.descriptionByDoctorFinished ? (
      <Box style={{ minWidth: "130px" }}>
        <Button
          className={`${classes.tableDescButton}`}
          onClick={() => {
            history.push({
              pathname: "/detailed-results",
              state: {
                screeningId: pck.screeningId,
                withDescription: true,
                patientScreeningId: pck.patientScreeningId,
                hearBoxScreeningId: pck.hearBoxScreeningId,
                hearBoxTestId: pck.hearBoxTestId,
                id,
                packageName: label,
                patientName: patientName,
              },
            });
          }}
        >
          Wyświetl opis
        </Button>
      </Box>
    ) : pck.exStatus.descriptionRequested ? (
      <Box style={{ minWidth: "130px" }}>
        <CustomTooltip
          title={
            pck.reclaimedAt
              ? `Badanie zostało zareklamowane`
              : "Trwa proces tworzenia opisu"
          }
        >
          <span>
            <Button disabled={true} className={`${classes.tableDescButton}`}>
              Wyświetl opis
            </Button>
          </span>
        </CustomTooltip>
      </Box>
    ) : pck.buyDescriptionOptions.buyDescriptionStatus === "CanBuy" ? (
      <Box style={{ minWidth: "130px" }}>
        <Button
          className={`${classes.tableDescButton}`}
          onClick={() => {
            handleOrderDesc(pck);
          }}
          disabled={pck.reclaimedAt}
        >
          Zamów opis
        </Button>
      </Box>
    ) : pck.buyDescriptionOptions.buyDescriptionStatus ===
      "CannotBuyAlreadyBought" ? (
      <Box style={{ minWidth: "130px" }}>
        <CustomTooltip
          title={
            pck.reclaimedAt
              ? `Badanie zostało zareklamowane`
              : "Trwa proces tworzenia opisu"
          }
        >
          <span>
            <Button disabled={true} className={`${classes.tableDescButton}`}>
              Wyświetl opis
            </Button>
          </span>
        </CustomTooltip>
      </Box>
    ) : pck.buyDescriptionOptions.buyDescriptionStatus ===
        "CannotBuyNoPrices" ||
      pck.buyDescriptionOptions.buyDescriptionStatus ===
        "CannotBuyIncompleteTests" ? (
      <Box style={{ minWidth: "130px" }}>
        {" "}
        <CustomTooltip
          title={
            pck.buyDescriptionOptions.buyDescriptionStatus ===
            "CannotBuyNoPrices"
              ? "Brak możliwości kupna dla tego badania"
              : "Aby dokupić opis, należy wykonać wszystkie testy z vouchera"
          }
        >
          <span>
            <Button disabled className={`${classes.tableDescButton}`}>
              Zamów opis
            </Button>
          </span>
        </CustomTooltip>
      </Box>
    ) : null;
  }
};

const ResultRow = ({
  result: { label, ts, patient, result, medicalDesc, packages },
  allPackages,
  handleOrderDesc,
}) => {
  const {
    params: { id },
  } = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";

  return (
    <TableRow>
      {/* <TableCell
        className={classes.tableBodyCell}
        width="20%"
      >{` ${label}`}</TableCell> */}
      {/* <TableCell className={classes.tableBodyCell} width="25%"> */}
      <TableCell className={classes.tableBodyCell}>
        {packages.map((p) => {
          const label = allPackages?.find(
            (pck) => p.hearBoxTestId === pck.hearBoxTestId
          )?.label;
          return (
            <p
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {p.reclaimedAt && (
                <Box style={{ marginRight: "10px" }}>
                  <CustomTooltip title="Badanie zostało zareklamowane. Brak możliwości zamówienia opisu.">
                    {isLightGlobalTheme ? (
                      <WarningIcon />
                    ) : (
                      <WarningYellow style={{ cursor: "pointer" }} />
                    )}
                  </CustomTooltip>
                </Box>
              )}
              {label}{" "}
            </p>
          );
        })}
      </TableCell>
      <TableCell
        style={{ textAlign: "center" }}
        className={classes.tableBodyCell}
        // width="10%"
      >
        {packages.map(
          (p) =>
            allPackages?.find(
              (pck) => p.hearBoxTestId === pck.hearBoxTestId
            ) && (
              <p style={{ marginTop: "10px" }}>
                {p.ts
                  ? moment.utc(p.ts).local().format("DD/MM/YYYY HH:mm:ss")
                  : "---"}
              </p>
            )
        )}
      </TableCell>
      <TableCell
        // width="10%"
        style={{ textAlign: "center" }}
        className={classes.tableBodyCell}
      >
        {packages.map(
          (p) =>
            allPackages?.find(
              (pck) => p.hearBoxTestId === pck.hearBoxTestId
            ) && <p style={{ marginTop: "10px" }}> {RESULT_MAP[p.result]}</p>
        )}
      </TableCell>
      <TableCell
        // width="55%"
        style={{ textAlign: "center" }}
        className={classes.tableBodyCell}
      >
        {packages.map(
          (p) =>
            allPackages?.find(
              (pck) => p.hearBoxTestId === pck.hearBoxTestId
            ) && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  className={classes.editionButton}
                  onClick={() => {
                    history.push({
                      pathname: "/detailed-results",
                      state: {
                        screeningId: p.screeningId,
                        patientScreeningId: p.patientScreeningId,
                        hearBoxScreeningId: p.hearBoxScreeningId,
                        hearBoxTestId: p.hearBoxTestId,
                        id,
                        packageName: label,
                        patientName:
                          `${patient.firstName} ${patient.lastName}` || "---",
                        date: p.ts,
                      },
                    });
                  }}
                >
                  Wynik Szczegółowy
                </Button>{" "}
                {p.survey &&
                p.examinationPackageFormsToFills[0].formsToFill.length ? (
                  p.reclaimedAt ? (
                    <Button
                      className={`${classes.tableDescButton}`}
                      disabled={p.reclaimedAt}
                    >
                      WYPEŁNIJ ANKIETĘ
                    </Button>
                  ) : (
                    <Link
                      to={{
                        pathname: id
                          ? `/fill-survey/${p.screeningId}/${id}`
                          : `/fill-survey/${p.screeningId}`,
                        state: {
                          tenantId: p.tenantId,
                          hearBoxScreeningId: p.hearBoxScreeningId,
                          patientScreeningId: p.patientScreeningId,
                          hearBoxTestIdArray: [
                            allPackages?.find(
                              (pck) => p.hearBoxTestId === pck.hearBoxTestId
                            ),
                          ],
                        },
                      }}
                    >
                      <Button
                        className={`${classes.tableDescButton}`}
                        disabled={p.reclaimedAt}
                      >
                        WYPEŁNIJ ANKIETĘ
                      </Button>
                    </Link>
                  )
                ) : null}
                <DescriptionOption
                  pck={p}
                  id={id}
                  patientName={
                    `${patient.firstName} ${patient.lastName}` || "---"
                  }
                  label={label}
                  handleOrderDesc={handleOrderDesc}
                />
              </Box>
            )
        )}
      </TableCell>
    </TableRow>
  );
};

const EmptyTestList = () => {
  const classes = useStyles();
  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";
  return (
    <Box className={classes.root2}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} md={10} lg={8} xl={6}>
          <Paper className={classes.paper}>
            <Box p={3}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Box>
                  {isLightGlobalTheme ? (
                    <InfoIcon />
                  ) : (
                    <InfoIconYellow style={{ cursor: "pointer" }} />
                  )}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  className={classes.widthBox}
                >
                  <Typography className={classes.loginTitle}>
                    Brak dostępnych wyników
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const Results = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [allPackages, setAllPackages] = useState([]);
  const [results, setResults] = useState([]);
  const [fetchingResults, setFetchingResults] = useState(true);

  const [openDescBuyModal, setOpenDescBuyModal] = useState(false);
  const [resultDetails, setResultDetails] = useState({});

  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";
  const [resultsCount, setResultsCount] = useState(0);
  const proteges = useSelector((s) => s.proteges);
  const my = useSelector((s) => s.my);

  const {
    params: { id },
  } = useRouteMatch();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");
  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [filterQueries, setFilterQueries] = useState({});

  const [personResults, setPersonResults] = useState(my || {});

  // useEffect(() => {
  //   async function fetchProteges() {
  //     await dispatch(fetchProteges());
  //   }

  //   fetchProteges();
  // }, []);

  useEffect(() => {
    if (id) {
      setPersonResults(proteges.find((p) => p.id === id));
    } else {
      if (history.location.pathname != `/results`) {
        history.push(`/results`);
      }
    }
    setFetchingResults(true);
    fetchResults();
    loadPackages();
  }, [id, proteges]);

  useEffect(() => {
    if (personResults?.parentAccountId) {
      if (history.location.pathname != `/results/${personResults.id}`) {
        history.push(`/results/${personResults.id}`);
      }
    }
  }, [personResults]);

  const loadPackages = async () => {
    const response = await dispatch(fetchPackages());
    setAllPackages(response);
  };

  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }

  const fetchResults = async () => {
    try {
      let response = null;
      if (id) {
        response = await getAxiosInstance().get(
          `/api/screening/completed/protegee/all?id=${id}`
        );
      } else {
        response = await getAxiosInstance().get(
          // "/patient/v1/screening/completed/all"
          "/api/screening/completed"
        );
      }
      const { completedScreenings } = response.data;
      const { screeningDefinitions } = response.data;

      const groups = groupByKey(
        completedScreenings.sort((a, b) =>
          a.ts > b.ts ? -1 : a.ts < b.ts ? 1 : 0
        ),
        "patientScreeningId"
      );
      let res = Object.entries(groups).map(
        ([patientScreeningId, obj], index) => {
          return {
            patientScreeningId,
            screeningId: obj[0].screeningId,
            patient: obj[0].patient,
            tenantId: obj[0].tenantId,
            packages: obj,
            buyDescriptionOptions: obj[0].buyDescriptionOptions,
            result: true,
          };
        }
      );
      const formsToFill = await dispatch(
        fetchFilledFormsInfo(
          completedScreenings.map(
            ({
              patient: { id },
              patientScreeningId,
              screeningId,
              hearBoxTestId,
            }) => ({
              patientId: id,
              patientScreeningId,
              screeningId,
              requiredExaminationPackages: [hearBoxTestId],
            })
          ),
          true
        )
      );

      console.log("forms to fill", formsToFill);

      res = res.map((e) => ({
        ...e,
        packages: e.packages.map((element) => ({
          ...element,
          ...formsToFill.find((form) => {
            return (
              form.patientId === element.patient.id &&
              form.screeningId === element.screeningId &&
              form.patientScreeningId === element.patientScreeningId &&
              form.examinationPackageFormsToFills.find(
                (f) => f.hearBoxTestId === element.hearBoxTestId
              )
            );
          }),
        })),
      }));

      for (var i = 0; i < res.length; i++) {
        for (var j = 0; j < res[i].packages?.length; j++) {
          const p = res[i].packages[j];
          let respStatus;
          if (id) {
            respStatus = await getAxiosInstance().get(
              "/patient/v1/screening/examination/status/protegee",
              {
                params: {
                  patientScreeningId: p.patientScreeningId,
                  hearBoxTestId: p.hearBoxTestId,
                  hearBoxScreeningId: p.hearBoxScreeningId,
                  patientId: id,
                },
              }
            );
            p.exStatus = respStatus.data;
            const forms = await dispatch(
              fetchFormByScreeningId(p.screeningId, id, p.hearBoxTestId)
            );
            p.survey = forms.length > 0;
          } else {
            respStatus = await getAxiosInstance().get(
              "/patient/v1/screening/examination/status",
              {
                params: {
                  patientScreeningId: p.patientScreeningId,
                  hearBoxTestId: p.hearBoxTestId,
                  hearBoxScreeningId: p.hearBoxScreeningId,
                },
              }
            );
            p.exStatus = respStatus.data;
            const forms = await dispatch(
              fetchFormByScreeningId(p.screeningId, null, p.hearBoxTestId)
            );
            p.survey = forms.length > 0;
          }
        }
      }

      setResults(res);

      setFetchingResults(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const headerCells = [
    {
      title: "Przesiew",
      key: "type",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Osoba",
      key: "person",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Ankiety",
      key: "survey",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Opcje",
      key: "options",
      sortable: false,
      filter: (key) => null,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  return fetchingResults ? (
    <Box p={1}>
      {<Loader loading={fetchingResults} text="Pobieranie wyników..." />}
    </Box>
  ) : (
    <Box className={classes.root}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} md={10}>
          <Paper className={classes.paper}>
            <Box
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
              className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
            >
              <StopIcon style={{ height: "8px", color: "#3458A4" }} />
              <Typography className={classes.loginTitle}>Wyniki</Typography>
              <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
            </Box>
            <Box className={`${classes.paddingMedium}`}>
              <Grid container style={{ width: "100%" }}>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth>
                    <Select
                      // className={classes.select}
                      labelId="select-outlined-label"
                      id="select-outlined"
                      value={personResults}
                      className={classes.select}
                      classes={{
                        root: classes.color,
                      }}
                      // fullWidth
                      onChange={(event) => {
                        if (event.target.value.id === my.id) {
                          history.push("/results");
                        }
                        setPersonResults(event.target.value);
                      }}
                      IconComponent={(props) => (
                        <i
                          {...props}
                          className={`material-icons ${props.className}`}
                          style={{ top: "calc(50% - 20px)" }}
                        >
                          {isLightGlobalTheme ? (
                            <ArrowDownIcon />
                          ) : (
                            <ArrowDownIconYellow />
                          )}
                        </i>
                      )}
                      label="Osoba badana"
                    >
                      {proteges &&
                        [my].concat(proteges).map((p) => {
                          return (
                            <MenuItem
                              key={p.id}
                              value={p}
                              className={classes.menuItemFont}
                            >
                              {`${p.firstName} ${p.surname}`}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box className={`${classes.paddingMedium}`}>
              {results.length > 0 ? (
                <TableContainer className={classes.tableContainer}>
                  <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        {/* <TableCell width="20%" className={classes.tableHeader}>
                        Przesiew
                      </TableCell> */}
                        <TableCell width="25%" className={classes.tableHeader}>
                          Testy
                        </TableCell>
                        <TableCell
                          width="10%"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                          className={classes.tableHeader}
                        >
                          DATA
                        </TableCell>
                        <TableCell
                          width="10%"
                          style={{ textAlign: "center" }}
                          className={classes.tableHeader}
                        >
                          WYNIK
                        </TableCell>

                        <TableCell
                          width="55%"
                          style={{ textAlign: "center" }}
                          className={classes.tableHeader}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {console.log("packages", results)}
                      {results.map((result, index) => (
                        <ResultRow
                          key={index}
                          result={result}
                          allPackages={allPackages}
                          handleOrderDesc={(pck) => {
                            setOpenDescBuyModal(true);
                            setResultDetails(pck);
                          }}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <EmptyTestList />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {openDescBuyModal && (
        <DescBuyModal
          openDescBuyModal={openDescBuyModal}
          handleClose={() => {
            setOpenDescBuyModal(false);
            setResultDetails({});
          }}
          resultDetails={resultDetails}
        />
      )}
    </Box>
  );
};

const ResultsPage = () => {
  return (
    <Can
      permission="results-page:view"
      ok={() => <Results />}
      not={() => <Redirect to="/" />}
    />
  );
};

export default ResultsPage;
