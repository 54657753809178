import React from "react";
import s from "./index.module.css";
import classnames from "classnames";
import { useDrop } from "react-dnd";
import uuid from "uuid4";
import { createControl, ItemTypes } from "../../../DnDTypes";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import { FormFieldChecks } from "../../../FormFieldChecks";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Link from "@material-ui/core/Link";
import { useSelector } from "react-redux";

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.anchor.primary,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
      textDecoration: "underline",
    },
  },
}))(Link);

const ItemSeparator = ({
  displayMode,
  visible = false,
  dragMode,
  rowIndex,
  index,
  canDrop,
  moveItemToIndex,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.COMPONENT,
    drop: (item, rest) => {
      if (!displayMode) {
        const isClone = item.dragMode === "clone";
        const isMove = item.dragMode === "move";

        const targetRow = rowIndex;
        const targetIndex = index;
        const sourceRow = item.rowIndex;
        const sourceIndex = item.index;

        if (isClone) {
        } else if (isMove) {
          moveItemToIndex &&
            moveItemToIndex({
              item,
              targetRow,
              targetIndex,
              sourceRow,
              sourceIndex,
            });
        }
      }
    },
    collect: (monitor, props) => {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  // only in form edit mode
  if (dragMode === "move" || dragMode === "clone") {
    return (
      <Tooltip
        title={
          !displayMode
            ? "Przeciągnij tu element formularza aby zmienić jego pozycję"
            : ""
        }
      >
        <div
          className={classnames(s.separator, { [s.show]: isOver })}
          ref={drop}
        ></div>
      </Tooltip>
    );
  }

  return null;
};

const ItemRowSeparator = ({ displayMode, dragMode }) => {
  const [{ isOver }, dropItem] = useDrop({
    accept: ItemTypes.COMPONENT,
    drop: (item, rest) => {},
    collect: (monitor, props) => {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  // only in form edit mode
  if (dragMode === "move" || dragMode === "clone") {
    return (
      <Tooltip
        title={
          !displayMode
            ? "Przeciągnij tu element formularza aby zmienić jego pozycję"
            : ""
        }
      >
        <div
          className={classnames(s.separatorRow, { [s.show]: isOver })}
          ref={dropItem}
        />
      </Tooltip>
    );
  }

  return null;
};

const useStyles = makeStyles((theme) => ({
  hintText: {
    display: "flex",
    fontSize: theme.typography.body2.hintText,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.hintText,
    },
    lineHeight: "1.4",
    margin: "-5px auto 5px",
    color: theme.palette.text.primary2,
  },
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}));

// model = null | 'fill'
const RowControl = ({
  displayMode,
  update,
  updateValues,
  onNoteUpdate,
  notes,
  markSelectedConfiguration,
  items = [],
  dragMode,
  selectedId,
  formCreatorMode,
  removeItem,
  moveItemToIndex,
  rowIndex,
  showDropMarkers,
  setIsDragging,
  rowValues,
  actualValues,
  filledValuesDetails,
  notesData,
  filesData,
  filledFormMode,
  disabled,
  rowsLength,
  alertDisplaying,
  textColorFilling,
  blankedRequiredFields,
  blankedRequiredArrayValueFields,
  checkBlankedFields,
  incorrectFileTypeIDs,
  setIncorrectFileTypeIDs,
  edit,
}) => {
  const classes = useStyles();
  const globalTheme = useSelector((s) => s.globalTheme);
  const [, drop] = useDrop({
    accept: ItemTypes.COMPONENT,
    drop: (item, rest) => {
      if (!displayMode) {
        const isClone = item.dragMode === "clone";

        const targetRow = rowIndex;
        const sourceRow = item.rowIndex;
        const sourceIndex = item.index;

        if (isClone && update) {
          update([
            ...items,
            {
              id: uuid(),
              dataType: item.subtype,
              configuration: {
                label: item.defaultLabel || "",
              },
              // componentType: TODO in case we have multiple possible renderers
              // this field could be used to specify which one we want to use
            },
          ]);
        } else {
          if (!(targetRow < items.length)) {
            const targetIndex = items.findIndex((i) => item.id === i.id);
            moveItemToIndex &&
              moveItemToIndex({
                item,
                targetRow,
                targetIndex,
                sourceRow,
                sourceIndex,
              });
          }
        }
      }
    },
    collect: (connect, monitor) => ({}),
  });

  const rowIsEmpty = items.length === 0;

  const renderErrorMessage = (field) =>
    [
      FormFieldChecks.isNumberWithNoType(field) &&
        "Brak ustawionego typu liczby",
      FormFieldChecks.isNumberWithInvertedRanges(field) &&
        "Wartość maksymalna jest mniejsza od wartości minimalnej",
      FormFieldChecks.isTextWithNoCharLimit(field) &&
        "Brak ustawionej liczby znaków",
      FormFieldChecks.isTextWithUnpositiveCharLimit(field) &&
        "Liczba znaków musi być dodatnia",
      FormFieldChecks.isTextWithUnnaturalCharLimit(field) &&
        "Liczba znaków musi być liczbą całkowitą",
      FormFieldChecks.isTextWithNoRowsLimit(field) &&
        "Brak ustawionej liczby wierszy",
      FormFieldChecks.isTextWithUnpositiveRowsLimit(field) &&
        "Liczba wierszy musi być dodatnia",
      FormFieldChecks.isTextWithUnnaturalRowsLimit(field) &&
        "Liczba wierszy musi być liczbą całkowitą",
      FormFieldChecks.isFileWithNoFileLimit(field) &&
        "Brak ustawionej maksymalnej liczby plików",
    ]
      .filter((item) => item)
      .map((item) => <li className={s.errorMessage}>{item}</li>);

  const cardSource = {
    beginDrag(props) {
      return {
        id: props.id,
        index: props.index,
      };
    },
  };

  const updateValue = (data) => {
    if (updateValues) {
      updateValues(data);
    }
  };

  return (
    <div
      className={classnames(s.main, "columns", {
        [s.alignCenter]: rowIsEmpty,
      })}
      ref={drop}
    >
      {Object.keys(items).forEach((key) =>
        items[key] === undefined ? delete items[key] : {}
      )}
      {items.map((item, index) => {
        let filledValue = null;

        if (rowValues && rowValues[index]) {
          if (item.dataType === "FILE") {
            filledValue = rowValues[index].files;
          } else {
            filledValue = rowValues[index].value;
          }
        }

        const control = createControl({
          id: item.id,
          subtype: item.dataType,
          configuration: item.configuration,
          markSelected: markSelectedConfiguration,
          isSelected: item.id === selectedId,
          dragMode,
          formCreatorMode,
          onChange: (d) => {
            updateValue({ ...d, configuration: item.configuration });
          },
          removable: formCreatorMode,
          onRemove: () => removeItem(index),
          rowIndex,
          index,
          setIsDragging: setIsDragging,
          filledValue,
          filledValuesDetails,
          actualValues,
          alertDisplaying,
          textColorFilling,
          filledFormMode,
          filesData,
          disabled,
          blankedRequiredFields,
          blankedRequiredArrayValueFields,
          checkBlankedFields,
          incorrectFileTypeIDs,
          setIncorrectFileTypeIDs,
          displayMode,
        });
        return (
          <React.Fragment key={index}>
            {showDropMarkers && index === 0 && (
              <ItemSeparator
                displayMode={displayMode}
                rowIndex={rowIndex}
                index={index}
                dragMode={dragMode}
                moveItemToIndex={moveItemToIndex}
              />
            )}
            <div className="column">
              {control}
              <span className={classes.hintText} style={{ marginTop: "10px" }}>
                {item.configuration.required && "* Pole wymagane"}
              </span>
              <ul style={{ marginTop: "5px" }}>{renderErrorMessage(item)}</ul>
              {filledFormMode &&
                item.configuration.noteEnabled &&
                notesData?.find((note) => note.id === item.id)?.note && (
                  <>
                    <hr />
                    <TextField
                      id="notes"
                      size="small"
                      label="Dodatkowa uwaga"
                      disabled={!edit}
                      value={
                        notesData?.find((note) => note.id === item.id).note ||
                        ""
                      }
                      variant={
                        globalTheme === "high-contrast"
                          ? "outlined"
                          : "standard"
                      }
                      fullWidth
                    />
                  </>
                )}
              {!filledFormMode &&
                !formCreatorMode &&
                item.configuration.noteEnabled && (
                  <>
                    <hr
                      style={{
                        backgroundColor:
                          globalTheme === "high-contrast" && "yellow",
                      }}
                    />
                    {notes?.hasOwnProperty(item.id) && (
                      <TextField
                        id="notes"
                        size="small"
                        label="Dodatkowa uwaga"
                        value={notes[item.id] || ""}
                        fullWidth
                        variant={
                          globalTheme === "high-contrast"
                            ? "outlined"
                            : "standard"
                        }
                        onChange={({ target: { value: note } }) => {
                          onNoteUpdate({ note, id: item.id });
                        }}
                        // style={{
                        //   border: "1px solid lightgray",
                        //   borderRadius: "4px",
                        //   padding: "3px",
                        //   backgroundColor: "white",
                        // }}
                      />
                    )}
                    <MuiNavLink
                      // className={
                      //   classes.aalertHighContrast
                      // }
                      style={{ display: "flex" }}
                      onClick={() => onNoteUpdate({ id: item.id })}
                    >
                      {notes?.hasOwnProperty(item.id)
                        ? `Usuń uwagę`
                        : `Dodaj uwagę`}
                    </MuiNavLink>
                  </>
                )}
            </div>
            {showDropMarkers && (
              <ItemSeparator
                displayMode={displayMode}
                rowIndex={rowIndex}
                index={index + 1}
                dragMode={dragMode}
                moveItemToIndex={moveItemToIndex}
              />
            )}
          </React.Fragment>
        );
      })}
      {rowIsEmpty && !(rowsLength - 1 === rowIndex) && (
        <div className="column">
          <ItemRowSeparator displayMode={displayMode} dragMode={dragMode} />
        </div>
      )}
      {!displayMode &&
        dragMode !== "none" &&
        rowIsEmpty &&
        rowsLength - 1 === rowIndex && (
          <div className="column" style={{ textAlign: "center" }}>
            Przeciągnij i upuść elementy formularza
          </div>
        )}
    </div>
  );
};

export default RowControl;
