import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "16px",
  },
  card: {
    minWidth: 250,
    borderRadius: "1rem",
    border: `0.5px solid ${theme.palette.hr.primary}`,
    boxShadow: "none",
  },
  mainGrid: {
    justifyContent: "center",
  },
  hr: {
    opacity: "0.5",
    margin: "2.5em 20px 2.5em 60px",
    border: `0.5px solid ${theme.palette.text.whiteHeader}`,
    [theme.breakpoints.down("lg")]: {
      margin: "1.5em 0 1.5em 0",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  locationButton: {
    marginTop: "3em",
    backgroundColor: theme.palette.background.orderButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `162px`,
    height: "50px",
  },
  paper: {
    border: `0.5px solid ${theme.palette.hr.primary}`,
    padding: "0 0 32px 0",
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  text: {
    color: theme.palette.passwordIcon.primary,
    fontSize: theme.typography.paragraph,
    letterSpacing: "1.5px",
    fontWeight: "700",
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingSmall: {
    padding: "12px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  boxLoginTitle: {
    background: theme.palette.background.loginBackground,
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
  },
  formHint: {
    fontSize: theme.typography.hintText || 12,
    color: theme.palette.text.hintText,
    letterSpacing: "0.7px",
    textAlign: "left",
  },
}));

const NotFound = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Paper className={classes.paper}>
            <Box
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
              className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
            >
              <Typography className={classes.loginTitle}>
                Strona nie została odnaleziona
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotFound;
