import React, { useEffect, useState, useRef } from "react";
import { Prompt, Redirect, useHistory, NavLink, Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Can from "../../components/can";
import {
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  TableBody,
  TextField,
  Typography,
  IconButton,
  withStyles,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { fetchPriceList } from "../../redux/shopping-cart/actions";
import { TestKitsAmount } from "../../components/test-kit/testAmount";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getAxiosInstance } from "../../redux/common";
import Loader from "../../components/loader";
import { MAPDoctorTitle } from "../../components/test-kit/pruchaseSummary";
import {
  editProductInCart,
  fetchShoppingCartProducts,
  removeProductInCart,
} from "../../redux/actions";
import StopIcon from "@material-ui/icons/Stop";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

const CustomTextField = withStyles((theme) => ({
  root: {
    fontFamily: "Montserrat",
    "& label.Mui-focused": {
      color: theme.palette.text.hintText,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.lockIcon.primary,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
      "& fieldset": {
        borderColor: theme.palette.lockIcon.primary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.lockIcon.primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.lockIcon.primary,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "5px 10px",
    },
  },
}))(TextField);

const CustomTableCell = withStyles((theme) => ({
  root: {
    paddingTop: "16px",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "16px",
  },
  card: {
    minWidth: 250,
    borderRadius: "1rem",
    border: `0.5px solid ${theme.palette.hr.primary}`,
    boxShadow: "none",
  },
  mainGrid: {
    justifyContent: "center",
  },
  hr: {
    opacity: "0.5",
    margin: "2.5em 20px 2.5em 60px",
    border: `0.5px solid ${theme.palette.text.whiteHeader}`,
    [theme.breakpoints.down("lg")]: {
      margin: "1.5em 0 1.5em 0",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  locationButton: {
    marginTop: "3em",
    backgroundColor: theme.palette.background.orderButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `162px`,
    height: "50px",
  },
  paper: {
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  text: {
    color: theme.palette.passwordIcon2.primary,
    fontSize: theme.typography.paragraph,
    letterSpacing: "1.5px",
    fontWeight: "700",
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  boxLoginTitle: {
    background: theme.palette.background.loginBackground,
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingMedium: {
    padding: "16px 32px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  tableCellContentAlign: {
    verticalAlign: "top",
    textAlign: "center",
  },
  tableHeader: {
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    borderBottom: "none",
  },
  tableBodyCell: {
    color: theme.palette.text.hintText,
    borderBottom: `0.5px solid ${theme.palette.lockIcon.primary}`,
    fontFamily: "Montserrat",
  },
  tableLastBodyCell: {
    border: "none",
  },
  tableBodyRemoveButton: {
    color: theme.palette.lockIcon.primary,
    letterSpacing: "0.7px",
  },
  priceText: {
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    color: theme.palette.text.alternative2,
    fontWeight: "600",
    fontSize: theme.typography.footerMobile,
    letterSpacing: "0.8px",
  },
  priceValueBox: {
    background: theme.palette.background.filter,
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    borderRadius: "9px",
  },
  priceValueText: {
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    color: theme.palette.text.alternative2,
    fontWeight: "600",
    fontSize: theme.typography.text,
  },
  incrementIcon: {
    border: `1px solid ${theme.palette.lockIcon.primary}`,
    opacity: "1",
    borderBottomLeftRadius: "0px",
    borderTopLeftRadius: "0px",
    color: theme.palette.lockIcon.primary,
    height: theme.typography.formLabel,
    width: "20px",
  },
  saveChangesButton: {
    color: theme.palette.text.hintText,
    fontSize: theme.typography.footerMobile,
  },
}));

const OrderTestKitPage = ({}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [dataChanged, setDataChanged] = useState(false);

  const [nextButtonValid, setNextButtonValid] = useState(true);

  const [productsToBuy, setProductsToBuy] = useState([]);
  const [fetchingProducts, setFetchingProducts] = useState();

  const shoppingCartProducts = useSelector((s) => s.shoppingCartProducts || []);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    if (shoppingCartProducts) {
      setFetchingProducts(true);
      fetchPackages();
    }
  }, [shoppingCartProducts]);

  const fetchPackages = async () => {
    let hearBoxTestArray = [];

    for (let i = 0; i < shoppingCartProducts.length; i++) {
      const {
        id,
        hearBoxTestId,
        amount,
        price,
        describeBy,
      } = shoppingCartProducts[i];
      const result = await getAxiosInstance().get(
        `/api/examination_package/${shoppingCartProducts[i].hearBoxTestId}`
      );
      hearBoxTestArray.push({
        id,
        hearBoxTestId,
        amount,
        price,
        ...(describeBy && { describeBy }),
        label: result.data.label,
        desc: result.data.description,
      });
    }
    setProductsToBuy(hearBoxTestArray);
    setFetchingProducts(false);
  };

  const total = () => {
    return productsToBuy
      .map(({ price }) => price)
      .reduce((sum, i) => sum + i, 0);
  };

  const ccyFormat = (num) => {
    return `${(num / 100).toFixed(2)}`;
  };

  const amountHandler = (e, id) => {
    const value = e.target.value;
    if (value.match(/^[1-9]\d*$/)) {
      setProductsToBuy(
        productsToBuy.map((p) =>
          p.id === id
            ? {
                ...p,
                amount: value,
              }
            : p
        )
      );
      setNextButtonValid(
        shoppingCartProducts.find((p) => p.id === id).amount == e.target.value
      );
    }
  };

  const amountHandlerAction = (e, id, action) => {
    setProductsToBuy(
      productsToBuy.map((p) =>
        p.id === id
          ? {
              ...p,
              amount: action === "decrement" ? p.amount - 1 : p.amount + 1,
            }
          : p
      )
    );
    setNextButtonValid(
      shoppingCartProducts.find((p) => p.id === id).amount ===
        (action === "decrement"
          ? productsToBuy.find((p) => p.id === id).amount - 1
          : productsToBuy.find((p) => p.id === id).amount + 1)
    );
  };

  const amountClickHandler = async (value, id) => {
    try {
      const response = await dispatch(
        editProductInCart({
          amount: value,
          id,
        })
      );
      setNextButtonValid(true);
    } catch (e) {
      console.log(e);
    }
  };

  const removeItemClickHandler = async (id) => {
    try {
      const response = await dispatch(
        removeProductInCart({
          id,
        })
      );
      if (response) {
        setFetchingProducts(true);
        await dispatch(fetchShoppingCartProducts());
      }
    } catch (e) {
      console.log(e);
    }
  };

  return fetchingProducts ? (
    <Box p={1}>
      <Loader loading={true} text="Pobieranie badań w koszyku..." />
    </Box>
  ) : productsToBuy?.length !== 0 ? (
    <Box className={classes.root}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} md={10}>
          <Paper className={classes.paper}>
            <Box
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
              className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
            >
              <StopIcon style={{ height: "8px", color: "#3458A4" }} />
              <Typography className={classes.loginTitle}>Koszyk</Typography>
              <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
            </Box>
            <Box className={`${classes.paddingMedium}`}>
              <TableContainer>
                <Table className={classes.table} aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell width="35%" className={classes.tableHeader}>
                        Badania
                      </TableCell>
                      <TableCell
                        width="25%"
                        style={{ verticalAlign: "top", textAlign: "center" }}
                        className={classes.tableHeader}
                      >
                        Lekarz opisujący badanie
                      </TableCell>
                      <TableCell
                        width="15%"
                        style={{ textAlign: "center" }}
                        className={classes.tableHeader}
                      >
                        Ilość
                      </TableCell>

                      <TableCell
                        width="15%"
                        style={{ textAlign: "center" }}
                        className={classes.tableHeader}
                      >
                        Cena
                      </TableCell>
                      <TableCell
                        width="10%"
                        className={classes.tableHeader}
                        style={{ textAlign: "center" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productsToBuy?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell
                          width="35%"
                          className={classes.tableBodyCell}
                        >
                          {row.label}
                        </TableCell>
                        <TableCell
                          width="25%"
                          style={{ textAlign: "center" }}
                          className={classes.tableBodyCell}
                        >
                          {row.describeBy
                            ? MAPDoctorTitle[row.describeBy]
                            : `Bez opisu`}
                        </TableCell>
                        <TableCell
                          width="15%"
                          style={{ textAlign: "center" }}
                          className={classes.tableBodyCell}
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              disabled={row?.amount < 2}
                              onClick={(e) =>
                                amountHandlerAction(e, row.id, "decrement")
                              }
                            >
                              <RemoveOutlinedIcon
                                role="img"
                                aria-hidden="false"
                                focusable="true"
                                alt="zmniejsza wartość w koszyku o 1"
                                title="zmniejsza wartość w koszyku o 1"
                                className={classes.incrementIcon}
                              />
                            </IconButton>
                            <CustomTextField
                              style={{ width: "55px" }}
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                min: 0,
                                style: { textAlign: "center" },
                              }}
                              value={row?.amount}
                              variant="outlined"
                              onFocus={(event) => {
                                event.target.select();
                              }}
                              onChange={(e) => amountHandler(e, row.id)}
                              type="text"
                              InputProps={{
                                style: { fontFamily: "Montserrat" },
                              }}
                            />
                            <IconButton
                              onClick={(e) =>
                                amountHandlerAction(e, row.id, "increment")
                              }
                            >
                              <AddOutlinedIcon
                                style={{ transform: "rotate(180deg)" }}
                                role="img"
                                aria-hidden="false"
                                alt="zwiększa wartość w koszyku o 1"
                                title="zwiększa wartość w koszyku o 1"
                                className={classes.incrementIcon}
                              />
                            </IconButton>
                          </Box>
                          {shoppingCartProducts.find((p) => p.id === row.id)
                            .amount != row.amount && (
                            <Button
                              onClick={() =>
                                amountClickHandler(row.amount, row.id)
                              }
                              className={classes.saveChangesButton}
                            >
                              Zapisz zmiany
                            </Button>
                          )}
                        </TableCell>
                        <TableCell
                          width="15%"
                          style={{ textAlign: "center" }}
                          className={classes.tableBodyCell}
                        >
                          {`${ccyFormat(row.price)} PLN`}
                        </TableCell>
                        <TableCell
                          width="10%"
                          style={{ textAlign: "center" }}
                          className={`${classes.tableBodyCell}`}
                        >
                          <Button
                            className={`${classes.tableBodyRemoveButton}`}
                            onClick={() => removeItemClickHandler(row.id)}
                          >
                            Usuń
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}

                    <TableRow style={{ paddingTop: 20 }}>
                      <CustomTableCell className={classes.tableLastBodyCell} />
                      <CustomTableCell className={classes.tableLastBodyCell} />
                      <CustomTableCell
                        className={`${classes.tableLastBodyCell} `}
                        style={{ textAlign: "center" }}
                      >
                        <Typography className={classes.priceText}>
                          Suma łącznie
                        </Typography>
                      </CustomTableCell>
                      <CustomTableCell
                        className={classes.tableLastBodyCell}
                        style={{ textAlign: "center" }}
                      >
                        <Typography
                          className={`${classes.priceText} ${classes.priceValueBox}`}
                          style={{}}
                        >
                          <Typography className={classes.priceValueText}>
                            {" "}
                            {`${ccyFormat(total())}`}
                          </Typography>
                          <span style={{ margin: "5px" }}>PLN</span>
                        </Typography>
                      </CustomTableCell>
                      <CustomTableCell className={classes.tableLastBodyCell} />
                    </TableRow>
                    <TableRow style={{ paddingTop: 20 }}>
                      <CustomTableCell className={classes.tableLastBodyCell} />
                      <CustomTableCell className={classes.tableLastBodyCell} />
                      <CustomTableCell className={classes.tableLastBodyCell} />
                      <CustomTableCell
                        colSpan={2}
                        className={classes.tableLastBodyCell}
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          type="submit"
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => history.push("/login")}
                          disabled={!nextButtonValid}
                          style={{
                            width: "100%",
                            maxWidth: "250px",
                            borderRadius: "0px",
                            height: "50px",
                          }}
                        >
                          Dalej
                        </Button>
                      </CustomTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box pb={3} pt={1} pr={5} style={{ textAlign: "right" }}></Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box className={classes.root}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Paper className={classes.paper}>
            <Box pt={4} pb={1}>
              <Typography className={classes.text}>
                Twój koszyk jest pusty
              </Typography>
            </Box>
            <Box pb={3}>
              <Link to={"/products-to-buy"}>
                <Button
                  variant={"contained"}
                  className={classes.locationButton}
                >
                  Złóż zamówienie
                </Button>
              </Link>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OrderTestKitPage);
