import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Can from "../../components/can";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Paper,
  Box,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BillingAddressForm from "../address-form/billing-info";
import ShippingAddressForm from "../address-form/shipping-info";
import StopIcon from "@material-ui/icons/Stop";
import { ReactComponent as HBLogo } from "../../assets/hb-tmp.svg";

const useStyles = makeStyles((theme) => ({
  rootBox: {
    padding: "24px 64px",
    marginBottom: "55px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 8px",
    },
  },
  paper: {
    width: "100%",
    border: `0.5px solid ${theme.palette.hr.primary}`,

    textAlign: "left",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  mainGrid: {
    // padding: "16px",
    // justifyContent: "center",
    // paddingBottom: "72.5px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  typographyTitle: {
    color: theme.palette.text.alternative3,
    fontSize: theme.typography.header,
    textTransform: "uppercase",
    textAlign: "center",
  },
  typography: {
    color: theme.palette.text.hintText,
    fontSize: theme.typography.footerMobile,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  boxLoginTitle: {
    borderTop: "1px solid #AFAEAE",
    background: theme.palette.background.loginBackground,
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingMedium: {
    padding: "16px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingSmall: {
    padding: "16px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  checkbox: {
    color: theme.palette.lockIcon.primary,
  },
  aMain: {
    color: theme.palette.passwordIcon.primary,
    "&:hover": {
      color: theme.palette.passwordIcon.primary,
      textDecoration: "underline",
    },
    letterSpacing: "1px",
    fontWeight: "600",
  },
}));

export const TestKitPersonalData = ({
  deliveryInfo,
  setDeliveryInfo,
  billingInfo,
  setBillingInfo,
  provideShippingData,
  setProvideShippingData,
  provideFVData,
  setProvideFVData,
  saveShippingData,
  setSaveShippingData,
  saveBillingData,
  setSaveBillingData,
  setDataChanged,
  handleFinish,
  handleNext,
  handleBack,
  activeStep,
  steps,
  nextButtonValid,
}) => {
  const classes = useStyles();

  const [name, setName] = useState(deliveryInfo.name || "");
  const [street, setStreet] = useState(deliveryInfo.street || "");
  const [zipCode, setZipCode] = useState(deliveryInfo.zipCode || "");
  const [city, setCity] = useState(deliveryInfo.city || "");
  const [post, setPost] = useState(deliveryInfo.post || "");
  const [phone, setPhone] = useState(deliveryInfo.phone || "");
  const [notes, setNotes] = useState(deliveryInfo.notes || "");
  const [nameFV, setNameFV] = useState(billingInfo.nameFV || "");
  const [streetFV, setStreetFV] = useState(billingInfo.streetFV || "");
  const [zipFV, setZipFV] = useState(billingInfo.zipFV || "");
  const [cityFV, setCityFV] = useState(billingInfo.cityFV || "");
  const [taxID, setTaxID] = useState(billingInfo.taxID || "");
  const [postFV, setPostFV] = useState(billingInfo.postFV || "");

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  useEffect(() => {
    setDeliveryInfo({
      ...(name && { name }),
      ...(street && { street }),
      ...(zipCode && { zipCode }),
      ...(city && { city }),
      ...(post && { post }),
      ...(phone && { phone }),
      ...(notes && { notes }),
    });
  }, [name, street, zipCode, city, post, phone, notes]);

  useEffect(() => {
    setBillingInfo({
      ...(nameFV && { nameFV }),
      ...(streetFV && { streetFV }),
      ...(zipFV && { zipFV }),
      ...(cityFV && { cityFV }),
      ...(postFV && { postFV }),
      ...(taxID && { taxID }),
    });
  }, [nameFV, streetFV, zipFV, cityFV, postFV, taxID]);

  const NIPValidator = (nipNumber) => {
    const nipWithoutDashes = nipNumber.replace(/-/g, "");
    const reg = /^[0-9]{10}$/;
    if (reg.test(nipWithoutDashes) == false) {
      return false;
    } else {
      const digits = ("" + nipWithoutDashes).split("");
      const checksum =
        (6 * parseInt(digits[0]) +
          5 * parseInt(digits[1]) +
          7 * parseInt(digits[2]) +
          2 * parseInt(digits[3]) +
          3 * parseInt(digits[4]) +
          4 * parseInt(digits[5]) +
          5 * parseInt(digits[6]) +
          6 * parseInt(digits[7]) +
          7 * parseInt(digits[8])) %
        11;

      return parseInt(digits[9]) == checksum;
    }
  };

  return (
    <Box className={classes.rootBox}>
      <Grid
        container
        spacing={!smallScreen ? 5 : 1}
        className={classes.mainGrid}
      >
        <Grid
          item
          xs={12}
          md={8}
          lg={provideFVData ? 6 : 8}
          xl={provideFVData ? 4 : 6}
        >
          <Paper className={classes.paper}>
            {provideShippingData && (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  className={classes.paddingLarge}
                >
                  <HBLogo />
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  className={`${classes.boxLoginTitle} ${classes.paddingSmall}`}
                >
                  <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                  <Typography className={classes.loginTitle}>
                    Dane adresowe
                  </Typography>
                  <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
                </Box>

                <Box className={classes.paddingMedium}>
                  <ShippingAddressForm
                    provideShippingData={true}
                    name={name || ""}
                    setName={setName}
                    street={street}
                    setStreet={setStreet}
                    city={city}
                    setCity={setCity}
                    zipCode={zipCode}
                    setZipCode={setZipCode}
                    post={post}
                    setPost={setPost}
                    phone={phone}
                    setNotes={setNotes}
                    notes={notes}
                    setPhone={setPhone}
                    setDataChanged={setDataChanged}
                    fetchData={true}
                  />
                </Box>

                <Box
                  style={{
                    display: "block",
                    flexDirection: "column",
                    paddingRight: "64px",
                    paddingLeft: "64px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="saveShippingData"
                        className={classes.checkbox}
                        color="primary"
                        checked={saveShippingData}
                        onChange={() => {
                          setSaveShippingData(!saveShippingData);
                        }}
                      />
                    }
                    label={
                      <Typography className={classes.typography}>
                        Zapamiętaj dane adresowe
                      </Typography>
                    }
                  />
                </Box>
                <Box
                  style={{
                    display: "block",
                    flexDirection: "column",
                    paddingRight: "64px",
                    paddingLeft: "64px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        name="provideFVData"
                        color="primary"
                        checked={provideFVData}
                        onChange={() => {
                          setProvideFVData(!provideFVData);
                        }}
                      />
                    }
                    label={
                      <Typography className={classes.typography}>
                        Chcę podać dane do faktury
                      </Typography>
                    }
                  />
                </Box>
                <Box mt={2} mb={2} style={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={!nextButtonValid}
                    onClick={
                      activeStep === steps.length - 1
                        ? handleFinish
                        : handleNext
                    }
                    style={{
                      width: "100%",
                      maxWidth: "250px",
                      borderRadius: "0px",
                      height: "50px",
                    }}
                  >
                    Dalej
                  </Button>
                </Box>
                <Box mt={2} mb={2} style={{ textAlign: "center" }}>
                  <a className={classes.aMain} onClick={handleBack}>
                    Powrót
                  </a>
                </Box>
              </>
            )}{" "}
          </Paper>
        </Grid>
        {provideFVData && (
          <Grid
            item
            container
            xs={12}
            md={8}
            lg={6}
            xl={4}
            style={{ display: "block" }}
          >
            <Paper className={classes.paper}>
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  className={classes.paddingLarge}
                >
                  <HBLogo />
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  className={`${classes.boxLoginTitle} ${classes.paddingSmall}`}
                >
                  <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                  <Typography className={classes.loginTitle}>
                    Dane do faktury
                  </Typography>
                  <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
                </Box>

                <Box className={classes.paddingMedium}>
                  <BillingAddressForm
                    nameFV={nameFV}
                    setNameFV={setNameFV}
                    streetFV={streetFV}
                    setStreetFV={setStreetFV}
                    zipFV={zipFV}
                    setZipFV={setZipFV}
                    cityFV={cityFV}
                    setCityFV={setCityFV}
                    taxID={taxID}
                    setTaxID={setTaxID}
                    postFV={postFV}
                    setPostFV={setPostFV}
                    setDataChanged={setDataChanged}
                    fetchData={true}
                    NIPValidator={NIPValidator}
                  />
                </Box>

                <Box
                  style={{
                    display: "block",
                    flexDirection: "column",
                    paddingRight: "64px",
                    paddingLeft: "64px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="saveShippingData"
                        color="primary"
                        checked={saveBillingData}
                        onChange={() => {
                          setSaveBillingData(!saveBillingData);
                        }}
                      />
                    }
                    label={
                      <Typography className={classes.typography}>
                        Zapamiętaj dane do faktury
                      </Typography>
                    }
                  />{" "}
                  <Box mt={2} mb={2} style={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      color="primary"
                      disabled={!nextButtonValid}
                      onClick={
                        activeStep === steps.length - 1
                          ? handleFinish
                          : handleNext
                      }
                      style={{
                        width: "100%",
                        maxWidth: "250px",
                        borderRadius: "0px",
                        height: "50px",
                      }}
                    >
                      Dalej
                    </Button>
                  </Box>
                  <Box mt={2} mb={2} style={{ textAlign: "center" }}>
                    <a className={classes.aMain} onClick={handleBack}>
                      Powrót
                    </a>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
