export const data = {
  name: 'CSIM demo 01',
  status: 'NOK',
  ts: '2024-04-08T23:07:40.084144200Z',
  exceptions: [
    {
      code: 12001,
      name: 'ExaminationImpossible1',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible2',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible3',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible4',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible5',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible6',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible7',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossible',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
    {
      code: 12001,
      name: 'ExaminationImpossibleEND',
      exceptionTs: '2024-04-08T23:07:34.362212300Z',
      reason:
        'nie udało się utworzyć połączenia z AudioMetrem, bądź wystąpił błąd z nim związany',
      instruction:
        '0. Zresetuj przy użyciu fizycznego przycisku 1. Upewnij się, że urządzenie jest sparowane w ustawieniach Bluetooth systemu Windows. 2. Upewnij się, że urządzenie jest włączone. 3. Jeżeli urządzenie się nie włącza spróbuj je naładować.',
    },
  ],
  devicesStates: {
    ancState: 'OK',
    audioMetrState: 'OK',
    ancBattery: 55.55,
    audioMetrBattery: 3700,
    isAudioMetrBatteryBelowMinimum: 'OK',
    areHeadphonesPluggedIn: 'TRUE',
    isAncCharged: 'TRUE',
    isAudioMetrCharged: 'TRUE',
    lockState: 'OK',
    isLockClosed: 'FALSE',
    ancId: 'ID2012',
    ancVersion: '1.21',
    audioMetrId: 'SN3028',
    os: 'Windows 11',
    backendVersion: '2.12.34',
    frontendVersion: '4.22.34',
    diskSpace: '1000',
    diskSpaceUsed: '250',
  },
};
