import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import Row from './row';
import MuiTableCell from '@material-ui/core/TableCell';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  withStyles,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { PAGE_CHANGE } from '../../../../../hooks/usePagination/constants';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

const ScrollableTableContainer = withStyles((theme) => ({
  root: {
    maxHeight: '300px',
    [theme.breakpoints.up('xl')]: {
      maxHeight: '450px',
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: '250px',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '200px',
    },
  },
}))(TableContainer);

const ScrollTable = function ({ data, page, dispatchPagination, loading }) {
  const tableEl = useRef();

  const [hasMore] = useState(true);
  const [distanceBottom, setDistanceBottom] = useState(0);

  const scrollListener = useCallback(() => {
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;

    if (!distanceBottom) {
      setDistanceBottom(Math.round(bottom * 0.01));
    }
    if (
      tableEl.current.scrollTop > bottom - distanceBottom &&
      hasMore &&
      !loading
    ) {
      dispatchPagination({ type: PAGE_CHANGE, payload: page + 1 });
    }
  }, [hasMore, loading, distanceBottom, page]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef.addEventListener('scroll', scrollListener);
    return () => {
      tableRef.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  return (
    <ScrollableTableContainer ref={tableEl}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <Typography variant="subtitle2">Data</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle2">Kod</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle2">
                Techniczna nazwa błędu
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <Row key={i} row={row} page={page} />
          ))}
          {loading && (
            <TableRow>
              <TableCell colspan="4" style={{ 'text-align': 'center' }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <Box></Box>
      </Table>
      {/* <Pagination
        count={Math.ceil(data.length / rowsPerPage)}
        page={page}
        dispatchPagination={dispatchPagination}
      /> */}
    </ScrollableTableContainer>
  );
};

export default ScrollTable;
