import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import ReCAPTCHA from "react-google-recaptcha";
import { sendContact } from "../../redux/actions";
import StopIcon from "@material-ui/icons/Stop";

const WithStylesTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 32px 32px 32px",
  },
  card: {
    minWidth: 250,
    borderRadius: "1rem",
    border: `0.5px solid ${theme.palette.hr.primary}`,
    boxShadow: "none",
  },
  mainGrid: {
    justifyContent: "center",
  },
  hr: {
    opacity: "0.5",
    margin: "2.5em 20px 2.5em 60px",
    border: `0.5px solid ${theme.palette.text.whiteHeader}`,
    [theme.breakpoints.down("lg")]: {
      margin: "1.5em 0 1.5em 0",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  locationButton: {
    marginTop: "3em",
    backgroundColor: theme.palette.background.orderButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `162px`,
    height: "50px",
  },
  paper: {
    border: `0.5px solid ${theme.palette.hr.primary}`,
    padding: "0 0 32px 0",
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  text: {
    color: theme.palette.passwordIcon.primary,
    fontSize: theme.typography.paragraph,
    letterSpacing: "1.5px",
    fontWeight: "700",
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingSmall: {
    padding: "12px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  boxLoginTitle: {
    background: theme.palette.background.loginBackground,
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
  },
  formHint: {
    fontSize: theme.typography.hintText || 12,
    color: theme.palette.text.hintText,
    letterSpacing: "0.7px",
    textAlign: "left",
  },
}));

const ContactPage = ({ sendContact }) => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [messageText, setMessageText] = useState("");
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [alert, setAlert] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down(400));

  const onChange = (value) => {
    setRecaptchaValid(!!value);
  };

  useEffect(() => {
    if (mail) {
      let polishChars1 = String(mail)
        .toLowerCase()
        .normalize("NFD")
        .search(/[\u0300-\u036f]/g);
      let polishChars2 = String(mail)
        .toLowerCase()
        .normalize("NFD")
        .search(/\u0142/g);

      setEmailValid(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(mail).toLowerCase()
        ) &&
          (polishChars2 !== 0
            ? polishChars1 + polishChars2 < 0
            : polishChars1 + polishChars2 < -1)
      );
    }
  }, [mail]);

  useEffect(() => {
    setFormIsValid(name && mail && messageText && recaptchaValid);
  }, [name, mail, messageText, recaptchaValid]);

  useEffect(() => {
    if (alert) {
      setTimeout(() => setAlert(false), 3000);
    }
  }, [alert]);

  const contact = async (e) => {
    e.preventDefault();

    const contactOk = await sendContact({
      name,
      mail,
      messageText,
    });

    if (contactOk) {
      // dispatch(
      //   alertAdd({
      //     text:
      //       "Dziękujemy za skorzystanie z formularza kontaktowego. Odpowiadamy w ciągu 24 godzin",
      //   })
      // );
      history.push("/message-sent");
    } else {
      setAlert(true);
    }
  };

  return (
    <Box className={classes.root}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12}>
          <hr className={classes.hr} />
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Paper className={classes.paper}>
            <Box
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
              className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
            >
              <StopIcon style={{ height: "8px", color: "#3458A4" }} />{" "}
              <Typography className={classes.loginTitle}>
                FORMULARZ KONTAKTOWY
              </Typography>
              <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
            </Box>
            {alert && (
              <Box style={{ padding: "12px" }}>
                <Alert severity="error">Nie udało się wysłać wiadomości</Alert>
              </Box>
            )}
            <Box className={`${classes.paddingSmall}`}>
              <Box display="flex" mt={1}>
                <TextField
                  fullWidth
                  label="* Imię i nazwisko"
                  value={name || ""}
                  onChange={(e) => setName(e.target.value)}
                  inputProps={{
                    "aria-label": "Imię i nazwisko",
                  }}
                />
              </Box>
              <Box display="flex" mt={1}>
                <TextField
                  label="* E-mail"
                  // variant="outlined"
                  value={mail}
                  error={mail && !emailValid}
                  helperText={
                    mail &&
                    !emailValid &&
                    "Nieprawidłowy format wprowadzonego adresu email"
                  }
                  fullWidth
                  onChange={(e) => setMail(e.target.value)}
                  inputProps={{ "aria-label": "E-mail" }}
                />
              </Box>
              <Box display="flex" mt={2}>
                <WithStylesTextField
                  label="* Treść"
                  variant="outlined"
                  value={messageText}
                  fullWidth
                  multiline
                  rows={10}
                  rowsMax={10}
                  onChange={(e) => setMessageText(e.target.value)}
                  inputProps={{ "aria-label": "Treść wiadomości" }}
                />
              </Box>
              <Box m={1}>
                <Typography className={classes.formHint}>
                  {"* Pola wymagane"}
                </Typography>
              </Box>
              <Box>
                <ReCAPTCHA
                  style={{ transform: "scale(0.77)", transformOrigin: "0 0" }}
                  sitekey="6LcDZv0pAAAAANRDhkLcoavTotAsRukQzKLZd06k"
                  onChange={onChange}
                />
              </Box>
              <Box mt={3} style={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ height: 40, width: 150, borderRadius: 0 }}
                  disabled={!formIsValid || !emailValid}
                  onClick={contact}
                >
                  Wyślij
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  sendContact: (data) => dispatch(sendContact(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
