import React from "react";
import {
  Box,
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import FilterProperty from "../../components/filter-property/filter-property";
import {
  activateForm,
  alertAdd,
  duplicateForm,
  fetchForms,
  removeForm,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import SingleForm from "../../pages/FormsPage/single-form";
import Loader from "../../components/loader";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  table: {
    minWidth: 650,
  },
  stickyRow: {
    position: "sticky",
    top: "0px",
    background: "white",
    zIndex: "100",
  },
  contextFlexContainer: {
    display: "flex",
    gap: "15px",
  },
  tableContainer: {
    flexGrow: 1,
    minHeight: "1px",
    // overflow: "auto",
  },
  table: {
    height: "100%",
    overflow: "auto",
  },
}));

const FormsAdminPanel = () => {
  const classes = useStyles();
  const history = useHistory();

  const [filterQueries, setFilterQueries] = useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("label");

  const [busy, setBusy] = useState(false);

  const forms = useSelector((s) => s.orgForms);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        setBusy(true);
        await dispatch(
          fetchForms({
            page: 0,
            pageSize: 100,
          })
        );
        setBusy(false);
      } catch (e) {
        setBusy(false);
        dispatch(
          alertAdd({
            text: "Wystąpił błąd - Dane nie zostały pobrane",
            isError: true,
          })
        );
      }
    })();
  }, []);

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const remove = (id) => {
    const r = dispatch(removeForm(id));
  };

  const edit = (id) => {
    history.push(`/admin-panel/surveys/edit/${id}`);
  };

  const display = (id) => {
    history.push(`/admin-panel/surveys/display/${id}`);
  };

  const headerCells = [
    {
      title: "Nazwa",
      key: "label",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    // {
    //   title: "Project",
    //   key: "projectName",
    //   sortable: true,
    //   filter: (key) => (
    //     <FilterProperty
    //       filterProperty={key}
    //       setFilterQueries={setFilterQueries}
    //       filterQueries={filterQueries}
    //     />
    //   ),
    // },

    {
      title: "Odpowiedzi",
      key: "answerCount",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Opcje",
      key: "options",
      sortable: false,

      filter: (key) => <></>,
    },
  ];

  return (
    <Box className={classes.rootContainer}>
      {" "}
      <NavLink to="/admin-panel/surveys/add">
        <Button variant={"contained"}>Dodaj nową ankietę</Button>
      </NavLink>
      <Box pt={3} className={classes.tableContainer}>
        {busy ? (
          <Loader loading={true} text="Pobieranie ankiet" />
        ) : (
          <TableContainer component={Paper} className={classes.table}>
            <Table className={classes.table} aria-label="Test stands list">
              <TableHead>
                <TableRow>
                  {headerCells.map((headCell) =>
                    headCell.sortable ? (
                      <TableCell
                        key={headCell.key}
                        // sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <Tooltip title="Sortuj">
                          <TableSortLabel
                            active={orderBy === headCell.key}
                            direction={orderBy === headCell.key ? order : "asc"}
                            onClick={() => {
                              handleSortRequest(headCell.key);
                            }}
                          >
                            {headCell.title}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    ) : (
                      <TableCell>{headCell.title}</TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {forms.map((form, index) => {
                  return (
                    <SingleForm
                      filledFormsDisplay={false}
                      key={index}
                      form={form}
                      remove={remove}
                      edit={edit}
                      display={display}
                      activateForm={(form) => dispatch(activateForm(form))}
                      duplicateForm={(id) => dispatch(duplicateForm(id))}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  );
};

export default FormsAdminPanel;
