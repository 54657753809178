import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Tooltip,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ResultsMapper from "./results-mapper";
import { getAxiosInstance } from "../../redux/common";
import TestDescription, { testName } from "./test-name-description";
import UnfinishedTestInfo from "./unfinished-test-info";
import Can from "../../components/can";
import format from "date-fns/format";
import StopIcon from "@material-ui/icons/Stop";
import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import { ReactComponent as CloseIconYellow } from "../../assets/close-icon-yellow.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { lightTheme } from "../../theme";
import GeneratePDF from "./generate-pdf";
import SurveyResultsModal from "../survey-results-modal";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "16px",
  },
  root2: {
    padding: "16px",
  },
  paper: {
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "2px 6px 23px -10px rgba(66, 68, 90, 1)",
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  boxLoginTitle: {
    background: theme.palette.background.loginBackground,
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingMedium: {
    padding: "16px 32px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  tableHeader: {
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    borderBottom: `1px solid ${theme.palette.background.menuItemBorderBottom}`,
    letterSpacing: "0.7px",
  },
  table: {
    //minWidth: 1250,
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
  },

  color: {
    color: theme.palette.text.hintText,
  },
  patientDataFont: {
    letterSpacing: "1px",
    lineHeight: `${theme.typography.formLabel}px`,
    fontSize: theme.typography.footer,
    fontWeight: "bold",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  tableBodyCell: {
    color: theme.palette.text.hintText,
    borderBottom: `0.5px solid ${theme.palette.lockIcon.primary}`,
    fontFamily: "Montserrat",
  },
  tableBodyText: {
    fontFamily: "Montserrat",
  },
  tableDescButton: {
    color: theme.palette.text.alternative2,
    fontWeight: "bold",
    letterSpacing: "0.7px",
    fontSize: theme.typography.footerMobile,
    "&:hover": {
      color: theme.palette.lockIcon.primary,
    },
  },
  widthBox: {
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  grey: {
    color: theme.palette.text.hintText,
    fontWeight: theme.typography.fontWeightMedium,
  },
  tableHeaderPerExamination: {
    color: theme.palette.text.alternative2,
    fontSize: theme.typography.footerMobile,
    fontWeight: "bold",
  },
  leftIcon: {
    color: theme.palette.text.alternative2,
    height: "8px",
  },
  rightIcon: {
    color: theme.palette.primary.main,
    height: "8px",
  },
  editionButton: {
    padding: "0px 16px",
    borderRadius: "20px",
    letterSpacing: "0.5px",
    background: theme.palette.lockIcon.primary,
    color: theme.palette.background.default,
    fontSize: theme.typography.footerMobile,
    "&:hover": {
      background: theme.palette.passwordIcon.primary,
    },
  },
}));

const stringForMaxExceeded = "maxExceeded";
export const resultToTextCAPD = (result) => {
  switch (result) {
    case "OK":
      return "w granicach normy";
    case "NOK":
      return "nieprawidłowy";
    case "FANC":
      return "wystąpiły zakłócenia";
    case "FP":
      return "fałszywe kliknięcia Pacjenta";
    case null:
      return "brak danych";
    default:
      return "";
  }
};
export const ResultToTextCAPD = ({ result, capd, percent }) => {
  const isLightTheme =
    useTheme().palette.primary.main === lightTheme.palette.primary.main;
  const classes = useStyles();
  switch (result) {
    case "OK":
      return (
        <Typography
          component="span"
          className={classes.grey}
          style={{
            color: isLightTheme ? "#2EAB8D" : "#ffff00",
            textTransform: "uppercase",
          }}
        >
          {percent ? ` ${percent}%` : ""}
          {capd ? " w granicach normy" : " prawidłowy"}
        </Typography>
      );
    case "NOK":
      return (
        <Typography
          component="span"
          className={classes.grey}
          style={{
            color: isLightTheme ? "#CF1616" : "#ffff00",
            textTransform: "uppercase",
          }}
        >
          {percent ? ` ${percent}% ` : " "}
          nieprawidłowy
        </Typography>
      );
    case "FANC":
      return (
        <Typography component="span" className={classes.grey}>
          {percent ? ` ${percent}% ` : " "}
          wystąpiły zakłócenia
        </Typography>
      );
    case "FP":
      return (
        <Typography component="span" className={classes.grey}>
          {percent ? ` ${percent}% ` : " "}
          fałszywe kliknięcia Pacjenta
        </Typography>
      );
    default:
      return "";
  }
};

export function resultToText(result) {
  switch (result) {
    case "OK":
      return "prawidłowy";
    case "NOK":
      return "nieprawidłowy";
    case "FANC":
      return "wystąpiły zakłócenia";
    case "FP":
      return "fałszywe kliknięcia Pacjenta";
    case null:
      return "brak danych";
    default:
      return "";
  }
}

export function countRightAnswers(tries) {
  var score = 0;
  tries.forEach((attempt) => {
    attempt.isCorrect && score++;
  });
  return score;
}

export function countRightAnswersPercent(tries) {
  if (tries.length === 0) {
    return "0";
  }
  return ((countRightAnswers(tries) * 100) / tries.length).toFixed(0);
}

export const formatStartAndFinishDate = (startTs, finishTs) => {
  {
    return startTs
      ? `${format(new Date(startTs), "dd-MM-yyyy")} ${format(
          new Date(startTs),
          "HH:mm"
        )} ${finishTs ? ` - ${format(new Date(finishTs), "HH:mm")}` : ""}`
      : "---";
  }
};

function isThresholdResultsEmpty(frequenciesResult) {
  return frequenciesResult.every(({ answer }) => answer === "");
}

const styles = (theme) => ({
  tooltip: {
    borderRadius: "10px",
    opacity: "0.5",
    boxShadow: "0px 2px 5px #BEBEBE",
    backgroundColor: theme.palette.background.default,
    padding: "16px",
    fontSize: theme.typography.desc,
    fontFamily: "Raleway",
    color: theme.palette.text.hintText,
    maxWidth: "150px",
    textAlign: "center",
  },
});

const CustomTooltip = withStyles(styles)(Tooltip);

export const ResultWithTooltip = ({
  result,
  earlyFinished,
  percent,
  capd = false,
  bothEars = false,
}) => {
  if (earlyFinished !== null) {
    return (
      <CustomTooltip title="Wynik niejednoznaczny z powodu przedwczesnego zakończenia badania.">
        <span>
          {`${percent !== undefined && percent !== null ? `${percent}%` : ""}
        ${capd ? `(${resultToTextCAPD(result)})` : `${resultToText(result)}`}
        ${bothEars ? " - Test obuuszny " : ""}  
      ${earlyFinished !== null ? "(niedokończone)" : ""}
      `}
        </span>
      </CustomTooltip>
    );
  } else {
    return `${percent !== undefined && percent !== null ? `${percent}%` : ""}
        ${capd ? `(${resultToTextCAPD(result)})` : `${resultToText(result)}`}
        ${bothEars ? " - Test obuuszny " : ""}  
      ${earlyFinished !== null ? "(niedokończone)" : ""}
      `;
  }
};

export const CombinedResult = ({ result, text, percent, capd = true }) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography component="span" align="center" className={classes.grey}>
        {text}
      </Typography>
      <ResultToTextCAPD
        result={result}
        grey={false}
        capd={capd}
        percent={percent}
      />
    </Box>
  );
};

const GeneralResults = ({ testType, testData, failureReason }) => {
  const classes = useStyles();

  if (!testData) {
    if (
      failureReason === "CANCELLED" ||
      failureReason === "INACTIVITY_LOGOUT"
    ) {
      return (
        <TableCell
          align="center"
          colSpan={2}
          className={classes.tableBodyCell}
          style={{ textAlign: "center" }}
        >
          <UnfinishedTestInfo />
        </TableCell>
      );
    } else {
      return (
        <TableCell
          align="center"
          colSpan={2}
          className={classes.tableBodyCell}
          style={{ textAlign: "center" }}
        >
          Nie można wyświetlić wyniku
        </TableCell>
      );
    }
  }
  if (testType === "AP" || testType === "GC") {
    return [
      <TableCell
        align="center"
        key={1}
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        <ResultWithTooltip
          result={testData.resultRight}
          earlyFinished={testData.earlyFinishedEnum}
        />
      </TableCell>,
      <TableCell
        align="center"
        key={2}
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        <ResultWithTooltip
          result={testData.resultLeft}
          earlyFinished={testData.earlyFinishedEnum}
        />
      </TableCell>,
    ];
  } else if (["AS", "TRC", "TRS"].includes(testType)) {
    return [
      <TableCell
        align="center"
        key={1}
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        <ResultWithTooltip
          result={testData.resultRight}
          earlyFinished={testData.earlyFinishedEnum}
          percent={testData.correctPercentRight}
          capd={true}
        />
      </TableCell>,
      <TableCell
        align="center"
        key={2}
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        <ResultWithTooltip
          result={testData.resultLeft}
          earlyFinished={testData.earlyFinishedEnum}
          percent={testData.correctPercentLeft}
          capd={true}
        />
      </TableCell>,
    ];
  } else if (
    ["TDW", "TSW", "TSD", "TPR", "TRMS", "TMS", "TMF"].includes(testType)
  ) {
    return (
      <TableCell
        align="center"
        colSpan={2}
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        <ResultWithTooltip
          result={testData.result}
          earlyFinished={testData.earlyFinishedEnum}
          percent={testData.correctPercent}
          capd={true}
          bothEars={true}
        />
      </TableCell>
    );
  } else {
    return null;
  }
};

const ResultsRow = ({
  testData,
  handleChangeDisplayedResults,
  forDocument = false,
}) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.tableBodyCell}>
        {testName(testData.testType, testData.data?.forKids)}
      </TableCell>
      <GeneralResults
        testType={testData.testType}
        testData={testData.data}
        failureReason={testData.failureReason}
      />
      {!forDocument && (
        <TableCell align="center" className={classes.tableBodyCell}>
          <Box whiteSpace="nowrap">
            <TestDescription
              type={testData.testType}
              forKids={testData.data?.forKids}
            />
            {testData.data && (
              <Button
                className={`${classes.tableDescButton}`}
                onClick={() => handleChangeDisplayedResults(testData)}
              >
                szczegóły
              </Button>
            )}
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};

const ResultsTable = ({
  data,
  doctorDescription,
  pdfData,
  downloading,
  handleDownloadPdfClick,
  handleChangeDisplayedResults,
}) => {
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-label="spanning table"
        size={smallScreen ? "small" : "medium"}
      >
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Test</TableCell>
            <TableCell
              align="center"
              className={classes.tableHeader}
              style={{ textAlign: "center" }}
            >
              Wynik ucha prawego
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeader}
              style={{ textAlign: "center" }}
            >
              Wynik ucha lewego
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeader}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.testsData.map((testData, index) => (
            <ResultsRow
              key={index}
              testData={testData}
              handleChangeDisplayedResults={handleChangeDisplayedResults}
            />
          ))}
          {doctorDescription && (
            <TableRow>
              <TableCell className={classes.tableBodyCell}>
                Opis badania
              </TableCell>
              <TableCell
                colSpan={2}
                align="center"
                className={classes.tableBodyCell}
                style={{ textAlign: "center" }}
              >
                {doctorDescription}
              </TableCell>
              <TableCell align="center" className={classes.tableBodyCell}>
                {pdfData ? (
                  <div align="center">
                    <Button
                      style={{ margin: "auto" }}
                      disabled={downloading}
                      className={classes.tableDescButton}
                      onClick={() => handleDownloadPdfClick()}
                      endIcon={
                        downloading ? <CircularProgress size={24} /> : ""
                      }
                    >
                      {downloading ? "Pobieranie" : "Pobierz PDF"}
                    </Button>
                  </div>
                ) : (
                  pdfData === null && (
                    <CustomTooltip title="Nie można pobrać dokumentu.">
                      <div align="center">
                        <Button
                          style={{ margin: "auto" }}
                          disabled={true}
                          className={classes.tableDescButton}
                        >
                          Pobierz
                        </Button>
                      </div>
                    </CustomTooltip>
                  )
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ResultsPerPackageTable = () => {
  const isLightTheme =
    useTheme().palette.primary.main === lightTheme.palette.primary.main;
  const classes = useStyles();
  const history = useHistory();
  const [params, setParams] = useState();
  const [data, setData] = useState();
  const [patient, setPatient] = useState();
  const [doctorDescription, setDoctorDescription] = useState("");
  const [displayDetailedResult, setDisplayDetailedResult] = useState();
  const [downloading, setDownloading] = useState(false);
  const [pdfData, setPdfData] = useState(undefined);
  const pdfRef = useRef();
  const audiometry = useMemo(
    () => data?.testsData?.find(({ testType }) => testType === "AP"),
    [data]
  );

  const [openSurveyModal, setOpenSurveyModal] = useState();

  const handleExitClick = () => {
    history.goBack();
  };

  const handleDownloadPdfClick = async () => {
    setDownloading(true);
    await pdfRef.current.downloadPDF();
    setDownloading(false);
  };

  useEffect(() => {
    if (!history.location.state) {
      history.push({ pathname: "/results" });
    } else {
      setParams(history.location.state);
    }
  }, []);

  useEffect(() => {
    if (
      params &&
      params.patientScreeningId &&
      params.hearBoxScreeningId &&
      params.hearBoxTestId
    ) {
      if (params.id) {
        getAxiosInstance()
          .get(`/patient/v1/screening/completed/protegee/entry`, {
            params: {
              patientScreeningId: params.patientScreeningId,
              hearBoxScreeningId: params.hearBoxScreeningId,
              id: params.id,
            },
          })
          .then((result) => {
            setPatient(result.data.header.patient);
            setData(result.data.data);
          });
        if (params.withDescription) {
          getAxiosInstance()
            .get(`/patient/v1/screening/completed/description/protegee/entry`, {
              params: {
                patientScreeningId: params.patientScreeningId,
                hearBoxTestId: params.hearBoxTestId,
                id: params.id,
              },
            })
            .then((result) => {
              setDoctorDescription(result.data.doctorDescription);
            })
            .catch(() => {});
          getAxiosInstance()
            .get(`/api/test_description/${params.hearBoxScreeningId}`)
            .then((result) => {
              setPdfData(result.data.testData);
            })
            .catch(() => setPdfData(null));
        }
      } else {
        getAxiosInstance()
          .get(`/patient/v1/screening/completed/entry`, {
            params: {
              patientScreeningId: params.patientScreeningId,
              hearBoxScreeningId: params.hearBoxScreeningId,
            },
          })
          .then((result) => {
            setPatient(result.data.header.patient);
            setData(result.data.data);
          });
        if (params.withDescription) {
          getAxiosInstance()
            .get(`/patient/v1/screening/completed/description/entry`, {
              params: {
                patientScreeningId: params.patientScreeningId,
                hearBoxTestId: params.hearBoxTestId,
              },
            })
            .then((result) => {
              setDoctorDescription(result.data.doctorDescription);
            })
            .catch(() => {});
          getAxiosInstance()
            .get(`/api/test_description/${params.hearBoxScreeningId}`)
            .then((result) => {
              setPdfData(result.data.testData);
            })
            .catch(() => setPdfData(null));
        }
      }
    }
  }, [params]);

  if (!data || !patient) {
    return null;
  }

  return displayDetailedResult ? (
    <Box>
      <ResultsMapper
        test={displayDetailedResult}
        audiometry={audiometry}
        handleChangeDisplayedResults={setDisplayDetailedResult}
      />
      {doctorDescription && pdfData && (
        <GeneratePDF
          ref={pdfRef}
          data={pdfData.data}
          doctorDescription={pdfData.doctorDescription}
          patient={pdfData.patient}
          result={pdfData.result}
        />
      )}
    </Box>
  ) : (
    <Box>
      <Box className={classes.root}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} md={10}>
            <Paper className={classes.paper}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
              >
                <CloseIcon style={{ visibility: "hidden" }} />
                <StopIcon className={classes.leftIcon} />
                <Typography className={classes.loginTitle}>
                  Wyniki szczegółowe
                </Typography>
                <StopIcon className={classes.rightIcon} />
                {isLightTheme ? (
                  <CloseIcon onClick={() => handleExitClick()} />
                ) : (
                  <CloseIconYellow onClick={() => handleExitClick()} />
                )}
              </Box>
              <Box className={`${classes.paddingMedium}`}>
                <Grid container style={{ width: "100%" }}>
                  <Grid item>
                    <Typography
                      className={classes.patientDataFont}
                      style={{ marginLeft: 16 }}
                    >{`${
                      `${patient?.firstName} ${patient?.lastName}` || "---"
                    } ${formatStartAndFinishDate(
                      data.startTs,
                      data.finishTs
                    )}`}</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box className={`${classes.paddingMedium}`}>
                <Grid container style={{ width: "100%" }}>
                  <Grid item>
                    <Button
                      variant="contained"
                      className={classes.editionButton}
                      onClick={() => setOpenSurveyModal(true)}
                    >
                      Wypełnione ankiety
                    </Button>{" "}
                  </Grid>
                </Grid>
              </Box>
              <Box className={`${classes.paddingMedium}`}>
                <ResultsTable
                  data={data}
                  doctorDescription={doctorDescription}
                  pdfData={pdfData}
                  downloading={downloading}
                  handleDownloadPdfClick={handleDownloadPdfClick}
                  handleChangeDisplayedResults={setDisplayDetailedResult}
                />
                <br />
                <br />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    variant={"contained"}
                    color="primary"
                    style={{
                      borderRadius: "0px",
                      height: "40px",
                      width: "150px",
                    }}
                    onClick={() => handleExitClick()}
                  >
                    zamknij
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <SurveyResultsModal
        handleClose={() => setOpenSurveyModal(false)}
        open={openSurveyModal}
        params={params}
        hearBoxTestId={params.hearBoxTestId}
        hearBoxScreeningId={params.hearBoxScreeningId}
        patientScreeningId={params.patientScreeningId}
      />
      {doctorDescription && pdfData && (
        <GeneratePDF
          ref={pdfRef}
          data={pdfData.data}
          doctorDescription={pdfData.doctorDescription}
          patient={pdfData.patient}
          result={pdfData.result}
        />
      )}
    </Box>
  );
};

const ResultsPerPackagePage = () => {
  return (
    <Can
      permission="results-page:view"
      ok={() => <ResultsPerPackageTable />}
      not={() => <Redirect to="/" />}
    />
  );
};

export default ResultsPerPackagePage;
export { ResultsRow };
