import React from "react";
import {
  Box,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Grid,
} from "@material-ui/core";
import { useStyles } from "./results-per-package-table";
import { AudiogramThreshold } from "./audiogram";
import { CombinedResult } from "./results-per-package-table";
import DateAndResultGrid from "./DateAndResultGrid";
import { useTheme } from "@material-ui/core/styles";
import { lightTheme } from "../../theme";

const ThresholdAudiometryResults = ({ audiometry, basicAudiometry }) => {
  const isLightTheme =
    useTheme().palette.primary.main === lightTheme.palette.primary.main;
  const classes = useStyles();
  const stringForMaxExceeded = "maxExceeded";
  function resultToText(result) {
    switch (result) {
      case "OK":
        return "PRAWIDŁOWY";
      case "NOK":
        return "NIEPRAWIDŁOWY";
      case "FANC":
        return "wystąpiły zakłócenia";
      case "FP":
        return "fałszywe kliknięcia Pacjenta";
      case null:
        return "brak danych";
      default:
        return result;
    }
  }

  function calculateFalseClicksRatePerFreq(audiometryDetails, frequency) {
    const falseClicksCount = audiometryDetails.find(
      (details) => details.frequency === frequency
    )?.falseClicksCount;
    const allClicksCount = audiometryDetails.find(
      (details) => details.frequency === frequency
    )?.allClicksCount;
    const rate =
      falseClicksCount !== undefined &&
      allClicksCount !== undefined &&
      falseClicksCount !== 0 &&
      allClicksCount !== 0
        ? ((falseClicksCount / allClicksCount) * 100).toFixed()
        : 0;
    return rate;
  }

  const resultsPerEar = (ear) => {
    return (
      <Box className={`${classes.boxLoginTitle} ${classes.paddingMedium}`}>
        <Typography
          align="center"
          style={{
            color: (theme) => theme.palette.text.alternative2,
          }}
        >{`UCHO ${ear === "right" ? "PRAWE" : "LEWE"}`}</Typography>
        <TableContainer style={{ maxWidth: "400px", display: "inline-block" }}>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography className={classes.tableHeaderPerExamination}>
                    częstotliwość [Hz]
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    align="center"
                    className={classes.tableHeaderPerExamination}
                  >
                    próg słyszenia [dB HL]
                  </Typography>
                </TableCell>
                {audiometry.data.forKids && (
                  <TableCell
                    align="center"
                    className={classes.tableHeaderPerExamination}
                  >
                    <Typography>odsetek fałszywych kliknięć [%]</Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                ...(ear === "right"
                  ? audiometry.data.frequenciesResultRight
                  : audiometry.data.frequenciesResultLeft),
              ]
                .sort((a, b) => (a.frequency > b.frequency ? 1 : -1))
                .map(({ frequency, answer }) => (
                  <TableRow key={frequency}>
                    <TableCell className={classes.grey}>
                      <Typography>{frequency}</Typography>
                    </TableCell>
                    <TableCell className={classes.grey}>
                      <Typography
                        align="center"
                        style={{
                          color:
                            answer === stringForMaxExceeded
                              ? isLightTheme
                                ? "red"
                                : "yellow"
                              : "",
                        }}
                      >
                        {answer !== stringForMaxExceeded
                          ? resultToText(answer)
                          : "!"}
                      </Typography>
                    </TableCell>
                    {audiometry.data.forKids && (
                      <TableCell className={classes.grey} align="center">
                        <Typography>
                          {calculateFalseClicksRatePerFreq(
                            ear === "right"
                              ? audiometry.data.audiometryDetailsListRight
                              : audiometry.data.audiometryDetailsListLeft,
                            frequency
                          )}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  if (!audiometry.data) {
    return null;
  }
  return (
    <Box>
      <DateAndResultGrid
        startDate={audiometry.startDate}
        endDate={audiometry.endDate}
      >
        <CombinedResult
          text="Wynik ucha prawego:"
          result={audiometry.data.resultRight}
          capd={false}
        />
        <CombinedResult
          text="Wynik ucha lewego:"
          result={audiometry.data.resultLeft}
          capd={false}
        />
        <Box>
          {audiometry.data.earlyFinishedEnum && (
            <Typography
              component="span"
              align="center"
              className={classes.grey}
            >
              Badanie niedokończone
            </Typography>
          )}
          {audiometry.failureReason === "FANC" && (
            <Typography
              component="span"
              align="center"
              className={classes.grey}
            >
              Podczas badania wystąpiły zakłócenia
            </Typography>
          )}
        </Box>
      </DateAndResultGrid>
      <br />
      <Grid
        container
        spacing={2}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <AudiogramThreshold
            audiometry={audiometry.data}
            basicAudiometry={basicAudiometry?.data}
          />
        </Grid>
        <Grid
          container
          item
          spacing={2}
          direction="row"
          justify="center"
          style={{ mb: 2 }}
        >
          <Grid item>{resultsPerEar("right")}</Grid>
          <Grid item>{resultsPerEar("left")}</Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ThresholdAudiometryResults;
