import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  alertAdd,
  fetchForm,
  fetchFormTemplate,
  saveForm,
  updateForm,
} from "../../redux/actions";
import FormEditor from "../../components/form-editor/form-editor";
import { Prompt, Redirect } from "react-router-dom";
import Can from "../../components/can";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RuleEditor } from "../../components/rule-editor";
import { NotificationReminder } from "../../components/notification-reminder";
import s from "./index.module.css";
import { ColorBox } from "../../components/color-box";
import PageTitle from "../../components/page-title";
import ScheduleFormPage from "../schedule-form-page";
import Box from "@material-ui/core/Box";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Radio,
  Button,
  FormControlLabel,
  Grid,
  RadioGroup,
  TextField,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { FormFieldChecks } from "../../FormFieldChecks";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import Tooltip from "@material-ui/core/Tooltip";
import { ArrowDropUp } from "@material-ui/icons";
import { MessageWelcomeFarewell } from "../../components/form-message-welcome-farewell";

const loToRows = (formData) => {
  return formData.layoutElementObject.children.map((c) => {
    return c.children.map((c) => {
      return c;
    });
  });
};

const OPERATORS = {
  GREATER_THAN: "większa niż",
  LESS_THAN: "mniejsza niż",
  EQUALS: "równa",
  BETWEEN: "pomiędzy",
}; // TODO - extract

const getTimezoneOffset = (date) => {
  return date.getTimezoneOffset() * 60_000;
};

const actualDate = new Date();
const defaultStartDateString = new Date(
  startOfDay(actualDate) - getTimezoneOffset(actualDate)
)
  .toISOString()
  .slice(0, -1);
const defaultEndDateString = new Date(
  endOfDay(actualDate) - getTimezoneOffset(actualDate)
)
  .toISOString()
  .slice(0, -1);

const days = [
  { key: "EVERYDAY", value: "Codziennie" },
  { key: "MONDAY", value: "Poniedziałek" },
  { key: "TUESDAY", value: "Wtorek" },
  { key: "WEDNESDAY", value: "Środa" },
  { key: "THURSDAY", value: "Czwartek" },
  { key: "FRIDAY", value: "Piątek" },
  { key: "SATURDAY", value: "Sobota" },
  { key: "SUNDAY", value: "Niedziela" },
];

const AddNewFormPage = ({
  saveForm,
  updateForm,
  history,
  match: {
    params: { id, scheduledFormId },
  },
  fetchForm,
  orgForms,
  fetchFormTemplate,
  existingFormData,
  alertAdd,
}) => {
  const [fetching, setFetching] = useState();
  const [formData, setFormData] = useState();
  const [addMode, setAddMode] = useState();
  const [displayMode, setdDisplayMode] = useState(false);
  const [formMetadata, setFormMetadata] = useState();
  const [initialRows, setInitialRows] = useState();
  const [name, setName] = useState("");
  const [ageCategory, setAgeCategory] = useState("Adult");
  const [isObligatory, setIsObligatory] = useState(true);
  const [description, setDescription] = useState("");
  const [rules, setRules] = useState([]);
  const [schedule, setSchedule] = useState({});
  const [showNewRule, setShowNewRule] = useState(false);
  const [formEdited, isFormEdited] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [allowDrafting, setAllowDrafting] = useState(false);
  const [defaultAssignmentDuration, setDefaultAssignmentDuration] = useState(
    parseInt(process.env.REACT_APP_DEFAULT_ASSIGNMENT_DURATION, 10)
  );
  const [welcomeFarewellMessages, setWelcomeFarewellMessages] = useState({
    welcomeSmsMessage: "",
    welcomeEmailMessage: "",
    farewellSmsMessage: "",
    farewellEmailMessage: "",
  });

  const [editedRule, setEditedRule] = useState({});
  const [timeBeforeEnd, setTimeBeforeEnd] = useState([]);
  const [notification, setNotification] = useState([]);
  const [timesToSend, setTimesToSend] = useState("1");

  const canAddForm = useSelector((s) => s.organization?.editorsCanManageForms);

  const roles = useSelector((s) => s.roles);

  useEffect(() => {
    schedule.timePeriods ||
      setSchedule((prevState) => ({ ...prevState, timePeriods: [] }));
    schedule.startDate ||
      setSchedule((prevState) => ({
        ...prevState,
        startDate: defaultStartDateString,
      }));
    schedule.endDate ||
      setSchedule((prevState) => ({
        ...prevState,
        endDate: defaultEndDateString,
      }));
    schedule.selectedDays ||
      setSchedule((prevState) => ({ ...prevState, selectedDays: [] }));
  }, [schedule]);

  useEffect(() => {
    setAddMode(id === undefined);
  }, [id]);

  useEffect(() => {
    setdDisplayMode(window.location.pathname.includes("display"));
    // if (
    //   !window.location.pathname.includes("display") &&
    //   !roles.find((r) => r.includes("ROLE_OWNER"))
    // ) {
    //   if (!canAddForm) {
    //     history.push("/");
    //   }
    // }
  }, [canAddForm]);

  useEffect(() => {
    if (formMetadata) {
      if (formMetadata.formRules) {
        setRules(
          formMetadata.formRules
            ? formMetadata.formRules.map((r) =>
                !r.smsPhone
                  ? { ...r, smsPhone: formMetadata.formGroup?.smsPhone }
                  : r.smsPhone[0] !== "+"
                  ? { ...r, smsPhone: "+" + r.smsPhone }
                  : { ...r }
              )
            : []
        );
      }
      // setRules(formMetadata.formRules || []);

      setAllowDrafting(Boolean(formMetadata.allowDrafting));
      setDefaultAssignmentDuration(formMetadata.defaultAssignmentDuration);

      setWelcomeFarewellMessages({
        welcomeSmsMessage: formMetadata.welcomeSmsMessage || "",
        welcomeEmailMessage: formMetadata.welcomeEmailMessage || "",
        farewellSmsMessage: formMetadata.farewellSmsMessage || "",
        farewellEmailMessage: formMetadata.farewellEmailMessage || "",
      });
    }
  }, [formMetadata]);

  useEffect(() => {
    const invalidFields =
      formData &&
      formData
        .map((formFieldsInRow) =>
          formFieldsInRow.find(
            (formField) =>
              FormFieldChecks.isNumberWithNoType(formField) ||
              FormFieldChecks.isNumberWithInvertedRanges(formField) ||
              FormFieldChecks.isTextWithNoCharLimit(formField) ||
              FormFieldChecks.isTextWithUnpositiveCharLimit(formField) ||
              FormFieldChecks.isTextWithUnnaturalCharLimit(formField) ||
              FormFieldChecks.isTextWithNoRowsLimit(formField) ||
              FormFieldChecks.isTextWithUnpositiveRowsLimit(formField) ||
              FormFieldChecks.isTextWithUnnaturalRowsLimit(formField) ||
              FormFieldChecks.isFileWithNoFileLimit(formField)
          )
        )
        .flat()
        .filter(Boolean);

    setSaveDisabled(invalidFields && invalidFields.length > 0);

    if (formData) {
      if (formData.fillReminders) {
        setTimeBeforeEnd(
          formData.fillReminders?.map(({ timeBeforeEnd }) =>
            timeBeforeEnd ? timeBeforeEnd : ""
          )
        );
        setNotification(
          formData.fillReminders?.map(({ notification }) =>
            notification ? notification : ""
          )
        );
        setTimesToSend(
          formData.fillReminders?.map(({ notification }) =>
            notification ? notification : ""
          ).length
        );
      }
    }
  }, [formData]);

  useEffect(() => {
    if (!addMode && !fetching) {
      const formId = parseInt(id);

      if (formId && fetching === undefined) {
        setFetching(true);
        fetchForm(formId).then((fff) => {
          setFetching(false);
        });
      }
    }
  }, [addMode, fetching, id]);

  useEffect(() => {
    const meta = orgForms.find((f) => f.id === parseInt(id));
    setFormMetadata(meta);
  }, [orgForms, id]);

  useEffect(() => {
    if (formMetadata) {
      fetchFormTemplate(formMetadata.layoutId);
    }
  }, [formMetadata]);

  useEffect(() => {
    if (formMetadata) {
      setName(formMetadata.name);
      setDescription(formMetadata.description);
      setSchedule(formMetadata.schedule || {});
      setAgeCategory(formMetadata.ageCategory);
      setIsObligatory(formMetadata.mandatory);

      if (formMetadata.fillReminders) {
        setTimeBeforeEnd(
          formMetadata.fillReminders?.map(({ timeBeforeEnd }) =>
            timeBeforeEnd ? timeBeforeEnd : ""
          )
        );
        setNotification(
          formMetadata.fillReminders?.map(({ notification }) =>
            notification ? notification : ""
          )
        );
        setTimesToSend(
          formMetadata.fillReminders?.map(({ notification }) =>
            notification ? notification : ""
          ).length
        );
      }
    }
  }, [formMetadata]);

  // useEffect(() => {
  //   isFormEdited(true);
  // }, [schedule]);

  useEffect(() => {
    if (id && existingFormData) {
      setInitialRows(loToRows(existingFormData));

      setFetching(false);
    }
  }, [existingFormData]);

  const addRule = () => {
    setShowNewRule(true);
  };

  const editRule = (index) => {
    setEditedRule(rules[index] || {});
  };

  const acceptRule = (rule) => {
    if (
      !(
        editedRule &&
        Object.keys(editedRule).length === 0 &&
        editedRule.constructor === Object
      )
    ) {
      let tmpRulesArray = [...rules];
      tmpRulesArray[
        tmpRulesArray.findIndex(({ id }) => editedRule.id === id)
      ] = Object.assign(
        tmpRulesArray[
          tmpRulesArray.findIndex(({ id }) => editedRule.id === id)
        ],
        rule
      );
      setRules(tmpRulesArray);
      setEditedRule({});
    } else {
      setRules([...rules, rule]);
      setShowNewRule(false);
    }
  };

  const removeRule = (index) => {
    setRules(rules.filter((_, ind) => ind !== index));
  };

  const rowsToLayoutElementObject = (rows) => {
    return {
      dataType: "FORM",
      children: rows
        .filter((r) => r.length)
        .map((r) => ({
          dataType: "ROW",
          children: r.map((item) => ({
            ...item,
          })),
        })),
    };
  };

  function millisToMinutes(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes;
  }

  const isTimeCorrect = () => {
    if (schedule) {
      let shortestDifferenceFillTime = "";
      schedule.timePeriods.map((time) => {
        let startTime = moment(time.startTime, "HH:mm");
        let endTime = moment(time.endTime, "HH:mm");
        let duration = moment.duration(endTime.diff(startTime));
        if (shortestDifferenceFillTime === "") {
          shortestDifferenceFillTime = millisToMinutes(duration._milliseconds);
        } else if (
          shortestDifferenceFillTime > millisToMinutes(duration._milliseconds)
        ) {
          shortestDifferenceFillTime = millisToMinutes(duration._milliseconds);
        }
      });

      if (timeBeforeEnd.find((time) => time > shortestDifferenceFillTime)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const ageCategoryHandler = (e) => {
    setAgeCategory(e.target.value);
  };

  const isObligatoryHandler = (e) => {
    if (e.target.value === "true") {
      setIsObligatory(true);
    } else {
      setIsObligatory(false);
    }
  };

  const prepareSchedule = () => {
    const { selectedDays, ...trimmedSchedule } = schedule;
    return schedule.one || schedule.multi
      ? schedule.selectedDays.find(
          (selectedDay) => selectedDay.dayOfWeek === "EVERYDAY"
        )
        ? {
            ...trimmedSchedule,
            timePeriods: days.reduce((merged, day) => {
              schedule.timePeriods.forEach((timePeriod) => {
                if (day.key !== "EVERYDAY") {
                  merged.push({ ...timePeriod, dayOfWeek: day.key });
                }
              });
              return merged;
            }, []),
          }
        : trimmedSchedule
      : null;
  };

  const save = async (e) => {
    isFormEdited(false);
    const preparedSchedule = prepareSchedule();
    const payload = {
      name,
      description,
      ageCategory,
      mandatory: isObligatory,
      layoutElementObject: rowsToLayoutElementObject(formData),
      ...(rules.length > 0
        ? {
            formRules: rules.map((r) => ({
              ...r,
              smsPhone: r.smsPhone?.replace(/[^+\d]+/g, ""),
            })),
          }
        : {}),
      schedule: preparedSchedule,
      welcomeFarewellMessages,
      welcomeSmsMessage: welcomeFarewellMessages.welcomeSmsMessage,
      welcomeEmailMessage: welcomeFarewellMessages.welcomeEmailMessage,
      farewellSmsMessage: welcomeFarewellMessages.farewellSmsMessage,
      farewellEmailMessage: welcomeFarewellMessages.farewellEmailMessage,
      allowDrafting,
      fillReminders:
        parseInt(timesToSend) === 0
          ? []
          : notification.map((not, value) => {
              return {
                notification: not,
                timeBeforeEnd: timeBeforeEnd[value],
              };
            }),
      defaultAssignmentDuration,
    };
    if (isTimeCorrect()) {
      if (addMode) {
        const data = await saveForm(payload);
        if (data) {
          alertAdd({ text: "Zapisano formularz", isSuccess: true });
          history.push(`/admin-panel/surveys`);
        }
      } else {
        const data = await updateForm(formMetadata.id, payload);
        if (data) {
          alertAdd({ text: "Zaktualizowano formularz", isSuccess: true });
          history.push(`/admin-panel/surveys`);
        }
      }
    } else {
      alertAdd({
        text: "Czas powiadomienia jest dłuższy, niż okno czasowe wypełnienia",
        isWarning: true,
      });
    }
  };

  const cancel = (e) => {
    history.push("/admin-panel/surveys");
  };

  const [showAdvanced, setShowAdvanced] = useState(false);

  const scheduleCorrect =
    (!schedule.one && !schedule.multi) ||
    schedule.timePeriods.filter(
      (timePeriod) => !timePeriod.startTime || !timePeriod.endTime
    ).length === 0;

  return (
    <Box>
      {!displayMode && (
        <Prompt
          when={formEdited}
          message={() => "Czy na pewno chcesz opuścić edycję ankiety?"}
        />
      )}
      {/* <Box p={1}>
        <h1 style={{ right: 35, position: "absolute" }}>
          {showAdvanced
            ? "Ukryj ustawienia zaawansowane"
            : "Pokaż ustawienia zaawansowane"}
        </h1>
        <FontAwesomeIcon
          className={s.advancedIcon}
          style={{ marginRight: 20 }}
          icon="cog"
          onClick={() => setShowAdvanced(!showAdvanced)}
        />

        {addMode ? (
          <PageTitle title={"Nowy formularz"} />
        ) : displayMode ? (
          <PageTitle title={`Tryb podglądu formularza: ${name}`} />
        ) : (
          <PageTitle title={`Edycja ${name}`} />
        )}
      </Box>
      <Box p={1}>
        {showAdvanced && (
          <div className="box">
            <div className="is-size-5">Ustawienia zaawansowane</div>
            <p>
              {rules.length === 0 ? "Brak reguł" : "Reguły:"}
              &nbsp; {!displayMode && <a onClick={addRule}>Dodaj</a>}
            </p>
            <ul>
              {rules.map((r, index) => (
                <li key={index}>
                  {!(r === editedRule) ? (
                    <>
                      Jeśli suma punktów formularza jest {OPERATORS[r.operator]}{" "}
                      {r.arg1} {r.operator === "BETWEEN" ? `a ${r.arg2} ` : ""}
                      {r.color && (
                        <>
                          ustaw kolor na &nbsp; <ColorBox color={r.color} />
                        </>
                      )}{" "}
                      {r.text && `ustaw tekst na: "${r.text || ""}"`}
                      &nbsp;{" "}
                      {r.smsMessage &&
                        `ustaw tekst wiadomości SMS na: "${
                          r.smsMessage || ""
                        }"`}
                      &nbsp;{" "}
                      {r.smsPhone &&
                        r.smsMessage &&
                        `i wyślij pod numer telefonu: "${r.smsPhone || ""}"`}
                      &nbsp;{" "}
                      {!displayMode && (
                        <a
                          disabled={displayMode}
                          onClick={() => editRule(index)}
                        >
                          Edytuj
                        </a>
                      )}
                      &nbsp;{" "}
                      {!displayMode && (
                        <a onClick={() => removeRule(index)}>Usuń</a>
                      )}
                    </>
                  ) : (
                    <RuleEditor
                      onDone={acceptRule}
                      onCancel={() => {
                        setEditedRule({});
                      }}
                      editedRule={editedRule}
                      rules={rules}
                      setRules={setRules}
                      setEditedRule={setEditedRule}
                    />
                  )}
                </li>
              ))}
            </ul>
            {showNewRule && (
              <RuleEditor
                onDone={acceptRule}
                onCancel={() => setShowNewRule(false)}
                phone={formMetadata?.formGroup?.smsPhone}
              />
            )}
            <hr />
            <MessageWelcomeFarewell
              displayMode={displayMode}
              welcomeFarewellMessages={welcomeFarewellMessages}
              setWelcomeFarewellMessages={setWelcomeFarewellMessages}
            />
            <hr />
            <div className="is-size-5">
              <NotificationReminder
                displayMode={displayMode}
                timeBeforeEnd={timeBeforeEnd}
                setTimeBeforeEndFilling={setTimeBeforeEnd}
                notification={notification}
                setNotification={setNotification}
                timesToSend={timesToSend}
                setTimesToSend={setTimesToSend}
              />
            </div>
            <hr />
            <ScheduleFormPage
              displayMode={displayMode}
              addMode={addMode}
              formName={name}
              schedule={schedule}
              setSchedule={setSchedule}
              setShowAdvanced={() => setShowAdvanced(false)}
            />

            <hr />
            <div className="is-size-5">
              <FormControlLabel
                disabled={displayMode}
                control={
                  <Checkbox
                    checked={allowDrafting}
                    onChange={(e) => {
                      setAllowDrafting(e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label="Zezwalaj na zapis wersji roboczej tego formularza"
              />
            </div>

            <div className="is-size-5">
              <FormControlLabel
                disabled={displayMode}
                control={
                  <TextField
                    value={defaultAssignmentDuration}
                    type="number"
                    size="small"
                    onChange={(e) => {
                      if (e.target.value < 371) {
                        setDefaultAssignmentDuration(
                          e.target.value > 0 ? parseInt(e.target.value, 10) : 0
                        );
                      } else if (e.target.value > 370) {
                        setDefaultAssignmentDuration(370);
                      }
                    }}
                  />
                }
                label="Domyślny czas na jaki nowe osoby będą przypisane do tego formularza (0 wyłącza tę funkcję)"
              />
            </div>

            <Box pt={6}>
              <Grid container justify="center">
                <Grid
                  item
                  onClick={() => setShowAdvanced(!showAdvanced)}
                  style={{ cursor: "pointer" }}
                >
                  Ukryj ustawienia zaawansowane
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item>
                  <ArrowDropUp
                    onClick={() => setShowAdvanced(!showAdvanced)}
                    style={{ cursor: "pointer" }}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        )}
      </Box> */}
      <Box p={1}>
        <label htmlFor="form-name">Nazwa ankiety</label>

        <input
          disabled={displayMode}
          id="form-name"
          type="text"
          className="input"
          placeholder="np. Dane osobowe"
          value={name}
          onChange={({ target: { value: name } }) => {
            setName(name);
            isFormEdited(true);
          }}
        />
      </Box>
      <Box p={1}>
        <label htmlFor="form-name">Opis ankiety</label>

        <input
          disabled={displayMode}
          id="form-description"
          type="text"
          className="input"
          value={description}
          onChange={({ target: { value: name } }) => {
            setDescription(name);
            isFormEdited(true);
          }}
          maxlength="1000"
        />
        {`Pozostało znaków do wprowadzenia: ${1000 - description.length} `}
        <hr />
      </Box>{" "}
      <Box p={1}>
        <FormControl
          component="status"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "space-around",
          }}
        >
          <FormLabel
            id="radio-buttons-group-label"
            style={{ color: "#000000" }}
          >
            Ankieta przeznaczona dla:
          </FormLabel>
          <RadioGroup
            aria-label="ageCategory"
            name="ageCategory"
            value={ageCategory}
            onChange={ageCategoryHandler}
            row
            disabled={displayMode || !addMode}
            style={{ marginLeft: "5px" }}
          >
            <FormControlLabel
              value={"Adult"}
              control={
                <Radio color="primary" disabled={displayMode || !addMode} />
              }
              label="Osoby dorosłej"
            />
            <FormControlLabel
              value={"Child"}
              control={
                <Radio color="primary" disabled={displayMode || !addMode} />
              }
              label="Dziecka"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box p={1}>
        <FormControl
          component="isObligatory"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "space-around",
          }}
        >
          <FormLabel id="radio-buttons" style={{ color: "#000000" }}>
            Ankieta obowiązkowa:
          </FormLabel>
          <RadioGroup
            aria-label="isObligatory"
            name="isObligatory"
            value={isObligatory}
            onChange={isObligatoryHandler}
            row
            style={{ marginLeft: "5px" }}
          >
            <FormControlLabel
              value={true}
              control={
                <Radio color="primary" disabled={displayMode || !addMode} />
              }
              label="Tak"
            />
            <FormControlLabel
              value={false}
              control={
                <Radio color="primary" disabled={displayMode || !addMode} />
              }
              label="Nie"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box p={1}>
        <FormEditor
          displayMode={displayMode}
          onUpdate={setFormData}
          formId={id}
          initialRows={initialRows}
          formEdited={isFormEdited}
        />
        <hr />
      </Box>
      <div className="field is-grouped is-grouped-centered">
        <div className="control">
          <button className="button " onClick={cancel}>
            {!displayMode ? "Anuluj" : "Powrót"}
          </button>
        </div>

        <div className="control">
          <Tooltip title={scheduleCorrect ? "" : "Uzupełnij harmonogram"}>
            <span>
              <button
                className="button is-primary "
                onClick={save}
                disabled={saveDisabled || displayMode || !scheduleCorrect}
              >
                Zapisz
              </button>
            </span>
          </Tooltip>
        </div>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  orgForms: state.orgForms,
  existingFormData: state.formData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchForm: (id) => dispatch(fetchForm(id)),
  fetchFormTemplate: (id) => dispatch(fetchFormTemplate(id)),
  saveForm: (payload) => dispatch(saveForm(payload)),
  updateForm: (id, payload) => dispatch(updateForm(id, payload)),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewFormPage);
