import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Redirect } from "react-router-dom";
import Can from "../../components/can";
import Loader from "../../components/loader";
import {
  alertAdd,
  login,
  toggleGlobalTheme,
  setFontSize,
} from "../../redux/actions";
import Cookies from "js-cookie";
import s from "./index.module.css";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { LockOpen, Visibility } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { Box, makeStyles, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Alert from "@material-ui/lab/Alert";
import ApiVersionSpan from "../../components/api-version-span/api-version-span";
import UnsupportedBrowserAlert from "../../components/unsupported-browser-alert";
import { ReactComponent as HBLogo } from "../../assets/hb-tmp.svg";
import StopIcon from "@material-ui/icons/Stop";
import { VisibilityOutlined } from "@material-ui/icons";

const RegisterButton = styled(Button)`
  color: white;
  background-color: orange;
`;

const useStyles = makeStyles((theme) => ({
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  formHint: {
    fontSize: theme.typography.hintText || 12,
    color: theme.palette.text.primary2,
    letterSpacing: "0.7px",
  },
  formHint1: {
    // fontSize: theme.typography.hintText || 12,
    color: theme.palette.text.item,
    marginRight: "5px",
    letterSpacing: "0.7px",
  },
  iconHeader: {
    color: theme.palette.text.primary,
    padding: "5px",
  },
  icon: {
    color: theme.palette.text.primary,
  },
  iconSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.iconSize,
  },
  paperContainer: {
    // backgroundImage: `url(${BoxImage})`,
    borderRadius: "0px",
    boxShadow: "13px 18px 29px -9px rgba(82, 82, 92, 1)",
    // padding: "32px 64px",
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingMedium: {
    padding: "12px 64px 64px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingSmall: {
    padding: "12px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  selectedIconHeader: {
    border: `1px solid ${theme.palette.text.primary}`,
    color: theme.palette.background.default,
    backgroundColor: theme.palette.text.primary,
    "&:hover": {
      border: `1px solid ${theme.palette.background.default}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
    },
    padding: "5px",
  },
  iconColor: {
    color: theme.palette.passwordIcon.primary,
  },
  a: {
    color: theme.palette.text.blueHeaderYellow,
    "&:hover": {
      color: theme.palette.text.blueHeaderYellow,
      textDecoration: "underline",
    },
    letterSpacing: "1px",
    fontSize: theme.typography.hintText,
  },
  aMain: {
    color: theme.palette.passwordIcon.primary,
    "&:hover": {
      color: theme.palette.passwordIcon.primary,
      textDecoration: "underline",
    },
    letterSpacing: "1px",
    fontWeight: "600",
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
  },
  boxLoginTitle: {
    borderTop: "1px solid #AFAEAE",
    background: theme.palette.background.loginBackground,
  },
  aUnderline: {
    textDecoration: "underline",
  },
}));

const LoginEmployeePage = ({
  login,
  history,
  alertAdd,
  loggingIn,
  loggedIn,
}) => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [emailValid, setEmailValid] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [invalidUserLogin, setInvalidUserLogin] = useState(false);

  const [loginAttemptCounter, setLoginAttemptCounter] = useState(0);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [loginAttemptsLeft, setLoginAttemptsLeft] = useState(0);

  const [preventLogin, setPreventLogin] = useState(false);

  const rememberMeLogin = Cookies.get("rememberMeLogin");

  const [anchorEl, setAnchorEl] = useState(null);
  const [hostname, setHostname] = useState("");

  const globalTheme = useSelector((s) => s.globalTheme);

  const dispatch = useDispatch();

  const handleInstructionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (globalTheme === "high-contrast") {
      dispatch(toggleGlobalTheme());
    }
    dispatch(setFontSize("normal"));
  }, []);

  useEffect(() => {
    setHostname(window.location.hostname);
    if (loggedIn) {
      history.push("/");
    } else {
      setUsername(rememberMeLogin);
    }
  }, [loggedIn]);

  useEffect(() => {
    let valid = username && password;
    let userLoginValid = true;
    if (username) {
      const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(username).toLowerCase()
      );
      setEmailValid(emailValid);
      userLoginValid = emailValid;
      valid = valid && emailValid;
    }
    setPreventLogin(!valid);
    setInvalidUserLogin(!userLoginValid);
  }, [username, password]);

  const doLogin = async (e) => {
    e.preventDefault();

    let response = {};

    response = await login(
      {
        username,
        password,
      },
      "employee"
    );

    if (response.status === 200) {
      history.push("/admin-screening");
    } else if (response.data.name === "not activated") {
      alertAdd({
        text: "Użytkownik nie został aktywowany",
        isError: true,
      });
    } else if (response.data.name === "InvalidEmailError") {
      if (!emailValid) {
        alertAdd({
          text: "Nieprawidłowy format adresu email",
          isError: true,
        });
      } else {
        alertAdd({
          text: "Nieprawidłowy adres email lub hasło",
          isError: true,
        });
      }
    } else if (response.data.name === "InvalidPasswordError") {
      alertAdd({
        text: "Nieprawidłowy adres email lub hasło",
        isError: true,
      });
    } else if (
      response.data.name === "EmployeeBlockedError" ||
      response.data.attemptsLeft === 0
    ) {
      setDisplayAlert(false);
      alertAdd({
        text: "Konto tymczasowo zablokowane",
        isError: true,
      });
    } else {
      if (response.data.attemptsLeft - 1 > 3 || !response.data.attemptsLeft) {
        setLoginAttemptCounter(loginAttemptCounter + 1);
        alertAdd({
          text: (
            <span>
              Spróbuj poprawnie wpisać hasło lub{" "}
              <a href="/password-employee-reset">
                <strong>Ustaw nowe hasło</strong>
              </a>
            </span>
          ),
          isError: true,
        });
      } else {
        setDisplayAlert(true);
        setLoginAttemptsLeft(response.data.attemptsLeft - 1);
      }
    }
  };

  const cancel = (e) => {
    e.preventDefault();

    history.push("/");
  };

  return (
    <Can
      permission="login:view"
      ok={() => {
        return (
          <>
            <UnsupportedBrowserAlert />
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
                <Box p={1}>
                  <Paper className={classes.paperContainer}>
                    <Box>
                      <form onSubmit={doLogin} noValidate autoComplete="off">
                        <Box
                          display="flex"
                          justifyContent="center"
                          className={classes.paddingLarge}
                        >
                          <HBLogo />
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-evenly"
                          alignItems="center"
                          className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
                        >
                          <StopIcon
                            style={{ height: "8px", color: "#3458A4" }}
                          />
                          <Typography className={classes.loginTitle}>
                            ZALOGUJ SIĘ
                          </Typography>
                          <StopIcon
                            style={{ height: "8px", color: "#23EAB6" }}
                          />
                        </Box>
                        <Box className={classes.paddingSmall}>
                          <Box display="flex">
                            <TextField
                              // required
                              error={invalidUserLogin}
                              helperText={
                                invalidUserLogin
                                  ? "Nieprawidłowy format wprowadzonego loginu"
                                  : ""
                              }
                              label="* E-mail"
                              // variant="outlined"
                              value={username || ""}
                              fullWidth
                              onChange={(e) => setUsername(e.target.value)}
                            />
                          </Box>
                          <Box mt={1}>
                            <TextField
                              label="* Hasło"
                              type={showPassword ? "text" : "password"}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              fullWidth
                              InputProps={{
                                // className: classes.input,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      aria-label="toggle password visibility"
                                    >
                                      <VisibilityOutlined
                                        className={classes.iconColor}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box
                            m={1}
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <span className={classes.formHint}>
                              {"* Pola wymagane"}
                            </span>
                            {/* <Box textAlign="right" fontSize={12} m={1}> */}
                            <Link
                              className={classes.a}
                              to="/password-employee-reset"
                            >
                              Zapomniałeś hasła?
                            </Link>
                            {/* </div></Box> */}
                          </Box>
                        </Box>
                        <Box className={classes.paddingMedium}>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              type="submit"
                              variant={"contained"}
                              color="primary"
                              disabled={preventLogin}
                              style={{
                                borderRadius: "0px",
                                height: "50px",
                                width: "215px",
                              }}
                            >
                              Zaloguj się
                            </Button>
                          </Box>
                          <br />
                        </Box>
                        <Loader loading={loggingIn} text="Logowanie..." />
                      </form>
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </>
        );
      }}
      not={() => {
        return <Redirect to="/" />;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  loggingIn: state.loggingIn,
  loggedIn: state.loggedIn,
  activeForms: state.activeForms,
  my: state.my,
});

const mapDispatchToProps = (dispatch) => ({
  login: (data, loginType) => dispatch(login(data, loginType)),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginEmployeePage);
