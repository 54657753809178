import React, { useEffect, useState } from 'react';
import { usePagination } from '../../../../../hooks/usePagination/usePagination';
import ScrollTable from './scroll-table';

const ExceptionsInfo = ({ data }) => {
  const [pagedData, setPagedData] = useState([]);

  const { paginationState, dispatch: dispatchPagination } = usePagination();
  const { page, rowsPerPage } = paginationState;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const count = Math.ceil(data.length / rowsPerPage);
    if (page > count) return;

    setLoading(true);
    setTimeout(() => {
      const indexOfLastRecord = page * rowsPerPage;
      const indexOfFirstRecord = indexOfLastRecord - rowsPerPage;
      setPagedData((prev) => [
        ...prev,
        ...data.slice(indexOfFirstRecord, indexOfLastRecord),
      ]);
      setLoading(false);
    }, [2000]);
  }, [page, data]);

  return (
    <ScrollTable
      data={pagedData}
      page={page}
      dispatchPagination={dispatchPagination}
      loading={loading}
    />
  );
};

export default ExceptionsInfo;
