import { Button, makeStyles, TableCell, TableRow } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  spacing: {
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
    boxShadow: "none",
  },
}));

export const MAPDoctorTitle = {
  lek: "Lekarz Specjalista",
  dr: "Dr n. med.",
  prof: "Profesor",
};

const UserRow = ({
  user,
  setOpenUserModal,
  setUserToEdit,
  setUserHandlerType,
  setDoctorToRemove,
  setOpenDoctorRemovePanel,
}) => {
  const classes = useStyles();

  const { name, surname, email, titles, organization } = user;

  const userEditionHandler = () => {
    setUserHandlerType("edition");
    setUserToEdit(user);
    setOpenUserModal(true);
  };

  return (
    <TableRow>
      <TableCell>{name || "---"}</TableCell>
      <TableCell>{email || "---"}</TableCell>
      <TableCell>{MAPDoctorTitle[titles[0]] || "---"}</TableCell>

      <TableCell>
        <Button
          variant={"contained"}
          color="primary"
          onClick={userEditionHandler}
        >
          Edytuj
        </Button>{" "}
        <Button
          variant={"contained"}
          color="primary"
          onClick={() => {
            setDoctorToRemove(user);
            setOpenDoctorRemovePanel(true);
          }}
        >
          Usuń
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default UserRow;
