import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Divider,
  Box,
  Grid,
} from "@material-ui/core";
import { CombinedResult } from "./results-per-package-table";
import { useStyles } from "./results-per-package-table";
import DateAndResultGrid from "./DateAndResultGrid";
import { useTheme } from "@material-ui/core/styles";
import { lightTheme } from "../../theme";

const answerTextToLetter = (text) => {
  switch (text) {
    case "high":
      return "W";

    case "low":
      return "N";

    case "long":
      return "D";

    case "short":
      return "K";

    default:
      return text;
  }
};

const Answers = ({ attempt, index, training = false }) => {
  const isLightTheme =
    useTheme().palette.primary.main === lightTheme.palette.primary.main;
  const classes = useStyles();
  const rightAnswers = attempt.listOfPlayedSounds[0].metadata.rightAnswers;
  const givenAnswers = attempt.givenAnswers;

  return (
    <TableRow key={index}>
      <TableCell className={classes.grey}>
        {training ? `U${index + 1}` : index + 1}
      </TableCell>
      <TableCell className={classes.grey}>
        <Grid
          container
          // spacing={2}
          direction="row"
          style={{ width: "100px" }}
        >
          {rightAnswers.map(({ text }, index) => (
            <Grid item key={index}>
              <span
                className={classes.grey}
                style={{
                  display: "inline-block",
                  width: "15px",
                }}
              >
                {answerTextToLetter(text)}
              </span>
            </Grid>
          ))}
        </Grid>
      </TableCell>
      <TableCell>
        <Grid
          container
          //  spacing={2}
          direction="row"
          style={{ width: "100px" }}
        >
          {givenAnswers.map(({ text }, index) => (
            <Grid item key={index}>
              <span
                key={index}
                style={{
                  display: "inline-block",
                  color: isLightTheme
                    ? text === rightAnswers[index].text
                      ? "#2EAB8D"
                      : "#CF1616"
                    : "#ffff00",
                  width: "15px",
                }}
              >
                {answerTextToLetter(text)}
              </span>
            </Grid>
          ))}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const SequenceAnswersResults = ({ test }) => {
  const classes = useStyles();
  return (
    <Box>
      <DateAndResultGrid startDate={test.startDate} endDate={test.endDate}>
        <CombinedResult
          text="Wynik ogólny:"
          percent={test.data.correctPercent}
          result={test.data.result}
        />
        <Box>
          {test.data.earlyFinishedEnum && (
            <Typography
              component="span"
              align="center"
              className={classes.grey}
            >
              Badanie niedokończone
            </Typography>
          )}
          {test.failureReason === "FANC" && (
            <Typography align="center" className={classes.grey}>
              Podczas badania wystąpiły zakłócenia
            </Typography>
          )}
        </Box>
      </DateAndResultGrid>

      <br />
      <Grid container justify="space-evenly" alignItems="center">
        <Box className={`${classes.boxLoginTitle} ${classes.paddingMedium}`}>
          <Grid item>
            <Typography
              style={{
                color: (theme) => theme.palette.text.alternative2,
              }}
            >
              PRZEBIEG BADANIA
            </Typography>
          </Grid>
          {test.data && (
            <Grid item>
              <TableContainer style={{ maxWidth: 550, mb: 2 }}>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeaderPerExamination}>
                        Nr próby
                      </TableCell>
                      <TableCell className={classes.tableHeaderPerExamination}>
                        Bodziec
                      </TableCell>
                      <TableCell className={classes.tableHeaderPerExamination}>
                        Odpowiedź
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {test.data.tries.map((attempt, index) => (
                      <Answers key={index} attempt={attempt} index={index} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default SequenceAnswersResults;
