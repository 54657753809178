import React from "react";
import { Grid, Box, Button } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PropertyStatus, { colorMapper } from "./property-state";
import LinearProgressWithLabel from "../../linear-progress";

const DeviceStates = ({ data, openModal, handleOpenModal }) => {
  const {
    ancState,
    audioMetrState,
    isAudioMetrCharged,
    isLockClosed,
    lockState,
    areHeadphonesPluggedIn,
    ancBattery,
    isAudioMetrBatteryBelowMinimum,
    audioMetrBattery,
  } = data;

  const dataToRender = [
    {
      text: "Backend",
      value: "TRUE",
      render: () => (
        <Box display="flex" alignItems="center">
          <Button size="small" variant="contained" onClick={handleOpenModal}>
            Logi systemu
          </Button>
        </Box>
      ),
    },
    { text: "Frontend", value: "TRUE" },
    {
      text: "ANC",
      value: ancState,
      render: () => (
        <LinearProgressWithLabel
          helperText={"Bateria: "}
          text={`${Math.round(ancBattery)}%`}
          value={ancBattery}
        />
      ),
    },
    {
      text: "AudioMetr",
      value: audioMetrState,
      render: () => (
        <Box display="flex" alignItems="center">
          {`Bateria: `}
          <FiberManualRecordIcon
            fontSize="large"
            style={{ fill: colorMapper[isAudioMetrBatteryBelowMinimum] }}
          />
          {`${audioMetrBattery} mV`}
        </Box>
      ),
    },
    { text: "AudioMetr ładowanie", value: isAudioMetrCharged },
    { text: "Detektor klapki", value: lockState },
    { text: "Klapka zamknięta", value: isLockClosed },
    {
      text: "Podłączone słuchawki",
      value: areHeadphonesPluggedIn,
    },
  ];

  return (
    <Grid container direction="column">
      {dataToRender.map((d) => (
        <PropertyStatus key={d.text} {...d} />
      ))}
    </Grid>
  );
};
export default DeviceStates;
