import React from "react";
import { Grid, Box } from "@material-ui/core";
import InfoStatus from "./property-info";
import LinearProgressWithLabel from "../../linear-progress";

const DeviceInfo = ({
  data: {
    os,
    backendVersion,
    frontendVersion,
    diskSpace,
    diskSpaceUsed,
    ancVersion,
    audioMetrId,
    ancId,
  },
}) => {
  const dataToRender = [
    { text: "Backend", value: backendVersion },
    { text: "Frontend", value: frontendVersion },
    { text: "ANC", value: ancVersion, additional: () => `id ${ancId}` },
    { text: "AudioMetr", value: `id ${audioMetrId}` },
    { text: "System operacyjny", value: os },
  ];

  return (
    <Grid container direction="column">
      {dataToRender.map((d, i) => (
        <InfoStatus key={i} {...d} />
      ))}
      <Box
        mt={1}
        mb={1}
        style={{ display: "flex", alignItems: "center", maxHeight: "20px" }}
      >
        <Grid item xs={6} md={4} lg={2}>
          <strong>{`Przestrzeń dyskowa`}</strong>
        </Grid>{" "}
        <Grid item xs={6} lg={3}>
          <LinearProgressWithLabel
            helperText={`Wolnych [GB]: `}
            text={`${diskSpace - diskSpaceUsed}/${diskSpace}`}
            value={(diskSpaceUsed / diskSpace) * 100}
          />
        </Grid>
      </Box>
    </Grid>
  );
};

export default DeviceInfo;
