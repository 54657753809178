import React from "react";
import {
  Box,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Grid,
} from "@material-ui/core";
import { CombinedResult } from "./results-per-package-table";
import { useStyles } from "./results-per-package-table";
import DateAndResultGrid from "./DateAndResultGrid";
import { useTheme } from "@material-ui/core/styles";
import { lightTheme } from "../../theme";

const AnswerWithProperColor = ({ answersToCompareWith, text }) => {
  const isLightTheme =
    useTheme().palette.primary.main === lightTheme.palette.primary.main;
  return (
    <span
      style={{
        color: isLightTheme
          ? answersToCompareWith.some((ans) => ans.text === text)
            ? "#2EAB8D"
            : "#CF1616"
          : "#ffff00",
      }}
    >
      {`${text} `}
    </span>
  );
};

const groupGivenAnswers = (
  givenAnswers,
  correctAnswersRight,
  correctAnswersLeft
) => {
  const givenAnswersLeft = [];
  const givenAnswersRight = [];
  const givenAnswersIncorrect = [];
  givenAnswers.forEach(({ text }) => {
    if (correctAnswersLeft.some((ans) => ans.text === text)) {
      givenAnswersLeft.push(text);
    } else if (correctAnswersRight.some((ans) => ans.text === text)) {
      givenAnswersRight.push(text);
    } else {
      givenAnswersIncorrect.push(text);
    }
  });
  return { givenAnswersRight, givenAnswersLeft, givenAnswersIncorrect };
};

const Answers = ({ attempt, index, training = false }) => {
  const classes = useStyles();

  const rightAnswersRight = attempt.listOfPlayedSounds.find(
    ({ channel }) => channel === "RIGHT"
  ).metadata.rightAnswers;
  const rightAnswersLeft = attempt.listOfPlayedSounds.find(
    ({ channel }) => channel === "LEFT"
  ).metadata.rightAnswers;
  const allRightAnswers = [...rightAnswersLeft, ...rightAnswersRight];
  const givenAnswers = attempt.givenAnswers;
  const {
    givenAnswersRight,
    givenAnswersLeft,
    givenAnswersIncorrect,
  } = groupGivenAnswers(givenAnswers, rightAnswersRight, rightAnswersLeft);

  return (
    <TableRow key={index}>
      <TableCell className={classes.grey}>
        {training ? `U${index + 1}` : index + 1}
      </TableCell>
      <TableCell className={classes.grey}>
        {rightAnswersRight.map(({ text }, ind) => (
          <AnswerWithProperColor
            key={ind}
            answersToCompareWith={givenAnswers}
            text={text}
          />
        ))}
      </TableCell>
      <TableCell className={classes.grey}>
        {givenAnswersRight.map((text, ind) => (
          <AnswerWithProperColor
            key={ind}
            index={ind}
            answersToCompareWith={allRightAnswers}
            text={text}
          />
        ))}
      </TableCell>
      <TableCell className={classes.grey}>
        {rightAnswersLeft.map(({ text }, ind) => (
          <AnswerWithProperColor
            key={ind}
            index={ind}
            answersToCompareWith={givenAnswers}
            text={text}
          />
        ))}
      </TableCell>
      <TableCell className={classes.grey}>
        {givenAnswersLeft.map((text, ind) => (
          <AnswerWithProperColor
            key={ind}
            index={ind}
            answersToCompareWith={allRightAnswers}
            text={text}
          />
        ))}
      </TableCell>
    </TableRow>
  );
};

const DichoticTestResults = ({ test }) => {
  const classes = useStyles();
  return (
    <Box>
      <DateAndResultGrid startDate={test.startDate} endDate={test.endDate}>
        <CombinedResult
          text="Wynik ucha prawego:"
          percent={test.data.correctPercentRight}
          result={test.data.resultRight}
        />
        <CombinedResult
          text="Wynik ucha lewego:"
          percent={test.data.correctPercentLeft}
          result={test.data.resultLeft}
        />
        <Box>
          {test.data.earlyFinishedEnum && (
            <Typography
              component="span"
              align="center"
              className={classes.grey}
            >
              Badanie niedokończone
            </Typography>
          )}
          {test.failureReason === "FANC" && (
            <Typography align="center" className={classes.grey}>
              Podczas badania wystąpiły zakłócenia
            </Typography>
          )}
        </Box>
      </DateAndResultGrid>
      <br />
      <Box className={`${classes.paddingMedium}`}>
        <Grid
          container
          justify="space-evenly"
          alignItems="center"
          direction="column"
          className={`${classes.boxLoginTitle}`}
          style={{ overflowX: "auto" }}
        >
          <Grid item>
            <Typography
              style={{
                color: (theme) => theme.palette.text.alternative2,
              }}
            >
              PRZEBIEG BADANIA
            </Typography>
          </Grid>
          <Grid item>
            <TableContainer
              style={{ maxWidth: 650, mb: 2, display: "inline-block" }}
            >
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeaderPerExamination}>
                      Nr próby
                    </TableCell>
                    <TableCell className={classes.tableHeaderPerExamination}>
                      Bodziec ucho PRAWE
                    </TableCell>
                    <TableCell className={classes.tableHeaderPerExamination}>
                      Odpowiedź prawe
                    </TableCell>
                    <TableCell className={classes.tableHeaderPerExamination}>
                      Bodziec ucho LEWE
                    </TableCell>
                    <TableCell className={classes.tableHeaderPerExamination}>
                      Odpowiedź lewe
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {test.data.tries.map((attempt, index) => (
                    <Answers key={index} attempt={attempt} index={index} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DichoticTestResults;
