import React, { useEffect, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Can from "../../components/can";
import {
  fetchProteges,
  addProtege,
  editProtege,
  removeProtege,
} from "../../redux/actions";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import Grid from "@material-ui/core/Grid";
// import TableCell from "@material-ui/core/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { buyCartItem, removeCartItem } from "../../redux/shopping-cart/actions";
import { connect } from "react-redux";
import { alertAdd } from "../../redux/actions";
import AppBarTitle from "../../components/app-bar-title";
import GroupIcon from "@material-ui/icons/Group";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ProtegesModal from "./proteges-handler-modal";
import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import MenuItem from "@material-ui/core/MenuItem";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import * as moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InputLabel from "@material-ui/core/InputLabel";
import { CircularProgress } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { LockOpen, Visibility } from "@material-ui/icons";
import Loader from "../../components/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "@material-ui/core/Select";
import TrashIcon from "@material-ui/icons/DeleteForever";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";
import { format } from "date-fns";
import { useRef } from "react";
import { ReactComponent as ArrowDownIcon } from "../../assets/arrowDownIcon.svg";
import { ReactComponent as ArrowDownIconYellow } from "../../assets/arrowDownIcon-yellow.svg";
import { ReactComponent as HBLogo } from "../../assets/hb-tmp.svg";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";
import { ReactComponent as CalendarIconYellow } from "../../assets/calendar-yellow.svg";
import StopIcon from "@material-ui/icons/Stop";

const genderTypes = [
  {
    type: "Female",
    description: "Kobieta",
  },
  {
    type: "Male",
    description: "Mężczyzna",
  },
];

const MuiHeaderTableCell = withStyles((theme) => ({
  root: {
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    borderBottom: `0.5px solid ${theme.palette.background.menuItemBorderBottom}`,
    letterSpacing: "0.7px",
    textAlign: "center",
  },
}))(TableCell);

const MuiBodyTableCell = withStyles((theme) => ({
  root: {
    color: theme.palette.text.hintText,
    borderBottom: `0.5px solid ${theme.palette.background.menuItemBorderBottom}`,
    fontFamily: "Montserrat",
  },
}))(TableCell);

const NO_ID_TO_REMOVE = -1;

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "16px",
  },
  paper: {
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  rootProgress: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  protegeButton: {
    // backgroundColor: "#0000FF",
    borderRadius: "4px",
  },

  gridTable: {
    margin: "12px auto",
  },

  protegeGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "12px 8px",
    boxShadow: `0px 1px 0px ${theme.palette.text.primary}20`,
    position: "sticky",
    top: "4rem",
    [theme.breakpoints.down("xs")]: {
      top: "5.5rem",
    },
    background: theme.palette.background.default,
    zIndex: "100",
    marginRight: "10px",
  },
  protegeTable: {
    padding: theme.spacing(2),
  },
  pad: {
    padding: "10px",
    "@media (min-width: 600px)": {
      padding: "20px",
    },
  },
  mainGrid: {
    height: "86vh",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  contentGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  itemGrid: {
    display: "flex",
    justifyContent: "center",
  },
  label: {
    fontSize: "24px",
    lineHeight: "20px",
    color: "#707070",
  },
  button: {
    background: "#0000FF",
    borderRadius: "4px",
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.text.item,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.item,
    },
    menuItem: {
      color: "red",
    },
  },
  focused: {
    "& label": {
      color: theme.palette.text.item,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.text.item,
        color: theme.palette.text.alternative3,
      },
      "& .Mui-focused": {
        borderColor: theme.palette.text.alternative3,
        color: theme.palette.text.alternative3,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.text.alternative3,
        color: theme.palette.text.alternative3,
      },
    },
  },
  selectText: {
    letterSpacing: "1px",
    lineHeight: `${theme.typography.formLabel}px`,
    fontSize: theme.typography.footer,
    color: theme.palette.text.hintText,
    textTransform: "uppercase",
    borderBottom: `1px solid ${theme.palette.background.menuItemBorderBottom}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  paddingMedium: {
    padding: "12px 64px 64px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  paddingSmall: {
    padding: "12px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  boxLoginTitle: {
    borderTop: "1px solid #AFAEAE",
    background: theme.palette.background.loginBackground,
  },
  formHint: {
    fontSize: theme.typography.hintText || 12,
    color: theme.palette.text.hintText,
    letterSpacing: "0.7px",
  },
  aMain: {
    color: theme.palette.passwordIcon.primary,
    "&:hover": {
      color: theme.palette.passwordIcon.primary,
      textDecoration: "underline",
    },
    letterSpacing: "1px",
    fontWeight: "600",
  },
  tableDescButton: {
    color: theme.palette.lockIcon.primary,
    letterSpacing: "0.7px",
    fontSize: theme.typography.footerMobile,
    "&:hover": {
      color: theme.palette.text.hintText,
    },
  },
  editionButton: {
    padding: "0px 16px",
    borderRadius: "20px",
    letterSpacing: "0.5px",
    background: theme.palette.passwordIcon.primary,
    color: theme.palette.background.default,
    fontSize: theme.typography.footerMobile,
    "&:hover": {
      background: theme.palette.lockIcon.primary,
    },
    margin: "5px",
  },
  resultsButton: {
    padding: "0px 16px",
    borderRadius: "20px",
    letterSpacing: "0.5px",
    margin: "5px",
    background: theme.palette.background.default,
    color: theme.palette.passwordIcon.primary,
    border: `0.5px solid ${theme.palette.passwordIcon.primary}`,
    fontSize: theme.typography.footerMobile,
    "&:hover": {
      color: theme.palette.lockIcon.primary,
      background: theme.palette.background.default,
      border: `0.5px solid ${theme.palette.lockIcon.primary}`,
    },
  },
}));

const headerCells = [
  { id: "firstName", label: "Imię" },
  { id: "surname", label: "Nazwisko" },
  { id: "dateOfBirth", label: "Wiek" },
  { id: "gender", label: "Płeć" },
];

const Proteges = ({
  alertAdd,
  fetchProteges,
  addProtege,
  editProtege,
  removeProtege,
  history,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const proteges = useSelector((s) => s.proteges);
  const protegesFetching = useSelector((s) => s.protegesFetching);
  const protegesLength = useSelector((s) => s.protegesLength);
  const protegeAdded = useSelector((s) => s.protegeAdded);
  const protegeRemoved = useSelector((s) => s.protegeRemoved);
  const protegeEdited = useSelector((s) => s.protegeEdited);
  const protegeRemovedMessage = useSelector((s) => s.protegeRemovedMessage);
  const protegesAll = useSelector((s) => s.protegesAll);

  const [protegeToEdit, setProtegeToEdit] = useState({});
  const [protegeRemoving, setProtegeRemoving] = useState(false);

  const [openProtegesModal, setOpenProtegesModal] = useState(false);
  const [protegeHandlerType, setProtegeHandlerType] = useState("");

  const [openProtegeRemovingPanel, setOpenProtegeRemovingPanel] = useState(
    false
  );

  const [idToRemove, setIdToRemove] = useState(NO_ID_TO_REMOVE);

  const [busy, setBusy] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [birth, setBirth] = useState("");
  const [gender, setGender] = useState("");

  const [isValid, setIsValid] = useState(false);

  const pages = [30, 50, 100, 200, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);

  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";

  const dispatch = useDispatch();

  useEffect(() => setIsValid(firstName && surname && birth && gender), [
    firstName,
    surname,
    birth,
    gender,
  ]);

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (protegeRemovedMessage === "subccount-has-assigments") {
        alertAdd({
          text:
            "Nie możesz usunąć podopiecznego ponieważ ma przypisane lub wypełnione badania.",
          isError: true,
        });
      }
    }
  }, [protegeRemovedMessage]);

  // useEffect(() => {
  //   if (protegeRemovedMessage === "subccount-has-assigments") {
  //     alertAdd({
  //       text:
  //         "Nie możesz usunąć podopiecznego ponieważ ma przypisane lub wypełnione badania.",
  //       isError: true,
  //     });
  //   }
  // }, [protegeRemovedMessage]);

  useEffect(() => {
    fetchProteges({
      page: pageNr,
      pageSize: rowsPerPage,
      ...(orderBy
        ? { sortBy: `${order === "asc" ? orderBy : "-" + orderBy}` }
        : {}),
    });
  }, [pageNr, rowsPerPage, orderBy, order]);

  useEffect(() => {
    if (protegeAdded || protegeEdited || protegeRemoved)
      fetchProteges({
        page: pageNr,
        pageSize: rowsPerPage,
        ...(orderBy
          ? { sortBy: `${order === "asc" ? orderBy : "-" + orderBy}` }
          : {}),
      });
  }, [protegeAdded, protegeEdited, protegeRemoved]);

  const askToRemove = (protegeId) => {
    setIdToRemove(protegeId);
  };

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  function calcAge(dateString) {
    var birthday = +new Date(dateString);
    return ~~((Date.now() - birthday) / 31557600000);
  }

  const page = () =>
    protegeRemoving || protegesFetching ? (
      <Box className={classes.root}>
        <h1
          aria-hidden="false"
          aria-label="Profil innej osoby"
          style={{ display: "none" }}
        >
          Profil innej osoby
        </h1>
        <Box display="flex" justifyContent="center">
          {
            <>
              <div className={classes.rootProgress}>
                <LinearProgress />
              </div>
            </>
          }
        </Box>
      </Box>
    ) : protegesLength > 0 ? (
      <Box className={classes.root}>
        <Grid container>
          <Grid xs={12} item className={classes.protegeGrid} elevation={15}>
            <Button
              variant="contained"
              color="primary"
              className={classes.protegeButton}
              onClick={() => {
                setProtegeToEdit(null);
                setProtegeHandlerType("add");
                setOpenProtegesModal(true);
              }}
            >
              Dodaj nowy profil
            </Button>
            {openProtegesModal && (
              <ProtegesModal
                openProtegesModal={openProtegesModal}
                protegeHandlerType={protegeHandlerType}
                handleClose={() => {
                  setOpenProtegesModal(false);
                  // setProtegeRemoving(true);
                  // setTimeout(() => {
                  //   setProtegeRemoving(false);
                  // }, 3000);
                }}
                protegeToEdit={protegeToEdit}
                addProtege={addProtege}
                editProtege={editProtege}
              />
            )}
          </Grid>
          <Grid item xs={12} className={classes.gridTable}>
            <Grid
              container
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={12} md={10}>
                <Paper className={classes.paper}>
                  <Box
                    display="flex"
                    justifyContent="space-evenly"
                    alignItems="center"
                    className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
                  >
                    <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                    <Typography className={classes.loginTitle}>
                      Profile innych osób
                    </Typography>
                    <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
                  </Box>
                  <Box className={`${classes.paddingMedium}`}>
                    <TableContainer style={{ marginBottom: "50px" }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <MuiHeaderTableCell
                              style={{
                                verticalAlign: "top",
                                textAlign: "center",
                              }}
                            >
                              Lp.
                            </MuiHeaderTableCell>
                            {headerCells.map(({ label, id }) => (
                              <MuiHeaderTableCell
                                key={id}
                                style={{
                                  verticalAlign: "top",
                                  textAlign: "center",
                                }}
                              >
                                <TableSortLabel
                                  active={orderBy === id}
                                  hideSortIcon={true}
                                  direction={orderBy === id ? order : "asc"}
                                  onClick={() => {
                                    handleSortRequest(id);
                                  }}
                                >
                                  {label}
                                </TableSortLabel>
                              </MuiHeaderTableCell>
                            ))}
                            <MuiHeaderTableCell
                              style={{
                                verticalAlign: "top",
                                textAlign: "center",
                              }}
                            >
                              Opcje
                            </MuiHeaderTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {proteges.map((protege, index) => (
                            <TableRow key={index}>
                              <MuiBodyTableCell
                                component="th"
                                scope="row"
                                style={{ textAlign: "center" }}
                              >
                                {index + 1 + pageNr * rowsPerPage}
                              </MuiBodyTableCell>
                              <MuiBodyTableCell style={{ textAlign: "center" }}>
                                {protege.firstName}
                              </MuiBodyTableCell>
                              <MuiBodyTableCell style={{ textAlign: "center" }}>
                                {protege.surname}
                              </MuiBodyTableCell>
                              <MuiBodyTableCell style={{ textAlign: "center" }}>
                                {calcAge(protege.dateOfBirth)}
                              </MuiBodyTableCell>
                              <MuiBodyTableCell style={{ textAlign: "center" }}>
                                {
                                  genderTypes.find(
                                    ({ type }) => type === protege.gender
                                  ).description
                                }
                              </MuiBodyTableCell>
                              <MuiBodyTableCell style={{ textAlign: "center" }}>
                                <Tooltip
                                  placement="top"
                                  title="Sprawdź wyniki innej osoby"
                                >
                                  <Button
                                    variant="contained"
                                    className={classes.resultsButton}
                                    onClick={() =>
                                      history.push(`/results/${protege.id}`)
                                    }
                                  >
                                    Wyniki
                                  </Button>
                                </Tooltip>

                                <Tooltip
                                  placement="top"
                                  title="Edytuj profil innej osoby"
                                >
                                  <Button
                                    variant="contained"
                                    className={classes.editionButton}
                                    onClick={() => {
                                      setProtegeToEdit(protege);
                                      setProtegeHandlerType("edit");
                                      setOpenProtegesModal(true);
                                    }}
                                  >
                                    Edytuj
                                  </Button>
                                </Tooltip>

                                <Tooltip
                                  placement="top"
                                  title="Usuń profil innej osoby"
                                >
                                  <IconButton
                                    aria-label="Usuń profil innej osoby"
                                    size="small"
                                    style={{ margin: "5px" }}
                                    onClick={() => {
                                      askToRemove(protege.id);
                                      setOpenProtegeRemovingPanel(true);
                                    }}
                                  >
                                    <TrashIcon
                                      role="img"
                                      aria-hidden="false"
                                      alt="Usuń profil innej osoby"
                                      title="Usuń profil innej osoby"
                                    />
                                  </IconButton>
                                </Tooltip>
                                {idToRemove !== NO_ID_TO_REMOVE &&
                                  idToRemove === protege.id && (
                                    <ConfirmDialogMUI
                                      handleClose={() => {
                                        setOpenProtegeRemovingPanel(false);
                                      }}
                                      open={openProtegeRemovingPanel}
                                      text={`Czy na pewno usunąć profil innej osoby: ${protege.firstName} ${protege.surname}?`}
                                      yesAction={() => {
                                        // setProtegeRemoving(true);
                                        if (
                                          proteges.length === 1 &&
                                          pageNr !== 0
                                        ) {
                                          setPageNr(pageNr - 1);
                                        }
                                        removeProtege(idToRemove);

                                        setIdToRemove(NO_ID_TO_REMOVE);
                                      }}
                                      noAction={() =>
                                        setIdToRemove(NO_ID_TO_REMOVE)
                                      }
                                    />
                                  )}
                              </MuiBodyTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        component="div"
                        page={pageNr}
                        rowsPerPageOptions={pages}
                        rowsPerPage={rowsPerPage}
                        // count={protegesAll}
                        count={protegesLength}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage={"Wierszy na stronę:"}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} z ${count}`
                        }
                        className={classes.select}
                      />
                    </TableContainer>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <Box className={classes.root}>
        <Grid container style={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} md={10} lg={6} xl={4}>
            <Paper className={classes.paper}>
              <Box>
                <Paper className={classes.paperContainer}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    className={classes.paddingLarge}
                  >
                    <HBLogo />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-evenly"
                    alignItems="center"
                    className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
                  >
                    <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                    <Typography className={classes.loginTitle}>
                      Dodaj osobę
                    </Typography>
                    <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
                  </Box>
                  <Box className={classes.paddingSmall}>
                    <Box mt={1}>
                      <TextField
                        label="Imię"
                        required
                        inputProps={{ maxLength: 255 }}
                        value={firstName}
                        fullWidth
                        onChange={({ target: { value: firstName } }) => {
                          setFirstName(firstName);
                        }}
                        inputProps={{ "aria-label": "Imię" }}
                      />
                    </Box>
                    <Box mt={1}>
                      <TextField
                        label="Nazwisko"
                        required
                        inputProps={{ maxLength: 255 }}
                        value={surname}
                        fullWidth
                        onChange={({ target: { value: lastName } }) => {
                          setSurname(lastName);
                        }}
                        inputProps={{ "aria-label": "Nazwisko" }}
                      />
                    </Box>
                    <Box mt={1}>
                      <MuiPickersUtilsProvider
                        locale={plLocale}
                        utils={DateFnsUtils}
                      >
                        <KeyboardDatePicker
                          disableToolbar
                          id="start-date-picker-dialog"
                          label="Rok urodzenia"
                          required
                          fullWidth
                          format="MM/yyyy"
                          views={["year", "month"]}
                          maxDate={new Date()}
                          helperText={""}
                          value={birth ? birth : null}
                          onChange={(value) => {
                            setBirth(value);
                          }}
                          okLabel="Zatwierdź"
                          clearLabel="Wyczyść"
                          cancelLabel="Anuluj"
                          KeyboardButtonProps={{
                            "aria-label": "Zmień datę urodzenia",
                          }}
                          keyboardIcon={
                            isLightGlobalTheme ? (
                              <CalendarIcon />
                            ) : (
                              <CalendarIconYellow />
                            )
                          }
                          inputProps={{ "aria-label": "Rok urodzenia" }}
                          disable
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                    <Box mt={1}>
                      <FormControl fullWidth>
                        <InputLabel id="select-outlined-label" required>
                          Płeć
                        </InputLabel>
                        <Select
                          className={classes.select}
                          labelId="select-outlined-label"
                          id="select-outlined"
                          value={gender}
                          fullWidth
                          onChange={(event) => setGender(event.target.value)}
                          label="Płeć *"
                          IconComponent={(props) => (
                            <i
                              {...props}
                              className={`material-icons ${props.className}`}
                              style={{
                                top: "calc(50% - 20px)",
                                right: "15px",
                              }}
                            >
                              {isLightGlobalTheme ? (
                                <ArrowDownIcon />
                              ) : (
                                <ArrowDownIconYellow />
                              )}
                            </i>
                          )}
                        >
                          {genderTypes.map((genderType, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={genderType.type}
                                className={classes.selectText}
                              >
                                {genderType.description}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box mt={1} style={{ textAlign: "left" }}>
                      <span className={classes.formHint}>* Pola wymagane</span>
                    </Box>
                  </Box>
                  <Box className={classes.paddingMedium}>
                    <Box display="flex" justifyContent={"center"}>
                      <Button
                        type="submit"
                        variant={"contained"}
                        color="primary"
                        style={{
                          borderRadius: "0px",
                          height: "50px",
                          width: "250px",
                        }}
                        onClick={() => {
                          const dateOfBirth = new Date(birth);
                          dateOfBirth.setDate("1");
                          setFirstName("");
                          setSurname("");
                          setBirth("");
                          setGender("");
                          addProtege({
                            firstName,
                            surname,
                            dateOfBirth: new Date(
                              dateOfBirth.getTime() +
                                Math.abs(
                                  dateOfBirth.getTimezoneOffset() * 60000
                                )
                            )
                              .toISOString()
                              .split("T")[0],
                            gender,
                          });
                        }}
                        disabled={!isValid}
                      >
                        Dodaj profil
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Paper>
            {openProtegesModal && (
              <ProtegesModal
                openProtegesModal={openProtegesModal}
                protegeHandlerType={protegeHandlerType}
                handleClose={() => {
                  setOpenProtegesModal(false);
                  // setProtegeRemoving(true);
                  // setTimeout(() => {
                  //   setProtegeRemoving(false);
                  // }, 3000);
                }}
                protegeToEdit={protegeToEdit}
                addProtege={addProtege}
                editProtege={editProtege}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    );
  const redirect = () => <Redirect to="/" />;

  return <Can permission="proteges:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchProteges: (payload) => dispatch(fetchProteges(payload)),
  addProtege: (payload) => dispatch(addProtege(payload)),
  editProtege: (protegeId, payload) =>
    dispatch(editProtege(protegeId, payload)),
  removeProtege: (protegeId) => dispatch(removeProtege(protegeId)),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Proteges);
