import {
  Box,
  makeStyles,
  withStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Can from "../../components/can";
import FilterProperty from "../../components/filter-property/filter-property";
import { fetchPurchasedProducts } from "../../redux/actions";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import { ReactComponent as InfoIconYellow } from "../../assets/info-yellow.svg";
import Loader from "../../components/loader";

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.text.alternative2,
    letterSpacing: "1.2px",
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    fontWeight: "700",
    "&:hover": {
      color: theme.palette.text.hintText,
    },
  },
}))(Link);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1250,
  },
  root: {
    padding: "16px",
  },
  tableContainer: {
    maxHeight: `calc(50vh - 55px)`,
    "@media (min-height: 300px)": {
      maxHeight: `calc(54vh - 55px)`,
    },
    "@media (min-height: 350px)": {
      maxHeight: `calc(60vh - 55px)`,
    },
    "@media (min-height: 400px)": {
      maxHeight: `calc(64vh - 55px)`,
    },
    "@media (min-height: 500px)": {
      maxHeight: `calc(70vh - 55px)`,
    },
    "@media (min-height: 600px)": {
      maxHeight: `calc(76vh - 55px)`,
    },
    "@media (min-height: 700px)": {
      maxHeight: `calc(80vh - 55px)`,
    },
    "@media (min-height: 800px)": {
      maxHeight: `calc(82vh - 55px)`,
    },
    "@media (min-height: 900px)": {
      maxHeight: `calc(84vh - 55px)`,
    },
  },
  tableHeader: {
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    borderBottom: "none",
    letterSpacing: "0.7px",
  },
  tableBodyCell: {
    color: theme.palette.text.hintText,
    borderBottom: `0.5px solid ${theme.palette.lockIcon.primary}`,
    fontFamily: "Montserrat",
  },
  widthBox: {
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  text: {
    fontSize: theme.typography.desc,
    fontWeight: "600",
    letterSpacing: "1.5px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  paper: {
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "2px 6px 23px -10px rgba(66, 68, 90, 1)",
  },
  button: {
    width: "240px",
    height: "50px",
    borderRadius: "0px",
  },
  aRouter: {
    color: "inherit",
    textDecoration: "underline",
    "&:hover": {
      color: "inherit",
    },
  },
}));

const paymentStatusMapper = {
  Bought: "Zapłacone",
  Cancelled: "Anulowane",
  Buying: "Przetwarzanie płatności",
};

const ResultRow = ({
  test: { orderState, price, invoiceNeeded, createdAt, items, orderId },
}) => {
  const history = useHistory();
  const classes = useStyles();
  const ccyFormat = (num) => {
    return `${(num / 100).toFixed(2)}`;
  };
  return (
    <TableRow>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {paymentStatusMapper[orderState]}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {items
          .map(
            (item) =>
              item.amount -
              (item.assignments?.filter((a) => a.reclaimedAt).length || 0)
          )
          .reduce((prev, next) => prev + next, 0)}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {" "}
        {`${ccyFormat(price)} PLN`}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {invoiceNeeded ? "Tak" : "Nie"}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {createdAt ? format(new Date(createdAt), "dd-MM-yyyy") : "---"}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        <MuiNavLink onClick={() => history.push(`/active-test/${orderId}`)}>
          Szczegóły
        </MuiNavLink>
      </TableCell>
    </TableRow>
  );
};

const EmptyTestList = () => {
  const classes = useStyles();
  const history = useHistory();
  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";
  return (
    <Box className={classes.root}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} md={10} lg={8} xl={6}>
          <Paper className={classes.paper}>
            <Box p={3}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Box>
                  {isLightGlobalTheme ? (
                    <InfoIcon />
                  ) : (
                    <InfoIconYellow style={{ cursor: "pointer" }} />
                  )}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  className={classes.widthBox}
                >
                  <Typography className={classes.loginTitle}>
                    Brak dostępnych badań
                  </Typography>
                </Box>
              </Box>

              <Box p={2}>
                <Typography className={classes.text}>
                  przejdź na{" "}
                  {
                    <a
                      onClick={() => history.push("/products")}
                      className={classes.aRouter}
                    >
                      stronę
                    </a>
                  }{" "}
                  i zamów badanie dla siebie i twoich Najbliższych.
                </Typography>
              </Box>

              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/products")}
                className={classes.button}
              >
                WYBIERZ BADANIE
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const TestsPurchased = ({}) => {
  const classes = useStyles();
  const headerCells = [
    {
      title: "Status zamówienia",
      key: "orderState",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Ilość badań",
      key: "testsValue",
      sortable: false,
      filter: (key) => <></>,
    },

    {
      title: "Cena",
      key: "price",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Faktura VAT",
      key: "invoiceNeeded",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Data zakupu",
      key: "createdAt",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Opcje",
      key: "options",
      sortable: false,
    },
  ];

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const dispatch = useDispatch();

  const products = useSelector((s) => s.purchasedProducts || []);

  const [fetchingScreenings, setFetchingScreenings] = useState(true);

  const [filterQueries, setFilterQueries] = useState({});
  useEffect(() => {
    fetchProducts();
  }, [order, orderBy]);

  const fetchProducts = async () => {
    const response = await dispatch(
      fetchPurchasedProducts({
        ...(orderBy
          ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
          : {}),
      })
    );
    setFetchingScreenings(false);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };
  const page = () =>
    fetchingScreenings ? (
      <Loader loading={true} text="Wczytywanie badań" />
    ) : products.length > 0 ? (
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headerCells.map((headCell) =>
                headCell.sortable ? (
                  <TableCell
                    key={headCell.key}
                    className={classes.tableHeader}
                    style={{ textAlign: "center" }}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.key}
                      direction={orderBy === headCell.key ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.key);
                      }}
                    >
                      {headCell.title}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell
                    key={headCell.key}
                    className={classes.tableHeader}
                    style={{ textAlign: "center" }}
                  >
                    {headCell.title}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => (
              <ResultRow key={index} test={product} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <EmptyTestList />
    );

  const redirect = () => <Redirect to="/" />;

  return <Can permission="active-tests:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TestsPurchased);
