import React, { useMemo } from "react";
import { Box, Grid, Paper, Typography, Button } from "@material-ui/core";
import StopIcon from "@material-ui/icons/Stop";
import OneRightAnswerResults from "./one-right-answer-results";
import SequenceAnswersResults from "./sequence-answers-results";
import DichoticTestResults from "./dichotic-test-results";
import ASResults from "./as-results";
import AudiometryResults from "./audiometry-results";
import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import { ReactComponent as CloseIconYellow } from "../../assets/close-icon-yellow.svg";
import { useTheme } from "@material-ui/core/styles";
import { lightTheme } from "../../theme";
import { useStyles } from "./results-per-package-table";

const ResultsMapper = ({
  test,
  handleChangeDisplayedResults,
  forDocument = false,
  audiometry,
  ...other
}) => {
  const classes = useStyles();
  const isLightTheme =
    useTheme().palette.primary.main === lightTheme.palette.primary.main;

  const component = useMemo(() => {
    if (test.data) {
      switch (test.data.testEnum) {
        case "AP":
          return <AudiometryResults test={test} />;

        case "GC":
          return <AudiometryResults test={test} audiometry={audiometry} />;

        case "TPR":
          return <OneRightAnswerResults test={test} />;

        case "TDW":
          return <OneRightAnswerResults test={test} />;

        case "TSW":
          return <SequenceAnswersResults test={test} />;

        case "TSD":
          return <SequenceAnswersResults test={test} />;

        case "TRC":
          return <DichoticTestResults test={test} />;

        case "TRS":
          return <DichoticTestResults test={test} />;

        case "TRMS":
          return <OneRightAnswerResults test={test} />;

        case "TMS":
          return <OneRightAnswerResults test={test} />;

        case "TMF":
          return <OneRightAnswerResults test={test} />;

        case "AS":
          return <ASResults test={test} />;
      }
    }
    return null;
  }, [test]);

  if (component) {
    return (
      <Box className={forDocument ? "" : classes.root} {...other}>
        <Grid container style={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} md={forDocument ? 12 : 10}>
            <Paper
              style={{
                textAlign: "center",
                borderRadius: "0px",
                boxShadow: forDocument
                  ? "none"
                  : "2px 6px 23px -10px rgba(66, 68, 90, 1)",
              }}
            >
              <Box
                className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
                style={{ paddingBottom: 16 }}
              >
                <Grid container justify="space-between" alignItems="center">
                  {!forDocument && (
                    <Grid item xs={1}>
                      <CloseIcon style={{ visibility: "hidden" }} />
                    </Grid>
                  )}

                  <Grid item xs={1}>
                    <StopIcon className={classes.leftIcon} />
                  </Grid>
                  <Grid item xs={forDocument ? 10 : 8}>
                    <Typography className={classes.loginTitle}>
                      {test.data.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <StopIcon className={classes.rightIcon} />
                  </Grid>
                  {!forDocument && (
                    <Grid item xs={1}>
                      {isLightTheme ? (
                        <CloseIcon
                          onClick={() => handleChangeDisplayedResults(null)}
                        />
                      ) : (
                        <CloseIconYellow
                          onClick={() => handleChangeDisplayedResults(null)}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
                <br />
              </Box>
              {component}
              <br />
              <br />
              <Box className={`${classes.paddingMedium}`}>
                {!forDocument && (
                  <Box
                    style={{
                      display: "-webkit-flex",
                      display: "flex",
                      WebkitJustifyContent: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant={"contained"}
                      color="primary"
                      style={{
                        borderRadius: "0px",
                        height: "40px",
                        width: "150px",
                      }}
                      onClick={() => handleChangeDisplayedResults(null)}
                    >
                      zamknij
                    </Button>
                  </Box>
                )}
                <br />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  }
  return null;
};

export default ResultsMapper;
