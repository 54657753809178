import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
} from "@material-ui/core";
import { QRScanner } from "../../components/qr-reader";
import Cookies from "js-cookie";
import { logout, saveQRCode } from "../../redux/actions";
import Loader from "../../components/loader";

const QrReaderPage = ({
  match: {
    params: { voucherID },
  },
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [codeTyped, setCodeTyped] = useState("");
  const [qrOpen, setQrOpen] = useState(false);

  const userLoggedIn = useSelector((s) => s.loggedIn);

  const [openQrPanel, setOpenQRPanel] = useState(false);
  const [openTypeCodePanel, setOpenTypeCodePanel] = useState(false);

  const [qrCodeValid, setQrCodeValid] = useState(false);

  useEffect(() => {
    if (codeTyped) {
      setCodeTyped("");
    }
    const parser = document.createElement("a");
    parser.href = code;
    const readedCode = parser.href.split("/").pop();
    if (readedCode.length === 6) {
      Cookies.set("code", readedCode);
      setQrCodeValid(true);
    } else {
      setQrCodeValid(false);
    }
  }, [code]);

  useEffect(() => {
    if (voucherID) {
      setOpenTypeCodePanel(true);
      setCodeTyped(voucherID);
    }
  }, [voucherID]);

  useEffect(() => {
    if (code) {
      setCode("");
    }
    if (codeTyped.length === 6) {
      Cookies.set("code", codeTyped);
      setQrCodeValid(true);
    } else {
      setQrCodeValid(false);
    }
  }, [codeTyped]);

  const changeCode = (data) => {
    setCode(data);
  };

  return (
    <Container>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid
                  item
                  container
                  xs={12}
                  spacing={1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      setOpenQRPanel(false);
                      setOpenTypeCodePanel(true);
                    }}
                  >
                    {`Wpisz KOD ręcznie`}
                  </Button>
                  <p>{`lub`}</p>
                  <Button
                    onClick={() => {
                      setOpenQRPanel(true);
                      setOpenTypeCodePanel(false);
                    }}
                  >
                    {` Zeskanuj KOD QR`}
                  </Button>
                </Grid>
                {openTypeCodePanel && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      value={codeTyped}
                      onChange={({ target: { value: code } }) =>
                        setCodeTyped(code)
                      }
                      label="Wprowadź 6-znakowy kod"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  {openQrPanel && (
                    <>
                      <QRScanner
                        changeCode={changeCode}
                        qrOpen={qrOpen}
                        closeQrPanel={() => setQrOpen(false)}
                        openQrPanel={() => setQrOpen(true)}
                      ></QRScanner>
                      {code.length > 0 &&
                        (qrCodeValid ? (
                          <h3>{`LINK: ${code} `}</h3>
                        ) : (
                          <h3>Wczytany kod jest nieprawidłowy</h3>
                        ))}
                      {/* <h3>{`ZESKANOWANY LINK: ${code} `}</h3> */}
                    </>
                  )}

                  {qrCodeValid && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={() => {
                        if (!userLoggedIn) {
                          history.push("/login");
                        } else {
                          history.push(`/qr-code-validation/${codeTyped}`);
                        }
                      }}
                      // onClick={() => history.push(window.location.hostname)}
                    >
                      Przejdź dalej
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(QrReaderPage);
