import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { statusTypeMap } from "../../../test-stand/mapper";

const useStyles = makeStyles((theme) => ({
  statusText: {
    textTransform: "uppercase",
    fontWeight: "600",
    marginRight: theme.spacing(1),
  },
}));

const ColorStatus = ({ status = "" }) => {
  const classes = useStyles();
  const { label: labelStatus, color } = statusTypeMap[status];
  return (
    <Typography className={classes.statusText} style={{ color }}>
      {labelStatus || "---"}
    </Typography>
  );
};

export default ColorStatus;
