import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    maxHeight: "20px",
  },
  statusContainer: {
    textAlign: "center",
    maxWidth: "50px",
  },
}));

const InfoStatus = ({ text, value, additional }) => {
  const classes = useStyles();

  return (
    <Box mt={1} mb={1} className={classes.flexContainer}>
      <Grid item xs={6} md={4} lg={2}>
        <strong>{text}</strong>
      </Grid>{" "}
      <Grid item xs={4} lg={2}>
        {value}
      </Grid>
      <Grid item xs={2} lg={1}>
        {additional && additional()}
      </Grid>
    </Box>
  );
};

export default InfoStatus;
