import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, Prompt, Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Can from "../../components/can";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  TableBody,
  IconButton,
  TextField,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TestKitPersonalData } from "../../components/test-kit/personalData";
import { TestKitsAmount } from "../../components/test-kit/testAmount";
import {
  MAPDoctorTitle,
  TestKitSummary,
} from "../../components/test-kit/pruchaseSummary";
import { buyCartItem, fetchPriceList } from "../../redux/shopping-cart/actions";
import { QRScanner } from "../../components/qr-reader";
import VoucherCard from "../../components/voucher-card";
import { getAxiosInstance } from "../../redux/common";
import Loader from "../../components/loader";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import {
  alertAdd,
  editProductInCart,
  fetchShoppingCartProducts,
  removeProductInCart,
} from "../../redux/actions";
import { NIPValidator } from "../profile";
import {
  fetchMyProfile,
  updateMyAddressData,
  updateMyBillingData,
} from "../../redux/person/actions";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import CheckIcon from "@material-ui/icons/Check";
import StopIcon from "@material-ui/icons/Stop";

export const CustomCheckIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.text.whiteHeader,
    background: theme.palette.checkIcon.secondary,
  },
}))(CheckIcon);

const CustomTextField = withStyles((theme) => ({
  root: {
    fontFamily: "Montserrat",
    "&$disabled": {
      borderBottomColor: theme.palette.lockIcon.primary,
      borderColor: theme.palette.lockIcon.primary,
      border: theme.palette.lockIcon.primary,
    },
    "& label.Mui-focused": {
      color: theme.palette.text.hintText,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.lockIcon.primary,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
      "& fieldset": {
        borderColor: theme.palette.lockIcon.primary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.lockIcon.primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.lockIcon.primary,
      },
      "&.Mui-disabled fieldset": {
        borderColor: theme.palette.lockIcon.primary,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "5px 10px",
    },
  },
}))(TextField);

const CustomTableCell = withStyles((theme) => ({
  root: {
    paddingTop: "16px",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiStepLabel-active": {
      textTransform: "uppercase",
      letterSpacing: "1.2px",
      color: theme.palette.text.whiteHeader,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& .MuiStepLabel-completed": {
      textTransform: "uppercase",
      letterSpacing: "1.2px",
      color: theme.palette.text.whiteHeader,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  card: {
    minWidth: 250,
    borderRadius: "1rem",
    border: `0.5px solid ${theme.palette.hr.primary}`,
    boxShadow: "none",
  },
  mainGrid: {
    justifyContent: "center",
  },
  hr: {
    opacity: "0.5",
    margin: "2.5em 20px 2.5em 60px",
    border: `0.5px solid ${theme.palette.text.whiteHeader}`,
    [theme.breakpoints.down("lg")]: {
      margin: "1.5em 0 1.5em 0",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  locationButton: {
    marginTop: "3em",
    backgroundColor: theme.palette.background.orderButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `162px`,
    height: "50px",
  },
  paper: {
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  text: {
    color: theme.palette.passwordIcon2.primary,
    fontSize: theme.typography.paragraph,
    letterSpacing: "1.5px",
    fontWeight: "700",
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  boxLoginTitle: {
    background: theme.palette.background.loginBackground,
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingMedium: {
    padding: "16px 32px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  tableCellContentAlign: {
    verticalAlign: "top",
    textAlign: "center",
  },
  tableHeader: {
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    borderBottom: "none",
    letterSpacing: "0.7px",
  },
  tableBodyCell: {
    color: theme.palette.text.hintText,
    borderBottom: `0.5px solid ${theme.palette.lockIcon.primary}`,
    fontFamily: "Montserrat",
  },
  tableLastBodyCell: {
    border: "none",
  },
  tableBodyRemoveButton: {
    color: theme.palette.lockIcon.primary,
    letterSpacing: "0.7px",
  },
  priceText: {
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    color: theme.palette.text.alternative2,
    fontWeight: "600",
    fontSize: theme.typography.footerMobile,
    letterSpacing: "0.8px",
  },
  priceValueBox: {
    background: theme.palette.background.filter,
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    borderRadius: "9px",
  },
  priceValueText: {
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    color: theme.palette.text.alternative2,
    fontWeight: "600",
    fontSize: theme.typography.text,
  },
  incrementIcon: {
    border: `1px solid ${theme.palette.lockIcon.primary}`,
    opacity: "1",
    borderBottomLeftRadius: "0px",
    borderTopLeftRadius: "0px",
    color: theme.palette.lockIcon.primary,
    height: theme.typography.formLabel,
    width: "20px",
  },
  saveChangesButton: {
    color: theme.palette.text.hintText,
    fontSize: theme.typography.footerMobile,
  },
  labelStyle: {
    wordBreak: "break-word",
  },
  labelActiveColor: {
    color: theme.palette.text.alternative2,
  },
}));

const getSteps = () => {
  return [
    { id: 0, label: "Wybrane badania" },
    { id: 1, label: "Twoje dane" },
    { id: 2, label: "Podsumowanie" },
  ];
};

const getStepContent = (stepIndex) => {
  switch (stepIndex) {
    case 0:
      return "0";
    case 1:
      return "1";
    case 2:
      return "2";
    case 3:
      return "3";
    default:
      return "Unknown stepIndex";
  }
};

const TestKit = ({
  match: {
    params: { id },
  },
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [nextButtonValid, setNextButtonValid] = useState(true);
  const [
    amountChangedNextButtonValid,
    setAmountChangedNextButtonValid,
  ] = useState(true);

  const [deliveryInfo, setDeliveryInfo] = useState({});
  const [billingInfo, setBillingInfo] = useState({});

  const [provideShippingData, setProvideShippingData] = useState(true);
  const [provideFVData, setProvideFVData] = useState(false);

  const [saveShippingData, setSaveShippingData] = useState(false);
  const [saveBillingData, setSaveBillingData] = useState(false);

  const shoppingCartProducts = useSelector((s) => s.shoppingCartProducts);
  const shoppingCartProductsFetching = useSelector(
    (s) => s.shoppingCartProductsFetching
  );

  const [productsToBuy, setProductsToBuy] = useState([]);
  const [fetchingProducts, setFetchingProducts] = useState(true);

  const steps = getSteps();

  useEffect(() => {
    setProductsToBuy(localStorage.getItem("productsToBuy") || []);
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);

  const onStorageUpdate = async (e) => {
    const { key, newValue } = e;
    if (key === "productsToBuy1") {
      setProductsToBuy(JSON.parse(newValue));
    } else if (key === "productsToBuy2") {
      setProductsToBuy(JSON.parse(newValue));
      await dispatch(fetchShoppingCartProducts());
    }
  };

  const amountHandler = (e, id) => {
    setDataChanged(true);
    const value = e.target.value;
    if (value.match(/^[1-9]\d*$/)) {
      setProductsToBuy(
        productsToBuy.map((p) =>
          p.id === id
            ? {
                ...p,
                amount: parseInt(value),
              }
            : p
        )
      );
      localStorage.setItem(
        "productsToBuy1",
        JSON.stringify(
          productsToBuy.map((p) =>
            p.id === id
              ? {
                  ...p,
                  amount: value,
                }
              : p
          )
        )
      );

      setAmountChangedNextButtonValid(
        shoppingCartProducts.find((p) => p.id === id).amount == e.target.value
      );
    }
  };

  const amountHandlerAction = (e, id, action) => {
    setDataChanged(true);
    setProductsToBuy(
      productsToBuy.map((p) =>
        p.id === id
          ? {
              ...p,
              amount: action === "decrement" ? p.amount - 1 : p.amount + 1,
            }
          : p
      )
    );
    localStorage.setItem(
      "productsToBuy1",
      JSON.stringify(
        productsToBuy.map((p) =>
          p.id === id
            ? {
                ...p,
                amount: action === "decrement" ? p.amount - 1 : p.amount + 1,
              }
            : p
        )
      )
    );
    setAmountChangedNextButtonValid(
      shoppingCartProducts.find((p) => p.id === id).amount ===
        (action === "decrement"
          ? productsToBuy.find((p) => p.id === id).amount - 1
          : productsToBuy.find((p) => p.id === id).amount + 1)
    );
  };

  const amountClickHandler = async (value, id) => {
    try {
      const response = await dispatch(
        editProductInCart({
          amount: value,
          id,
        })
      );

      if (response) {
        await dispatch(fetchShoppingCartProducts());
      }

      setAmountChangedNextButtonValid(true);
      setDataChanged(false);
    } catch (e) {
      console.log(e);
    }
  };

  const removeItemClickHandler = async (id) => {
    try {
      const response = await dispatch(
        removeProductInCart({
          id,
        })
      );
      if (response) {
        setFetchingProducts(true);
        await dispatch(fetchShoppingCartProducts());
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (shoppingCartProducts && !shoppingCartProductsFetching) {
      setFetchingProducts(true);
      fetchPackages();
    }
  }, [shoppingCartProducts, shoppingCartProductsFetching]);

  useEffect(() => {
    if (activeStep === 1) {
      if (provideFVData) {
        setNextButtonValid(
          deliveryInfo.name &&
            deliveryInfo.street &&
            deliveryInfo.city &&
            // deliveryInfo.post &&
            zipValidator(deliveryInfo.zipCode) &&
            billingInfo.nameFV &&
            billingInfo.streetFV &&
            billingInfo.cityFV &&
            // billingInfo.postFV &&
            zipValidator(billingInfo.zipFV) &&
            NIPValidator(billingInfo.taxID)
        );
      } else {
        setNextButtonValid(
          deliveryInfo.name &&
            // deliveryInfo.post &&
            deliveryInfo.street &&
            deliveryInfo.city &&
            zipValidator(deliveryInfo.zipCode)
        );
      }
    } else {
      setNextButtonValid(true);
    }
  }, [deliveryInfo, billingInfo, provideFVData, activeStep]);

  const zipValidator = (zip) => {
    let isZipCodeOk;
    if (zip && /^\d{2}-\d{3}$/.test(zip)) {
      isZipCodeOk = true;
    } else {
      isZipCodeOk = false;
    }
    return isZipCodeOk;
  };

  const fetchPackages = async () => {
    let hearBoxTestArray = [];

    for (let i = 0; i < shoppingCartProducts.length; i++) {
      const {
        id,
        hearBoxTestId,
        amount,
        price,
        describeBy,
        patientScreeningId,
        hearBoxId,
        hearBoxScreeningId,
        screeningId,
        tenantId,
        patientId,
      } = shoppingCartProducts[i];
      const result = await getAxiosInstance().get(
        `/api/examination_package/${shoppingCartProducts[i].hearBoxTestId}`
      );
      hearBoxTestArray.push({
        id,
        hearBoxTestId,
        amount,
        price,
        ...(describeBy && { describeBy }),
        label: result.data.label,
        desc: result.data.description,
        patientScreeningId,
        hearBoxId,
        hearBoxScreeningId,
        screeningId,
        tenantId,
        patientId,
      });
    }
    setProductsToBuy(hearBoxTestArray);

    localStorage.setItem("productsToBuy2", JSON.stringify(hearBoxTestArray));
    setFetchingProducts(false);
  };

  const total = () => {
    return productsToBuy
      .map(({ price }) => price)
      .reduce((sum, i) => sum + i, 0);
  };

  const ccyFormat = (num) => {
    return `${(num / 100).toFixed(2)}`;
  };

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    if (saveShippingData) {
      await dispatch(
        updateMyAddressData({
          name: deliveryInfo.name,
          street: deliveryInfo.street,
          zipCode: deliveryInfo.zipCode,
          city: deliveryInfo.city,
          // post: deliveryInfo.post,
          notes: deliveryInfo.notes,
          phone: deliveryInfo.phone,
        })
      );
    }

    if (saveBillingData) {
      await dispatch(
        updateMyBillingData({
          name: billingInfo.nameFV,
          street: billingInfo.streetFV,
          zipCode: billingInfo.zipFV,
          city: billingInfo.cityFV,
          // post: billingInfo.postFV,
          taxId: billingInfo.taxID,
        })
      );
    }

    // await dispatch(fetchShoppingCartProducts());
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = async () => {
    try {
      const cart = await dispatch(fetchShoppingCartProducts());
      if (cart.length === 0) {
        history.push("/test-kit/add");
      } else {
        setDataChanged(false);
        const response = await dispatch(
          buyCartItem({
            deliveryInfo,
            billingInfo,
            provideFVData,
          })
        );
        if (response) {
          const onlyHearBoxTestDescription = cart.filter(
            (item) => item.orderItemType === "HearBoxTestDescription"
          );
          if (onlyHearBoxTestDescription.length === cart.length) {
            dispatch(
              alertAdd({
                text: "Dziękujemy za zakup opisu lekarskiego.",
                isSuccess: true,
                customModalAlert: true,
                // timeout: 10000,
                persistent: true,
              })
            );
          } else {
            dispatch(
              alertAdd({
                text:
                  "Dziękujemy za zakup badania. Pamiętaj aby przed udaniem się do stanowiska HearBox przypisać badanie do pacjenta.",
                redirect: {
                  text: "Przypisz",
                  href: `/active-test/${response}`,
                },
                isSuccess: true,
                customModalAlert: true,
                // timeout: 10000,
                persistent: true,
              })
            );
          }

          await dispatch(fetchShoppingCartProducts());
          if (saveBillingData || saveShippingData) {
            await dispatch(fetchMyProfile());
          }
          history.push("/active-tests");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const stepper = (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      style={{
        background: "inherit",
        paddingTop: "16px",
        paddingBottom: "16px",
      }}
    >
      {steps.map((step) => (
        <Step key={step.id}>
          <StepLabel
            StepIconComponent={activeStep > step.id && CustomCheckIcon}
            className={classes.root}
          >
            <span
              className={`${classes.labelStyle} ${
                activeStep === step.id && classes.labelActiveColor
              }`}
            >
              {step.label}
            </span>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );

  const page = () =>
    fetchingProducts || shoppingCartProductsFetching ? (
      <Box p={1}>
        <Loader loading={true} text="Pobieranie produktów w koszyku..." />
      </Box>
    ) : productsToBuy?.length !== 0 ? (
      <Box className={classes.root}>
        {stepper}
        {activeStep === 0 ? (
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "16px",
            }}
          >
            <Grid item xs={12} md={10}>
              <Paper className={classes.paper}>
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
                >
                  <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                  <Typography className={classes.loginTitle}>Koszyk</Typography>
                  <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
                </Box>
                <Box className={`${classes.paddingMedium}`}>
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-label="spanning table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            width="35%"
                            className={classes.tableHeader}
                          >
                            Badania
                          </TableCell>
                          <TableCell
                            width="25%"
                            style={{
                              verticalAlign: "top",
                              textAlign: "center",
                            }}
                            className={classes.tableHeader}
                          >
                            Lekarz opisujący badanie
                          </TableCell>
                          <TableCell
                            width="15%"
                            style={{ textAlign: "center" }}
                            className={classes.tableHeader}
                          >
                            Ilość
                          </TableCell>

                          <TableCell
                            width="15%"
                            style={{ textAlign: "center" }}
                            className={classes.tableHeader}
                          >
                            Cena
                          </TableCell>
                          <TableCell
                            width="10%"
                            className={classes.tableHeader}
                            style={{ textAlign: "center" }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {productsToBuy?.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell
                              width="35%"
                              className={classes.tableBodyCell}
                            >
                              {row.label}
                            </TableCell>
                            <TableCell
                              width="25%"
                              style={{ textAlign: "center" }}
                              className={classes.tableBodyCell}
                            >
                              {row.describeBy
                                ? MAPDoctorTitle[row.describeBy]
                                : `Bez opisu`}
                            </TableCell>
                            <TableCell
                              width="15%"
                              style={{ textAlign: "center" }}
                              className={classes.tableBodyCell}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {!row.patientId && (
                                  <IconButton
                                    disabled={row?.amount < 2}
                                    onClick={(e) =>
                                      amountHandlerAction(
                                        e,
                                        row.id,
                                        "decrement"
                                      )
                                    }
                                  >
                                    <RemoveOutlinedIcon
                                      role="img"
                                      aria-hidden="false"
                                      focusable="true"
                                      alt="zmniejsza wartość w koszyku o 1"
                                      title="zmniejsza wartość w koszyku o 1"
                                      className={classes.incrementIcon}
                                    />
                                  </IconButton>
                                )}
                                <CustomTextField
                                  style={{ width: "55px" }}
                                  size="small"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    min: 0,
                                    style: { textAlign: "center" },
                                  }}
                                  value={row?.amount}
                                  variant="outlined"
                                  onFocus={(event) => {
                                    event.target.select();
                                  }}
                                  onChange={(e) => amountHandler(e, row.id)}
                                  type="text"
                                  InputProps={{
                                    style: { fontFamily: "Montserrat" },
                                  }}
                                  disabled={row.patientId}
                                />
                                {!row.patientId && (
                                  <IconButton
                                    onClick={(e) =>
                                      amountHandlerAction(
                                        e,
                                        row.id,
                                        "increment"
                                      )
                                    }
                                  >
                                    <AddOutlinedIcon
                                      style={{
                                        transform: "rotate(180deg)",
                                      }}
                                      role="img"
                                      aria-hidden="false"
                                      alt="zwiększa wartość w koszyku o 1"
                                      title="zwiększa wartość w koszyku o 1"
                                      className={classes.incrementIcon}
                                    />
                                  </IconButton>
                                )}
                              </Box>
                              {shoppingCartProducts.find((p) => p.id === row.id)
                                .amount != row.amount && (
                                <Button
                                  onClick={() =>
                                    amountClickHandler(row.amount, row.id)
                                  }
                                  className={classes.saveChangesButton}
                                >
                                  Zapisz zmiany
                                </Button>
                              )}
                            </TableCell>
                            <TableCell
                              width="15%"
                              style={{ textAlign: "center" }}
                              className={classes.tableBodyCell}
                            >
                              {`${ccyFormat(row.price)} PLN`}
                            </TableCell>
                            <TableCell
                              width="10%"
                              style={{ textAlign: "center" }}
                              className={`${classes.tableBodyCell}`}
                            >
                              <Button
                                className={`${classes.tableBodyRemoveButton}`}
                                onClick={() => removeItemClickHandler(row.id)}
                              >
                                Usuń
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}

                        <TableRow style={{ paddingTop: 20 }}>
                          <CustomTableCell
                            className={classes.tableLastBodyCell}
                          />
                          <CustomTableCell
                            className={classes.tableLastBodyCell}
                          />
                          <CustomTableCell
                            className={`${classes.tableLastBodyCell} `}
                            style={{ textAlign: "center" }}
                          >
                            <Typography className={classes.priceText}>
                              Suma łącznie
                            </Typography>
                          </CustomTableCell>
                          <CustomTableCell
                            className={classes.tableLastBodyCell}
                            style={{ textAlign: "center" }}
                          >
                            <Typography
                              className={`${classes.priceText} ${classes.priceValueBox}`}
                              style={{}}
                            >
                              <Typography className={classes.priceValueText}>
                                {" "}
                                {`${ccyFormat(total())}`}
                              </Typography>
                              <span style={{ margin: "5px" }}>PLN</span>
                            </Typography>
                          </CustomTableCell>
                          <CustomTableCell
                            className={classes.tableLastBodyCell}
                          />
                        </TableRow>
                        <TableRow style={{ paddingTop: 20 }}>
                          <CustomTableCell
                            className={classes.tableLastBodyCell}
                          />
                          <CustomTableCell
                            className={classes.tableLastBodyCell}
                          />
                          <CustomTableCell
                            className={classes.tableLastBodyCell}
                          />
                          <CustomTableCell
                            colSpan={2}
                            className={classes.tableLastBodyCell}
                            style={{ textAlign: "center" }}
                          >
                            <Tooltip
                              placement="left-start"
                              title={
                                amountChangedNextButtonValid
                                  ? ""
                                  : "Aby przejść dalej musisz zapisać zmiany"
                              }
                            >
                              <div style={{ display: "block" }}>
                                <Button
                                  type="submit"
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  disabled={
                                    !nextButtonValid ||
                                    !amountChangedNextButtonValid
                                  }
                                  onClick={
                                    activeStep === steps.length - 1
                                      ? handleFinish
                                      : handleNext
                                  }
                                  style={{
                                    width: "100%",
                                    maxWidth: "250px",
                                    borderRadius: "0px",
                                    height: "50px",
                                  }}
                                >
                                  Dalej
                                </Button>
                              </div>
                            </Tooltip>
                          </CustomTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box pb={3} pt={1} pr={5} style={{ textAlign: "right" }}></Box>
              </Paper>
            </Grid>
          </Grid>
        ) : activeStep === 1 ? (
          <TestKitPersonalData
            deliveryInfo={deliveryInfo}
            setDeliveryInfo={setDeliveryInfo}
            billingInfo={billingInfo}
            setBillingInfo={setBillingInfo}
            provideShippingData={provideShippingData}
            setProvideShippingData={setProvideShippingData}
            provideFVData={provideFVData}
            setProvideFVData={setProvideFVData}
            saveShippingData={saveShippingData}
            setSaveShippingData={setSaveShippingData}
            saveBillingData={saveBillingData}
            setSaveBillingData={setSaveBillingData}
            setDataChanged={setDataChanged}
            handleFinish={handleFinish}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
            nextButtonValid={nextButtonValid}
          />
        ) : (
          <TestKitSummary
            productsToBuy={productsToBuy}
            deliveryInfo={deliveryInfo}
            billingInfo={billingInfo}
            setActiveStep={setActiveStep}
            provideShippingData={provideShippingData}
            provideFVData={provideFVData}
            handleFinish={handleFinish}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
          />
        )}
        <Prompt
          when={dataChanged}
          message={(params) =>
            params.pathname !== window.location.pathname && dataChanged
              ? "Wprowadzone dane nie zostały zapisane. Czy na pewno chcesz opuścić stronę?"
              : true
          }
        ></Prompt>
        {/* <Box className={classes.footer}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
          >
            Cofnij
          </Button>
          <Tooltip
            placement="left-start"
            title={
              amountChangedNextButtonValid
                ? ""
                : "Aby przejść dalej musisz zapisać zmiany"
            }
          >
            <div style={{ display: "inline-block" }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={!nextButtonValid || !amountChangedNextButtonValid}
                onClick={
                  activeStep === steps.length - 1 ? handleFinish : handleNext
                }
              >
                {activeStep === steps.length - 1 ? "Zapłać" : "Dalej"}
              </Button>
            </div>
          </Tooltip>
          <Prompt
            when={dataChanged}
            message={(params) =>
              params.pathname !== window.location.pathname && dataChanged
                ? "Wprowadzone dane nie zostały zapisane. Czy na pewno chcesz opuścić stronę?"
                : true
            }
          ></Prompt>
        </Box> */}
      </Box>
    ) : (
      <Box className={classes.root}>
        <Grid container style={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
            <Paper className={classes.paper}>
              <Box pt={4} pb={1}>
                <Typography className={classes.text}>
                  Twój koszyk jest pusty
                </Typography>
              </Box>
              <Box pb={3}>
                <Link to={"/products"}>
                  <Button
                    variant={"contained"}
                    className={classes.locationButton}
                  >
                    Złóż zamówienie
                  </Button>
                </Link>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  const redirect = () => <Redirect to="/login" />;
  return <Can permission="test-kit:page" ok={page} not={redirect} />;
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TestKit);
