import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";

const PrivateRoute = ({ loggedIn, location, ...props }) => {
  const employeeLogout = useSelector((s) => s.isEmployeeLastLogout);
  return (
    <Route
      path={props.path}
      exact={props.exact}
      component={
        loggedIn
          ? props.component
          : () => (
              <Redirect
                to={
                  props.path === "/voucher/:voucherID" ||
                  props.path === "/msg/account_activated" ||
                  props.path === "/msg/account_activation_failed"
                    ? location.pathname
                    : employeeLogout
                    ? window.localStorage.getItem("firstUrl") === "/login"
                      ? "/login-employee"
                      : "/start"
                    : window.localStorage.getItem("firstUrl") === "/login"
                    ? "/login"
                    : "/start"
                }
              />
            )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.loggedIn,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
