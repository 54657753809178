import React, { useState, useEffect, useRef } from "react";
import QrReader from "modern-react-qr-reader";
import { useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NoVideoInputDevicesError } from "modern-react-qr-reader/lib/errors";

const useStyles = makeStyles((theme) => ({
  hidden: {
    visibility: "hidden",
    height: "0px",
    width: "0px",
  },
  visible: {
    visibility: "visible",
    // height: "auto",
    height: "300px",
    width: "300px",
    border: "10px",
  },
  margin: {
    margin: theme.spacing(0.5),
  },
  text: {
    fontSize: theme.typography.hintText,
    color: theme.palette.text.alternative3,
    background: theme.palette.background.typography,
    fontWeight: "600",
    padding: "8px",
  },
  locationButton: {
    backgroundColor: theme.palette.locationIcon.primary,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `${theme.typography.buttonWidth}px`,
    height: "45px",
  },
  testButton: {
    backgroundColor: theme.palette.testIcon.primary,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `${theme.typography.buttonWidth}px`,
    height: "45px",
  },
}));

const hasCapabilities = () => {
  return !!(
    navigator.mediaDevices &&
    navigator.mediaDevices.getUserMedia &&
    navigator.mediaDevices.enumerateDevices
  );
};

export const QRScanner = ({
  changeCode,
  qrOpen,
  openQrPanel,
  closeQrPanel,
  ...props
}) => {
  const classes = useStyles();

  const qrReader = useRef();
  const [camera, setCamera] = useState("environment");
  const [permissionGranted, setPermissionGranted] = useState(hasCapabilities());
  const [legacyMode, setLegacyMode] = useState(!permissionGranted);
  const [legacyScanUnsuccessful, setLegacyScanUnsuccessful] = useState(false);
  const [wasDialogOpened, setWasDialogOpened] = useState(false);
  const [ready, setReady] = useState(false);

  const handleScan = (data) => {
    if (data) {
      changeCode(data);
    } else if (legacyMode && wasDialogOpened) {
      setLegacyScanUnsuccessful(true);
    }
  };

  const handleError = (err) => {
    console.warn(err);
    if (
      err &&
      (err.name === "NoVideoInputDevicesError" ||
        err.name === "NotAllowedError")
    ) {
      setPermissionGranted(false);
      setLegacyMode(true);
    }
  };

  const handleCamera = () => {
    if (legacyMode) {
      setLegacyMode(false);
      openQrPanel();
    } else {
      setCamera((prevState) =>
        prevState === "environment" ? "user" : "environment"
      );
    }
  };

  const handleLegacyMode = () => {
    if (legacyMode) {
      setLegacyScanUnsuccessful(false);
      setWasDialogOpened(true);
      if (qrReader.current) {
        qrReader.current.openImageDialog();
      }
    } else {
      setLegacyMode(true);
      closeQrPanel();
    }
  };

  useEffect(() => {
    if (ready && qrReader.current) {
      qrReader.current.forceUpdate(); //forces rerender - component bug
    }
  }, [legacyMode, legacyScanUnsuccessful, permissionGranted, camera]);

  useEffect(() => {
    //prevents case when legacyScanUnsuccessful is set to true in handleScan when switching legacyMode
    setWasDialogOpened(false);
    setLegacyScanUnsuccessful(false);
  }, [legacyMode]);

  const globalTheme = useSelector((s) => s.globalTheme || "light");

  return (
    <Box {...props}>
      {ready && !permissionGranted && legacyMode && (
        <Alert
          style={{
            backgroundColor: globalTheme === "high-contrast" && "#000000",
            border: globalTheme === "high-contrast" && "1px solid #ffff00",
            color: globalTheme === "high-contrast" && "#ffff00",
          }}
          severity="info"
          className={`${classes.margin} ${classes.text}`}
        >
          Przeglądarka nie ma uprawnień do kamery, proszę kliknąć "Wczytaj kod
          QR ze zdjęcia".
        </Alert>
      )}
      {ready && legacyScanUnsuccessful && (
        <Alert
          style={{
            backgroundColor: globalTheme === "high-contrast" && "#000000",
            border: globalTheme === "high-contrast" && "1px solid #ffff00",
            color: globalTheme === "high-contrast" && "#ffff00",
          }}
          severity="info"
          className={`${classes.margin} ${classes.text}`}
        >
          Numer nie został wykryty. Spróbuj ponownie z innym zdjęciem.
        </Alert>
      )}
      {ready && (
        <Box mb={1} style={{ textAlign: "center" }}>
          {permissionGranted && (
            <span>
              {!legacyMode && (
                <Typography
                  // severity="info"
                  style={{
                    backgroundColor:
                      globalTheme === "high-contrast" && "#000000",
                    border:
                      globalTheme === "high-contrast" && "1px solid #ffff00",
                    color: globalTheme === "high-contrast" && "#ffff00",
                  }}
                  className={`${classes.margin} ${classes.text}`}
                >
                  Jeśli nie widzisz obrazu z kamery spróbuj kliknąć "Zmień
                  kamerę", możesz też wczytać zdjęcie poprzez kliknięcie
                  "Zamknij kamerę" a następnie "Wczytaj kod QR ze zdjęcia".
                </Typography>
              )}
              <Button
                className={`${classes.margin} ${classes.testButton}`}
                variant="contained"
                onClick={handleCamera}
              >
                {legacyMode ? "Wczytaj z kamery" : "Zmień kamerę"}
              </Button>
            </span>
          )}
          <Button
            className={`${classes.margin} ${classes.locationButton}`}
            variant="contained"
            onClick={handleLegacyMode}
          >
            {legacyMode ? "Wczytaj kod QR ze zdjęcia" : "Zamknij kamerę"}
          </Button>
        </Box>
      )}
      {
        <Box m={1} style={{ display: "flex", justifyContent: "center" }}>
          <QrReader
            className={
              (permissionGranted && !legacyMode) || legacyScanUnsuccessful
                ? classes.visible
                : classes.hidden
            }
            ref={qrReader}
            facingMode={camera}
            legacyMode={legacyMode}
            onLoad={() => setReady(true)}
            onError={handleError}
            onScan={handleScan}
          />
        </Box>
      }
    </Box>
  );
};
