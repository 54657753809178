import React, { useState, useEffect } from "react";
import { getAxiosInstance } from "../../redux/common";
import {
  Radio,
  TextField,
  Button,
  Container,
  Paper,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import Loader from "../../components/loader";
import { Visibility } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import { getTenantContext } from "../../redux/admin/actions";

function LoginForm(props) {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [isUserLoggedIn, setUserLoggedIn] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [role, setRole] = useState("admin");
  const onUserChange = props.onUserChange
    ? props.onUserChange
    : () => {
        console.log("Set onUserChange prop!");
      };

  function handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  }

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  async function checkLogin() {
    try {
      await getAxiosInstance().get("/api/auth/check-owner");
      setUserLoggedIn(true);
    } catch (e) {
      setUserLoggedIn(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const loginData = {
      email: formData.email,
      password: formData.password,
    };

    try {
      if (role) {
        if (role === "admin") {
          const response = await getAxiosInstance().post(
            "/api/auth/login-owner",
            loginData
          );
          const identityResponse = await getAxiosInstance().get(
            "/api/auth/identity"
          );
          if (response && identityResponse) {
            onUserChange();
            setUserLoggedIn(true);
          }
        } else if (role === "doctor") {
          const response = await getAxiosInstance().post(
            "/api/auth/login-medic-consultant",
            loginData
          );
          const identityResponse = await getAxiosInstance().get(
            "/api/auth/identity"
          );
          if (response && identityResponse) {
            onUserChange();
            setUserLoggedIn(true);
          }
          onUserChange();
          setUserLoggedIn(true);
        }
      }
    } catch (e) {
      setUserLoggedIn(false);
      setErrorMessage("Error");
    }
  }

  useEffect(() => {
    checkLogin();
  }, []);

  if (isUserLoggedIn === undefined) {
    return <Loader loading={true} text="Uwierzytelnianie" />;
  } else if (isUserLoggedIn) {
    return <Redirect to="/admin-panel" />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          minHeight="100vh"
        >
          {" "}
          {errorMessage && (
            <Box mb={2}>
              <Alert severity="error">Błąd logowania</Alert>
            </Box>
          )}
          <Paper elevation={3}>
            <Box pl={4} pr={4} pt={2} pb={2}>
              <form onSubmit={handleSubmit}>
                <Box display="block" m={1}>
                  <TextField
                    name="email"
                    label="User name"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                  />
                </Box>
                <Box display="block" m={1}>
                  <TextField
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={formData.password}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            aria-label="toggle password visibility"
                          >
                            <Visibility />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box display="block" m={1}>
                  <FormControl component="role-form">
                    <RadioGroup
                      aria-label="role-radio"
                      name="role"
                      value={role}
                      onChange={handleRoleChange}
                      row
                    >
                      <FormControlLabel
                        value="admin"
                        control={<Radio />}
                        label="Administrator"
                      />
                      <FormControlLabel
                        value="doctor"
                        control={<Radio />}
                        label="Lekarz"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box m={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Zaloguj się
                  </Button>
                </Box>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  }
}

export default LoginForm;
