import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardContent,
  ClickAwayListener,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  withStyles,
  Zoom,
} from "@material-ui/core";
import DefaultInfoIcon from "@material-ui/icons/Info";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import styled from "styled-components";
import { stationTypeMap, statusTypeMap } from "./mapper";

const CenteredTableCell = styled(TableCell)`
  text-align: center !important;
`;
const InfoIcon = styled(DefaultInfoIcon)`
  margin-left: 5px;
  color: #d3d3d3;
`;

const useStyles = makeStyles((theme) => ({
  spacing: {
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
    boxShadow: "none",
  },
  root: {
    position: "relative",
    whiteSpace: "nowrap",
    // minWidth: "212px",
  },
  flexBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  statusIcon: {
    minHeight: "40px",
    minWidth: "40px",
  },
  linkButton: {
    "&:hover": {
      color: "white",
    },
  },
}));

const TextOnlyTooltip = withStyles({
  tooltip: {
    display: "block",
    color: "black",
    backgroundColor: "transparent",
    maxWidth: "500px",
  },
})(Tooltip);

const TestStandRow = ({
  testStandData,
  setOpenTestStandModal,
  setTestStandsToEdit,
  setTestStandsHandlerType,
  setTestStandToRemove,
  setOpenTestStandRemovePanel,
}) => {
  const {
    hearBoxId: id,
    label,
    city,
    stationType,
    voivodeship,
    country,
    street,
    postCode,
    status,
  } = testStandData;
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { label: labelStatus, color } = statusTypeMap[status] || null;

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [openAddress, setOpenAddress] = useState(false);

  const handleTooltipCloseAddress = () => {
    setOpenAddress(false);
  };

  const handleTooltipOpenAddress = () => {
    setOpenAddress(true);
  };

  const testStandEditionHandler = () => {
    setTestStandsHandlerType("edition");
    setTestStandsToEdit(testStandData);
    setOpenTestStandModal(true);
  };

  const AddressCard = () => (
    <Card>
      <CardContent>
        <Typography variant="body2">Kraj: {country}</Typography>
        <Typography variant="body2">Województwo: {voivodeship}</Typography>
        <Typography variant="body2">Miasto: {city}</Typography>
        <Typography variant="body2">Ulica: {`${street}`}</Typography>
        <Typography variant="body2">Kod pocztowy: {`${postCode}`}</Typography>
        <Typography variant="body2"></Typography>
      </CardContent>
    </Card>
  );

  const IdCard = () => (
    <Card>
      <CardContent>
        <Typography variant="body2">{id}</Typography>
      </CardContent>
    </Card>
  );

  return (
    <TableRow>
      <TableCell>
        <Box display={"flex"} alignItems={"center"}>
          {label || "---"}{" "}
          <Box display="flex" className={classes.root}>
            {!smallScreen ? (
              <TextOnlyTooltip
                transitionComponent={Zoom}
                interactive
                title={<IdCard />}
              >
                <InfoIcon />
              </TextOnlyTooltip>
            ) : (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <TextOnlyTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    transitionComponent={Zoom}
                    interactive
                    title={<IdCard />}
                  >
                    <InfoIcon onClick={handleTooltipOpen} />
                  </TextOnlyTooltip>
                </div>
              </ClickAwayListener>
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Box display={"flex"} alignItems={"center"}>
          {city || "---"}{" "}
          <Box display="flex" className={classes.root}>
            {!smallScreen ? (
              <TextOnlyTooltip
                className={classes.tooltip}
                transitionComponent={Zoom}
                interactive
                title={<AddressCard />}
              >
                <InfoIcon />
              </TextOnlyTooltip>
            ) : (
              <ClickAwayListener onClickAway={handleTooltipCloseAddress}>
                <div>
                  <TextOnlyTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipCloseAddress}
                    open={openAddress}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    transitionComponent={Zoom}
                    interactive
                    title={<AddressCard />}
                  >
                    <InfoIcon onClick={handleTooltipOpenAddress} />
                  </TextOnlyTooltip>
                </div>
              </ClickAwayListener>
            )}
          </Box>
        </Box>
      </TableCell>

      <CenteredTableCell>
        <Box className={classes.flexBox}>
          <FiberManualRecordIcon
            style={{ fill: color }}
            className={classes.statusIcon}
          />
          {labelStatus || "---"}
        </Box>
      </CenteredTableCell>
      <CenteredTableCell>
        {stationTypeMap[stationType] || "---"}
      </CenteredTableCell>
      <TableCell>
        <ButtonGroup
          className={classes.spacing}
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          <Button
            variant={"contained"}
            color="primary"
            onClick={testStandEditionHandler}
          >
            Konfiguruj
          </Button>
          <Button
            variant={"contained"}
            color="primary"
            onClick={() => {
              setTestStandToRemove(testStandData);
              setOpenTestStandRemovePanel(true);
            }}
          >
            Usuń
          </Button>

          <Button
            className={classes.linkButton}
            component={Link}
            to={`/admin-panel/test-stands/monitoring/${id}`}
            variant={"contained"}
            color="primary"
          >
            Monitoring
          </Button>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
};

export default TestStandRow;
