import React, { useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import QRCode from "qrcode";
import ReactToPrint from "react-to-print";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialogActions-root": {
      justifyContent: "center",
    },
    "& .MuiDialog-paper": {
      borderRadius: "0px",
      padding: "16px 8px",
    },
  },
  title: {
    color: theme.palette.text.title,
    fontSize: theme.typography.fontSize,
    textTransform: "uppercase",
    fontWeight: "600",
    letterSpacing: "1.5px",
    textAlign: "center",
  },
  idText: {
    fontSize: theme.typography.text2,
    color: theme.palette.text.id,
    fontWeight: "700",
    textAlign: "center",
    letterSpacing: "1.5px",
  },
  idPin: {
    fontSize: theme.typography.text2,
    color: theme.palette.text.pin,
    fontWeight: "700",
    textAlign: "center",
    letterSpacing: "1.5px",
  },
  text: {
    color: theme.palette.text.buttonTitle,
    fontSize: theme.typography.hintText,
    letterSpacing: "1px",
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: "700",
  },
  locationButton: {
    backgroundColor: theme.palette.background.saveButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `115px`,
    height: "40px",
    marginRight: "15px",
  },
  testButton: {
    backgroundColor: theme.palette.testIcon.primary,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `115px`,
    height: "40px",
    marginRight: "5px",
  },
  closeButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
    color: theme.palette.grey[500],
  },
}));

const ModalAlert = ({
  onClose,
  text,
  redirectPath = "",
  redirectText = "",
}) => {
  const classes = useStyles();
  const history = useHistory();

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const handleClick = () => {
    history.push(redirectPath);
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      open={true}
      fullWidth
      maxWidth={smallScreen ? "xl" : "sm"}
      className={classes.root}
    >
      <DialogTitle id="qr code">
        <Typography className={classes.title}>{text}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {redirectText && (
        <DialogActions>
          <Button
            className={classes.locationButton}
            variant={"contained"}
            onClick={handleClick}
            autoFocus
          >
            {redirectText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ModalAlert;
