import React from "react";
import NumberPropertiesRuleModal from "./number-properties-rules-modal";

export const INTEGER_TYPE = "integer";
export const DECIMAL_TYPE = "decimal";

export class NumberProperties extends React.Component {
  constructor() {
    super();
    this.state = {
      numberType: "",
      decimalDigitNumber: "",
      openRuleModal: false,
      fieldRules: [],
      maxValue: "",
      minValue: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    // if (
    //   props.values.numberType !== state.numberType ||
    //   props.values.numberType !== ""
    // ) {
    //   if (
    //     props.values.numberType === "decimal" ||
    //     props.values.numberType === "integer"
    //   ) {
    //     return {
    //       numberType:
    //         props.values.numberType === "decimal" ? DECIMAL_TYPE : INTEGER_TYPE,
    //     };
    //   } else {
    //     return {
    //       numberType: "",
    //     };
    //   }
    // }
    if (
      props.values.numberType !== state.numberType ||
      props.values.decimalDigitNumber !== state.decimalDigitNumber ||
      props.values.maxValue !== state.maxValue ||
      props.values.minValue !== state.minValue ||
      props.values.fieldRules !== state.fieldRules
    ) {
      return {
        numberType: props.values.numberType,
        decimalDigitNumber: props.values.decimalDigitNumber,
        maxValue: props.values.maxValue,
        minValue: props.values.minValue,
        fieldRules: props.values.fieldRules,
      };
    }
    return null;
  }

  setType(numberType) {
    this.props.formEdited(true);
    this.setState({ numberType });
    this.props.setValues({
      ...this.props.values,
      numberType: numberType,
    });
  }

  setDecimalDigitNumber(decimalDigitNumber) {
    this.props.formEdited(true);
    this.setState({ decimalDigitNumber });
    this.props.setValues({
      ...this.props.values,
      decimalDigitNumber: decimalDigitNumber,
    });
  }
  setMaxValue(value) {
    this.props.formEdited(true);
    this.setState({ maxValue: value !== "" ? value : "" });
    this.props.setValues({
      ...this.props.values,
      maxValue: value !== "" ? value : "",
    });
  }
  setMinValue(minValue) {
    this.props.formEdited(true);
    this.setState({ minValue });
    this.props.setValues({
      ...this.props.values,
      minValue: minValue,
    });
  }
  setFieldRules = (fieldRules) => {
    this.props.formEdited(true);
    if (this.state.fieldRules) {
      this.setState({ fieldRules: [...this.state.fieldRules], fieldRules });
    } else {
      this.setState({ fieldRules: fieldRules });
    }
    this.props.setValues({
      ...this.props.values,
      fieldRules,
    });
  };

  render() {
    return (
      <li>
        {/* <a onClick={() => this.setState({ openRuleModal: true })}>Reguły</a> */}
        {/* {!this.props.displayMode && (
          <a onClick={() => this.setState({ openRuleModal: true })}>Reguły</a>
        )} */}
        {this.state.openRuleModal && (
          <NumberPropertiesRuleModal
            openRuleModal={this.state.openRuleModal}
            displayMode={this.props.displayMode}
            fieldRules={this.props.values.fieldRules}
            handleClose={() => this.setState({ openRuleModal: false })}
            setFieldRules={this.setFieldRules}
          />
        )}
        <br />
        {!this.state.numberType ? (
          <>
            <label color="black">Wybierz typ liczby:</label>
            <div>
              <button
                className="button"
                onClick={() => {
                  this.setType(INTEGER_TYPE);
                }}
              >
                Całkowita
              </button>
              <button
                className="button"
                onClick={() => {
                  this.setType(DECIMAL_TYPE);
                }}
              >
                Rzeczywista
              </button>
            </div>
          </>
        ) : (
          <>
            <>
              <hr />
              <div>
                {" "}
                {this.state.numberType === "integer" ? (
                  "Rodzaj: Liczba całkowita"
                ) : (
                  <div>
                    Rodzaj: Liczba rzeczywista
                    <div className="form-group form-inline">
                      <table>
                        <label>Ilość cyfr po przecinku: </label>
                        <input
                          disabled={this.props.displayMode}
                          type="number"
                          value={this.state.decimalDigitNumber}
                          onChange={(e) => {
                            this.setDecimalDigitNumber(e.target.value);
                          }}
                        ></input>
                      </table>
                    </div>
                  </div>
                )}
                <br />
                <div className="form-group form-inline">
                  <table>
                    <tr>
                      <td>
                        <label>Minimalna wartość: </label>
                      </td>
                      <td>
                        <input
                          disabled={this.props.displayMode}
                          type="number"
                          value={this.state.minValue}
                          onChange={(e) => {
                            this.setMinValue(e.target.value);
                          }}
                          onWheel={(event) => event.target.blur()}
                        ></input>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label>Maksymalna wartość: </label>
                      </td>
                      <td>
                        <input
                          disabled={this.props.displayMode}
                          type="number"
                          value={this.state.maxValue}
                          onChange={(e) => {
                            this.setMaxValue(e.target.value);
                          }}
                          onWheel={(event) => event.target.blur()}
                        ></input>
                      </td>
                    </tr>
                  </table>
                  {!this.props.displayMode && (
                    <button
                      className="button"
                      size="small"
                      onClick={() => {
                        this.setState({
                          numberType: "",
                          maxValue: "",
                          minValue: "",
                          decimalDigitNumber: "",
                        });
                        this.props.setValues({
                          ...this.props.values,
                          numberType: "",
                          maxValue: "",
                          minValue: "",
                          decimalDigitNumber: "",
                        });
                      }}
                    >
                      Cofnij
                    </button>
                  )}
                </div>
              </div>
            </>
          </>
        )}
      </li>
    );
  }
}
