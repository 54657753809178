import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ReactComponent as LocationIcon } from "../../assets/location.svg";
import { ReactComponent as LocationIconBlack } from "../../assets/location-black.svg";
import { ReactComponent as HowWorks } from "../../assets/how-works.svg";
import { ReactComponent as HowWorksBlack } from "../../assets/how-works-black.svg";
import { ReactComponent as TestIcon } from "../../assets/test.svg";
import { ReactComponent as TestIconBlack } from "../../assets/test-black.svg";
import { ReactComponent as ExaminationIcon } from "../../assets/examination.svg";
import { ReactComponent as ExaminationIconBlack } from "../../assets/examination-black.svg";
import { Link } from "react-router-dom";
import { ReactComponent as HBIcon } from "../../assets/hb-tmp.svg";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  mainGrid: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  locationButton: {
    backgroundColor: theme.palette.locationIcon.primary,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `${theme.typography.buttonWidth}px`,
    height: "45px",
    letterSpacing: "1.25px",
  },
  howWorksButton: {
    height: "49px",
  },
  testButton: {
    backgroundColor: theme.palette.testIcon.primary,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `${theme.typography.buttonWidth}px`,
    height: "45px",
    letterSpacing: "1.25px",
    "& .MuiButton-startIcon": {
      marginLeft: -10,
      marginRight: 15,
    },
  },
  examinationIcon: {
    backgroundColor: theme.palette.examinationIcon.primary,
    color: theme.palette.examinationIcon.text,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `${theme.typography.buttonWidth}px`,
    height: "45px",
    letterSpacing: "1.25px",
    fontWeight: "700",
    "& .MuiButton-startIcon": {
      marginLeft: -20,
      marginRight: 20,
    },
  },
  mainTitle: {
    color: theme.palette.text.whiteHeader,
    fontSize: theme.typography.mainTitle2,
    fontWeight: "700",
    textAlign: "center",
    lineHeight: `${theme.typography.mainTitle2}px`,
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.typography.mainTitle2Mobile,
    },
  },
  secondTitle: {
    color: theme.palette.text.whiteHeader,
    fontSize: theme.typography.header,
    fontWeight: "400",
    textAlign: "center",
    lineHeight: `${theme.typography.header}px`,
    [theme.breakpoints.down("xs")]: {
      fontFamily: "Raleway",
    },
  },
}));

const StartPage = ({}) => {
  const classes = useStyles();
  const userLoggedIn = useSelector((s) => s.loggedIn || false);
  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";

  const handleHowItWorksScroll = () => {
    const element = document.getElementById("how-it-works");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleHowItWorksVoucherScroll = () => {
    const element = document.getElementById("how-it-works-voucher");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Hidden mdUp>
        <Grid container spacing={1} className={classes.mainGrid}>
          <Grid
            item
            sm={12}
            md={10}
            lg={8}
            xl={6}
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <HBIcon style={{ height: "34px", marginBottom: "15px" }} />
          </Grid>
          <Grid item sm={12} md={10} lg={8} xl={6}>
            <Typography className={classes.mainTitle}>Hear Box</Typography>
          </Grid>
          <Grid item sm={10} md={8}>
            <Typography className={classes.secondTitle}>
              sprawdź Swój słuch i umiejętności słuchowe
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant={"contained"}
              style={{ margin: "15px 0px" }}
              className={classes.locationButton}
              onClick={handleHowItWorksScroll}
            >
              Jak to działa?
            </Button>
            <Button
              variant={"contained"}
              style={{ margin: "15px 0px" }}
              className={classes.locationButton}
              onClick={handleHowItWorksVoucherScroll}
            >
              <div style={{ display: "block" }}>
                <p>Mam voucher</p>
                <p>Jak to działa?</p>
              </div>
            </Button>{" "}
            <Link to={userLoggedIn ? "/test-stands-all" : "/hear-box-stations"}>
              <Button
                variant={"contained"}
                style={{ margin: "15px 0px" }}
                className={classes.locationButton}
              >
                ZNAJDŹ STANOWISKO
              </Button>
            </Link>
            <Link to={userLoggedIn ? "/products" : "/products-to-buy"}>
              {" "}
              <Button
                variant={"contained"}
                style={{ margin: "0px 0px 15px 0" }}
                className={classes.testButton}
              >
                WYBIERZ BADANIE
              </Button>
            </Link>
            {userLoggedIn && (
              <Link to={"/active-tests"}>
                <Button
                  variant={"contained"}
                  className={classes.examinationIcon}
                >
                  <span>Twoje Badania</span>
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid container spacing={3} className={classes.mainGrid}>
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant={"contained"}
              style={{ marginRight: "10px" }}
              className={`${classes.locationButton} ${classes.howWorksButton}`}
              startIcon={
                isLightGlobalTheme ? (
                  <HowWorks style={{ marginRight: "15px" }} />
                ) : (
                  <HowWorksBlack style={{ marginRight: "15px" }} />
                )
              }
              onClick={handleHowItWorksScroll}
            >
              Jak to działa?
            </Button>

            <Button
              variant={"contained"}
              className={`${classes.locationButton} ${classes.howWorksButton}`}
              startIcon={
                isLightGlobalTheme ? (
                  <HowWorks style={{ marginRight: "15px" }} />
                ) : (
                  <HowWorksBlack style={{ marginRight: "15px" }} />
                )
              }
              style={{
                marginRight: "10px",
              }}
              onClick={handleHowItWorksVoucherScroll}
            >
              <div style={{ display: "block" }}>
                <p> Mam voucher</p>
                <p> Jak to działa?</p>
              </div>
            </Button>
          </Grid>
          <Grid item sm={12} md={10} lg={8} xl={6}>
            {" "}
            <Typography className={classes.mainTitle}>Hear Box</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={8}>
            {" "}
            <Typography className={classes.secondTitle}>
              sprawdź Swój słuch i umiejętności słuchowe
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {" "}
            <Link to={userLoggedIn ? "/test-stands-all" : "/hear-box-stations"}>
              <Button
                variant={"contained"}
                style={{ marginRight: "10px" }}
                className={classes.locationButton}
                startIcon={
                  isLightGlobalTheme ? <LocationIcon /> : <LocationIconBlack />
                }
              >
                ZNAJDŹ HEAR BOX
              </Button>
            </Link>
            <Link to={userLoggedIn ? "/products" : "/products-to-buy"}>
              {" "}
              <Button
                variant={"contained"}
                style={{ marginRight: "10px" }}
                startIcon={
                  isLightGlobalTheme ? <TestIcon /> : <TestIconBlack />
                }
                className={classes.testButton}
              >
                WYBIERZ BADANIE
              </Button>
            </Link>
            {userLoggedIn && (
              <Link to={"/active-tests"}>
                <Button
                  variant={"contained"}
                  className={classes.examinationIcon}
                  startIcon={
                    isLightGlobalTheme ? (
                      <ExaminationIcon />
                    ) : (
                      <ExaminationIconBlack />
                    )
                  }
                >
                  <span>Twoje Badania</span>
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default StartPage;
