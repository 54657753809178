import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { LockOpen, Visibility } from "@material-ui/icons";
import { getAxiosInstance } from "../../../redux/common";
import { alertAdd } from "../../../redux/actions";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  paper: {
    width: "80vh",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.alternative,
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width: 600px)": {
      padding: "20px",
    },
    // "@media (max-width: 600px)": {
    //   height: "100vh",
    // },
    // height: "70vh",
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.text.item,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.item,
    },
  },
}));

const UserModal = ({
  openUserModal,
  userHandlerType,
  handleClose,
  userToEdit = userHandlerType === "add" && {},
  loadUsers,
  userType,
  setAdditionCompleted,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [busy, setBusy] = useState(false);

  const nameArray = userToEdit ? userToEdit?.name?.split(" ") : [];
  const [name, setName] = useState(
    (nameArray?.length > 0 && nameArray[0]) || ""
  );
  const [surname, setSurname] = useState(
    (nameArray?.length > 0 && nameArray[nameArray.length - 1]) || ""
  );
  const [email, setEmail] = useState(userToEdit.email || "");
  const [doctorTitle, setDoctorTitle] = useState(
    (nameArray?.length && userToEdit?.titles[0]) || ""
  );
  const [organization, setOrganization] = useState(
    userToEdit.organization || ""
  );
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [formValid, setFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [invalidPasswordInputChar, setInvalidPasswordInputChar] = useState(
    false
  );

  const [screeningTests, setScreeningTests] = useState(
    userToEdit.screeningTests || []
  );

  const [selectOpen, setSelectOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    variant: "menu",
    getContentAnchorEl: null,
  };

  useEffect(() => {
    if (userHandlerType === "add") {
      setFormValid(
        name &&
          surname &&
          testEmailPattern(email) &&
          // doctorTitle &&
          password &&
          password === password2
      );
    } else {
      setFormValid(name && surname && testEmailPattern(email) && doctorTitle);
    }
  }, [name, surname, email, doctorTitle, password, password2]);

  useEffect(() => {
    setPasswordsMatch(password === password2);
  }, [password, password2]);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(""), [4000]);
    }
  }, [errorMessage]);

  const saveHandler = () => {
    try {
      setBusy(true);
      const domain =
        window.location.hostname === "localhost"
          ? `${window.location.hostname}:${window.location.port}`
          : window.location.hostname;

      const secure = window.location.hostname === "localhost" ? `` : "s";
      let socket = new WebSocket(
        `ws${secure}://${domain}/medic/v1/owner/websocket/connect`
      );
      let response = {};
      socket.onopen = async function (e) {
        try {
          response = await getAxiosInstance().post(`/owner/v1/create`, {
            name: `${name} ${surname}`,
            email,
            password,
            roles: ["SystemConsultant"],
            password,
          });
        } catch (e) {
          const errorType = e.response.data.name;
          if (errorType === "EmailAlreadyUsedError") {
            setErrorMessage(`Podany email już istnieje`);
            setBusy(false);
          } else {
            setErrorMessage(
              `Błąd ${
                userHandlerType === "add" ? "dodawania" : "edycji"
              } lekarza`
            );
            setBusy(false);
          }
        }
      };

      socket.onmessage = function (event) {
        if (event.data.includes("DoctorAddedMedicOwnerWebSocketMessage")) {
          setBusy(false);
          handleClose();
          loadUsers();
          socket.close();
        }
      };

      socket.onerror = function (event) {
        setBusy(false);
        setErrorMessage("Wystąpił problem z dodaniem lekarza");
        socket.close();
      };
    } catch (e) {}
  };
  const editHandler = () => {
    try {
      setBusy(true);
      const domain =
        window.location.hostname === "localhost"
          ? `${window.location.hostname}:${window.location.port}`
          : window.location.hostname;

      const secure = window.location.hostname === "localhost" ? `` : "s";
      let socket = new WebSocket(
        `ws${secure}://${domain}/medic/v1/owner/websocket/connect`
      );
      let response = {};
      socket.onopen = async function (e) {
        try {
          const responseData = await getAxiosInstance().put(
            `/owner/v1/user/update?userId=${userToEdit.userId}`,
            {
              email,
              name: `${name} ${surname}`,
              roles: ["SystemConsultant"],
            }
          );
          const responseTitle = await getAxiosInstance().put(
            `/medic/v1/doctor`,
            {
              userId: userToEdit.userId,
              titles: [doctorTitle],
            }
          );
        } catch (e) {
          const errorType = e.response.data.name;
          if (errorType === "EmailAlreadyUsedError") {
            setErrorMessage(`Podany email już istnieje`);
            setBusy(false);
          } else {
            setErrorMessage(
              `Błąd ${
                userHandlerType === "add" ? "dodawania" : "edycji"
              } lekarza`
            );
            setBusy(false);
          }
        }
      };

      socket.onmessage = function (event) {
        if (event.data.includes("DoctorUpdatedMedicOwnerWebSocketMessage")) {
          setBusy(false);
          handleClose();
          loadUsers();
          socket.close();
        }
      };

      socket.onerror = function (event) {
        setBusy(false);
        setErrorMessage("Wystąpił problem z dodaniem lekarza");
        socket.close();
      };
    } catch (e) {}
  };

  const handleSelectClose = () => setSelectOpen(false);

  const handleSelectOpen = () => setSelectOpen(true);

  const handleChange = (e) => {
    const value = e.target.value;
    if (value) {
      setScreeningTests(value);
    }
  };

  const handleDelete = (value) => {
    setScreeningTests(screeningTests.filter((t) => t !== value));
  };

  const handleOrgChange = (e) => {
    setOrganization(e.target.value);
  };

  const testEmailPattern = (mail) => {
    let polishChars1 = String(mail)
      .toLowerCase()
      .normalize("NFD")
      .search(/[\u0300-\u036f]/g);
    let polishChars2 = String(mail)
      .toLowerCase()
      .normalize("NFD")
      .search(/\u0142/g);

    return (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(mail).toLowerCase()
      ) &&
      (polishChars2 !== 0
        ? polishChars1 + polishChars2 < 0
        : polishChars1 + polishChars2 < -1)
    );
  };

  const title = (
    <Box mt={1} style={{ display: "flex" }}>
      <Typography variant="h6" gutterBottom>
        {userHandlerType === "add"
          ? "Dodaj lekarza"
          : `Edycja ${userType === "employee" ? "lekarza" : "pacjenta"} : ${
              userToEdit.name
            }`}
      </Typography>
    </Box>
  );
  const body = (
    <Box>
      {" "}
      <Box mt={1}>
        <TextField
          disabled={busy}
          label="Imię"
          variant="outlined"
          required
          value={name}
          fullWidth
          onChange={({ target: { value: name } }) => {
            setName(name);
          }}
          inputProps={{
            "aria-label": "Imię",
            maxLength: 255,
          }}
        />
      </Box>
      <Box mt={1}>
        <TextField
          disabled={busy}
          label="Nazwisko"
          variant="outlined"
          required
          value={surname}
          fullWidth
          onChange={({ target: { value: surname } }) => {
            setSurname(surname);
          }}
          inputProps={{
            "aria-label": "Nazwisko",
            maxLength: 255,
          }}
        />
      </Box>
      <Box mt={1}>
        <TextField
          disabled={busy}
          label="Email"
          variant="outlined"
          required
          value={email}
          error={!(email === "" || testEmailPattern(email))}
          helperText={
            !(email === "" || testEmailPattern(email)) &&
            "Nieprawidłowy adres email"
          }
          fullWidth
          onChange={({ target: { value: email } }) => {
            setEmail(email);
          }}
          inputProps={{
            "aria-label": "Email",
            maxLength: 255,
          }}
        />
      </Box>
      {userHandlerType === "add" && (
        <Box mt={1} display="flex">
          <TextField
            disabled={busy}
            label="Hasło"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            value={password}
            error={invalidPasswordInputChar}
            helperText={
              invalidPasswordInputChar ? "Spacja nie jest dozwolona." : ""
            }
            required
            onChange={(e) => {
              if (e.target.value.includes(" ")) {
                setInvalidPasswordInputChar(true);
                setTimeout(() => setInvalidPasswordInputChar(false), 3000);
              } else {
                setInvalidPasswordInputChar(false);
              }
              setPassword(e.target.value.split(" ").join(""));
            }}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label="toggle password visibility"
                  >
                    <Visibility />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            disabled={busy}
            error={password2 && !passwordsMatch}
            helperText={
              password2 && !passwordsMatch
                ? "Powtórzone hasło nie jest zgodne"
                : ""
            }
            label="Powtórz hasło"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            value={password2}
            required
            onChange={(e) => setPassword2(e.target.value.split(" ").join(""))}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label="toggle password visibility"
                  >
                    <Visibility />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
      {userHandlerType !== "add" && (
        <Box mt={1}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="open-title-filter-label" required>
              Tytuł lekarza
            </InputLabel>
            <Select
              disabled={busy}
              labelId="open-title-filter-label"
              id="open-title-filter"
              label={"Tytuł lekarza *"}
              onChange={(e) => {
                setDoctorTitle(e.target.value);
              }}
              value={doctorTitle}
            >
              <MenuItem value={"prof"} key={"prof"}>
                {"Profesor"}
              </MenuItem>
              <MenuItem value={"dr"} key={"dr"}>
                {"Dr n. med."}
              </MenuItem>
              <MenuItem value={"lek"} key={"lek"}>
                {"Lekarz Specjalista"}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );

  const foot = (
    <Grid>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button variant={"contained"} onClick={() => handleClose()}>
          Anuluj
        </Button>
        &nbsp;&nbsp;&nbsp;{" "}
        <Button
          variant={"contained"}
          color="primary"
          // onClick={() => {}}
          disabled={!formValid || busy}
          onClick={userHandlerType === "add" ? saveHandler : editHandler}
          // onClick={userHandlerType === "add" ? saveHandler : editHandler}
        >
          {busy && <CircularProgress size={14} />}
          Zapisz
        </Button>
      </Box>
    </Grid>
  );

  return (
    <Modal open={openUserModal} className={classes.modalStyle}>
      <Grid container>
        <Grid item xs={12} className={classes.grid}>
          <Paper className={classes.paper}>
            {" "}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            {title} <hr />
            {body}
            <hr
              style={{
                margin: "24px 0px",
              }}
            />
            {foot}
          </Paper>
        </Grid>
      </Grid>
      {/* 
       
      </Box> */}
    </Modal>
  );
};
export default UserModal;
