import React, { useEffect, useState } from "react";

import RowControl from "../form-controls/row-control";

const FormDetails = ({
  rows,
  values,
  filledValuesDetails,
  notesData,
  filesData,
  alertDisplaying,
  textColorFilling,
}) => {
  const [rowsWithValues, setRowsWithValues] = useState([]);

  useEffect(() => {
    setRowsWithValues(
      rows.reduce((all, row) => {
        let rowValues = [];
        row.map(({ id, dataType }, index) => {
          switch (dataType) {
            case "FILE":
              rowValues.push({
                id,
                files: filledValuesDetails.find((f) => f.id === id)?.files,
              });
              break;
            default:
              if (values[id]) {
                if (!values.hasOwnProperty(id)) {
                  rowValues.push({ id, value: " " });
                } else {
                  rowValues.push({ id, value: values[id] });
                }
              } else {
                let value = Object.keys(values)
                  .map((i) => values[i])
                  .find((v) => v.id === id);

                if (!value) {
                  rowValues.push({ id, value: " " });
                } else {
                  rowValues.push({
                    id,
                    value: value.value || value.alternativeAnswer,
                  });
                }
              }
              break;
          }
        });

        return [...all, rowValues];
      }, [])
    );
  }, [rows, values]);

  return (
    <div className="columns">
      <div className="column">
        <div>
          {rows.map((row, rowIndex) => {
            return (
              <RowControl
                key={rowIndex}
                items={row}
                rowValues={rowsWithValues[rowIndex]}
                filledValuesDetails={filledValuesDetails}
                notesData={notesData}
                filesData={filesData}
                alertDisplaying={alertDisplaying}
                textColorFilling={textColorFilling}
                filledFormMode
                disabled
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FormDetails;
