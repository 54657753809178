import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  CircularProgress,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import { getAxiosInstance } from "../../../redux/common";
import Loader from "../../../components/loader";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Alert from "@material-ui/lab/Alert";
import { normalizeZIP } from "../../../components/address-form/shipping-info";

const OrangeButton = styled(Button)`
  background-color: orange;
  color: white;
`;

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  paper: {
    width: "80vh",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.alternative,
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width: 600px)": {
      padding: "20px",
    },
    // "@media (max-width: 600px)": {
    //   height: "100vh",
    // },
    // height: "70vh",
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.text.item,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.item,
    },
  },
}));

const TestStandModal = ({
  openTestStandModal,
  testStandsHandlerType,
  handleClose,
  testStandToEdit,
  setSavedTestStand,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [busy, setBusy] = useState(false);

  const [label, setLabel] = useState(testStandToEdit.label || "");
  const [secretToken, setSecretToken] = useState("");
  // const [description, setDescription] = useState(testStandToEdit.desc || "");
  const [country, setCountry] = useState(testStandToEdit.country || "");
  const [voivodeship, setVoivodeship] = useState(
    testStandToEdit.voivodeship || ""
  );
  const [city, setCity] = useState(testStandToEdit.city || "");
  const [street, setStreet] = useState(testStandToEdit.street || "");
  const [postCode, setPostCode] = useState(testStandToEdit.postCode || "");
  const [stationType, setStationType] = useState(
    testStandToEdit.stationType || ""
  );

  const [screeningTest, setScreeningTest] = useState(
    testStandToEdit.screeningTestName || {}
  );

  const [isPostalCodeValid, setIsPostalCodeValid] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [savingTestStand, setSavingTestStand] = useState(false);

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (
      label &&
      country &&
      voivodeship &&
      city &&
      street &&
      postCode &&
      stationType
    ) {
      if (testStandsHandlerType === "add") {
        if (secretToken) {
          setValid(true);
        } else {
          setValid(false);
        }
      } else {
        setValid(true);
      }
    } else {
      setValid(false);
    }
  }, [
    label,
    country,
    voivodeship,
    city,
    street,
    postCode,
    stationType,
    secretToken,
  ]);

  useEffect(() => {
    let postalCode = "";
    postCode.length > 6
      ? (postalCode = postCode.substring(0, 6))
      : (postalCode = postCode);
    let isOk;
    if (
      (postalCode && /^\d{2}-\d{3}$/.test(postalCode)) ||
      postalCode === "" ||
      postalCode === undefined
    ) {
      isOk = true;
    } else {
      isOk = false;
    }

    setIsPostalCodeValid(isOk);
  }, [postCode]);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(""), [5000]);
    }
    if (message) {
      setTimeout(() => setMessage(""), [5000]);
    }
  }, [errorMessage, message]);

  const handleExpandPanelChange = () => {
    setExpanded(!expanded);
  };

  const saveTokenHandler = async (e) => {
    try {
      const response = await getAxiosInstance().put(
        `/screening/v1/hearbox/station/secretChange`,
        {
          hearBoxId: testStandToEdit.hearBoxId,
          newSecretToken: secretToken,
        }
      );
      if (response.status === 200) {
        setMessage("Token zaktualizowany");
        setSecretToken("");
        setExpanded(false);
      }
    } catch (e) {
      setErrorMessage("Token nie został zaktualizowany");
    }
  };

  const saveHandler = async () => {
    try {
      setSavedTestStand(false);
      setSavingTestStand(true);
      if (testStandsHandlerType === "edition") {
        const responseEdition = await getAxiosInstance().put(
          `/screening/v1/hearbox/station`,
          {
            hearBoxId: testStandToEdit.hearBoxId,
            country,
            label,
            voivodeship,
            city,
            street,
            postCode,
            label,
            stationType,
            secretToken,
          }
        );

        if (responseEdition) {
          setTimeout(() => {
            setSavingTestStand(false);
            //
            handleClose();
            setSavedTestStand(true);
          }, [5000]);
        }
      } else {
        const responseAdd = await getAxiosInstance().post(
          `/screening/v1/hearbox/station`,
          {
            country,
            label,
            voivodeship,
            city,
            street,
            postCode,
            label,
            stationType,
            secretToken,
          }
        );
        if (responseAdd) {
          setTimeout(() => {
            setSavingTestStand(false);
            setSavedTestStand(true);
            handleClose();
          }, [5000]);
        }
      }
    } catch (e) {
      console.log(e);
      setSavingTestStand(false);
      setErrorMessage(
        `Stanowisko HearBox nie zostało ${
          testStandsHandlerType === "edition" ? "edytowane" : "dodane"
        }`
      );
    }
  };

  const handleStatusChange = (e) => {
    setStationType(e.target.value);
  };

  const handleScreeningTestChange = (event) => {
    setScreeningTest(event.target.value);
  };

  const title = (
    <Box mt={1} style={{ display: "flex" }}>
      <Typography variant="h6" gutterBottom>
        {testStandsHandlerType === "add"
          ? "Dodaj stanowisko"
          : `Edycja stanowiska: ${testStandToEdit.label}`}
      </Typography>
    </Box>
  );
  const body = (
    <Box>
      {(errorMessage || message) && (
        <Alert severity={message ? "info" : "error"}>
          {message ? message : errorMessage}
        </Alert>
      )}
      <Box mt={1}>
        <TextField
          label="Nazwa stanowiska"
          variant="outlined"
          required
          value={label}
          fullWidth
          onChange={({ target: { value: label } }) => {
            setLabel(label);
          }}
          inputProps={{
            "aria-label": "Nazwa stanowiska",
            maxLength: 255,
          }}
        />
      </Box>
      {testStandsHandlerType === "add" && (
        <Box mt={1}>
          <TextField
            label="Token do autoryzacji"
            variant="outlined"
            required
            value={secretToken}
            fullWidth
            onChange={({ target: { value: secretToken } }) => {
              setSecretToken(secretToken);
            }}
            inputProps={{
              "aria-label": "Token do autoryzacji",
              maxLength: 255,
            }}
          />
        </Box>
      )}
      <Box mt={1}>
        <TextField
          label="Kraj"
          variant="outlined"
          required
          value={country}
          fullWidth
          onChange={({ target: { value: country } }) => {
            setCountry(country);
          }}
          inputProps={{
            "aria-label": "Kraj",
            maxLength: 255,
          }}
        />
      </Box>
      <Box mt={1}>
        <TextField
          label="Województwo"
          variant="outlined"
          required
          value={voivodeship}
          fullWidth
          onChange={({ target: { value: voivodeship } }) => {
            setVoivodeship(voivodeship);
          }}
          inputProps={{
            "aria-label": "Województwo",
            maxLength: 255,
          }}
        />
      </Box>
      <Box mt={1}>
        <TextField
          label="Miasto"
          variant="outlined"
          required
          value={city}
          fullWidth
          onChange={({ target: { value: city } }) => {
            setCity(city);
          }}
          inputProps={{
            "aria-label": "Miasto",
            maxLength: 255,
          }}
        />
      </Box>
      <Box mt={1}>
        <TextField
          label="Ulica"
          variant="outlined"
          required
          value={street}
          fullWidth
          onChange={({ target: { value: street } }) => {
            setStreet(street);
          }}
          inputProps={{
            "aria-label": "Ulica",
            maxLength: 255,
          }}
        />
      </Box>
      <Box mt={1}>
        <TextField
          label="Kod pocztowy"
          variant="outlined"
          required
          type="text"
          value={postCode}
          fullWidth
          onChange={({ target: { value } }) => {
            setPostCode(normalizeZIP(value, postCode));
          }}
          error={!isPostalCodeValid}
          helperText={!isPostalCodeValid && "Nieprawidłowy format."}
        />
      </Box>
      <Box mt={1}>
        <FormControl
          component="status"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <FormLabel id="radio-buttons-group-label" required>
            Typ Stacji:
          </FormLabel>
          <RadioGroup
            aria-label="status"
            name="status"
            value={stationType}
            onChange={handleStatusChange}
            row
          >
            <FormControlLabel
              value="Open"
              control={<Radio color="primary" />}
              label="Otwarty"
            />
            <FormControlLabel
              value="Closed"
              control={<Radio color="primary" />}
              label="Zamknięty"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {testStandsHandlerType === "edition" && (
        <Accordion expanded={expanded} onChange={handleExpandPanelChange}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panelexpanded-content"
            id="panelexpanded-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Token do autoryzacji
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              size="small"
              label="Wprowadź nowy token"
              variant="outlined"
              fullWidth
              value={secretToken}
              fullWidth
              onChange={({ target: { value: secretToken } }) => {
                setSecretToken(secretToken);
              }}
              inputProps={{
                "aria-label": "Token do autoryzacji",
                maxLength: 255,
              }}
            ></TextField>
            <Button
              variant={"contained"}
              disabled={!secretToken}
              color="primary"
              onClick={saveTokenHandler}
            >
              Zapisz
            </Button>
            {/* <Box m={3}>
              
            </Box> */}
          </AccordionDetails>
        </Accordion>
      )}
      {/* <Box mt={1}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="screeningTest">Przypisz do przesiewu</InputLabel>
          <Select
            labelId="screeningTest"
            id="screeningTest"
            value={screeningTest}
            onChange={handleScreeningTestChange}
            label="Przypisz do przesiewu"
          >
            <MenuItem value="">
              <em>---</em>
            </MenuItem>
            {screeningTests.map((screening) => (
              <MenuItem key={screening.screeningId} value={screening}>
                {screening.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box> */}
    </Box>
  );

  const foot = (
    <Grid>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant={"contained"}
          disabled={savingTestStand}
          onClick={() => handleClose()}
        >
          Anuluj
        </Button>
        &nbsp;&nbsp;&nbsp;{" "}
        <Button
          variant={"contained"}
          disabled={
            savingTestStand ||
            !isPostalCodeValid ||
            (testStandsHandlerType === "edition" && secretToken) ||
            !valid
          }
          color="primary"
          onClick={saveHandler}
        >
          {busy && <CircularProgress size={14} />}
          Zapisz
        </Button>
      </Box>
    </Grid>
  );

  return (
    <Modal open={openTestStandModal} className={classes.modalStyle}>
      <Grid container>
        <Grid item xs={12} className={classes.grid}>
          <Paper className={classes.paper}>
            {" "}
            {title} <hr />
            {!savingTestStand ? (
              body
            ) : (
              <Loader
                loading={true}
                text="Zapis stanowiska - proszę czekać"
              ></Loader>
            )}
            <hr
              style={{
                margin: "24px 0px",
              }}
            />
            {foot}
          </Paper>
        </Grid>
      </Grid>
      {/* 
       
      </Box> */}
    </Modal>
  );
};
export default TestStandModal;
