import React, { useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Badge,
  Box,
  Hidden,
  Tooltip,
  Typography,
  Paper,
  withStyles,
  Grid,
} from "@material-ui/core";
import Sidebar from "./sidebar";
import MuiIconButton from "@material-ui/core/IconButton";
import ContrastIcon from "@material-ui/icons/BrightnessMedium";
import { useDispatch, useSelector } from "react-redux";
import { toggleGlobalTheme, setFontSize } from "../../redux/actions";
import TextFormatIcon from "@material-ui/icons/TextFormat";
// import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { ReactComponent as ShoppingCartIcon } from "../../assets/shopping-cart.svg";
import { ReactComponent as ShoppingCartIconYellow } from "../../assets/shopping-cart-yellow.svg";
import { ReactComponent as ProfileIcon } from "../../assets/profile.svg";
import { ReactComponent as ProfileIconYellow } from "../../assets/profile-yellow.svg";
import { ReactComponent as HBIcon } from "../../assets/hb-tmp.svg";
import { ReactComponent as EUflag } from "../../assets/europe-flag-icon.svg";
import MainBackground from "../../assets/main2.svg";
import MainBackgroundMobile from "../../assets/main2Mobile.svg";
import Image from "../../assets/background.svg";
import ImageMobile from "../../assets/backgroundMobile.svg";
import StartPage from "../../pages/start-page";
import StartPageLower from "../../pages/start-page-lower";
import Cookies from "../cookies/cookies";

const IconButton = withStyles((theme) => ({
  root: {
    padding: "4px",
    marginTop: "5px",
    height: "25px",
    borderRadius: "0%",
    marginRight: "10px",
    color: theme.palette.text.default,
  },
}))(MuiIconButton);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: theme.palette.text.default,
  },
  title1: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  title: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  titleText: {
    fontWeight: "700",
    color: theme.palette.text.default,
    fontSize: theme.typography.formLabel,
  },
  titleTextMobile: {
    fontWeight: "700",
    color: theme.palette.text.default2,
    fontSize: theme.typography.formLabel,
  },
  titleTestTextMobile: {
    color: theme.palette.text.default2,
    fontSize: theme.typography.footerMobile,
  },
  activePage: {
    color: theme.palette.background.default,
    textDecoration: "underline",
    textUnderlineOffset: "13px",
  },
  upperToolbar: {
    minHeight: "35px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: "0px",
  },
  lowerToolbar: {
    minHeight: "80px",
    margin: "0 20px 0 60px",
    [theme.breakpoints.down("lg")]: {
      margin: "0 0 0 0",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  titleIcon: {
    marginRight: "4px",
    marginLeft: "4px",
  },
  mainContent: {
    flexGrow: 1,
    padding: theme.spacing(0),
    minHeight: `calc(100vh - 115px)`,
  },
  a: {
    fontFamily: "Raleway",
    fontWeight: "700",
    size: "12px",
    letterSpacing: "2px",
    textTransform: "uppercase",
    margin: "15px",
    whiteSpace: "nowrap",
    color: theme.palette.text.whiteHeader,
    // textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.whiteHeader,
      textDecoration: "underline",
      textUnderlineOffset: "13px",
    },
  },
  aIcon: {
    fontFamily: "Raleway",
    fontWeight: "700",
    height: "42px",
    size: "12px",
    letterSpacing: "2px",
    textTransform: "uppercase",
    margin: "15px",
    padding: "6px",
    color: theme.palette.text.whiteHeader,
    // textDecoration: "underline",
    "&:hover": {
      borderBottom: `1px solid ${theme.palette.text.whiteHeader}`,
      color: theme.palette.text.whiteHeader,
    },
  },

  spanHeader: {
    color: theme.palette.text.whiteHeader,
    fontFamily: "ROBOTO",
    fontWeight: "300",
    size: "7px",
    lineHeight: "10.8px",
    letterSpacing: "1px",
  },
  activePageIcon: {
    fontFamily: "Raleway",
    fontWeight: "700",
    size: "12px",
    letterSpacing: "2px",
    textTransform: "uppercase",
    margin: "15px",
    padding: "6px",
    color: theme.palette.text.whiteHeader,
    borderBottom: `1px solid ${theme.palette.text.whiteHeader}`,
    // textDecoration: "underline",
  },
  aIconPadding: {
    padding: "10px",
  },
  activePageIconPadding: {
    padding: "10px",
  },
  paperMainContainer: {
    backgroundImage: `url(${MainBackground})`,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${MainBackgroundMobile})`,
      backgroundPosition: "inherit",
    },
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    objectFit: "contain",
    borderRadius: "0px",
    height: "100vh",
  },
  paperContainer: {
    backgroundImage: `url(${Image})`,
    [theme.breakpoints.down("md")]: {
      backgroundImage: `url(${ImageMobile})`,
    },
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    objectFit: "contain",
    borderRadius: "0px",
    height: "100%",
  },
  paperContainerGray: {
    background: theme.palette.background.menu,
    boxShadow: "none",
    borderRadius: "0px",
    height: "100%",
  },

  paperContainerStartPage: {
    borderRadius: "0px",
    height: "100%",
    backgroundColor: "inherit",
    [theme.breakpoints.down("xs")]: {
      padding: "4px",
    },
  },
  customBadge: {
    backgroundColor: theme.palette.background.badgeBackground,
    color: theme.palette.text.badgeText,
    fontWeight: "600",
  },
}));

const HeaderMenu = ({ children }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const fontSize = useSelector((s) => s.fontSize || "normal");
  const cookiesAccepted = useSelector(
    (s) => s.cookiesAccepted || window.localStorage.getItem("cookiesAccepted")
  );
  const [openSidebar, setOpenSidebar] = useState(false);
  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";

  const shoppingCartProducts = useSelector((s) => s.shoppingCartProducts || []);

  const history = useHistory();

  const navigationLinks = [
    { label: "Co to jest Hear box", href: "/start" },
    // { label: "Stanowiska", href: "/test-stands" },
    { label: "Wybierz badanie", href: "/products-to-buy" },
    { label: "Kontakt", href: "/contact-form" },
  ];

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  return (
    <div>
      <Box className={pathname === "/start" && classes.paperMainContainer}>
        <Grid container className={classes.scrollType}>
          {/* <Grid container style={{ overflow: "scroll" }}> */}
          <Grid item xs={12}>
            <AppBar
              position="static"
              style={{
                backgroundColor: pathname === "/start" && "inherit",
                boxShadow: "none",
              }}
            >
              <Toolbar className={classes.upperToolbar}>
                {" "}
                <Tooltip title="Przełącz kontrastowy tryb strony">
                  <IconButton>
                    <ContrastIcon
                      onClick={() => dispatch(toggleGlobalTheme())}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Zmień rozmiar czcionki">
                  <IconButton>
                    <TextFormatIcon
                      style={{ border: fontSize === "normal" && "1px solid" }}
                      fontSize="small"
                      onClick={() => dispatch(setFontSize("normal"))}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Zmień rozmiar czcionki">
                  <IconButton>
                    <TextFormatIcon
                      style={{ border: fontSize === "medium" && "1px solid" }}
                      fontSize="medium"
                      onClick={() => dispatch(setFontSize("medium"))}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Zmień rozmiar czcionki">
                  <IconButton>
                    <TextFormatIcon
                      style={{ border: fontSize === "large" && "1px solid" }}
                      fontSize="large"
                      onClick={() => dispatch(setFontSize("large"))}
                    />
                  </IconButton>
                </Tooltip>
              </Toolbar>
              <Toolbar className={classes.lowerToolbar}>
                <Hidden lgUp>
                  <Box className={classes.buttonMenuBox}>
                    <MuiIconButton
                      edge="start"
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="menu"
                    >
                      <MenuIcon onClick={() => handleSidebarOpen(true)} />
                    </MuiIconButton>
                  </Box>
                  <Box className={classes.title}>
                    {" "}
                    <Typography
                      className={classes.titleTextMobile}
                      onClick={() => history.push("/start")}
                    >
                      HEAR BOX
                    </Typography>
                    <Typography
                      className={classes.titleTestTextMobile}
                      onClick={() => history.push("/")}
                    >
                      wersja testowa
                    </Typography>
                  </Box>
                  <Box mr={2} style={{ display: "flex" }}>
                    <Link
                      className={`${classes.aIcon} ${
                        pathname === "/login" && classes.activePageIcon
                      }`}
                      to={"/login"}
                    >
                      {isLightGlobalTheme ? (
                        <ProfileIcon onClick={() => history.push("/login")} />
                      ) : (
                        <ProfileIconYellow
                          onClick={() => history.push("/login")}
                        />
                      )}
                    </Link>
                    <Link
                      className={`${classes.aIcon} ${classes.aIconPadding}  ${
                        pathname === "/order-test-kit" && classes.activePageIcon
                      } ${
                        pathname === "/order-test-kit" &&
                        classes.activePageIconPadding
                      }`}
                      to={"/order-test-kit"}
                    >
                      <Badge
                        classes={{ badge: classes.customBadge }}
                        badgeContent={parseInt(shoppingCartProducts.length)}
                      >
                        {isLightGlobalTheme ? (
                          <ShoppingCartIcon />
                        ) : (
                          <ShoppingCartIconYellow />
                        )}
                      </Badge>
                    </Link>
                  </Box>
                </Hidden>
                {/* <Hidden mdDown xlUp>
                  <Box className={classes.title1}>
                    <Box
                      mr={4}
                      ml={2}
                      component="div"
                      display="inline"
                      className={classes.titleIcon}
                    >
                      <HBIcon
                        style={{ height: "55px", cursor: "pointer" }}
                        onClick={() => history.push("/start")}
                      />
                    </Box>
                    {navigationLinks.map((link) => (
                      <Link
                        className={`${classes.a} ${
                          pathname === link.href && classes.activePage
                        }`}
                        to={link.href}
                      >
                        {link.label}
                      </Link>
                    ))}
                  </Box>{" "}
                  <Box mr={2} style={{ display: "flex" }}>
                    <Link
                      className={`${classes.aIcon} ${
                        pathname === "/login" && classes.activePageIcon
                      }`}
                      to={"/login"}
                    >
                      {isLightGlobalTheme ? (
                        <ProfileIcon onClick={() => history.push("/login")} />
                      ) : (
                        <ProfileIconYellow
                          onClick={() => history.push("/login")}
                        />
                      )}
                    </Link>
                    <Link
                      className={`${classes.aIcon} ${classes.aIconPadding}  ${
                        pathname === "/order-test-kit" && classes.activePageIcon
                      } ${
                        pathname === "/order-test-kit" &&
                        classes.activePageIconPadding
                      }`}
                      to={"/order-test-kit"}
                    >
                      <Badge
                        badgeContent={parseInt(shoppingCartProducts.length)}
                        classes={{ badge: classes.customBadge }}
                      >
                        {isLightGlobalTheme ? (
                          <ShoppingCartIcon />
                        ) : (
                          <ShoppingCartIconYellow />
                        )}
                      </Badge>
                    </Link>
                  </Box>
                  <Box
                    m={2}
                    component="div"
                    display="inline"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      marginTop: "-14px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        marginRight: "5px",
                      }}
                    >
                      <span className={classes.spanHeader}>UNIA</span>
                      <span
                        className={classes.spanHeader}
                        style={{
                          marginTop: "3px",
                        }}
                      >
                        EUROPEJSKA
                      </span>
                    </div>
                  </Box>
                  <Box style={{ marginTop: "-8px" }}>
                    <EUflag style={{ height: "35px" }} />
                  </Box>
                </Hidden> */}
                <Hidden mdDown>
                  <Box className={classes.title1}>
                    <Box mr={4} ml={2} component="div" display="inline">
                      <HBIcon
                        style={{ cursor: "pointer" }}
                        // change when original svg will be restored
                        // style={{ height: "55px", cursor: "pointer" }}
                        onClick={() => history.push("/start")}
                      />
                    </Box>
                    {navigationLinks.map((link) => (
                      <Link
                        className={`${classes.a} ${
                          pathname === link.href && classes.activePage
                        }`}
                        to={link.href}
                      >
                        {link.label}
                      </Link>
                    ))}
                  </Box>{" "}
                  <Box mr={2}>
                    <Link
                      to={"/login"}
                      className={`${classes.a} ${
                        pathname === "/login" && classes.activePage
                      }`}
                    >
                      ZALOGUJ SIĘ
                    </Link>
                    <Link
                      style={{ height: "36px" }}
                      className={`${classes.aIcon} ${classes.aIconPadding}  ${
                        pathname === "/order-test-kit" && classes.activePageIcon
                      } ${
                        pathname === "/order-test-kit" &&
                        classes.activePageIconPadding
                      }`}
                      to={"/order-test-kit"}
                    >
                      <Badge
                        badgeContent={parseInt(shoppingCartProducts.length)}
                        classes={{ badge: classes.customBadge }}
                      >
                        {isLightGlobalTheme ? (
                          <ShoppingCartIcon />
                        ) : (
                          <ShoppingCartIconYellow />
                        )}
                      </Badge>{" "}
                    </Link>
                  </Box>
                  <Box
                    m={2}
                    component="div"
                    display="inline"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      marginTop: "-14px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        marginRight: "5px",
                      }}
                    >
                      <span className={classes.spanHeader}>UNIA</span>
                      <span
                        className={classes.spanHeader}
                        style={{
                          marginTop: "3px",
                        }}
                      >
                        EUROPEJSKA
                      </span>
                    </div>
                  </Box>
                  <Box style={{ marginTop: "-8px" }}>
                    <EUflag style={{ height: "35px" }} />
                  </Box>
                </Hidden>
                <Sidebar
                  open={openSidebar}
                  onOpen={handleSidebarOpen}
                  onClose={handleSidebarClose}
                  navigationLinks={navigationLinks}
                  history={history}
                  pathname={pathname}
                />
              </Toolbar>
            </AppBar>
          </Grid>
          {pathname !== "/start" ? (
            <Grid item xs={12} className={classes.mainContent}>
              <Paper
                className={
                  pathname === "/login" ||
                  pathname === "/register" ||
                  pathname === "/login-employee"
                    ? classes.paperContainer
                    : classes.paperContainerGray
                }
              >
                {" "}
                {children}
              </Paper>
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.mainContent}>
              <Box p={2} className={classes.paperContainerStartPage}>
                <StartPage />
              </Box>
            </Grid>
          )}
        </Grid>
        {!cookiesAccepted && <Cookies />}
      </Box>
      {pathname === "/start" && <StartPageLower />}
    </div>
  );
};

export default HeaderMenu;
