import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Can from "../../components/can";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Box,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import StopIcon from "@material-ui/icons/Stop";

const CustomTableCell = withStyles((theme) => ({
  root: {
    paddingTop: "16px",
    border: "none",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  rootBox: {
    padding: "24px 64px",
    marginBottom: "55px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 8px",
    },
  },
  paper: {
    border: `0.5px solid ${theme.palette.hr.primary}`,
    padding: "16px",
    textAlign: "left",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  typography: {
    color: theme.palette.text.alternative3,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  tableCell: {
    textTransform: "uppercase",
    fontWeight: "600",
    borderBottom: "none",
    color: theme.palette.text.hintText,
    borderBottom: `none`,
    padding: "0px",
  },
  tableCellBody: {
    // textTransform: "uppercase",
    // fontWeight: "600",
    padding: "4px 16px",
    color: theme.palette.text.hintText,
    borderBottom: `none`,
  },
  tableCellBottom: {
    textTransform: "uppercase",
    fontWeight: "600",
    borderBottom: `1px solid ${theme.palette.text.whiteHeader}`,
    color: theme.palette.text.hintText,
  },
  button: {
    borderRadius: "0px",
    background: theme.palette.background.cancelButton,
    padding: "0px",
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  boxLoginTitle: {
    background: theme.palette.background.loginBackground,
  },
  paddingSmall: {
    padding: "16px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  formHint: {
    fontSize: theme.typography.hintText || 12,
    color: theme.palette.text.hintText,
    letterSpacing: "0.7px",
    textAlign: "left",
  },
  editionButton: {
    padding: "0px 16px",
    borderRadius: "20px",
    letterSpacing: "0.5px",
    background: theme.palette.lockIcon.primary,
    color: theme.palette.background.default,
    fontSize: theme.typography.footerMobile,
  },
  priceText: {
    fontFamily: "Montserrat",
    color: theme.palette.text.hintText,
    fontWeight: "600",
    fontSize: theme.typography.footerMobile,
    letterSpacing: "0.8px",
  },
  priceTextValue: {
    fontFamily: "Montserrat",
    color: theme.palette.text.alternative2,
    fontWeight: "600",
    fontSize: theme.typography.footerMobile,
    letterSpacing: "0.8px",
  },
  priceValueBox: {
    background: theme.palette.background.filter,
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    borderRadius: "9px",
  },
  priceValueText: {
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    color: theme.palette.text.alternative2,
    fontWeight: "600",
    fontSize: theme.typography.text,
  },
  aMain: {
    color: theme.palette.passwordIcon.primary,
    "&:hover": {
      color: theme.palette.passwordIcon.primary,
      textDecoration: "underline",
    },
    letterSpacing: "1px",
    fontWeight: "600",
  },
  tableHead: {
    border: `1px solid ${theme.palette.background.loginBackground}`,
  },
  tableBody: {
    border: `1px solid ${theme.palette.background.menuItemBorderBottom}`,
  },
  summarytableCellHeader: {
    fontSize: theme.typography.footerMobile,
    color: theme.palette.text.hintText,
    // borderBottom: `1px solid ${theme.palette.text.hintText}`,
  },
  summarytableCellBody: {
    fontSize: theme.typography.footerMobile,
    color: theme.palette.text.hintText,
    borderBottom: `1px solid ${theme.palette.lockIcon.primary}`,
  },
}));

export const MAPDoctorTitle = {
  lek: "Lekarz Specjalista",
  dr: "Dr n. med.",
  prof: "Profesor",
};

export const TestKitSummary = ({
  deliveryInfo,
  billingInfo,
  setActiveStep,
  provideShippingData,
  provideFVData,
  productsToBuy,
  handleFinish,
  handleNext,
  handleBack,
  activeStep,
  steps,
}) => {
  const classes = useStyles();
  const total = () => {
    return productsToBuy
      .map(({ price }) => price)
      .reduce((sum, i) => sum + i, 0);
  };
  const ccyFormat = (num) => {
    return `${(num / 100).toFixed(2)}`;
  };
  return (
    <Box className={classes.rootBox}>
      <Paper className={classes.paper}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6} lg={4}>
            <TableContainer>
              <Table>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell align="center" className={classes.tableCell}>
                      <Box
                        display="flex"
                        justifyContent="space-evenly"
                        alignItems="center"
                        className={`${classes.boxLoginTitle} ${classes.paddingSmall}`}
                      >
                        <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                        <Typography className={classes.loginTitle}>
                          Dane adresowe
                        </Typography>
                        <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  <TableRow>
                    <TableCell
                      className={classes.tableCellBody}
                      style={{ padding: "32px 16px 4px 16px" }}
                    >
                      {`${deliveryInfo.name}` || "---"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCellBody}>
                      {`${deliveryInfo.street}` || "---"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCellBody}>
                      {`${deliveryInfo.city}` || "---"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCellBody}>
                      {`${deliveryInfo.zipCode}` || "---"}
                    </TableCell>
                  </TableRow>
                  {deliveryInfo.phone && deliveryInfo.phone !== "+48" && (
                    <TableRow>
                      <TableCell className={classes.tableCellBody}>
                        {`${deliveryInfo.phone}` || "---"}
                      </TableCell>
                    </TableRow>
                  )}
                  {deliveryInfo.mail && (
                    <TableRow>
                      <TableCell
                        className={classes.tableCellBody}
                      >{`${deliveryInfo.mail}`}</TableCell>
                    </TableRow>
                  )}
                  {deliveryInfo.notes && (
                    <TableRow>
                      <TableCell
                        className={classes.tableCellBody}
                      >{`${deliveryInfo.notes}`}</TableCell>
                    </TableRow>
                  )}
                  <TableRow className={classes.tableCellBody}>
                    <TableCell
                      className={classes.tableCellBody}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "4px 16px 16px 4px",
                      }}
                    >
                      <Button
                        variant="contained"
                        className={classes.editionButton}
                        onClick={() => setActiveStep(1)}
                      >
                        EDYTUJ
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {provideFVData && (
              <TableContainer style={{ marginTop: "15px" }}>
                <Table>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell align="center" className={classes.tableCell}>
                        <Box
                          display="flex"
                          justifyContent="space-evenly"
                          alignItems="center"
                          className={`${classes.boxLoginTitle} ${classes.paddingSmall}`}
                        >
                          <StopIcon
                            style={{ height: "8px", color: "#3458A4" }}
                          />
                          <Typography className={classes.loginTitle}>
                            Dane do faktury
                          </Typography>
                          <StopIcon
                            style={{ height: "8px", color: "#23EAB6" }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    <TableRow>
                      <TableCell
                        className={classes.tableCellBody}
                        style={{ padding: "32px 16px 4px 16px" }}
                      >
                        {`${billingInfo.nameFV}` || "---"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCellBody}>
                        {`${billingInfo.streetFV}` || "---"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCellBody}>
                        {`${billingInfo.cityFV}` || "---"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCellBody}>
                        {`${billingInfo.zipFV}` || "---"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCellBody}>
                        {`${billingInfo.taxID}` || "---"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={classes.tableCellBody}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "4px 16px 16px 4px",
                        }}
                      >
                        <Button
                          variant="contained"
                          className={classes.editionButton}
                          onClick={() => setActiveStep(1)}
                        >
                          EDYTUJ
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <TableContainer>
              <Table aria-label="table summary">
                <TableHead>
                  <TableRow>
                    <TableCell
                      colspan={4}
                      align="center"
                      className={classes.tableCell}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-evenly"
                        alignItems="center"
                        className={`${classes.boxLoginTitle} ${classes.paddingSmall}`}
                      >
                        <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                        <Typography className={classes.loginTitle}>
                          Twoje zamówienie
                        </Typography>
                        <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={classes.summarytableCellHeader}
                    >
                      Badanie
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "center" }}
                      className={classes.summarytableCellHeader}
                    >
                      Ilość
                      {
                        <Button
                          variant="contained"
                          className={classes.editionButton}
                          style={{ margin: "5px" }}
                          onClick={() => setActiveStep(0)}
                        >
                          EDYTUJ
                        </Button>
                      }
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "center" }}
                      className={classes.summarytableCellHeader}
                    >
                      Opis
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "center" }}
                      className={classes.summarytableCellHeader}
                    >
                      Cena
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {productsToBuy
                    .filter(({ amount }) => amount > 0)
                    .map((p) => (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.summarytableCellBody}
                        >
                          {p.label}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center" }}
                          className={classes.summarytableCellBody}
                        >
                          {" "}
                          {p.amount}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center" }}
                          className={classes.summarytableCellBody}
                        >
                          {p.describeBy ? `tak` : `nie`}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center" }}
                          className={classes.summarytableCellBody}
                        >
                          {`${ccyFormat(p.price)} PLN`}
                        </TableCell>
                      </TableRow>
                    ))}
                  <TableRow style={{ paddingTop: 20 }}>
                    <CustomTableCell
                      colspan={3}
                      className={`${classes.tableLastBodyCell} `}
                      style={{ textAlign: "right" }}
                    >
                      <Typography className={classes.priceText}>
                        Do zapłaty
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell
                      className={classes.tableLastBodyCell}
                      style={{ textAlign: "center" }}
                    >
                      <Typography
                        className={`${classes.priceTextValue} ${classes.priceValueBox}`}
                        style={{}}
                      >
                        <Typography className={classes.priceValueText}>
                          {" "}
                          {`${ccyFormat(total())}`}
                        </Typography>
                        <span style={{ margin: "5px" }}>PLN</span>
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell className={classes.tableLastBodyCell} />
                  </TableRow>
                  <TableRow style={{ paddingTop: 20 }}>
                    <CustomTableCell
                      className={classes.tableLastBodyCell}
                      colspan={2}
                      style={{ textAlign: "right" }}
                    >
                      {" "}
                      <a className={classes.aMain} onClick={handleBack}>
                        Powrót
                      </a>
                    </CustomTableCell>
                    <CustomTableCell
                      colSpan={2}
                      className={classes.tableLastBodyCell}
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        type="submit"
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={
                          activeStep === steps.length - 1
                            ? handleFinish
                            : handleNext
                        }
                        style={{
                          width: "100%",
                          maxWidth: "250px",
                          borderRadius: "0px",
                          height: "50px",
                        }}
                      >
                        Realizuj zamówienie
                      </Button>
                    </CustomTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <br />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
