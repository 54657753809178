import React, { useState, useEffect, useRef, useMemo } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Button,
  ButtonGroup,
  Tooltip,
  ClickAwayListener,
  TablePagination,
  Box,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import format from "date-fns/format";

import Can from "../../components/can";

import Cookies from "js-cookie";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import FilterProperty, {
  FilterPropertySelect,
  UserFilterPropertySelect,
} from "../../components/filter-property/filter-property";
import { useDispatch, useSelector } from "react-redux";
import { fetchStands, fetchStandsWithPupils } from "../../redux/actions";
import StopIcon from "@material-ui/icons/Stop";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import { ReactComponent as InfoIconYellow } from "../../assets/info-yellow.svg";
import Loader from "../../components/loader";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "16px",
  },
  paper: {
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  boxLoginTitle: {
    background: theme.palette.background.loginBackground,
    display: "flex",
    justifyContent: "center",
  },
  widthBox: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  tooltip: {
    sx: {
      color: "purple",
      backgroundColor: "lightblue",
      fontSize: "2em",
    },
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingMedium: {
    padding: "16px 32px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  table: {
    minWidth: 1250,
  },
  tableContainer: {
    height: `calc(50vh - 55px)`,
    "@media (min-height: 300px)": {
      height: `calc(54vh - 55px)`,
    },
    "@media (min-height: 350px)": {
      height: `calc(60vh - 55px)`,
    },
    "@media (min-height: 400px)": {
      height: `calc(64vh - 55px)`,
    },
    "@media (min-height: 500px)": {
      height: `calc(70vh - 55px)`,
    },
    "@media (min-height: 600px)": {
      height: `calc(76vh - 55px)`,
    },
    "@media (min-height: 700px)": {
      height: `calc(80vh - 55px)`,
    },
    "@media (min-height: 800px)": {
      height: `calc(82vh - 55px)`,
    },
    "@media (min-height: 900px)": {
      height: `calc(84vh - 55px)`,
    },
  },
  tableHeader: {
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    borderBottom: "none",
    letterSpacing: "0.7px",
  },
  tableBodyCell: {
    color: theme.palette.text.hintText,
    borderBottom: `0.5px solid ${theme.palette.lockIcon.primary}`,
    fontFamily: "Montserrat",
  },
}));

const styles = (theme) => ({
  tooltip: {
    // width: "92px",
    // height: "36px",
    borderRadius: "10px",
    opacity: "0.5",
    boxShadow: "0px 2px 5px #BEBEBE",
    backgroundColor: theme.palette.background.default,
    padding: "16px",
    fontSize: theme.typography.desc,
    fontFamily: "Raleway",
    color: theme.palette.text.hintText,
    maxWidth: "150px",
    textAlign: "center",
  },
});

const CustomTooltip = withStyles(styles)(Tooltip);

const TestStandRow = ({
  testStand: {
    label: name,
    city,
    street,
    postCode,
    voivodeship,
    screeningTestName,
    stationType,
    allowedScreenings,
  },
}) => {
  const classes = useStyles();
  const type = stationType === "Open" ? "Otwarte" : "Zamknięte";
  return (
    <TableRow>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {name || ""}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {voivodeship || ""}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {city || ""}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {street || "---"}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {postCode || "---"}
      </TableCell>
      <TableCell
        style={{ textAlign: "center" }}
        className={classes.tableBodyCell}
      >
        {allowedScreenings.map(({ label }) => <p>{label}</p>) || "---"}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {type || ""}
      </TableCell>
    </TableRow>
  );
};

const EmptyTestStandsList = () => {
  const classes = useStyles();
  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";
  return (
    <Box className={classes.root2}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} md={10} lg={8} xl={6}>
          <Paper className={classes.paper}>
            <Box p={3}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Box>
                  {isLightGlobalTheme ? (
                    <InfoIcon />
                  ) : (
                    <InfoIconYellow style={{ cursor: "pointer" }} />
                  )}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  className={classes.widthBox}
                >
                  <Typography className={classes.loginTitle}>
                    Brak dostępnych stanowisk
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const TestStands = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";

  const [fetchingTestStands, setFetchingTestStands] = useState(true);
  const [testStandsToRender, setTestStandsToRender] = useState([]);
  const [
    unFilteredTestStandsToRender,
    setUnFilteredTestStandsToRender,
  ] = useState([]);

  useEffect(() => {
    dispatch(fetchStandsWithPupils());
  }, []);

  const testStandsWithPupils = useSelector((s) => s.availableStandsWithPupils);
  const testStands = useSelector((s) => s.availableStands);

  useEffect(() => {
    if (
      testStandsWithPupils &&
      testStandsWithPupils["stationsPerPatient"] &&
      testStandsWithPupils["screeningsPerStationPerPatient"]
    ) {
      let tmpHearBoxes = [];
      if (testStandsWithPupils["stationsPerPatient"]) {
        Object.entries(
          testStandsWithPupils["stationsPerPatient"]
        ).map(([k, v]) => tmpHearBoxes.push(v));
      }
      let mergedTestStands = [].concat
        .apply([], tmpHearBoxes)
        .filter(
          (tag, index, array) =>
            array.findIndex((t) => t.hearBoxId == tag.hearBoxId) == index
        );
      Object.entries(
        testStandsWithPupils["screeningsPerStationPerPatient"]
      ).map(([k, v]) =>
        Object.entries(v).map(([kNested, vNested]) => {
          mergedTestStands = mergedTestStands.map((testStand) => {
            if (testStand.hearBoxId === kNested) {
              return { ...testStand, allowedScreenings: vNested };
            } else return testStand;
          });
        })
      );
      setUnFilteredTestStandsToRender(
        mergedTestStands.sort((a, b) =>
          a.city !== b.city
            ? a.city.toUpperCase() < b.city.toUpperCase()
              ? -1
              : 1
            : 0
        )
      );
      setTestStandsToRender(
        mergedTestStands.sort((a, b) =>
          a.city !== b.city
            ? a.city.toUpperCase() < b.city.toUpperCase()
              ? -1
              : 1
            : 0
        )
      );
      setFetchingTestStands(false);
    }
  }, [testStandsWithPupils]);

  const [testStandsCount, setTestStandsCount] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [filterQueries, setFilterQueries] = useState({});

  useEffect(() => {
    const result = unFilteredTestStandsToRender.filter(search, filterQueries);
    setTestStandsToRender(result);
  }, [filterQueries]);

  function search(testStand) {
    return Object.keys(this).every((key) => {
      if (key !== "allowedScreenings" && key !== "stationType") {
        return testStand[key]
          .toString()
          .toUpperCase()
          .includes(this[key].toUpperCase());
      } else if (key === "allowedScreenings") {
        const screenings = testStand.allowedScreenings.filter((screening) =>
          screening.label.toLowerCase().includes(this[key].toLowerCase())
        );
        return this[key]
          ? testStand[key].filter((x) =>
              screenings.some((y) => y.label === x.label)
            ).length > 0
          : testStand;
      } else if (key === "stationType") {
        const parsed = JSON.parse(this[key]);
        return testStand[key] === parsed.value;
      }
    });
  }

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const headerCells = [
    {
      title: "Nazwa",
      key: "label",
      // sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Województwo",
      key: "voivodeship",
      // sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Miasto",
      key: "city",
      // sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Ulica",
      key: "street",
      // sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Kod pocztowy",
      key: "postCode",
      // sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Nazwa przesiewu",
      key: "allowedScreenings",
      // sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Rodzaj",
      key: "stationType",
      // sortable: true,
      filter: (key) => (
        <UserFilterPropertySelect
          label={"Rodzaj"}
          listItems={[
            { value: "Open", label: "Otwarty" },
            { value: "Closed", label: "Zamknięty" },
          ]}
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  return fetchingTestStands ? (
    <Loader loading={true} text="Wczytywanie stanowisk" />
  ) : (
    <Box className={classes.root}>
      {unFilteredTestStandsToRender.length === 0 ? (
        <EmptyTestStandsList />
      ) : (
        <Grid container style={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} md={10}>
            <Paper className={classes.paper}>
              <Box
                className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
              >
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  className={classes.widthBox}
                >
                  <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                  <Typography className={classes.loginTitle}>
                    Lista dostępnych stanowisk
                  </Typography>
                  <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
                </Box>
                <Box>
                  {isLightGlobalTheme ? (
                    <CustomTooltip
                      placement="right-start"
                      title="Lista stanowisk, na których możesz wykonać badania przypisane do Twojego konta. (Zwróć uwagę na to, które stanowisko jest przypisane do danego przesiewu) "
                    >
                      <InfoIcon style={{ cursor: "pointer" }} />
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip
                      placement="right-start"
                      title="Lista stanowisk, na których możesz wykonać badania przypisane do Twojego konta. (Zwróć uwagę na to, które stanowisko jest przypisane do danego przesiewu) "
                    >
                      <InfoIconYellow style={{ cursor: "pointer" }} />
                    </CustomTooltip>
                  )}
                </Box>
              </Box>
              <Box className={`${classes.paddingMedium}`}>
                <TableContainer className={classes.tableContainer}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        {headerCells.map((headCell, index) => (
                          <TableCell
                            key={headCell.key}
                            className={classes.tableHeader}
                            style={{ textAlign: "center" }}
                          >
                            {headCell.title}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {headerCells.map((headCell) => (
                          <TableCell key={headCell.key}>
                            {headCell.filter(headCell.key)}
                          </TableCell>
                        ))}
                      </TableRow>
                      {testStandsToRender.map((testStand, index) => (
                        <TestStandRow key={index} testStand={testStand} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const TestStandsPage = () => {
  return (
    <Can
      permission="test-stands-page:view"
      ok={() => <TestStands />}
      not={() => <Redirect to="/" />}
    />
  );
};

export default TestStandsPage;
