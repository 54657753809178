import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchActiveForms,
  fetchAllowedUsersToForm,
  fetchArchivedForms,
  fetchArchivedFormsBag,
  fetchFilledForms,
  fetchFormsScheduledForFuture,
  fetchPlannedForms,
  plannedFormsLength,
  fetchSavedFormData,
  removeDraft,
  removeForm,
} from "../../redux/actions";
import { Redirect } from "react-router-dom";
import SingleForm from "./single-form";
import Can from "../../components/can";
import PageTitle from "../../components/page-title";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import SingleScheduledForm from "./scheduled-form";
import groupBy from "lodash/groupBy";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Loader from "../../components/loader";
import { FilledFormData } from "../../components/filled-form-data";
import format from "date-fns/format";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Cookies from "js-cookie";

const useStyles = makeStyles((theme) => ({
  // table: {
  //   minWidth: 340,
  // },
  typography: {
    color: theme.palette.text.primary2,
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.text.item,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.item,
    },
  },

  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  tableCell: {
    fontSize: theme.typography.body1.fontSize,
    paddingRight: 2,
    paddingLeft: 3,
    right: 0,
    [theme.breakpoints.down("xs")]: {
      wordBreak: "break-all",
    },
  },
  lastTableCell: {
    fontSize: theme.typography.body1.fontSize,
    paddingRight: 0,
    paddingLeft: 3,
    rgiht: 0,
  },

  rootGrid: {
    display: "flex",
    // marginLeft: theme.spacing(0.1),
    overflowX: "auto",
    overflowY: "hidden",
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  button: {
    padding: "5px",
  },
  smallScreenTableCell: {
    [theme.breakpoints.down("xs")]: {
      wordWrap: "break-all",
      maxWidth: "75px",
    },
  },
  sortLabel: {
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down("xs")]: {
      wordBreak: "break-word",
      flexDirection: "column-reverse",
      width: "60px",
    },
  },
  // Fully visible for active icons
  activeSortIcon: {
    fill: theme.palette.text.primary2,
    opacity: 1,
    "&:hover": {
      opacity: 0.6,
    },
  },
  // Half visible for inactive icons
  inactiveSortIcon: {
    fill: theme.palette.text.primary2,
    opacity: 0.4,
    "&:hover": {
      opacity: 0.6,
    },
  },
}));

const CustomRow = ({
  history,
  form,
  setExpandedArchivedForms,
  expandedArchivedForms,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState();
  const archivedFormsBag = useSelector((s) => s.archivedFormsBag);
  const archivedForms = useSelector((s) => s.archivedForms);
  const globalTheme = useSelector((s) => s.globalTheme);
  const classes = useStyles();

  async function fetchAllFormsInBag() {
    await dispatch(fetchArchivedFormsBag({ bagId: form.bag.id }));
  }
  return (
    <React.Fragment>
      <TableRow>
        <TableCell numeric="true">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              !expandedArchivedForms[form.bag.id] && fetchAllFormsInBag();

              setExpandedArchivedForms((prevState) => ({
                ...prevState,
                [form.bag.id]: expandedArchivedForms[form.bag.id]
                  ? !expandedArchivedForms[form.bag.id]
                  : true,
              }));
            }}
          >
            {expandedArchivedForms[form.bag.id] ? (
              <Tooltip title="Zwiń listę historycznych wersji formularza.">
                <KeyboardArrowUpIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Rozwiń listę historycznych wersji formularza.">
                <KeyboardArrowDownIcon />
              </Tooltip>
            )}
          </IconButton>
        </TableCell>
        <TableCell numeric="true" component="th" scope="row">
          {form.name}
        </TableCell>
        <TableCell numeric="true">
          <Tooltip title="Wypełnione formularze. Kliknij by obejrzeć.">
            <Button
              variant="outlined"
              onClick={() => {
                history.push(`/formdata/all/${form.id}`);
              }}
              // className="button"
            >
              {form.filledFormCount || 0}
            </Button>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={6}
        >
          <Collapse
            in={expandedArchivedForms[form.bag.id]}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <Typography variant="subtitle1" gutterBottom component="div">
                Historyczne wersje formularza
              </Typography>
              <Table size="small" aria-label="filled">
                {archivedFormsBag[form.bag.id]?.length > 1 && (
                  <TableHead>
                    <TableRow>
                      <TableCell>Nazwa</TableCell>
                      <TableCell>Historia wypełnień</TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {archivedFormsBag[form.bag.id]?.length > 1 ? (
                    archivedFormsBag[form.bag.id]
                      .filter((form, index) => index !== 0)
                      .map((f, index) => (
                        <TableRow
                          key={index}
                          // className={classes.tableCell}
                        >
                          <TableCell component="th" scope="row">
                            {`${f.name} (v.${f.edition})`}
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Wypełnione formularze. Kliknij by obejrzeć.">
                              {/* <button
                                onClick={() => {
                                  history.push(`/formdata/all/${f.id}`);
                                }}
                                className="button"
                              >
                                {f.filledFormCount || 0}
                              </button> */}
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  history.push(`/formdata/all/${f.id}`);
                                }}
                                // className="button"
                              >
                                {f.filledFormCount || 0}
                              </Button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      className={classes.typography}
                    >
                      Brak
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const Page = ({
  history,
  loggedIn,
  filledForms,
  archivedFormsBag,
  fetchAllowedUsersToForm,
  allowedUsersToForm,
}) => {
  const globalTheme = useSelector((s) => s.globalTheme);
  const archivedForms = useSelector((s) => s.archivedForms) || null;
  const archivedFormsLength = useSelector((s) => s.archivedFormsLength) || null;
  const activeForms = useSelector((s) => s.activeForms) || null;
  const activeFormsLength = useSelector((s) => s.activeFormsLength) || null;
  const plannedForms = useSelector((s) => s.plannedForms) || null;
  const plannedFormsLength = useSelector((s) => s.plannedFormsLength) || null;
  const scheduledForFutureForms =
    useSelector((s) => s.scheduledForFutureForms) || null;
  const scheduledForFutureFormsLength =
    useSelector((s) => s.scheduledForFutureFormsLength) || null;
  const [groupedArchivedForms, setGroupedArchivedForms] = useState([]);
  const [groupedSavedForms, setGroupedSavedForms] = useState([]);
  const [open, setOpen] = useState(false);
  const [usersAllowedToForm, setUsersAllowedToForm] = useState([]);
  const [fetchingFormList, setFetchingFormList] = useState(false);
  const [formsFechtedDone, setFormsFechtedDone] = useState(false);
  const myId = useSelector((s) => s.my.id);
  const my = useSelector((s) => s.my);
  const savedForms = useSelector((s) => s.savedForms) || null;
  const savedFormsLength = useSelector((s) => s.savedFormsLength) || null;
  const dispatch = useDispatch();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const pages = [10, 20, 50];
  const [pageNr, setPageNr] = useState({
    formsActive: 0,
    scheduledActive: 0,
    scheduled: 0,
    archived: 0,
    saved: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState({
    formsActive: Cookies.get(`formsActive-per-page[${my.user.id}]`) || 10,
    scheduledActive:
      Cookies.get(`scheduledActive-per-page[${my.user.id}]`) || 10,
    scheduled: Cookies.get(`scheduled-per-page[${my.user.id}]`) || 10,
    archived: Cookies.get(`archived-per-page[${my.user.id}]`) || 10,
    saved: Cookies.get(`saved-per-page[${my.user.id}]`) || 10,
  });

  const [orderBy, setOrderBy] = useState({
    formsActive: "name",
    scheduledActive: "startDate",
    scheduled: "startDate",
    archived: "name",
    saved: "createdAt",
  });

  const [order, setOrder] = useState({
    formsActive: "asc",
    scheduledActive: "asc",
    scheduled: "asc",
    archived: "asc",
    saved: "desc",
  });
  // const [rowsPerPage, setRowsPerPage] = useState({
  //   formsActive: 10,
  //   scheduled: 10,
  //   archived: 10,
  //   saved: 10,
  // });

  const [expandedArchivedForms, setExpandedArchivedForms] = useState([]);

  useEffect(() => {
    async function fetchFormsBag() {
      await dispatch(fetchArchivedFormsBag({ bagId: archivedForms[0].bag.id }));
    }
    if (archivedFormsLength === 1 && archivedForms) {
      fetchFormsBag();
    }
  }, [archivedForms]);

  useEffect(() => {
    async function fetchFormdData() {
      if (loggedIn && pageNr && rowsPerPage) {
        await dispatch(
          fetchActiveForms({
            page: pageNr.formsActive,
            pageSize: rowsPerPage.formsActive,
            ...(orderBy.formsActive
              ? {
                  sortBy: `${
                    order.formsActive === "asc"
                      ? orderBy.formsActive
                      : "-" + orderBy.formsActive
                  }`,
                }
              : {}),
          })
        );
        await dispatch(
          fetchPlannedForms({
            page: pageNr.scheduledActive,
            pageSize: rowsPerPage.scheduledActive,
            ...(orderBy.scheduledActive
              ? {
                  sortBy: `${
                    order.scheduledActive === "asc"
                      ? orderBy.scheduledActive
                      : "-" + orderBy.scheduledActive
                  }`,
                }
              : {}),
          })
        );
        await dispatch(
          fetchArchivedForms({
            page: pageNr.archived,
            pageSize: rowsPerPage.archived,
            ...(orderBy.archived
              ? {
                  sortBy: `${
                    order.archived === "asc"
                      ? orderBy.archived
                      : "-" + orderBy.archived
                  }`,
                }
              : {}),
          })
        );
        await dispatch(
          fetchSavedFormData({
            page: pageNr.saved,
            pageSize: rowsPerPage.saved,
            ...(orderBy.saved
              ? {
                  sortBy: `${
                    order.saved === "asc" ? orderBy.saved : "-" + orderBy.saved
                  }`,
                }
              : {}),
          })
        );
        await dispatch(
          fetchFormsScheduledForFuture({
            page: pageNr.scheduled,
            pageSize: rowsPerPage.scheduled,
            ...(orderBy.scheduled
              ? {
                  sortBy: `${
                    order.scheduled === "asc"
                      ? orderBy.scheduled
                      : "-" + orderBy.scheduled
                  }`,
                }
              : {}),
          })
        );
      }
    }

    fetchFormdData();
  }, [loggedIn]);

  function useUpdateEffect(effect, dependencies = []) {
    const isInitialMount = useRef(true);

    useEffect(() => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        return effect();
      }
    }, dependencies);
  }

  useUpdateEffect(() => {
    async function fetchFormdData() {
      await dispatch(
        fetchActiveForms({
          page: pageNr.formsActive,
          pageSize: rowsPerPage.formsActive,
          ...(orderBy.formsActive
            ? {
                sortBy: `${
                  order.formsActive === "asc"
                    ? orderBy.formsActive
                    : "-" + orderBy.formsActive
                }`,
              }
            : {}),
        })
      );
    }
    fetchFormdData();
  }, [
    pageNr.formsActive,
    rowsPerPage.formsActive,
    order.formsActive,
    orderBy.formsActive,
  ]);

  useUpdateEffect(() => {
    async function fetchFormdData() {
      await dispatch(
        fetchArchivedForms({
          page: pageNr.archived,
          pageSize: rowsPerPage.archived,
          ...(orderBy.archived
            ? {
                sortBy: `${
                  order.archived === "asc"
                    ? orderBy.archived
                    : "-" + orderBy.archived
                }`,
              }
            : {}),
        })
      );
    }
    fetchFormdData();
  }, [pageNr.archived, rowsPerPage.archived, order.archived, orderBy.archived]);

  useUpdateEffect(() => {
    async function fetchFormdData() {
      await dispatch(
        fetchSavedFormData({
          page: pageNr.saved,
          pageSize: rowsPerPage.saved,
          ...(orderBy.saved
            ? {
                sortBy: `${
                  order.saved === "asc" ? orderBy.saved : "-" + orderBy.saved
                }`,
              }
            : {}),
        })
      );
    }
    fetchFormdData();
  }, [pageNr.saved, rowsPerPage.saved, order.saved, orderBy.saved]);

  useEffect(() => {
    async function fetchFormdData() {
      await dispatch(
        fetchPlannedForms({
          page: pageNr.scheduledActive,
          pageSize: rowsPerPage.scheduledActive,
          ...(orderBy.scheduledActive
            ? {
                sortBy: `${
                  order.scheduledActive === "asc"
                    ? orderBy.scheduledActive
                    : "-" + orderBy.scheduledActive
                }`,
              }
            : {}),
        })
      );
      await dispatch(
        fetchFormsScheduledForFuture({
          page: pageNr.scheduled,
          pageSize: rowsPerPage.scheduled,
          ...(orderBy.scheduled
            ? {
                sortBy: `${
                  order.scheduled === "asc"
                    ? orderBy.scheduled
                    : "-" + orderBy.scheduled
                }`,
              }
            : {}),
        })
      );
    }
    fetchFormdData();
  }, [
    pageNr.scheduledActive,
    rowsPerPage.scheduledActive,
    order.scheduledActive,
    orderBy.scheduledActive,
  ]);
  useEffect(() => {
    async function fetchFormdData() {
      await dispatch(
        fetchPlannedForms({
          page: pageNr.scheduledActive,
          pageSize: rowsPerPage.scheduledActive,
          ...(orderBy.scheduledActive
            ? {
                sortBy: `${
                  order.scheduledActive === "asc"
                    ? orderBy.scheduledActive
                    : "-" + orderBy.scheduledActive
                }`,
              }
            : {}),
        })
      );
      await dispatch(
        fetchFormsScheduledForFuture({
          page: pageNr.scheduled,
          pageSize: rowsPerPage.scheduled,
          ...(orderBy.scheduled
            ? {
                sortBy: `${
                  order.scheduled === "asc"
                    ? orderBy.scheduled
                    : "-" + orderBy.scheduled
                }`,
              }
            : {}),
        })
      );
    }
    fetchFormdData();
  }, [
    pageNr.scheduled,
    rowsPerPage.scheduled,
    order.scheduled,
    orderBy.scheduled,
  ]);

  useEffect(() => {
    setFormsFechtedDone(
      activeForms &&
        plannedForms &&
        archivedForms &&
        scheduledForFutureForms &&
        savedForms
        ? true
        : false
    );
  }, [
    archivedForms,
    activeForms,
    plannedForms,
    savedForms,
    scheduledForFutureForms,
  ]);

  useEffect(() => {
    if (
      usersAllowedToForm?.length === plannedForms?.length ||
      !plannedForms ||
      !usersAllowedToForm
    ) {
      setFetchingFormList(false);
    }
  }, [usersAllowedToForm, plannedForms]);

  useEffect(() => {
    if (plannedForms) {
      plannedForms.map((pForm) => {
        fetchAllowedUsersToForm(pForm.form.id).then(function (result) {
          setUsersAllowedToForm((oldArray) => [
            ...oldArray,
            { id: pForm.form.id, users: result },
          ]);
        });
      });
    }
  }, [plannedForms]);

  useEffect(() => {
    if (
      Number.isInteger(savedFormsLength / rowsPerPage.saved) &&
      savedFormsLength / rowsPerPage.saved < pageNr.saved + 1 &&
      pageNr.saved > 0
    ) {
      setPageNr({ ...pageNr, saved: pageNr.saved - 1 });
    }
  }, [savedForms]);

  useEffect(() => {
    if (archivedForms) {
      var grouped = groupBy(archivedForms, function (archivedForm) {
        return archivedForm.bag.id;
      });

      for (var propt in grouped) {
        grouped[propt].sort(function (a, b) {
          return b.edition - a.edition;
        });
      }

      grouped = Object.entries(grouped).map((group) => group[1]);
      grouped.sort((a, b) => a[0].name.localeCompare(b[0].name));
      setGroupedArchivedForms(grouped);
    }
  }, [archivedForms]);

  const headerCells = {
    formsActive: [{ id: "name", label: "Nazwa formularza" }],
    archived: [{ id: "name", label: "Nazwa formularza" }],
    scheduledActive: [
      { id: "startDate", label: "Aktwyny od" },
      { id: "endDate", label: "Aktywny do" },
    ],
    scheduled: [
      { id: "startDate", label: "Aktwyny od" },
      { id: "endDate", label: "Aktywny do" },
    ],
    saved: [
      { id: "name", label: "Nazwa formularza" },
      { id: "createdAt", label: "Data utworzenia" },
    ],
  };

  const fill = (id) => {
    history.push(`/formdata/add/${id}`);
  };
  const remove = (id) => {
    removeForm(id);
  };

  const handleChangePage = (name, event, newPage) => {
    setPageNr((prevState) => ({ ...prevState, [name]: newPage }));
  };

  const handleChangeRowsPerPage = (name, event) => {
    Cookies.set(`${name}-per-page[${my.user.id}]`, event.target.value, {
      sameSite: "strict",
    });
    setRowsPerPage((prevState) => ({
      ...prevState,
      [name]: parseInt(event.target.value, 10),
    }));
    setPageNr((prevState) => ({ ...prevState, [name]: 0 }));
  };

  const handleSortRequest = (name, cellId) => {
    const isAsc = orderBy[name] === cellId && order[name] === "asc";
    setOrder((prevState) => ({
      ...prevState,
      [name]: isAsc ? "desc" : "asc",
    }));
    setOrderBy((prevState) => ({ ...prevState, [name]: cellId }));
    // const isAsc = orderBy === cellId && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(cellId);
  };

  const classes = useStyles();

  function UngrouppedRow({ form, id, createdAt }) {
    return (
      <TableRow className={classes.root}>
        <TableCell
          numeric="true"
          className={classes.tableCell}
          component="th"
          scope="row"
        >
          {form.name}
        </TableCell>
        <TableCell
          numeric="true"
          className={classes.tableCell}
          component="th"
          scope="row"
        >
          {format(new Date(createdAt), "yyyy-MM-dd HH:mm")}
        </TableCell>
        <TableCell numeric="true" className={classes.tableCell}>
          {/* <button
            onClick={() => {
              history.push(`/formdata/edit/${form.id}/${id}`);
            }}
            className="button"
          >
            Edytuj
          </button> */}
          <Button
            variant="outlined"
            className={`${classes.button} ${classes.fontSize}`}
            onClick={() => {
              history.push(`/formdata/edit/${form.id}/${id}`);
            }}
            // className="button"
          >
            Edytuj
          </Button>

          {/* <button
            onClick={async () => {
              await dispatch(removeDraft(id));
              await fetchPlannedForms();
            }}
            className="button"
          >
            Usuń
          </button> */}
          <Button
            variant="outlined"
            className={`${classes.button} ${classes.fontSize}`}
            onClick={async () => {
              const response = await dispatch(removeDraft(id));
              if (response) {
                await dispatch(
                  fetchSavedFormData({
                    page: pageNr.saved,
                    pageSize: rowsPerPage.saved,
                    ...(orderBy.saved
                      ? {
                          sortBy: `${
                            order.saved === "asc"
                              ? orderBy.saved
                              : "-" + orderBy.saved
                          }`,
                        }
                      : {}),
                  })
                );
                await fetchPlannedForms({
                  page: pageNr.scheduledActive,
                  pageSize: rowsPerPage.scheduledActive,
                  ...(orderBy.scheduledActive
                    ? {
                        sortBy: `${
                          order.scheduledActive === "asc"
                            ? orderBy.scheduledActive
                            : "-" + orderBy.scheduledActive
                        }`,
                      }
                    : {}),
                });
              }
            }}
            // className="button"
          >
            Usuń
          </Button>
        </TableCell>
      </TableRow>
    );
  }
  const page = () => (
    <div>
      {!formsFechtedDone ? (
        <Grid>
          <Box p={3}>
            <Loader loading={true} text="Pobieranie listy formularzy..." />
          </Box>
        </Grid>
      ) : (
        <div className={classes.rootGrid}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box p={1}>
                <PageTitle title="Aktywne formularze" />
              </Box>
              {!(activeForms && activeFormsLength > 0) ? (
                <Box p={1}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.typography}
                  >
                    Brak
                  </Typography>
                </Box>
              ) : (
                <>
                  <Paper className={classes.rootGrid}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          {headerCells["formsActive"].map(({ label, id }) => (
                            <TableCell
                              key={id}
                              className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
                            >
                              <TableSortLabel
                                className={classes.sortLabel}
                                active={orderBy.formsActive === id}
                                direction={
                                  orderBy.formsActive === id
                                    ? order.formsActive
                                    : "asc"
                                }
                                onClick={() => {
                                  handleSortRequest("formsActive", id);
                                }}
                                classes={{
                                  // Override with the active class if this is the selected column or inactive otherwise
                                  icon:
                                    orderBy.formsActive === id
                                      ? classes.activeSortIcon
                                      : classes.inactiveSortIcon,
                                }}
                              >
                                {label}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                          <TableCell
                            numeric="true"
                            className={classes.tableCell}
                          >
                            Historia wypełnień
                          </TableCell>
                          <TableCell
                            numeric="true"
                            className={classes.tableCell}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {activeForms &&
                          activeForms.map((form) => (
                            <SingleForm
                              filledFormsDisplay={false}
                              key={form.id}
                              form={form}
                              fill={fill}
                              // fetchFilledForms={fetchFilledForms}
                              filledForms={filledForms}
                              history={history}
                            />
                          ))}
                      </TableBody>
                    </Table>
                  </Paper>
                  <TablePagination
                    component="div"
                    className={classes.select}
                    page={pageNr.formsActive}
                    rowsPerPageOptions={pages}
                    rowsPerPage={rowsPerPage.formsActive}
                    count={activeFormsLength}
                    onChangePage={(e, newPage) =>
                      handleChangePage("formsActive", e, newPage)
                    }
                    onChangeRowsPerPage={(e) =>
                      handleChangeRowsPerPage("formsActive", e)
                    }
                    labelRowsPerPage={"Wierszy na stronę:"}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} z ${count}`
                    }
                  />
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <Box p={1}>
                <PageTitle title="Aktywne zaplanowane formularze" />
              </Box>
              {!(
                plannedForms &&
                plannedFormsLength > 0 &&
                plannedForms.find((f) => f.form.status === "ACTIVE")
              ) ? (
                <Box p={1}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.typography}
                  >
                    Brak
                  </Typography>
                </Box>
              ) : (
                <>
                  <Paper className={classes.rootGrid}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            Nazwa formularza
                          </TableCell>
                          {headerCells["scheduledActive"].map(
                            ({ label, id }) => (
                              <TableCell
                                key={id}
                                className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
                              >
                                <TableSortLabel
                                  className={classes.sortLabel}
                                  active={orderBy.scheduledActive === id}
                                  direction={
                                    orderBy.scheduledActive === id
                                      ? order.scheduledActive
                                      : "asc"
                                  }
                                  onClick={() => {
                                    handleSortRequest("scheduledActive", id);
                                  }}
                                  classes={{
                                    // Override with the active class if this is the selected column or inactive otherwise
                                    icon:
                                      orderBy.scheduledActive === id
                                        ? classes.activeSortIcon
                                        : classes.inactiveSortIcon,
                                  }}
                                >
                                  {label}
                                </TableSortLabel>
                              </TableCell>
                            )
                          )}
                          {/* <TableCell
                            className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
                          >
                            Nazwa formularza
                          </TableCell>
                          <TableCell
                            numeric="true"
                            className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
                            style={{
                              maxWidth: smallScreen && "45px",
                            }}
                          >
                            Aktywny od
                          </TableCell>
                          <TableCell
                            numeric="true"
                            className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
                            style={{
                              maxWidth: smallScreen && "45px",
                            }}
                          >
                            Aktywny do
                          </TableCell> */}
                          <TableCell
                            numeric="true"
                            className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
                          >
                            Pozostały czas
                          </TableCell>
                          <TableCell
                            numeric="true"
                            className={classes.lastTableCell}
                            style={{
                              position: "sticky",
                              right: 0,
                              wordWrap: "break-word",
                            }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <>
                          {plannedForms &&
                            plannedForms.map((form) => {
                              const formWithUsersAllowed = usersAllowedToForm.find(
                                ({ id }) => id === form.form.id
                              );
                              if (
                                formWithUsersAllowed &&
                                formWithUsersAllowed.users.length > 0
                              ) {
                                return (
                                  form.form.status === "ACTIVE" &&
                                  formWithUsersAllowed.users.find(
                                    ({ userId }) => userId === myId
                                  ) &&
                                  formWithUsersAllowed.users.find(
                                    ({ userId }) => userId === myId
                                  ).selected && (
                                    <SingleScheduledForm
                                      key={form.id}
                                      scheduledForm={form}
                                      fill={(id, schedId) =>
                                        history.push({
                                          pathname: `/formdata/fill-scheduled-test/${id}/${schedId}`,
                                          state: { endDate: form.endDate },
                                        })
                                      }
                                      // fetchFilledForms={fetchFilledForms}
                                      filledForms={filledForms}
                                      history={history}
                                    />
                                  )
                                );
                              }

                              return (
                                form.form.status === "ACTIVE" && (
                                  <SingleScheduledForm
                                    key={form.id}
                                    scheduledForm={form}
                                    fill={(id, schedId) =>
                                      history.push({
                                        pathname: `/formdata/fill-scheduled-test/${id}/${schedId}`,
                                        state: { endDate: form.endDate },
                                      })
                                    }
                                    // fetchFilledForms={fetchFilledForms}
                                    filledForms={filledForms}
                                    history={history}
                                  />
                                )
                              );
                            })}
                        </>
                      </TableBody>
                    </Table>
                  </Paper>
                  <TablePagination
                    component="div"
                    className={classes.select}
                    page={pageNr.scheduledActive}
                    rowsPerPageOptions={pages}
                    rowsPerPage={rowsPerPage.scheduledActive}
                    count={plannedFormsLength}
                    onChangePage={(e, newPage) =>
                      handleChangePage("scheduledActive", e, newPage)
                    }
                    onChangeRowsPerPage={(e) =>
                      handleChangeRowsPerPage("scheduledActive", e)
                    }
                    labelRowsPerPage={"Wierszy na stronę:"}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} z ${count}`
                    }
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box p={1}>
                <PageTitle title="Zaplanowane formularze" />
              </Box>
              {!(
                scheduledForFutureForms && scheduledForFutureForms.length > 0
              ) ? (
                <Box p={1}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.typography}
                  >
                    Brak
                  </Typography>
                </Box>
              ) : (
                <>
                  <Paper className={classes.rootGrid}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            Nazwa formularza
                          </TableCell>
                          {headerCells["scheduled"].map(({ label, id }) => (
                            <TableCell
                              key={id}
                              className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
                            >
                              <TableSortLabel
                                className={classes.sortLabel}
                                active={orderBy.scheduled === id}
                                direction={
                                  orderBy.scheduled === id
                                    ? order.scheduled
                                    : "asc"
                                }
                                classes={{
                                  // Override with the active class if this is the selected column or inactive otherwise
                                  icon:
                                    orderBy.scheduled === id
                                      ? classes.activeSortIcon
                                      : classes.inactiveSortIcon,
                                }}
                                onClick={() => {
                                  handleSortRequest("scheduled", id);
                                }}
                              >
                                {label}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                          <TableCell
                            numeric="true"
                            className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
                          >
                            Pozostały czas
                          </TableCell>
                          <TableCell
                            numeric="true"
                            className={classes.lastTableCell}
                            style={{
                              position: "sticky",
                              right: 0,
                              wordWrap: "break-word",
                            }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <>
                          {scheduledForFutureForms.map((formWrapper) => (
                            <TableRow>
                              <TableCell
                                className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
                                component="th"
                                scope="row"
                              >
                                {formWrapper.form.name}
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
                              >
                                {format(
                                  new Date(formWrapper.startDate),
                                  "yyyy-MM-dd HH:mm"
                                )}
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
                              >
                                {format(
                                  new Date(formWrapper.endDate),
                                  "yyyy-MM-dd HH:mm"
                                )}
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
                              ></TableCell>
                              <TableCell
                                align="right"
                                className={`${classes.lastTableCell} ${classes.smallScreenTableCell}`}
                              ></TableCell>
                            </TableRow>
                          ))}
                        </>
                      </TableBody>
                    </Table>
                  </Paper>
                  <TablePagination
                    component="div"
                    className={classes.select}
                    page={pageNr.scheduled}
                    rowsPerPageOptions={pages}
                    rowsPerPage={rowsPerPage.scheduled}
                    count={scheduledForFutureFormsLength}
                    onChangePage={(e, newPage) =>
                      handleChangePage("scheduled", e, newPage)
                    }
                    onChangeRowsPerPage={(e) =>
                      handleChangeRowsPerPage("scheduled", e)
                    }
                    labelRowsPerPage={"Wierszy na stronę:"}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} z ${count}`
                    }
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box p={1}>
                <PageTitle title="Archiwalne formularze" />
              </Box>
              {!(archivedForms?.length > 0) ? (
                <Box p={1}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.typography}
                  >
                    Brak
                  </Typography>
                </Box>
              ) : archivedFormsLength === 1 &&
                archivedFormsBag[archivedForms[0]?.bag.id]?.length === 1 ? (
                // &&
                //   archivedFormsBag &&
                //   archivedFormsBag[archivedForms[0].bag.id].length === 1
                <Box p={1}>
                  <FilledFormData
                    formId={archivedForms[0].id}
                    showMissedFilter={true}
                    showMissed={true}
                  ></FilledFormData>
                </Box>
              ) : (
                <>
                  <Paper className={classes.rootGrid}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell} />
                          {headerCells["archived"].map(({ label, id }) => (
                            <TableCell
                              key={id}
                              // className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
                            >
                              <TableSortLabel
                                // className={classes.sortLabel}
                                active={orderBy.archived === id}
                                direction={
                                  orderBy.archived === id
                                    ? order.archived
                                    : "asc"
                                }
                                classes={{
                                  // Override with the active class if this is the selected column or inactive otherwise
                                  icon:
                                    orderBy.archived === id
                                      ? classes.activeSortIcon
                                      : classes.inactiveSortIcon,
                                }}
                                onClick={() => {
                                  handleSortRequest("archived", id);
                                }}
                              >
                                {label}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                          <TableCell numeric="true">
                            Historia wypełnień
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {archivedForms.length > 0 &&
                          archivedForms.map((groupedArchivedForm, index) => (
                            <CustomRow
                              key={index}
                              history={history}
                              form={groupedArchivedForm}
                              expandedArchivedForms={expandedArchivedForms}
                              setExpandedArchivedForms={
                                setExpandedArchivedForms
                              }
                            />
                          ))}
                      </TableBody>
                    </Table>
                  </Paper>
                  <TablePagination
                    component="div"
                    className={classes.select}
                    page={pageNr.archived}
                    rowsPerPageOptions={pages}
                    rowsPerPage={rowsPerPage.archived}
                    count={archivedFormsLength}
                    onChangePage={(e, newPage) =>
                      handleChangePage("archived", e, newPage)
                    }
                    onChangeRowsPerPage={(e) =>
                      handleChangeRowsPerPage("archived", e)
                    }
                    labelRowsPerPage={"Wierszy na stronę:"}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} z ${count}`
                    }
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box p={1}>
                <PageTitle title="Zapisane formularze - wersja robocza" />
              </Box>
              {!(savedFormsLength > 0) ? (
                <Box p={1}>
                  <Typography variant="h6" gutterBottom>
                    Brak
                  </Typography>
                </Box>
              ) : (
                <p>
                  <Paper className={classes.rootGrid}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          {headerCells["saved"].map(({ label, id }) => (
                            <TableCell
                              key={id}
                              className={`${classes.tableCell}`}
                            >
                              <TableSortLabel
                                className={classes.sortLabel}
                                active={orderBy.saved === id}
                                direction={
                                  orderBy.saved === id ? order.saved : "asc"
                                }
                                onClick={() => {
                                  handleSortRequest("saved", id);
                                }}
                                classes={{
                                  // Override with the active class if this is the selected column or inactive otherwise
                                  icon:
                                    orderBy.saved === id
                                      ? classes.activeSortIcon
                                      : classes.inactiveSortIcon,
                                }}
                              >
                                {label}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                          <TableCell
                            numeric="true"
                            className={classes.tableCell}
                          >
                            Opcje
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {savedForms.map((savedForm) => (
                          <UngrouppedRow {...savedForm} />
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                  <TablePagination
                    component="div"
                    className={classes.select}
                    page={pageNr.saved}
                    rowsPerPageOptions={pages}
                    rowsPerPage={rowsPerPage.saved}
                    count={savedFormsLength}
                    onChangePage={(e, newPage) =>
                      handleChangePage("saved", e, newPage)
                    }
                    onChangeRowsPerPage={(e) =>
                      handleChangeRowsPerPage("saved", e)
                    }
                    labelRowsPerPage={"Wierszy na stronę:"}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} z ${count}`
                    }
                  />
                </p>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="activeforms-page:view" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  loggedIn: state.loggedIn,
  filledForms: state.filledForms,
  archivedFormsBag: state.archivedFormsBag,
  allowedUsersToForm: state.allowedUsersToForm,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllowedUsersToForm: (formId) =>
    dispatch(fetchAllowedUsersToForm(formId)),
});

const ActiveFormsPage = connect(mapStateToProps, mapDispatchToProps)(Page);
export default ActiveFormsPage;
