import React from "react";
import { useSelector } from "react-redux";
import {
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as ArrowDownIcon } from "../../assets/arrowDownIcon.svg";
import { ReactComponent as ArrowDownIconYellow } from "../../assets/arrowDownIcon-yellow.svg";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment/moment";

const useStyles = makeStyles((theme) => ({
  selectText: {
    letterSpacing: "1px",
    lineHeight: `${theme.typography.formLabel}px`,
    fontSize: theme.typography.footer,
    color: theme.palette.text.hintText,
    textTransform: "uppercase",
    borderBottom: `1px solid ${theme.palette.background.menuItemBorderBottom}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
}));

const FilterProperty = ({
  filterProperty,
  setFilterQueries,
  filterQueries,
}) => {
  const removeFilterPropertyHandler = () => {
    const { [filterProperty]: removedProperty, ...rest } = filterQueries;
    setFilterQueries(rest);
  };

  return (
    <TextField
      type="text"
      value={filterQueries[filterProperty] || ""}
      InputProps={{
        startAdornment: (
          <Tooltip title="Filtrowanie">
            <FilterIcon />
          </Tooltip>
        ),
        endAdornment: (
          <IconButton
            onClick={removeFilterPropertyHandler}
            style={{ padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        ),
      }}
      onChange={({ target: { value } }) => {
        if (value) {
          setFilterQueries((prevQueries) => ({
            ...prevQueries,
            [filterProperty]: value,
          }));
        } else {
          removeFilterPropertyHandler();
        }
      }}
    />
  );
};

export const UserFilterPropertySelect = ({
  label,
  listItems,
  filterProperty,
  setFilterQueries,
  filterQueries,
}) => {
  const classes = useStyles();
  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";
  const removeFilterPropertyHandler = () => {
    const { [filterProperty]: removedProperty, ...rest } = filterQueries;
    setFilterQueries(rest);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value) {
      setFilterQueries((prevQueries) => ({
        ...prevQueries,
        [filterProperty]: value,
      }));
    } else {
      const { [filterProperty]: removedProperty, ...rest } = filterQueries;
      setFilterQueries(rest);
    }
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <Select
        labelId="select-label"
        id="simple-select"
        value={filterQueries[filterProperty] || ""}
        onChange={handleChange}
        IconComponent={(props) => (
          <i
            {...props}
            className={`material-icons ${props.className}`}
            style={{ top: "calc(50% - 20px)", right: "5px" }}
          >
            {isLightGlobalTheme ? <ArrowDownIcon /> : <ArrowDownIconYellow />}
          </i>
        )}
      >
        <MenuItem value="" className={classes.selectText}>
          <em>Brak</em>
        </MenuItem>
        {listItems.map((item) => (
          <MenuItem
            className={classes.selectText}
            key={item.value}
            value={JSON.stringify({
              label: item.label,
              value: item.value,
            })}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const FilterPropertySelect = ({
  label,
  listItems,
  filterProperty,
  setFilterQueries,
  filterQueries,
}) => {
  const removeFilterPropertyHandler = () => {
    const { [filterProperty]: removedProperty, ...rest } = filterQueries;
    setFilterQueries(rest);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value || typeof value == "boolean") {
      setFilterQueries((prevQueries) => ({
        ...prevQueries,
        [filterProperty]: value,
      }));
    } else {
      const { [filterProperty]: removedProperty, ...rest } = filterQueries;
      setFilterQueries(rest);
    }
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <Select
        labelId="select-label"
        id="simple-select"
        value={filterQueries[filterProperty] || ""}
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>Brak</em>
        </MenuItem>
        {listItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const FilterPropertyDate = ({
  filterProperty,
  setFilterQueries,
  filterQueries,
  onlyYear = false,
}) => {
  const removeFilterPropertyHandler = () => {
    const { [filterProperty]: removedProperty, ...rest } = filterQueries;
    setFilterQueries(rest);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value) {
      setFilterQueries((prevQueries) => ({
        ...prevQueries,
        [filterProperty]: value,
      }));
    } else {
      const { [filterProperty]: removedProperty, ...rest } = filterQueries;
      setFilterQueries(rest);
    }
  };

  return (
    <>
      <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
        <KeyboardDatePicker
          id="start-date-picker-dialog"
          placeholder={onlyYear ? "mm.rrrr" : "dd.mm.rrrr"}
          onChange={(value) => {
            setFilterQueries((prevQueries) => ({
              ...prevQueries,
              [filterProperty]: moment(value).format(
                onlyYear ? "YYYY/MM" : "YYYY-MM-DD"
              ),
            }));
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          format={onlyYear ? "MM/yyyy" : "dd-MM-yyyy"}
          views={onlyYear ? ["year", "month"] : ["date"]}
          cancelLabel={"Anuluj"}
          invalidDateMessage={
            filterQueries[filterProperty]?.length > 0 && "Nieprawidłowy format"
          }
          okLabel={"Zatwierdź"}
          value={
            filterQueries[filterProperty]?.length > 0
              ? filterQueries[filterProperty]
              : null
          }
          InputProps={{
            readOnly: true,
            startAdornment: (
              <IconButton
                onClick={removeFilterPropertyHandler}
                style={{ padding: 0 }}
              >
                <CloseIcon />
              </IconButton>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
      {/* <TextField
        id="date-local"
        type="date"
        defaultValue=""
        value={filterQueries[filterProperty] || ""}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={removeFilterPropertyHandler}
              style={{ padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          ),
        }}
        onChange={({ target: { value } }) => {
          setFilterQueries((prevQueries) => ({
            ...prevQueries,
            [filterProperty]: value,
          }));
        }}
      /> */}
    </>
  );
};

export default FilterProperty;
