import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.text2,
    color: theme.palette.text.alternative2,
    fontWeight: "600",
  },
}));

const PageTitle = ({ title }) => {
  const classes = useStyles();
  const globalTheme = useSelector((s) => s.globalTheme);

  return <Typography className={classes.title}>{title}</Typography>;
};

export default PageTitle;
