import React from "react";
import {
  Button,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
  SwipeableDrawer,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: "0px",
  },
  button: {
    fontSize: theme.typography.footerMobile,
  },
  buttonNested: {
    fontSize: theme.typography.hintText,
  },
}));

const Sidebar = ({
  open,
  onOpen,
  onClose,
  navigationLinks,
  history,
  pathname,
}) => {
  const classes = useStyles();
  const FullList = () => (
    <List className={classes.root}>
      {navigationLinks.map((link, id) =>
        link.header ? (
          <ListSubheader
            style={{ padding: "16px, 22px", textTransform: "uppercase" }}
            className={classes.button}
          >{`${link.label}`}</ListSubheader>
        ) : (
          <ListItem key={id} className={link.nested && classes.nested}>
            <Button
              className={link.nested ? classes.buttonNested : classes.button}
              style={{
                textDecoration: pathname === link.href ? "underline" : "",
              }}
              onClick={() => {
                onClose();
                history.push(link.href);
              }}
            >
              {link.label}
            </Button>
          </ListItem>
        )
      )}
    </List>
  );

  return (
    <SwipeableDrawer open={open} onOpen={onOpen} onClose={onClose}>
      <FullList />
    </SwipeableDrawer>
  );
};

export default Sidebar;
