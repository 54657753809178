import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Card,
  CardContent,
  TextField,
  Grid,
  Typography,
  IconButton,
  Button,
  withStyles,
  useMediaQuery,
  Box,
} from "@material-ui/core";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import { EXAMINATIONS_MAP } from "../../super-admin/packages/examinations";
import { useDispatch, useSelector } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { addProductToCart, alertAdd } from "../../redux/actions";
import { ReactComponent as ArrowDownIcon } from "../../assets/arrowDownIcon.svg";
import { ReactComponent as ArrowDownIconYellow } from "../../assets/arrowDownIcon-yellow.svg";

const CustomTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.text.alternative3,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.text.alternative2,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
      "& fieldset": {
        borderColor: theme.palette.text.alternative2,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.text.alternative2,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.text.alternative2,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "5px 10px",
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 250,
    borderRadius: "0px",
    // border: `0.5px solid ${theme.palette.hr.primary}`,
    boxShadow: "13px 18px 29px -9px rgb(82 82 92)",
    // [theme.breakpoints.down("lg")]: {
    //   padding: `15px`,
    // },
    [theme.breakpoints.down("md")]: {
      padding: `0px`,
    },
  },
  cardContent: {
    padding: "0px",
    // [theme.breakpoints.down("lg")]: {
    //   padding: `5px`,
    // },
  },
  icon: {
    marginRight: "12px",
    fontSize: "1.5rem",
    color: theme.palette.text.alternative2,
  },
  title: {
    color: theme.palette.text.alternative2,
    letterSpacing: "0.9px",
    fontWeight: "700",
    fontSize: theme.typography.text,
    lineHeight: "24px",
    textTransform: "uppercase",
  },
  price: {
    color: theme.palette.text.default,
    letterSpacing: "0.9px",
    fontSize: theme.typography.text,
    lineHeight: "24px",
  },
  desc: {
    color: theme.palette.text.hintText,
    letterSpacing: "0.55px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    lineHeight: "15px",
  },
  examinations: {
    color: theme.palette.text.hintText,
    letterSpacing: "0.4px",
    margin: "2px",
    fontWeight: "500",
    fontSize: theme.typography.desc,
    lineHeight: `${theme.typography.footer}px`,
  },
  priceSummary: {
    color: theme.palette.text.alternative3,
    letterSpacing: "0.49px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    lineHeight: "15.81px",
  },
  mainBox: {
    textAlign: "center",
    height: "100%",
  },
  titleBox: {
    backgroundColor: theme.palette.background.filter,
    [theme.breakpoints.up("md")]: {
      height: "27%",
    },
  },
  priceBox: {
    display: "inline-block",
    width: "auto",
    padding: "8px 16px",
    background: theme.palette.passwordIcon2.primary,
    fontFamily: "Noto Sans",
    fontWeight: "500",
  },
  text: {
    color: theme.palette.text.alternative3,
    letterSpacing: "0.48px",
  },
  incrementIcon: {
    border: `1px solid ${theme.palette.text.alternative2}`,
    opacity: "1",
    borderBottomLeftRadius: "0px",
    borderTopLeftRadius: "0px",
    color: theme.palette.text.alternative2,
    height: theme.typography.formLabel,
    width: "20px",
  },
  columnDirection: {
    display: "flex",
    flexDirection: "column",
  },
  menuItemFont: {
    letterSpacing: "0.5px",
    lineHeight: `${theme.typography.formLabel}px`,
    fontSize: theme.typography.footer,
    color: theme.palette.text.hintText,
    textTransform: "uppercase",
  },
  inputLabel: {
    "&.Mui-focused": {
      color: theme.palette.text.checked,
    },
  },

  select: {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.text.checked,
    },
    "&.MuiPaper-root": {
      borderRadius: "0px",
    },
    "&.MuiMenu-list": {
      background: "red",
    },
  },
  button: {
    width: "267px",
    height: "50px",
    borderRadius: "0px",
  },
}));

const doctorsTitle = [
  { value: "lek", label: "Lekarz Specjalista" },
  { value: "dr", label: "Dr n. med." },
  { value: "prof", label: "Profesor" },
];

export const TestCardComponent = ({
  product,
  disabled,
  setDataChanged,
  productsToBuy,
  setProductsToBuy,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const userLoggedIn = useSelector((s) => s.loggedIn);
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [priceText, setPriceText] = useState("");

  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";

  const productToBuy = productsToBuy.find(
    ({ hearBoxTestId }) => hearBoxTestId === product.hearBoxTestId
  );

  useEffect(() => {
    if (product.price) {
      const VAT = 0.23;
      const taxPrice =
        Math.round(
          ((product.price / 100).toFixed(2) * (VAT / 100).toFixed(2) +
            Number.EPSILON) *
            100
        ) / 100;
      let grossPrice = null;
      if (productToBuy.doctorDescription.title) {
        const additionalPrice = product.doctorDescriptionPrices.find(
          (t) => t.title === productToBuy.doctorDescription.title
        ).price;
        grossPrice = (
          (product.price * productToBuy.amount +
            additionalPrice * productToBuy.amount) /
            100 +
          taxPrice
        ).toFixed(2);
      } else {
        grossPrice = (
          (product.price * productToBuy.amount) / 100 +
          taxPrice
        ).toFixed(2);
      }

      setPriceText(`${grossPrice} PLN`);
    }
  }, [product, productsToBuy]);

  const handleDoctorTitleChange = (e) => {
    setProductsToBuy(
      productsToBuy.map((item) =>
        item.hearBoxTestId === productToBuy.hearBoxTestId
          ? {
              ...item,
              doctorDescription: {
                append: true,
                title: e.target.value,
              },
            }
          : item
      )
    );
  };

  const addToShoppingCart = async () => {
    try {
      const response = await dispatch(
        addProductToCart([
          {
            hearBoxTestId: productToBuy.hearBoxTestId,
            amount: productToBuy.amount,
            ...(productToBuy.doctorDescription.append && {
              describeBy: productToBuy.doctorDescription.title,
            }),
          },
        ])
      );
      if (response) {
        setDataChanged(false);
        dispatch(
          alertAdd({
            text: "Produkt dodany do koszyka",
            redirect: {
              text: "przejdź do koszyka",
              href: userLoggedIn ? "/test-kit/add" : "/order-test-kit",
            },
            customAlert: true,
            isSuccess: "true",
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Card className={classes.root} style={{ flex: "1", height: "100%" }}>
      <CardContent
        style={{ display: "flex", height: "100%", flexDirection: "column" }}
        className={classes.cardContent}
      >
        <Box className={classes.mainBox}>
          <Box className={classes.titleBox}>
            <Box p={3} style={{ height: !smallScreen && "65%" }}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.title}
              >
                {`${product.label}`}
              </Typography>
            </Box>
            <Box mb={3} className={classes.priceBox}>
              <span className={classes.price}>
                {`CENA: ${product.price / 100} PLN`}
              </span>
            </Box>
          </Box>
          <Box
            style={{
              height:
                !smallScreen &&
                (productToBuy.doctorDescription.append ? "35%" : "40%"),
            }}
          >
            <Box pt={3} pb={1}>
              <Typography className={classes.desc}>
                {`${product.description}`}
              </Typography>
            </Box>
            <Box>
              {product.examinations.map((e, i) => (
                <Typography className={classes.examinations}>
                  {EXAMINATIONS_MAP[e]}
                </Typography>
              ))}
            </Box>
          </Box>
          <Box
            style={{
              height:
                !smallScreen &&
                (productToBuy.doctorDescription.append ? "28%" : "20%"),
              paddingBottom: "10px",
            }}
          >
            <Box
              mt={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                disabled={productToBuy.amount < 1}
                onClick={() => {
                  productToBuy.amount > 0 &&
                    setProductsToBuy(
                      productsToBuy.map((item) =>
                        item.hearBoxTestId === productToBuy.hearBoxTestId
                          ? {
                              ...item,
                              amount: productToBuy.amount - 1,
                            }
                          : item
                      )
                    );
                  productToBuy.amount === 1 &&
                    setProductsToBuy(
                      productsToBuy.map((item) =>
                        item.hearBoxTestId === productToBuy.hearBoxTestId
                          ? {
                              ...item,
                              amount: 0,
                              doctorDescription: {
                                append: false,
                              },
                            }
                          : item
                      )
                    );
                  setDataChanged(true);
                }}
              >
                <RemoveOutlinedIcon
                  role="img"
                  aria-hidden="false"
                  focusable="true"
                  alt="zmniejsza wartość w koszyku o 1"
                  title="zmniejsza wartość w koszyku o 1"
                  className={classes.incrementIcon}
                />
              </IconButton>
              <span
                style={{
                  // color: theme.palette.text.alternative2,
                  letterSpacing: "0px",
                }}
              >
                <CustomTextField
                  style={{ width: "55px" }}
                  size="small"
                  disabled={disabled}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: 0, style: { textAlign: "center" } }}
                  value={productToBuy.amount}
                  variant="outlined"
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  onChange={(e) => {
                    let input = e.target.value;
                    if (
                      !input ||
                      (input[input.length - 1].match("[0-9]") &&
                        input[0].match("[0-9]"))
                    ) {
                      if (!input || input < 1) {
                        setProductsToBuy(
                          productsToBuy.map((item) =>
                            item.hearBoxTestId === productToBuy.hearBoxTestId
                              ? {
                                  ...item,
                                  amount: 0,
                                  doctorDescription: {
                                    append: false,
                                  },
                                }
                              : item
                          )
                        );
                      } else {
                        setProductsToBuy(
                          productsToBuy.map((item) =>
                            item.hearBoxTestId === productToBuy.hearBoxTestId
                              ? {
                                  ...item,
                                  amount: input,
                                }
                              : item
                          )
                        );
                      }
                    }
                  }}
                  type="text"
                />
              </span>
              <IconButton
                onClick={() => {
                  setDataChanged(true);
                  if (productToBuy.amount === "") {
                    setProductsToBuy(
                      productsToBuy.map((item) =>
                        item.hearBoxTestId === productToBuy.hearBoxTestId
                          ? {
                              ...item,
                              amount: 0,
                              doctorDescription: {
                                append: false,
                              },
                            }
                          : item
                      )
                    );
                  } else {
                    setProductsToBuy(
                      productsToBuy.map((item) =>
                        item.hearBoxTestId === productToBuy.hearBoxTestId
                          ? {
                              ...item,
                              amount: productToBuy.amount + 1,
                            }
                          : item
                      )
                    );
                  }
                }}
              >
                <AddOutlinedIcon
                  style={{ transform: "rotate(180deg)" }}
                  role="img"
                  aria-hidden="false"
                  alt="zwiększa wartość w koszyku o 1"
                  title="zwiększa wartość w koszyku o 1"
                  className={classes.incrementIcon}
                />
              </IconButton>
            </Box>
            <Box mt={1}>
              {priceText ? (
                <Typography gutterBottom className={classes.priceSummary}>
                  {`Cena aktualnego zestawu: ${priceText.replace(/\./g, ",")}`}
                </Typography>
              ) : (
                <CircularProgress size={14} />
              )}
            </Box>
            <Box
              pr={3}
              pl={3}
              pb={3}
              // style={{
              //   display: "flex",
              //   flexDirection: "column",
              //   alignItems: "center",
              // }}
            >
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          productToBuy.doctorDescription.append &&
                          productToBuy.amount > 0
                        }
                        onChange={(e) => {
                          setProductsToBuy(
                            productsToBuy.map((item) =>
                              item.hearBoxTestId === productToBuy.hearBoxTestId
                                ? {
                                    ...item,
                                    doctorDescription: {
                                      append: e.target.checked,
                                    },
                                  }
                                : item
                            )
                          );
                        }}
                        color="primary"
                        disabled={productToBuy.amount < 1}
                      />
                    }
                    label={
                      <Typography className={classes.priceSummary}>
                        Opis badania przez lekarza
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={10} style={{ width: "100%" }}>
                  {productToBuy.doctorDescription.append && (
                    <FormControl size="small" fullWidth>
                      <InputLabel className={classes.inputLabel}>
                        Tytuł lekarza
                      </InputLabel>
                      <Select
                        value={productToBuy.doctorDescription.title || ""}
                        onChange={handleDoctorTitleChange}
                        label="Tytuł lekarza"
                        className={classes.select}
                        IconComponent={(props) => (
                          <i
                            {...props}
                            className={`material-icons ${props.className}`}
                            style={{ top: "calc(50% - 20px)", right: "15px" }}
                          >
                            {isLightGlobalTheme ? (
                              <ArrowDownIcon />
                            ) : (
                              <ArrowDownIconYellow />
                            )}
                          </i>
                        )}
                      >
                        {doctorsTitle.map(({ label, value }) => (
                          <MenuItem
                            className={classes.menuItemFont}
                            value={value}
                          >{`${label} +${
                            product.doctorDescriptionPrices.find(
                              (t) => t.title === value
                            ).price / 100
                          } PLN`}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box style={{ height: !smallScreen && "10%" }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={
                productToBuy.amount == "0" ||
                (productToBuy.doctorDescription.append &&
                  !productToBuy.doctorDescription.title)
              }
              className={classes.button}
              onClick={addToShoppingCart}
            >
              Dodaj do koszyka
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
