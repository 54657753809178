import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TextField, Grid, Button, Snackbar, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ZipInput from "../ZipInput";
import MuiPhoneNumber from "material-ui-phone-number";
import GetAppIcon from "@material-ui/icons/GetApp";
import Alert from "@material-ui/lab/Alert";

export const CssTextField = withStyles((theme) => ({
  root: {
    "& .MuiFormLabel-filled": {
      color: theme.palette.success.dark,
    },
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: theme.palette.error.main,
    },
  },
}))(TextField);

export const normalizeZIP = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (cvLength < 3) {
    return currentValue;
  }
  return `${currentValue.slice(0, 2)}-${currentValue.slice(2, 5)}`;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formHint: {
    fontSize: theme.typography.footerMobile,
    lineHeight: "1.4",
    margin: "-5px auto 5px",
    color: theme.palette.text.hintText,
    fontFamily: "Montserrat",
  },
  greenBorder: {
    borderColor: theme.palette.success.dark,
  },
  textField: {
    "& p": {
      color: theme.palette.text.hintText,
    },
  },
}));

const ShippingAddressForm = ({
  provideShippingData,
  name,
  setName,
  street,
  setStreet,
  city,
  setCity,
  zipCode,
  setZipCode,
  post,
  setPost,
  phone,
  setPhone,
  // notes,
  // setNotes,
  setDataChanged,
  preferredChannel,
  fetchData = false,
  isUser = true,
}) => {
  const classes = useStyles();

  const [deletingPhone, setDeletingPhone] = useState(false);
  const [phoneLostFocus, setPhoneLostFocus] = useState(false);

  const [open, setOpen] = useState(false);
  const [copyDataFromProfile, setCopyDataFromProfile] = useState("");
  const [copyDataSeverity, setCopyDataSeverity] = useState("success");

  const address = useSelector((s) => s.my?.address);

  const fetchShippingData = () => {
    let isAddressEmpty = true;
    if (address) {
      isAddressEmpty = Object.values(address).every(
        (x) => x === null || x === ""
      );
    }

    if (!isAddressEmpty && address) {
      const { name, street, city, zipCode, post, phone } = address;
      setName(name || "");
      setStreet(street || "");
      setCity(city || "");
      setZipCode(zipCode || "");
      setPost(post || "");
      setPhone(phone || "");
      // setNotes(notes || "");
      setCopyDataFromProfile("Dane adresowe zostały skopiowane");
      setOpen(true);

      setDataChanged(true);
    } else {
      setCopyDataSeverity("warning");
      setCopyDataFromProfile(
        "Brak danych w profilu, dane nie zostały skopiowane"
      );
      setOpen(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCopyDataFromProfile("");
    setOpen(false);
  };

  return (
    <>
      {" "}
      {fetchData && (
        <Box mt={1}>
          <Button
            size="small"
            startIcon={<GetAppIcon />}
            onClick={() => fetchShippingData()}
          >
            Skopiuj dane z profilu
          </Button>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={open}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
          >
            {copyDataFromProfile && (
              <Alert onClose={handleCloseSnackbar} severity={copyDataSeverity}>
                {copyDataFromProfile}
              </Alert>
            )}
          </Snackbar>
        </Box>
      )}
      <Box>
        <CssTextField
          placeholder="* Imię i nazwisko"
          required
          value={name}
          InputLabelProps={{
            classes: {
              root: classes.cssLabelGreen,
            },
          }}
          fullWidth
          onChange={({ target: { value: name } }) => {
            setDataChanged(true);
            setName(name);
          }}
        />
      </Box>
      <Box mt={2}>
        <CssTextField
          placeholder="* Ulica"
          required
          value={street}
          fullWidth
          InputLabelProps={{
            classes: {
              root: classes.cssLabelGreen,
            },
          }}
          onChange={({ target: { value: street } }) => {
            setDataChanged(true);
            setStreet(street);
          }}
          helperText={street && "Przykład: Jasna 7A/5"}
        />
      </Box>
      <Box mt={2}>
        <CssTextField
          placeholder="* Miasto"
          required
          value={city}
          fullWidth
          onChange={({ target: { value: city } }) => {
            setDataChanged(true);
            setCity(city);
          }}
          // error={!city}
        />
      </Box>
      <Box mt={2}>
        <ZipInput
          zipCode={zipCode}
          setZipCode={setZipCode}
          setDataChanged={setDataChanged}
        />
      </Box>
      {/* <Box mt={2}>
        <CssTextField
          placeholder="* Poczta"
          required
          value={post}
          fullWidth
          onChange={({ target: { value: post } }) => {
            setPost(post);
          }}
          InputProps={{
            classes: {
              notchedOutline: post && classes.greenBorder,
            },
          }}
        />
      </Box> */}
      <Box mt={2}>
        <MuiPhoneNumber
          name="phone"
          placeholder="Telefon"
          data-cy="user-phone"
          defaultCountry={"pl"}
          regions={"europe"}
          fullWidth
          disabled={!provideShippingData}
          autoFormat={false}
          countryCodeEditable={false}
          required={false}
          error={phoneLostFocus && !(phone?.length === 12 || phone === "+48")}
          helperText={
            (phoneLostFocus && !(phone?.length === 12 || phone === "+48")
              ? "Nieprawidłowy numer telefonu."
              : "") ||
            (deletingPhone && preferredChannel === "phone"
              ? "Preferowany kanał do komunikacji zostanie zmieniony na email."
              : "")
          }
          inputProps={{ "aria-label": "Telefon" }}
          value={phone || ""}
          onChange={(e) => {
            setDataChanged(true);
            setPhoneLostFocus(false);
            setPhone(e);
          }}
          onFocus={() => setPhoneLostFocus(false)}
          onBlur={() => setPhoneLostFocus(true)}
        />
      </Box>
      {/* <Box mt={2}>
        <TextField
          placeholder="Uwagi"
          multiline
          rows={1}
          rowsMax={8}
          value={notes || ""}
          fullWidth
          inputProps={{ "aria-label": "Uwagi" }}
          onChange={({ target: { value: notes } }) => {
            setDataChanged(true);
            setNotes(notes);
          }}
          className={classes.textField}
          inputProps={{
            maxlength: 199,
          }}
          helperText={
            provideShippingData &&
            `Znaków do wprowadzenia: ${199 - notes.length}`
          }
        />
      </Box> */}
      {isUser && (
        <Box item mt={1}>
          <span className={classes.formHint}>* Pola wymagane</span>
        </Box>
      )}
    </>
  );
};

export default ShippingAddressForm;
