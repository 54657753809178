import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Can from "../../components/can";
import {
  Box,
  Button,
  Grid,
  Typography,
  withStyles,
  ListItem,
  List,
  makeStyles,
  Paper,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import QRCode from "qrcode";
import {
  fetchVoucherInfo,
  fetchScreeningInfo,
  fetchPackageInfo,
  fetchOrganization,
  fetchAssignedScreenings,
  fetchFilledFormsInfo,
} from "../../redux/actions";
import Loader from "../../components/loader";
import { EXAMINATIONS_MAP } from "../../super-admin/packages/examinations";

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.anchor.primary,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
      textDecoration: "underline",
    },
  },
}))(Link);

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "32px 16px 16px 16px",
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
  },
  paper: {
    textAlign: "left",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  locationButton: {
    backgroundColor: theme.palette.background.saveButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `140px`,
    height: "45px",
  },
  testButton: {
    backgroundColor: theme.palette.testIcon.primary,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `140px`,
    height: "45px",
  },
  list: {
    columns: "2 auto",
    padding: "0px",
    background: theme.palette.background.default,
    marginTop: "10px",
  },
  listItem: {
    padding: "0px",
    // background: theme.palette.background.default,
  },
  title: {
    fontSize: theme.typography.text2,
    color: theme.palette.text.alternative2,
    fontWeight: "600",
  },
  subtitle: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.alternative3,
    fontWeight: "600",
  },
  text: {
    fontSize: theme.typography.footerMobile,
    color: theme.palette.text.alternative3,
    fontWeight: "600",
  },
  paddingMedium: {
    padding: "16px 32px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  activeText: { color: theme.palette.text.alternative2 },
  paddingLarge: {
    padding: "32px 64px 64px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.lockIcon.primary}`,
  },
  backgroundGrey: {
    background: theme.palette.background.loginBackground,
  },
}));

const packages = [
  {
    package: "Badanie słuchu",
    tests: [
      "Audiometria Przesiewowa",
      "Audiometria Progowa",
      "Audiometria Słowna",
    ],
  },
  {
    package: "CAPD",
    tests: [
      "Test Dyskryminacji Wysokości",
      "Test Sekwencji Wysokości",
      "Test Sekwencji Długości",
      "Test Przerw Randomizowanych",
      "Test Rozdzielnouszny Cyfr",
      "Test Rozdzielnouszny Słów",
      "Test Rozumienia Mowy w Szumie",
      "Test Mowy Skompresowanej",
      "Test Mowy Filtrowanej",
    ],
  },
];

const SummaryAssign = ({ match }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const [qrCodeURL, setQRCodeURL] = useState("");
  const qrPIN = useSelector((s) => s.qrCodePIN);
  const qrID = useSelector((s) => s.qrCodeID);
  const organization = useSelector((s) => s.organization);
  const voucherID = match.params.voucherID;
  // const protegeAssigned = match.params.protegeAssigned;
  const [assignedPerson, setAssignedPerson] = useState(null);
  const [isSurveyToFill, setIsSurveyToFill] = useState(false);

  const [patientScreeningId, setPatientScreeningId] = useState(null);
  const [screeningId, setScreeningId] = useState(null);
  const [firstname, setFirstname] = useState("");

  const [surname, setSurname] = useState("");
  const [orgName, setOrgName] = useState("");
  const [label, setLabel] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [examinationPackages, setEexaminationPackage] = useState([]);

  const [fetchingSummary, setFetchingSummary] = useState(false);

  useEffect(() => {
    if (organization) {
      setOrgName(organization.orgName);
    }
  }, [organization]);

  useEffect(() => {
    if (location.state?.assignedPerson) {
      const { firstName, surname } = location.state?.assignedPerson;
      setFirstname(firstName);
      setSurname(surname);
      setAssignedPerson(location.state?.assignedPerson);
    }
  }, [location]);

  useEffect(() => {
    const fetchInfo = async () => {
      setFetchingSummary(true);
      const { tenantId, screeningId } = await dispatch(
        fetchVoucherInfo(voucherID)
      );

      setScreeningId(screeningId);

      const { label, startDate, endDate, examinationPackages } = await dispatch(
        fetchScreeningInfo(tenantId, screeningId)
      );
      setLabel(label);
      setStartDate(startDate);
      setEndDate(endDate);

      await dispatch(fetchOrganization(tenantId));

      for (var i = 0; i < examinationPackages.length; i++) {
        const { examinations, label, hearBoxTestId } = await dispatch(
          fetchPackageInfo(examinationPackages[i])
        );
        setEexaminationPackage((oldArray) => [
          ...oldArray,
          { examinations, label, hearBoxTestId },
        ]);
      }
      const assignedScreenings = await dispatch(fetchAssignedScreenings());
      const pScreeningId = assignedScreenings.find(
        (screening) =>
          screening.screeningId === screeningId &&
          screening.patientId === location.state?.assignedPerson.id
      );
      setPatientScreeningId(
        pScreeningId ? pScreeningId.patientScreeningId : null
      );

      setFetchingSummary(false);
    };

    if (voucherID) {
      fetchInfo();
    }
  }, [voucherID]);

  useEffect(() => {
    async function generateQRCode() {
      try {
        const response = await QRCode.toDataURL(
          JSON.stringify({
            id: qrID,
            pin: qrPIN,
          })
        );
        setQRCodeURL(response);
      } catch (e) {
        console.log(e);
      }
    }
    generateQRCode();
  }, [qrID, qrPIN]);

  useEffect(() => {
    (async () => {
      try {
        if (patientScreeningId) {
          const formsToFill = await dispatch(
            fetchFilledFormsInfo([
              {
                patientId: assignedPerson.id,
                patientScreeningId,
                screeningId,
                requiredExaminationPackages: examinationPackages.map(
                  (p) => p["hearBoxTestId"]
                ),
              },
            ])
          );
          setIsSurveyToFill(
            formsToFill.filter(({ examinationPackageFormsToFills }) =>
              examinationPackageFormsToFills.some((e) => e.formsToFill.length)
            ).length
          );
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [patientScreeningId]);

  const page = () =>
    fetchingSummary ? (
      <Box className={classes.root}>
        <Loader loading={true} text="Wczytywanie danych" />
      </Box>
    ) : (
      <>
        <Box className={classes.root}>
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <Grid container item xs={12} lg={10} xl={6}>
              <Paper className={classes.paper}>
                <Grid container item xs={12}>
                  <Grid item xs={12} md={6}>
                    <Box className={classes.paddingLarge}>
                      <Box>
                        <Typography className={classes.title}>
                          Twoje badanie
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Typography className={classes.subtitle}>
                          {`Badanie aktywowane dla: ${firstname} ${surname}`}
                        </Typography>
                      </Box>
                      <Box mt={3}>
                        {examinationPackages.map((p) =>
                          p.examinations.length < 4 ? (
                            <Box mt={1}>
                              {p.examinations.map((e, i) => (
                                <Typography className={classes.text}>
                                  {EXAMINATIONS_MAP[e]}
                                </Typography>
                              ))}
                            </Box>
                          ) : (
                            <List
                              className={classes.list}
                              sx={{ listStyleType: "disc" }}
                            >
                              {p.examinations.map((e, i) => (
                                <ListItem
                                  key={i}
                                  className={classes.listItem}
                                  sx={{ display: "list-item" }}
                                >
                                  <Typography className={classes.text}>
                                    {EXAMINATIONS_MAP[e]}
                                  </Typography>
                                </ListItem>
                              ))}
                            </List>
                          )
                        )}
                      </Box>
                      <Box mt={3}>
                        <Typography className={classes.text}>
                          Twoje badanie jest{" "}
                          <span className={classes.activeText}>aktywne</span>.
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Typography className={classes.text}>
                          Twoje badanie jest aktywne. Zachęcamy do wypełnienia
                          ankiety, która może być pomocna przy opisie badania.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      className={`${classes.paddingLarge} ${classes.backgroundGrey}`}
                    >
                      <Box mt={4}>
                        <Typography className={classes.text}>
                          Pamiętaj, abyś w momencie rozpoczęcia badania miał kod
                          QR przy sobie. Kod QR jest zapisany w Twoim profilu.
                        </Typography>
                      </Box>
                      <Box
                        mt={3}
                        className={classes.borderBottom}
                        style={{ textAlign: "center" }}
                      >
                        {" "}
                        {qrCodeURL && <img src={qrCodeURL} alt="qr-code" />}
                      </Box>
                      <Box mt={3} style={{ textAlign: "center" }}>
                        {isSurveyToFill ? (
                          <Link
                            to={{
                              pathname: location.state?.protegeAssigned
                                ? `/fill-survey/${screeningId}/${assignedPerson?.id}`
                                : `/fill-survey/${screeningId}`,
                              state: {
                                toSummary: true,
                                voucherID,
                                assignedPerson,
                                hearBoxTestIdArray: examinationPackages,
                                patientScreeningId,
                              },
                            }}
                          >
                            <Button
                              variant={"contained"}
                              style={{ marginRight: "5px" }}
                              className={classes.testButton}
                            >
                              ANKIETA
                            </Button>
                          </Link>
                        ) : null}
                        <Link to={"/profile"}>
                          {" "}
                          <Button
                            variant={"contained"}
                            className={classes.locationButton}
                          >
                            PROFIL
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </>
    );

  const redirect = () => <Redirect to="/login" />;
  return <Can permission="summary-assign:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryAssign);
