import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  makeStyles,
  IconButton,
  Collapse,
  Box,
  withStyles,
} from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment/moment';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function Row({ row, page }) {
  const { code, name, exceptionTs, instruction, reason } = row;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  useEffect(() => {
    // open && setOpen(false);
  }, [page]);

  let regex = /(\d+\.\d*)\s?(.*?)(?=\d+\.|$)/gs;
  let instToRender = instruction
    .match(regex)
    .map((i) => i.replace(/[0-9]./g, '*'));

  const dateToRender = moment(new Date(exceptionTs)).format('YYYY.MM.DD HH:mm');

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {dateToRender}
        </TableCell>
        <TableCell align="right">{code}</TableCell>
        <TableCell align="right">{name}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Przyczyna:</strong>
                    </TableCell>
                    <TableCell colSpan={2}>{reason}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ display: 'flex' }}>
                      <strong>Operacje do wykonania:</strong>
                    </TableCell>
                    <TableCell colSpan={2}>
                      {instToRender.map((i) => (
                        <Box>{i}</Box>
                      ))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
