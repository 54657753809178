import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { useStyles } from "./results-per-package-table";
import checkIcon from "../../assets/checked-icon2.svg";
import xIcon from "../../assets/x-icon.svg";
import xIconYellow from "../../assets/x-icon-yellow.svg";
import checkIconYellow from "../../assets/checked-icon-yellow2.svg";
import { useTheme } from "@material-ui/core/styles";
import { lightTheme } from "../../theme";

const inputToText = (answer) => {
  switch (answer) {
    case "gap":
      return "szum z przerwą";

    case "noGap":
      return "szum ciągły";

    case "low-low":
      return "niski - niski";

    case "high-high":
      return "wysoki - wysoki";

    case "high-low":
      return "wysoki - niski";

    case "low-high":
      return "niski - wysoki";

    default:
      return answer;
  }
};

const answerToText = (answer) => {
  switch (answer) {
    case "gap":
      return "szum z przerwą";

    case "noGap":
      return "szum ciągły";

    case "low-low":
      return "takie same";

    case "high-high":
      return "takie same";

    case "high-low":
      return "różne";

    case "low-high":
      return "różne";

    case "low":
      return "niski";

    case "high":
      return "wysoki";

    case "short":
      return "dźwięk krótki";

    case "long":
      return "dźwięk długi";

    default:
      return answer;
  }
};

const Answers = ({ attempt, index, training = false, withAnswer = true }) => {
  const classes = useStyles();
  const rightAnswers = attempt.listOfPlayedSounds[0].metadata.rightAnswers;
  const givenAnswers = attempt.givenAnswers;
  const isLightTheme =
    useTheme().palette.primary.main === lightTheme.palette.primary.main;

  return (
    <TableRow key={index}>
      <TableCell>{training ? `U${index + 1}` : index + 1}</TableCell>
      <TableCell className={classes.grey}>
        {rightAnswers.map(({ text }, index) => (
          <span key={index}>{inputToText(text)}</span>
        ))}
      </TableCell>
      {withAnswer && (
        <TableCell>
          {givenAnswers.map(({ text }, index) => (
            <span
              key={index}
              style={{
                color: isLightTheme
                  ? text === rightAnswers[index].text
                    ? "#2EAB8D"
                    : "#CF1616"
                  : "#ffff00",
              }}
            >
              {answerToText(text)}
            </span>
          ))}
        </TableCell>
      )}
      <TableCell>
        {givenAnswers.map(({ text }, index) =>
          text === rightAnswers[index].text ? (
            <img key={index} src={isLightTheme ? checkIcon : checkIconYellow} />
          ) : (
            <img key={index} src={isLightTheme ? xIcon : xIconYellow} />
          )
        )}
      </TableCell>
    </TableRow>
  );
};

export default Answers;
