import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import moment from "moment/moment";
import ColorStatus from "./status";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing("2"),
      marginRight: theme.spacing("2"),
    },
  },
  title: {
    fontWeight: "600",
  },
  statusIcon: {
    minHeight: "40px",
    minWidth: "40px",
  },
  alignRight: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "right",
    },
  },
}));

const Title = ({ title = "", date = "", status = null }) => {
  const classes = useStyles();

  const dateToRender = moment(new Date(date)).format("YYYY.MM.DD HH:mm");

  return (
    <Box className={classes.flexContainer}>
      <Typography className={classes.title}>{title}</Typography>
      {/* {status && <ColorStatus status={status} />} */}

      {date ? (
        <Box display={"flex"} alignItems={"flex-end"}>
          <ColorStatus status={status} />
          <Typography variant="subtitle2" className={classes.alignRight}>
            {`${
              status === "OFFLINE" ? `Ostatnia aktualizacja` : `Aktualizacja`
            }: ${dateToRender}`}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default Title;
