import React, { useState } from "react";
import { ClickAwayListener, Tooltip, Button } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  editionButton: {
    padding: "0px 16px",
    borderRadius: "20px",
    letterSpacing: "0.5px",
    background: theme.palette.lockIcon.primary,
    color: theme.palette.background.default,
    fontSize: theme.typography.footerMobile,
    fontWeight: "bold",
    "&:hover": {
      background: theme.palette.passwordIcon.primary,
    },
  },
}));

const styles = (theme) => ({
  tooltip: {
    borderRadius: "10px",
    opacity: "0.5",
    boxShadow: "0px 2px 5px #BEBEBE",
    backgroundColor: theme.palette.background.default,
    padding: "16px",
    fontSize: theme.typography.desc,
    fontFamily: "Raleway",
    color: theme.palette.text.hintText,
    maxWidth: "150px",
    textAlign: "center",
  },
});

const CustomTooltip = withStyles(styles)(Tooltip);

const testDescription = (type, forKids) => {
  var description = "";
  switch (type) {
    case "AP":
      description = "Badanie wykonano w obu uszach.";
      break;
    case "GC":
      description = forKids
        ? "Badanie wykonano w obu uszach. Wartości progów słyszenia wyznaczono na podstawie procedury wyznaczania progu słyszenia metodą Hughson Westlake`a."
        : "Badanie wykonano w obu uszach. Wartości progów słyszenia wyznaczono na podstawie wskazania przez pacjenta najmniejszego słyszalnego poziomu.";
      break;
    case "AS":
      description =
        "Badanie obejmowało słowa jednosylabowe. Wynik oznacza procent poprawnie rozpoznanych słów.";
      break;

    default:
      break;
  }
  return description;
};

const TestDescription = ({ type, forKids }) => {
  const classes = useStyles();
  const [openDescription, setOpenDescription] = useState(false);
  const handleTooltipClose = () => {
    setOpenDescription(false);
  };

  const handleTooltipOpen = () => {
    setOpenDescription(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <span>
        <CustomTooltip
          onClose={handleTooltipClose}
          open={openDescription}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={testDescription(type, forKids)}
        >
          <Button className={classes.editionButton} onClick={handleTooltipOpen}>
            opis
          </Button>
        </CustomTooltip>
      </span>
    </ClickAwayListener>
  );
};

export const testName = (type, forKids) => {
  var name = "";
  switch (type) {
    case "AP":
      name = forKids ? "Audiometria obrazkowa" : "Test tonowy";
      break;
    case "GC":
      name = forKids
        ? "Przesiewowy test progowy"
        : "Test progowy głośniej ciszej";
      break;
    case "AS":
      name = "Przesiewowa audiometria słowna";
      break;
    case "TDW":
      name = "Test Dyskryminacji Wysokości – TDW (PI)";
      break;
    case "TSW":
      name = "Test Sekwencji Wysokości – TSW (PPS, [FPT])";
      break;
    case "TSD":
      name = "Test Sekwencji Długości – TSD (DPS [DPT])";
      break;
    case "TPR":
      name = "Test Przerw Randomizowanych – TPR (RGDT [GDT])";
      break;
    case "TRC":
      name = "Test Rozdzielnouszny Cyfr – TRC (DD, [DDT])";
      break;
    case "TRS":
      name = "Test Rozdzielnouszny Słów – TRS (DWLT)";
      break;
    case "TRMS":
      name = "Test Rozumienia Mowy w Szumie – TRMS (SPN)";
      break;
    case "TMS":
      name = "Test Mowy Skompresowanej - TMS [CWT]";
      break;
    case "TMF":
      name = "Test Mowy Filtrowanej – TMF [FWT]";
      break;

    default:
      break;
  }
  return name;
};

export default TestDescription;
