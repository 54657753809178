import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  alertAdd,
  clearAllFormData,
  createFilledFormData,
  editFilledFormData,
  fetchAssignedScreenings,
  fetchFilledFormsInfo,
  fetchForm,
  fetchFormByScreeningId,
  fetchFormData,
  fetchForms,
  fetchFormTemplate,
  fetchScheduledForm,
  fetchVoucherInfo,
  removeDraft,
} from "../redux/actions";
import FormFiller from "../components/form-filler/form-filler";
import Can from "../components/can";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  MenuItem,
} from "@material-ui/core";
import PageTitle from "../components/page-title";
import Duration from "duration";
import Typography from "@material-ui/core/Typography";
import { uploadFormDataFile } from "../redux/organization/actions";
import Loader from "../components/loader";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { addMinutes } from "date-fns";
import FillFormPage from "./fill-form";
import { TextRotationAngledown } from "@material-ui/icons";
import { ReactComponent as InfoIcon } from "../assets/info.svg";
import { ReactComponent as InfoIconYellow } from "../assets/info-yellow.svg";
import { ReactComponent as ArrowDownIcon } from "../assets/arrowDownIcon.svg";
import { ReactComponent as ArrowDownIconYellow } from "../assets/arrowDownIcon-yellow.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "16px",
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
  },
  paper: {
    width: "100%",
    textAlign: "left",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  paperEmptyList: {
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "2px 6px 23px -10px rgba(66, 68, 90, 1)",
  },
  paddingLarge: {
    padding: "32px 64px 64px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingMedium: {
    padding: "16px 64px 8px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  aMain: {
    color: theme.palette.passwordIcon.primary,
    "&:hover": {
      color: theme.palette.passwordIcon.primary,
      textDecoration: "underline",
    },
    letterSpacing: "1px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
  },
  widthBox: {
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  selectText: {
    letterSpacing: "1px",
    lineHeight: `${theme.typography.formLabel}px`,
    fontSize: theme.typography.footer,
    color: theme.palette.text.hintText,
    borderBottom: `1px solid ${theme.palette.background.menuItemBorderBottom}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
}));

const FillSurveyPage = ({
  match,
  orgForms,
  fetchForm,
  fetchFormTemplate,
  formData,
  alertAdd,
  history,
  location,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";
  const me = useSelector((s) => s.my);

  const [hearBoxTestId, setHearBoxTestId] = useState(null);
  const [hearBoxTestToRender, setHearBoxTestToRender] = useState(null);

  const [formToFill, setFormToFill] = useState([]);
  const [valid, setValid] = useState([]);

  const [tenantId, setTenantId] = useState("");
  const [patientID, setPatientID] = useState("");
  const [screeningId, setScreeningId] = useState("");

  const [fetching, setFetching] = useState(false);

  const [fetchingData, setFetchingData] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const formsToFill = await dispatch(
          fetchFilledFormsInfo([
            {
              patientId: location.state?.assignedPerson
                ? location.state?.assignedPerson.id
                : match.params.patientID
                ? match.params.patientID
                : me.id,
              patientScreeningId: location.state?.patientScreeningId,
              screeningId: match.params.screeningId,
              requiredExaminationPackages: location.state?.hearBoxTestIdArray.map(
                (p) => p["hearBoxTestId"]
              ),
            },
          ])
        );

        setHearBoxTestToRender(
          location.state?.hearBoxTestIdArray.filter((e) => {
            return formsToFill.some((form) => {
              return form.examinationPackageFormsToFills.filter(
                (pck) =>
                  pck.hearBoxTestId === e.hearBoxTestId &&
                  pck.formsToFill.length
              ).length;
            });
          })
        );

        if (location?.state?.hearBoxTestIdArray?.length === 1) {
          setHearBoxTestId(location.state.hearBoxTestIdArray[0]);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (hearBoxTestId) {
        setFetching(true);

        const screeningId = match.params.screeningId;
        const patientID = match.params.patientID;

        let tenant = null;

        if (!location.state?.voucherID) {
          tenant = location.state?.tenantId;
        } else {
          const { tenantId } = await dispatch(
            fetchVoucherInfo(location.state?.voucherID)
          );
          tenant = tenantId;
        }

        setScreeningId(screeningId);
        setPatientID(patientID);
        setTenantId(tenant);

        if (patientID) {
          const forms = await dispatch(
            fetchFormByScreeningId(
              screeningId,
              patientID,
              hearBoxTestId.hearBoxTestId
            )
          );
          if (forms?.length > 0) {
            setFormToFill(forms.filter(({ mandatory }) => !mandatory));
          }
        } else {
          const forms = await dispatch(
            fetchFormByScreeningId(
              screeningId,
              null,
              hearBoxTestId.hearBoxTestId
            )
          );

          if (forms?.length > 0) {
            setFormToFill(forms.filter(({ mandatory }) => !mandatory));
          }
        }
      }
      setFetching(false);
      setFetchingData(false);
    })();
  }, [hearBoxTestId]);

  const page = () =>
    fetchingData ? (
      <Box p={2}>
        <Loader
          loading={true}
          text="Pobieranie danych - proszę czekać"
        ></Loader>
      </Box>
    ) : (
      <Box p={2}>
        {formToFill.length > 0 ? (
          <Box className={classes.root}>
            <Grid
              container
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid container item xs={12} lg={10} xl={8}>
                <Paper className={classes.paper}>
                  {" "}
                  <Box className={`${classes.paddingMedium}`}>
                    {location.state?.hearBoxTestIdArray.length !== 0 && (
                      <Grid container style={{ width: "100%" }}>
                        <Grid item xs={12} md={10} lg={6}>
                          <FormControl fullWidth>
                            <InputLabel>Wybierz pakiet</InputLabel>
                            <Select
                              // className={classes.select}
                              labelId="select-outlined-label"
                              id="select-outlined"
                              value={hearBoxTestId}
                              className={classes.select}
                              classes={{
                                root: classes.color,
                              }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                              // fullWidth
                              onChange={(event) => {
                                setHearBoxTestId(event.target.value);
                              }}
                              IconComponent={(props) => (
                                <i
                                  {...props}
                                  className={`material-icons ${props.className}`}
                                  style={{ top: "calc(50% - 20px)" }}
                                >
                                  {isLightGlobalTheme ? (
                                    <ArrowDownIcon />
                                  ) : (
                                    <ArrowDownIconYellow />
                                  )}
                                </i>
                              )}
                              label=""
                            >
                              {location.state?.hearBoxTestIdArray &&
                                location.state?.hearBoxTestIdArray.map(
                                  (hearBoxTest) => {
                                    return (
                                      <MenuItem
                                        key={hearBoxTest.id}
                                        value={hearBoxTest}
                                        className={classes.selectText}
                                        style={{ whiteSpace: "normal" }}
                                      >
                                        {`Ankieta dla ${hearBoxTest.label}`}
                                      </MenuItem>
                                    );
                                  }
                                )}
                            </Select>
                          </FormControl>{" "}
                        </Grid>{" "}
                      </Grid>
                    )}
                  </Box>
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Box className={classes.paddingLarge}>
                        {formToFill.map((f) => (
                          <FillFormPage
                            key={f.formId}
                            formId={f.formId}
                            mandatory={f.mandatory}
                            setValidAssignButton={setValid}
                            screeningId={screeningId}
                            tenantId={tenantId}
                            meToAssign={me.id === patientID || !patientID}
                            patientID={patientID}
                            urlToNavigate={
                              location.state?.toSummary
                                ? `/summary-assign/${location.state?.voucherID}`
                                : location.state?.toActiveTests
                                ? "/active-tests"
                                : patientID
                                ? `/results/${patientID}`
                                : "/results"
                            }
                            assignedPerson={location.state?.assignedPerson}
                            hearBoxScreeningId={
                              location.state?.hearBoxScreeningId
                            }
                            hearBoxTestId={hearBoxTestId.hearBoxTestId}
                            patientScreeningId={
                              location.state?.patientScreeningId
                            }
                            setFetching={setFetching}
                            fetching={fetching}
                          />
                        ))}
                        <Link
                          className={classes.aMain}
                          to={{
                            pathname: location.state?.toSummary
                              ? `/summary-assign/${location.state?.voucherID}`
                              : location.state?.toActiveTests
                              ? "/active-tests"
                              : patientID
                              ? `/results/${patientID}`
                              : "/results",
                            state: {
                              assignedPerson: location.state?.assignedPerson,
                            },
                          }}
                        >
                          Powrót
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box className={classes.root}>
            <Grid
              container
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={12} md={10} lg={8} xl={6}>
                <Paper className={classes.paperEmptyList}>
                  {" "}
                  <Box className={`${classes.paddingMedium}`}>
                    {" "}
                    {hearBoxTestToRender?.length !== 0 && (
                      <Grid container style={{ width: "100%" }}>
                        <Grid item xs={12} md={10} lg={6}>
                          <FormControl fullWidth>
                            <InputLabel>Wybierz pakiet</InputLabel>
                            <Select
                              // className={classes.select}
                              labelId="select-outlined-label"
                              id="select-outlined"
                              value={hearBoxTestId}
                              className={classes.select}
                              classes={{
                                root: classes.color,
                              }}
                              // fullWidth
                              onChange={(event) => {
                                setHearBoxTestId(event.target.value);
                              }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                              IconComponent={(props) => (
                                <i
                                  {...props}
                                  className={`material-icons ${props.className}`}
                                  style={{ top: "calc(50% - 20px)" }}
                                >
                                  {isLightGlobalTheme ? (
                                    <ArrowDownIcon />
                                  ) : (
                                    <ArrowDownIconYellow />
                                  )}
                                </i>
                              )}
                              label=""
                            >
                              {hearBoxTestToRender &&
                                hearBoxTestToRender.map((hearBoxTest) => {
                                  return (
                                    <MenuItem
                                      key={hearBoxTest.id}
                                      value={hearBoxTest}
                                      className={classes.selectText}
                                      style={{ whiteSpace: "normal" }}
                                    >
                                      {`Ankieta dla ${hearBoxTest.label}`}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>{" "}
                        </Grid>{" "}
                      </Grid>
                    )}
                  </Box>
                  <Box p={3}>
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                      <Box>
                        {isLightGlobalTheme ? (
                          <InfoIcon />
                        ) : (
                          <InfoIconYellow style={{ cursor: "pointer" }} />
                        )}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-evenly"
                        alignItems="center"
                        className={classes.widthBox}
                      >
                        <Typography className={classes.loginTitle}>
                          {hearBoxTestId && !fetching
                            ? `Brak przypisanych ankiet`
                            : `Proszę wybrać pakiet z listy`}
                        </Typography>
                      </Box>
                    </Box>

                    <Link
                      className={classes.aMain}
                      to={{
                        pathname: location.state?.toSummary
                          ? `/summary-assign/${location.state?.voucherID}`
                          : location.state?.toActiveTests
                          ? "/active-tests"
                          : patientID
                          ? `/results/${patientID}`
                          : "/results",
                        state: {
                          assignedPerson: location.state?.assignedPerson,
                        },
                      }}
                    >
                      Powrót
                    </Link>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    );

  const noAccess = () => <div>Brak dostępu</div>;

  return <Can permission="form-fill:page" ok={page} not={noAccess} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FillSurveyPage);
