import React, { useEffect, useState } from "react";
import { normalizeZIP } from "./shipping-info";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { CssTextField } from "./shipping-info";

const useStyles = makeStyles((theme) => ({
  greenBorder: {
    borderColor: theme.palette.success.dark,
  },
}));

const checkZipCode = (zipCode) => zipCode && /^\d{2}-\d{3}$/.test(zipCode);

const ZipInput = ({
  zipCode,
  setZipCode,
  isValidListener,
  disabled,
  setDataChanged,
}) => {
  const classes = useStyles();

  const [valid, setValid] = useState(checkZipCode(zipCode));
  const [isPostalCodeValid, setIsPostalCodeValid] = useState(true);
  useEffect(() => {
    let isOk;
    if (zipCode && /^\d{2}-\d{3}$/.test(zipCode)) {
      isOk = true;
    } else {
      isOk = false;
    }

    setValid(isOk);

    isValidListener && isValidListener(isOk);
  }, [zipCode]);

  return (
    <CssTextField
      placeholder="* Kod pocztowy"
      value={zipCode || ""}
      disabled={disabled}
      required
      fullWidth
      onChange={({ target: { value: newZipCode } }) => {
        setDataChanged(true);
        setZipCode(normalizeZIP(newZipCode, zipCode));
        let postalCode = "";
        newZipCode.length > 6
          ? (postalCode = newZipCode.substring(0, 6))
          : (postalCode = newZipCode);
        let isOk;
        if (
          (postalCode && /^\d{2}-\d{3}$/.test(postalCode)) ||
          postalCode === "" ||
          postalCode === undefined
        ) {
          isOk = true;
        } else {
          isOk = false;
        }

        setIsPostalCodeValid(isOk);
      }}
      error={!isPostalCodeValid}
      // error={!isPostalCodeValid || !zipCode}
      helperText={!isPostalCodeValid && "Nieprawidłowy format."}
    />
  );
};

export default ZipInput;
