import React, { useEffect } from "react";
import {
  Button,
  ButtonGroup,
  makeStyles,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { format } from "date-fns";
import { EXAMINATIONS_MAP } from "./examinations";
import { getAxiosInstance } from "../../redux/common";
import { useDispatch } from "react-redux";
import { alertAdd } from "../../redux/actions";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";

const useStyles = makeStyles((theme) => ({
  spacing: {
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
    boxShadow: "none",
  },
}));

const PackageRow = ({
  singlePackage,
  setOpenPackageModal,
  setPackageToEdit,
  setPackageHandlerType,
  setPackagePriceListToEdit,
  setOpenPriceListPanel,
  missingPricelist,
}) => {
  const {
    label,
    hearBoxTestId,
    examinations,
    description,
    allowDoctorDescription,
  } = singlePackage;

  const dispatch = useDispatch();
  const classes = useStyles();

  const orgEditionHandler = async () => {
    const response = await getAxiosInstance().get(
      `/api/examination_package/${hearBoxTestId}/is_used`
    );
    const packageIsActive = response.data;
    if (packageIsActive) {
      dispatch(
        alertAdd({
          text: "Pakiet jest przypisany do aktywnego przesiewu",
          isWarning: true,
        })
      );
    } else {
      setPackageHandlerType("edition");
      setPackageToEdit(singlePackage);
      setOpenPackageModal(true);
    }
  };

  const isMissingPrice = missingPricelist.find(
    (missingPrice) => missingPrice.hearBoxTestId === hearBoxTestId
  );

  return (
    <TableRow>
      <TableCell>{label || "---"}</TableCell>
      <TableCell>{hearBoxTestId || "---"}</TableCell>
      <TableCell>
        {examinations.map((examination) => (
          <p
            style={{
              borderBottom: examinations.length > 1 && "0.5px solid #d9d9d9",
            }}
          >
            {EXAMINATIONS_MAP[examination]}
          </p>
        )) || "---"}
      </TableCell>
      <TableCell>{description || "---"}</TableCell>

      <TableCell>
        {allowDoctorDescription ? (
          <CheckCircleIcon style={{ fill: "#008000" }} />
        ) : (
          <RemoveCircleIcon style={{ fill: "#ff0000" }} />
        )}
      </TableCell>
      <TableCell
      // style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <ButtonGroup
          className={classes.spacing}
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          <Button
            variant={"contained"}
            color="primary"
            onClick={orgEditionHandler}
          >
            Edycja
          </Button>
          {allowDoctorDescription ? (
            <Button
              variant={"contained"}
              color="primary"
              onClick={() => {
                // setScreeningTestToEdit(screeningTestData);
                setPackagePriceListToEdit(singlePackage);
                setOpenPriceListPanel(true);
              }}
            >
              Cennik
            </Button>
          ) : null}
          {isMissingPrice && allowDoctorDescription && (
            <Tooltip title="Należy uzupełnic braki w cenniku">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <PriorityHighIcon style={{ fill: "red" }} />
              </div>
            </Tooltip>
          )}
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
};

export default PackageRow;
