import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink, Redirect, Link } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { useSelector } from "react-redux";
import Can from "../../components/can";
import Loader from "../../components/loader";
import {
  alertAdd,
  login,
  toggleGlobalTheme,
  setFontSize,
  fetchCookiesAccepted,
} from "../../redux/actions";
import Cookies from "js-cookie";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MuiIconButton from "@material-ui/core/IconButton";
import { LockOpen, VisibilityOutlined } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { Box, Paper, useMediaQuery, useTheme } from "@material-ui/core";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Tooltip } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { getAxiosInstance } from "../../redux/common";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import ApiVersionSpan from "../../components/api-version-span/api-version-span";
import UnsupportedBrowserAlert from "../../components/unsupported-browser-alert";
import ContrastIcon from "@material-ui/icons/BrightnessMedium";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import BoxImage from "../../assets/box.svg";
import { ReactComponent as HBLogo } from "../../assets/hb-tmp.svg";
import StopIcon from "@material-ui/icons/Stop";

const IconButton = withStyles({
  root: {
    padding: "4px",
    marginTop: "5px",
    height: "25px",
    borderRadius: "0%",
    marginRight: "10px",
  },
})(MuiIconButton);

// const MuiNavLink = withStyles((theme) => ({
//   root: {
//     color: theme.palette.anchor.primary,
//     "&:hover": {
//       color: theme.palette.text.primary,
//       textDecoration: "underline",
//     },
//   },
// }))(Link);

const useStyles = makeStyles((theme) => ({
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  formHint: {
    fontSize: theme.typography.hintText || 12,
    color: theme.palette.text.primary2,
    letterSpacing: "0.7px",
  },
  formHint1: {
    // fontSize: theme.typography.hintText || 12,
    color: theme.palette.text.item,
    marginRight: "5px",
    letterSpacing: "0.7px",
  },
  iconHeader: {
    color: theme.palette.text.primary,
    padding: "5px",
  },
  icon: {
    color: theme.palette.text.primary,
  },
  iconSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.iconSize,
  },
  paperContainer: {
    // backgroundImage: `url(${BoxImage})`,
    borderRadius: "0px",
    boxShadow: "13px 18px 29px -9px rgba(82, 82, 92, 1)",
    // padding: "32px 64px",
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingMedium: {
    padding: "12px 64px 64px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingSmall: {
    padding: "12px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  selectedIconHeader: {
    border: `1px solid ${theme.palette.text.primary}`,
    color: theme.palette.background.default,
    backgroundColor: theme.palette.text.primary,
    "&:hover": {
      border: `1px solid ${theme.palette.background.default}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
    },
    padding: "5px",
  },
  iconColor: {
    color: theme.palette.passwordIcon.primary,
  },
  a: {
    color: theme.palette.text.blueHeaderYellow,
    "&:hover": {
      color: theme.palette.text.blueHeaderYellow,
      textDecoration: "underline",
    },
    letterSpacing: "1px",
    fontSize: theme.typography.hintText,
  },
  aMain: {
    color: theme.palette.passwordIcon.primary,
    "&:hover": {
      color: theme.palette.passwordIcon.primary,
      textDecoration: "underline",
    },
    letterSpacing: "1px",
    fontWeight: "600",
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
  },
  boxLoginTitle: {
    borderTop: "1px solid #AFAEAE",
    background: theme.palette.background.loginBackground,
  },
  aUnderline: {
    textDecoration: "underline",
  },
}));

const LoginPage = ({
  login,
  history,
  alertAdd,
  fetchCookiesAccepted,
  loggingIn,
  loggedIn,
  unsupportedBrowser,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userLogin, setUserLogin] = useState("");
  const [invalidUserLogin, setInvalidUserLogin] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [preventLogin, setPreventLogin] = useState(false);

  const [loginAttemptCounter, setLoginAttemptCounter] = useState(0);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [loginAttemptsLeft, setLoginAttemptsLeft] = useState(0);

  const rememberMeLogin = Cookies.get("rememberMeLogin");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [isRegisterButtonVisible, setIsRegisterButtonVisible] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const [globalTosAccepted, setGlobalTosAccepted] = useState(false);
  const config = useSelector((s) => s.config);
  const cartItems = useSelector((s) => s.shoppingCartProducts);

  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const theme = useTheme();

  const handleInstructionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (loggedIn) {
      const firstUrl = localStorage.firstUrl;
      firstUrl?.startsWith("/formdata/fill-scheduled-test")
        ? history.push(firstUrl)
        : Cookies.get("code")
        ? history.push(`/qr-code-validation/${Cookies.get("code")}`)
        : history.push("/");
    } else {
      setUserLogin(rememberMeLogin);
    }
  }, [loggedIn]);

  useEffect(() => {
    let valid = userLogin && password;
    let userLoginValid = true;
    if (userLogin) {
      const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(userLogin).toLowerCase()
      );

      const phoneValid = checkIfPhoneIsValid(userLogin);

      userLoginValid = emailValid || phoneValid;
      valid = valid && userLoginValid;
    }
    setPreventLogin(!valid);
    setInvalidUserLogin(!userLoginValid);
  }, [userLogin, password, globalTosAccepted]);

  useEffect(() => {
    getAxiosInstance()
      .get("/api/config")
      .then((result) => {
        setIsRegisterButtonVisible(result.data.hasAnyJoinableOrganization);
        setIsReady(true);
      });
    fetchCookiesAccepted();
  }, []);

  const doLogin = async (e) => {
    e.preventDefault();

    let response = {};

    let isEmailProvided = false;
    let isPhoneProvided = false;

    const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(userLogin).toLowerCase()
    );

    const phoneValid = checkIfPhoneIsValid(userLogin);
    let validPhoneNumber = "";

    if (emailValid) {
      isEmailProvided = true;
    } else if (phoneValid) {
      switch (userLogin.length) {
        case 9:
          validPhoneNumber = `+48${userLogin}`;
          break;
        case 11:
          validPhoneNumber = `+${userLogin}`;
          break;
        case 12:
          validPhoneNumber = userLogin;
          break;
      }
      isPhoneProvided = true;
    }

    response = await login(
      {
        ...(isEmailProvided ? { username: userLogin } : {}),
        ...(isPhoneProvided ? { phone: validPhoneNumber } : {}),
        password,
        ...(config.requireGlobalTosAcceptance
          ? {
              globalTosAccepted,
            }
          : {}),
      },
      "patient"
    );

    const message = response.data.message;
    const attemptsLeft =
      /^\d+$/.test(response.data.message) && response.data.message;

    if (response.status === 200) {
      Cookies.get("code")
        ? history.push(`/qr-code-validation/${Cookies.get("code")}`)
        : cartItems?.length > 0
        ? history.push("/test-kit/add")
        : history.push("/");
    } else if (message === "not activated") {
      alertAdd({
        text: "Użytkownik nie został aktywowany",
        isError: true,
      });
    } else if (message === "global-tos-not-accepted") {
      alertAdd({
        text: "Wymagane zaakceptowanie regulaminu serwisu",
        isError: true,
      });
    } else if (message === "blocked") {
      setDisplayAlert(false);
      alertAdd({
        text: "Konto zostało czasowo zablokowane",
        isError: true,
      });
    } else if (attemptsLeft < 4 && /^\d+$/.test(attemptsLeft)) {
      setDisplayAlert(true);
      setLoginAttemptsLeft(attemptsLeft);
      // alertAdd({
      //   text: (
      //     <span>
      //       Spróbuj poprawnie wpisać hasło lub{" "}
      //       <a>
      //         <strong>Ustaw nowe hasło</strong>
      //       </a>
      //       pozostało prób przed zablokowaniem konta:{" "}
      //       <strong>{attemptsLeft}</strong>
      //     </span>
      //   ),
      //   isError: true,
      // });
    } else {
      if (invalidUserLogin) {
        alertAdd({
          text: "Nieprawidłowy format loginu",
          isError: true,
        });
      } else {
        setLoginAttemptCounter(loginAttemptCounter + 1);
        alertAdd({
          text: (
            <span>
              Spróbuj poprawnie wpisać hasło lub{" "}
              <a href="/password-reset">
                <Link
                  className={`${classes.a} ${classes.aUnderline}`}
                  to="/password-reset"
                >
                  Ustaw nowe hasło
                </Link>
              </a>
            </span>
          ),
          isError: true,
          ...(loginAttemptCounter > 1 && { timeout: 7000 }),
        });
      }
    }
  };

  const cancel = (e) => {
    e.preventDefault();

    history.push("/");
  };

  const checkIfPhoneIsValid = (phone) => {
    return /^(\d{9}|\+48\d{9}|48\d{9})$/.test(phone);
  };

  return (
    <Can
      permission="login:view"
      ok={() => {
        return !isReady ? (
          // <Box style={{ height: "100vh" }}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Grid item xs={12}>
              <Loader loading={true} text="Ładowanie..." />
            </Grid>
          </Grid>
        ) : (
          // </Box>
          <>
            <UnsupportedBrowserAlert />
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
                <Box p={1}>
                  {displayAlert && (
                    <Box textAlign="center">
                      <Alert severity="error">
                        <span>
                          Spróbuj poprawnie wpisać hasło lub{" "}
                          <Link className={classes.a} to="/password-reset">
                            Ustaw nowe hasło
                          </Link>
                        </span>
                        <br />
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          Pozostało prób przed zablokowaniem konta:{" "}
                          <strong>{loginAttemptsLeft}</strong>
                        </span>
                      </Alert>
                    </Box>
                  )}

                  {/* {Cookies.get("code") */}

                  <Paper
                    className={classes.paperContainer}
                    style={{
                      marginTop:
                        unsupportedBrowser && mediumScreen ? "90px" : "",
                    }}
                  >
                    <Box>
                      <form onSubmit={doLogin} noValidate autoComplete="off">
                        <Box
                          display="flex"
                          justifyContent="center"
                          className={classes.paddingLarge}
                        >
                          <HBLogo />
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-evenly"
                          alignItems="center"
                          className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
                        >
                          <StopIcon
                            style={{ height: "8px", color: "#3458A4" }}
                          />
                          <Typography className={classes.loginTitle}>
                            ZALOGUJ SIĘ
                          </Typography>
                          <StopIcon
                            style={{ height: "8px", color: "#23EAB6" }}
                          />
                        </Box>
                        <Box className={classes.paddingSmall}>
                          <Box display="flex">
                            <TextField
                              // required
                              error={invalidUserLogin}
                              helperText={
                                invalidUserLogin
                                  ? "Nieprawidłowy format wprowadzonego loginu"
                                  : ""
                              }
                              label="* E-mail"
                              // variant="outlined"
                              value={userLogin || ""}
                              fullWidth
                              onChange={(e) => setUserLogin(e.target.value)}
                            />
                          </Box>
                          <Box mt={1}>
                            <TextField
                              label="* Hasło"
                              type={showPassword ? "text" : "password"}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              fullWidth
                              InputProps={{
                                // className: classes.input,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      aria-label="toggle password visibility"
                                    >
                                      <VisibilityOutlined
                                        className={classes.iconColor}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>

                          {config.requireGlobalTosAcceptance ? (
                            <Box className={classes.padding}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={globalTosAccepted}
                                    onChange={({ target: { checked } }) =>
                                      setGlobalTosAccepted(checked)
                                    }
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                                label={
                                  <Typography variant="body1">
                                    Akceptuję{" "}
                                    <a
                                      href="/org-media/files/public/regulamin.pdf"
                                      target="_blank"
                                    >
                                      regulamin
                                    </a>{" "}
                                    serwisu
                                  </Typography>
                                }
                              />
                            </Box>
                          ) : null}

                          <Box
                            m={1}
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <span className={classes.formHint}>
                              {"* Pola wymagane"}
                            </span>
                            {/* <Box textAlign="right" fontSize={12} m={1}> */}
                            <Link className={classes.a} to="/password-reset">
                              Zapomniałeś hasła?
                            </Link>
                            {/* </div></Box> */}
                          </Box>
                        </Box>
                        <br />
                        <Box className={classes.paddingMedium}>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              type="submit"
                              variant={"contained"}
                              color="primary"
                              disabled={preventLogin}
                              style={{
                                borderRadius: "0px",
                                height: "50px",
                                width: "215px",
                              }}
                            >
                              Zaloguj się
                            </Button>
                          </Box>
                          <Box
                            mt={2}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            <span className={classes.formHint1}>
                              {"Nie masz konta?  "}
                            </span>
                            {/* <Box textAlign="right" fontSize={12} m={1}> */}
                            <Link className={classes.aMain} to="/register">
                              Zarejestruj się
                            </Link>
                          </Box>
                        </Box>

                        <Loader loading={loggingIn} text="Logowanie..." />
                        {/* <hr
                          style={{
                            // margin: "0 0 8px 0",
                            backgroundColor: theme.palette.hr.primary,
                          }}
                        /> */}
                        {isRegisterButtonVisible && (
                          <div>
                            Nie masz konta?{" "}
                            <NavLink to="/register">
                              <Button
                                variant={"contained"}
                                color="primary"
                                size="small"
                              >
                                Dołącz do organizacji
                              </Button>
                            </NavLink>
                          </div>
                        )}
                      </form>
                      {/* <MuiNavLink
                        href="/org-media/files/public/regulamin.pdf"
                        target="_blank"
                      >
                        Regulamin
                      </MuiNavLink>{" "} */}
                    </Box>
                  </Paper>
                </Box>

                {/* <Grid
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "16px",
                  }}
                >
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="primary"
                    variant="contained"
                    onClick={handleInstructionClick}
                  >
                    INSTRUKCJA
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        window.open(
                          "/org-media/files/public/instrukcja_uzytkownika.pdf",
                          "_blank"
                        );
                      }}
                    >
                      Instrukcja dla{" "}
                      {process.env.REACT_APP_USE_PATIENT === "true"
                        ? "pacjenta"
                        : "użytkownika"}{" "}
                      PDF
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        window.open(
                          "/org-media/files/public/zrodlo.mp4",
                          "_blank"
                        );
                      }}
                    >
                      Film instruktażowy dla{" "}
                      {process.env.REACT_APP_USE_PATIENT === "true"
                        ? "pacjenta"
                        : "użytkownika"}
                    </MenuItem>
                  </Menu>
                </Grid> */}
              </Grid>
              {/* <ApiVersionSpan /> */}
            </Grid>
          </>
        );
      }}
      not={() => {
        return <Redirect to="/" />;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  loggingIn: state.loggingIn,
  loggedIn: state.loggedIn,
  activeForms: state.activeForms,
  my: state.my,
  unsupportedBrowser: state.unsupportedBrowser,
});

const mapDispatchToProps = (dispatch) => ({
  login: (data, loginType) => dispatch(login(data, loginType)),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
  fetchCookiesAccepted: () => dispatch(fetchCookiesAccepted()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
