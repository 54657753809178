import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import * as classnames from "classnames";
import s from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "@material-ui/core/styles/withStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";

// dragaMode = either 'clone' or 'move'

const useStyles = makeStyles((theme) => ({
  muiTextarea: {
    background: "white",
  },
  isDanger: {
    borderColor: "#ff3860",
  },
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  fontSize1: {
    fontSize: theme.typography.formLabel,
  },
  hintText: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.hintText,
    lineHeight: 1.4,
    margin: "-5px auto 5px",
    color: theme.palette.text.primary2,
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.formLabel,
  },
  resize: {
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.footerMobile,
    },
  },
  resizeLabel: {
    fontSize: theme.typography.redirectCardTitle,
    // [theme.breakpoints.down("sm")]: {
    // fontSize: theme.typography.footerMobile,
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "hidden",
    // maxWidth: "120%",
    // height: "100%",
    // },
  },
}));

const NumberControl = ({
  name,
  id,
  disabled,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  showSelfDescription,
  sidebarMode,
  formCreatorMode,
  removable,
  onRemove,
  rowIndex,
  index,
  setIsDragging,
  filledValue,
  filledValuesDetails,
  alertDisplaying,
  textColorFilling,
  blankedRequiredFields,
  checkBlankedFields,
  displayMode,
}) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.NUMBER,
      dragMode,
      defaultLabel: "Liczba",
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });

  const classes = useStyles();
  const [value, setValue] = useState(filledValue || "");
  const globalTheme = useSelector((s) => s.globalTheme);

  const [filledValueDetails, setFilledValueDetails] = useState(
    (filledValuesDetails && filledValuesDetails.find((v) => v.id === id)) ||
      null
  );
  const isJustUser = useSelector(
    (s) => s?.my?.user?.authorities === "ROLE_USER" || false
  );

  const setValueWithNumberTypeContext = (stringValue) => {
    const isInteger = configuration.numberType === "integer";

    setValue(stringValue);

    if (onChange) {
      try {
        if (isInteger) {
          onChange({ id, value: parseInt(stringValue, 10) });
        } else {
          onChange({ id, value: parseFloat(stringValue.replace(",", ".")) });
        }
      } catch {
        // console.log("catch...");
      }
    }
  };

  useEffect(() => {
    setValueWithNumberTypeContext(filledValue);
  }, [filledValue]);

  const clicked = () => {
    markSelected &&
      markSelected({
        id,
        dataType: ItemSubtypes.NUMBER,
        configuration,
      });
  };

  const updateValue = (tmpValue) => {
    const isInteger = configuration.numberType === "integer";
    const digits = configuration.decimalDigitNumber;

    const correctPattern = isInteger
      ? new RegExp("(^\\d+$)|^$")
      : new RegExp(
          `(^\\d+$)|(^\\d+[,\\.]$)|(^\\d+[,\\.]\\d{0,${digits}}$)|(^[,\\.]\\d{0,${digits}}$)|^$|^[,\\.]$`
        );

    const correct = correctPattern.test(tmpValue);
    if (tmpValue === "," || tmpValue === ".") {
      tmpValue = "0" + tmpValue;
    }

    if (correct) {
      setValueWithNumberTypeContext(tmpValue);
    }
  };

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Liczba"
        />
      </div>
    );
  }

  const placeholder = showSelfDescription
    ? "Liczba"
    : (configuration && configuration.text) || "";
  const label = (configuration && configuration.label) || "";

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          {!displayMode && (
            <FontAwesomeIcon
              className={classnames(s.trashIcon, "has-text-grey")}
              onClick={onRemove}
              icon="trash"
              title="Usuń"
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div ref={drag}>
      {/* {label && (
        <label className={`label ${classes.label}`}>
          {label}
          {configuration.required && " *"}
        </label>
      )} */}
      {!label && (
        <label
          dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
          className={classnames("label")}
        ></label>
      )}{" "}
      {disabled && !isJustUser && !value ? (
        <div className={`${classes.hintText}`}>
          Pole niewypełnione przez{" "}
          {process.env.REACT_APP_USE_PATIENT === "true"
            ? "pacjenta"
            : "użytkownika"}
        </div>
      ) : (
        <>
          {label && (
            <div
              className={`${classes.fontSize1}`}
              style={{
                marginBottom: "0px",
                color: globalTheme === "high-contrast" && "#fff200",
                textAlign: "left",
              }}
            >
              {label}
              {configuration.required && " *"}
            </div>
          )}
          <TextField
            fullWidth
            // variant="outlined"
            size="small"
            // label={label || ""}
            disabled={disabled}
            error={
              !!(
                checkBlankedFields &&
                blankedRequiredFields.find((fieldId) => fieldId === id)
              ) ||
              !!(
                value &&
                ((configuration.maxValue &&
                  configuration.maxValue <
                    value?.toString().replace(/,/g, ".")) ||
                  (configuration.minValue &&
                    configuration.minValue >
                      value?.toString().replace(/,/g, ".")))
              )
            }
            helperText={
              (value &&
                ((configuration.maxValue &&
                  configuration.maxValue <
                    value?.toString().replace(/,/g, ".")) ||
                  (configuration.minValue &&
                    configuration.minValue >
                      value?.toString().replace(/,/g, "."))) &&
                "Nieprawidłowa wartość") ||
              (checkBlankedFields &&
                blankedRequiredFields.find((fieldId) => fieldId === id) &&
                `To pole musi zostać wypełnione`)
            }
            placeholder={placeholder}
            id={id}
            onClick={clicked}
            onChange={({ target: { value } }) => {
              updateValue(value.toString().replace(/\./g, ","));
            }}
            value={value || ""}
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.resizeLabel,
              },
            }}
            multiline
            style={{ textAlign: "left", paddingTop: "10px" }}
          />
        </>
      )}
      {disabled && filledValueDetails && alertDisplaying && (
        <div>
          {" "}
          <label
            className={s.text}
            style={{ color: filledValueDetails.color || "" }}
          >
            {filledValueDetails.text ? filledValueDetails.text : ""}
          </label>
        </div>
      )}
      {filledValueDetails &&
        alertDisplaying &&
        filledValueDetails.weight &&
        !isJustUser && (
          <div>
            <p>
              Liczba uzyskanych punktów za odpowiedź:
              <span style={{ color: filledValueDetails.color || "" }}>
                {" "}
                {filledValueDetails.weight}
              </span>{" "}
              pkt
            </p>
          </div>
        )}
      {!(disabled && !isJustUser && !value) && (
        <>
          <label className={`${classes.hintText}`}>
            {(configuration.minValue || configuration.minValue === 0) &&
              `Minimalna wartość: ${configuration.minValue
                .toString()
                .replace(/\./g, ",")}`}
          </label>
          &nbsp;&nbsp;&nbsp;
          <label className={`${classes.hintText}`}>
            {(configuration.maxValue || configuration.maxValue === 0) &&
              `Maksymalna wartość: ${configuration.maxValue
                .toString()
                .replace(/\./g, ",")} `}
          </label>
        </>
      )}
    </div>
  );
};

export default NumberControl;
