import { Box, Button, Grid, InputLabel, Typography } from "@material-ui/core";
// import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import s from "./index.module.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.primary2,
  },
  button: {
    background: theme.palette.background.qrPanelButton,
    borderRadius: "20px",
    color: theme.palette.background.default,
    height: "40px",
    width: "180px",
    fontSize: theme.typography.hintText,
  },
  cancelButton: {
    // background: theme.palette.background.qrPanelButton,
    // borderRadius: "20px",
    // color: theme.palette.background.default,
    // height: "40px",
    // width: "180px",
    marginLeft: "5px",
    fontSize: theme.typography.hintText,
  },
}));

const AvatarUpload = ({
  avatarPath,
  newAvatarPath,
  enableProfileDataEdition,
  setDataChanged,
  handleUploadedFiles,
  removeAvatar,
  deletingAvatar,
}) => {
  const classes = useStyles();
  // const classes = useStyles();

  return (
    <Box>
      <InputLabel className={classes.label}>
        Aktualne zdjęcie profilowe:
      </InputLabel>
      <Box style={{ margin: "10px" }}>
        {avatarPath ? (
          <img
            className={s.avatarImg}
            src={`/org-media/files${avatarPath}`}
            alt="aktualne zdjęcie profilowe"
          />
        ) : (
          <span style={{ fontWeight: "bold" }}>Brak</span>
        )}
      </Box>

      {newAvatarPath && (
        <Grid item xs={12}>
          <InputLabel className={classes.label}>
            Nowe zdjęcie profilowe:{" "}
          </InputLabel>
          <Box style={{ margin: "10px" }}>
            <img
              className={s.avatarImg}
              src={newAvatarPath}
              alt="nowe zdjęcie profilowe"
            />
          </Box>
        </Grid>
      )}
      {deletingAvatar && (
        <Grid item xs={12}>
          Zdjęcie zostanie usunięte po zapisaniu zmian.
        </Grid>
      )}
      <Button
        className="button is-primary"
        variant="contained"
        component="label"
        className={classes.button}
        disabled={!enableProfileDataEdition}
      >
        {!avatarPath ? "Wgraj zdjęcie" : "Zmień zdjęcie"}
        <input
          type="file"
          hidden
          disabled={!enableProfileDataEdition}
          onChange={({ target: { files } }) => {
            setDataChanged(true);
            handleUploadedFiles(files);
          }}
        />
      </Button>
      {avatarPath && (
        <Button
          // variant="contained"
          component="label"
          className={classes.cancelButton}
          disabled={!enableProfileDataEdition}
          onClick={() => {
            setDataChanged(true);
            removeAvatar();
          }}
        >
          Usuń zdjęcie
        </Button>
      )}
      {/* {avatarPath && (
        <Button
          variant="contained"
          component="label"
          className={classes.fontSize}
          style={{
            marginLeft: "5px",
          }}
          disabled={!enableProfileDataEdition}
          onClick={() => {
            setDataChanged(true);
            removeAvatar();
          }}
        >
          Usuń zdjęcie
        </Button>
      )} */}
    </Box>
  );
};

export default AvatarUpload;
