import React, { useContext } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStyles } from "./results-per-package-table";
import { formatStartAndFinishDate } from "./results-per-package-table";
import PDFContext from "./pdf-context";

const DateAndResultGrid = ({ children, startDate, endDate }) => {
  const classes = useStyles();
  const isPDF = useContext(PDFContext);
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box
      className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
      style={{ paddingTop: 0 }}
    >
      <Grid
        container
        direction={smallScreen && !isPDF ? "column" : "row"}
        justify={"space-between"}
        alignItems="center"
      >
        <Grid item xs={isPDF ? 3 : 12} lg={3}>
          <Typography
            className={classes.patientDataFont}
            style={{ marginLeft: 16 }}
          >{`Data badania: ${formatStartAndFinishDate(
            startDate,
            endDate
          )}`}</Typography>
        </Grid>
        <Grid item xs={isPDF ? 6 : 12} lg={6}>
          {children}
        </Grid>
        <Grid item xs={isPDF ? 3 : 12} lg={3}>
          <Typography
            className={classes.patientDataFont}
            style={{ marginLeft: 16, visibility: "hidden" }}
          >{`Data badania: ${formatStartAndFinishDate(
            startDate,
            endDate
          )}`}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DateAndResultGrid;
