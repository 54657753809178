import React, { useState, useEffect, useRef, useMemo } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Button,
  ButtonGroup,
  Tooltip,
  ClickAwayListener,
  TablePagination,
  Box,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";

import format from "date-fns/format";

import Can from "../../components/can";

import Cookies from "js-cookie";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import FilterProperty, {
  UserFilterPropertySelect,
} from "../../components/filter-property/filter-property";
import { useDispatch, useSelector } from "react-redux";
import { fetchStands, fetchStandsWithPupils } from "../../redux/actions";
import StopIcon from "@material-ui/icons/Stop";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import { ReactComponent as InfoIconYellow } from "../../assets/info-yellow.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "16px",
  },
  paper: {
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  boxLoginTitle: {
    background: theme.palette.background.loginBackground,
    display: "flex",
    justifyContent: "center",
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingMedium: {
    padding: "16px 32px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  table: {
    minWidth: 1250,
  },
  tableContainer: {
    height: `calc(50vh - 55px)`,
    "@media (min-height: 300px)": {
      height: `calc(54vh - 55px)`,
    },
    "@media (min-height: 350px)": {
      height: `calc(60vh - 55px)`,
    },
    "@media (min-height: 400px)": {
      height: `calc(64vh - 55px)`,
    },
    "@media (min-height: 500px)": {
      height: `calc(70vh - 55px)`,
    },
    "@media (min-height: 600px)": {
      height: `calc(76vh - 55px)`,
    },
    "@media (min-height: 700px)": {
      height: `calc(80vh - 55px)`,
    },
    "@media (min-height: 800px)": {
      height: `calc(82vh - 55px)`,
    },
    "@media (min-height: 900px)": {
      height: `calc(84vh - 55px)`,
    },
  },
  tableHeader: {
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    borderBottom: "none",
    letterSpacing: "0.7px",
  },
  tableBodyCell: {
    color: theme.palette.text.hintText,
    borderBottom: `0.5px solid ${theme.palette.lockIcon.primary}`,
    fontFamily: "Montserrat",
  },
  widthBox: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
}));

const styles = (theme) => ({
  tooltip: {
    // width: "92px",
    // height: "36px",
    borderRadius: "10px",
    opacity: "0.5",
    boxShadow: "0px 2px 5px #BEBEBE",
    backgroundColor: theme.palette.background.default,
    padding: "16px",
    fontSize: theme.typography.desc,
    fontFamily: "Raleway",
    color: theme.palette.text.hintText,
    maxWidth: "150px",
    textAlign: "center",
  },
});

const CustomTooltip = withStyles(styles)(Tooltip);

const TestStandAllRow = ({
  testStand: { label: name, city, street, postCode, voivodeship, stationType },
}) => {
  const type = stationType === "Open" ? "Otwarte" : "Zamknięte";
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {name || ""}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {voivodeship || ""}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {city || ""}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {street || "---"}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {postCode || "---"}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {type || ""}
      </TableCell>
    </TableRow>
  );
};

const TestStandsAll = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [testStandsToRender, setTestStandsToRender] = useState([]);
  const [
    unFilteredTestStandsToRender,
    setUnFilteredTestStandsToRender,
  ] = useState([]);

  useEffect(() => {
    dispatch(fetchStands());
  }, []);

  const testStands = useSelector((s) => s.availableStands);

  const [testStandsCount, setTestStandsCount] = useState(0);

  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [filterQueries, setFilterQueries] = useState({});

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  useEffect(() => {
    setUnFilteredTestStandsToRender(
      testStands.sort((a, b) =>
        a.city !== b.city
          ? a.city.toUpperCase() < b.city.toUpperCase()
            ? -1
            : 1
          : 0
      )
    );
    setTestStandsToRender(
      testStands.sort((a, b) =>
        a.city !== b.city
          ? a.city.toUpperCase() < b.city.toUpperCase()
            ? -1
            : 1
          : 0
      )
    );
  }, [testStands]);

  const headerCells = [
    {
      title: "Nazwa",
      key: "label",
      // sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Województwo",
      key: "voivodeship",
      // sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Miasto",
      key: "city",
      // sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Ulica",
      key: "street",
      // sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Kod pocztowy",
      key: "postCode",
      // sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Rodzaj",
      key: "stationType",
      // sortable: true,
      filter: (key) => (
        <UserFilterPropertySelect
          label={"Rodzaj"}
          listItems={[
            { value: "Open", label: "Otwarty" },
            { value: "Closed", label: "Zamknięty" },
          ]}
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
  ];

  useEffect(() => {
    const result = unFilteredTestStandsToRender.filter(search, filterQueries);
    setTestStandsToRender(result);
  }, [filterQueries]);

  function search(testStand) {
    return Object.keys(this).every((key) => {
      if (key !== "stationType") {
        return testStand[key]
          .toString()
          .toUpperCase()
          .includes(this[key].toUpperCase());
      } else if (key === "stationType") {
        const parsed = JSON.parse(this[key]);
        return testStand[key] === parsed.value;
      }
    });
  }

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  return (
    <Box className={classes.root}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} md={10}>
          <Paper className={classes.paper}>
            <Box className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}>
              <Box
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                className={classes.widthBox}
              >
                <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                <Typography className={classes.loginTitle}>
                  Lista dostępnych stanowisk
                </Typography>
                <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
              </Box>
              <Box>
                {isLightGlobalTheme ? (
                  <CustomTooltip
                    placement="right-start"
                    title="Lista wszystkich dostępnych stanowisk, na których możesz wykonać badanie zakupione przez nasz sklep"
                  >
                    <InfoIcon style={{ cursor: "pointer" }} />
                  </CustomTooltip>
                ) : (
                  <CustomTooltip
                    placement="right-start"
                    title="Lista wszystkich dostępnych stanowisk, na których możesz wykonać badanie zakupione przez nasz sklep"
                  >
                    <InfoIconYellow style={{ cursor: "pointer" }} />
                  </CustomTooltip>
                )}
              </Box>
            </Box>
            <Box className={`${classes.paddingMedium}`}>
              <TableContainer className={classes.tableContainer}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {headerCells.map((headCell, index) => (
                        <TableCell
                          key={headCell.key}
                          className={classes.tableHeader}
                          style={{ textAlign: "center" }}
                        >
                          {headCell.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {headerCells.map((headCell) => (
                        <TableCell
                          key={headCell.key}
                          style={{ textAlign: "center" }}
                        >
                          {headCell.filter(headCell.key)}
                        </TableCell>
                      ))}
                    </TableRow>
                    {testStandsToRender.map((testStand, index) => (
                      <TestStandAllRow key={index} testStand={testStand} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const TestStandsAllPage = () => {
  return (
    <Can
      permission="test-stands-page:view"
      ok={() => <TestStandsAll />}
      not={() => <Redirect to="/" />}
    />
  );
};

export default TestStandsAllPage;
