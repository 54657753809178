import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { setCookiesAccepted } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    width: "95vw",
    borderRadius: "8px",
    background: theme.palette.background.default,
    position: "fixed",
    bottom: "5px",
    zIndex: "100",
    padding: "8px 16px",
    textAlign: "center",
    border: `0.5px solid ${theme.palette.text.hintText}`,
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      padding: "16px 32px",
    },
  },
  box: {
    width: "100vw",
    display: "flex",
    justifyContent: "center",
  },
  text: {
    color: theme.palette.text.hintText,
    fontSize: theme.typography.footerMobile,
  },
  aMain: {
    color: theme.palette.passwordIcon.primary,
    "&:hover": {
      textTransform: "underline",
    },
  },
  approveButton: {
    color: theme.palette.passwordIcon.primary,
    letterSpacing: "0.7px",
    fontSize: theme.typography.footerMobile,
    "&:hover": {
      color: theme.palette.text.hintText,
    },
  },
  closeIconButton: {
    padding: "0px",
    position: "absolute",
    right: "10px",
    top: "10px",
    borderRadius: "0px",
    border: `0.3px solid ${theme.palette.lockIcon.primary}`,
    // border: `0.3px solid ${theme.palette.text.alternative2}`,
  },
  closeIcon: {
    fill: theme.palette.text.alternative2,
    fontSize: "medium",
  },
}));

const Cookies = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const closeHandler = () => {
    dispatch(setCookiesAccepted());
  };
  return (
    <Box className={classes.box}>
      <Box className={classes.boxWrapper}>
        <IconButton className={classes.closeIconButton}>
          <CloseIcon className={classes.closeIcon} onClick={closeHandler} />
        </IconButton>
        <Typography className={classes.text}>
          Ważne: Użytkowanie Witryny oznacza zgodę na wykorzystywanie plików
          cookie. Szczegółowe informacje w{" "}
          {<a className={classes.aMain}>polityce prywatności</a>}.
          {
            <Button
              className={`${classes.approveButton}`}
              onClick={closeHandler}
            >
              Zgoda
            </Button>
          }
        </Typography>
      </Box>
    </Box>
  );
};

export default Cookies;
