import React from "react";
import { makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  boxBorder: {
    marginTop: theme.spacing(1),
    border: `1.5px solid ${theme.palette.grey[300]}`,
    borderRadius: "0.2rem",
    minHeight: "100px",
    minWidth: "100px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing("1"),
    },
  },
}));

const BoxBorder = ({ children }) => {
  const classes = useStyles();
  return (
    <Box pt={2} pb={2} pr={4} pl={4} className={classes.boxBorder}>
      {children}
    </Box>
  );
};

export default BoxBorder;
