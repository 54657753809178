import React from "react";

import RowControl from "../form-controls/row-control";

const FormFiller = ({
  onUpdate,
  onNoteUpdate,
  notes,
  rows,
  values,
  actualValues,
  edit,
  blankedRequiredFields,
  checkBlankedFields,
  incorrectFileTypeIDs,
  blankedRequiredArrayValueFields,
  setIncorrectFileTypeIDs,
}) => {
  // const [selectedItem, setSelectedItem] = useState()
  // const [rowsWithData, setRowsWithData] = useState([])
  // const [newRows, setNewRows] = useState([])

  // useEffect(() => {
  //   onUpdate && onUpdate(rows)
  // }, [onUpdate, rows])

  // useEffect(() => {
  //   setRowsWithData(JSON.parse(JSON.stringify(rows)))
  // }, [rows])

  // useEffect(() => {
  //   onUpdate(newRows)
  // }, [onUpdate, newRows])

  //
  // useEffect(() => {
  //   if (form) {
  //
  //   }
  // }, [form])

  const updateValues = (data) => {
    if (onUpdate) {
      onUpdate(data);
    }

    // setRows(rows.map((row, index) => {
    //   if (index === rowIndex) {
    //     return rowItems
    //   }
    //   return row;
    // }))
  };

  // const rowsToJson = rows => {
  //   return {
  //     children: rows,
  //   }
  // }

  return (
    <div className="columns">
      {/* <div className="column"> */}
      <div style={{ width: "100%" }}>
        {edit
          ? rows.map((row, rowIndex) => {
              let rowValues = [];
              row.map(({ id, dataType }, index) => {
                switch (dataType) {
                  case "FILE":
                    rowValues.push({
                      id,
                      files: values.find((f) => f.id === id)?.files || [],
                    });
                    break;
                  default:
                    if (values[id]) {
                      if (!values.hasOwnProperty(id)) {
                        rowValues.push({ id, value: " " });
                      } else {
                        rowValues.push({ id, value: values[id] });
                      }
                    } else {
                      let value = Object.keys(values)
                        .map((i) => values[i])
                        .find((v) => v.id === id);

                      if (!value) {
                        rowValues.push({ id, value: " " });
                      } else {
                        rowValues.push({
                          id,
                          value: value.value || value.alternativeAnswer,
                        });
                      }
                    }
                    break;
                }
              });
              return (
                <RowControl
                  key={rowIndex}
                  updateValues={updateValues}
                  onNoteUpdate={onNoteUpdate}
                  notes={notes}
                  filledValuesDetails={values}
                  actualValues={actualValues}
                  rowValues={rowValues}
                  actualValues={actualValues}
                  items={row}
                  dragMode="none"
                  blankedRequiredFields={blankedRequiredFields}
                  incorrectFileTypeIDs={incorrectFileTypeIDs}
                  blankedRequiredArrayValueFields={
                    blankedRequiredArrayValueFields
                  }
                  checkBlankedFields={checkBlankedFields}
                  setIncorrectFileTypeIDs={setIncorrectFileTypeIDs}
                  edit={edit}
                />
              );
            })
          : rows.map((row, rowIndex) => {
              return (
                <RowControl
                  key={rowIndex}
                  onNoteUpdate={onNoteUpdate}
                  notes={notes}
                  blankedRequiredFields={blankedRequiredFields}
                  incorrectFileTypeIDs={incorrectFileTypeIDs}
                  blankedRequiredArrayValueFields={
                    blankedRequiredArrayValueFields
                  }
                  checkBlankedFields={checkBlankedFields}
                  setIncorrectFileTypeIDs={setIncorrectFileTypeIDs}
                  actualValues={actualValues}
                  updateValues={updateValues}
                  items={row}
                  dragMode="none"
                />
              );
            })}
      </div>
    </div>
  );
};

export default FormFiller;
