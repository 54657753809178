import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Can from "../../components/can";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BillingAddressForm from "../address-form/billing-info";
import ShippingAddressForm from "../address-form/shipping-info";
import { TestCardComponent } from "./testCard";

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    padding: "16px",
    justifyContent: "center",
    paddingBottom: "72.5px",
  },
  typography: {
    color: theme.palette.text.alternative3,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const TestKitsAmount = ({
  setDataChanged,
  products,
  productsToBuy,
  setProductsToBuy,
}) => {
  const classes = useStyles();

  return products
    ?.slice(0)
    .reverse()
    .map((product, index) => (
      <Grid item xs={12} md={6} lg={4} key={index}>
        <TestCardComponent
          product={product}
          setDataChanged={setDataChanged}
          productsToBuy={productsToBuy}
          setProductsToBuy={setProductsToBuy}
        />
      </Grid>
    ));
};
