import React, { useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { QRScanner } from "../qr-reader";
import Loader from "../loader";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function getModalStyleMobile() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    maxWidth: 500,
    backgroundColor: theme.palette.background.default,
    border: theme.palette.background.default,
    padding: "16px 8px",
    borderRadius: "0px",
    border: `0.5px solid ${theme.palette.hr.primary}`,
  },
  title: {
    color: theme.palette.text.title,
    fontSize: theme.typography.footer,
    fontWeight: "600",
    textAlign: "center",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  buttonTitle: {
    color: theme.palette.text.buttonTitle,
    fontSize: theme.typography.footerMobile,
  },
  plainText: {
    color: theme.palette.text.buttonTitle,
    fontSize: theme.typography.hintText,
  },
}));

const VoucherCardModal = ({
  handleClose,
  open,
  changeCode,
  setCode,
  code,
  codeTyped,
  setCodeTyped,
  qrCodeValid,
  setOpenTypeCodePanel,
  openTypeCodePanel,
}) => {
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const userLoggedIn = useSelector((s) => s.loggedIn);

  const history = useHistory();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [modalStyleMobile] = useState(getModalStyleMobile);

  const [qrOpen, setQrOpen] = useState(false);

  const [openQrPanel, setOpenQRPanel] = useState(false);

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        style={smallScreen ? modalStyleMobile : modalStyle}
        className={classes.paper}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.title}>Wczytaj kod QR</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                setOpenTypeCodePanel(true);
                setOpenQRPanel(false);
                setCode("");
              }}
              style={{ minWidth: "138px" }}
            >
              <Typography
                className={classes.buttonTitle}
              >{`Wpisz KOD ręcznie`}</Typography>
            </Button>
            <Typography className={classes.plainText}>{`lub`}</Typography>
            <Button
              style={{ minWidth: "138px" }}
              onClick={() => {
                setOpenQRPanel(true);
                setOpenTypeCodePanel(false);
                setCodeTyped("");
              }}
            >
              <Typography className={classes.buttonTitle}>
                {" "}
                {` Zeskanuj KOD QR`}
              </Typography>
            </Button>
          </Grid>
          {openTypeCodePanel && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={codeTyped}
                onChange={({ target: { value: code } }) => setCodeTyped(code)}
                label="Wprowadź 6-znakowy kod"
                size="small"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            {openQrPanel && (
              <>
                <QRScanner
                  changeCode={changeCode}
                  qrOpen={qrOpen}
                  closeQrPanel={() => setQrOpen(false)}
                  openQrPanel={() => setQrOpen(true)}
                ></QRScanner>
                {code?.length > 0 &&
                  (qrCodeValid ? (
                    <h3>{`LINK: ${code} `}</h3>
                  ) : (
                    <h3>Wczytany kod jest nieprawidłowy</h3>
                  ))}
                {/* <h3>{`ZESKANOWANY LINK: ${code} `}</h3> */}
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            {qrCodeValid && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                style={{ borderRadius: "0px", height: "33px" }}
                onClick={() => {
                  if (!userLoggedIn) {
                    history.push("/login");
                  } else {
                    history.push(
                      `/qr-code-validation/${codeTyped ? codeTyped : code}`
                    );
                  }
                }}
              >
                Przejdź dalej
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default VoucherCardModal;
