import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  alertAdd,
  clearAllFormData,
  createFilledFormData,
  editFilledFormData,
  fetchForm,
  fetchFormData,
  fetchForms,
  fetchFormTemplate,
  fetchScheduledForm,
  removeDraft,
} from "../redux/actions";
import FormFiller from "../components/form-filler/form-filler";
import Can from "../components/can";
import { Box } from "@material-ui/core";
import PageTitle from "../components/page-title";
import Duration from "duration";
import Typography from "@material-ui/core/Typography";
import { uploadFormDataFile } from "../redux/organization/actions";
import Loader from "../components/loader";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { addMinutes } from "date-fns";

const useStyles = makeStyles((theme) => ({
  hr: {
    border: theme.palette.primary.main === "#fff200" && "1px solid #fff200",
  },
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  alertHighContrast: {
    "& .MuiAlert-icon": {
      // fontSize: 40,
      color: theme.palette.text.primary,
    },
    backgroundColor: "#000",
    border: "1px solid #fff200",
    color: theme.palette.text.primary,
  },
  textCentered: {
    textAlign: "center",
  },
  formHint: {
    fontSize: theme.typography.footer,
    lineHeight: "1.4",
    margin: "-5px auto 5px",
    color: theme.palette.text.error,
    fontFamily: "Montserrat",
  },
}));

const FillFormPage = ({
  match,
  orgForms,
  fetchForm,
  fetchFormTemplate,
  formData,
  alertAdd,
  formId,
  setValidAssignButton,
  assignHandler,
  mandatory,
  tenantId,
  screeningId,
  urlToNavigate,
  meToAssign,
  patientID,
  assignedPerson,
  hearBoxScreeningId,
  hearBoxTestId,
  patientScreeningId,
  fetching,
  setFetching,
}) => {
  const baseFontSize = useSelector((s) => s.baseFontSize);
  const my = useSelector((state) => state.my);
  const globalTheme = useSelector((s) => s.globalTheme);
  const [form, setForm] = useState();
  const [formTemplate, setFormTemplate] = useState();
  const [rows, setRows] = useState([]);
  const [valid, setValid] = useState(false);
  const [formIsSending, setFormIsSending] = useState(false);

  const history = useHistory();

  const [checkBlankedFields, setCheckBlankedFields] = useState(false);

  const [blankedRequiredFields, setBlankedRequiredFields] = useState([]);
  const [
    blankedRequiredArrayValueFields,
    setBlankedRequiredArrayValueFields,
  ] = useState([]);
  const [
    blankedRequiredAlternativeAnswer,
    setBlankedRequiredAlternativeAnswer,
  ] = useState([]);
  const [incorrectFileTypeIDs, setIncorrectFileTypeIDs] = useState({});

  const [values, setValues] = useState({});
  const [notes, setNotes] = useState({});
  const [fieldAlternativeAnswer, setFieldAlternativeAnswer] = useState(null);
  const [arrayValues, setArrayValues] = useState(null);
  const [color, setColor] = useState("");
  const [text, setText] = useState("");
  const [locationValues, setLocationValues] = useState({});
  const [filledValuesDetails, setFilledValuesDetails] = useState([]);
  const [scheduledFormData, setScheduledFormData] = useState(null);
  const [existingdFormData, setExistingFormData] = useState(null);

  const [timeToGo, setTimeToGo] = useState("");
  const [expired, setExpired] = useState(false);
  const [files, setFiles] = useState(null);

  const [formState, setFormState] = useState("");
  const [fieldsWithNotes, setFieldsWithNotes] = useState([]);

  // const formDataId = match.params.formdataId;

  const dispatch = useDispatch();

  const location = useLocation();

  const classes = useStyles();

  const [scheduledFormNotFound, setScheduledFormNotFound] = useState(false);

  const setRemainingTime = (duration) => {
    if (duration.seconds < 0) {
      setTimeToGo("---");
      setExpired(true);
    } else {
      setTimeToGo(
        `${duration.hours}`.padStart(2, "0") +
          ":" +
          `${duration.minutes % 60}`.padStart(2, "0") +
          ":" +
          `${duration.seconds % 60}`.padStart(2, "0")
      );
    }
  };

  // useEffect(() => {
  //   if (formDataId) {
  //     fetchAll();
  //   }
  // }, [formDataId]);

  // const fetchAll = async () => {
  //   const data = await dispatch(fetchFormData(formDataId));
  //   setFilledValuesDetails(data.items);
  //   setFormState(data.state);
  // };

  useEffect(() => {
    const noteKeysToAdd = [];
    fieldsWithNotes.forEach((id) => {
      if (!notes.hasOwnProperty(id)) {
        noteKeysToAdd.push(id);
      }
    });

    if (noteKeysToAdd.length) {
      setNotes({
        ...noteKeysToAdd.reduce((all, id) => ({ ...all, [id]: "" }), []),
        ...notes,
      });
    }
  }, [fieldsWithNotes, notes]);

  useEffect(() => {
    if (form?.schedule && (scheduledFormData || existingdFormData)) {
      const endDate =
        scheduledFormData?.endDate ||
        existingdFormData?.editableUntil ||
        existingdFormData?.scheduledExecutionEnd;

      const duration = new Duration(new Date(), new Date(endDate));
      setRemainingTime(duration);

      const timerId = setInterval(() => {
        const duration = new Duration(new Date(), new Date(endDate));

        setRemainingTime(duration);
      }, 1000);

      return () => {
        clearInterval(timerId);
      };
    }
  }, [form, scheduledFormData, existingdFormData]);

  useEffect(() => {
    // if (fetching === undefined) {
    //   // const formId = parseInt(match.params.formId);
    //   if (formId) {
    // setFetching(true);
    fetchForm(formId).then((fff) => {
      setFetching(false);
    });
    //   }
    // }
  }, []);

  // useEffect(() => {
  //   if (fetching === undefined) {
  //     const formdataId = parseInt(match.params.formdataId);
  //     if (formdataId) {
  //       // setFetching(true)
  //       // fetchForm(formdataId).then((fff) => {
  //       //   setFetching(false)
  //       // })
  //     }
  //   }
  // }, [orgForms, fetching, match, fetchForm]);

  useEffect(() => {
    // const formId = parseInt(match.params.formId);

    const f = orgForms.find(({ id }) => id === formId);
    if (f) {
      setForm(f);
    }
  }, [orgForms, match]);

  useEffect(() => {
    setValidAssignButton(valid);
  }, [valid]);

  useEffect(() => {
    if (form && !formTemplate && form.layoutId) {
      fetchFormTemplate(form.layoutId);
      setFormTemplate(true);
    }
  }, [form, formTemplate, fetchFormTemplate]);

  useEffect(() => {
    const loToRows = () => {
      return formData.layoutElementObject.children.map((c) => {
        return c.children.map((c) => {
          return c;
        });
      });
    };

    if (formData) {
      const rows = loToRows();

      setRows(rows);

      const notesConfig = rows
        .map((row) => row.filter((item) => item.configuration.noteEnabled))
        .flat()
        .reduce((all, current) => [...all, current.id], []);

      setFieldsWithNotes(notesConfig);
    }
  }, [formData]);

  useEffect(() => {
    let tmpValues;
    let tmpNotes;

    tmpValues = rows
      .map((row) => row.map((rowElement) => ({ [rowElement.id]: undefined })))
      .flat();

    tmpValues = tmpValues.filter(
      (tmpValue) => Object.keys(tmpValue).length !== 0
    );

    let tmpValuesObject = {};
    tmpValues.forEach(function (item) {
      Object.keys(item).forEach(function (key) {
        tmpValuesObject[key] = item[key];
      });
    });

    const tmpMultiplyValuesObject = {};
    let tmpArrayValues = rows
      .map((row) =>
        row.filter(({ configuration }) => configuration.allowMultipleAnswers)
      )
      .flat();

    tmpArrayValues = tmpArrayValues.map(({ id }) => ({ [id]: [] }));

    tmpArrayValues.forEach(function (item) {
      Object.keys(item).forEach(function (key) {
        tmpMultiplyValuesObject[key] = item[key];
      });
    });

    setValues(tmpValuesObject);
    setArrayValues(tmpMultiplyValuesObject);
    setFieldAlternativeAnswer({});
    setFiles({});
  }, [rows]);

  const onFormUpdated = ({
    id,
    value,
    isText,
    configuration,
    selected,
    isFiles,
  } = {}) => {
    if (id) {
      const isArray = configuration.allowMultipleAnswers;

      if (isArray) {
        if (selected) {
          setArrayValues((f) => ({
            ...f,
            [id]: [...(f[id] ? f[id] : []), value],
          }));
        } else {
          const arr = (arrayValues[id] || []).filter((v) => v !== value);

          setArrayValues((f) => ({
            ...f,
            [id]: arr,
          }));
        }
      } else if (isFiles) {
        if (value.length === 0) {
          let tmpFiles = { ...files };

          delete tmpFiles[id];

          setFiles(tmpFiles);
        } else {
          setFiles((files) => ({
            ...files,
            [id]: value,
          }));
        }
      } else {
        // if (values[id] === value) {
        if (
          values[id] === value ||
          (!isText &&
            fieldAlternativeAnswer[id] &&
            value !== fieldAlternativeAnswer[id])
        ) {
          if (
            !isText &&
            fieldAlternativeAnswer[id] &&
            value !== fieldAlternativeAnswer[id]
          ) {
            setValues({
              ...values,
              [id]: value,
            });

            const {
              [id]: valueToRemoveAlternative,
              ...vAlternative
            } = fieldAlternativeAnswer;
            setFieldAlternativeAnswer(vAlternative);
          } else {
            const { [id]: valueToRemove, ...v } = values;
            setValues(v);
          }
        } else {
          if (isText) {
            setFieldAlternativeAnswer({
              ...fieldAlternativeAnswer,
              [id]: value,
            });

            if (values[id]) {
              const { [id]: valueToRemove, ...v } = values;
              setValues(v);
            }
          } else {
            setValues({
              ...values,
              [id]: value,
            });
          }
        }
      }
    }
  };

  const onNoteUpdate = ({ note, id }) => {
    setNotes({ ...notes, [id]: note || "" });
  };

  useEffect(() => {
    // TODO: remove me
  }, [values]);

  useEffect(() => {
    const requiredFieldsNotProvided = rows
      .map((row) =>
        row.filter(
          (rowElement) =>
            rowElement.configuration.required &&
            rowElement.dataType === "DICTIONARY" &&
            rowElement.configuration.allowAlternativeAnswer &&
            fieldAlternativeAnswer[rowElement.id] === "" &&
            values[rowElement.id] === ""
        )
      )
      .flat();
    setBlankedRequiredAlternativeAnswer(
      requiredFieldsNotProvided.map((field) => field.id)
    );
    // setRequiredFieldWithAlternativeAnswerProvided(
    //   !(requiredFieldsNotProvided.length > 0)
    // );
  }, [fieldAlternativeAnswer, values]);

  useEffect(() => {
    const requiredFieldsNotProvided = rows
      .map((row) =>
        row.filter(
          (rowElement) =>
            rowElement.configuration.required &&
            rowElement.configuration.allowMultipleAnswers &&
            (!arrayValues[rowElement.id] ||
              arrayValues[rowElement.id].length === 0)
        )
      )
      .flat();
    setBlankedRequiredArrayValueFields(
      requiredFieldsNotProvided.map((field) => field.id)
    );
    // setRequiredArrayValueFieldProvided(!(requiredFieldsNotProvided.length > 0));
  }, [arrayValues]);

  useEffect(() => {
    let requiredFieldsNotProvided = rows
      .map((row) =>
        row.filter(
          (rowElement) =>
            rowElement.configuration.required &&
            !rowElement.configuration.allowMultipleAnswers &&
            !fieldAlternativeAnswer[rowElement.id] &&
            (values[rowElement.id] === "" ||
              (!values[rowElement.id] && values[rowElement.id] !== 0) ||
              values[rowElement.id] === "Invalid date" ||
              (rowElement.dataType === "NUMBER" &&
                isNaN(values[rowElement.id])) ||
              (rowElement.dataType === "DICTIONARY" &&
                rowElement.allowAlternativeAnswer &&
                values[rowElement.id] === "" &&
                (!fieldAlternativeAnswer[rowElement.id] ||
                  fieldAlternativeAnswer[rowElement.id] === "")))
        )
      )
      .flat();

    const requiredFieldsWithFilesProvided = rows
      .map((row) =>
        row.filter(
          (rowElement) =>
            rowElement.configuration.required &&
            rowElement.dataType === "FILE" &&
            files?.hasOwnProperty(rowElement.id)
        )
      )
      .flat();

    requiredFieldsNotProvided = requiredFieldsNotProvided.filter(
      (fieldNotProvided) =>
        !requiredFieldsWithFilesProvided.includes(fieldNotProvided)
    );
    setBlankedRequiredFields(
      requiredFieldsNotProvided.map((field) => field.id)
    );

    const fieldsValueOutOfBounds = rows
      .map((row) =>
        row.filter(
          (rowElement) =>
            rowElement.dataType === "NUMBER" &&
            rowElement.configuration.maxValue !== null &&
            rowElement.configuration.minValue !== null &&
            (values[rowElement.id] || values[rowElement.id] === 0) &&
            ((rowElement.configuration.maxValue &&
              rowElement.configuration.maxValue < values[rowElement.id]) ||
              (rowElement.configuration.minValue &&
                rowElement.configuration.minValue > values[rowElement.id]))
        )
      )
      .flat();

    setValid(!(fieldsValueOutOfBounds.length > 0));
  }, [values, fieldAlternativeAnswer, files]);

  const saveDraft = (e) => {
    e.preventDefault();

    save({
      draft: true,
    });
  };

  const saveForm = (e) => {
    e.preventDefault();

    save();
  };

  // const removeFormData = async (e) => {
  //   e.preventDefault();

  //   // const response = await dispatch(removeDraft(formDataId));

  //   if (response.status === 200) {
  //     alertAdd({
  //       timeout: 7000,
  //       text: "Wersja robocza formularza została usunięta",
  //       isSuccess: true,
  //     });
  //     history.push("/");
  //   } else if (response.data.message === "form-data-not-removable") {
  //     alertAdd({
  //       timeout: 7000,
  //       text: "Nie można usunąć danych formularza",
  //       isError: true,
  //     });
  //   } else {
  //     alertAdd({
  //       timeout: 7000,
  //       text: "Nie można usunąć danych formularza",
  //       isError: true,
  //     });
  //   }
  // };

  const save = async ({ draft } = { draft: false }) => {
    setFormIsSending(true);
    const uploadedFiles = {};

    const filteredNotes = Object.keys(notes)
      .filter(
        (key) =>
          notes[key] !== undefined &&
          notes[key] !== null &&
          notes[key].length > 0
      )
      .reduce(
        (result, key) => ({
          ...result,
          [key]: notes[key],
        }),
        {}
      );

    const requiredFormFields = draft
      ? []
      : rows
          .map((row) =>
            row.filter((rowElement) => rowElement.configuration.required)
          )
          .flat();

    if (
      (blankedRequiredFields.length === 0 &&
        blankedRequiredArrayValueFields.length === 0 &&
        blankedRequiredAlternativeAnswer.length === 0) ||
      requiredFormFields.length === 0
    ) {
      setCheckBlankedFields(false);
      const fieldIds = Object.keys(files);
      let uploadStatus;
      for (let fieldId of fieldIds) {
        const filesToUpload = files[fieldId];
        uploadedFiles[fieldId] = [];

        for (let i = 0; i < filesToUpload.length; i++) {
          const fileToUpload = filesToUpload[i];
          try {
            const { id } = await dispatch(
              uploadFormDataFile({ file: fileToUpload })
            );
            uploadedFiles[fieldId][i] = id;
          } catch (e) {
            setFormIsSending(false);
            alertAdd({
              timeout: 7000,
              text: "Błąd przy uploadzie plików",
              isError: true,
            });
            uploadStatus = false;
            break;
          }
        }
      }

      if (uploadStatus !== false) {
        try {
          const filledFormId = await dispatch(
            createFilledFormData({
              formId: form.id,
              values,
              arrayValues,
              files: uploadedFiles,
              notes: filteredNotes,
              fieldAlternativeAnswer,
              draft,
              screeningId,
              tenantId,
              ...(!meToAssign && { filledForSubaccountId: patientID }),
              ...(!mandatory && { hearBoxTestId }),
              ...(!mandatory && { patientScreeningId }),
            })
          );

          dispatch(clearAllFormData());
          if (!mandatory) {
            history.push({
              pathname: urlToNavigate,
              state: {
                assignedPerson,
                protegeAssigned: !meToAssign,
              },
            });
          }
          assignHandler();

          setFormIsSending(false);

          // if (my.user.authorities === "ROLE_USER") {
          //   const viewInvalidTimestamp = addMinutes(new Date(), 10).getTime();
          //   history.push({
          //     pathname: `/formdata/z/${filledFormId}/${viewInvalidTimestamp}`,
          //     state: { displayNotification: true, draft },
          //   });
          // } else {
          //   history.push({
          //     pathname: `/formdata/${filledFormId}`,
          //     state: { displayNotification: true, draft },
          //   });
          // }
        } catch (e) {
          const message = e.response?.data.message;

          switch (message) {
            case "too-many-files-sent":
              alertAdd({
                timeout: 3000,
                isError: true,
                text: "Przesłano zbyt wiele plików",
              });
              break;
            case "scheduled-form-is-expired":
              alertAdd({
                timeout: 7000,
                isError: true,
                text:
                  "Nie możesz wysłać formularza. Termin jego dostępności minął.",
              });

              history.push(`/forms/active`);
              break;
            case "form-data-already-exists-for-this-user-and-screening-id":
              alertAdd({
                timeout: 7000,
                isError: true,
                text: mandatory
                  ? "Nie można przypisać osoby do przesiewu więcej, niż jeden raz."
                  : "Ankieta została już wypełniona.",
              });
              break;
            case "test-not-assigned-to-patient":
              alertAdd({
                timeout: 3000,
                isError: true,
                text: "Błąd przy zapisie ankiety",
              });
              break;
            default:
              if (message?.indexOf("file-type-not-allowed") === 0) {
                Object.keys(uploadedFiles).find((key) => {
                  const tmp = e.response.data.fileIds.filter((fileIds) => {
                    if (uploadedFiles[key].includes(fileIds))
                      return uploadedFiles[key].includes(fileIds);
                  });
                  setIncorrectFileTypeIDs((prevState) => ({
                    ...prevState,
                    [key]: tmp,
                  }));
                });
                alertAdd({
                  timeout: 3000,
                  isError: true,
                  text: "Dodano niepoprawny typ pliku",
                });
              }
          }
          setFormIsSending(false);
        }
      }
    } else {
      setFormIsSending(false);
      setCheckBlankedFields(!draft);
    }

    setFormIsSending(false);
  };

  const cancel = (e) => {
    my?.user.authorities.localeCompare("ROLE_USER") === 0
      ? history.push("/forms/active")
      : history.push("/forms");
  };

  return fetching ? (
    <Box p={2}>
      <Loader loading={true} text="Pobieranie danych - proszę czekać"></Loader>
    </Box>
  ) : (
    <Box>
      {scheduledFormNotFound ? (
        <div className={classes.textCentered}>
          <Typography variant="h5" className={classes.textCentered}>
            Nie można wypełnić formularza
          </Typography>
          <Typography variant="h6" className={classes.textCentered}>
            Formularz został już wypełniony lub czas na wypełnienie formularza
            minął.
          </Typography>
          <Button
            variant={"contained"}
            className={classes.fontSize}
            style={{
              marginTop: "10px",
              backgroundColor:
                globalTheme === "high-contrast" ? "yellow" : "blue",
              color: globalTheme === "high-contrast" ? "black" : "white",
              border: globalTheme === "high-contrast" && "1px solid yellow",
            }}
            onClick={() => {
              history.push("/forms/active");
            }}
          >
            Przejdź do listy formularzy
          </Button>
        </div>
      ) : (
        <>
          <Box p={1}>
            {formState === "SAVED" ? (
              <Typography variant="subtitle1">
                <Alert
                  severity="info"
                  className={`${
                    globalTheme === "high-contrast" && classes.alertHighContrast
                  } ${classes.fontSize}`}
                >
                  Formularz jest zapisany na serwerze.
                </Alert>
              </Typography>
            ) : null}

            {formState !== "SAVED" && timeToGo && timeToGo !== "---" ? (
              <Typography variant="subtitle1">
                <Alert
                  severity="info"
                  className={`${
                    globalTheme === "high-contrast" && classes.alertHighContrast
                  } ${classes.fontSize}`}
                >
                  Formularz dostępny jeszcze przez: {timeToGo}
                </Alert>
              </Typography>
            ) : null}

            {formState !== "SAVED" && timeToGo === "---" ? (
              <Typography variant="subtitle1">Formularz niedostępny</Typography>
            ) : null}
            <PageTitle title={`Wypełnianie ankiety ${form ? form.name : ""}`} />
            {formIsSending && (
              <Box p={1}>
                {<Loader loading={true} text="Przesyłanie formularza..." />}
              </Box>
            )}

            {form && form.description && (
              <p className={classes.fontSize}>
                {/* <b>Opis</b> */}
                <br />
                <small>{form.description}</small>
              </p>
            )}
            <hr className={classes.hr} />
          </Box>
          <Box p={1}>
            {rows.length && (
              <FormFiller
                onUpdate={onFormUpdated}
                onNoteUpdate={onNoteUpdate}
                notes={notes}
                rows={rows}
                edit={false}
                actualValues={values}
                blankedRequiredFields={blankedRequiredFields}
                blankedRequiredArrayValueFields={
                  blankedRequiredArrayValueFields
                }
                incorrectFileTypeIDs={incorrectFileTypeIDs}
                checkBlankedFields={checkBlankedFields}
                setIncorrectFileTypeIDs={setIncorrectFileTypeIDs}
              />
            )}
            <hr className={classes.hr} />
          </Box>
          {checkBlankedFields && (
            <Box p={1}>
              {blankedRequiredFields.length > 0 && (
                <span className={classes.formHint}>
                  Wypełnij wszystkie wymagane pola
                </span>
              )}
            </Box>
          )}
          <Box p={1} style={{ padding: "0 48px" }}>
            <Button
              // className={classes.fontSize}
              title="Wyślij formularz i zapisz na serwerze."
              fullWidth
              variant="contained"
              color="primary"
              // variant={"contained"}
              disabled={
                // !requiredFieldProvided ||
                // !requiredArrayValueFieldProvided ||
                // !requiredFieldWithAlternativeAnswerProvided ||
                !valid || timeToGo === "---" || formIsSending
              }
              onClick={saveForm}
            >
              {mandatory ? "Przypisz osobę" : "Zapisz ankietę"}
            </Button>{" "}
          </Box>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  orgForms: state.orgForms,
  formData: state.formData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchForms: () => dispatch(fetchForms()),
  fetchForm: (id) => dispatch(fetchForm(id)),
  fetchFormTemplate: (id) => dispatch(fetchFormTemplate(id)),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FillFormPage);
