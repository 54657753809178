import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import s from "./index.module.css";
import * as classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { fetchUploadableFileTypes } from "../../../redux/actions";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fontSize: {
    color: theme.palette.text.primary2,
    fontSize: theme.typography.fontSize,
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.formLabel,
  },
  alert: {
    color: theme.palette.text.error,
    fontSize: theme.typography.formLabel,
  },
}));

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.anchor.primary,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
      textDecoration: "underline",
    },
    marginLeft: "5px",
  },
}))(Link);

const FileControl = ({
  name,
  id,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  disabled,
  showSelfDescription,
  rowIndex,
  index,
  setIsDragging,
  filledValue,
  sidebarMode,
  onRemove,
  formCreatorMode,
  filledFormMode,
  incorrectFileTypeIDs,
  setIncorrectFileTypeIDs,
  blankedRequiredFields,
  checkBlankedFields,
  displayMode,
  filesData,
}) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.FILE,
      dragMode,
      defaultLabel: "Plik",
      id,
      rowIndex,
      index,
    },
    collect: (monitor) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });

  const classes = useStyles();
  const globalTheme = useSelector((s) => s.globalTheme);

  const [value, setValue] = useState(filledValue || []);
  const [fieldIDWithMovie, setFieldIDWithMovie] = useState("");
  const uploadableFileTypes = useSelector((s) => s.uploadableFileTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (uploadableFileTypes.length === 0) {
      dispatch(fetchUploadableFileTypes());
    }
  }, [uploadableFileTypes]);

  useEffect(() => {
    const file = filesData?.find((data) => data.id === id);
    if (file) {
      setValue(file.note);
    }
  }, [filesData]);

  const clicked = () =>
    markSelected &&
    markSelected({
      id,
      dataType: ItemSubtypes.FILE,
      configuration,
    });

  const updateValue = (v) => {
    let tmpValueArray = [...value];
    tmpValueArray = tmpValueArray.concat(v);
    setValue(tmpValueArray);

    if (onChange) {
      onChange({
        id,
        value: tmpValueArray,
        isFiles: true,
      });
    }
  };

  const removeValue = (v, index) => {
    setValue(value.filter((value, idx) => index !== idx));
    if (Object.keys(incorrectFileTypeIDs).length !== 0) {
      if (value.filter((value, idx) => index !== idx).length === 0) {
        let tmpIncorrectFiles = { ...incorrectFileTypeIDs };
        delete tmpIncorrectFiles[id];
        setIncorrectFileTypeIDs(tmpIncorrectFiles);
      } else {
        setIncorrectFileTypeIDs({
          ...incorrectFileTypeIDs,
          [id]: value.filter((value, idx) => index !== idx),
        });
      }
    }

    if (onChange) {
      onChange({
        id,
        value: value.filter((value, idx) => index !== idx),
        isFiles: true,
      });
    }
  };

  const handleUploadedFiles = async (files) => {
    const ff = [];

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      ff.push(file);
    }

    updateValue(ff);
  };

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Plik"
        />
      </div>
    );
  }

  const placeholder = showSelfDescription
    ? "Plik"
    : (configuration && configuration.text) || "";
  const label = (configuration && configuration.label) || "";
  const fileType = configuration.fileType;

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          {!displayMode && (
            <FontAwesomeIcon
              className={classnames(s.trashIcon, "has-text-grey")}
              onClick={onRemove}
              icon="trash"
              title="Usuń"
            />
          )}
        </div>
      </div>
    );
  }

  const extensions = (
    uploadableFileTypes.find((x) => x.name === fileType)?.details || []
  )
    .map((x) => x.extension)
    .filter((v, i, a) => a.indexOf(v) == i);

  return (
    <div
      ref={drag}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {/* <div ref={drag} className={classes.alignItemsLeft}> */}
      {configuration && configuration.label && (
        <label className={classes.label}>
          {configuration.label}
          {configuration.required && " *"}
        </label>
      )}
      {!filledFormMode && (
        <>
          <input
            style={{ display: "none" }}
            id={`contained-button-file-${id}`}
            type="file"
            multiple
            disabled={configuration.maxNumberOfFiles === value.length}
            onChange={(event) => {
              handleUploadedFiles(event.target.files);
              event.target.value = null;
            }}
          />
          <label htmlFor={`contained-button-file-${id}`}>
            <Button
              disabled={configuration.maxNumberOfFiles === value.length}
              variant="contained"
              color="primary"
              component="span"
            >
              Wgraj
            </Button>
          </label>

          {extensions.length > 0 ? (
            <div className={`${classes.fontSize}`}>
              Wspierane rozszerzenia: {extensions.join(", ")}; maksymalna ilość
              plików: {configuration.maxNumberOfFiles}
            </div>
          ) : (
            <div className={`${classes.fontSize}`}>
              Wspierane rozszerzenia: dowolne; maksymalna ilość plików:{" "}
              {configuration.maxNumberOfFiles}
            </div>
          )}
        </>
      )}
      <ul>
        {(value &&
          value.length > 0 &&
          value.map((f, index) => (
            <React.Fragment key={index}>
              <li key={index}>
                <span className={`${classes.label}`}>{f.name}</span>
                {filledFormMode &&
                  (!f.mimeType.includes("video") ? (
                    f.mimeType.includes("image") && (
                      <MuiNavLink
                        href={f.id ? `/api/form_data/serve_file/${f.id}` : "#"}
                        target="_blank"
                        className={`${classes.fontSize}`}
                      >
                        Podgląd
                      </MuiNavLink>
                    )
                  ) : !(fieldIDWithMovie === f.id) ? (
                    <MuiNavLink onClick={() => setFieldIDWithMovie(f.id)}>
                      Podgląd
                    </MuiNavLink>
                  ) : (
                    <MuiNavLink onClick={() => setFieldIDWithMovie("")}>
                      Zamknij
                    </MuiNavLink>
                  ))}{" "}
                {filledFormMode && (
                  <MuiNavLink
                    href={
                      f.id
                        ? `/api/form_data/serve_file/${f.id}?download=true`
                        : "#"
                    }
                    className={`${classes.fontSize}`}
                    target="_black"
                  >
                    Pobierz
                  </MuiNavLink>
                )}{" "}
                {!disabled && (
                  <MuiNavLink
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      removeValue(f, index);
                    }}
                    className={`${classes.fontSize}`}
                  >
                    Usuń
                  </MuiNavLink>
                )}
                {fieldIDWithMovie === f.id && (
                  <div>
                    <video width="1024" height="800" controls>
                      <source
                        src={f.id ? `/api/form_data/serve_file/${f.id}` : "#"}
                        type="video/mp4"
                      />
                    </video>{" "}
                  </div>
                )}
              </li>
            </React.Fragment>
          ))) || <span className={`${classes.fontSize}`}>Brak</span>}
        {checkBlankedFields &&
          blankedRequiredFields.find((fieldId) => fieldId === id) && (
            <p className={classes.alert}>To pole musi zostać wypełnione</p>
          )}
      </ul>
      {incorrectFileTypeIDs?.hasOwnProperty(id) &&
        incorrectFileTypeIDs[id].length > 0 && (
          <p class="help is-danger">Niepoprawny typ pliku</p>
        )}
    </div>
  );
};

export default FileControl;
