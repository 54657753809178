import React from "react";
import { Link, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Box, makeStyles, Paper, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Can from "../../components/can";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as LockOpen } from "../../assets/lock-open.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "32px",
  },
  formHint1: {
    color: theme.palette.text.item,
    marginRight: "5px",
    letterSpacing: "0.7px",
  },
  lock: {
    color: theme.palette.lockIcon.primary,
    marginRight: "5px",
  },
  card: {
    minWidth: 250,
    borderRadius: "1rem",
    border: `0.5px solid ${theme.palette.hr.primary}`,
    boxShadow: "none",
  },
  mainGrid: {
    justifyContent: "center",
  },
  hr: {
    opacity: "0.5",
    margin: "2.5em 20px 2.5em 60px",
    border: `0.5px solid ${theme.palette.text.whiteHeader}`,
    [theme.breakpoints.down("lg")]: {
      margin: "1.5em 0 1.5em 0",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  locationButton: {
    marginTop: "3em",
    backgroundColor: theme.palette.background.orderButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `190px`,
    height: "50px",
  },
  paper: {
    border: `0.5px solid ${theme.palette.hr.primary}`,
    padding: "32px",
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  text: {
    color: theme.palette.passwordIcon2.primary,
    fontSize: theme.typography.paragraph,
    letterSpacing: "1.5px",
    fontWeight: "700",
  },
  aMain: {
    color: theme.palette.passwordIcon.primary,
    "&:hover": {
      color: theme.palette.passwordIcon.primary,
      textDecoration: "underline",
    },
    letterSpacing: "1px",
    fontWeight: "600",
  },
}));

const PasswordResetMailLinkExpiredPage = ({ history }) => {
  const buttonHandler = (e) => {
    e.preventDefault();

    history.push("/login");
  };

  const classes = useStyles();

  const page = () => {
    return (
      <>
        <Box className={classes.root}>
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12}>
              <hr className={classes.hr} />
            </Grid>
            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
              <Box mb={2} style={{ alignItems: "center" }}>
                <Alert severity="error">
                  Przepraszamy! Link do zmiany hasła wygasł.
                </Alert>
              </Box>
              <Paper className={classes.paper}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <LockOpen style={{ marginRight: "5px", height: "25px" }} />
                  <Typography className={classes.text}>
                    Odzyskaj hasło
                  </Typography>
                </Box>

                <Box m={1}>
                  <Typography variant="subtitle1" gutterBottom>
                    <Link className={classes.aMain} to="/password-reset">
                      Kliknij tutaj
                    </Link>
                    , aby przejść do formularza odpowiadającego za wysłanie
                    linku do zmiany hasła.
                  </Typography>
                </Box>

                <Box m={1}>
                  <Link className={classes.aMain} to="/login">
                    Powrót do logowania
                  </Link>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };
  return (
    <Can
      permission="password-reset-email-link-expired:view"
      ok={page}
      not={() => {
        return <Redirect to="/" />;
      }}
    />
  );
};

export default PasswordResetMailLinkExpiredPage;
