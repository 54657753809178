import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosInstance } from "../redux/common";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Button,
  Typography,
  Paper,
  Tabs,
  Box,
  Tab,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import LoginForm from "./login";
import {
  BrowserRouter as Router,
  Route,
  Link as RouterLink,
  Switch,
  Redirect,
  useRouteMatch,
  useHistory,
  Link,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "../store";
import Loader from "../components/loader";
import OrganizationsAdminPanel from "./organizations";
import ScreeningTestsAdminPanel from "./screening-tests";
import TestStandsAdminPanel from "./test-stand";
import UsersAdminPanel from "./users";
import PackagesAdminPanel from "./packages";
import Alerts from "../components/alerts";
import { getTenantContext } from "../redux/admin/actions";
import DescriptionAssignDoctorPanel from "../pages/doctor-page/description-assign";
import DescriptionLockDoctorPanel from "../pages/doctor-page/desciption-lock";
import ResultsPerPackageTableDoctor from "../pages/doctor-page/desciption-fill";
import FinishedDescriptionsDoctorPanel from "../pages/doctor-page/finished-descriptions";
import { createMuiTheme } from "@material-ui/core";
import { lightTheme } from "../theme";
import FormsAdminPanel from "./forms";
import AddNewFormPage from "../pages/add-new-form";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import UserExaminationExecuted from "./users/patient/examinations-executed";
import TermsAdminPanel from "./terms";
import TestStandMonitoring from "./monitoring";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    minHeight: "1px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export const CenteredTabs = withStyles({
  flexContainer: {
    textAlign: "center",
    display: "inherit",
  },
})(Tabs);

function Main(props) {
  const history = useHistory();
  const location = useLocation();
  const tenantsContext = useSelector((s) => s.tenantsContext || []);
  const { path, url } = useRouteMatch();
  const [value, setValue] = useState("");
  const { userState } = props;
  const [user, setUser] = userState;
  const classes = useStyles();

  useEffect(() => {
    if (path === location?.pathname) {
      setValue(`${location?.pathname}/registration`);
    } else {
      const currentUrlGroup = routes.find((r) =>
        location?.pathname.includes(r)
      );
      setValue(currentUrlGroup);
    }
  }, [location]);

  async function logout() {
    try {
      await getAxiosInstance().post("/api/auth/logout-owner", {});
      setUser(null);
    } catch (e) {
      console.error(e);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const routes = [
    `${path}/organizations`,
    `${path}/screening-tests`,
    `${path}/test-stands`,
    `${path}/users`,
    `${path}/surveys`,
    `${path}/packages`,
    `${path}/add/survey`,
    `${path}/terms`,
  ];

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Panel administracyjny
            </Typography>
            {tenantsContext.length > 0 && (
              <Typography variant="body1" className={classes.title}>
                Kontekst organizacji:{" "}
                {tenantsContext
                  .filter((c) => c.orgName)
                  .map(({ orgName }) => orgName)
                  .join(", ")}
              </Typography>
            )}

            <Button color="inherit" onClick={logout}>
              Wyloguj się
            </Button>
          </Toolbar>{" "}
        </AppBar>
        <Paper>
          <CenteredTabs
            fullwidth
            variant="scrollable"
            value={value}
            onChange={handleChange}
            scrollButtons="auto"
          >
            <Tab
              label="Organizacje"
              value={routes[0]}
              component={Link}
              to={routes[0]}
            />

            <Tab
              label="Przesiewy"
              value={routes[1]}
              component={Link}
              to={routes[1]}
            />
            <Tab
              label="Pakiety"
              value={routes[5]}
              component={Link}
              to={routes[5]}
            />
            <Tab
              label="Stanowiska"
              value={routes[2]}
              component={Link}
              to={routes[2]}
            />
            <Tab
              label="Użytkownicy"
              value={routes[3]}
              component={Link}
              to={routes[3]}
            />
            <Tab
              label="Ankiety"
              value={routes[4]}
              component={Link}
              to={routes[4]}
            />
            <Tab
              label="Regulaminy"
              value={routes[7]}
              component={Link}
              to={routes[7]}
            />
          </CenteredTabs>
        </Paper>
      </div>
      <Box pt={0.1} className={classes.content}>
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/organizations`} />
          </Route>
          <Route
            path={`${path}/organizations`}
            component={OrganizationsAdminPanel}
          />
          <Route
            path={`${path}/screening-tests`}
            component={ScreeningTestsAdminPanel}
          />
          <Route
            path={`${path}/test-stands/monitoring/:id`}
            component={TestStandMonitoring}
          ></Route>
          <Route
            path={`${path}/test-stands`}
            component={TestStandsAdminPanel}
          ></Route>
          <Route
            path={`${path}/packages`}
            component={PackagesAdminPanel}
          ></Route>
          <Route
            path={`${path}/users/examinations-executed/user/:id`}
            component={UserExaminationExecuted}
          ></Route>
          <Route path={`${path}/users`} component={UsersAdminPanel}></Route>
          <Route
            path={`${path}/surveys/add`}
            component={AddNewFormPage}
          ></Route>
          <Route
            path={`${path}/surveys/edit/:id`}
            component={AddNewFormPage}
          ></Route>
          <Route
            path={`${path}/surveys/display/:id`}
            component={AddNewFormPage}
          ></Route>
          <Route path={`${path}/surveys`} component={FormsAdminPanel}></Route>{" "}
          <Route path={`${path}/terms`} component={TermsAdminPanel}></Route>
          <Route path="*">
            <div>No match!!!</div>
          </Route>
        </Switch>
      </Box>
    </DndProvider>
  );
}
function MainDoctor(props) {
  const history = useHistory();
  const location = useLocation();
  const tenantsContext = useSelector((s) => s.tenantsContext || []);
  const { path, url } = useRouteMatch();
  const [value, setValue] = useState("");
  const [docName, setDocName] = useState("");
  const { userState } = props;
  const [user, setUser] = userState;
  const classes = useStyles();

  useEffect(() => {
    if (path === location?.pathname) {
      setValue(`${location?.pathname}/registration`);
    } else {
      !location?.pathname.endsWith("/description-fill") &&
        setValue(location?.pathname);
    }
  }, [location]);

  useEffect(() => {
    async function fetchMedicName() {
      const response = await getAxiosInstance().get("/medic/v1/auth");
      const { name } = response.data;
      setDocName(name);
    }

    if (user?.roles?.includes("SystemConsultant")) {
      fetchMedicName();
    }
  }, [user]);

  async function logout() {
    try {
      await getAxiosInstance().post("/api/auth/logout-owner", {});
      setUser(null);
    } catch (e) {
      console.error(e);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const routes = [
    `${path}/examinations-to-describe`,
    `${path}/examinations-described`,
    `${path}/descriptions-finished`,
  ];

  return (
    <>
      <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                {`Panel Lekarza: ${docName}`}
              </Typography>

              <Button color="inherit" onClick={logout}>
                Wyloguj się
              </Button>
            </Toolbar>{" "}
          </AppBar>
          <Paper>
            <CenteredTabs
              fullwidth
              variant="scrollable"
              value={value}
              onChange={handleChange}
              scrollButtons="auto"
            >
              <Tab
                label="Dostępne opisy"
                value={routes[0]}
                component={Link}
                to={routes[0]}
              />

              <Tab
                label="Opisy"
                value={routes[1]}
                component={Link}
                to={routes[1]}
              />
              <Tab
                label="Wykonane opisy"
                value={routes[2]}
                component={Link}
                to={routes[2]}
              />
            </CenteredTabs>
          </Paper>
        </div>
        <Box pt={0.1} className={classes.content}>
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${path}/examinations-to-describe`} />
            </Route>
            <Route
              path={`${path}/examinations-to-describe`}
              component={DescriptionAssignDoctorPanel}
            ></Route>
            <Route
              path={`${path}/examinations-described`}
              component={DescriptionLockDoctorPanel}
            ></Route>

            <Route
              path={`${path}/description-fill`}
              component={ResultsPerPackageTableDoctor}
            ></Route>
            <Route
              path={`${path}/descriptions-finished`}
              component={FinishedDescriptionsDoctorPanel}
            ></Route>

            <Route path="*">
              <div>No match!!!</div>
            </Route>
          </Switch>
        </Box>
      </ThemeProvider>
    </>
  );
}

function Protected(props) {
  const { userState } = props;
  const [user, setUser] = userState;
  const [userRoles, setUserRoles] = useState([]);

  const dispatch = useDispatch();

  async function checkLogin() {
    if (user === undefined) {
      try {
        const authResult = await getAxiosInstance().get(
          "/api/auth/check-owner"
        );

        await dispatch(getTenantContext());
        setUser(authResult.data);
        setUserRoles(authResult.data.roles);
      } catch (e) {
        setUser(null);
      }
    }
  }

  useEffect(() => {
    checkLogin();
  });

  if (user === undefined) {
    return <Loader loading={true} text="Uwierzytelnianie" />;
  } else if (user === null) {
    return <Redirect to="/admin-panel/login" />;
  } else {
    const isDoctor = user.roles.includes("SystemConsultant");

    return isDoctor ? (
      <MainDoctor userState={userState} />
    ) : (
      <Main userState={userState} />
    );
  }
}

function SuperAdminApp() {
  const userState = useState();
  const [user, setUser] = userState;

  const [theme, setTheme] = useState(createMuiTheme({}));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <Route exact path="/admin-panel/login">
            <LoginForm onUserChange={setUser} />
          </Route>

          <Provider store={store}>
            <Route path="/admin-panel">
              <Protected userState={userState} />
            </Route>
            <Route path="" component={Alerts} />
          </Provider>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default SuperAdminApp;
