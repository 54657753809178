import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { Container, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  colorText: {
    color: theme.palette.text.whiteHeader,
  },
}));

export const AccountNotActivatedPage = ({
  history,
  location,
  params,
  match,
  ...props
}) => {
  const roles = useSelector((s) => s.roles || null);
  const classes = useStyles();
  if (match.params.param === "already") {
    history.push("/");
  }

  useEffect(() => {
    if (!match.params.param && roles) {
      setTimeout(() => {
        if (roles.indexOf("GUEST") === -1) {
          history.push("/");
        } else {
          history.push("/login");
        }
      }, 5000);
    }
  });

  return (
    <Container fixed>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "80vh" }}
      >
        <h1
          aria-hidden="false"
          aria-label="Aktywacja konta"
          style={{ display: "none" }}
        >
          Aktywacja konta
        </h1>
        <Grid item xs={12}>
          {match.params.param === "not" && (
            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              style={{ color: "white" }}
            >
              Problem z aktywacją konta,
              <br />
              prosimy spróbować ponownie
            </Typography>
          )}
          {!match.params.param && (
            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              className={roles.length > 0 && classes.colorText}
              style={{ color: roles.length === 0 && "white" }}
            >
              Coś poszło nie tak,
              <br />
              konto nie zostało aktywowane
            </Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
