import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { getAxiosInstance } from "../../redux/common";
import Can from "../../components/can";
import FilterProperty, {
  FilterPropertyDate,
  FilterPropertySelect,
} from "../../components/filter-property/filter-property";
import Loader from "../../components/loader";
import {
  Box,
  Button,
  ButtonGroup,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import QRCode from "qrcode";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    padding: "1.5rem",
    marginTop: 10,
  },
  spacing: {
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
    boxShadow: "none",
  },
}));
const testTypeMap = {
  Free: "Darmowy",
  Commercial: "Komercyjny",
};

const OrgAdminScreeningPage = () => {
  const my = useSelector((s) => s.my);

  const classes = useStyles();
  const [fetchingScreeningTest, setFetchingScreeningTest] = useState(false);

  const [screeningTests, setScreeningTests] = useState([]);
  const [unfilteredScreeningTests, setUnfilteredScreeningTests] = useState([]);

  const [testStands, setTestStands] = useState([]);
  const [fetchingTestStands, setFetchingTestStands] = useState(false);

  const [packages, setPackages] = useState([]);
  const [fetchingPackages, setFetchingPackages] = useState(false);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("ts");
  const [filterQueries, setFilterQueries] = useState({});
  const [filterScreeningStatus, setFilterScreeningStatus] = useState("any");

  const [displaySearchButton, setDisplaySearchButton] = useState(false);

  useEffect(() => {
    if (my.tenantId) {
      loadScreeningTests(my.tenantId);
      loadPackages();
      loadTestStands();
    }
    if (!my) {
      window.location.reload();
    }
  }, [my]);

  useEffect(() => {
    const arrayScreening = [...screeningTests];
    const sortParam = `${order === "desc" ? "-" : ""}${orderBy}`;
    if (orderBy == "quantity" || orderBy == "completedScreenings") {
      setScreeningTests(
        arrayScreening.sort((a, b) =>
          order === "desc" ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy]
        )
      );
    } else {
      setScreeningTests(arrayScreening.sort(dynamicSort(sortParam)));
    }
  }, [order, orderBy]);

  useEffect(() => {
    const result = unfilteredScreeningTests.filter(search, filterQueries);
    setScreeningTests(result);
    setDisplaySearchButton(true);
  }, [filterQueries]);

  const clearAllFilterHandler = () => {
    setFilterQueries({});
    setDisplaySearchButton(false);
    const result = unfilteredScreeningTests.sort(
      dynamicSort(`${order === "desc" ? "-" : ""}${orderBy}`)
    );
    setScreeningTests(result);
  };

  function search(screening) {
    return Object.keys(this).every((key) => {
      if (
        key !== "hearBoxStations" &&
        key !== "screeningType" &&
        key !== "examinationPackages" &&
        key !== "quantity"
      ) {
        return screening[key]
          .toString()
          .toLowerCase()
          .includes(this[key].toLowerCase());
      } else if (key === "hearBoxStations") {
        const tstands = testStands.filter((ts) =>
          ts.label.toLowerCase().includes(this[key].toLowerCase())
        );
        return this[key]
          ? screening[key].filter((x) => tstands.some((y) => y.hearBoxId === x))
              .length > 0
          : screening;
      } else if (key === "examinationPackages") {
        const pckges = packages.filter((p) =>
          p.label.toLowerCase().includes(this[key].toLowerCase())
        );
        return this[key]
          ? screening[key].filter((x) =>
              pckges.some((y) => y.hearBoxTestId === x)
            ).length > 0
          : screening;
      } else if (key === "screeningType") {
        return screening[key] === this[key];
      } else if (key === "quantity") {
        if (parseInt(this[key])) {
          return screening[key]
            .toString()
            .toLowerCase()
            .includes(this[key].toLowerCase());
        } else {
          if (new String("<bez limitu>").includes(this[key].toLowerCase())) {
            return screening[key] == 0;
          }
        }
      }
    });
  }

  const loadScreeningTests = async (tenantId) => {
    try {
      setFetchingScreeningTest(true);
      const response = await getAxiosInstance().get("/screening/v1/tenant");
      await getAxiosInstance().get("/screening/v1/tenant/hearbox/station/all");
      const responseVouchers = await getAxiosInstance().get("/api/voucher");
      const sortParam = `${order === "desc" ? "-" : ""}${
        orderBy === "ts" ? "ts" : orderBy
      }`;
      let arrayScreening = [
        ...response.data
          .filter((screeningTest) => {
            const screeningStartDate = new Date(screeningTest.startDate);
            screeningStartDate.setHours(0, 0, 0);
            const screeningEndDate = new Date(screeningTest.endDate);
            screeningEndDate.setHours(23, 59, 59);
            if (filterScreeningStatus === "inProgress") {
              return (
                screeningStartDate < new Date() && screeningEndDate > new Date()
              );
            } else if (filterScreeningStatus === "finished") {
              return screeningEndDate < new Date();
            } else if (filterScreeningStatus === "future") {
              return screeningStartDate > new Date();
            } else {
              return screeningTest;
            }
          })
          .map((t1) => ({
            ...t1,
            ...responseVouchers.data.find(
              (t2) => t2.screeningId === t1.screeningId
            ),
            ...(!t1?.quantity && { quantity: 0 }),
          })),
      ];
      setScreeningTests(arrayScreening.sort(dynamicSort(sortParam)));
      setUnfilteredScreeningTests(arrayScreening);
      setFetchingScreeningTest(false);
    } catch (e) {
      setFetchingScreeningTest(false);
      //   setScreeningTests([]);
    }
  };

  const loadPackages = async () => {
    try {
      setFetchingPackages(true);
      const response = await getAxiosInstance().get("/api/examination_package");
      setFetchingPackages(false);
      setPackages(response.data);
    } catch (e) {
      setFetchingPackages(false);
      setPackages([]);
    }
  };

  const loadTestStands = async () => {
    try {
      setFetchingTestStands(true);
      const response = await getAxiosInstance().get(
        "/screening/v1/tenant/hearbox/station/all"
      );
      setFetchingTestStands(false);
      const testStand = response.data;
      setTestStands(testStand);
    } catch (e) {
      setFetchingTestStands(false);
      setTestStands([]);
    }
  };

  const dynamicSort = (property) => {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      if (property)
        var result =
          a[property].toString().toLowerCase() <
          b[property].toString().toLowerCase()
            ? -1
            : a[property].toString().toLowerCase() >
              b[property].toString().toLowerCase()
            ? 1
            : 0;
      return result * sortOrder;
    };
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const qrLinkGenerate = async (screeningId) => {
    try {
      const response = await getAxiosInstance().post("/api/voucher/admin", {
        screeningId,
      });
      if (response) {
        loadScreeningTests();
      }
    } catch (e) {
      if (e.response.data.message.includes("already-exists")) {
        window.location.reload();
      }
      console.log("error", e);
    }
  };

  const qrLinkDownload = async (screening) => {
    try {
      if (screening.voucherId) {
        const domain =
          window.location.hostname === "localhost"
            ? `${window.location.hostname}:${window.location.port}`
            : window.location.hostname;
        const link = `https://${domain}/voucher/${screening.voucherId}`;
        const res = await QRCode.toDataURL(link);
        const downloadLink = document.createElement("a");
        downloadLink.href = res;
        downloadLink.download = "qr link";
        downloadLink.click();
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const headerCells = [
    {
      title: "Przesiew",
      key: "label",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Stanowiska",
      key: "hearBoxStations",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Pakiety",
      key: "examinationPackages",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Rodzaj",
      key: "screeningType",
      sortable: true,
      filter: (key) => (
        <FilterPropertySelect
          label={"Rodzaj"}
          listItems={[
            { value: "Free", label: "Darmowy" },
            { value: "Commercial", label: "Komercyjny" },
          ]}
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Data rozpoczęcia",
      key: "startDate",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Data zakończenia",
      key: "endDate",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Liczba maksymalna",
      key: "quantity",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Liczba zrealizowanych",
      key: "completedScreenings",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Opcje",
      key: "options",
      sortable: false,
      filter: (key) => (
        <Box>
          {Object.keys(filterQueries).length !== 0 && (
            <Button
              color="primary"
              // size="large"
              startIcon={<ClearIcon />}
              onClick={clearAllFilterHandler}
            >
              <Tooltip title="Czyści wszystkie pola filtracji">
                <span>Wyczyść</span>
              </Tooltip>
            </Button>
          )}
        </Box>
      ),
    },
  ];

  const page = () =>
    fetchingScreeningTest || fetchingTestStands || fetchingPackages ? (
      <Loader loading={true} text="Wczytywanie przesiewów" />
    ) : (
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="Test stands list">
          <TableHead>
            <TableRow>
              {headerCells.map((headCell) =>
                headCell.sortable ? (
                  <TableCell
                    key={headCell.key}

                    // sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.key}
                      direction={orderBy === headCell.key ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.key);
                      }}
                    >
                      {headCell.title}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell>{headCell.title}</TableCell>
                )
              )}
            </TableRow>
            <TableRow>
              {headerCells.map((headCell) => (
                <TableCell key={headCell.key}>
                  {headCell.filter(headCell.key)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {screeningTests.map((screening) => (
              <TableRow>
                <TableCell>{screening.label || "---"}</TableCell>
                <TableCell>
                  {testStands
                    .filter((t) =>
                      screening.hearBoxStations.includes(t.hearBoxId)
                    )
                    .map((testStand) => (
                      <p
                        style={{
                          borderBottom:
                            testStands.filter((t) =>
                              screening.hearBoxStations.includes(t.hearBoxId)
                            ).length > 1 && "0.5px solid #d9d9d9",
                        }}
                      >
                        {testStand.label}
                      </p>
                    )) || "---"}
                </TableCell>
                <TableCell>
                  {packages
                    .filter((p) =>
                      screening.examinationPackages.includes(p.hearBoxTestId)
                    )
                    .map((packg) => (
                      <p
                        style={{
                          borderBottom:
                            packages.filter((p) =>
                              screening.examinationPackages.includes(
                                p.hearBoxTestId
                              )
                            ).length > 1 && "0.5px solid #d9d9d9",
                        }}
                      >
                        {packg.label}
                      </p>
                    )) || "---"}
                </TableCell>
                <TableCell>
                  {testTypeMap[screening.screeningType] || "---"}
                </TableCell>
                <TableCell>{screening.startDate || "---"}</TableCell>
                <TableCell>{screening.endDate || "---"}</TableCell>
                <TableCell>{screening.quantity || "<bez limitu>"}</TableCell>
                <TableCell>{screening.completedScreenings}</TableCell>
                <TableCell>
                  <ButtonGroup
                    className={classes.spacing}
                    variant="contained"
                    color="primary"
                    aria-label="contained primary button group"
                  >
                    {/* <Button variant={"contained"} color="primary">
                      Statystyki
                    </Button> */}

                    {screening.screeningType === "Free" && (
                      <Tooltip
                        title={
                          screening.voucherId
                            ? `Kod: ${screening.voucherId}`
                            : "Naciśnij przycisk, aby wygenerować kod"
                        }
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            screening.voucherId
                              ? qrLinkDownload(screening)
                              : qrLinkGenerate(screening.screeningId)
                          }
                        >
                          <span style={{ whiteSpace: "nowrap" }}>
                            {screening.voucherId
                              ? `Pobierz QR LINK`
                              : `Generuj QR Link`}
                          </span>
                        </Button>
                      </Tooltip>
                    )}
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );

  const redirect = () => <Redirect to="/" />;

  return <Can permission="admin-screening:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgAdminScreeningPage);
