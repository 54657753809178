import React, { useEffect, useRef, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { QRScanner } from "../../components/qr-reader";
import Cookies from "js-cookie";
import { logout, saveQRCode } from "../../redux/actions";
import Loader from "../../components/loader";
import VoucherCard from "../../components/voucher-card";
import { TestKitsAmount } from "../../components/test-kit/testAmount";
import { fetchPriceList } from "../../redux/shopping-cart/actions";
import { getAxiosInstance } from "../../redux/common";
import VoucherCardModal from "../../components/voucher-card";

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "16px",
    justifyContent: "center",
  },
  typography: {
    color: theme.palette.text.alternative3,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mainBox: {
    textAlign: "center",
    height: "100%",
  },
  titleBox: {
    backgroundColor: theme.palette.background.filter,
    [theme.breakpoints.up("md")]: {
      height: "27%",
    },
  },
  title: {
    color: theme.palette.text.alternative2,
    letterSpacing: "0.9px",
    fontWeight: "700",
    fontSize: theme.typography.text,
    lineHeight: "24px",
    textTransform: "uppercase",
  },
  subtitle: {
    padding: "0px 16px 16px 16px",
    color: theme.palette.text.hintText,
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
  },
  root: {
    minWidth: 250,
    borderRadius: "0px",
    // border: `0.5px solid ${theme.palette.hr.primary}`,
    boxShadow: "13px 18px 29px -9px rgb(82 82 92)",
    // [theme.breakpoints.down("lg")]: {
    //   padding: `15px`,
    // },
    [theme.breakpoints.down("md")]: {
      padding: `0px`,
    },
  },
  cardContent: {
    padding: "0px",
    // [theme.breakpoints.down("lg")]: {
    //   padding: `5px`,
    // },
  },
  button: {
    width: "267px",
    height: "50px",
    borderRadius: "0px",
  },
  title: {
    color: theme.palette.text.alternative2,
    letterSpacing: "0.9px",
    fontWeight: "700",
    fontSize: theme.typography.text,
    lineHeight: "24px",
  },

  desc: {
    color: theme.palette.text.alternative3,
    letterSpacing: "0.55px",
    fontWeight: "700",
    fontSize: theme.typography.formLabel,
    lineHeight: "30px",
  },
}));

const ShopProductsPage = ({
  match: {
    params: { voucherID },
  },
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [code, setCode] = useState(null);
  const [codeTyped, setCodeTyped] = useState("");
  const [openVoucherCard, setOpenVoucherCard] = useState(false);
  const [qrCodeValid, setQrCodeValid] = useState(false);

  const [productsToBuy, setProductsToBuy] = useState([]);
  const [fetchingProducts, setFetchingProducts] = useState("true");

  const [dataChanged, setDataChanged] = useState(false);

  const products = useSelector((s) => s.products);
  const [productPackages, setProductPackages] = useState([]);

  const userLoggedIn = useSelector((s) => s.loggedIn);

  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [openTypeCodePanel, setOpenTypeCodePanel] = useState(false);

  useEffect(() => {
    if (codeTyped) {
      setCodeTyped("");
    }
    const parser = document.createElement("a");
    parser.href = code;
    const readedCode = parser.href.split("/").pop();
    if (readedCode.length === 6) {
      Cookies.set("code", readedCode);
      setQrCodeValid(true);
    } else {
      setQrCodeValid(false);
    }
  }, [code]);

  useEffect(() => {
    if (voucherID) {
      setOpenTypeCodePanel(true);
      setOpenVoucherCard(true);
      setCodeTyped(voucherID);
    }
  }, [voucherID]);

  useEffect(() => {
    if (code) {
      setCode("");
    }
    if (codeTyped.length === 6) {
      Cookies.set("code", codeTyped);
      setQrCodeValid(true);
    } else {
      setQrCodeValid(false);
    }
  }, [codeTyped]);

  useEffect(() => {
    setFetchingProducts(true);
    if (products.length === 0) {
      dispatch(fetchPriceList());
    } else {
      fetchPackages();
    }
  }, [products]);

  const fetchPackages = async () => {
    let hearBoxTestArray = [];

    setProductsToBuy(
      products.map((p) => ({
        hearBoxTestId: p.hearBoxTestId,
        amount: 0,
        doctorDescription: {
          append: false,
        },
      }))
    );
    for (let i = 0; i < products.length; i++) {
      const result = await getAxiosInstance().get(
        `/api/examination_package/${products[i].hearBoxTestId}`
      );
      hearBoxTestArray.push({ ...result.data, ...products[i] });
    }
    setProductPackages(hearBoxTestArray);
    setFetchingProducts(false);
  };

  const changeCode = (data) => {
    const parser = document.createElement("a");
    parser.href = data;
    setCode(parser.href.split("/").pop());
  };

  return fetchingProducts ? (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Grid item xs={12}>
        <Box p={1}>
          <Loader loading={true} text="Pobieranie listy produktów..." />
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Grid item xs={12} container className={classes.mainGrid}>
      <Grid container item xs={12} xl={10} spacing={mediumScreen ? 1 : 5}>
        <TestKitsAmount
          productsToBuy={productsToBuy}
          setProductsToBuy={setProductsToBuy}
          setDataChanged={setDataChanged}
          products={productPackages}
        />{" "}
        <Grid item xs={12} md={6} lg={4}>
          <Card className={classes.root}>
            <CardContent
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
              }}
              className={classes.cardContent}
            >
              <Box className={classes.mainBox}>
                <Box className={classes.titleBox}>
                  <Box p={3} mb={3}>
                    <Typography className={classes.title}>
                      {`MAM VOUCHER`}
                    </Typography>
                  </Box>
                  {/* <Box mb={3}>
                    <Typography className={classes.subtitle}>
                      {`Pakiet służący do przeprowadzenia badania capd`}
                    </Typography>
                  </Box> */}
                </Box>

                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenVoucherCard(true)}
                    className={classes.button}
                  >
                    WCZYTAJ KOD QR
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
          {openVoucherCard && (
            <VoucherCardModal
              codeTyped={codeTyped}
              setCodeTyped={setCodeTyped}
              code={code}
              open={openVoucherCard}
              changeCode={changeCode}
              setCode={setCode}
              handleClose={() => setOpenVoucherCard(false)}
              qrCodeValid={qrCodeValid}
              openTypeCodePanel={openTypeCodePanel}
              setOpenTypeCodePanel={setOpenTypeCodePanel}
            />
          )}
        </Grid>
        <Prompt
          when={dataChanged}
          message={(params) =>
            params.pathname !== window.location.pathname && dataChanged
              ? "Wprowadzone dane nie zostały zapisane. Czy na pewno chcesz opuścić stronę?"
              : true
          }
        ></Prompt>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShopProductsPage);
