import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader";
import { getAxiosInstance } from "../../redux/common";
import {
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
  TableBody,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Tooltip,
} from "@material-ui/core";
import ScreeningTestRow from "./screening-test-row";
import ScreeningTestModal from "./modal/ScreeningTestModal";
import FilterProperty from "../../components/filter-property/filter-property";
import { FilterPropertySelect } from "../../components/filter-property/filter-property";
import { FilterPropertyDate } from "../../components/filter-property/filter-property";
import Alert from "@material-ui/lab/Alert";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";
import PriceListModal from "./modal/PriceListModal";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { fetchForms } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  table: {
    minWidth: 650,
  },
  container: {
    padding: "1.5rem",
    marginTop: 10,
    maxHeight: "calc(100% - 66px)",
  },
  stickyRow: {
    position: "sticky",
    top: "0px",
    background: "white",
    zIndex: "100",
  },
  tableContainer: {
    flexGrow: 1,
    minHeight: "1px",
  },
}));

const statusMap = {
  any: "Dowolny",
  finished: "Zakończony",
  future: "Zaplanowany",
  inProgress: "W trakcie",
};

const ScreeningTestsAdminPanel = () => {
  const tenantsContext = useSelector((s) => s.tenantsContext || "");

  const [screeningTests, setScreeningTests] = useState([]);
  const [unfilteredScreeningTests, setUnfilteredScreeningTests] = useState([]);
  const [missingPricelist, setMissingPricelist] = useState([]);

  const [screeningTestCount, setScreeningTestCount] = useState(0);
  const [fetchingScreeningTest, setFetchingScreeningTest] = useState(false);
  const [removingScreeningTest, setRemovingScreeningTest] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("ts");

  const [openModal, setOpenModal] = useState(false);
  const [openPriceListPanel, setOpenPriceListPanel] = useState(false);
  const [screeningTestsHandlerType, setScreeningTestsHandlerType] = useState(
    "edition"
  );

  const [filterQueries, setFilterQueries] = useState({});
  const [filterScreeningStatus, setFilterScreeningStatus] = useState("any");
  const [screeningTestToEdit, setScreeningTestToEdit] = useState({});
  const [screeningTestToRemove, setScreeningTestToRemove] = useState({});

  const [organizations, setOrganizations] = useState(null);
  const [fetchingOrganizations, setFetchingOrganizations] = useState(false);

  const [testStands, setTestStands] = useState([]);
  const [fetchingTestStands, setFetchingTestStands] = useState(false);

  const [packages, setPackages] = useState([]);
  const [fetchingPackages, setFetchingPackages] = useState(false);

  const forms = useSelector((s) => s.orgForms);

  const [savedScreeningTest, setSavedScreeningTest] = useState(false);
  const [additionCompleted, setAdditionCompleted] = useState(false);

  const [
    openScreeningTestRemovePanel,
    setOpenScreeningTestRemovePanel,
  ] = useState(false);
  const [displaySearchButton, setDisplaySearchButton] = useState(false);

  const headerCells = [
    {
      title: "Przesiew",
      key: "label",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    // {
    //   title: "Lokalizacja",
    //   key: "location",
    //   sortable: true,
    //   filter: (key) => (
    //     <FilterProperty
    //       filterProperty={key}
    //       setFilterQueries={setFilterQueries}
    //       filterQueries={filterQueries}
    //     />
    //   ),
    // },
    {
      title: "Organizacja",
      key: "organization",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Stanowiska",
      key: "hearBoxStations",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Pakiety",
      key: "examinationPackages",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Rodzaj",
      key: "screeningType",
      sortable: true,
      filter: (key) => (
        <FilterPropertySelect
          label={"Rodzaj"}
          listItems={[
            { value: "Free", label: "Darmowy" },
            { value: "Commercial", label: "Komercyjny" },
          ]}
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Data rozpoczęcia",
      key: "startDate",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Data zakończenia",
      key: "endDate",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Liczba maksymalna",
      key: "quantity",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Liczba zrealizowanych",
      key: "completedScreenings",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Opcje",
      key: "options",
      sortable: false,

      filter: (key) => (
        <Box>
          {displaySearchButton && (
            <Button
              color="primary"
              // size="large"
              startIcon={<SearchIcon />}
              onClick={searchFilterHandler}
            >
              Szukaj
            </Button>
          )}
          {Object.keys(filterQueries).length !== 0 && (
            <Button
              color="primary"
              // size="large"
              startIcon={<ClearIcon />}
              onClick={clearAllFilterHandler}
            >
              <Tooltip title="Czyści wszystkie pola filtracji">
                <span>Wyczyść</span>
              </Tooltip>
            </Button>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    loadOrganizations();
    loadPackages();
    dispatch(fetchForms({ page: 0, pageSize: 10000 }));
  }, []);

  useEffect(() => {
    if (organizations) {
      loadScreeningTests();
      loadTestStands();
    }
  }, [organizations]);

  useEffect(() => {
    if (savedScreeningTest) {
      loadOrganizations();
      loadScreeningTests();
      loadPackages();
    }
  }, [savedScreeningTest]);

  useEffect(() => {
    if (filterScreeningStatus) {
      loadScreeningTests();
    }
  }, [filterScreeningStatus]);

  const loadOrganizations = async () => {
    try {
      setFetchingOrganizations(true);
      const response = await getAxiosInstance().get("/api/organizations", {
        params: {
          pageSize: 100,
          ...(tenantsContext.length > 0 && {
            tenantIds: tenantsContext.map((org) => org.tenantId).join(","),
          }),
        },
      });

      let orgs = response.data.content;
      setFetchingOrganizations(false);
      setOrganizations(orgs);
    } catch (e) {
      setFetchingOrganizations(false);
      setOrganizations([]);
    }
  };
  const loadPackages = async () => {
    try {
      setFetchingPackages(true);
      const response = await getAxiosInstance().get("/api/examination_package");
      setFetchingPackages(false);
      setPackages(response.data);
    } catch (e) {
      setFetchingPackages(false);
      setPackages([]);
    }
  };

  const loadTestStands = async () => {
    try {
      setFetchingTestStands(true);
      const response = await getAxiosInstance().get(
        "/screening/v1/hearbox/station/all"
      );
      setFetchingTestStands(false);
      const testStand = response.data;
      setTestStands(testStand);
    } catch (e) {
      setFetchingTestStands(false);
      setTestStands([]);
    }
  };

  const screeningTestRemoveHandler = async () => {
    try {
      const domain =
        window.location.hostname === "localhost"
          ? `${window.location.hostname}:${window.location.port}`
          : window.location.hostname;

      const secure = window.location.hostname === "localhost" ? `` : "s";
      let socket = new WebSocket(
        `ws${secure}://${domain}/screening/v1/owner/websocket/connect`
      );
      socket.onopen = async function (e) {
        setRemovingScreeningTest(true);
        const response = await getAxiosInstance().delete(
          "/screening/v1/owner",
          {
            params: {
              tenantId: screeningTestToRemove.tenantId,
              screeningId: screeningTestToRemove.screeningId,
            },
          }
        );
      };

      socket.onmessage = function (event) {
        setRemovingScreeningTest(false);
        setScreeningTestToRemove({});
        loadScreeningTests();
        socket.close();
      };
    } catch (e) {
      setRemovingScreeningTest(false);
      console.log(e);
    }
  };

  useEffect(() => {
    setDisplaySearchButton(true);
  }, [filterQueries]);

  const searchFilterHandler = () => {
    setDisplaySearchButton(false);
    const result = unfilteredScreeningTests
      .sort(dynamicSort(`${order === "desc" ? "-" : ""}${orderBy}`))
      .filter(search, filterQueries);
    setScreeningTests(result);
  };

  const clearAllFilterHandler = () => {
    setFilterQueries({});
    setDisplaySearchButton(false);
    const result = unfilteredScreeningTests.sort(
      dynamicSort(`${order === "desc" ? "-" : ""}${orderBy}`)
    );
    setScreeningTests(result);
  };

  function search(screening) {
    return Object.keys(this).every((key) => {
      if (
        key !== "hearBoxStations" &&
        key !== "screeningType" &&
        key !== "examinationPackages" &&
        key !== "quantity"
      ) {
        return screening[key]
          .toString()
          .toLowerCase()
          .includes(this[key].toLowerCase());
      } else if (key === "hearBoxStations") {
        const tstands = testStands.filter((ts) =>
          ts.label.toLowerCase().includes(this[key].toLowerCase())
        );
        return this[key]
          ? screening[key].filter((x) => tstands.some((y) => y.hearBoxId === x))
              .length > 0
          : screening;
      } else if (key === "examinationPackages") {
        const pckges = packages.filter((p) =>
          p.label.toLowerCase().includes(this[key].toLowerCase())
        );
        return this[key]
          ? screening[key].filter((x) =>
              pckges.some((y) => y.hearBoxTestId === x)
            ).length > 0
          : screening;
      } else if (key === "screeningType") {
        // const parsed = JSON.parse(this[key]);
        // return screening[key] === parsed.value;
        return screening[key] === this[key];
      } else if (key === "quantity") {
        if (parseInt(this[key])) {
          return screening[key]
            .toString()
            .toLowerCase()
            .includes(this[key].toLowerCase());
        } else {
          if (new String("<bez limitu>").includes(this[key].toLowerCase())) {
            return screening[key] == 0;
          }
        }
      }
    });
  }

  useEffect(() => {
    const arrayScreening = [...screeningTests];
    const sortParam = `${order === "desc" ? "-" : ""}${orderBy}`;
    setScreeningTests(arrayScreening.sort(dynamicSort(sortParam)));
  }, [order]);

  useEffect(() => {
    if (additionCompleted) {
      loadOrganizations();
    }
  }, [additionCompleted]);

  const dynamicSort = (property) => {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    if (property != "quantity" && property != "completedScreenings") {
      return function (a, b) {
        var result =
          a[property].toLowerCase() < b[property].toLowerCase()
            ? -1
            : a[property].toLowerCase() > b[property].toLowerCase()
            ? 1
            : 0;
        return result * sortOrder;
      };
    } else if (property == "completedScreenings" || property == "quantity") {
      return function (a, b) {
        var result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    }
  };

  const loadScreeningTests = async () => {
    try {
      setFetchingScreeningTest(true);
      const response = await getAxiosInstance().get("/screening/v1/owner/all", {
        params: {
          ...(tenantsContext.length > 0 && {
            tenantIds: tenantsContext.map((org) => org.tenantId).join(","),
          }),
        },
      });

      const responseVouchers = await getAxiosInstance().get(
        "/api/voucher/all",
        {
          params: {
            ...(tenantsContext.length > 0 && {
              tenantIds: tenantsContext.map((org) => org.tenantId).join(","),
            }),
          },
        }
      );
      const sortParam = `${order === "desc" ? "-" : ""}${
        orderBy === "ts" ? "ts" : orderBy
      }`;
      let arrayScreening = [
        ...response.data
          .filter((screeningTest) => {
            const screeningStartDate = new Date(screeningTest.startDate);
            screeningStartDate.setHours(0, 0, 0);
            const screeningEndDate = new Date(screeningTest.endDate);
            screeningEndDate.setHours(23, 59, 59);
            if (filterScreeningStatus === "inProgress") {
              return (
                screeningStartDate < new Date() && screeningEndDate > new Date()
              );
            } else if (filterScreeningStatus === "finished") {
              return screeningEndDate < new Date();
            } else if (filterScreeningStatus === "future") {
              return screeningStartDate > new Date();
            } else {
              return screeningTest;
            }
          })
          .map((t1) => ({
            ...t1,
            obligatoryForms: [],
            optionalFormsForms: [],
            ...responseVouchers.data.find(
              (t2) => t2.screeningId === t1.screeningId
            ),
            ...(!t1?.quantity && { quantity: 0 }),
            ...(organizations.find((t2) => t2.tenantId === t1.tenantId) && {
              organization: organizations.find(
                (t2) => t2.tenantId === t1.tenantId
              ).orgName,
            }),
          })),
      ];
      const responseMissingPriceList = await getAxiosInstance().get(
        "/api/product_price/owner/packages_with_missing_prices"
      );
      setMissingPricelist(responseMissingPriceList.data);
      setScreeningTests(arrayScreening.sort(dynamicSort(sortParam)));
      setUnfilteredScreeningTests(arrayScreening);
      setFetchingScreeningTest(false);
    } catch (e) {
      setFetchingScreeningTest(false);
      setScreeningTests([]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const additionButtonHandler = () => {
    setScreeningTestsHandlerType("add");
    setOpenModal(true);
  };

  return fetchingScreeningTest ||
    fetchingOrganizations ||
    fetchingTestStands ||
    fetchingPackages ||
    removingScreeningTest ? (
    <Loader loading={true} text="Pobieranie przesiewów" />
  ) : (
    <Box className={classes.rootContainer}>
      <Box mt={1} display="flex" alignItems="center">
        <Button variant={"contained"} onClick={additionButtonHandler}>
          Dodaj nowy przesiew
        </Button>{" "}
        <FormControl
          variant="outlined"
          style={{ width: "15vw", marginLeft: "10px" }}
        >
          <InputLabel id="open-role-filter-label">Status przesiewu</InputLabel>
          <Select
            labelId="open-role-filter-label"
            id="open-role-filter"
            label={"Status przesiewu"}
            onChange={(e) => {
              setFilterScreeningStatus(e.target.value);
            }}
            value={filterScreeningStatus}
          >
            <MenuItem value="any">Dowolny</MenuItem>
            <MenuItem value="finished">Zakończony</MenuItem>
            <MenuItem value="future">Zaplanowany</MenuItem>
            <MenuItem value="inProgress">W trakcie</MenuItem>
          </Select>
        </FormControl>{" "}
      </Box>
      <Box mt={1} style={{ width: "15vw" }}></Box>
      {/* {additionCompleted && <Alert>Organizacja została dodana</Alert>} */}
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="Test stands list">
          <TableHead>
            <TableRow className={classes.stickyRow}>
              {headerCells.map((headCell) =>
                headCell.sortable ? (
                  <TableCell
                    key={headCell.key}

                    // sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <Tooltip title="Sortuj">
                      <TableSortLabel
                        active={orderBy === headCell.key}
                        direction={orderBy === headCell.key ? order : "asc"}
                        onClick={() => {
                          handleSortRequest(headCell.key);
                        }}
                      >
                        {headCell.title}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ) : (
                  <TableCell>{headCell.title}</TableCell>
                )
              )}
            </TableRow>
            <TableRow>
              {headerCells.map((headCell) => (
                <TableCell key={headCell.key}>
                  {headCell.filter(headCell.key)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {screeningTests.map((screeningTest, index) => (
              <ScreeningTestRow
                key={index}
                screeningTestData={screeningTest}
                screeningTestToRemove={screeningTestToRemove}
                setScreeningTestToEdit={setScreeningTestToEdit}
                setOpenModal={setOpenModal}
                setScreeningTestsHandlerType={setScreeningTestsHandlerType}
                loadScreeningTests={loadScreeningTests}
                testStands={testStands}
                packages={packages}
                organization={organizations.find(
                  (o) => o.tenantId === screeningTest.tenantId
                )}
                setOpenScreeningTestRemovePanel={
                  setOpenScreeningTestRemovePanel
                }
                setScreeningTestToRemove={setScreeningTestToRemove}
                setOpenPriceListPanel={setOpenPriceListPanel}
                missingPricelist={missingPricelist}
              />
            ))}
          </TableBody>
        </Table>
        {/* <TablePagination
          component="div"
          page={pageNr}
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          count={screeningTests.length}
          // count={screeningTestsCount}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={"Wierszy na stronę:"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} z ${count}`
          }
          className={classes.select}
        />*/}
      </TableContainer>
      {openModal && (
        <ScreeningTestModal
          openModal={openModal}
          screeningTestsHandlerType={screeningTestsHandlerType}
          handleClose={() => {
            setOpenModal(false);
          }}
          allowedOrgsToAssign={organizations}
          screeningTestToEdit={
            screeningTestsHandlerType === "add" ? {} : screeningTestToEdit
          }
          loadScreeningTests={loadScreeningTests}
          setSavedScreeningTest={setSavedScreeningTest}
          org={
            screeningTestsHandlerType === "add"
              ? null
              : organizations.find(
                  (o) => o.tenantId === screeningTestToEdit.tenantId
                )
          }
          missingPricelist={missingPricelist}
          testStandsToAssign={testStands}
          packagesToAssign={packages}
          formsToAssign={forms}
          setAdditionCompleted={setAdditionCompleted}
          tenantsContext={tenantsContext}
        />
      )}
      {openPriceListPanel && (
        <PriceListModal
          openPriceListPanel={openPriceListPanel}
          screeningTestToEdit={screeningTestToEdit}
          // screeningTestsHandlerType={screeningTestsHandlerType}
          handleClose={() => {
            setOpenPriceListPanel(false);
          }}
        />
      )}
      <ConfirmDialogMUI
        handleClose={() => {
          setOpenScreeningTestRemovePanel(false);
        }}
        open={openScreeningTestRemovePanel}
        text={
          "Czy na pewno usunąć przesiew " + screeningTestToRemove.label + "?"
        }
        yesAction={screeningTestRemoveHandler}
        noAction={() => {
          setScreeningTestToRemove({});
        }}
      />
    </Box>
  );
};
export default ScreeningTestsAdminPanel;
