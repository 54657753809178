import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addProtege,
  assignPerson,
  patientsNotAssigned,
  editProtege,
  fetchFormByScreeningId,
  fetchProteges,
  fetchScreeningInfo,
  fetchVoucherInfo,
} from "../../redux/actions";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Loader from "../../components/loader";
import ProtegesModal from "../../pages/proteges/proteges-handler-modal";
import { ReactComponent as ArrowDownIcon } from "../../assets/arrowDownIcon.svg";
import { ReactComponent as ArrowDownIconYellow } from "../../assets/arrowDownIcon-yellow.svg";
import FillFormPage from "../fill-form";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "16px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  mainGrid: {
    justifyContent: "center",
  },
  checkbox: {
    color: theme.palette.lockIcon.primary,
  },
  typography: {
    color: theme.palette.text.hintText,
    fontSize: theme.typography.footerMobile,
  },
  hr: {
    opacity: "0.5",
    margin: "2.5em 20px 2.5em 60px",
    border: `0.5px solid ${theme.palette.text.whiteHeader}`,
    [theme.breakpoints.down("lg")]: {
      margin: "1.5em 0 1.5em 0",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  editButton: {
    backgroundColor: theme.palette.background.orderButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `${theme.typography.assignButtonWidth}px`,
    height: "45px",
  },
  addButton: {
    backgroundColor: theme.palette.testIcon.primary,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `${theme.typography.assignButtonWidth}px`,
    height: "45px",
  },
  paper: {
    border: `0.5px solid ${theme.palette.hr.primary}`,
    padding: "32px 0px",
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
    [theme.breakpoints.down("xs")]: {
      padding: "32px 0px",
    },
  },
  text: {
    color: theme.palette.text.alternative2,
    fontSize: theme.typography.text,
    letterSpacing: "1.5px",
    fontWeight: "700",
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.testIcon.primary,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "45px",
      top: "-10px",
      color: theme.palette.testIcon.primary,
    },
  },
  selectText: {
    letterSpacing: "1px",
    lineHeight: `${theme.typography.formLabel}px`,
    fontSize: theme.typography.footer,
    color: theme.palette.text.hintText,
    textTransform: "uppercase",
    borderBottom: `1px solid ${theme.palette.background.menuItemBorderBottom}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
}));

const QRCodeValidationPage = ({
  match,
  editProtege,
  addProtege,
  assignPerson,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const voucherId = match.params.qrCode;

  const classes = useStyles();

  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";
  const my = useSelector((s) => s.my);
  const [codeVerification, setCodeVerifycation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [patientID, setPatientID] = useState("");
  const [meToAssign, setMeToAssign] = useState(false);

  const [valid, setValid] = useState(false);

  const [tenantId, setTenantId] = useState("");
  const [screeningId, setScreeningId] = useState("");

  const [formToFill, setFormToFill] = useState([]);
  const [displayAssignedForms, setDisplayAssignedForms] = useState(false);

  const [protegeToEdit, setProtegeToEdit] = useState({});

  const [openProtegesModal, setOpenProtegesModal] = useState(false);
  const [protegeHandlerType, setProtegeHandlerType] = useState("");

  const [fetchingActiveForms, setFetchingActiveForms] = useState(false);

  const proteges = useSelector((s) => s.proteges);
  const protegeAdded = useSelector((s) => s.protegeAdded);
  const protegeEdited = useSelector((s) => s.protegeEdited);
  const notAssignedPatients = useSelector((s) => s.notAssignedPatients || []);
  const notAssignedPatientsFetching = useSelector(
    (s) => s.notAssignedPatientsFetching || false
  );

  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    if (patientID) {
      (async () => {
        setFetching(true);
        setFetchingActiveForms(true);
        let screeningId = null;
        let tenantId = null;
        let voucherInfo = null;
        try {
          voucherInfo = await dispatch(fetchVoucherInfo(voucherId));
          screeningId = voucherInfo.screeningId;
          tenantId = voucherInfo.tenantId;
          const {
            startDate,
            endDate,
            completedScreenings,
            quantity,
          } = await dispatch(fetchScreeningInfo(tenantId, screeningId));
          const startDateWithTime = new Date(startDate);
          const endDateWithTime = new Date(endDate);
          startDateWithTime.setHours(0, 0, 1);
          endDateWithTime.setHours(23, 59, 59);
          setScreeningId(screeningId);
          setTenantId(tenantId);
          if (
            startDateWithTime < new Date() &&
            endDateWithTime > new Date() &&
            (completedScreenings < quantity || !Number.isInteger(quantity))
          ) {
            if (patientID && !meToAssign) {
              const forms = await dispatch(
                fetchFormByScreeningId(screeningId, patientID)
              );
              if (forms.length > 0) {
                setFormToFill(forms.filter(({ mandatory }) => mandatory));
              } else {
                setValid(true);
                setFormToFill([]);
              }
            } else {
              const forms = await dispatch(fetchFormByScreeningId(screeningId));
              if (forms.length > 0) {
                setFormToFill(forms.filter(({ mandatory }) => mandatory));
              } else {
                setValid(true);
                setFormToFill([]);
              }
            }
          }
          if (!(completedScreenings < quantity) && Number.isInteger(quantity)) {
            setErrorMessage("Limit badań w przesiewie został osiągnięty");
          }
          setFetchingActiveForms(false);
          setFetching(false);
        } catch (e) {
          if (!voucherInfo) {
            setErrorMessage("Nieprawidłowy kod vouchera");
            setFetchingActiveForms(false);
            setFetching(false);
          }
        }
      })();
    }
  }, [patientID, protegeEdited]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchProteges({ page: 0, pageSize: 1000 }));
        const voucherInfo = await dispatch(fetchVoucherInfo(voucherId));
        const screeningId = voucherInfo.screeningId;
        if (screeningId) {
          setFetching(false);
          await dispatch(patientsNotAssigned({ screeningId }));
        }
      } catch (e) {
        setFetching(false);
      }
    })();
  }, [protegeAdded, protegeEdited]);

  useEffect(() => {
    if (patientID) {
      setProtegeToEdit(proteges.find((p) => p.id === patientID));
    }
  }, [proteges]);

  const handleProtegeChange = (event) => {
    const target = event.target;
    const value = target.value;

    if (value === my.id) {
      setMeToAssign(true);
      setPatientID(value);
    } else if (value) {
      setMeToAssign(false);
      setPatientID(value);
      setProtegeToEdit(proteges.find((p) => p.id === value));
    }
  };

  const assignHandler = async () => {
    let r = null;
    setCodeVerifycation(true);
    try {
      if (meToAssign) {
        r = await assignPerson({ voucherId });
      } else {
        r = await assignPerson({ voucherId, subaccountId: patientID });
      }
      if (r.status == 200) {
        try {
          const domain =
            window.location.hostname === "localhost"
              ? `${window.location.hostname}:${window.location.port}`
              : window.location.hostname;

          const secure = window.location.hostname === "localhost" ? `` : "s";
          let ws = new WebSocket(
            `ws${secure}://${domain}/patient/v1/websocket/connect`
          );
          ws.onmessage = function (e) {
            if (
              e.data.includes("ScreeningAddedToPatientPatientWebSocketMessage")
            ) {
              setCodeVerifycation(false);
              history.push({
                pathname: `/summary-assign/${voucherId}`,
                state: {
                  assignedPerson: meToAssign
                    ? my
                    : proteges.find((p) => p.id === patientID),
                  protegeAssigned: !meToAssign,
                },
              });
              ws.close();
            }
          };
          ws.onerror = function (e) {};
          ws.onclose = function (e) {};
        } catch (e) {
          console.log(e, "e");
        }
      }
    } catch (e) {
      if (e.response.data.code == 404) {
        if (e.response.data.message === "Not found : voucher") {
          setErrorMessage("Nieprawidłowy kod vouchera");
        }
        if (e.response.data.message === "Not found : screening") {
          setErrorMessage("Przesiew o podanym kodzie vouchera nie istnieje");
        }
      } else if (e.response.data.code == 400) {
        if (e.response.data.message === "screening-not-active") {
          setErrorMessage(
            "Przesiew o podanym kodzie vouchera nie jest aktywny"
          );
        }
        if (e.response.data.message === "screening-has-no-capacity") {
          setErrorMessage("Limit badań w przesiewie został osiągnięty");
        }
        if (e.response.data.message === "examination-packages-empty") {
          setErrorMessage(
            "Przesiew o podanym kodzie vouchera nie posiada badań"
          );
        }
      } else if (e.response.data.code == 409) {
        if (e.response.data.message === "voucher-already-used") {
          setErrorMessage(
            "Nie można zostać przypisanym więcej, niż jeden raz do przesiewu"
          );
        }
      } else {
        setErrorMessage("Wystąpił problem z przypisaniem do badania");
      }
      setCodeVerifycation(false);
    }
  };

  return codeVerification ? (
    <Box className={classes.root}>
      <Loader loading={true} text="Weryfikacja kodu QR - Proszę Czekać" />
    </Box>
  ) : notAssignedPatientsFetching || fetching ? (
    <Box className={classes.root}>
      <Loader loading={true} text="Pobieranie danych - Proszę Czekać" />
    </Box>
  ) : (
    <Box className={classes.root}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        {/* <Grid item xs={12} lg={10}> */}
        <Grid item xs={12} md={11} xl={7}>
          <Paper className={classes.paper}>
            <Typography className={classes.text}>
              Przypisz badanie do osoby
            </Typography>
            <Box mt={5} mb={5} style={{ padding: "0 48px" }}>
              {notAssignedPatients.includes(my.id) ||
              proteges?.filter(({ id }) => notAssignedPatients.includes(id))
                .length > 0 ? (
                <FormControl fullWidth error={errorMessage}>
                  <InputLabel id="sub-account-select-label">
                    * wybierz osobę do badania
                  </InputLabel>
                  <Select
                    labelId="sub-account-select-label"
                    name="sub-account"
                    required
                    disabled={displayAssignedForms}
                    value={patientID}
                    onChange={handleProtegeChange}
                    label="Osoba do badania *"
                    className={classes.select}
                    IconComponent={(props) => (
                      <i
                        {...props}
                        className={`material-icons ${props.className}`}
                        style={{ top: "calc(50% - 20px)" }}
                      >
                        {isLightGlobalTheme ? (
                          <ArrowDownIcon />
                        ) : (
                          <ArrowDownIconYellow />
                        )}
                      </i>
                    )}
                  >
                    {notAssignedPatients.includes(my.id) ? (
                      <MenuItem
                        key={0}
                        value={my.id}
                        className={classes.selectText}
                      >
                        {`${my.firstName} ${my.surname}`}
                      </MenuItem>
                    ) : null}

                    {proteges?.filter(({ id }) =>
                      notAssignedPatients.includes(id)
                    ).length > 0 && (
                      <MenuItem
                        disabled
                        className={classes.selectText}
                        style={{ opacity: "0.5" }}
                      >
                        DLA INNEJ OSOBY
                      </MenuItem>
                    )}
                    {proteges
                      ?.filter(({ id }) => notAssignedPatients.includes(id))
                      .map((protege, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={protege.id}
                            className={classes.selectText}
                          >
                            {`${protege.firstName} ${protege.surname}`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>{errorMessage}</FormHelperText>
                </FormControl>
              ) : (
                <span>Brak osób do przypisania</span>
              )}
            </Box>
            <Box
              mt={5}
              mb={5}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <Button
                variant="contained"
                className={classes.addButton}
                disabled={displayAssignedForms}
                onClick={() => {
                  setProtegeHandlerType("add");
                  setOpenProtegesModal(true);
                }}
              >
                Dodaj nowy profil
              </Button>{" "}
              <Button
                variant="contained"
                size="small"
                className={classes.editButton}
                disabled={
                  !patientID ||
                  patientID < 0 ||
                  meToAssign ||
                  displayAssignedForms
                }
                onClick={() => {
                  setProtegeHandlerType("edit");
                  setOpenProtegesModal(true);
                }}
              >
                Edytuj profil
              </Button>
            </Box>
            {openProtegesModal && (
              <ProtegesModal
                openProtegesModal={openProtegesModal}
                protegeHandlerType={protegeHandlerType}
                handleClose={() => {
                  setOpenProtegesModal(false);
                  // setProtegeRemoving(true);
                  // setTimeout(() => {
                  //   setProtegeRemoving(false);
                  // }, 3000);
                }}
                protegeToEdit={
                  protegeHandlerType === "add" ? {} : protegeToEdit
                }
                addProtege={addProtege}
                editProtege={editProtege}
              />
            )}
            {fetchingActiveForms || fetching ? (
              <Loader loading={true} text="Wczytywanie danych" />
            ) : (
              <>
                {formToFill.length > 0 && (
                  <Box mt={1} style={{ display: "flex", padding: "0px 48px" }}>
                    <Tooltip title="Wypełnij ankietę aby móc przypisać pakiet do osoby ">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="fetchForms"
                            className={classes.checkbox}
                            color="primary"
                            disabled={!patientID}
                            checked={displayAssignedForms}
                            onChange={() => {
                              setDisplayAssignedForms(!displayAssignedForms);
                            }}
                          />
                        }
                        label={
                          <Typography className={classes.typography}>
                            Wypełnij ankietę dla przypisanej osoby
                          </Typography>
                        }
                      />
                    </Tooltip>
                  </Box>
                )}
                {displayAssignedForms &&
                  formToFill.map((f) => (
                    <FillFormPage
                      key={f.formId}
                      formId={f.formId}
                      setValidAssignButton={setValid}
                      assignHandler={assignHandler}
                      screeningId={screeningId}
                      tenantId={tenantId}
                      meToAssign={meToAssign}
                      mandatory={true}
                      patientID={patientID}
                      setFetching={setFetching}
                    />
                  ))}
                {formToFill.length === 0 && (
                  <Box mt={5} style={{ padding: "0px 48px" }}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      disabled={!patientID && !valid && !displayAssignedForms}
                      onClick={assignHandler}
                    >
                      Przypisz osobę
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  addProtege: (payload) => dispatch(addProtege(payload)),
  assignPerson: (payload) => dispatch(assignPerson(payload)),
  editProtege: (protegeId, payload) =>
    dispatch(editProtege(protegeId, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QRCodeValidationPage);
