import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  Tooltip,
} from "@material-ui/core";
import { getAxiosInstance } from "../../../redux/common";
import Loader from "../../../components/loader";
import UserRow from "../user-row";
import UserModal from "../modal/user-modal";
import { Alert, TabPanel } from "@material-ui/lab";
import PatientRow from "./patient-row.js";
import FilterProperty from "../../../components/filter-property/filter-property";
import { alertAdd } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    padding: "1.5rem",
    marginTop: 10,
  },
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

const PatientPanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [patients, setPatients] = useState([]);
  const [patientsCount, setPatientsCount] = useState(0);

  const [fetchingPatients, setFetchingPatients] = useState(false);

  const [fetchingUsers, setFetchingUsers] = useState(false);
  const [filterQueries, setFilterQueries] = useState({});
  const [displaySearchButton, setDisplaySearchButton] = useState(false);

  const [openUserModal, setOpenUserModal] = useState(false);
  const [userHandlerType, setUserHandlerType] = useState("edition");
  const [userToEdit, setUserToEdit] = useState({});

  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("anonymizationRequestedAt");

  const [additionCompleted, setAdditionCompleted] = useState(false);

  const headerCells = [
    {
      title: "Imię",
      key: "firstName",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Nazwisko",
      key: "surname",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Email",
      key: "mail",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },

    {
      title: "Opcje",
      key: "anonymizationRequestedAt",
      sortable: true,
      filter: (key) => (
        <>
          <Box>
            {displaySearchButton && (
              <Button
                color="primary"
                // size="large"
                startIcon={<SearchIcon />}
                onClick={searchFilterHandler}
              >
                Szukaj
              </Button>
            )}
            {Object.keys(filterQueries).length !== 0 && (
              <Button
                color="primary"
                // size="large"
                startIcon={<ClearIcon />}
                onClick={clearAllFilterHandler}
              >
                <Tooltip title="Czyści wszystkie pola filtracji">
                  <span>Wyczyść</span>
                </Tooltip>
              </Button>
            )}
          </Box>
        </>
      ),
    },
    {
      title: "",
      key: "examinationsExecuted",
      sortable: false,
      filter: () => {},
    },
  ];

  useEffect(() => {
    loadUsers();
  }, [pageNr, rowsPerPage, orderBy, order]);

  useEffect(() => {
    setDisplaySearchButton(true);
  }, [filterQueries]);

  useEffect(() => {
    if (additionCompleted) {
      setTimeout(() => {
        setAdditionCompleted(false);
      }, 3000);
    }
  }, [additionCompleted]);

  const userRemoveHandler = async (id) => {
    try {
      const response = await getAxiosInstance().put(
        `/api/persons/${id}/anonymize`
      );
      if (response) {
        loadUsers();
        dispatch(
          alertAdd({
            text: "Użytkownik został pomyślnie usunięty",
            isSuccess: true,
          })
        );
      }
    } catch (err) {
      dispatch(
        alertAdd({
          text: "Użytkownik nie został usunięty",
          isError: true,
        })
      );
    }
  };

  const loadUsers = async (areFilterQueriesEmpty = false) => {
    try {
      setFetchingUsers(true);
      let response = null;
      if (Object.keys(filterQueries).length > 0 && !areFilterQueriesEmpty) {
        const q = Object.entries(filterQueries)
          .map(([key, value]) => {
            return {
              key: key,
              value: value,
            };
          })
          .reduce(
            (obj, item) => ({ ...obj, [item.key]: { contains: item.value } }),
            {}
          );
        const payload = {
          q,
          page: pageNr,
          pageSize: rowsPerPage,
          ...(orderBy
            ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
            : {}),
        };
        response = await getAxiosInstance().post(
          "/api/v2/persons/all",
          payload
        );
      } else {
        response = await getAxiosInstance().post("/api/v2/persons/all", {
          page: pageNr,
          pageSize: rowsPerPage,
          ...(orderBy
            ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
            : {}),
        });
      }

      setFetchingUsers(false);
      const users = response.data.content;
      setPatients(users);
      setPatientsCount(response.data.pagination.total);
    } catch (e) {
      setFetchingUsers(false);
      setPatients([]);
    }
  };

  const searchFilterHandler = () => {
    setDisplaySearchButton(false);
    setPageNr(0);
    loadUsers();
  };

  const clearAllFilterHandler = () => {
    setFilterQueries({});
    setDisplaySearchButton(false);
    setPageNr(0);
    loadUsers(true);
  };

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const additionButtonHandler = () => {
    setUserHandlerType("add");
    setUserToEdit({});
    setOpenUserModal(true);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return fetchingUsers ? (
    <Loader loading={true} text="Pobieranie użytkowników" />
  ) : (
    <>
      {" "}
      {additionCompleted && <Alert>Użytkownik dodany</Alert>}
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="Users list">
          <TableHead>
            <TableRow>
              {headerCells.map((headCell) =>
                headCell.sortable ? (
                  <TableCell
                    key={headCell.key}

                    // sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <Tooltip title="Sortuj">
                      <TableSortLabel
                        active={orderBy === headCell.key}
                        direction={orderBy === headCell.key ? order : "asc"}
                        onClick={() => {
                          handleSortRequest(headCell.key);
                        }}
                      >
                        {headCell.title}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ) : (
                  <TableCell>{headCell.title}</TableCell>
                )
              )}
            </TableRow>
            <TableRow>
              {headerCells.map((headCell) => (
                <TableCell key={headCell.key}>
                  {headCell.filter(headCell.key)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {patients.map((patient, index) => (
              <PatientRow
                key={index}
                patient={patient}
                setOpenUserModal={setOpenUserModal}
                setUserToEdit={setUserToEdit}
                setUserHandlerType={setUserHandlerType}
                userRemoveHandler={userRemoveHandler}
              />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          page={pageNr}
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          // count={patients.length}
          count={patientsCount}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={"Wierszy na stronę:"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} z ${count}`
          }
          className={classes.select}
        />
      </TableContainer>
      {openUserModal && (
        <UserModal
          userType={"patient"}
          openUserModal={openUserModal}
          userHandlerType={userHandlerType}
          handleClose={() => {
            setOpenUserModal(false);
          }}
          userToEdit={userToEdit}
          loadUsers={loadUsers}
          setAdditionCompleted={setAdditionCompleted}
        />
      )}
    </>
  );
};

export default PatientPanel;
