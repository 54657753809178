import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { getAxiosInstance } from "../../../../redux/common";
import { useDispatch } from "react-redux";
import { alertAdd } from "../../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  paper: {
    width: "80vh",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.alternative,
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width: 600px)": {
      padding: "20px",
    },
    // "@media (max-width: 600px)": {
    //   height: "100vh",
    // },
    // height: "70vh",
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.text.item,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.item,
    },
  },
}));

const ReclamationModal = ({
  openReclamationModal,
  handleClose,
  setReclamationText,
  reclamationText,
  patient,
  patientScreeningId,
  hearBoxTestId,
  hearBoxScreeningId,
  setOpenReclamationModal,
  loadExaminations,
}) => {
  const classes = useStyles();
  const [busy, setBusy] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let isActive = true;
    const domain =
      window.location.hostname === "localhost"
        ? `${window.location.hostname}:${window.location.port}`
        : window.location.hostname;

    const secure = window.location.hostname === "localhost" ? `` : "s";
    let socket = new WebSocket(
      `ws${secure}://${domain}/patient/v1/owner/websocket/connect`
    );

    socket.onmessage = function (event) {
      if (
        event.data.includes("ExaminationReclaimedOwnerPatientWebSocketMessage")
      ) {
        socket.close();
        dispatch(
          alertAdd({
            text: "Proces reklamacji został przeprowadzony pomyślnie.",
            isSuccess: true,
          })
        );
        setBusy(false);
        setOpenReclamationModal(false);
        loadExaminations();
      }
    };

    socket.onerror = function (err) {
      if (isActive) {
        setOpenReclamationModal(false);
        dispatch(
          alertAdd({
            text: "Proces reklamacji nie został przeprowadzony pomyślnie.",
            isError: true,
          })
        );
      }
      socket.close();
    };
    return () => {
      isActive = false;
      socket.close();
    };
  }, []);

  const saveHandler = async () => {
    setBusy(true);
    try {
      await getAxiosInstance().post(
        `/patient/v1/screening/owner/examination/reclamation`,
        {
          patientId: patient.id,
          patientScreeningId,
          reclamations: [
            {
              hearBoxTestId,
              hearBoxScreeningId,
              reason: reclamationText,
            },
          ],
        }
      );
    } catch (e) {
      setOpenReclamationModal(false);
      dispatch(
        alertAdd({
          text: "Proces reklamacji nie został przeprowadzony pomyślnie.",
          isError: true,
        })
      );
    }
  };

  useEffect(() => setFormValid(reclamationText.length), [reclamationText]);

  const title = (
    <Box mt={1} style={{ display: "flex" }}>
      <Typography variant="h6" gutterBottom>
        Reklamacja badania
      </Typography>
    </Box>
  );
  const body = (
    <Box>
      {" "}
      <Box mt={1}>
        <TextField
          disabled={busy}
          label="Powód reklamacji"
          rows={4}
          maxRows={6}
          multiline
          variant="outlined"
          required
          value={reclamationText}
          fullWidth
          onChange={({ target: { value: text } }) => {
            setReclamationText(text);
          }}
        />
      </Box>
    </Box>
  );

  const foot = (
    <Grid>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button variant={"contained"} onClick={() => handleClose()}>
          Anuluj
        </Button>
        &nbsp;&nbsp;&nbsp;{" "}
        <Button
          variant={"contained"}
          color="primary"
          disabled={!formValid || busy}
          onClick={saveHandler}
        >
          {busy && <CircularProgress size={14} />}
          Zapisz
        </Button>
      </Box>
    </Grid>
  );

  return (
    <Modal open={openReclamationModal} className={classes.modalStyle}>
      <Grid container>
        <Grid item xs={12} className={classes.grid}>
          <Paper className={classes.paper}>
            {" "}
            {title} <hr />
            {body}
            <hr
              style={{
                margin: "24px 0px",
              }}
            />
            {foot}
          </Paper>
        </Grid>
      </Grid>
      {/* 
       
      </Box> */}
    </Modal>
  );
};
export default ReclamationModal;
