import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getAxiosInstance } from "../../../../redux/common";
import { NavLink, useParams } from "react-router-dom";
import Loader from "../../../../components/loader";
import FilterProperty, {
  FilterPropertyDate,
  FilterPropertySelect,
} from "../../../../components/filter-property/filter-property";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import classNames from "classnames";
import moment from "moment/moment";
import ReclamationModal from "../reclamation-modal";
import { alertAdd } from "../../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    padding: "1.5rem",
    marginTop: 10,
  },
}));

const ExaminationsRow = ({
  ex: {
    header: {
      patient,
      ts,
      patientScreeningId,
      hearBoxScreeningId,
      reclamation,
    },
    screeningLabel,
    examinationPackage: { label, hearBoxTestId },
  },
  loadExaminations,
}) => {
  const [openReclamationModal, setOpenReclamationModal] = useState(false);
  const [reclamationText, setReclamationText] = useState("");

  return (
    <TableRow>
      <TableCell>{`${patient.firstName} ${patient.lastName}`}</TableCell>
      <TableCell>{`${screeningLabel}`}</TableCell>
      <TableCell>{`${label}`}</TableCell>
      <TableCell>
        {moment.utc(ts).local().format("DD/MM/YYYY HH:mm:ss")}
      </TableCell>
      <TableCell>
        {reclamation ? (
          <span>{reclamation.reason}</span>
        ) : (
          <Button
            variant={"contained"}
            color="secondary"
            onClick={() => setOpenReclamationModal(true)}
          >
            Reset
          </Button>
        )}
      </TableCell>
      {openReclamationModal && (
        <ReclamationModal
          openReclamationModal={openReclamationModal}
          setOpenReclamationModal={setOpenReclamationModal}
          handleClose={() => {
            setOpenReclamationModal(false);
          }}
          setReclamationText={setReclamationText}
          reclamationText={reclamationText}
          patient={patient}
          patientScreeningId={patientScreeningId}
          hearBoxTestId={hearBoxTestId}
          hearBoxScreeningId={hearBoxScreeningId}
          loadExaminations={loadExaminations}
        />
      )}
    </TableRow>
  );
};

const UserExaminationExecuted = () => {
  const [fetchingExaminations, setFetchingExaminations] = useState(true);
  const { id } = useParams();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("examination_ts");

  const pages = [5, 10, 20, 50];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [filterQueries, setFilterQueries] = useState({});
  const [displaySearchButton, setDisplaySearchButton] = useState(false);

  const [examinations, setExaminations] = useState([]);
  const [examinationsCount, setExaminationsCount] = useState(0);

  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    loadExaminations();
  }, [pageNr, rowsPerPage, orderBy, order]);

  useEffect(() => {
    setDisplaySearchButton(true);
  }, [filterQueries]);

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const loadExaminations = async (areFilterQueriesEmpty = false) => {
    try {
      setFetchingExaminations(true);
      let response = null;
      if (Object.keys(filterQueries).length > 0 && !areFilterQueriesEmpty) {
        const q = Object.entries(filterQueries).map(([key, value]) => {
          return {
            key: key,
            value:
              key === "birthdaySearch" ? value.replaceAll("/", "-") : value,
          };
        });

        const newQuery = q.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: item.value }),
          {}
        );
        response = await getAxiosInstance().get(
          "/patient/v1/owner/patient/examination/paginated",
          {
            params: {
              patientId: id,
              page: pageNr,
              limit: rowsPerPage,
              sortBy: orderBy,
              sortDir: order,
              ...newQuery,
            },
          }
        );
      } else {
        response = await getAxiosInstance().get(
          "/patient/v1/owner/patient/examination/paginated",
          {
            params: {
              patientId: id,
              page: pageNr,
              limit: rowsPerPage,
              sortBy: orderBy,
              sortDir: order,
            },
          }
        );
      }
      setExaminations(response.data.results);
      setExaminationsCount(response.data.countOfFilteredResults);

      setFetchingExaminations(false);
    } catch (e) {
      console.log(e);
      setFetchingExaminations(false);
    }
  };

  const searchFilterHandler = () => {
    setDisplaySearchButton(false);
    setPageNr(0);
    loadExaminations();
  };

  const clearAllFilterHandler = () => {
    setFilterQueries({});
    setDisplaySearchButton(false);
    setPageNr(0);
    loadExaminations(true);
  };

  const headerCells = [
    {
      title: "Pacjent",
      key: "patientSearch",
      keySort: "patient",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Przesiew",
      key: "screeningSearch",
      keySort: "screening",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Pakiety",
      key: "examinationPackageSearch",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Data badania",
      key: "examinationTsSearch",
      keySort: "examination_ts",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Opcje",
      key: "anonymizationRequestedAt",
      sortable: true,
      filter: (key) => (
        <>
          <Box>
            {displaySearchButton && (
              <Button
                color="primary"
                // size="large"
                startIcon={<SearchIcon />}
                onClick={searchFilterHandler}
              >
                Szukaj
              </Button>
            )}
            {Object.keys(filterQueries).length !== 0 && (
              <Button
                color="primary"
                // size="large"
                startIcon={<ClearIcon />}
                onClick={clearAllFilterHandler}
              >
                <Tooltip title="Czyści wszystkie pola filtracji">
                  <span>Wyczyść</span>
                </Tooltip>
              </Button>
            )}
          </Box>
        </>
      ),
    },
  ];

  return fetchingExaminations ? (
    <Loader loading={true} text="Pobieranie opisów" />
  ) : (
    <Box>
      <NavLink to={`/admin-panel/users`}>
        <Button
        // onClick={() => userRemoveHandler(id)}
        >
          Powrót do listy pacjentów
        </Button>{" "}
      </NavLink>
      <TableContainer component={Paper} className={classes.container}>
        {" "}
        <Table
          className={classes.table}
          aria-label="Examination to complaint list"
        >
          {" "}
          <TableHead>
            <TableRow>
              {headerCells.map((headCell) =>
                headCell.sortable ? (
                  <TableCell
                    key={headCell.keySort}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.keySort}
                      direction={orderBy === headCell.keySort ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.keySort);
                      }}
                    >
                      {headCell.title}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell>{headCell.title}</TableCell>
                )
              )}
            </TableRow>
            <TableRow>
              {headerCells.map((headCell) => (
                <TableCell key={headCell.key}>
                  {headCell.filter(headCell.key)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {examinationsCount === 0 ? (
              <TableRow>
                <TableCell colSpan={9} style={{ textAlign: "center" }}>
                  {" "}
                  <h1>Brak wykonanych badań</h1>
                </TableCell>
              </TableRow>
            ) : (
              examinations.map((ex, index) => (
                <ExaminationsRow
                  key={index}
                  ex={ex}
                  loadExaminations={loadExaminations}
                />
              ))
            )}
          </TableBody>
        </Table>{" "}
        <TablePagination
          component="div"
          page={pageNr}
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          // count={patients.length}
          count={examinationsCount}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={"Wierszy na stronę:"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} z ${count}`
          }
          className={classes.select}
        />
      </TableContainer>
    </Box>
  );
};

export default UserExaminationExecuted;
