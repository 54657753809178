import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader";
import { getAxiosInstance } from "../../redux/common";
import OrganizationRow from "./organization-row";
import {
  Button,
  makeStyles,
  Modal,
  Paper as DefaultPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Box,
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
  Tooltip,
} from "@material-ui/core";
import FilterProperty, {
  FilterPropertyDate,
  FilterPropertySelect,
} from "../../components/filter-property/filter-property";
import OrganizationModal from "./modal/organization-modal";
import { Alert } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { tenantContext } from "../../redux/admin/actions";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import styled from "styled-components";

export const Paper = styled(DefaultPaper)`
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  table: {
    minWidth: 650,
  },
  stickyRow: {
    position: "sticky",
    top: "0px",
    background: "white",
    zIndex: "100",
  },
  contextFlexContainer: {
    display: "flex",
    gap: "15px",
  },
  tableContainer: {
    flexGrow: 1,
    minHeight: "1px",
  },
}));

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "75vh",
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const OrganizationsAdminPanel = () => {
  const tenantsContext = useSelector((s) => s.tenantsContext || "");

  const [organizations, setOrganizations] = useState([]);
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [organizationsCount, setOrganizationsCount] = useState(0);
  const [fetchingOrganizations, setFetchingOrganizations] = useState(false);

  const classes = useStyles();

  const pages = [5, 10, 20, 30, 50];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("orgName");

  const [filterQueries, setFilterQueries] = useState({});
  const [displaySearchButton, setDisplaySearchButton] = useState(false);
  const [statsFilterOpen, setStatsFilterOpen] = useState(false);

  const [openOrganizationModal, setOpenOrganizationModal] = useState(false);
  const [organizationHandlerType, setOrganizationHandlerType] = useState(
    "edition"
  );
  const [orgToEdit, setOrgToEdit] = useState({});
  const [registerCompleted, setRegisterCompleted] = useState(false);

  const [orgContext, setOrgContext] = useState(tenantsContext || []);
  const [selectOpen, setSelectOpen] = useState(false);
  const handleSelectClose = () => setSelectOpen(false);

  const handleSelectOpen = () => setSelectOpen(true);

  const handleChange = (e, value) => {
    if (value) {
      setOrgContext(value.filter((v) => v));
    }
  };

  const handleDelete = (value) => {
    setOrgContext(orgContext.filter((t) => t.tenantId !== value.tenantId));
  };

  const headerCells = [
    {
      title: "Nazwa",
      key: "orgName",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Numer umowy",
      key: "agreement.name",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Email organizacji",
      key: "orgMail",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Imię osoby do kontaktu",
      key: "contactPerson.firstName",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Nazwisko osoby do kontaktu",
      key: "contactPerson.surname",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Data podpisania",
      key: "agreement.signedAt",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Przesiewy",
      key: "screener test",
      sortable: false,
      filter: (key) => null,
    },

    {
      title: "Dostęp do statystyk",
      key: "canViewStatistics",
      sortable: true,
      filter: (key) => (
        <FilterPropertySelect
          label={"Dostęp"}
          listItems={[
            { value: "yes", label: "Tak" },
            { value: "no", label: "Nie" },
          ]}
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Opcje",
      key: "options",
      sortable: false,
      filter: (key) => (
        <>
          <Box>
            {displaySearchButton && (
              <Button
                color="primary"
                // size="large"
                startIcon={<SearchIcon />}
                onClick={searchFilterHandler}
              >
                Szukaj
              </Button>
            )}
            {Object.keys(filterQueries).length !== 0 && (
              <Button
                color="primary"
                // size="large"
                startIcon={<ClearIcon />}
                onClick={clearAllFilterHandler}
              >
                <Tooltip title="Czyści wszystkie pola filtracji">
                  <span>Wyczyść</span>
                </Tooltip>
              </Button>
            )}
          </Box>
        </>
      ),
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    loadOrganizations();
  }, [pageNr, rowsPerPage, orderBy, order, tenantsContext]);

  useEffect(() => {
    setDisplaySearchButton(true);
  }, [filterQueries]);

  useEffect(() => {
    if (registerCompleted) {
      setTimeout(() => {
        setRegisterCompleted(false);
      }, 3000);
    }
  }, [registerCompleted]);

  const searchFilterHandler = () => {
    setDisplaySearchButton(false);
    setPageNr(0);
    loadOrganizations();
  };

  const clearAllFilterHandler = () => {
    setFilterQueries({});
    setDisplaySearchButton(false);
    setPageNr(0);
    loadOrganizations(true);
  };

  const loadOrganizations = async (areFilterQueriesEmpty = false) => {
    try {
      setFetchingOrganizations(true);
      const responseOrg = await getAxiosInstance().get(
        "/api/organizations/name_with_tenant_ids"
      );
      const orgWithScreeningURL =
        tenantsContext.length > 0
          ? `/api/organizations_with_screenings?tenantIds=${tenantsContext
              .map((org) => org.tenantId)
              .join(",")}`
          : "/api/organizations_with_screenings";
      const response = await getAxiosInstance().post(orgWithScreeningURL, {
        page: pageNr,
        pageSize: rowsPerPage,
        ...(orderBy
          ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
          : {}),
        ...(Object.keys(filterQueries).length > 0 && !areFilterQueriesEmpty
          ? {
              q: Object.keys(filterQueries).reduce(
                (attrs, key) => ({
                  ...attrs,
                  [key]:
                    key === "canViewStatistics"
                      ? {
                          is: filterQueries[key] === "yes",
                        }
                      : key === "agreement.signedAt"
                      ? {
                          between: [
                            `${filterQueries[key]}T00:00:00.000Z`,
                            `${filterQueries[key]}T23:59:59.000Z`,
                          ],
                        }
                      : {
                          contains: filterQueries[key],
                        },
                }),
                {}
              ),
            }
          : {}),
      });
      setAllOrganizations(responseOrg.data);
      const orgs = response.data.content;
      setFetchingOrganizations(false);
      setOrganizations(orgs);
      setOrganizationsCount(response.data.pagination.total);
    } catch (e) {
      setFetchingOrganizations(false);
      setOrganizations([]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const orgRegisterButtonHandler = () => {
    setOrganizationHandlerType("register");
    setOpenOrganizationModal(true);
  };

  return fetchingOrganizations ? (
    <Loader loading={true} text="Pobieranie organizacji" />
  ) : (
    <Box className={classes.rootContainer}>
      <Button
        style={{ alignSelf: "baseline" }}
        variant={"contained"}
        onClick={orgRegisterButtonHandler}
      >
        Rejestruj Organizację
      </Button>

      <Box className={classes.contextFlexContainer} pt={3}>
        {" "}
        <Button
          variant={"contained"}
          disabled={orgContext == tenantsContext}
          onClick={async () => {
            await dispatch(tenantContext(orgContext));
          }}
        >
          Zmień kontekst
        </Button>
        <Autocomplete
          style={{ flex: 1 }}
          id="org-assign"
          options={allOrganizations}
          value={orgContext}
          multiple
          getOptionLabel={(option) => option.orgName || ""}
          getOptionSelected={(option, value) =>
            option.tenantId === value.tenantId
          }
          onChange={handleChange}
          noOptionsText={"Brak wyników"}
          renderTags={(selected) =>
            orgContext.length > 0 && (
              <Box style={{ whiteSpace: "break-spaces" }}>
                {selected.map(
                  (value) =>
                    value && (
                      <Chip
                        key={value.id}
                        label={
                          allOrganizations?.find(
                            (e) => e.tenantId === value.tenantId
                          )?.orgName
                        }
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        onDelete={() => handleDelete(value)}
                        className={classes.chip}
                      />
                    )
                )}
              </Box>
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Kontekst organizacji"
              variant="outlined"
            />
          )}
        />
      </Box>
      {registerCompleted && <Alert>Organizacja została założona</Alert>}
      <Box pt={3} className={classes.tableContainer}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="Organization list">
            <TableHead>
              <TableRow className={classes.stickyRow}>
                {headerCells.map((headCell) =>
                  headCell.sortable ? (
                    <TableCell
                      key={headCell.key}
                      align={headCell.numeric ? "right" : "left"}
                      padding={headCell.disablePadding ? "none" : "normal"}
                      // sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <Tooltip title="Sortuj">
                        <TableSortLabel
                          active={orderBy === headCell.key}
                          direction={orderBy === headCell.key ? order : "asc"}
                          onClick={() => {
                            handleSortRequest(headCell.key);
                          }}
                        >
                          {headCell.title}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  ) : (
                    <TableCell>{headCell.title}</TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                {headerCells.map((headCell) => (
                  <TableCell key={headCell.key}>
                    {headCell.filter(headCell.key)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {organizations.map((org, index) => (
                <OrganizationRow
                  key={index}
                  orgData={org}
                  setOpenOrganizationModal={setOpenOrganizationModal}
                  setOrgToEdit={setOrgToEdit}
                  setOrganizationHandlerType={setOrganizationHandlerType}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            page={pageNr}
            rowsPerPageOptions={pages}
            rowsPerPage={rowsPerPage}
            count={organizationsCount}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={"Wierszy na stronę:"}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} z ${count}`
            }
            className={classes.select}
          />
        </TableContainer>
      </Box>
      {openOrganizationModal && (
        <OrganizationModal
          openOrganizationModal={openOrganizationModal}
          organizationHandlerType={organizationHandlerType}
          handleClose={() => {
            setOpenOrganizationModal(false);
            // setProtegeRemoving(true);
            // setTimeout(() => {
            //   setProtegeRemoving(false);
            // }, 3000);
          }}
          orgToEdit={orgToEdit}
          loadOrganizations={loadOrganizations}
          setRegisterCompleted={setRegisterCompleted}
        />
      )}
    </Box>
  );
};

export default OrganizationsAdminPanel;
