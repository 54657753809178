import React, { useEffect, useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import Title from "./components/title";
import { data } from "./sample-data";
import BoxBorder from "./components/box-border";
import DeviceStates from "./components/device/states/states";
import ExceptionsInfo from "./components/device/exceptions/exceptions";
import DeviceInfo from "./components/device/info/info";
import LogsModal from "./components/logs-modal";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100%",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing("2"),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing("4"),
    },
  },
}));

const TestStandMonitoring = () => {
  const classes = useStyles();

  const [openLogsModal, setOpenLogsModal] = useState(false);

  const handleOpenLogsModal = () => setOpenLogsModal(true);

  const { name, ts, devicesStates, exceptions, status } = data;
  return (
    <Box className={classes.rootContainer}>
      <Title
        title={`  Monitoring stanowiska: ${name}`}
        date={ts}
        status={status}
      />
      <BoxBorder>
        <DeviceStates
          data={devicesStates}
          handleOpenModal={handleOpenLogsModal}
        />
      </BoxBorder>
      <br />
      <Title title={`Historia błędów`} />
      <BoxBorder>
        <ExceptionsInfo data={exceptions} />
      </BoxBorder>
      <br />
      <Title title={"Ogólne informacje"} />
      <BoxBorder>
        <DeviceInfo data={devicesStates} />
      </BoxBorder>
      {openLogsModal && (
        <LogsModal open={openLogsModal} setOpen={setOpenLogsModal} />
      )}
    </Box>
  );
};

export default TestStandMonitoring;
