import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptFutureTerms,
  acceptTerms,
  fetchTermsAcceptedByUser,
  revokeFutureTerms,
  revokeTerms,
} from "../../redux/organization/actions";
import { alertAdd } from "../../redux/actions";

import { Box } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { ConfirmDialog } from "../confirm-dialog";
import { ConfirmDialogMUI } from "../confirm-dialog-mui";

const useStyles = makeStyles((theme) => ({
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}));

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.anchor.primary,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
      textDecoration: "underline",
    },
  },
}))(Link);

const TermsAcceptance = ({ terms, acceptance, future }) => {
  const globalTheme = useSelector((s) => s.globalTheme);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [accepted, setAccepted] = useState(false);
  const [openConfirmPanel, setOpenConfirmPanel] = useState(false);

  const termsText = future ? "Przyszły regulamin" : "Regulamin";
  const additionalText =
    "rezygnacja spowoduje zablokowanie funkcjonalności systemu, ";

  const accept = async () => {
    try {
      if (!future) {
        await dispatch(acceptTerms(terms.id));
        window.location.reload();
      } else {
        await dispatch(acceptFutureTerms(terms.id));
      }
      setAccepted(true);
      await dispatch(fetchTermsAcceptedByUser());
    } catch (e) {
      setAccepted(false);
      let errorMsg;
      let message = e.response.data.message;
      if (message?.includes("terms-already-accepted")) {
        errorMsg = "Regulamin został już wcześniej zaakceptowany";
      } else if (message?.includes("missing-or-not-current")) {
        errorMsg = "Organizacja nie posiada aktualnego regulaminu";
      } else if (message?.includes("selected-terms-are-not-future")) {
        errorMsg = "Wystąpił błąd, to nie jest przyszły regulamin";
      } else if (message?.includes("missing-or-not-future")) {
        errorMsg =
          "Wystąpił błąd, nie ma takiego regulaminu lub nie jest przyszły";
      } else {
        errorMsg = `Błąd podczas akceptacji regulaminu, (${e.message}).`;
      }
      dispatch(
        alertAdd({
          text: errorMsg,
          isError: true,
        })
      );
    }
  };

  const handleRevokeTerms = async () => {
    try {
      if (!future) {
        await dispatch(revokeTerms(terms.id));
        window.location.reload();
      } else {
        await dispatch(revokeFutureTerms(terms.id));
      }
      dispatch(fetchTermsAcceptedByUser());
    } catch (e) {
      let errorMsg = "Wystąpił błąd";
      if (
        e.response.status === 404 &&
        e.response.data.message.includes("not-found")
      ) {
        errorMsg = "Nie ma takiego regulaminu";
      } else if (e.response.status === 400) {
        const message = e.response.data.message;
        if (message?.includes("terms-already-revoked")) {
          errorMsg = "Cofnięto już wcześniej zgodę na ten regulamin";
        } else if (message?.includes("terms-were-never-accepted")) {
          errorMsg = "Regulamin nie był wcześniej zaakceptowany";
        } else if (message?.includes("selected-terms-are-not-current")) {
          errorMsg =
            "Nie jest to bieżący regulamin, cofnięcie zgody jest możliwe tylko dla aktualnego regulaminu";
        } else if (message?.includes("missing-or-not-current")) {
          errorMsg =
            "Organizacja nie posiada regulaminu lub żaden nie jest obecnie aktywny";
        } else if (message?.includes("selected-terms-are-not-future")) {
          errorMsg = "Wystąpił błąd, to nie jest przyszły regulamin";
        } else if (message?.includes("missing-or-not-future")) {
          errorMsg =
            "Wystąpił błąd, nie ma takiego regulaminu lub nie jest przyszły";
        }
      } else {
        errorMsg = `Błąd podczas cofnięcia akceptacji regulaminu, (${e.message}).`;
      }
      dispatch(
        alertAdd({
          text: errorMsg,
          isError: true,
        })
      );
    }
  };

  const askToRemove = (e) => {
    e.preventDefault();
    setOpenConfirmPanel(true);
  };

  const acceptComponent = () => (
    <Box mt={1}>
      <FormControlLabel
        control={
          <Checkbox
            checked={accepted}
            onChange={({ target: { checked } }) => {
              checked && accept();
            }}
            name="checkedB"
            color="primary"
          />
        }
        label={
          <Typography variant="body1">
            Akceptuję{" "}
            <MuiNavLink href={`/org-media/files${terms.link}`} target="_blank">
              {" "}
              regulamin
            </MuiNavLink>{" "}
            serwisu
          </Typography>
        }
      />
    </Box>
  );

  const revokeComponent = () => (
    <Grid item xs={12}>
      <br />
      {/* {openConfirmPanel && (
        <ConfirmDialog
          text={`Czy na pewno chcesz cofnąć zgodę na regulamin ${terms.name}?`}
          yesAction={() => {
            handleRevokeTerms();
            setOpenConfirmPanel(false);
          }}
          noAction={() => setOpenConfirmPanel(false)}
        />
      )} */}
      <ConfirmDialogMUI
        handleClose={() => {
          setOpenConfirmPanel(false);
        }}
        open={openConfirmPanel}
        text={`Czy na pewno chcesz cofnąć zgodę na regulamin ${terms.name}?`}
        yesAction={() => {
          handleRevokeTerms();
          setOpenConfirmPanel(false);
        }}
        noAction={() => setOpenConfirmPanel(false)}
      />
      <MuiNavLink href={`/org-media/files${terms.link}`} target="_blank">
        {termsText}
      </MuiNavLink>{" "}
      {`serwisu jest zaakceptowany, ${!future ? additionalText : ""}
      jeśli ${!future ? "mimo to " : ""}chcesz zrezygnować kliknij `}
      <MuiNavLink
        href="#"
        style={{ fontWeight: "bold" }}
        onClick={(e) => askToRemove(e)}
      >
        tutaj
      </MuiNavLink>
      .
    </Grid>
  );

  if (acceptance) {
    return acceptComponent();
  } else {
    return revokeComponent();
  }
};

export default TermsAcceptance;
