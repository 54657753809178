import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Link,
  Redirect,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {
  fetchHasSingleScheduledForm,
  fetchOrganization,
  logout,
  logout as loogutAction,
  setFontSize,
  toggleGlobalTheme,
} from "../../redux/actions";
import Typography from "@material-ui/core/Typography";
import MuiIconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { Warning } from "@material-ui/icons";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Button, Paper, Switch, Tab, Tabs, Tooltip } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Breadcrumbs from "../breadcrumbs";
import UnsupportedBrowserAlert from "../unsupported-browser-alert";
import ContrastIcon from "@material-ui/icons/BrightnessMedium";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import { ReactComponent as ShoppingCartIcon } from "../../assets/shopping-cart.svg";
import { ReactComponent as ShoppingCartIconYellow } from "../../assets/shopping-cart-yellow.svg";
import Badge from "@material-ui/core/Badge";
import { ReactComponent as HBIcon } from "../../assets/hb-tmp.svg";
import { ReactComponent as ProfileIcon } from "../../assets/profile.svg";
import { ReactComponent as ProfileIconYellow } from "../../assets/profile-yellow.svg";
import { ReactComponent as EUflag } from "../../assets/europe-flag-icon.svg";
import Sidebar from "../header-menu/sidebar";
import StartPage from "../../pages/start-page";
import StartPageLower from "../../pages/start-page-lower";
import MainBackground from "../../assets/main2.svg";
import MainBackgroundMobile from "../../assets/main2Mobile.svg";
import Image from "../../assets/background.svg";
import ImageMobile from "../../assets/backgroundMobile.svg";
import HearingIcon from "@material-ui/icons/Hearing";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import PlaceIcon from "@material-ui/icons/Place";
import CropFreeIcon from "@material-ui/icons/CropFree";
import QRCodeModal from "../qr-code";
import Cookies from "../cookies/cookies";

export const drawerWidth = 50;

const IconButton = withStyles((theme) => ({
  root: {
    padding: "4px",
    marginTop: "5px",
    height: "25px",
    borderRadius: "0%",
    marginRight: "10px",
    color: theme.palette.text.default,
  },
}))(MuiIconButton);

export const CenteredTabs = withStyles({
  flexContainer: {
    textAlign: "center",
    display: "inherit",
  },
})(Tabs);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  rootAdmin: {
    flexGrow: 1,
  },
  menuButtonAdmin: {
    marginRight: theme.spacing(2),
  },
  titleAdmin: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: theme.palette.text.default,
  },
  title1: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  title: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  titleText: {
    fontWeight: "700",
    color: theme.palette.text.default,
    fontSize: theme.typography.formLabel,
  },
  titleTextMobile: {
    fontWeight: "700",
    color: theme.palette.text.default2,
    fontSize: theme.typography.formLabel,
  },
  titleTestTextMobile: {
    color: theme.palette.text.default2,
    fontSize: theme.typography.footerMobile,
  },
  activePage: {
    color: theme.palette.background.default,
    textDecoration: "underline",
    textUnderlineOffset: "13px",
  },
  upperToolbar: {
    minHeight: "35px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: "0px",
  },
  lowerToolbar: {
    minHeight: "80px",
    margin: "0 20px 0 60px",
    [theme.breakpoints.down("lg")]: {
      margin: "0 0 0 0",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  titleIcon: {
    marginRight: "4px",
    marginLeft: "4px",
  },
  mainContent: {
    flexGrow: 1,
    padding: theme.spacing(0),
    minHeight: `calc(100vh - 115px)`,
  },
  mainContentAdmin: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  a: {
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: theme.typography.fontSize,
    letterSpacing: "2px",
    textTransform: "uppercase",
    margin: "15px",

    color: theme.palette.text.whiteHeader,
    // textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.whiteHeader,
      textDecoration: "underline",
      textUnderlineOffset: "13px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "4px",
    },
  },
  aIcon: {
    fontFamily: "Raleway",
    fontWeight: "700",
    height: "42px",
    size: "12px",
    letterSpacing: "2px",
    textTransform: "uppercase",
    // margin: "15px",
    padding: "6px",
    color: theme.palette.text.whiteHeader,
    // textDecoration: "underline",
    "&:hover": {
      borderBottom: `1px solid ${theme.palette.text.whiteHeader}`,
      color: theme.palette.text.whiteHeader,
    },
  },
  spanHeader: {
    color: theme.palette.text.whiteHeader,
    fontFamily: "ROBOTO",
    fontWeight: "300",
    size: "7px",
    lineHeight: "10.8px",
    letterSpacing: "1px",
  },
  activePageIcon: {
    fontFamily: "Raleway",
    fontWeight: "700",
    size: "12px",
    letterSpacing: "2px",
    textTransform: "uppercase",
    // margin: "15px",
    padding: "6px",
    color: theme.palette.text.whiteHeader,
    borderBottom: `1px solid ${theme.palette.text.whiteHeader}`,
    // textDecoration: "underline",
  },
  activePageAvatar: {
    borderBottom: `1px solid ${theme.palette.text.whiteHeader}`,
  },
  avatarHover: {
    "&:hover": {
      borderBottom: `1px solid ${theme.palette.text.whiteHeader}`,
    },
  },
  paperMainContainer: {
    backgroundImage: `url(${MainBackground})`,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${MainBackgroundMobile})`,
      backgroundPosition: "inherit",
    },
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    objectFit: "contain",
    borderRadius: "0px",
    height: "100vh",
  },
  paperMainContainerAdmin: {
    height: "100vh",
    // backgroundColor: theme.palette.background.menu,
  },
  customBadge: {
    backgroundColor: theme.palette.background.badgeBackground,
    color: theme.palette.text.badgeText,
    fontWeight: "600",
  },
  paperContainer: {
    backgroundImage: `url(${Image})`,
    [theme.breakpoints.down("md")]: {
      backgroundImage: `url(${ImageMobile})`,
    },
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    objectFit: "contain",
    borderRadius: "0px",
    height: "100%",
  },
  paperContainerGray: {
    background: theme.palette.background.menu,
    boxShadow: "none",
    borderRadius: "0px",
    height: "100%",
    // maxHeight: "90vh",
  },

  paperContainerStartPage: {
    borderRadius: "0px",
    height: "100%",
    backgroundColor: "inherit",
    [theme.breakpoints.down("xs")]: {
      padding: "4px",
    },
  },
  card: {
    background: theme.palette.background.list,
    minWidth: "130px",
    borderRadius: "0px",
  },
  // aIconPadding: {
  //   padding: "10px",
  // },
  // activePageIconPadding: {
  //   padding: "10px",
  // },
  listItemText: {
    color: theme.palette.text.hintText,
    fontSize: theme.typography.footerMobile,
    textTransform: "uppercase",

    // fontWeight: "400",
  },
  iconHeader: {
    margin: "0px",
    padding: "0px",
  },
}));

const Header = ({
  loggedIn,
  my,
  // cartItems,
  children,
  fetchOrganization,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  const fontSize = useSelector((s) => s.fontSize || "normal");
  const cookiesAccepted = useSelector((s) => s.cookiesAccepted || false);
  const roles = useSelector((s) => s.roles);
  const unsupportedBrowser = useSelector((s) => s.unsupportedBrowser);
  const shoppingCartProducts = useSelector((s) => s.shoppingCartProducts || []);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [menuTestsOpened, setMenuTestsOpened] = useState(false);
  const [qrOpened, setQROpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [orgName, setOrgName] = useState("");

  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";

  const [openSidebar, setOpenSidebar] = useState(false);

  const isOwner = !!my?.user.authorities.includes("ROLE_OWNER");
  const isEditor = my?.user.authorities.localeCompare("ROLE_EDITOR") === 0;
  const isUser = my?.user.authorities.localeCompare("ROLE_USER") === 0;

  const unsupportedBrowserAlert = unsupportedBrowser
    ? "Korzystasz z niewspieranej wersji przeglądarki, niektóre funkcje mogą nie działać prawidłowo. "
    : "";

  useEffect(() => {
    if (
      roles.indexOf("ROLE_OWNER") !== -1 ||
      roles.indexOf("ROLE_EDITOR") !== -1
    ) {
      // dispatch(fetchHasSingleScheduledForm());
    }
  }, []);

  useEffect(() => {
    async function fetchOrgName() {
      const { orgName } = await fetchOrganization(my.tenantId);
      setOrgName(orgName);
    }
    if (isOwner && my.tenantId) {
      fetchOrgName();
    }
  }, [isOwner]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleInstructionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const navigationLinks = [
    { label: "Co to jest Hear box", href: "/", nested: false, header: false },
    // { label: "Stanowiska", href: "/test-stands" },
    { label: "Badania", href: "/products", nested: false, header: true },
    {
      label: "Kup badanie",
      href: "/products",
      nested: true,
      header: false,
    },
    {
      label: "Twoje badanie",
      href: "/active-tests",
      nested: true,
      header: false,
    },
    {
      label: "Sprawdź wyniki",
      href: "/results",
      nested: true,
      header: false,
    },
    { label: "Stanowiska", href: "/test-stands", nested: false },
    { label: "Kontakt", href: "/send-message", nested: false, header: false },
  ];

  let instructionPathForRole = "";
  if (my?.user.authorities.localeCompare("ROLE_ADMIN") === 0) {
    instructionPathForRole = "/org-media/files/public/admin1.mp4";
  } else if (isOwner) {
    instructionPathForRole =
      "/org-media/files/public/instrukcja_wlasciciela_organizacji.pdf";
  } else if (isEditor) {
    instructionPathForRole =
      "/org-media/files/public/instrukcja_koordynatora.pdf";
  } else if (isUser) {
    instructionPathForRole =
      "/org-media/files/public/instrukcja_uzytkownika.pdf";
  }

  let instructionPathForPage = "";
  if (location?.pathname.match("^/$")) {
    if (isUser) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_start_user.pdf";
    } else {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_start_owner_editor.pdf";
    }
  } else if (location?.pathname.match("^/profile/api-keys")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_api-keys.pdf";
  } else if (location?.pathname.match("^/profile")) {
    if (isOwner) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_profil_owner.pdf";
    } else if (isEditor) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_profil_editor.pdf";
    } else if (isUser) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_profil_user.pdf";
    }
  } else if (location?.pathname.match("^/filled-forms")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_filled_forms.pdf";
  } else if (
    location?.pathname.match("^/(formdata/add|formdata/fill-scheduled-test)")
  ) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_formdata_add_fill-scheduled.pdf";
  } else if (location?.pathname.match("^/formdata/edit")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_formdata_edit.pdf";
  } else if (location?.pathname.match("^/formdata")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_formdata.pdf";
  } else if (location?.pathname.match("^/results")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_results.pdf";
  } else if (location?.pathname.match("^/forms/display")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_forms_display.pdf";
  } else if (location?.pathname.match("^/forms/add")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_forms_add.pdf";
  } else if (location?.pathname.match("^/forms/edit")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_forms_edit.pdf";
  } else if (location?.pathname.match("^/forms/active")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_forms_active.pdf";
  } else if (location?.pathname.match("^/forms")) {
    if (isOwner) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_forms_owner.pdf";
    } else {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_forms_editor.pdf";
    }
  } else if (location?.pathname.match("^/person/add")) {
    if (isOwner) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_owner_person_add.pdf";
    } else {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_editor_person_add.pdf";
    }
  } else if (location?.pathname.match("^/(persons|person)")) {
    if (isOwner) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_persons_owner.pdf";
    } else {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_persons_editor.pdf";
    }
  } else if (location?.pathname.match("^/groups")) {
    if (isOwner) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_groups_owner.pdf";
    } else {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_groups_editor.pdf";
    }
  } else if (location?.pathname.match("^/form_group")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_form_group.pdf";
  } else if (location?.pathname.match("^/settings")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_settings.pdf";
  }

  const browserAlert = unsupportedBrowserAlert ? (
    mediumScreen ? (
      <Tooltip
        title={`${unsupportedBrowserAlert} 
      Rekomendujemy zaktualizowanie do najnowszej wersji.`}
      >
        <ReportProblemOutlinedIcon className={classes.alertIcon} />
      </Tooltip>
    ) : (
      <UnsupportedBrowserAlert forHeader={true} />
    )
  ) : (
    ""
  );

  return (
    <>
      {loggedIn &&
        (!isOwner ? (
          <>
            <Box className={pathname === "/" && classes.paperMainContainer}>
              <Grid container className={classes.scrollType}>
                <Grid item xs={12}>
                  <AppBar
                    position="static"
                    style={{
                      backgroundColor: pathname === "/" && "inherit",
                      boxShadow: "none",
                    }}
                  >
                    <Toolbar className={classes.upperToolbar}>
                      {" "}
                      {!my.user.activated ? (
                        <Tooltip
                          title="Konto nie zostało aktywowane - prosimy kliknąć w link aktywacyjny
              wysłany w mailu powitalnym."
                        >
                          <IconButton>
                            <Warning />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                      <Tooltip title="Przełącz kontrastowy tryb strony">
                        <IconButton>
                          <ContrastIcon
                            onClick={() => dispatch(toggleGlobalTheme())}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Zmień rozmiar czcionki">
                        <IconButton>
                          <TextFormatIcon
                            style={{
                              border: fontSize === "normal" && "1px solid",
                            }}
                            fontSize="small"
                            onClick={() => dispatch(setFontSize("normal"))}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Zmień rozmiar czcionki">
                        <IconButton>
                          <TextFormatIcon
                            style={{
                              border: fontSize === "medium" && "1px solid",
                            }}
                            fontSize="medium"
                            onClick={() => dispatch(setFontSize("medium"))}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Zmień rozmiar czcionki">
                        <IconButton>
                          <TextFormatIcon
                            style={{
                              border: fontSize === "large" && "1px solid",
                            }}
                            fontSize="large"
                            onClick={() => dispatch(setFontSize("large"))}
                          />
                        </IconButton>
                      </Tooltip>
                    </Toolbar>
                    <Toolbar className={classes.lowerToolbar}>
                      <Hidden lgUp>
                        <Box className={classes.buttonMenuBox}>
                          <MuiIconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                          >
                            <MenuIcon onClick={() => handleSidebarOpen(true)} />
                          </MuiIconButton>
                        </Box>
                        <Box className={classes.title}>
                          {" "}
                          <Typography
                            className={classes.titleTextMobile}
                            onClick={() => history.push("/")}
                          >
                            HEAR BOX
                          </Typography>
                          <Typography
                            className={classes.titleTestTextMobile}
                            onClick={() => history.push("/")}
                          >
                            wersja testowa
                          </Typography>
                        </Box>
                        {isUser && (
                          <>
                            <Tooltip title="Otwórz przypisany do konta QR kod">
                              <IconButton className={classes.iconHeader}>
                                <CropFreeIcon
                                  onClick={() => setQROpened(true)}
                                />
                              </IconButton>
                            </Tooltip>
                            <QRCodeModal
                              handleClose={() => setQROpened(false)}
                              open={qrOpened}
                            />
                          </>
                        )}
                        <Box
                          ml={1}
                          position="relative"
                          mr={2}
                          className={`${
                            my.avatarPath &&
                            pathname === "/profile" &&
                            classes.activePageAvatar
                          } ${my.avatarPath && classes.avatarHover}`}
                        >
                          {my.avatarPath ? (
                            <Avatar
                              style={{
                                cursor: "pointer",
                                height: "35px",
                                width: "35px",
                              }}
                              onClick={() => setMenuOpened(!menuOpened)}
                              src={`/org-media/files${my.avatarPath}`}
                            />
                          ) : (
                            <Box
                              className={`${classes.aIcon} ${
                                classes.aIconPadding
                              }  ${
                                pathname === "/profile" &&
                                classes.activePageIcon
                              } ${
                                pathname === "/profile" &&
                                classes.activePageIconPadding
                              }`}
                              onClick={() => setMenuOpened(!menuOpened)}
                              style={{ cursor: "pointer" }}
                            >
                              {isLightGlobalTheme ? (
                                <ProfileIcon
                                  style={{
                                    cursor: "pointer",
                                    marginBottom: "-5px",
                                  }}
                                />
                              ) : (
                                <ProfileIconYellow
                                  style={{
                                    cursor: "pointer",
                                    marginBottom: "-5px",
                                  }}
                                />
                              )}
                            </Box>
                          )}
                          {menuOpened && (
                            <ClickAwayListener
                              onClickAway={() => setMenuOpened(false)}
                            >
                              <Box
                                position="absolute"
                                right={0}
                                style={{ zIndex: "101" }}
                              >
                                <Card
                                  variant="outlined"
                                  p={1}
                                  className={classes.card}
                                >
                                  <CardContent p={1} style={{ padding: "0px" }}>
                                    <List>
                                      <ListItem
                                        button
                                        onClick={() => {
                                          history.push("/profile");
                                          setMenuOpened(false);
                                        }}
                                      >
                                        <ListItemText
                                          primary={
                                            <Typography
                                              className={classes.listItemText}
                                            >
                                              Twój profil
                                            </Typography>
                                          }
                                        ></ListItemText>
                                      </ListItem>
                                      <ListItem
                                        onClick={() => {
                                          history.push("/proteges");
                                          setMenuOpened(false);
                                        }}
                                      >
                                        <ListItemText
                                          primary={
                                            <Typography
                                              className={classes.listItemText}
                                            >
                                              Profil innej osoby
                                            </Typography>
                                          }
                                        ></ListItemText>
                                      </ListItem>
                                      <ListItem onClick={() => {}}>
                                        <ListItemText
                                          primary={
                                            <Typography
                                              className={classes.listItemText}
                                            >
                                              Pomoc
                                            </Typography>
                                          }
                                        ></ListItemText>
                                      </ListItem>
                                      <ListItem
                                        primary={
                                          <Typography
                                            className={classes.listItemText}
                                          />
                                        }
                                        onClick={() => {
                                          const isUser =
                                            my.user.authorities.localeCompare(
                                              "ROLE_USER"
                                            ) === 0;
                                          dispatch(logout({ isUser }));
                                          setMenuOpened(false);
                                        }}
                                      >
                                        <ListItemText
                                          primary={
                                            <Typography
                                              className={classes.listItemText}
                                            >
                                              Wyloguj
                                            </Typography>
                                          }
                                        ></ListItemText>
                                      </ListItem>
                                    </List>
                                  </CardContent>
                                </Card>
                              </Box>
                            </ClickAwayListener>
                          )}
                        </Box>
                        <Box mr={4}>
                          <Link
                            className={`${classes.aIcon} ${
                              classes.aIconPadding
                            }  ${
                              pathname === "/test-kit/add" &&
                              classes.activePageIcon
                            } ${
                              pathname === "/test-kit/add" &&
                              classes.activePageIconPadding
                            }`}
                            to={"/test-kit/add"}
                          >
                            <Badge
                              classes={{ badge: classes.customBadge }}
                              badgeContent={parseInt(
                                shoppingCartProducts.length
                              )}
                            >
                              {isLightGlobalTheme ? (
                                <ShoppingCartIcon />
                              ) : (
                                <ShoppingCartIconYellow />
                              )}
                            </Badge>
                          </Link>
                        </Box>
                      </Hidden>
                      {/* <Hidden smDown lgUp>
                        <Box className={classes.buttonMenuBox}>
                          <MuiIconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                          >
                            <MenuIcon onClick={() => handleSidebarOpen(true)} />
                          </MuiIconButton>
                        </Box>
                        <Box className={classes.title}>
                          {" "}
                          <Typography
                            className={classes.titleTextMobile}
                            onClick={() => history.push("/")}
                          >
                            HEAR BOX
                          </Typography>
                        </Box>
                        {isUser && (
                          <>
                            <Tooltip title="Otwórz przypisany do konta QR kod">
                              <IconButton className={classes.iconHeader}>
                                <CropFreeIcon
                                  onClick={() => setQROpened(true)}
                                />
                              </IconButton>
                            </Tooltip>
                            <QRCodeModal
                              handleClose={() => setQROpened(false)}
                              open={qrOpened}
                            />
                          </>
                        )}
                        <Box
                          position="relative"
                          mr={2}
                          ml={2}
                          className={`${
                            my.avatarPath &&
                            pathname === "/profile" &&
                            classes.activePageAvatar
                          } ${my.avatarPath && classes.avatarHover}`}
                        >
                          {my.avatarPath ? (
                            <Avatar
                              style={{
                                cursor: "pointer",
                                height: "35px",
                                width: "35px",
                              }}
                              onClick={() => setMenuOpened(!menuOpened)}
                              src={`/org-media/files${my.avatarPath}`}
                            />
                          ) : (
                            <Box
                              className={`${classes.aIcon} ${
                                classes.aIconPadding
                              }  ${
                                pathname === "/profile" &&
                                classes.activePageIcon
                              } ${
                                pathname === "/profile" &&
                                classes.activePageIconPadding
                              }`}
                              onClick={() => setMenuOpened(!menuOpened)}
                              style={{ cursor: "pointer" }}
                            >
                              {isLightGlobalTheme ? (
                                <ProfileIcon
                                  style={{
                                    cursor: "pointer",
                                    marginBottom: "-5px",
                                  }}
                                />
                              ) : (
                                <ProfileIconYellow
                                  style={{
                                    cursor: "pointer",
                                    marginBottom: "-5px",
                                  }}
                                />
                              )}
                            </Box>
                          )}

                          {menuOpened && (
                            <ClickAwayListener
                              onClickAway={() => setMenuOpened(false)}
                            >
                              <Box
                                position="absolute"
                                right={0}
                                style={{ zIndex: "101" }}
                              >
                                <Card
                                  variant="outlined"
                                  p={1}
                                  className={classes.card}
                                >
                                  <CardContent p={1} style={{ padding: "0px" }}>
                                    <List>
                                      <ListItem
                                        button
                                        onClick={() => {
                                          history.push("/profile");
                                          setMenuOpened(false);
                                        }}
                                      >
                                        <ListItemText
                                          primary={
                                            <Typography
                                              className={classes.listItemText}
                                            >
                                              Twój profil
                                            </Typography>
                                          }
                                        ></ListItemText>
                                      </ListItem>
                                      <ListItem
                                        button
                                        onClick={() => {
                                          history.push("/proteges");
                                          setMenuOpened(false);
                                        }}
                                      >
                                        <ListItemText
                                          primary={
                                            <Typography
                                              className={classes.listItemText}
                                            >
                                              Profil innej osoby
                                            </Typography>
                                          }
                                        ></ListItemText>
                                      </ListItem>
                                      <ListItem
                                        className={classes.listItemText}
                                        button
                                        onClick={() => {
                                          const isUser =
                                            my.user.authorities.localeCompare(
                                              "ROLE_USER"
                                            ) === 0;
                                          dispatch(logout({ isUser }));
                                          setMenuOpened(false);
                                        }}
                                      >
                                        <ListItemText
                                          primary={
                                            <Typography
                                              className={classes.listItemText}
                                            >
                                              Wyloguj
                                            </Typography>
                                          }
                                        ></ListItemText>
                                      </ListItem>
                                    </List>
                                  </CardContent>
                                </Card>
                              </Box>
                            </ClickAwayListener>
                          )}
                        </Box>
                        <Box mr={4}>
                          <Link
                            className={`${classes.aIcon} ${
                              classes.aIconPadding
                            }  ${
                              pathname === "/test-kit/add" &&
                              classes.activePageIcon
                            } ${
                              pathname === "/test-kit/add" &&
                              classes.activePageIconPadding
                            }`}
                            to={"/test-kit/add"}
                          >
                            <Badge
                              badgeContent={parseInt(
                                shoppingCartProducts.length
                              )}
                              classes={{ badge: classes.customBadge }}
                            >
                              {isLightGlobalTheme ? (
                                <ShoppingCartIcon />
                              ) : (
                                <ShoppingCartIconYellow />
                              )}
                            </Badge>
                          </Link>
                        </Box>

                        <Box
                          m={2}
                          component="div"
                          display="inline"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            margin: "0px",
                            marginTop: "-14px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                              marginRight: "5px",
                            }}
                          >
                            <span className={classes.spanHeader}>UNIA</span>
                            <span
                              className={classes.spanHeader}
                              style={{ marginTop: "6px" }}
                            >
                              EUROPEJSKA
                            </span>
                          </div>{" "}
                        </Box>
                        <Box style={{ marginTop: "-8px" }}>
                          <div>
                            <EUflag
                              style={{ height: "40px", marginTop: "-3px" }}
                            />
                          </div>
                        </Box>
                      </Hidden> */}
                      <Hidden mdDown>
                        <Box className={classes.title1}>
                          <Box mr={4} ml={2} component="div" display="inline">
                            <HBIcon
                              style={{ cursor: "pointer" }}
                              // change when original svg will be restored
                              // style={{ height: "50px", cursor: "pointer" }}
                              onClick={() => history.push("/")}
                            />
                          </Box>
                          {navigationLinks.map((link) =>
                            link.header ? (
                              <Box position="relative">
                                <Typography
                                  className={`${classes.a} ${
                                    (pathname === "/products" ||
                                      pathname === "/active-tests" ||
                                      pathname === "/results") &&
                                    classes.activePage
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setMenuTestsOpened(!menuTestsOpened)
                                  }
                                  src={`/org-media/files${my.avatarPath}`}
                                >
                                  BADANIA
                                </Typography>
                                {menuTestsOpened && (
                                  <ClickAwayListener
                                    onClickAway={() =>
                                      setMenuTestsOpened(false)
                                    }
                                  >
                                    <Box
                                      position="absolute"
                                      left={15}
                                      style={{
                                        zIndex: "101",
                                        minWidth: "165px",
                                      }}
                                    >
                                      <Card
                                        variant="outlined"
                                        className={classes.card}
                                        p={1}
                                      >
                                        <CardContent
                                          p={1}
                                          style={{ padding: "0px" }}
                                        >
                                          <List
                                            style={{
                                              paddingTop: "0px",
                                              paddingBottom: "0px",
                                            }}
                                          >
                                            <ListItem
                                              button
                                              style={{ padding: "6px 8px" }}
                                              onClick={() => {
                                                history.push("/products");
                                                setMenuTestsOpened(false);
                                              }}
                                            >
                                              <ListItemText
                                                primary={
                                                  <Typography
                                                    className={
                                                      classes.listItemText
                                                    }
                                                  >
                                                    Kup Badanie
                                                  </Typography>
                                                }
                                              ></ListItemText>
                                            </ListItem>
                                            <ListItem
                                              button
                                              style={{ padding: "6px 8px" }}
                                              onClick={() => {
                                                history.push("/active-tests");
                                                setMenuTestsOpened(false);
                                              }}
                                            >
                                              <ListItemText
                                                primary={
                                                  <Typography
                                                    className={
                                                      classes.listItemText
                                                    }
                                                  >
                                                    Twoje Badanie
                                                  </Typography>
                                                }
                                              ></ListItemText>
                                            </ListItem>
                                            <ListItem
                                              button
                                              style={{ padding: "6px 8px" }}
                                              onClick={() => {
                                                history.push("/results");
                                                setMenuTestsOpened(false);
                                              }}
                                            >
                                              <ListItemText
                                                primary={
                                                  <Typography
                                                    className={
                                                      classes.listItemText
                                                    }
                                                  >
                                                    Sprawdź wyniki
                                                  </Typography>
                                                }
                                              ></ListItemText>
                                            </ListItem>
                                          </List>
                                        </CardContent>
                                      </Card>
                                    </Box>
                                  </ClickAwayListener>
                                )}
                              </Box>
                            ) : (
                              !link.nested && (
                                <Link
                                  className={`${classes.a} ${
                                    pathname === link.href && classes.activePage
                                  }`}
                                  to={link.href}
                                >
                                  {link.label}
                                </Link>
                              )
                            )
                          )}
                        </Box>{" "}
                        {isUser && (
                          <>
                            <Tooltip title="Otwórz przypisany do konta QR kod">
                              <IconButton className={classes.iconHeader}>
                                <CropFreeIcon
                                  onClick={() => setQROpened(true)}
                                />
                              </IconButton>
                            </Tooltip>
                            <QRCodeModal
                              handleClose={() => setQROpened(false)}
                              open={qrOpened}
                            />
                          </>
                        )}
                        <Box
                          mr={4}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {" "}
                          <Box
                            position="relative"
                            mr={2}
                            ml={2}
                            className={`${
                              my.avatarPath &&
                              pathname === "/profile" &&
                              classes.activePageAvatar
                            } ${my.avatarPath && classes.avatarHover}`}
                            style={
                              {
                                // display: "flex",
                                // alignItems: "flex-end",
                                // justifyContent: "center",
                              }
                            }
                          >
                            {my.avatarPath ? (
                              <Avatar
                                style={{
                                  cursor: "pointer",
                                  height: "35px",
                                  width: "35px",
                                }}
                                onClick={() => setMenuOpened(!menuOpened)}
                                src={`/org-media/files${my.avatarPath}`}
                              />
                            ) : (
                              <Box
                                className={`${classes.aIcon} ${
                                  classes.aIconPadding
                                }  ${
                                  pathname === "/profile" &&
                                  classes.activePageIcon
                                } ${
                                  pathname === "/profile" &&
                                  classes.activePageIconPadding
                                }`}
                                onClick={() => setMenuOpened(!menuOpened)}
                                style={{ cursor: "pointer" }}
                              >
                                {isLightGlobalTheme ? (
                                  <ProfileIcon
                                    style={{
                                      cursor: "pointer",
                                      marginBottom: "-5px",
                                    }}
                                  />
                                ) : (
                                  <ProfileIconYellow
                                    style={{
                                      cursor: "pointer",
                                      marginBottom: "-5px",
                                    }}
                                  />
                                )}
                              </Box>
                            )}
                            {menuOpened && (
                              <ClickAwayListener
                                onClickAway={() => setMenuOpened(false)}
                              >
                                <Box
                                  position="absolute"
                                  // right={0}
                                  mt={1}
                                  style={{ zIndex: "101" }}
                                >
                                  <Card
                                    variant="outlined"
                                    className={classes.card}
                                    p={1}
                                  >
                                    <CardContent
                                      p={1}
                                      style={{ padding: "0px" }}
                                    >
                                      <List>
                                        <ListItem
                                          button
                                          className={classes.listItemText}
                                          onClick={() => {
                                            history.push("/profile");
                                            setMenuOpened(false);
                                          }}
                                        >
                                          <ListItemText
                                            primary={
                                              <Typography
                                                className={classes.listItemText}
                                              >
                                                Twój profil
                                              </Typography>
                                            }
                                          ></ListItemText>
                                        </ListItem>
                                        <ListItem
                                          button
                                          onClick={() => {
                                            history.push("/proteges");
                                            setMenuOpened(false);
                                          }}
                                        >
                                          <ListItemText
                                            primary={
                                              <Typography
                                                className={classes.listItemText}
                                              >
                                                Profil innej osoby
                                              </Typography>
                                            }
                                          ></ListItemText>
                                        </ListItem>
                                        <ListItem button onClick={() => {}}>
                                          <ListItemText
                                            primary={
                                              <Typography
                                                className={classes.listItemText}
                                              >
                                                Pomoc
                                              </Typography>
                                            }
                                          ></ListItemText>
                                        </ListItem>
                                        <ListItem
                                          button
                                          onClick={() => {
                                            const isUser =
                                              my.user.authorities.localeCompare(
                                                "ROLE_USER"
                                              ) === 0;
                                            dispatch(logout({ isUser }));
                                            setMenuOpened(false);
                                          }}
                                        >
                                          <ListItemText
                                            primary={
                                              <Typography
                                                className={classes.listItemText}
                                              >
                                                Wyloguj
                                              </Typography>
                                            }
                                          ></ListItemText>
                                        </ListItem>
                                      </List>
                                    </CardContent>
                                  </Card>
                                </Box>
                              </ClickAwayListener>
                            )}
                          </Box>
                          <Box
                            style={{ height: "36px", cursor: "pointer" }}
                            className={`${classes.aIcon} ${
                              classes.aIconPadding
                            }  ${
                              pathname === "/test-kit/add" &&
                              classes.activePageIcon
                            } ${
                              pathname === "/test-kit/add" &&
                              classes.activePageIconPadding
                            }`}
                            onClick={() => history.push("/test-kit/add")}
                          >
                            <Badge
                              badgeContent={parseInt(
                                shoppingCartProducts.length
                              )}
                              classes={{ badge: classes.customBadge }}
                            >
                              {isLightGlobalTheme ? (
                                <ShoppingCartIcon />
                              ) : (
                                <ShoppingCartIconYellow />
                              )}
                            </Badge>{" "}
                          </Box>
                        </Box>
                        <Box
                          m={2}
                          component="div"
                          display="inline"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            margin: "0px",
                            marginTop: "-14px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                              marginRight: "5px",
                            }}
                          >
                            <span className={classes.spanHeader}>UNIA</span>
                            <span
                              className={classes.spanHeader}
                              style={{ marginTop: "6px" }}
                            >
                              EUROPEJSKA
                            </span>
                          </div>{" "}
                        </Box>
                        <Box style={{ marginTop: "-8px" }}>
                          <div>
                            <EUflag
                              style={{ height: "40px", marginTop: "-3px" }}
                            />
                          </div>
                        </Box>
                      </Hidden>
                      <Sidebar
                        open={openSidebar}
                        onOpen={handleSidebarOpen}
                        onClose={handleSidebarClose}
                        navigationLinks={navigationLinks}
                        history={history}
                        pathname={pathname}
                      />
                    </Toolbar>
                  </AppBar>
                </Grid>

                {pathname !== "/" ? (
                  <Grid item xs={12} className={classes.mainContent}>
                    <Paper
                      className={
                        pathname === "/login" || pathname === "/register"
                          ? classes.paperContainer
                          : classes.paperContainerGray
                      }
                    >
                      {/* <Breadcrumbs /> */}
                      {children}
                    </Paper>
                  </Grid>
                ) : (
                  <Grid item xs={12} className={classes.mainContent}>
                    <Box p={2} className={classes.paperContainerStartPage}>
                      <StartPage />
                    </Box>
                  </Grid>
                )}
              </Grid>
              {!cookiesAccepted && <Cookies />}
            </Box>
          </>
        ) : (
          <Box className={classes.paperMainContainerAdmin}>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" className={classes.titleAdmin}>
                  {`Panel administratora organizacji: ${orgName}`}
                </Typography>

                <Button
                  color="inherit"
                  onClick={() => dispatch(logout({ isUser }))}
                >
                  Wyloguj się
                </Button>
              </Toolbar>{" "}
            </AppBar>
            <Paper>
              <CenteredTabs
                fullwidth
                variant="scrollable"
                value={pathname}
                onChange={(v, newValue) => history.push(newValue)}
                scrollButtons="auto"
              >
                <Tab label="Przesiewy" value={"/admin-screening"} />
              </CenteredTabs>
            </Paper>
            {children}
          </Box>
        ))}
      {pathname === "/" && !isOwner && <StartPageLower />}
      {!loggedIn && children}
    </>
  );
};

export default connect(
  (state) => ({
    loggedIn: state.loggedIn,
    my: state.my,
    // cartItems: state.cartItems,
  }),
  (dispatch) => ({
    logout: () => dispatch(loogutAction()),
    fetchOrganization: (tenantId) => dispatch(fetchOrganization(tenantId)),
  })
)(Header);
