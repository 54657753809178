import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Can from "../components/can";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { normalizeZIP } from "../components/address-form/shipping-info";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { validate } from "email-validator";
import {
  addPerson,
  editUser,
  alertAdd,
  fetchMatchingUsers,
} from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { checkPermission } from "../rbac";
import { PersonForm } from "../components/person-form";
import Alert from "@material-ui/lab/Alert";
import PageTitle from "../components/page-title";
import { ConfirmDialogMUI } from "../components/confirm-dialog-mui";
import IconButton from "@material-ui/core/IconButton";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import ClearIcon from "@material-ui/icons/Clear";
import GroupsAndFormsPermissionsDialog from "../components/groups-and-forms-permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.primary,
    background: "WhiteSmoke",
  },
}));

const roleTypes = [
  {
    type: "ROLE_OWNER",
    description: "Administrator",
  },
  {
    type: "ROLE_EDITOR",
    description:
      process.env.REACT_APP_USE_PATIENT === "true" ? "Lekarz" : "Redaktor",
  },
  {
    type: "ROLE_USER",
    description:
      process.env.REACT_APP_USE_PATIENT === "true" ? "Pacjent" : "Użytkownik",
  },
];

const emptyDefaultPerson = {
  hospitalIdentifier: null,
  role: "",
  firstName: "",
  surname: "",
  phone: "",
  mail: "",
  title: "",
  description: "",
  enableProfileDataEdition: false,
  address: {
    name: "",
    street: "",
    city: "",
    zipCode: "",
    post: "",
    phone: "",
  },
};

const AddNewPersonsPage = ({ history }) => {
  const classes = useStyles();

  const [person, setPerson] = useState(emptyDefaultPerson);
  const [editedPerson, setEditedPerson] = useState({});
  const [canEditProfile, setCanEditProfile] = useState(false);

  const [busy, setBusy] = useState(false);
  const [valid, setValid] = useState(false);

  const [edition, setEdition] = useState(false);
  const [openConfirmPanel, setOpenConfirmPanel] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");

  const dispatch = useDispatch();
  const organization = useSelector((s) => s.organization);
  const roles = useSelector((s) => s.roles);
  const canSetAdminRole = checkPermission("admin:setAdminRole", roles);

  const [dataToCheck, setDataToCheck] = useState({});
  const [isPhoneInvalid, setIsPhoneInvalid] = useState(false);
  const [isMailInvalid, setIsMailInvalid] = useState(false);
  const [checked, setChecked] = useState(false);
  const [
    isHospitalIdentifierInvalid,
    setIsHospitalIdentifierInvalid,
  ] = useState(false);

  const [
    openConfirmToOpenPermissionPanel,
    setOpenConfirmToOpenPermissionPanel,
  ] = useState(false);
  const [personToEditPermissions, setPersonToEditPermissions] = useState(false);
  const [
    openFormsAndGroupsPermissionsDialog,
    setOpenFormsAndGroupsPermissionsDialog,
  ] = useState(false);

  useEffect(() => {
    if (alertMessage !== "") {
      setTimeout(() => {
        setAlertMessage("");
      }, 3000);
    }
  }, [alertMessage]);

  useEffect(() => {
    async function fetchMatchingUser() {
      if (dataToCheck && !(Object.keys(dataToCheck).length === 0)) {
        const response = await dispatch(fetchMatchingUsers(dataToCheck));
        if (response.status === 200 && response.data.length !== 0) {
          setOpenConfirmPanel(true);
          setEditedPerson(response.data[0]);
          setValid(false);
          setChecked(true);
        } else if (response.status === 409) {
          setIsMailInvalid(response.data.message.includes("email-exists"));
          setIsHospitalIdentifierInvalid(
            response.data.message.includes("hospital-identifier")
          );
          setIsPhoneInvalid(response.data.message.includes("phone-exists"));
          setChecked(true);
        } else if (response.status === 200 && response.data.length === 0) {
          setIsMailInvalid(false);
          setIsPhoneInvalid(false);
          setIsHospitalIdentifierInvalid(false);
          setChecked(true);
        }
      }
      if (dataToCheck?.phone === "+48") {
        setIsPhoneInvalid(false);
      }
    }
    fetchMatchingUser();
  }, [dataToCheck]);

  useEffect(() => {
    if (edition) {
      const p = editedPerson;
      setPerson({
        id: p.id || "",
        hospitalIdentifier: p.hospitalIdentifier || null,
        role: p.user.authorities || "",
        firstName: p.firstName || "",
        surname: p.surname || "",
        phone: p.phone || null,
        mail: p.mail || "",
        title: p.title || "",
        description: p.description || "",
        enableProfileDataEdition: p.enableProfileDataEdition || true,
        address:
          {
            name: p.address?.name || "",
            street: p.address?.street || "",
            city: p.address?.city || "",
            zipCode: p.address?.zipCode || "",
            post: p.address?.post || "",
            phone: p.address?.phoe || "",
          } || {},
        user: p.user || {},
      });
    }
  }, [edition]);

  useEffect(() => {
    if (person.address?.zipCode?.length > 0) {
      if (person.role !== "ROLE_USER") {
        setValid(
          person.role !== "" &&
            person.firstName !== "" &&
            person.surname !== "" &&
            validate(person.mail) &&
            person.address?.zipCode?.length === 6
        );
      } else {
        setValid(
          person.role !== "" &&
            (person.phone?.length > 3 || validate(person.mail)) &&
            person.address?.zipCode?.length === 6
        );
      }
    } else {
      if (person.role !== "ROLE_USER") {
        setValid(
          person.role !== "" &&
            person.firstName !== "" &&
            person.surname !== "" &&
            validate(person.mail)
        );
      } else {
        setValid(
          person.role !== "" &&
            (person.phone?.length > 3 || validate(person.mail))
        );
      }
    }
  }, [person]);

  const profileDataChange = (value, p, fieldProperty) => {
    if (fieldProperty === "role") {
      setPerson({
        ...person,
        mail: "",
        phone: "",
        enableProfileDataEdition: value === "ROLE_OWNER" || canEditProfile,
        [fieldProperty]: value,
      });
    } else {
      setPerson((prevState) => ({
        ...prevState,
        [fieldProperty]: value,
      }));
    }

    if (fieldProperty === "enableProfileDataEdition") {
      setCanEditProfile(value);
    }
  };

  const addressDataChange = (value, person, fieldProperty) => {
    setPerson((prevState) => ({
      ...prevState,
      address: {
        ...person.address,
        [fieldProperty]:
          fieldProperty.localeCompare("zipCode") === 0
            ? normalizeZIP(value, person.address.zipCode)
            : value,
      },
    }));
  };

  const dataIsUsed = async (value, fieldProperty) => {
    if (person.role !== "" && !edition) {
      if (fieldProperty === "mail") {
        setDataToCheck({
          ...dataToCheck,
          ...(value || value === "" ? { email: value } : {}),
        });
      } else {
        setDataToCheck({
          ...dataToCheck,
          ...(value || value === "" ? { [fieldProperty]: value } : {}),
        });
      }
    }
  };

  const handleChecbox = (event, person) => {
    setPerson({
      ...person,
      [event.target.name]: event.target.checked,
    });
  };

  const editPersonHandler = async () => {
    setBusy(true);
    const response = await dispatch(editUser(person));
    const message = response.data.message;

    if (response.status === 200) {
      dispatch(
        alertAdd({
          text: "Edycja użytkownika przebiegła pomyślnie.",
          isSuccess: true,
        })
      );
      history.push("/persons");
    } else if (message.includes("Phone is not unique")) {
      dispatch(
        alertAdd({
          text:
            "Nie udało się dokonać edycji użytkownika. Podany numer telefonu istnieje już w systemie.",
          isError: true,
        })
      );
    } else if (message === "hospital-identifier-exists") {
      dispatch(
        alertAdd({
          text: "Wprowadzony identyfikator już istnieje",
          isError: true,
        })
      );
    } else if (message === "hospital-identifier-empty") {
      dispatch(
        alertAdd({
          text: "Wprowadzony identyfikator jest pusty",
          isError: true,
        })
      );
    }
    setBusy(false);
  };

  const addPersonHandler = async () => {
    const hasPersonVerifiedMail = validate(person.mail);

    if (person?.phone?.length < 3) {
      setPerson({
        ...person,
        phone: "",
      });
    }

    if (hasPersonVerifiedMail || person?.phone?.length > 3) {
      try {
        setBusy(true);

        const { mail, phone, ...personData } = person;

        const result = await dispatch(
          addPerson({
            person: {
              ...personData,
              ...(mail ? { mail } : {}),
              ...(phone
                ? phone.length > 3
                  ? { phone: phone?.replace(/[^+\d]+/g, "") }
                  : ""
                : {}),
            },
            orgId: organization.id,
          })
        );

        dispatch(
          alertAdd({
            text: "Dodano użytkownika",
            isSuccess: true,
          })
        );

        setPersonToEditPermissions(result);
        setOpenConfirmToOpenPermissionPanel(true);

        setBusy(false);
      } catch (e) {
        let errorMessage;
        if (e.response.status === 409) {
          const message = e.response.data.message;
          if (message.includes("Phone is not unique")) {
            // setOpenConfirmPanel(true);
            errorMessage = "Numer telefonu jest już używany w systemie.";
          } else if (message.includes("Email is not unique")) {
            // setOpenConfirmPanel(true);
            errorMessage = "Adres email jest już używany w systemie.";
          } else if (message.includes("hospital-identifier-exists")) {
            // setOpenConfirmPanel(true);
            errorMessage = "Identyfikator jest już używany w systemie.";
          }
        } else {
          errorMessage = `Błąd podczas dodwania użytkownika, (${e.message}).`;
        }
        setBusy(false);
        setAlertMessage(errorMessage);
        window.scrollTo(0, 0);
      }
    }
  };

  const page = () => (
    <div className={classes.root}>
      {
        <ConfirmDialogMUI
          handleClose={() => {
            setOpenConfirmPanel(false);
          }}
          open={openConfirmPanel && person.role === "ROLE_USER"}
          text={"Taki użytkownik już istnieje. Czy edytować jego dane?"}
          yesAction={() => {
            setEdition(true);
            setValid(true);
          }}
          noAction={() => {
            setEdition(false);
            setValid(true);
          }}
        />
      }
      <ConfirmDialogMUI
        handleClose={() => {
          setOpenConfirmToOpenPermissionPanel(false);
        }}
        open={openConfirmToOpenPermissionPanel}
        text={
          "Czy chcesz otworzyć kreator dodawania użytkownika do projektów i formularzy?"
        }
        yesAction={() => {
          setOpenFormsAndGroupsPermissionsDialog(true);
        }}
        noAction={() => {
          history.push("/persons");
        }}
      />
      <GroupsAndFormsPermissionsDialog
        open={openFormsAndGroupsPermissionsDialog}
        handleClose={() => setOpenFormsAndGroupsPermissionsDialog(false)}
        person={personToEditPermissions}
        history={history}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box p={1}>
            <PageTitle title={"Dodaj nową osobę do organizacji"} />
          </Box>
          {alertMessage !== "" && (
            <Box p={1}>
              <Alert severity="warning">{alertMessage}</Alert>
            </Box>
          )}
          <Box p={1} flexDirection="row">
            <IconButton
              color="primary"
              size="small"
              onClick={() => history.goBack()}
            >
              <NavigateBeforeIcon />
              Powrót
            </IconButton>
            &nbsp;&nbsp;&nbsp;
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                setPerson(emptyDefaultPerson);
                setEdition(false);
                setIsMailInvalid(false);
                setIsMailInvalid(false);
                setIsHospitalIdentifierInvalid(false);
              }}
            >
              <ClearIcon />
              Wyczyść wszystko
            </IconButton>
          </Box>
          <Box p={1}>
            <Paper className={classes.paper}>
              <Box mt={1}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="select-outlined-label">Rola</InputLabel>
                  <Select
                    labelId="select-outlined-label"
                    id="select-outlined"
                    value={person.role || ""}
                    fullWidth
                    onChange={(event) =>
                      profileDataChange(event.target.value, person, "role")
                    }
                    label="Rola"
                  >
                    {roleTypes.map((role, index) => {
                      if (
                        role.type === "ROLE_OWNER" ||
                        role.type === "ROLE_EDITOR"
                      ) {
                        if (canSetAdminRole) {
                          return (
                            <MenuItem key={index} value={role.type}>
                              {role.description}
                            </MenuItem>
                          );
                        } else {
                          return null;
                        }
                      }

                      return (
                        <MenuItem key={index} value={role.type}>
                          {role.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box mt={1}>
                <Typography variant="h6" component="h1">
                  Dane personalne:
                </Typography>
                <PersonForm
                  index={person.hospitalIdentifier}
                  data={person}
                  profileDataChangeHandler={profileDataChange}
                  addressDataChangeHandler={addressDataChange}
                  handleChecbox={handleChecbox}
                  edit={edition}
                  dataIsUsed={dataIsUsed}
                  isPhoneInvalid={isPhoneInvalid}
                  isMailInvalid={isMailInvalid}
                  isHospitalIdentifierInvalid={isHospitalIdentifierInvalid}
                />
              </Box>
            </Paper>
            <br />
          </Box>
        </Grid>
      </Grid>
      <Box position="fixed" bottom={20} right="calc(100% - 100vw + 30px)">
        <Button
          variant="contained"
          color="primary"
          onClick={!edition ? addPersonHandler : editPersonHandler}
          disabled={
            busy ||
            !checked ||
            !valid ||
            isPhoneInvalid ||
            isMailInvalid ||
            isHospitalIdentifierInvalid
          }
        >
          {busy && <CircularProgress size={24} style={{ color: "white" }} />}
          {!edition ? "Dodaj osobę" : "Edytuj osobę"}
        </Button>
      </Box>
    </div>
  );

  const redirect = () => <Redirect to="/" />;

  return <Can permission="add-persons-page:view" ok={page} not={redirect} />;
};

export default AddNewPersonsPage;
