import React from "react";
import { Button, TableCell, TableRow } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { format } from "date-fns";

const OrganizationRow = ({
  orgData,
  setOpenOrganizationModal,
  setOrgToEdit,
  setOrganizationHandlerType,
}) => {
  const {
    orgName,
    agreement,
    contactPerson,
    canViewStatistics,
    orgMail,
    screenings,
  } = orgData;

  const orgEditionHandler = () => {
    setOrganizationHandlerType("edition");
    setOrgToEdit(orgData);
    setOpenOrganizationModal(true);
  };

  return (
    <TableRow>
      <TableCell>{orgName}</TableCell>
      <TableCell>{agreement?.name || "---"}</TableCell>
      <TableCell>{orgMail || "---"}</TableCell>
      <TableCell>{`${contactPerson?.firstName || "---"} `}</TableCell>
      <TableCell>{`${contactPerson?.surname || "---"}`}</TableCell>
      <TableCell>
        {agreement?.signedAt
          ? format(new Date(agreement?.signedAt), "dd-MM-yyyy")
          : "---"}
      </TableCell>
      <TableCell>
        {screenings.map(({ label }) => (
          <p
            style={{
              borderBottom: screenings.length > 1 && "0.5px solid #d9d9d9",
            }}
          >
            {label}
          </p>
        ))}
      </TableCell>
      <TableCell>
        {canViewStatistics != null ? (
          canViewStatistics ? (
            <CheckCircleIcon style={{ fill: "#008000" }} />
          ) : (
            <RemoveCircleIcon style={{ fill: "#ff0000" }} />
          )
        ) : (
          "----"
        )}
      </TableCell>
      <TableCell>
        <Button
          variant={"contained"}
          color="primary"
          onClick={orgEditionHandler}
        >
          Edycja
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default OrganizationRow;
