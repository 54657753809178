import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 32px",
  },
  card: {
    minWidth: 250,
    borderRadius: "1rem",
    border: `0.5px solid ${theme.palette.hr.primary}`,
    boxShadow: "none",
  },
  mainGrid: {
    justifyContent: "center",
  },
  hr: {
    opacity: "0.5",
    margin: "2.5em 20px 2.5em 60px",
    border: `0.5px solid ${theme.palette.text.whiteHeader}`,
    [theme.breakpoints.down("lg")]: {
      margin: "1.5em 0 1.5em 0",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  locationButton: {
    marginTop: "3em",
    backgroundColor: theme.palette.background.orderButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `162px`,
    height: "50px",
  },
  text: {
    color: theme.palette.passwordIcon.primary,
    fontSize: theme.typography.paragraph,
    letterSpacing: "1.5px",
    fontWeight: "700",
  },
  paper: {
    border: `0.5px solid ${theme.palette.hr.primary}`,
    padding: "0 0 32px 0",
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  text: {
    color: theme.palette.passwordIcon.primary,
    fontSize: theme.typography.formLabel,
    letterSpacing: "1.5px",
    fontWeight: "700",
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingSmall: {
    padding: "32px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  boxLoginTitle: {
    background: theme.palette.background.loginBackground,
  },
  formHint: {
    fontSize: theme.typography.hintText || 12,
    color: theme.palette.text.primary2,
    letterSpacing: "0.7px",
    textAlign: "left",
  },
}));

const MessageSent = () => {
  const history = useHistory();
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => history.push("/start"), 5000);
  }, []);
  return (
    <Box className={classes.root}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12}>
          <hr className={classes.hr} />
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Paper className={classes.paper}>
            <Box className={`${classes.paddingSmall}`}>
              <Box m={1}>
                <Typography className={classes.text}>
                  Dziękujemy za skorzystanie z formularza kontaktowego,
                  odpowiemy w ciągu 24 godzin
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MessageSent;
