import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CssTextField, normalizeZIP } from "../shipping-info";
import { makeStyles } from "@material-ui/core/styles";
import ZipInput from "../ZipInput";
import {
  Grid,
  Checkbox,
  Typography,
  FormControlLabel,
  TextField,
  Button,
  Snackbar,
  Box,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  greenBorder: {
    borderColor: theme.palette.success.dark,
  },
  formHint: {
    fontSize: theme.typography.footerMobile,
    lineHeight: "1.4",
    margin: "-5px auto 5px",
    color: theme.palette.text.hintText,
    fontFamily: "Montserrat",
  },
}));

const BillingAddressForm = ({
  nameFV,
  setNameFV,
  streetFV,
  setStreetFV,
  cityFV,
  setCityFV,
  zipFV,
  setZipFV,
  postFV,
  setPostFV,
  taxID,
  setTaxID,
  setDataChanged,
  fetchData = false,
  NIPValidator,
}) => {
  const classes = useStyles();

  const [zipIsValid, setZipIsValid] = useState(false);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setValid(zipIsValid);
    // isValidListener && isValidListener(zipIsValid);
  }, [zipIsValid]);

  const maxNIPLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const [open, setOpen] = useState(false);
  const [copyDataFromProfile, setCopyDataFromProfile] = useState("");
  const [copyDataSeverity, setCopyDataSeverity] = useState("success");

  const billingData = useSelector((s) => s.my?.billingInfo);

  const fetchBillingData = () => {
    let isBillingDataEmpty = false;
    if (billingData) {
      isBillingDataEmpty = Object.values(billingData).every(
        (x) => x === null || x === ""
      );
    }

    if (!isBillingDataEmpty && billingData) {
      const { name, street, city, zipCode, post, taxId } = billingData;
      setNameFV(name || "");
      setStreetFV(street || "");
      setCityFV(city || "");
      setZipFV(zipCode || "");
      setPostFV(post || "");
      setTaxID(taxId || "");
      setCopyDataFromProfile("Dane do faktury zostały skopiowane");
      setOpen(true);
      setDataChanged(true);
    } else {
      setCopyDataSeverity("warning");
      setCopyDataFromProfile(
        "Brak danych w profilu, dane do faktury nie zostały skopiowane"
      );
      setOpen(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCopyDataFromProfile("");
    setOpen(false);
  };

  return (
    <>
      {fetchData && (
        <Box mt={1}>
          <Button
            size="small"
            startIcon={<GetAppIcon />}
            onClick={() => fetchBillingData()}
          >
            Skopiuj dane do faktury z profilu
          </Button>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={open}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
          >
            {copyDataFromProfile && (
              <Alert onClose={handleCloseSnackbar} severity={copyDataSeverity}>
                {copyDataFromProfile}
              </Alert>
            )}
          </Snackbar>
        </Box>
      )}
      <Box>
        <CssTextField
          placeholder="* Imię i nazwisko / nazwa *"
          value={nameFV}
          fullWidth
          required
          onChange={({ target: { value: name } }) => {
            setDataChanged(true);
            setNameFV(name);
          }}
          // error={!nameFV}
        />
      </Box>
      <Box mt={2}>
        <CssTextField
          placeholder="* Ulica"
          value={streetFV}
          required
          fullWidth
          onChange={({ target: { value: street } }) => {
            setDataChanged(true);
            setStreetFV(street);
          }}
          // error={!streetFV}
        />
      </Box>
      <Box mt={2}>
        <CssTextField
          placeholder="* Miasto"
          value={cityFV}
          required
          fullWidth
          onChange={({ target: { value: city } }) => {
            setDataChanged(true);
            setCityFV(city);
          }}

          // error={!cityFV}
        />
      </Box>
      <Box mt={2}>
        <ZipInput
          zipCode={zipFV}
          setZipCode={(newZip) => {
            setZipFV(normalizeZIP(newZip, zipFV));
          }}
          setDataChanged={setDataChanged}
          isValidListener={setZipIsValid}
        />
      </Box>
      {/* <Box mt={2}>
        <CssTextField
          placeholder="* Poczta"
          required
          value={postFV}
          fullWidth
          onChange={({ target: { value: post } }) => {
            setDataChanged(true);
            setPostFV(post);
          }}
        />
      </Box> */}
      <Box mt={2}>
        <CssTextField
          placeholder="* NIP"
          inputProps={{
            maxLength: 10,
          }}
          onInput={maxNIPLengthCheck}
          type="number"
          value={taxID}
          required
          fullWidth
          onChange={({ target: { value: taxID } }) => {
            setDataChanged(true);
            setTaxID(taxID);
          }}
          error={taxID && !NIPValidator(taxID)}
          // error={!taxID || !NIPValidator(taxID)}
          helperText={
            taxID && !NIPValidator(taxID) && `Nieprawidłowy numer NIP`
          }
        />
      </Box>
      <Box mt={2}>
        <span className={classes.formHint}>
          * Przy wyborze FV, pola wymagane
        </span>
      </Box>
    </>
  );
};

export default BillingAddressForm;
