import React, { useImperativeHandle, useMemo } from "react";
import {
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Grid,
  createMuiTheme,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import StopIcon from "@material-ui/icons/Stop";
import _ from "lodash";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import { lightTheme, fontSizeLarge } from "../../theme";
import { useStyles } from "./results-per-package-table";
import { ResultsRow } from "./results-per-package-table";
import ResultsMapper from "./results-mapper";
import { RESULT_MAP } from "../../pages/doctor-page/desciption-lock";
import PDFContext from "./pdf-context";
import hbIcon from "../../assets/hb-tmp.svg";
import { ralewayRegular, ralewaySemiBold } from "./fonts.js";

const GeneratePDF = React.forwardRef(
  ({ data, doctorDescription, patient, result }, ref) => {
    const classes = useStyles();
    const audiometry = useMemo(
      () => data?.testsData?.find(({ testType }) => testType === "AP"),
      [data]
    );
    const fontSize = 18;
    const ptTomm = 0.3527777778;
    const patientDataFont = {
      letterSpacing: "1px",
      lineHeight: "16px",
      fontSize: 18,
      fontWeight: "bold",
      color: "#3458A4",
      textTransform: "uppercase",
    };

    useImperativeHandle(ref, () => ({
      downloadPDF,
    }));

    const addText = (doc, text, y, margin, paperH, paperW) => {
      var split = text;
      if (!Array.isArray(split)) {
        split = doc.splitTextToSize(
          split.replaceAll("\t", "    "),
          paperW - 2 * margin
        ); //w naszej czcionce jspdf ucina cały tekt po znaku tabulacji, dlatego trzeba zamienić na 4 spacje
      }
      if (y + split.length * doc.getLineHeight() * ptTomm > paperH - margin) {
        const possibleHeight = paperH - margin - y;
        const maxLines = Math.floor(
          possibleHeight / (doc.getLineHeight() * ptTomm)
        );
        doc.text(split.slice(0, maxLines), margin, y);
        doc.addPage();
        y = margin;
        y = addText(doc, split.slice(maxLines), y, margin, paperH, paperW);
      } else {
        doc.text(split, margin, y);
        y += split.length * doc.getLineHeight() * ptTomm;
      }
      return y;
    };

    const downloadPDF = async () => {
      const doc = new jsPDF({ orientation: "p", unit: "mm", format: "a4" });
      doc.addFileToVFS("RalewayRegular.tff", ralewayRegular);
      doc.addFont("RalewayRegular.tff", "RalewayRegular", "normal");
      doc.addFileToVFS("RalewaySemiBold.tff", ralewaySemiBold);
      doc.addFont("RalewaySemiBold.tff", "RalewaySemiBold", "normal");
      doc.setFont("RalewayRegular");
      doc.setFontSize(10);
      const margin = 10;
      const paperH = 297;
      const paperW = 210;
      const arr = ["title", "title2", "table", "results"];
      data &&
        data.testsData.forEach(
          (testData, index) => testData.data && arr.push(`results-${index}`)
        );

      let i = 0;
      let y = margin;
      const targetWidth = paperW - 2 * margin;
      while (i < arr.length) {
        const elem = document.getElementById(arr[i]);
        if (elem) {
          const canvas = await html2canvas(elem, {
            onclone: function (clonedDoc) {
              clonedDoc.getElementById(arr[i]).style.visibility = "visible";
            },
          });
          var targetWidthTmp = targetWidth;
          var computedHeight = (targetWidth / canvas.width) * canvas.height;
          if (y + computedHeight > paperH - 2 * margin) {
            doc.addPage();
            y = margin;
            if (computedHeight > paperH - 2 * margin) {
              computedHeight = paperH - 2 * margin;
              targetWidthTmp = (computedHeight / canvas.height) * canvas.width;
            }
          }
          doc.addImage(canvas, margin, y, targetWidthTmp, computedHeight);
          y += computedHeight;
        }
        if (arr[i] === "title2" && doctorDescription) {
          doc.text(
            `Data wykonania opisu: ${moment
              .utc(doctorDescription.ts)
              .local()
              .format("DD/MM/YYYY HH:mm")}`,
            paperW - margin,
            y,
            {
              align: "right",
            }
          );
          doc.setFont("RalewaySemiBold");
          doc.text("Opis badania:", margin + 2, y);
          y += doc.getTextDimensions("Opis badania:").h + 1;
          doc.setFont("RalewayRegular");
          y = addText(
            doc,
            doctorDescription.description,
            y,
            margin + 2,
            paperH,
            paperW
          );
          doc.setFont("RalewaySemiBold");
          doc.text("Lekarz opisujący: ", margin + 2, y);
          const w = doc.getTextDimensions("Lekarz opisujący: ").w;
          doc.setFont("RalewayRegular");
          doc.text(doctorDescription.signature, margin + 2 + w, y);
          y += doc.getTextDimensions("Opis lekarza").h;
        }

        i++;
      }
      doc.setFontSize(8);
      doc.text(
        `Data wygenerowania dokumentu: ${moment
          .utc()
          .local()
          .format("DD-MM-YYYY HH:mm")}`,
        margin,
        paperH - margin
      );
      const pageCount = doc.internal.getNumberOfPages();
      for (var p = 1; p <= pageCount; p++) {
        doc.setPage(p);
        doc.text(String(p), paperW - margin + 1, paperH - margin + 1);
      }
      doc.save("opis_lekarski.pdf");
    };

    return (
      <Box
        style={{
          width: "100%",
          height: 10,
          overflow: "hidden",
        }}
      >
        <PDFContext.Provider value={true}>
          <ThemeProvider
            theme={createMuiTheme(_.merge({}, lightTheme, fontSizeLarge))}
          >
            <Grid
              id={"doc"}
              container
              style={{
                display: "flex",
                justifyContent: "center",
                width: "300mm",
                backgroundColor: "white",
                visibility: "hidden",
              }}
            >
              <Grid item xs={12}>
                <Paper style={{ borderRadius: "0px" }}>
                  <Box
                    id={"title"}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                      background: "#FBFBFB",
                      padding: "32px 64px",
                    }}
                  >
                    <img src={hbIcon} />
                    <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                    <Typography
                      style={{
                        fontWeight: "600",
                        letterSpacing: "2px",
                        textTransform: "uppercase",
                        fontSize,
                        color: "#3458A4",
                      }}
                    >
                      Wyniki szczegółowe
                    </Typography>
                    <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
                    <img src={hbIcon} style={{ visibility: "hidden" }} />
                  </Box>
                  <Box id={"title2"} style={{ padding: "16px" }}>
                    <Typography style={patientDataFont}>{`Dane pacjenta: ${
                      `${patient?.firstName} ${patient?.lastName}` || "---"
                    }, data urodzenia: ${moment(
                      new Date(
                        `${patient?.monthOfBirth}/01/${patient?.yearOfBirth}`
                      )
                    ).format("MM/YYYY")}`}</Typography>
                    <Typography
                      style={patientDataFont}
                    >{`Wypełnione dnia: od ${moment
                      .utc(data?.startTs)
                      .local()
                      .format("DD/MM/YYYY HH:mm")} do ${moment
                      .utc(data?.finishTs)
                      .local()
                      .format("DD/MM/YYYY HH:mm")}`}</Typography>
                    <br />
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold" }}
                    >{`Ogólny wynik pakietu badań: ${RESULT_MAP[result]}`}</Typography>
                    <br />
                  </Box>
                  {/* {doctorDescription && (
                    <Box>
                      <Typography
                        style={{
                          fontSize,
                          display: "inline",
                          fontWeight: "bold",
                        }}
                      >
                        Opis badania:
                      </Typography>
                      <Typography
                        align="right"
                        style={{
                          fontSize,
                          display: "inline",
                          float: "right",
                        }}
                      >{`Data wykonania opisu: ${moment
                        .utc(doctorDescription.ts)
                        .local()
                        .format("DD/MM/YYYY HH:mm")}`}</Typography>
                      <Typography
                        style={{ fontSize, overflowWrap: "anywhere" }}
                      >
                        {doctorDescription.description}
                      </Typography>
                      <br />
                      <Typography
                        style={{
                          fontSize,
                          fontWeight: "bold",
                          display: "inline",
                        }}
                      >{`Lekarz opisujący: `}</Typography>
                      <Typography style={{ fontSize, display: "inline" }}>
                        {doctorDescription.signature}
                      </Typography>
                    </Box>
                  )} */}
                  <br />
                  <Box>
                    <Box id={"table"} style={{ padding: "16px" }}>
                      <Typography style={{ fontSize, fontWeight: "bold" }}>
                        Wyniki szczegółowe:
                      </Typography>

                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-label="spanning table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className={classes.tableHeaderDoc}
                                size="small"
                              >
                                Test
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableHeaderDoc}
                                style={{ textAlign: "center" }}
                              >
                                Wynik ucha prawego
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableHeaderDoc}
                                style={{ textAlign: "center" }}
                              >
                                Wynik ucha lewego
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data?.testsData.map((testData, index) => (
                              <ResultsRow
                                key={index}
                                testData={testData}
                                forDocument={true}
                              />
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <br />
                    <br />
                    <Box style={{ marginTop: 24 }}>
                      {data?.testsData.map(
                        (testData, index) =>
                          testData.data && (
                            <ResultsMapper
                              key={index}
                              id={`results-${index}`}
                              test={testData}
                              forDocument={true}
                              audiometry={audiometry}
                            />
                          )
                      )}
                    </Box>
                    <br />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </ThemeProvider>
        </PDFContext.Provider>
      </Box>
    );
  }
);

export default GeneratePDF;
