import FillFormPage from "./pages/fill-form";
import FillSurveyPage from "./pages/fill-surevy";
import AddNewFormGroupPage from "./pages/add-form-group";
import RegisterOrganisation from "./pages/register-organization";
import LoginPage from "./pages/login-page";
import LoginEmployeePage from "./pages/login-employee-page";
import PasswordResetPage from "./pages/password-reset";
import PasswordEmployeeResetPage from "./pages/password-employee-reset";
import AddNewPersonsPage from "./pages/add-new-persons";
import FormsPage from "./pages/FormsPage";
import FilledFormsPage from "./pages/FilledFormsPage";
import ActiveFormsPage from "./pages/active-forms-page";
import Main from "./pages/main";
import ProfilePage from "./pages/profile";
import EditPerson from "./pages/edit-person";
import { Persons } from "./persons";
import Proteges from "./pages/proteges";
import { FilledFormData } from "./components/filled-form-data";
import { UserFilledFormData } from "./components/filled-form-data/user-filled-form-data";
import PasswordChangePage from "./pages/password-change";
import FilledFormDetails from "./pages/filled-form-details";
import UserAllFilledForms from "./pages/user-all-filled-forms";
import AdminSettingsPage from "./pages/admin-settings-page";
import PasswordResetMailPage from "./pages/password-reset-mail";
import TestKit from "./pages/test-kit-page";
import OrderTestKitPage from "./pages/unlogged-test-kit-page";
import ShopProductsPage from "./pages/unlogged-products-page";
import ViewAllData from "./pages/view-all-tests";
// import TestMain from "./pages/sat-form-page";
// import AddNewSet from "./pages/sat-form-page/add-new-set";
// import AddNewTest from "./pages/sat-form-page/add-new-test";
// import FillTest from "./pages/sat-form-page/fill-test";
// import TestResult from "./pages/sat-form-page/result";
import OrganizationTerms from "./pages/organization-terms";
import PasswordResetMailLinkExpiredPage from "./pages/password-reset-link-expired";
import AddNewFormPage from "./pages/add-new-form";
import GroupsPage from "./pages/groups-page";
import ResultsPage from "./pages/results-page";
import { APIKeys } from "./pages/api-keys";
import StartPage from "./pages/start-page";
import ContactPage from "./pages/contact-page";
import QrReaderPage from "./pages/qr-reader-page";
import QRCodeValidationPage from "./pages/qr-code-validation-page";
import TestAssignPage from "./pages/test-assign-page";
import SummaryAssign from "./pages/summary-assign";
import ActiveTestsPage from "./pages/active-tests";
import TestPurchasedDetails from "./pages/test-purchased-details";
import ProductsPage from "./pages/products-page";
import OrgAdminScreeningPage from "./pages/org-admin-screening";
import MessageSent from "./pages/message-sent";
import MessageSentLoggedUser from "./pages/message-sent-logged-user";
import contactLoggedUser from "./pages/contact-logged-user";
import { AccountActivatedPage } from "./pages/account-activation";
import { AccountNotActivatedPage } from "./pages/account-activation-not";
import ResultsPerPackagePage from "./components/detailed-results/results-per-package-table";
import TestStandsPage from "./pages/test-stands-page";
import TestStandsAllPage from "./pages/test-stands-all-page";
import UnloggedTestStandsAllPage from "./pages/unlogged-test-stands-all-page";

export const noHeaderRoutes = [
  {
    path: "/login",
    component: LoginPage,
    public: true,
  },
  {
    path: "/login-employee",
    component: LoginEmployeePage,
    public: true,
  },
  {
    path: "/register",
    component: RegisterOrganisation,
    public: true,
  },
  {
    path: "/password-reset",
    component: PasswordResetPage,
    public: true,
  },
  {
    path: "/password-employee-reset",
    component: PasswordEmployeeResetPage,
    public: true,
  },
  {
    path: "/password-set-new/:token",
    component: PasswordResetMailPage,
    public: true,
  },
  {
    path: "/password-reset-link-expired",
    component: PasswordResetMailLinkExpiredPage,
    public: true,
  },
  {
    path: "/start",
    component: StartPage,
    public: true,
  },
  {
    path: "/contact-form",
    component: ContactPage,
    public: true,
  },
  {
    path: "/products-to-buy",
    component: ShopProductsPage,
    public: true,
  },
  {
    path: "/order-test-kit",
    component: OrderTestKitPage,
    public: true,
  },
  {
    path: "/qr-reader",
    component: QrReaderPage,
    public: true,
  },
  {
    path: "/voucher/:voucherID",
    component: ShopProductsPage,
    public: true,
  },
  {
    path: "/message-sent",
    component: MessageSent,
    public: true,
  },
  {
    path: "/msg/account_activated",
    component: AccountActivatedPage,
    public: true,
  },
  {
    path: "/msg/account_activation_failed",
    component: AccountNotActivatedPage,
    public: true,
  },
  {
    path: "/hear-box-stations",
    component: UnloggedTestStandsAllPage,
    public: true,
  },
];

export const routes = [
  {
    path: "/",
    component: Main,
  },
  {
    path: "/settings",
    component: AdminSettingsPage,
  },
  // {
  //   path: "/activated-kits",
  //   component: FormsPage,
  // },
  // {
  //   path: "/filled-kits",
  //   component: FilledKits,
  // },
  // {
  //   path: "/forms",
  //   component: TestMain,
  // },
  // {
  //   path: "/forms/set/add",
  //   component: AddNewSet,
  // },
  // {
  //   path: "/forms/add/:scheduledId",
  //   component: AddNewTest,
  // },
  // {
  //   path: "/forms/fill/:scheduledId",
  //   component: FillTest,
  // },
  // {
  //   path: "/forms",
  //   component: FormsPage,
  // },
  {
    path: "/filled-forms",
    component: FilledFormsPage,
  },
  {
    path: "/groups",
    component: GroupsPage,
  },
  // {
  //   path: "/forms/active",
  //   component: ActiveFormsPage,
  // },
  // {
  //   path: "/forms/add",
  //   component: AddNewFormPage,
  // },
  // {
  //   path: "/forms/edit/:id",
  //   component: AddNewFormPage,
  // },
  // {
  //   path: "/forms/display/:id",
  //   component: AddNewFormPage,
  // },
  {
    path: "/form_group/add",
    component: AddNewFormGroupPage,
  },
  {
    path: "/form_group/edit/:id",
    component: AddNewFormGroupPage,
  },
  // {
  //   path: "/tests/result/:resultId",
  //   component: TestResult,
  // },
  {
    path: "/formdata/add/:formId",
    component: FillFormPage,
  },
  {
    path: "/fill-survey/:screeningId/:patientID",
    component: FillSurveyPage,
  },
  {
    path: "/fill-survey/:screeningId/",
    component: FillSurveyPage,
  },
  {
    path: "/filled-forms/all/user/:personId",
    component: UserAllFilledForms,
  },
  {
    path: "/formdata/fill-scheduled-test/:formId/:scheduledFormId",
    component: FillFormPage,
  },
  {
    path: "/formdata/edit/:formId/:formdataId",
    component: FillFormPage,
  },
  {
    path: "/person/add",
    component: AddNewPersonsPage,
  },
  {
    path: "/profile",
    component: ProfilePage,
  },
  {
    path: "/password-change",
    component: PasswordChangePage,
  },
  {
    path: "/persons",
    component: Persons,
  },
  {
    path: "/proteges",
    component: Proteges,
  },
  {
    path: "/persons/:personId",
    component: EditPerson,
  },
  {
    path: "/formdata/all/:formId",
    component: FilledFormData,
  },
  {
    path: "/formdata/all/:formId/:personId",
    component: UserFilledFormData,
  },
  {
    path: "/formdata/:formDataId",
    component: FilledFormDetails,
  },
  {
    path: "/formdata/z/:formDataId/:invalidateViewAt",
    component: FilledFormDetails,
  },
  {
    path: "/testdata",
    component: ViewAllData,
  },
  {
    path: "/terms",
    component: OrganizationTerms,
  },
  {
    path: "/results/:id",
    component: ResultsPage,
  },
  {
    path: "/results",
    component: ResultsPage,
  },
  {
    path: "/test-stands",
    component: TestStandsPage,
  },
  {
    path: "/profile/api-keys",
    component: APIKeys,
  },
  {
    path: "/contact",
    component: ContactPage,
  },
  {
    path: "/test-kit/add",
    component: TestKit,
  },
  {
    path: "/test-kit/edit/:id",
    component: TestKit,
  },
  {
    path: "/qr-code-validation/:qrCode",
    component: QRCodeValidationPage,
  },

  {
    path: "/summary-assign/:voucherID",
    component: SummaryAssign,
  },
  {
    path: "/purchased-test-assign/:orderId/:orderItemId",
    component: TestAssignPage,
  },
  {
    path: "/active-tests",
    component: ActiveTestsPage,
  },
  {
    path: "/active-test/:id",
    component: TestPurchasedDetails,
  },
  {
    path: "/voucher/:voucherID",
    component: QrReaderPage,
  },
  {
    path: "/products",
    component: ProductsPage,
  },
  {
    path: "/sent",
    component: MessageSentLoggedUser,
  },
  {
    path: "/send-message",
    component: contactLoggedUser,
  },
  {
    path: "/msg/account_activated",
    component: AccountActivatedPage,
  },
  {
    path: "/msg/account_activation_failed",
    component: AccountNotActivatedPage,
  },
  {
    path: "/test-stands-all",
    component: TestStandsAllPage,
  },

  // {
  //   path: "/msg/account_activation_failed",
  //   component: AccountNotActivatedPage,
  // },
  { path: "/detailed-results", component: ResultsPerPackagePage },
];
