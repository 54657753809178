import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  Tooltip,
} from "@material-ui/core";
import { getAxiosInstance } from "../../../redux/common";
import UserRow from "../user-row";
import UserModal from "../modal/user-modal";
import { Alert, TabPanel } from "@material-ui/lab";
import FilterProperty, {
  FilterPropertySelect,
} from "../../../components/filter-property/filter-property";
import SearchIcon from "@material-ui/icons/Search";
import { ConfirmDialogMUI } from "../../../components/confirm-dialog-mui";
import Loader from "../../../components/loader";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    padding: "1.5rem",
    marginTop: 10,
  },
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

const EmployeePanel = () => {
  const [filterQueries, setFilterQueries] = useState({});

  useEffect(() => {
    setDisplaySearchButton(true);
  }, [filterQueries]);

  const searchFilterHandler = () => {
    setDisplaySearchButton(false);
    const result = unfilteredUsers.filter(search, filterQueries);
    setUsers(result);
  };

  const clearAllFilterHandler = () => {
    setFilterQueries({});
    setDisplaySearchButton(false);
    const result = unfilteredUsers.sort(
      dynamicSort(`${order === "desc" ? "-" : ""}${orderBy}`)
    );
    setUsers(result);
  };

  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [unfilteredUsers, setUnfilteredUsers] = useState([]);

  const [usersCount, setUsersCount] = useState(0);

  const [fetchingUsers, setFetchingUsers] = useState(false);
  const [removingUser, setRemovingUser] = useState(false);

  const [openUserModal, setOpenUserModal] = useState(false);
  const [userHandlerType, setUserHandlerType] = useState("edition");
  const [userToEdit, setUserToEdit] = useState({});

  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const [additionCompleted, setAdditionCompleted] = useState(false);

  const [doctorToRemove, setDoctorToRemove] = useState({});
  const [openDoctorRemovePanel, setOpenDoctorRemovePanel] = useState(false);

  const [displaySearchButton, setDisplaySearchButton] = useState(false);

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    const arrayUsers = [...users];
    let sortParam = ``;
    sortParam = `${order === "desc" ? "-" : ""}${orderBy}`;
    setUsers(arrayUsers.sort(dynamicSort(sortParam)));
  }, [order, orderBy]);

  const dynamicSort = (property) => {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    if (property !== "titles") {
      return function (a, b) {
        var result =
          a[property].toLowerCase() < b[property].toLowerCase()
            ? -1
            : a[property].toLowerCase() > b[property].toLowerCase()
            ? 1
            : 0;
        return result * sortOrder;
      };
    } else {
      return function (a, b) {
        const [firstItem] = a[property];
        const [secondItem] = b[property];
        var result =
          firstItem === undefined || secondItem === undefined
            ? -1
            : firstItem < secondItem
            ? -1
            : firstItem > secondItem
            ? 1
            : 0;

        return result * sortOrder;
      };
    }
  };

  useEffect(() => {
    if (additionCompleted) {
      setTimeout(() => {
        setAdditionCompleted(false);
      }, 3000);
    }
  }, [additionCompleted]);

  const loadUsers = async () => {
    try {
      setFetchingUsers(true);
      const response = await getAxiosInstance().get("/medic/v1/doctor/all");
      if (response) {
        setFetchingUsers(false);
        const users = response.data;

        let sortParam = ``;
        sortParam = `${order === "desc" ? "-" : ""}${orderBy}`;
        setUsers(
          users
            .map((u) => (u.titles.length === 0 ? { ...u, titles: [""] } : u))
            .sort(dynamicSort(sortParam))
        );
        setUnfilteredUsers(
          users
            .map((u) => (u.titles.length === 0 ? { ...u, titles: [""] } : u))
            .sort(dynamicSort(sortParam))
        );
      }
    } catch (e) {
      setFetchingUsers(false);
      setUsers([]);
    }
  };

  function search(users) {
    return Object.keys(this).every((key) => {
      if (key !== "titles") {
        return users[key]
          .toString()
          .toLowerCase()
          .includes(this[key].toLowerCase());
      } else if (key === "titles") {
        return users[key].find((title) => title === this[key]);
      }
    });
  }

  const doctorRemoveHandler = async () => {
    setRemovingUser(true);
    const domain =
      window.location.hostname === "localhost"
        ? `${window.location.hostname}:${window.location.port}`
        : window.location.hostname;

    const secure = window.location.hostname === "localhost" ? `` : "s";
    let socket = new WebSocket(
      `ws${secure}://${domain}/medic/v1/owner/websocket/connect`
    );
    socket.onopen = async function (e) {
      try {
        const response = await getAxiosInstance().delete(`/owner/v1/remove`, {
          params: {
            userId: doctorToRemove.userId,
          },
        });
      } catch (e) {
        setFetchingUsers(false);
      }

      socket.onmessage = function (event) {
        if (event.data.includes("DoctorRemovedMedicOwnerWebSocketMessage")) {
          setRemovingUser(false);
          setDoctorToRemove({});
          loadUsers({});
          socket.close();
        }
      };

      socket.onerror = function (event) {
        setFetchingUsers(false);
        socket.close();
      };
    };
  };

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const additionButtonHandler = () => {
    setUserHandlerType("add");
    setUserToEdit({});
    setOpenUserModal(true);
  };

  const headerCells = [
    {
      title: "Imię i nazwisko",
      key: "name",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Email",
      key: "email",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Tytuł",
      key: "titles",
      sortable: true,
      filter: (key) => (
        <FilterPropertySelect
          label={"Rodzaj"}
          listItems={[
            { value: "lek", label: "Lekarz Specjalista" },
            { value: "dr", label: "Dr n. med." },
            { value: "prof", label: "Profesor" },
          ]}
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },

    {
      title: "Opcje",
      key: "options",
      sortable: false,
      filter: (key) => (
        <>
          <Box>
            {displaySearchButton && (
              <Button
                color="primary"
                // size="large"
                startIcon={<SearchIcon />}
                onClick={searchFilterHandler}
              >
                Szukaj
              </Button>
            )}
            {Object.keys(filterQueries).length !== 0 && (
              <Button
                color="primary"
                // size="large"
                startIcon={<ClearIcon />}
                onClick={clearAllFilterHandler}
              >
                <Tooltip title="Czyści wszystkie pola filtracji">
                  <span>Wyczyść</span>
                </Tooltip>
              </Button>
            )}
          </Box>
        </>
      ),
    },
  ];
  return fetchingUsers || removingUser ? (
    <Loader
      loading={true}
      text={fetchingUsers ? "Pobieranie użytkowników" : "Usuwanie użytkownika"}
    />
  ) : (
    <>
      {" "}
      <Button
        variant={"contained"}
        onClick={additionButtonHandler}
        style={{ marginRight: "10px" }}
      >
        Dodaj lekarza
      </Button>
      {"  "}
      {/* <Button variant={"contained"} onClick={orgRegisterButtonHandler}>
        Dodaj właściciela organizacji
      </Button> */}
      {additionCompleted && <Alert>Użytkownik dodany</Alert>}
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="Users list">
          <TableHead>
            <TableRow>
              {headerCells.map((headCell) =>
                headCell.sortable ? (
                  <TableCell
                    key={headCell.key}

                    // sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <Tooltip title="Sortuj">
                      <TableSortLabel
                        active={orderBy === headCell.key}
                        direction={orderBy === headCell.key ? order : "asc"}
                        onClick={() => {
                          handleSortRequest(headCell.key);
                        }}
                      >
                        {headCell.title}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ) : (
                  <TableCell>{headCell.title}</TableCell>
                )
              )}
            </TableRow>
            <TableRow>
              {headerCells.map((headCell) => (
                <TableCell key={headCell.key}>
                  {headCell.filter(headCell.key)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <UserRow
                key={index}
                user={user}
                setOpenUserModal={setOpenUserModal}
                setUserToEdit={setUserToEdit}
                setUserHandlerType={setUserHandlerType}
                setDoctorToRemove={setDoctorToRemove}
                setOpenDoctorRemovePanel={setOpenDoctorRemovePanel}
              />
            ))}
          </TableBody>
        </Table>
        {/* <TablePagination
          component="div"
          page={pageNr}
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          count={users.length}
          // count={usersCount}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={"Wierszy na stronę:"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} z ${count}`
          }
          className={classes.select}
        /> */}
      </TableContainer>
      <ConfirmDialogMUI
        handleClose={() => {
          setOpenDoctorRemovePanel(false);
        }}
        open={openDoctorRemovePanel}
        text={
          <div>
            <p>{`Czy na pewno usunąć lekarza:  ${doctorToRemove.name} ?`}</p>
            <p
              style={{ fontSize: "14px", marginTop: "10px", fontWeight: "300" }}
            >{`Usunięcie lekarza, który ma przypisane do siebie opisy spowoduje ich powrót na giełdę.`}</p>
          </div>
        }
        yesAction={doctorRemoveHandler}
        noAction={() => {
          setDoctorToRemove({});
        }}
      />
      {openUserModal && (
        <UserModal
          userType={"employee"}
          openUserModal={openUserModal}
          userHandlerType={userHandlerType}
          handleClose={() => {
            setOpenUserModal(false);
          }}
          userToEdit={userToEdit}
          loadUsers={loadUsers}
          setAdditionCompleted={setAdditionCompleted}
        />
      )}
    </>
  );
};

export default EmployeePanel;
