export const stationTypeMap = {
  Open: "Otwarty",
  Closed: "Zamknięty",
};

export const statusTypeMap = {
  OK: { label: "Gotowy", color: "green" },
  OFFLINE: { label: "Offline", color: "grey" },
  NOK: { label: "Błędy", color: "red" },
};
