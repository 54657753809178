import {
  Box,
  makeStyles,
  withStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Can from "../../components/can";
import FilterProperty from "../../components/filter-property/filter-property";
import {
  fetchOrderDetails,
  fetchPackageInfo,
  fetchProteges,
  fetchPurchasedProducts,
  fetchFilledFormsInfo,
} from "../../redux/actions";
import Loader from "../../components/loader";
import StopIcon from "@material-ui/icons/Stop";
import { Link as Link1 } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.text.alternative2,
    letterSpacing: "1.2px",
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    fontWeight: "700",
    "&:hover": {
      color: theme.palette.text.hintText,
    },
  },
}))(Link);

const doctorsTitle = {
  lek: "Lekarz Specjalista",
  dr: "Dr n. med.",
  prof: "Profesor",
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "16px",
  },
  paper: {
    // textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
    height: "calc(100vh - 160px)",
  },
  tableContainerBox: {
    height: "calc(100% - 100px)",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100% - 80px)",
    },
    position: "relative",
    padding: "inherit",
  },
  tableContainer: {
    height: "inherit",
    position: "relative",
    height: "calc(100% - 35px)",
    // width: "calc(100% - 64px)",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 8px)",
      // height: "calc(100% - 8px)",
    },
  },
  boxLoginTitle: {
    background: theme.palette.background.loginBackground,
    borderTop: "1px solid #AFAEAE",
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingMedium: {
    padding: "16px 32px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  table: {
    minWidth: 1250,
  },
  tableHeader: {
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    borderBottom: "none",
    letterSpacing: "0.7px",
    position: "sticky",
    top: "0px",
    zIndex: "100",
    background: theme.palette.background.default,
  },
  tableBodyCell: {
    color: theme.palette.text.hintText,
    borderBottom: `0.5px solid ${theme.palette.lockIcon.primary}`,
    fontFamily: "Montserrat",
  },
  aMain: {
    color: theme.palette.passwordIcon.primary,
    "&:hover": {
      color: theme.palette.passwordIcon.primary,
      textDecoration: "underline",
    },
    letterSpacing: "1px",
    fontWeight: "600",
    padding: "16px 32px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
    position: "absolute",
    bottom: "0",
  },
  tableDescButton: {
    color: theme.palette.text.alternative2,
    fontWeight: "bold",
    letterSpacing: "0.7px",
    fontSize: theme.typography.footerMobile,
    "&:hover": {
      color: theme.palette.lockIcon.primary,
    },
  },
  checkIcon: {
    color: theme.palette.checkIcon.primary,
  },
}));

const TestPurchasedDetails = ({ match, history }) => {
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [itemsDetails, setItemsDetails] = useState([]);
  const [fetchingItems, setFetchingItems] = useState(true);
  const headerCells = [
    {
      title: "Pakiet",
      key: "package",
      sortable: false,
      filter: (key) => <></>,
    },
    {
      title: "Lekarz opisujący",
      key: "describeBy",
      sortable: false,
      filter: (key) => <></>,
    },
    {
      title: "Osoba przypisana",
      key: "describeBy",
      sortable: false,
      filter: (key) => <></>,
    },
    {
      title: "Ankiety",
      key: "options",
      sortable: false,
    },
  ];

  const id = match.params.id;

  const dispatch = useDispatch();

  const [filterQueries, setFilterQueries] = useState({});
  const fetchData = async () => {
    await dispatch(fetchProteges({ page: 0, pageSize: 1000 }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [id]);

  useEffect(() => {
    fetchDetails();
  }, [items]);

  useEffect(() => {
    if (itemsDetails.length > 0) {
      setFetchingItems(false);
    }
  }, [itemsDetails]);

  const fetchProducts = async () => {
    const response = await dispatch(fetchOrderDetails(id));
    const tmpItems = response.items.map((e) => ({
      ...e,
      reclaimed: e.assignments?.filter((a) => a.reclaimedAt).length || 0,
      assignments:
        e.assignments?.length > 1
          ? e.assignments.filter((a) => !a.reclaimedAt)
          : e.assignments,
    }));
    setItems(tmpItems);
  };

  const fetchDetails = async () => {
    if (items.length > 0) {
      const tmpItemsArray = [];
      const tmpAssignmentsArray = [];
      for (var i = 0; i < items.length; i++) {
        const { label: labelPackage, hearBoxTestId } = await dispatch(
          fetchPackageInfo(items[i].hearBoxTestId)
        );
        tmpItemsArray.push({ ...items[i], labelPackage, hearBoxTestId });
      }
      const concatedItemDetails = Array.prototype.concat.apply(
        [],
        tmpItemsArray.map((item) => {
          const tmpA = [];
          for (var i = 0; i < item.amount - item.reclaimed; i++) {
            tmpA.push({
              ...item,
              ...(item.assignments && item.assignments[i]
                ? { assignments: item.assignments[i] }
                : { assignments: null }),
            });
          }
          return tmpA;
        })
      );

      if (items.assigned > 0) {
        tmpAssignmentsArray.push(items.assignments);
      }

      let arr3 = concatedItemDetails.map((item, i) =>
        Object.assign(
          {},
          item,
          tmpAssignmentsArray[i] ? { assigned: tmpAssignmentsArray[i] } : {}
        )
      );

      const formsToFill = await dispatch(
        fetchFilledFormsInfo(
          concatedItemDetails
            .filter((element) => element.assignments)
            .map(
              ({
                assignments: { patientId, patientScreeningId },
                screeningId,
                hearBoxTestId,
              }) => ({
                patientId,
                patientScreeningId,
                screeningId,
                requiredExaminationPackages: [hearBoxTestId],
              })
            )
        )
      );

      const tmpItemDetails = concatedItemDetails.map((e) =>
        e.assignments
          ? {
              ...e,
              ...formsToFill.find((form) => {
                return (
                  form.patientId === e.assignments.patientId &&
                  form.screeningId === e.screeningId &&
                  form.patientScreeningId === e.assignments.patientScreeningId
                );
              }),
            }
          : e
      );

      setItemsDetails(tmpItemDetails);
    }
  };
  const page = () =>
    fetchingItems ? (
      <Box p={1}>{<Loader loading={true} text="Pobieranie danych..." />}</Box>
    ) : (
      <Box className={classes.root}>
        <Grid container style={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} md={10}>
            <Paper className={classes.paper}>
              <Box
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
              >
                <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                <Typography className={classes.loginTitle}>
                  Szczegóły zamówienia
                </Typography>
                <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
              </Box>
              <Box
                className={`${classes.paddingMedium} ${classes.tableContainerBox}`}
              >
                <TableContainer className={classes.tableContainer}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        {headerCells.map((headCell) => (
                          <TableCell
                            key={headCell.key}
                            className={classes.tableHeader}
                            style={{ textAlign: "center" }}
                          >
                            {headCell.title}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itemsDetails.map((item, index) => (
                        <ResultRow key={index} item={item} id={id} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* </Box>
              <Box className={`${classes.paddingMedium}`}>
                {" "} */}
                <a
                  className={classes.aMain}
                  onClick={() => history.push("/active-tests")}
                >
                  Powrót do wszystkich badań
                </a>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );

  const redirect = () => <Redirect to="/" />;

  return <Can permission="active-tests:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestPurchasedDetails);

const ResultRow = ({
  id,
  item: {
    hearBoxTestId,
    labelPackage,
    describeBy,
    assignments,
    orderItemId,
    screeningId,
    tenantId,
    orderItemType,
    examinationPackageFormsToFills,
  },
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const proteges = useSelector((s) => s.proteges);
  const my = useSelector((s) => s.my);
  const ccyFormat = (num) => {
    return `${(num / 100).toFixed(2)}`;
  };

  const assignedProtege = assignments?.patientIdIsSubAccount
    ? proteges.find((p) => p.id == assignments.patientId)
    : false;

  return (
    <TableRow>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {labelPackage}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {describeBy ? doctorsTitle[describeBy] : "<bez opisu>"}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{
          textAlign: "center",
          // display: "flex",
          // flexDirection: "column",
        }}
      >
        {assignments ? (
          assignedProtege ? (
            `${assignedProtege.firstName} ${assignedProtege.surname}`
          ) : (
            `${my.firstName} ${my.surname}`
          )
        ) : (
          <MuiNavLink
            onClick={() =>
              history.push(`/purchased-test-assign/${id}/${orderItemId}`)
            }
          >
            Przypisz osobę
          </MuiNavLink>
        )}
      </TableCell>
      <TableCell
        className={classes.tableBodyCell}
        style={{ textAlign: "center" }}
      >
        {assignments && examinationPackageFormsToFills[0].formsToFill.length ? (
          assignedProtege ? (
            <Link1
              to={{
                pathname: `/fill-survey/${screeningId}/${assignedProtege.id}`,
                state: {
                  tenantId,
                  toActiveTests: true,
                  hearBoxTestIdArray: [{ hearBoxTestId, label: labelPackage }],
                  patientScreeningId: assignments.patientScreeningId,
                },
              }}
            >
              <Button className={`${classes.tableDescButton}`}>Wypełnij</Button>
            </Link1>
          ) : (
            <Link1
              to={{
                pathname: `/fill-survey/${screeningId}`,
                state: {
                  tenantId,
                  toActiveTests: true,
                  hearBoxTestIdArray: [{ hearBoxTestId, label: labelPackage }],
                  patientScreeningId: assignments.patientScreeningId,
                },
              }}
            >
              <Button className={`${classes.tableDescButton}`}>Wypełnij</Button>
            </Link1>
          )
        ) : examinationPackageFormsToFills &&
          examinationPackageFormsToFills[0].formsFilled.length ? (
          <CheckCircleIcon className={classes.checkIcon} />
        ) : assignments ? (
          <span>{"<brak ankiet>"}</span>
        ) : null}
      </TableCell>
      {/* {orderItemType !== "HearBoxTestDescription" && (
        <TableCell
          className={classes.tableBodyCell}
          style={{ textAlign: "center" }}
        >
          {assignments &&
          examinationPackageFormsToFills[0].formsToFill.length ? (
            assignedProtege ? (
              <Link1
                to={{
                  pathname: `/fill-survey/${screeningId}/${assignedProtege.id}`,
                  state: {
                    tenantId,
                    toActiveTests: true,
                    hearBoxTestIdArray: [
                      { hearBoxTestId, label: labelPackage },
                    ],
                    patientScreeningId: assignments.patientScreeningId,
                  },
                }}
              >
                <Button className={`${classes.tableDescButton}`}>
                  Wypełnij ankietę
                </Button>
              </Link1>
            ) : (
              <Link1
                to={{
                  pathname: `/fill-survey/${screeningId}`,
                  state: {
                    tenantId,
                    toActiveTests: true,
                    hearBoxTestIdArray: [
                      { hearBoxTestId, label: labelPackage },
                    ],
                    patientScreeningId: assignments.patientScreeningId,
                  },
                }}
              >
                <Button className={`${classes.tableDescButton}`}>
                  Wypełnij ankietę
                </Button>
              </Link1>
            )
          ) : null}
        </TableCell>
      )} */}
    </TableRow>
  );
};
