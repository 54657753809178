import React from "react";
import {
  Box,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Grid,
} from "@material-ui/core";
import Answers from "./answers";
import {
  CombinedResult,
  countRightAnswersPercent,
} from "./results-per-package-table";
import { useStyles } from "./results-per-package-table";
import DateAndResultGrid from "./DateAndResultGrid";

const OneEarTable = ({ ear, test }) => {
  const classes = useStyles();
  const trainingTries = test.training.tries.filter(
    (attempt) => attempt.listOfPlayedSounds[0].channel === ear
  );
  const testTries = test.tries.filter(
    (attempt) => attempt.listOfPlayedSounds[0].channel === ear
  );
  return (
    <Box className={`${classes.boxLoginTitle} ${classes.paddingMedium}`}>
      <Typography
        align="center"
        style={{
          color: (theme) => theme.palette.text.alternative2,
        }}
      >{`UCHO ${ear === "RIGHT" ? "PRAWE" : "LEWE"}`}</Typography>
      <TableContainer style={{ maxWidth: 550, mb: 2, display: "inline-block" }}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderPerExamination}>
                Nr próby
              </TableCell>
              <TableCell className={classes.tableHeaderPerExamination}>
                Bodziec
              </TableCell>
              <TableCell className={classes.tableHeaderPerExamination}>
                Odpowiedź
              </TableCell>
              <TableCell className={classes.tableHeaderPerExamination}>
                Wynik
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trainingTries.map((attempt, index) => (
              <Answers
                key={index}
                attempt={attempt}
                index={index}
                training={true}
              />
            ))}
            <TableRow>
              <TableCell colSpan={5}>
                <Typography
                  className={classes.tableHeaderPerExamination}
                >{`Faza wstępna: ${countRightAnswersPercent(
                  trainingTries
                )}%`}</Typography>
              </TableCell>
            </TableRow>
            {testTries.map((attempt, index) => (
              <Answers key={index} attempt={attempt} index={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const ASResults = ({ test }) => {
  const classes = useStyles();
  return (
    <Box>
      <DateAndResultGrid startDate={test.startDate} endDate={test.endDate}>
        <CombinedResult
          text="Wynik ucha prawego:"
          percent={test.data.correctPercentRight}
          result={test.data.resultRight}
        />
        <CombinedResult
          text="Wynik ucha lewego:"
          percent={test.data.correctPercentLeft}
          result={test.data.resultLeft}
        />
        <Box>
          {test.data.earlyFinishedEnum && (
            <Typography
              component="span"
              align="center"
              className={classes.grey}
            >
              Badanie niedokończone
            </Typography>
          )}
          {test.failureReason === "FANC" && (
            <Typography align="center" className={classes.grey}>
              Podczas badania wystąpiły zakłócenia
            </Typography>
          )}
        </Box>
      </DateAndResultGrid>
      <br />
      <Grid
        container
        justify="space-evenly"
        alignItems="center"
        style={{ overflowX: "auto" }}
      >
        <Grid item style={{ overflowX: "auto" }}>
          <OneEarTable ear={"RIGHT"} test={test.data} />
        </Grid>
        <Grid item style={{ overflowX: "auto" }}>
          <OneEarTable ear={"LEFT"} test={test.data} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ASResults;
