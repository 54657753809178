import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import * as classnames from "classnames";
import s from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

// dragaMode = either 'clone' or 'move'

const useStyles = makeStyles((theme) => ({
  muiTextarea: {
    background: "white",
  },
  isDanger: {
    borderColor: "#ff3860",
  },
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  fontSize1: {
    fontSize: theme.typography.formLabel,
  },
  hintText: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.hintText,
    lineHeight: 1.4,
    margin: "-5px auto 5px",
    color: theme.palette.text.hintText,
    display: "flex",
    padding: "5px 0",
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.formLabel,
  },
  alertText: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.hintText,
    lineHeight: 1.4,
    margin: "-5px auto 5px",
    color: theme.palette.text.primary2,
  },
  resize: {
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.footerMobile,
    },
  },
  resizeLabel: {
    fontSize: theme.typography.redirectCardTitle,
    // [theme.breakpoints.down("sm")]: {
    // fontSize: theme.typography.footerMobile,
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "hidden",
    // maxWidth: "120%",
    // height: "100%",
    // },
  },
}));

const TextControl = ({
  name,
  id,
  disabled,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  showSelfDescription,
  sidebarMode,
  formCreatorMode,
  onRemove,
  rowIndex,
  index,
  setIsDragging,
  filledValue,
  blankedRequiredFields,
  checkBlankedFields,
  displayMode,
}) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.TEXT,
      dragMode,
      defaultLabel: "Tekst",
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });
  const globalTheme = useSelector((s) => s.globalTheme);
  const isJustUser = useSelector(
    (s) => s?.my?.user?.authorities === "ROLE_USER" || false
  );
  const [value, setValue] = useState(filledValue || "");
  useEffect(() => {
    setValue(filledValue);
  }, [filledValue]);

  const classes = useStyles();

  const clicked = () => {
    markSelected &&
      markSelected({
        id,
        dataType: ItemSubtypes.TEXT,
        configuration,
      });
  };

  const updateValue = (value) => {
    setValue(value);
    if (onChange) {
      onChange({
        id,
        value,
      });
    }
  };

  const renderInputField = () => {
    return (
      <>
        {label && (
          <div
            className={`${classes.fontSize1}`}
            style={{
              marginBottom: "0px",
              color: globalTheme === "high-contrast" && "#fff200",
              textAlign: "left",
            }}
          >
            {label}
            {configuration.required && " *"}
          </div>
        )}
        <TextField
          placeholder={placeholder}
          id={id}
          value={value || ""}
          onChange={({ target: { value } }) => {
            if (configuration?.size && value.length <= configuration.size) {
              updateValue(value);
            }
          }}
          // label={label || ""}
          onClick={clicked}
          disabled={disabled}
          fullWidth
          // variant={"outlined"}
          size="small"
          error={
            checkBlankedFields &&
            blankedRequiredFields.find((fieldId) => fieldId === id)
          }
          helperText={
            checkBlankedFields &&
            blankedRequiredFields.find((fieldId) => fieldId === id) &&
            "To pole musi zostać wypełnione1"
          }
          maxLength={configuration?.size}
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes.resizeLabel,
            },
          }}
          style={{ textAlign: "left", paddingTop: "10px" }}
        />
      </>
    );
  };

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Tekst"
        />
      </div>
    );
  }

  const placeholder = showSelfDescription
    ? "Tekst"
    : (configuration && configuration.text) || "";
  const label = (configuration && configuration.label) || "";

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          {!displayMode && (
            <FontAwesomeIcon
              className={classnames(s.trashIcon, "has-text-grey")}
              onClick={onRemove}
              icon="trash"
              title="Usuń"
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div ref={drag}>
      {/* {!label && (
        // <label
        //   dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
        //   className={`label ${classes.fontSize}`}
        // ></label>
      )} */}
      {configuration?.maxRows > 1 ? (
        disabled && !isJustUser && !value ? (
          <div className={`${classes.hintText} ${classes.fontSize}`}>
            Pole niewypełnione przez{" "}
            {process.env.REACT_APP_USE_PATIENT === "true"
              ? "pacjenta"
              : "użytkownika"}
          </div>
        ) : (
          <>
            {label && (
              <div
                className={`${classes.fontSize1}`}
                style={{
                  marginBottom: "0px",
                  color: globalTheme === "high-contrast" && "#fff200",
                  textAlign: "left",
                }}
              >
                {label}
                {configuration.required && " *"}
              </div>
            )}
            <TextField
              // className={classnames(classes.muiTextarea)}
              rowsMax={configuration?.maxRows}
              // label={label || ""}
              multiline={true}
              placeholder={placeholder}
              id={id}
              value={value}
              onChange={({ target: { value } }) => {
                if (configuration?.size && value.length <= configuration.size) {
                  updateValue(value);
                }
              }}
              onClick={clicked}
              disabled={disabled}
              fullWidth
              // variant={"outlined"}
              size="small"
              error={
                checkBlankedFields &&
                blankedRequiredFields.find((fieldId) => fieldId === id)
              }
              helperText={
                checkBlankedFields &&
                blankedRequiredFields.find((fieldId) => fieldId === id) &&
                "To pole musi zostać wypełnione"
              }
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.resizeLabel,
                },
              }}
              style={{ textAlign: "left", paddingTop: "10px" }}
            />
          </>
        )
      ) : disabled && !isJustUser && !value ? (
        <div className={classes.hintText}>
          Pole niewypełnione przez{" "}
          {process.env.REACT_APP_USE_PATIENT === "true"
            ? "pacjenta"
            : "użytkownika"}
        </div>
      ) : (
        renderInputField()
      )}
      <label className={`${classes.hintText}`}>
        {configuration.size &&
          value &&
          `Pozostało znaków do wprowadzenia: ${
            configuration.size - value.length
          } `}
      </label>

      {!(!isJustUser && !value) && (
        <label
          className={`${classes.hintText}`}
          // style={{ color: globalTheme === "high-contrast" && "yellow" }}
        >
          &nbsp;&nbsp;&nbsp;
          {configuration.maxRows &&
            `Maksymalna ilość wierszy: ${configuration.maxRows}`}
        </label>
      )}
    </div>
  );
};

export default TextControl;
