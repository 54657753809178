import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../components/loader";
import { getAxiosInstance } from "../../../redux/common";
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { format } from "date-fns";
import moment from "moment/moment";
import {
  alertAdd,
  fetchPackages,
  fetchScreeningInfo,
} from "../../../redux/actions";
import Alert from "@material-ui/lab/Alert";
import FilterProperty, {
  FilterPropertyDate,
  FilterPropertySelect,
} from "../../../components/filter-property/filter-property";
// import TestStandModal from "./modal/test-stand-modal";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    padding: "1.5rem",
    marginTop: 10,
    height: "calc(100vh - 150px)",
  },
  stickyRow: {
    position: "sticky",
    zIndex: "100",
    background: "white",
    top: "-25px",
  },
}));

const RESULT_MAP = {
  AMB: "Niejednoznaczny",
  OK: "Poprawny",
  NOK: "Niepoprawny",
};

const DescriptionAssignDoctorPanel = () => {
  const classes = useStyles();

  const [fetchingDescriptions, setFetchingDescriptions] = useState(true);
  const [fetchingButtonLoader, setFetchingButtonLoader] = useState(true);
  const [fetchingButtonRender, setFetchingButtonRender] = useState(true);
  const [packages, setPackages] = useState([]);
  const [unfilteredPackages, setUnfilteredPackages] = useState([]);
  const [allPackages, setAllPackages] = useState([]);
  const [fetchingPackages, setFetchingPackages] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("descTime");
  const [filterQueries, setFilterQueries] = useState({});

  const pages = [5, 10, 20, 50];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [descriptionsLength, setDescriptionsLength] = useState(null);

  const [savedPackage, setSavedPackage] = useState(false);

  const [lockingDesc, setLockingDesc] = useState(null);
  // const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (allPackages.length > 0) {
      loadDescriptions(true);
    }
  }, [allPackages, rowsPerPage, descriptionsLength]);

  useEffect(() => {
    if (pageNr > 0) {
      loadDescriptions(false);
    }
  }, [pageNr]);

  // useEffect(() => {
  //   if (errorMessage) {
  //     setTimeout(() => setErrorMessage(""), 5000);
  //   }
  // }, [errorMessage]);

  useEffect(() => {
    const arrayPackages = [...packages];
    const sortParam = `${order === "desc" ? "-" : ""}${orderBy}`;
    setPackages(arrayPackages.sort(dynamicSort(sortParam)));
  }, [order, orderBy]);

  useEffect(() => {
    setFetchingPackages(true);
    loadPackages();
  }, []);

  const dynamicSort = (property) => {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    if (property === "patient") {
      return function (a, b) {
        var result =
          a.patient.firstName.toLowerCase() < b.patient.firstName.toLowerCase()
            ? -1
            : a.patient.firstName.toLowerCase() >
              b.patient.firstName.toLowerCase()
            ? 1
            : 0;
        return result * sortOrder;
      };
    } else if (property === "yearOfBirth") {
      return function (a, b) {
        var result =
          a.patient.yearOfBirth < b.patient.yearOfBirth
            ? -1
            : a.patient.yearOfBirth > b.patient.yearOfBirth
            ? 1
            : 0;
        return result * sortOrder;
      };
    } else if (property === "testTime") {
      return function (a, b) {
        var result = a.ts < b.ts ? -1 : a.ts > b.ts ? 1 : 0;
        return result * sortOrder;
      };
    } else if (property === "descTime") {
      return function (a, b) {
        var result =
          a.requestedDoctorDescription.ts < b.requestedDoctorDescription.ts
            ? -1
            : a.requestedDoctorDescription.ts > b.requestedDoctorDescription.ts
            ? 1
            : 0;
        return result * sortOrder;
      };
    } else {
      return function (a, b) {
        var result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    }
  };

  useEffect(() => {
    const result = unfilteredPackages.filter(search, filterQueries);
    const sortParam = `${order === "desc" ? "-" : ""}${orderBy}`;
    setPackages(result.sort(dynamicSort(sortParam)));
  }, [filterQueries, unfilteredPackages]);

  function search(screening) {
    return Object.keys(this).every((key) => {
      if (key === "patient") {
        return `${screening[key].firstName} ${screening[key].lastName}`
          .split(" ")
          .join("")
          .toLowerCase()
          .includes(this[key].toLowerCase().split(" ").join(""));
      } else if (key === "yearOfBirth") {
        return (
          screening.patient.yearOfBirth
            .toString()
            .toLowerCase()
            .includes(this[key].toString().substring(0, 4).toLowerCase()) &&
          this[key]
            .toString()
            .substring(5, 7)
            .toString()
            .toLowerCase()
            .includes(screening.patient.monthOfBirth)
        );
      } else if (key === "packages") {
        return screening[key].filter((y) =>
          y.label.toString().toLowerCase().includes(this[key].toLowerCase())
        ).length > 0
          ? screening
          : null;
      } else if (key === "testTime") {
        return screening.ts
          .toString()
          .toLowerCase()
          .includes(this[key].toLowerCase());
      } else if (key === "result") {
        return screening[key] === this[key];
      } else if (key === "descTime") {
        return screening.requestedDoctorDescription.ts
          .toString()
          .toLowerCase()
          .includes(this[key].toLowerCase());
      } else {
        return screening[key]
          .toString()
          .toLowerCase()
          .includes(this[key].toLowerCase());
      }
    });
  }

  const dispatch = useDispatch();

  const loadPackages = async () => {
    const response = await dispatch(fetchPackages());
    setAllPackages(response);
  };

  const loadDescriptions = async (loader = false) => {
    try {
      setFetchingDescriptions(loader);
      setFetchingButtonLoader(true);
      const titleResponse = await getAxiosInstance().get("/medic/v1/auth");

      const response = await getAxiosInstance().get(
        "/medic/v1/patient/description/filtered_list",
        {
          params: {
            title: titleResponse.data.titles[0],
            page: pageNr,
            limit: 100,
          },
        }
      );
      let filteredResponse = response.data.filter((value, index, self) => {
        return (
          index ===
          self.findIndex(
            (r) =>
              r.requestedDoctorDescription.screeningId ===
                value.requestedDoctorDescription.screeningId &&
              r.requestedDoctorDescription.tenantId ===
                value.requestedDoctorDescription.tenantId
          )
        );
      });

      let tmpScreeningArray = [];

      for (var j = 0; j < filteredResponse.length; j++) {
        const r = await getAxiosInstance().get(
          "/screening/v1/medic/screening/entry",
          {
            params: {
              screeningId: filteredResponse[j].result.screeningId,
              tenantId: filteredResponse[j].result.tenantId,
            },
          }
        );
        tmpScreeningArray.push(r.data);
      }

      let tmpArray = [];

      for (var j = 0; j < response.data.length; j++) {
        const { screeningId, tenantId } = response.data[j].result;
        const r = tmpScreeningArray.find(
          (screening) =>
            screening.screeningId === screeningId &&
            screening.tenantId === tenantId
        );

        tmpArray.push({
          ...response.data[j],
          ...response.data[j].result,
          label: r.label,
          packages: allPackages.filter(
            (p) =>
              response.data[j].result.hearBoxTestId.localeCompare(
                p.hearBoxTestId
              ) == 0
          ),
        });
      }
      !tmpArray.length && setFetchingButtonRender(false);

      setFetchingPackages(false);
      setPackages([...packages, ...tmpArray]);
      setUnfilteredPackages([...unfilteredPackages, ...tmpArray]);
      setFetchingDescriptions(false);
      setFetchingButtonLoader(false);
    } catch (e) {
      setFetchingDescriptions(false);
      setFetchingButtonLoader(false);
      setPackages([]);
    }
  };

  const hanldePageIncrement = () => {
    setPageNr(pageNr + 1);
  };

  const descriptionLockHandler = async (
    patient,
    patientScreeningId,
    hearBoxScreeningId,
    hearBoxTestId,
    ts
  ) => {
    try {
      setLockingDesc(ts);
      const domain =
        window.location.hostname === "localhost"
          ? `${window.location.hostname}:${window.location.port}`
          : window.location.hostname;

      const secure = window.location.hostname === "localhost" ? `` : "s";
      let socket = new WebSocket(
        `ws${secure}://${domain}/medic/v1/doctor/websocket/connect`
      );
      let response = {};
      socket.onopen = async function (e) {
        try {
          response = await getAxiosInstance().post(
            "/patient/v1/medic/screening/completed/description/lock",
            null,
            {
              params: {
                patientId: patient.id,
                patientScreeningId,
                hearBoxScreeningId,
                hearBoxTestId,
              },
            }
          );
        } catch (error) {
          dispatch(
            alertAdd({
              text:
                "Wystąpił problem z przejęciem badania - badanie zostało już przejęte",
              isWarning: true,
              timeout: 5000,
            })
          );
          setLockingDesc(null);
          socket.close();
        }
      };

      socket.onmessage = function (event) {
        // setPackages(packages.filter((p) => p.ts !== ts));
        setUnfilteredPackages(unfilteredPackages.filter((p) => p.ts !== ts));
        setDescriptionsLength(null);
        // loadDescriptions();

        setLockingDesc(null);
        socket.close();
      };

      socket.onerror = function (event) {
        setLockingDesc(null);
        socket.close();
      };
    } catch (e) {
      dispatch(
        alertAdd({
          text:
            "Wystąpił problem z przejęciem badania - badanie zostało już przejęte",
          isWarning: true,
          timeout: 5000,
        })
      );
      console.log("error", e);
    }
  };

  const headerCells = [
    {
      title: "Pacjent",
      key: "patient",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Rok urodzenia",
      key: "yearOfBirth",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
          onlyYear
        />
      ),
    },
    {
      title: "Przesiew",
      key: "label",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Pakiety",
      key: "packages",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Czas badania",
      key: "testTime",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Wynik Ogólny",
      key: "result",
      sortable: true,
      filter: (key) => (
        <FilterPropertySelect
          label={"Wynik"}
          listItems={[
            { value: "OK", label: "Poprawny" },
            { value: "NOK", label: "Niepoprawny" },
            { value: "AMB", label: "Niejednoznaczny" },
          ]}
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Czas zamówienia",
      key: "descTime",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Opcje",
      key: "option",
      sortable: false,
      filter: (key) => null,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const PackageRow = ({
    simplePackage: {
      result,
      patient,
      label,
      ts,
      patientScreeningId,
      hearBoxScreeningId,
      hearBoxTestId,
      packages,
      requestedDoctorDescription,
    },
  }) => {
    return (
      <TableRow>
        {" "}
        <TableCell>{`${patient.firstName} ${patient.lastName}`}</TableCell>
        <TableCell>
          {moment(
            new Date(`${patient.monthOfBirth}/01/${patient.yearOfBirth}`)
          ).format("MM/YYYY")}
        </TableCell>
        <TableCell>{label}</TableCell>
        <TableCell>
          {packages.map((p) => (
            <p style={{ marginTop: "5px" }}>{p.label}</p>
          ))}
        </TableCell>
        <TableCell>
          {moment.utc(ts).local().format("DD/MM/YYYY HH:mm:ss")}
        </TableCell>
        <TableCell>{RESULT_MAP[result]}</TableCell>
        <TableCell>
          {moment
            .utc(requestedDoctorDescription.ts)
            .local()
            .format("DD/MM/YYYY HH:mm:ss")}
        </TableCell>
        <TableCell>
          <Button
            disabled={lockingDesc}
            onClick={() =>
              descriptionLockHandler(
                patient,
                patientScreeningId,
                hearBoxScreeningId,
                hearBoxTestId,
                ts
              )
            }
          >
            {lockingDesc !== ts ? (
              `Przejmij`
            ) : (
              <CircularProgress style={{ height: "30px", width: "30px" }} />
            )}
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  return fetchingDescriptions ? (
    <Loader loading={true} text="Pobieranie opisów do przypisania" />
  ) : unfilteredPackages.length === 0 ? (
    <Box p={3} style={{ textAlign: "center" }}>
      <h1>Brak opisów do wykonania</h1>
    </Box>
  ) : (
    <Box>
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="Test stands list">
          <TableHead>
            <TableRow className={classes.stickyRow}>
              {headerCells.map((headCell, index) =>
                headCell.sortable ? (
                  <TableCell
                    key={index}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.key}
                      direction={orderBy === headCell.key ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.key);
                      }}
                    >
                      {headCell.title}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell>{headCell.title}</TableCell>
                )
              )}
            </TableRow>
            <TableRow>
              {headerCells.map((headCell, index) => (
                <TableCell key={index}>
                  {headCell.filter(headCell.key)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {packages.map((simplePackage, index) => (
              <PackageRow key={index} simplePackage={simplePackage} />
            ))}
          </TableBody>
        </Table>
        {fetchingButtonRender && (
          <Box style={{ textAlign: "center" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: "10px", fontSize: "16px" }}
              onClick={hanldePageIncrement}
              disabled={fetchingButtonLoader}
            >
              {fetchingButtonLoader ? (
                <CircularProgress color="primary" />
              ) : (
                `Wczytaj więcej badań...`
              )}
            </Button>
          </Box>
        )}
        {/* <TablePagination
          component="div"
          page={pageNr}
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          // count={protegesAll}
          count={descriptionsLength}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={"Wierszy na stronę:"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} z ${count}`
          }
          className={classes.select}
        /> */}
      </TableContainer>
    </Box>
  );
};

export default DescriptionAssignDoctorPanel;
