import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Prompt, Redirect } from "react-router-dom";
import Can from "../../components/can";
import s from "./index.module.css";
import {
  removeMyAccountRequest,
  updateMyProfile,
  updateMyProfileExt,
} from "../../redux/person/actions";
import * as classnames from "classnames";
import {
  fetchOrganizationCurrentTerms,
  fetchOrganizationFutureTerms,
  fetchTermsAcceptedByUser,
  updateMyOrganization,
} from "../../redux/organization/actions";
import { checkPermission } from "../../rbac";
import { Box, useMediaQuery } from "@material-ui/core";
import { alertAdd, recoverPassword } from "../../redux/actions";
import ShippingAddressForm from "../../components/address-form/shipping-info";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PageTitle from "../../components/page-title";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import Tooltip from "@material-ui/core/Tooltip";
import EventIcon from "@material-ui/icons/Event";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Link from "@material-ui/core/Link";
import TermsAcceptance from "../../components/terms-acceptance";
import OrganizationTerms from "../organization-terms";
import AvatarUpload from "../../components/avatar-upload";
import ApiVersionSpan from "../../components/api-version-span/api-version-span";
import { testEmailPattern } from "../register-organization";
import QRCode from "qrcode";
import QRCodeModal from "../../components/qr-code";
import BillingAddressForm from "../../components/address-form/billing-info";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";
import StopIcon from "@material-ui/icons/Stop";
import { ReactComponent as ArrowDownIcon } from "../../assets/arrowDownIcon.svg";
import { ReactComponent as ArrowDownIconYellow } from "../../assets/arrowDownIcon-yellow.svg";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";
import { ReactComponent as CalendarIconYellow } from "../../assets/calendar-yellow.svg";

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.text.hintText,
    fontSize: theme.typography.hintText,
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))(Link);

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "16px",
  },
  card: {
    minWidth: 250,
    borderRadius: "1rem",
    border: `0.5px solid ${theme.palette.hr.primary}`,
    boxShadow: "none",
  },
  mainGrid: {
    justifyContent: "center",
  },
  hr: {
    opacity: "0.5",
    margin: "2.5em 20px 2.5em 60px",
    border: `0.5px solid ${theme.palette.text.whiteHeader}`,
    [theme.breakpoints.down("lg")]: {
      margin: "1.5em 0 1.5em 0",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  locationButton: {
    marginTop: "3em",
    backgroundColor: theme.palette.background.saveButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `162px`,
    height: "50px",
  },
  grid: {
    [theme.breakpoints.up("xl")]: {
      margin: "2.5em 20px 2.5em 60px",
    },
  },
  paper: {
    border: `0.5px solid ${theme.palette.hr.primary}`,
    // padding: "32px",
    textAlign: "left",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  text: {
    color: theme.palette.passwordIcon.primary,
    fontSize: theme.typography.paragraph,
    letterSpacing: "1.5px",
    fontWeight: "700",
  },
  title: {
    fontSize: theme.typography.menu,
    color: theme.palette.text.alternative2,
    fontWeight: "700",
  },
  subtitle: {
    fontSize: theme.typography.hintText,
    color: theme.palette.text.alternative3,
    fontWeight: "700",
  },
  subtitle2: {
    fontSize: theme.typography.hintText,
    color: theme.palette.text.alternative2,
    letterSpacing: "1.5px",
    fontWeight: "700",
    textTransform: "uppercase",
    textAlign: "center",
  },
  greyColor: {
    backgroundColor: theme.palette.background.loginBackground,
  },
  idText: {
    fontSize: theme.typography.menu,
    color: theme.palette.text.idProfile,
    fontWeight: "700",
  },
  idPin: {
    fontSize: theme.typography.menu,
    color: theme.palette.text.pinProfile,
    fontWeight: "700",
  },
  button: {
    background: theme.palette.background.photoUpload,
    borderRadius: "20px",
    color: theme.palette.background.default,
    height: "40px",
    width: "180px",
    fontSize: theme.typography.hintText,
  },
  placeholder: {
    color: theme.palette.text.placeholder,
  },
  locationButton: {
    backgroundColor: theme.palette.background.saveButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `115px`,
    height: "40px",
    marginRight: "15px",
  },
  testButton: {
    backgroundColor: theme.palette.testIcon.primary,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `115px`,
    height: "40px",
    marginRight: "5px",
  },
  selectText: {
    letterSpacing: "1px",
    lineHeight: `${theme.typography.formLabel}px`,
    fontSize: theme.typography.footer,
    color: theme.palette.text.hintText,
    textTransform: "uppercase",
    borderBottom: `1px solid ${theme.palette.background.menuItemBorderBottom}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
}));

const genderTypes = [
  {
    type: "Female",
    description: "Kobieta",
  },
  {
    type: "Male",
    description: "Mężczyzna",
  },
];

const channelOptions = [
  {
    type: "mail",
    description: "email",
  },
  {
    type: "phone",
    description: "telefon",
  },
];

let actualDate = new Date();
actualDate.setUTCHours(0);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const NIPValidator = (nipNumber) => {
  const nipWithoutDashes = nipNumber?.replace(/-/g, "");
  const reg = /^[0-9]{10}$/;
  if (reg.test(nipWithoutDashes) == false) {
    return false;
  } else {
    const digits = ("" + nipWithoutDashes).split("");
    const checksum =
      (6 * parseInt(digits[0]) +
        5 * parseInt(digits[1]) +
        7 * parseInt(digits[2]) +
        2 * parseInt(digits[3]) +
        3 * parseInt(digits[4]) +
        4 * parseInt(digits[5]) +
        5 * parseInt(digits[6]) +
        6 * parseInt(digits[7]) +
        7 * parseInt(digits[8])) %
      11;

    return parseInt(digits[9]) == checksum;
  }
};

const ProfilePage = ({ history }) => {
  const my = useSelector((s) => s.my);
  const globalTheme = useSelector((s) => s.globalTheme);
  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";
  const enableProfileDataEdition = useSelector(
    (s) => s.my.enableProfileDataEdition
  );
  const organization = useSelector((s) => s.organization);
  const roles = useSelector((s) => s.roles);
  const orgCurrentTerms = useSelector((s) => s.orgCurrentTerms);
  const fetchingOrgCurrentTerms = useSelector((s) => s.fetchingOrgCurrentTerms);
  const termsAcceptedByUser = useSelector((s) => s.termsAcceptedByUser);
  const orgFutureTerms = useSelector((s) => s.orgFutureTerms);
  const fetchingOrgFutureTerms = useSelector((s) => s.fetchingOrgFutureTerms);

  const isOwner = my.user.authorities.indexOf("ROLE_OWNER") !== -1;
  const isUser = my?.user.authorities.localeCompare("ROLE_USER") === 0;
  const enableEmailEdition =
    roles && roles.length === 1 && roles[0] === "ROLE_USER";

  const classes = useStyles();

  const [qrCodeURL, setQRCodeURL] = useState("");
  const qrCode = useSelector((s) => s.qrCode);

  const [firstName, setFirstName] = useState((my && my.firstName) || "");
  const [lastName, setLastName] = useState((my && my.surname) || "");
  const [selectedGender, setSelectedGender] = useState((my && my.gender) || "");

  const [yearOfBirth, setYearOfBirth] = useState(
    (my && my.dateOfBirth?.toString()) || null
  );

  const [username, setUsername] = useState(
    (my && my.user && my.user.username) || ""
  );
  const [email, setEmail] = useState((my && my.user && my.user.email) || "");
  const avatarPath = (my && my.avatarPath) || "";
  const [newAvatarPath, setNewAvatarPath] = useState(null);
  const [avatarImage, setAvatarImage] = useState();

  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [post, setPost] = useState("");
  const [phone, setPhone] = useState(null);
  // const [notes, setNotes] = useState("");

  const [nameFV, setNameFV] = useState("");
  const [streetFV, setStreetFV] = useState("");
  const [zipFV, setZipFV] = useState("");
  const [cityFV, setCityFV] = useState("");
  const [postFV, setPostFV] = useState("");
  const [taxID, setTaxID] = useState("");

  const [orgName, setOrgName] = useState("");
  const [customFooter, setCustomFooter] = useState("");
  const [orgMessage, setOrgMessage] = useState("");
  const [orgCode, setOrgCode] = useState("");
  const [formDataCreatedWebhookUrl, setFormDataCreatedWebhookUrl] = useState(
    ""
  );
  const [selfRegistration, setSelfRegistration] = useState(false);
  const [userActivation, setUserActivation] = useState(false);
  const [orgCodeRequired, setOrgCodeRequired] = useState(false);
  const [busy, setBusy] = useState(false);
  const [displayHint, setDisplayHint] = useState(false);

  const [dialCode, setDialCode] = useState("48");

  const [dataProvidedValid, setDataProvidedValid] = useState(false);
  const [yearProvidedValid, setYearProvidedValid] = useState(false);
  const [emailProvidedValid, setEmailProvidedValid] = useState(true);

  const [editorsCanManageForms, setEditorsCanManageForms] = useState(false);
  const [editorsCanManageUsers, setEditorsCanManageUsers] = useState(false);
  const dispatch = useDispatch();

  const [dataChanged, setDataChanged] = useState(false);

  const [currentTermsAccepted, setCurrentTermsAccepted] = useState(false);
  const [futureTermsAccepted, setFutureTermsAccepted] = useState(false);

  const [value, setValue] = React.useState(0);

  const [qrOpened, setQROpened] = useState(false);

  const canUpdateOrgData = checkPermission("orgDetails:change", roles);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [deletingAvatar, setDeletingAvatar] = useState(false);
  const [deletingEmail, setDeletingEmail] = useState(false);
  //const enableProfileDataEdition = my.enableProfileDataEdition || true;

  const [preferredChannel, setPreferredChannel] = useState(
    (my && my.preferredChannel) || ""
  );
  const [enableReminders, setEnableReminders] = useState(
    my && my.enableReminders
  );

  const [openFormRemovingPanel, setOpenFormRemovingPanel] = useState(false);

  const componentRef = useRef();

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const qrPIN = useSelector((s) => s.qrCodePIN);
  const qrID = useSelector((s) => s.qrCodeID);

  const handleUploadedFiles = (files) => {
    if (files.length > 0) {
      const file = files[0];

      let reader = new FileReader();
      reader.onloadend = () => {
        setAvatarImage(file);
        setNewAvatarPath(reader.result);
        setDeletingAvatar(false);
      };

      reader.readAsDataURL(file);
    }
  };

  const removeAvatar = () => {
    setAvatarImage(null);
    setNewAvatarPath("");
    setDeletingAvatar(true);
  };

  useEffect(() => {
    async function generateQRCode() {
      try {
        const response = await QRCode.toDataURL(
          JSON.stringify({
            id: qrID,
            pin: qrPIN,
          })
        );
        setQRCodeURL(response);
      } catch (e) {
        console.log(e);
      }
    }
    generateQRCode();
  }, [qrID, qrPIN]);

  useEffect(() => {
    if (my?.phone) {
      setPhone(my.phone);
    }

    if (organization) {
      setOrgName(organization.orgName || "");
      setCustomFooter(organization.customFooter || "");

      setOrgMessage(organization.orgMessage || "");
      setOrgCode(organization.orgCodeRegistration || "");
      setFormDataCreatedWebhookUrl(
        organization.formDataCreatedWebhookUrl || ""
      );
      setOrgCodeRequired(organization.enableCodeRegistration || false);
      setUserActivation(organization.enableUserActivation || false);
      setSelfRegistration(organization.enableSelfRegistration || false);
      setEditorsCanManageForms(organization.editorsCanManageForms || false);
      setEditorsCanManageUsers(organization.editorsCanManageUsers || false);
    }

    if (my?.billingInfo) {
      setNameFV(my.billingInfo.name || "");
      setStreetFV(my.billingInfo.street || "");
      setZipFV(my.billingInfo.zipCode || "");
      setCityFV(my.billingInfo.city || "");
      setTaxID(my.billingInfo.taxId || "");
      setPostFV(my.billingInfo.post || "");
    }
  }, [organization, my]);

  useEffect(() => {
    setDataProvidedValid(
      (!zipCode || zipCode.length === 6) &&
        (!zipFV || zipFV.length === 6) &&
        (!taxID || NIPValidator(taxID))
      // &&
      //   (!phone || phone.length === 12 || phone.length < dialCode.length + 2)
    );
  }, [phone, zipCode, zipFV, taxID]);

  useEffect(() => {
    if (yearOfBirth) {
      setYearProvidedValid(
        yearOfBirth?.length === 4 &&
          yearOfBirth > 1899 &&
          yearOfBirth <= new Date().getFullYear()
      );
    } else {
      setYearProvidedValid(true);
    }
  }, [yearOfBirth]);

  useEffect(() => {
    if (email) {
      setEmailProvidedValid(testEmailPattern(email));
    } else {
      setEmailProvidedValid(true);
    }
  }, [email]);

  useEffect(() => {
    if (my) {
      if (my.address) {
        setName(my.address.name || "");
        setStreet(my.address.street || "");
        setZipCode(my.address.zipCode || "");
        setCity(my.address.city || "");
        setPost(my.address.post || "");
        setPhone(my.phone || null);
        // setNotes(my.address.notes || "");
      }
    }
  }, [my]);

  useEffect(() => {
    isUser &&
      !orgCurrentTerms &&
      organization &&
      !fetchingOrgCurrentTerms &&
      dispatch(fetchOrganizationCurrentTerms(organization.id));
  }, [organization?.id]);

  useEffect(() => {
    isUser &&
      !orgFutureTerms &&
      !fetchingOrgFutureTerms &&
      dispatch(fetchOrganizationFutureTerms());
  }, [isUser]);

  useEffect(() => {
    isUser && !termsAcceptedByUser && dispatch(fetchTermsAcceptedByUser());
  }, [isUser]);

  useEffect(() => {
    if (isUser && termsAcceptedByUser && orgCurrentTerms) {
      const founded = termsAcceptedByUser.find(
        ({ terms }) => terms.id === orgCurrentTerms.id
      );
      if (founded && !founded.revokedAt) {
        setCurrentTermsAccepted(true);
      } else {
        setCurrentTermsAccepted(false);
      }
    }
  }, [termsAcceptedByUser, orgCurrentTerms]);

  useEffect(() => {
    if (isUser && termsAcceptedByUser && orgFutureTerms) {
      const founded = termsAcceptedByUser.find(
        ({ terms }) => terms.id === orgFutureTerms.id
      );
      if (founded && !founded.revokedAt) {
        setFutureTermsAccepted(true);
      } else {
        setFutureTermsAccepted(false);
      }
    }
  }, [termsAcceptedByUser, orgFutureTerms]);

  const removeAccountHanlder = async () =>
    await dispatch(removeMyAccountRequest());

  const save = async () => {
    setBusy(true);

    const loginToChange =
      my.user.email && my.user.email?.toLowerCase() !== email;
    //  ||
    // (my.user.phone &&
    //   my.user.phone !== phone?.replace(/[^+\d]+/g, "") &&
    //   roles &&
    //   roles.length === 1 &&
    //   roles[0] === "ROLE_USER");

    let response = {};

    const phoneNormalized =
      phone && phone !== "+48" ? phone.replace(/[^+\d]+/g, "") : null;

    if (avatarImage || deletingAvatar) {
      const dateOfBirth = new Date(yearOfBirth);
      dateOfBirth.setDate("1");
      response = await dispatch(
        updateMyProfileExt({
          firstName,
          surname: lastName,
          dateOfBirth: new Date(
            dateOfBirth.getTime() +
              Math.abs(dateOfBirth.getTimezoneOffset() * 60000)
          )
            .toISOString()
            .split("T")[0],
          ...(selectedGender ? { gender: selectedGender } : {}),
          ...(phoneNormalized ? { phone: phoneNormalized } : {}),
          street,
          mail: email,
          city,
          post,
          zipCode,
          avatarImage,
          address: {
            name,
            street,
            zipCode,
            city,
            post,
            ...(phoneNormalized ? { phone: phoneNormalized } : {}),
            // notes,
          },
          billingInfo: {
            name: nameFV,
            street: streetFV,
            zipCode: zipFV,
            city: cityFV,
            post: postFV,
            taxId: taxID,
          },
          preferredChannel,
          enableReminders,
        })
      );
    } else {
      const dateOfBirth = new Date(yearOfBirth);
      dateOfBirth.setDate("1");
      response = await dispatch(
        updateMyProfile({
          firstName,
          surname: lastName,
          ...(yearOfBirth
            ? {
                dateOfBirth: new Date(
                  dateOfBirth.getTime() +
                    Math.abs(dateOfBirth.getTimezoneOffset() * 60000)
                )
                  .toISOString()
                  .split("T")[0],
              }
            : {}),
          ...(selectedGender ? { gender: selectedGender } : {}),
          ...(phoneNormalized ? { phone: phoneNormalized } : {}),
          street,
          mail: email,
          city,
          post,
          zipCode,
          avatarImage,
          address: {
            name,
            street,
            zipCode,
            city,
            post,
            // notes,
            ...(phoneNormalized ? { phone: phoneNormalized } : {}),
          },
          billingInfo: {
            name: nameFV,
            street: streetFV,
            zipCode: zipFV,
            city: cityFV,
            post: postFV,
            taxId: taxID,
          },
          preferredChannel,
          enableReminders,
        })
      );
    }

    if (response.status === 200) {
      setBusy(false);

      if (loginToChange) {
        dispatch(
          alertAdd({
            text:
              "Zaktualizowano profil. Z uwagi na zmianę loginu może być konieczne ponowne zalogowanie.",
            isWarning: true,
            timeout: 4000,
          })
        );

        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        setDataChanged(false);
        dispatch(alertAdd({ text: "Zaktualizowano profil", isSuccess: true }));

        setNewAvatarPath(null);
      }
    } else if (response.data.message.includes("Phone is not unique")) {
      setBusy(false);
      dispatch(
        alertAdd({
          text:
            "Nie udało się dokonać edycji. Podany numer telefonu istnieje już w systemie.",
          isError: true,
        })
      );
    } else if (response.data.message.includes("Email is not unique")) {
      setBusy(false);
      dispatch(
        alertAdd({
          text:
            "Nie udało się dokonać edycji. Podany adres email istnieje już w systemie.",
          isError: true,
        })
      );
    } else {
      setBusy(false);
      dispatch(alertAdd({ text: "Błąd aktualizacji profilu", isError: true }));
    }

    if (canUpdateOrgData) {
      const orgCodeRegistration = orgCodeRequired ? orgCode : "";

      response = await dispatch(
        updateMyOrganization({
          orgName,
          customFooter,
          orgMessage,
          orgCodeRegistration,
          enableUserActivation: userActivation,
          enableSelfRegistration: selfRegistration,
          enableCodeRegistration: orgCodeRequired,
          editorsCanManageForms,
          editorsCanManageUsers,
          billingInfo: {
            name: nameFV,
            street: streetFV,
            zipCode: zipFV,
            city: cityFV,
            post: postFV,
            taxId: setTaxID,
          },
          ...(formDataCreatedWebhookUrl ? { formDataCreatedWebhookUrl } : {}),
        })
      );

      if (response.status === 200) {
        dispatch(
          alertAdd({
            text: "Zaktualizowano dane organizacji",
            isSuccess: true,
          })
        );
      } else {
        dispatch(
          alertAdd({
            text: "Błąd aktualizacji danych organizacji",
            isError: true,
          })
        );
      }

      setBusy(false);
    }
  };

  const handleStartDateChange = (date) => {
    setDataChanged(true);
    if (date) {
      setYearOfBirth(new Date(date).getFullYear().toString());
    } else {
      setYearOfBirth("");
    }
  };

  const isNameAutoGenerated = () => {
    return (
      name === firstName ||
      name === lastName ||
      name === "".concat(firstName, " ", lastName)
    );
  };

  const handleChangeNameInAddress = (firstName, lastName) => {
    if (!name || isNameAutoGenerated()) {
      var tmpName =
        firstName && lastName
          ? "".concat(firstName, " ", lastName)
          : firstName
          ? firstName
          : lastName;
      setName(tmpName);
    }
  };

  const handleSaveQR = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = qrCodeURL;
    downloadLink.download = "Hearbox: Kod QR";
    downloadLink.click();
  };

  const page = () => (
    <>
      <Box className={classes.root}>
        <Grid container style={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} className={classes.grid}>
            <Paper className={classes.paper}>
              <Grid
                container
                spacing={4}
                style={{ width: "100%", margin: "0px" }}
              >
                <Grid item xs={12} sm={6} md={3} className={classes.greyColor}>
                  <Typography className={classes.title}>Profil</Typography>
                  <br />
                  <Typography className={classes.subtitle}>
                    Twój kod QR do logowania na stanowisku Hear box:
                  </Typography>
                  {qrCodeURL ? (
                    <Box mt={2} mb={1}>
                      <img
                        src={qrCodeURL}
                        alt="img"
                        // style={{ width: smallScreen ? "55%" : "10%" }}
                        ref={componentRef}
                      />
                      <Typography className={classes.idText}>
                        {`ID: ${qrID}`}
                      </Typography>
                      <Typography className={classes.idPin}>
                        {`PIN: ${qrPIN}`}
                      </Typography>
                    </Box>
                  ) : (
                    <Box mt={2} mb={1}>
                      <Typography variant="body1" color="textPrimary">
                        Brak kodu. Proszę spróbować później.
                      </Typography>
                    </Box>
                  )}
                  <Box mt={2}>
                    <Typography variant="body1" color="textPrimary">
                      <span>
                        <QRCodeModal
                          handleClose={() => setQROpened(false)}
                          open={qrOpened}
                        />
                        <Button
                          className={classes.button}
                          size="small"
                          variant={"contained"}
                          onClick={() => setQROpened(true)}
                        >
                          Otwórz panel zapisu
                        </Button>{" "}
                      </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ margin: "5px 0 10px 0", padding: "20px 0" }}
                    className={classes.greyColor}
                  >
                    <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                    <Typography className={classes.subtitle2}>
                      Dane personalne
                    </Typography>
                    <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
                  </Box>
                  <TextField
                    placeholder="Imię"
                    disabled={!enableProfileDataEdition}
                    value={firstName}
                    fullWidth
                    onChange={({ target: { value: firstNameTmp } }) => {
                      setDataChanged(true);
                      setFirstName(firstNameTmp);
                      handleChangeNameInAddress(firstNameTmp, lastName);
                    }}
                  />
                  <Box mt={2}>
                    <TextField
                      placeholder="Nazwisko"
                      disabled={!enableProfileDataEdition}
                      value={lastName}
                      fullWidth
                      onChange={({ target: { value: lastNameTmp } }) => {
                        setDataChanged(true);
                        setLastName(lastNameTmp);
                        handleChangeNameInAddress(firstName, lastNameTmp);
                      }}
                    />
                  </Box>
                  <Box mt={2}>
                    <MuiPickersUtilsProvider
                      locale={plLocale}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={!enableProfileDataEdition}
                        id="start-date-picker-dialog"
                        placeholder="Data urodzenia"
                        format="MM/yyyy"
                        views={["year", "month"]}
                        value={yearOfBirth}
                        fullWidth
                        onChange={(value) => {
                          setYearOfBirth(value);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        cancelLabel={"Anuluj"}
                        okLabel={"Zatwierdź"}
                        invalidDateMessage={
                          yearOfBirth?.length > 0 && "Nieprawidłowy format"
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        maxDateMessage="Błędny rok urodzenia"
                        minDateMessage="Błędny rok urodzenia"
                        maxDate={actualDate}
                        keyboardIcon={
                          isLightGlobalTheme ? (
                            <CalendarIcon />
                          ) : (
                            <CalendarIconYellow />
                          )
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                  <Box mt={2}>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        renderValue={
                          selectedGender !== ""
                            ? undefined
                            : () => (
                                <span className={classes.placeholder}>
                                  Płeć
                                </span>
                              )
                        }
                        labelId="select-outlined-label"
                        id="select-outlined"
                        disabled={!enableProfileDataEdition}
                        fullWidth
                        value={selectedGender}
                        onChange={(event) => {
                          setDataChanged(true);
                          setSelectedGender(event.target.value);
                        }}
                        placeholder="Płeć"
                        IconComponent={(props) => (
                          <i
                            {...props}
                            className={`material-icons ${props.className}`}
                            style={{ top: "calc(50% - 20px)", right: "15px" }}
                          >
                            {isLightGlobalTheme ? (
                              <ArrowDownIcon />
                            ) : (
                              <ArrowDownIconYellow />
                            )}
                          </i>
                        )}
                      >
                        {genderTypes.map((genderType, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={genderType.type}
                              className={classes.selectText}
                            >
                              {genderType.description}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mt={2}>
                    <TextField
                      placeholder="Email"
                      disabled={
                        !enableProfileDataEdition || !enableEmailEdition
                      }
                      value={email}
                      fullWidth
                      onChange={({ target: { value: email } }) => {
                        setDataChanged(true);
                        !email && setDeletingEmail(true);
                        setEmail(email);
                      }}
                      error={!emailProvidedValid}
                      // helperText={
                      //   deletingEmail && preferredChannel === "mail"
                      //     ? "Preferowany kanał do komunikacji zostanie zmieniony na telefon."
                      //     : !emailProvidedValid
                      //     ? "Nieprawidłowy format"
                      //     : ""
                      // }
                    />
                  </Box>
                  <Box mt={3}>
                    <AvatarUpload
                      avatarPath={avatarPath}
                      newAvatarPath={newAvatarPath}
                      enableProfileDataEdition={enableProfileDataEdition}
                      setDataChanged={setDataChanged}
                      handleUploadedFiles={handleUploadedFiles}
                      removeAvatar={removeAvatar}
                      deletingAvatar={deletingAvatar}
                    />
                  </Box>
                </Grid>
                <Grid container item sm={12} md={6}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    style={{ paddingRight: "16px", paddingBottom: "16px" }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      style={{ margin: "5px 0 10px 0", padding: "20px 0" }}
                      className={classes.greyColor}
                    >
                      <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                      <Typography className={classes.subtitle2}>
                        Dane adresowe
                      </Typography>
                      <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
                    </Box>
                    <ShippingAddressForm
                      provideShippingData={true}
                      name={name || ""}
                      setName={setName}
                      street={street}
                      setStreet={setStreet}
                      city={city}
                      setCity={setCity}
                      zipCode={zipCode}
                      setZipCode={setZipCode}
                      post={post}
                      setPost={setPost}
                      phone={phone}
                      setPhone={setPhone}
                      // notes={notes}
                      // setNotes={setNotes}
                      dialCode={dialCode}
                      setDialCode={setDialCode}
                      enableProfileDataEdition={enableProfileDataEdition}
                      setDataChanged={setDataChanged}
                      preferredChannel={preferredChannel}
                      isUser={isUser}
                    />
                  </Grid>
                  {isUser && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      style={{ paddingBottom: "16px" }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ margin: "5px 0 10px 0", padding: "20px 0" }}
                        className={classes.greyColor}
                      >
                        <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                        <Typography className={classes.subtitle2}>
                          Dane do faktury
                        </Typography>
                        <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
                      </Box>

                      <BillingAddressForm
                        nameFV={nameFV}
                        setNameFV={setNameFV}
                        streetFV={streetFV}
                        setStreetFV={setStreetFV}
                        zipFV={zipFV}
                        setZipFV={setZipFV}
                        cityFV={cityFV}
                        setCityFV={setCityFV}
                        taxID={taxID}
                        setTaxID={setTaxID}
                        postFV={postFV}
                        setPostFV={setPostFV}
                        setDataChanged={setDataChanged}
                        NIPValidator={NIPValidator}
                        // saveBillingData={saveBillingData}
                        // setSaveBillingData={setSaveBillingData}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      className={classes.testButton}
                      type="submit"
                      variant={"contained"}
                      // disabled={
                      // !enableProfileDataEdition ||
                      // !dataProvidedValid ||
                      // !yearProvidedValid ||
                      // !emailProvidedValid ||
                      // (!phone && !email)
                      // }
                      onClick={() => {
                        if (orgCodeRequired === false || orgCode.length >= 8) {
                          save();
                          setDisplayHint(false);
                        } else {
                          setDisplayHint(true);
                        }
                      }}
                    >
                      Zapisz
                    </Button>
                    <Button
                      className={classes.locationButton}
                      type="cancel"
                      variant={"contained"}
                      onClick={() => history.push("/")}
                    >
                      Anuluj
                    </Button>
                    {!my.anonymizationRequestedAt ? (
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <MuiNavLink
                          onClick={() => setOpenFormRemovingPanel(true)}
                        >
                          Poproś o usunięcie konta
                        </MuiNavLink>
                      </Box>
                    ) : (
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="subtitle1">
                          Konto w trakcie usuwania
                        </Typography>
                      </Box>
                    )}
                    <ConfirmDialogMUI
                      handleClose={() => {
                        setOpenFormRemovingPanel(false);
                      }}
                      open={openFormRemovingPanel}
                      text={"Czy na pewno usunąć konto?"}
                      textDetails={
                        "Dostęp do danych zostanie bezpowrotnie utracony. Usunięcie konta może zająć kilka dni."
                      }
                      yesAction={removeAccountHanlder}
                      noAction={() => setOpenFormRemovingPanel(false)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid
                container
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={9}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    className={classes.testButton}
                    type="submit"
                    variant={"contained"}
                    // disabled={
                    // !enableProfileDataEdition ||
                    // !dataProvidedValid ||
                    // !yearProvidedValid ||
                    // !emailProvidedValid ||
                    // (!phone && !email)
                    // }
                    onClick={() => {
                      if (orgCodeRequired === false || orgCode.length >= 8) {
                        save();
                        setDisplayHint(false);
                      } else {
                        setDisplayHint(true);
                      }
                    }}
                  >
                    Zapisz
                  </Button>
                  <Button
                    className={classes.locationButton}
                    type="cancel"
                    variant={"contained"}
                    onClick={() => history.push("/")}
                  >
                    Anuluj
                  </Button>
                  {!my.anonymizationRequestedAt ? (
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <MuiNavLink
                        onClick={() => setOpenFormRemovingPanel(true)}
                      >
                        Poproś o usunięcie konta
                      </MuiNavLink>
                    </Box>
                  ) : (
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="subtitle1">
                        Konto w trakcie usuwania
                      </Typography>
                    </Box>
                  )}
                  <ConfirmDialogMUI
                    handleClose={() => {
                      setOpenFormRemovingPanel(false);
                    }}
                    open={openFormRemovingPanel}
                    text={"Czy na pewno usunąć konto?"}
                    textDetails={
                      "Dostęp do danych zostanie bezpowrotnie utracony. Usunięcie konta może zająć kilka dni."
                    }
                    yesAction={removeAccountHanlder}
                    noAction={() => setOpenFormRemovingPanel(false)}
                  />
                </Grid>
              </Grid> */}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="profilePage:view" ok={page} not={redirect} />;
};

export default ProfilePage;
