import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Sidebar from "../sidebar/sidebar";
import RowControl from "../form-controls/row-control";
import ControlProperties from "../control-properties";
import Can from "../can";
import { Redirect } from "react-router-dom";
import s from "./index.module.css";
import { ConfirmDialogMUI } from "../confirm-dialog-mui";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";

import { fetchGlobalDictionaries } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FormEditor = ({
  onUpdate,
  formId,
  initialRows,
  formEdited,
  displayMode,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [rows, setRows] = useState([[]]);
  const [dragCount, setDragCount] = useState(1);
  const setIsDragging = (value) => {
    // if (value) {
    //   setDragCount(1)
    // } else {
    //   setDragCount(0)
    // }
    // console.log(dragCount)
  };

  const [selectedItem, setSelectedItem] = useState();
  const [itemAdded, setItemAdded] = useState(false);
  const [openGetDictionaryPanel, setOpenGetDictionaryPanel] = useState(false);
  const [
    openGetDictionariesListPanel,
    setOpenGetDictionariesListPanel,
  ] = useState(false);
  const [globalDictionaries, setGlobalDictionaries] = useState([]);
  const [selectedDictionary, setSelectedDictionary] = useState({});

  const [formElements, setFormElements] = useState([]);
  const [newFormElement, setNewFormElement] = useState({});

  const tmpStateVariable = React.useRef();

  useEffect(() => {
    if (initialRows) {
      !tmpStateVariable.current
        ? (tmpStateVariable.current = true)
        : rows.length - 1 < initialRows.length && formEdited(true);
    }
  }, [onUpdate, rows]);

  useEffect(() => {
    if (rows && rows.length) {
      if (rows.length > 2) {
        for (var i = 1; i < rows.length - 1; i = i + 2) {
          if (rows[i].length !== 0) {
            rows.splice(i, 0, []);
          }
        }
      }

      // if last row is not empty, add new empty row
      if (rows[rows.length - 1].length !== 0) {
        setRows([...rows, []]);
        formEdited(true);
      }

      if (rows && formElements && rows.flat().length > formElements.length) {
        const differentElement = rows
          .flat()
          .filter((rowElement) => !formElements.includes(rowElement));

        setNewFormElement(differentElement[0]);
        differentElement[0].dataType === "DICTIONARY" &&
          setOpenGetDictionaryPanel(true);
      }
    }
  }, [rows]);

  useEffect(() => {
    setFormElements(rows.flat());

    onUpdate && onUpdate(rows);
  }, [onUpdate, rows]);

  useEffect(() => {
    async function fetchGlobalDicts() {
      let fetchedGlobalDictionaries;
      if (openGetDictionariesListPanel)
        fetchedGlobalDictionaries = await dispatch(fetchGlobalDictionaries());
      setGlobalDictionaries(fetchedGlobalDictionaries);
    }
    fetchGlobalDicts();
  }, [openGetDictionariesListPanel]);

  const updateRow = (rowIndex, rowItems) => {
    setRows(
      rows.map((row, index) => {
        if (index === rowIndex) {
          formEdited(true);
          return rowItems;
        }
        return row;
      })
    );
  };

  useEffect(() => {
    if (initialRows) {
      setRows([...initialRows, []]);
      setFormElements(initialRows.flat());
    }
  }, [initialRows]);

  const updateSelectedItemConfiguration = (conf) => {
    if (selectedItem) {
      setItemAdded(
        initialRows && initialRows.flat().find((v) => v.id === selectedItem.id)
          ? false
          : true
      );

      const newRows = rows.map((r) => {
        return r.map((item) => {
          if (item.id === selectedItem.id) {
            const newConfiguration = {
              ...item.configuration,
              ...conf,
            };

            setSelectedItem({
              dataType: item.dataType,
              ...selectedItem,
              configuration: newConfiguration,
            });

            return {
              ...item,
              configuration: newConfiguration,
            };
          }

          return item;
        });
      });

      setRows(newRows);
    }
  };

  const removeItem = (row, rowIndex) => {
    setRows(
      rows
        .map((r) => {
          if (row === r) {
            r.splice(rowIndex, 1);
          }
          return r;
        })
        .filter((r, index, all) => {
          return r.length > 0 || index === all.length - 1;
        })
    );
  };

  const moveItemToIndex = ({
    item,
    targetRow,
    targetIndex,
    sourceRow,
    sourceIndex,
  }) => {
    let realItem = item;
    if (rows[sourceRow] !== undefined) {
      if (sourceRow !== undefined && sourceIndex !== undefined) {
        realItem = rows[sourceRow][sourceIndex];
      }

      const rr = rows
        .map((r, rIndex) => {
          if (rIndex === sourceRow && item.id === realItem.id) {
            r.splice(sourceIndex, 1);
          }

          if (rIndex === targetRow && item.id === realItem.id) {
            r.splice(targetIndex, 0, realItem);
          }

          return r;
        })
        .filter((row, index) => row.length > 0 || index === rows.length - 1);

      setRows(rr);
    }
  };

  const renderMenuItem =
    globalDictionaries &&
    globalDictionaries.map((globalDictionary) => {
      return (
        <MenuItem key={globalDictionary.id} value={globalDictionary}>
          {globalDictionary.configuration.label}
        </MenuItem>
      );
    });

  const [hasDictBeenChosen, sethasDictBeenChosen] = useState(false);
  const renderDictionariesList = () => {
    return (
      <Dialog
        open={openGetDictionariesListPanel}
        onClose={() => {
          sethasDictBeenChosen(false);
          setOpenGetDictionariesListPanel(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div>
            <Typography variant="h6" gutterBottom>
              {`Wybierz słownik:`}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {globalDictionaries && (
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="select-outlined-label">
                  Wybierz słownik
                </InputLabel>
                <Select
                  labelId="select-outlined-label"
                  id="select-outlined"
                  value={selectedDictionary || ""}
                  defaultValue=""
                  fullWidth
                  onChange={({ target: { value } }) => {
                    sethasDictBeenChosen(true);
                    setSelectedDictionary(value);
                  }}
                  label="Wybierz słownik"
                >
                  {renderMenuItem}
                  {/* <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              // noAction();
              setOpenGetDictionariesListPanel(false);
              sethasDictBeenChosen(false);
            }}
            color="primary"
            autoFocus
          >
            Anuluj
          </Button>
          <Button
            onClick={() => {
              fetchGlobalDictionaryHandler();
              setOpenGetDictionariesListPanel(false);
              sethasDictBeenChosen(false);
            }}
            color="primary"
            disabled={!hasDictBeenChosen}
          >
            Pobierz
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const fetchGlobalDictionaryHandler = () => {
    let tmpRowsArray = [...rows];

    tmpRowsArray = tmpRowsArray.map((row) =>
      row.map((element) =>
        element.id === newFormElement.id &&
        Object.keys(selectedDictionary).length > 0
          ? { ...selectedDictionary, id: newFormElement.id }
          : element
      )
    );

    setRows(tmpRowsArray);
  };

  const page = (
    <>
      <ConfirmDialogMUI
        handleClose={() => {
          setOpenGetDictionaryPanel(false);
        }}
        open={openGetDictionaryPanel}
        text={"Czy chcesz pobrać istniejący słownik?"}
        yesAction={() => {
          setOpenGetDictionariesListPanel(true);
        }}
        noAction={() => {}}
      />
      {openGetDictionariesListPanel && renderDictionariesList()}
      <div className="columns">
        {!displayMode && (
          <div className="column is-one-fifth">
            <div className={s.sticky}>
              <Sidebar />
            </div>
          </div>
        )}
        <div className="column">
          <div>
            {rows.map((row, rowIndex) => {
              return (
                <RowControl
                  displayMode={displayMode}
                  key={rowIndex}
                  update={(rowItems) => updateRow(rowIndex, rowItems)}
                  markSelectedConfiguration={setSelectedItem}
                  selectedId={(selectedItem && selectedItem.id) || -1}
                  items={row}
                  rowIndex={rowIndex}
                  dragMode="move"
                  formCreatorMode
                  removeItem={(index) => removeItem(row, index)}
                  moveItemToIndex={moveItemToIndex}
                  showDropMarkers={dragCount > 0}
                  setIsDragging={setIsDragging}
                  rowsLength={rows.length}
                />
              );
            })}
          </div>
        </div>

        <div className="column is-one-fifth">
          <div className={s.sticky}>
            <ControlProperties
              displayMode={displayMode}
              controlId={selectedItem && selectedItem.id}
              dataType={selectedItem && selectedItem.dataType}
              configuration={(selectedItem || {}).configuration || {}}
              update={updateSelectedItemConfiguration}
              formEdited={formEdited}
              addMode={itemAdded}
            />
          </div>
        </div>
      </div>
    </>
  );

  const redirect = () => <Redirect to="/login" />;

  return page;
};

export default FormEditor;
