import { createMuiTheme } from "@material-ui/core/styles";
import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export const lightTheme = {
  palette: {
    primary: {
      main: "#23EAB6",
    },
    secondary: {
      main: "#AC1426",
    },

    text: {
      primary: "#000000",
      primary2: "#656B6F",
      primary3: "#666666",
      alternative: "#FFA500",
      alternative2: "#3458A4",
      alternative3: "#323232",
      email: "#282829",
      blueHeader: "#0021db",
      blueHeaderYellow: "#3273dc",
      whiteHeader: "#fff",
      item: "#707070",
      qr: "#5D5D5D",
      default: "#ffffff",
      default2: "#ffffff",
      error: "#ff604f",
      checked: "#009eeb",
      title: "#0898AE",
      buttonTitle: "#5B5B5B",
      id: "#575758",
      idProfile: "#484848",
      pin: "#585858",
      pinProfile: "#58595A",
      placeholder: "#b5b5b5",
      hintText: "#393939",
      badgeText: "#3458A4",
      borderTopLine: "rgb(239, 239, 239,0.1)",
    },
    checkIcon: {
      primary: "#4CAF50",
      secondary: "#3458A4",
    },
    locationIcon: {
      primary: "#3658A4",
    },
    testIcon: {
      primary: "#6BB8C4",
    },
    examinationIcon: {
      primary: "#F1F1F1",
      text: "#124795",
    },
    passwordIcon: {
      primary: "#0064E6",
    },
    passwordIcon2: {
      primary: "#124795",
    },
    lockIcon: {
      primary: "#00E5AE",
    },
    background: {
      default: "#fff",
      secondary: "#F9F9F9",
      menu: "#959595",
      defaultHeader: "#00346A",
      tab: "rgba(250, 250, 250,  .8)",
      border: "rgba(250, 250, 250,  .8)",
      borderTest: "#979797",
      filter: "#f5f5f5",
      filterBorder: "#f5f5f5",
      button: "#FFA500",
      opinionGrid: "#3658A4",
      movie: "#3B3D40",
      movie2: "#3B3D40",
      typography: "#E2E2E2",
      orderButton: "#374A71",
      qrPanelButton: "#B8B8B8",
      saveButton: "#374A71",
      photoUpload: "#B8B8B8",
      cancelButton: "#dbd5d5",
      badgeBackground: "#86D7E3",
      list: "#FBFBFB",
      loginBackground: "#FBFBFB",
      menuItemBorderBottom: "#F1F1F1",
      step:
        "linear-gradient(90deg, rgba(0,158,235,1) 0%, rgba(2,227,179,1) 100%)",
    },
    hr: {
      primary: "#f5f5f5",
    },
    anchor: {
      primary: "#3273dc",
    },
    paragraph: {
      primary: "rgb(18, 33, 64)",
    },
  },
  background: {
    default: "#ffffff",
    menu: "#ffffff",
    // default: "#fbfbfb",
  },
  drawer: {
    backgroundColor: "#fff",
    tableCell: "#fff",
  },
  typography: {
    fontFamily: ["Raleway", "sans-serif"].join(","),
    color: "#5D5D5D",
    fontSize: 12,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    // h6: {
    //   color: "#0021db",
    // },
  },
  props: {
    MuiLink: {
      underline: "none",
    },
  },
  overrides: {
    MuiStepLabel: {
      label: {
        textTransform: "uppercase",
        color: "#ffffff",
        letterSpacing: "1.2px",
        fontWeight: "600",
      },
      active: {
        textTransform: "uppercase",
        color: "#ffffff",
        letterSpacing: "1.2px",
        fontWeight: "600",
      },
      alternativeLabel: {
        textTransform: "uppercase",
        color: "#ffffff",
        letterSpacing: "1.2px",
        fontWeight: "600",
      },
      completed: {
        textTransform: "uppercase",
        color: "#ffffff",
        letterSpacing: "1.2px",
        fontWeight: "600",
      },
    },
    MuiStepIcon: {
      root: {
        color: "#C1C1C1",
        background: "#C1C1C1",
        fontWeight: "700",
        "&$active": {
          fill: "#3458A4",
          color: "#3458A4",
          background: "#3458A4",
          fontWeight: "700",
          "& $text": {
            fill: "#fff",
          },
        },
        "&$completed": {
          fill: "#3458A4",
          color: "#3458A4",
          background: "#3458A4",
          fontWeight: "700",
          "& $text": {
            fill: "#fff",
          },
        },
      },
      text: {
        fill: "#fff",
        fontWeight: "700",
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgb(0 0 0)",
      },
    },
    MuiList: {
      root: {
        background: "#FBFBFB",
      },
    },
    MuiMenuItem: {
      root: {
        color: "#393939",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#959595",
      },
    },
    MuiTableCell: {
      root: {},
    },
    MuiStepper: {
      root: {
        padding: "16px 0px",
      },
    },
    MuiPickersToolbar: {
      toolbar: { backgroundColor: "#757575" },
    },
    MuiPickersToolbarText: {
      toolbarTxt: { color: "#FFFFFF" },
      toolbarBtnSelected: { color: "#FFFFFF" },
    },

    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "#e7e7e7",
        "&:hover": {
          padding: "10px",
          border: "1px solid #6BB8C4",
        },
      },
    },
    MuiPickersYear: {
      root: {
        "&:focus": {
          color: "#6BB8C4",
        },
      },
      yearSelected: { color: "#6BB8C4" },
    },
    MuiPickersMonth: {
      root: {
        "&:focus": {
          color: "#6BB8C4",
        },
      },
      monthSelected: { color: "#6BB8C4" },
    },
    // MuiBadge: {
    //   anchorOriginTopRightRectangle: {
    //     border: "1px solid #000",
    //   },
    // },
    MuiButton: {
      textPrimary: {
        color: "#0898AE",
      },
      containedPrimary: {
        letterSpacing: "2px",
        color: "#ffffff",
        background:
          "linear-gradient(90deg, rgba(0,158,235,1) 0%, rgba(2,227,179,1) 100%)",
        "&:hover": {
          backgroundColor: "#b5b516",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            background:
              "linear-gradient(90deg, rgba(0,158,235,1) 0%, rgba(2,227,179,1) 100%)",
          },
        },
      },
      contained: {
        letterSpacing: "2px",
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        "&$checked": {
          color: "#00E5AE",
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#ffffff",
        "&:hover": {
          backgroundColor: "#ffffff",
        },
        "&.Mui-focused": {
          backgroundColor: "#ffffff",
        },
      },
      underline: {
        "&:before": {
          borderBottomColor: "#23EAB6",
        },
        "&:hover:not(.Mui-focused):before": {
          borderBottomColor: "#23EAB6",
        },
        "&:after": {
          // focused
          borderBottomColor: "#23EAB6",
        },
      },
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px #ffffff inset",
          WebkitTextFillColor: "#000",
        },
        "&[type=number]": {
          "-moz-appearance": "textfield",
        },
        "&::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "&::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
      },
    },
    MuiInputBase: {
      input: {
        color: "#000",
        fontWeight: "300",
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottomColor: "#23EAB6",
        },
        "&:hover:not(.Mui-focused):before": {
          borderBottomColor: "#23EAB6",
        },
        "&:after": {
          // focused
          borderBottomColor: "#23EAB6",
        },
      },
    },
    MuiInputLabel: {
      root: {
        letterSpacing: "0.7px",
      },
    },
    MuiTab: {
      root: {
        color: "black",
        // backgroundColor: "#EEEDED",
        // opacity: 0.6,
        // borderTopRightRadius: "1rem",
        // borderTopLeftRadius: "1rem",
        "&$selected": {
          backgroundColor: "rgba(250, 250, 250,  .8)",
          color: "black",
          // borderTopRightRadius: "1rem",
          // borderTopLeftRadius: "1rem",
          opacity: 1,
          color: "black",
        },
      },
    },
  },
};

export const highContrastTheme = {
  palette: {
    primary: {
      main: "#ffff00",
    },
    secondary: {
      main: "#ffff00",
    },
    error: {
      main: "#ffff00",
    },
    success: {
      main: "#ffff00",
    },
    helper: {
      color: "#ffff00",
    },
    text: {
      primary: "#ffff00",
      primary2: "#ffff00",
      primary3: "#ffff00",
      secondary: "#ffff00",
      error: "#ffff00",
      alternative: "#ffff00",
      alternative2: "#ffff00",
      alternative3: "#ffff00",
      blueHeader: "#0021db",
      blueHeaderYellow: "#ffff00",
      whiteHeader: "#ffff00",
      email: "#ffff00",
      disabled: "#ffff00",
      item: "#ffff00",
      default: "#000",
      default2: "#ffff00",
      error: "#ffff00",
      checked: "#ffff00",
      title: "#ffff00",
      buttonTitle: "#ffff00",
      id: "#ffff00",
      pin: "#ffff00",
      placeholder: "#6a6a0a",
      badgeText: "#000",
      borderTopLine: "#ffff00",
      hintText: "#ffff00",
    },
    passwordIcon: {
      primary: "#ffff00",
    },
    passwordIcon2: {
      primary: "#ffff00",
    },
    lockIcon: {
      primary: "#ffff00",
    },
    checkIcon: {
      primary: "#ffff00",
      secondary: "#000",
    },
    locationIcon: {
      primary: "#ffff00",
    },
    testIcon: {
      primary: "#ffff00",
    },
    examinationIcon: {
      primary: "#ffff00",
      text: "#191B1D",
    },
    background: {
      default: "#000",
      secondary: "#000",
      defaultHeader: "#ffff00",
      tab: "#000",
      border: "#ffff00",
      borderTest: "#ffff00",
      filter: "#000",
      filterBorder: "#ffff00",
      opinionGrid: "#000",
      movie: "#000",
      movie2: "#ffff00",
      typography: "#000",
      orderButton: "#ffff00",
      qrPanelButton: "#ffff00",
      cancelButton: "#000",
      badgeBackground: "#ffff00",
      list: "#000",
      menuItemBorderBottom: "#ffff00",
      step: "#ffff00",
    },
    hr: {
      primary: "#ffff00",
    },
    anchor: {
      primary: "#ffff00",
    },
    paragraph: {
      primary: "#ffff00",
    },
    action: {
      disabledBackground: "#000",
      border: "1px solid red",
      disabled: "#ffff00",
    },
  },
  drawer: {
    backgroundColor: "#000",
    tableCell: "#ffff00",
  },
  typography: {
    fontFamily: ["Raleway", "sans-serif"].join(","),
    color: "#ffff00",
    // fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h6: {
      color: "#ffff00",
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        border: "1px solid #ffff00",
        borderBottom: "1px solid #ffff00",
      },
    },

    MuiStepLabel: {
      label: {
        textTransform: "uppercase",
        color: "#ffff00",
        letterSpacing: "1.2px",
        fontWeight: "600",
      },
      alternativeLabel: {
        textTransform: "uppercase",
        color: "#ffff00",
        letterSpacing: "1.2px",
        fontWeight: "600",
      },
      completed: {
        textTransform: "uppercase",
        color: "#ffff00",
        letterSpacing: "1.2px",
        fontWeight: "600",
      },
      active: {
        textTransform: "uppercase",
        color: "#ffff00",
        letterSpacing: "1.2px",
        fontWeight: "600",
      },
    },
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: "#d1c811",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#000",
      },
    },
    MuiGrid: {
      container: {
        backgroundColor: "#000",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        color: "#ffff00",
        backgroundColor: "#000",
      },
    },
    MuiButton: {
      containedPrimary: {
        letterSpacing: "2px",
      },
      contained: {
        letterSpacing: "2px",
        color: "#000",
        backgroundColor: "#ffff00",
        "&:hover": {
          backgroundColor: "#b5b516",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "#ffff00",
          },
        },
        "&.Mui-disabled": {
          border: `1px solid #ffff00`,
        },
      },
      outlined: {
        color: "#ffff00",
        border: `1px solid #ffff00`,
        "&:hover": {
          // backgroundColor: "#b5b516",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "#ffff00",
          },
        },
        "&.Mui-disabled": {
          border: `1px solid #ffff00`,
        },
      },
    },

    MuiButtonBase: {
      root: {
        "&.Mui-disabled": {
          // border: `1px solid #ffff00`,
        },
      },
    },
    MuiStepper: {
      root: {
        padding: "0px",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "#000",
        "&:hover": {
          padding: "10px",
          border: "1px solid #ffff00",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: "#ffff00",
      },
    },
    MuiAlert: {
      root: {
        backgroundColor: "#000000",
        border: "1px solid #ffff00",
        color: "#ffff00",
      },
      standardWarning: {
        backgroundColor: "#000000",
        border: "1px solid #ffff00",
        color: "#ffff00",
      },
      standardInfo: {
        backgroundColor: "#000000",
        border: "1px solid #ffff00",
        color: "#ffff00",
      },
      standardSuccess: {
        backgroundColor: "#000000",
        border: "1px solid #ffff00",
        color: "#ffff00",
      },
      standardWarning: {
        backgroundColor: "#000000",
        border: "1px solid #ffff00",
        color: "#ffff00",
      },
      standardError: {
        backgroundColor: "#000000",
        border: "1px solid #ffff00",
        color: "#ffff00",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#000",
        "&:hover": {
          backgroundColor: "#000",
        },
        "&$focused": {
          backgroundColor: "#000",
        },
      },
      underline: {
        "&:before": {
          borderBottomColor: "#ffff00",
        },
        "&:hover:not(.Mui-focused):before": {
          borderBottomColor: "#ffff00",
        },
        "&:after": {
          // focused
          borderBottomColor: "#ffff00",
        },
      },
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px #000 inset",
          WebkitTextFillColor: "#ffff00",
        },
        "&[type=number]": {
          "-moz-appearance": "textfield",
        },
        "&::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "&::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottomColor: "#ffff00",
        },
        "&:hover:not(.Mui-focused):before": {
          borderBottomColor: "#ffff00",
        },
        "&:after": {
          // focused
          borderBottomColor: "#ffff00",
        },
      },
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px #000 inset",
          WebkitTextFillColor: "#ffff00",
        },
        "&[type=number]": {
          "-moz-appearance": "textfield",
        },
        "&::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "&::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "#ffff00",
        },
        "&:hover $notchedOutline": {
          borderColor: "#ffff00",
        },
        "&$focused $notchedOutline": {
          borderColor: "#ffff00",
        },
      },
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px #000 inset",
          WebkitTextFillColor: "#ffff00",
        },
        "&[type=number]": {
          "-moz-appearance": "textfield",
        },
        "&::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "&::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
      },
    },
    MuiInputLabel: {
      root: {
        letterSpacing: "0.7px",
      },
      filled: {
        color: "#d1c811",
        ".MuiFormControl-root:hover &:not($focused)": {
          color: "#ffff00",
        },
        "&$focused": {
          color: "#ffff00",
        },
      },
      outlined: {
        color: "#ffff00",
        "&$disabled": {
          color: "#d1c811",
        },
        ".MuiFormControl-root:hover &:not($focused)": {
          color: "#ffff00",
        },
        "&$focused": {
          color: "#ffff00",
        },
      },
    },
    MuiTab: {
      root: {
        "& .MuiTab-wrapper": {
          color: "#ffff00",
        },
        color: "#ffff00",
        backgroundColor: "#000",
        borderTopRightRadius: "1rem",
        borderTopLeftRadius: "1rem",
        "&$selected": {
          border: "1px solid #ffff00",
          backgroundColor: "#000",
          color: "#ffff00",
          borderTopRightRadius: "1rem",
          borderTopLeftRadius: "1rem",
          opacity: 1,
        },
      },
    },
    MuiLink: {
      root: {
        "&$focusVisible": {
          backgroundColor: "#000",
        },
      },
    },
    MuiBadge: {
      anchorOriginTopRightRectangle: {
        border: "1px solid #000",
      },
    },
    MuiStepConnector: {
      line: {
        borderColor: "#ffff00",
      },
    },
  },
  props: {
    MuiLink: {
      underline: "none",
    },
    MuiSvgIcon: {
      htmlColor: "#ffff00",
    },
  },
};

const FONT_SIZE = 14;
const MENU = 18;
const MAIN_TITLE = 49;
const MAIN_TITLE_MOBILE = 30;
const MAIN_TITLE2 = 55;
const MAIN_TITLE2_MOBILE = 52;
const TITLE = 30;
const TEXT = 18;
const TEXT2 = 20;
const TEXT3 = 17;
const HEADER = 24;
const HEADER_LARGE = 60;
const FOOTER = 14;
const FOOTER_MOBILE = 12;
const FORM_LABEL = 16;
const HINT_TEXT = 10;
const DESC = 11;
const REDIRECT_CARD_TITLE = 22;
const BUTTON_WIDTH = 204;
const ASSIGN_BUTTON_WIDTH = 150;
const OPINION = 34;
const PARAGRAPH = 15;

export const fontSizeNormal = {
  typography: {
    fontSize: FONT_SIZE,
    menu: MENU,
    mainTitle: MAIN_TITLE,
    mainTitleMobile: MAIN_TITLE_MOBILE,
    mainTitle2: MAIN_TITLE2,
    mainTitle2Mobile: MAIN_TITLE2_MOBILE,
    title: TITLE,
    text: TEXT,
    text2: TEXT2,
    text3: TEXT3,
    header: HEADER,
    headerLarge: HEADER_LARGE,
    footer: FOOTER,
    footerMobile: FOOTER_MOBILE,
    formLabel: FORM_LABEL,
    hintText: HINT_TEXT,
    desc: DESC,
    redirectCardTitle: REDIRECT_CARD_TITLE,
    buttonWidth: BUTTON_WIDTH,
    assignButtonWidth: ASSIGN_BUTTON_WIDTH,
    opinion: OPINION,
    paragraph: PARAGRAPH,
    underline: "16px",
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: [TEXT, "!important"],
      },
    },
  },
};

export const fontSizeMedium = {
  typography: {
    fontSize: FONT_SIZE * 1.14,
    menu: MENU * 1.14,
    mainTitle: MAIN_TITLE * 1.14,
    mainTitleMobile: MAIN_TITLE_MOBILE * 1.14,
    mainTitle2: MAIN_TITLE2 * 1.14,
    mainTitle2Mobile: MAIN_TITLE2_MOBILE * 1.14,
    title: TITLE * 1.14,
    text: TEXT * 1.14,
    text2: TEXT2 * 1.14,
    text3: TEXT3 * 1.14,
    header: HEADER * 1.14,
    headerLarge: HEADER_LARGE * 1.14,
    footer: FOOTER * 1.14,
    footerMobile: FOOTER_MOBILE * 1.14,
    formLabel: FORM_LABEL * 1.14,
    hintText: HINT_TEXT * 1.14,
    desc: DESC * 1.14,
    redirectCardTitle: REDIRECT_CARD_TITLE * 1.14,
    buttonWidth: BUTTON_WIDTH * 1.14,
    assignButtonWidth: ASSIGN_BUTTON_WIDTH * 1.14,
    opinion: OPINION * 1.14,
    paragraph: PARAGRAPH * 1.14,
    underline: "15px",
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: [TEXT * 1.14, "!important"],
      },
    },
  },
};

export const fontSizeLarge = {
  typography: {
    fontSize: FONT_SIZE * 1.29,
    menu: MENU * 1.29,
    mainTitle: MAIN_TITLE * 1.29,
    mainTitleMobile: MAIN_TITLE_MOBILE * 1.29,
    mainTitle2: MAIN_TITLE2 * 1.29,
    mainTitle2Mobile: MAIN_TITLE2_MOBILE * 1.29,
    title: TITLE * 1.29,
    text: TEXT * 1.29,
    text2: TEXT2 * 1.29,
    text3: TEXT3 * 1.29,
    header: HEADER * 1.29,
    headerLarge: HEADER_LARGE * 1.29,
    footer: FOOTER * 1.29,
    footerMobile: FOOTER_MOBILE * 1.29,
    formLabel: FORM_LABEL * 1.29,
    hintText: HINT_TEXT * 1.29,
    desc: DESC * 1.29,
    redirectCardTitle: REDIRECT_CARD_TITLE * 1.29,
    buttonWidth: BUTTON_WIDTH * 1.29,
    assignButtonWidth: ASSIGN_BUTTON_WIDTH * 1.29,
    opinion: OPINION * 1.29,
    paragraph: PARAGRAPH * 1.29,
    underline: "13px",
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: [TEXT * 1.29, "!important"],
      },
    },
  },
};
