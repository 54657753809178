import React, { useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import QRCode from "qrcode";
import ReactToPrint from "react-to-print";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialogActions-root": {
      justifyContent: "center",
    },
    "& .MuiDialog-paper": {
      borderRadius: "0px",
      padding: "16px 8px",
    },
  },
  title: {
    color: theme.palette.text.title,
    fontSize: theme.typography.fontSize,
    textTransform: "uppercase",
    fontWeight: "600",
    letterSpacing: "1.5px",
    textAlign: "center",
  },
  idText: {
    fontSize: theme.typography.text2,
    color: theme.palette.text.id,
    fontWeight: "700",
    textAlign: "center",
    letterSpacing: "1.5px",
  },
  idPin: {
    fontSize: theme.typography.text2,
    color: theme.palette.text.pin,
    fontWeight: "700",
    textAlign: "center",
    letterSpacing: "1.5px",
  },
  text: {
    color: theme.palette.text.buttonTitle,
    fontSize: theme.typography.hintText,
    letterSpacing: "1px",
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: "700",
  },
  locationButton: {
    backgroundColor: theme.palette.background.saveButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `115px`,
    height: "40px",
    marginRight: "15px",
  },
  testButton: {
    backgroundColor: theme.palette.testIcon.primary,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `115px`,
    height: "40px",
    marginRight: "5px",
  },
  closeButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
  },
}));

const QRCodeModal = ({ handleClose, open }) => {
  const qrPIN = useSelector((s) => s.qrCodePIN);
  const qrID = useSelector((s) => s.qrCodeID);
  const [qrCodeURL, setQRCodeURL] = useState("");

  const classes = useStyles();

  useEffect(() => {
    if (open) {
      async function generateQRCode() {
        try {
          const response = await QRCode.toDataURL(
            JSON.stringify({
              id: qrID,
              pin: qrPIN,
            })
          );
          setQRCodeURL(response);
        } catch (e) {
          console.log(e);
        }
      }
      generateQRCode();
    }
  }, [open]);
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const componentRef = useRef();

  const handleSave = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = qrCodeURL;
    downloadLink.download = "Hearbox: Kod QR";
    downloadLink.click();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth={smallScreen ? "xl" : "sm"}
      className={classes.root}
    >
      <DialogTitle id="qr code">
        <Typography className={classes.title}>
          Twój kod QR do logowania na stanowisku Hearbox:
        </Typography>
        {handleClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent id="qr code image">
        {qrCodeURL ? (
          <>
            <Box mt={1} mb={1}>
              <Typography className={classes.idText}>
                {`ID: ${qrID}`}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography className={classes.idPin}>
                {`PIN: ${qrPIN}`}
              </Typography>
            </Box>
            <Box style={{ textAlign: "center" }} ref={componentRef}>
              {qrCodeURL && (
                <img
                  src={qrCodeURL}
                  alt="img"
                  style={{ width: smallScreen ? "55%" : "35%" }}
                />
              )}
            </Box>

            <Typography className={classes.text}>
              Proszę wydrukować ten dokument lub zapisać go na telefonie lub
              tablecie
            </Typography>
          </>
        ) : (
          <Typography variant="body1" color="textPrimary">
            Brak kodu. Proszę spróbować później.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <ReactToPrint
          trigger={() => (
            <Button
              className={classes.testButton}
              disabled={!qrCodeURL}
              variant={"contained"}
            >
              Drukuj
            </Button>
          )}
          content={() => componentRef.current}
        />

        <Button
          className={classes.locationButton}
          variant={"contained"}
          onClick={handleSave}
          disabled={!qrCodeURL}
          autoFocus
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRCodeModal;
