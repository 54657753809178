import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchForm,
  fetchFormData,
  fetchFormTemplate,
} from "../../redux/actions";
import FormDetails from "../../components/form-details";
import Can from "../../components/can";
import format from "date-fns/format";
import { ColorBox } from "../../components/color-box";
import Alert from "@material-ui/lab/Alert";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  alertHighContrast: {
    "& .MuiAlert-icon": {
      // fontSize: 40,
      color: theme.palette.text.primary,
    },
    backgroundColor: "#000",
    border: "1px solid #fff200",
    color: theme.palette.text.primary,
  },
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
}));

const FilledFormDetails = ({ formData, form, formTemplate }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const globalTheme = useSelector((s) => s.globalTheme);
  const [rows, setRows] = useState([]);
  const [color, setColor] = useState("");
  const [text, setText] = useState("");

  const location = useLocation();

  const [completedAt, setCompletedAt] = useState("");
  const [filledBy, setFilledBy] = useState("");
  const [totalWeight, setTotalWeight] = useState("");

  const [locationValues, setLocationValues] = useState({});
  const [filledValuesDetails, setFilledValuesDetails] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [filesData, setFilesData] = useState([]);

  // const [formTemplate, setFormTemplate] = useState();

  const [textColorFilling, setTextColorFilling] = useState(true);
  const [alertDisplaying, setAlertDisplaying] = useState(true);
  const [showNotification, setShowNotification] = useState(true);

  const isJustUser = useSelector(
    (s) => s?.my?.user?.authorities === "ROLE_USER"
  );
  const hasSingleScheduledForm = useSelector((s) => s.hasSingleScheduledForm);

  const [redirectToMain, setRedirectToMain] = useState(false);

  const fetchAll = async () => {
    // const data = await dispatch(fetchFormData(formDataId));

    // const form = await dispatch(fetchForm(data.form.id));
    // const template = await dispatch(fetchFormTemplate(form.layoutId));

    // setForm(form);
    // setFormTemplate(template);

    const data = formData[0];

    const arrayWithRowData = Object.entries(data.data).map(([id, value]) => ({
      id,
      value,
    }));
    const arrayWithAlternativeAnswer = Object.entries(
      data.fieldAlternativeAnswer
    ).map(([id, value]) => ({
      id,
      alternativeAnswer: value,
    }));
    setNotesData(
      Object.entries(data.noteData).map(([id, note]) => ({
        id,
        note,
      }))
    );

    setFilesData(
      Object.entries(data.filesData).map(([id, note]) => ({
        id,
        note,
      }))
    );

    setFilledValuesDetails(
      arrayWithRowData.map((item) => {
        if (arrayWithAlternativeAnswer.find((a) => a.id === item.id)) {
          return Object.assign(
            {},
            item,
            arrayWithAlternativeAnswer.find((a) => a.id === item.id)
          );
        } else {
          return item;
        }
      })
    );

    setTotalWeight(data.totalWeight);
    setCompletedAt(data.completedAt);
    setFilledBy(
      data.filledForSubaccount
        ? `${data.filledForSubaccount.firstName} ${data.filledForSubaccount.surname}`
        : data.filledBy?.name
    );

    setColor(data.color);
    setText(data.text);

    setLocationValues({
      ...arrayWithRowData.map((item) => {
        if (arrayWithAlternativeAnswer.find((a) => a.id === item.id)) {
          return Object.assign(
            {},
            item,
            arrayWithAlternativeAnswer.find((a) => a.id === item.id)
          );
        } else {
          return item;
        }
      }),
      ...data.arrayData,
    });
  };

  useEffect(() => {
    setTimeout(() => setShowNotification(false), 7000);
  }, []);

  useEffect(() => {
    fetchAll();
  }, [formData]);

  useEffect(() => {
    if (form.id === formTemplate.formId) {
      const loToRows = () => {
        return formTemplate.layoutElementObject.children.map((c) => {
          return c.children.map((c) => {
            return c;
          });
        });
      };

      if (Object.keys(formTemplate).length > 0) {
        setRows(loToRows());
      }
    }
  }, [formTemplate]);

  const data = (formData || []).map(({ items, ...row }) => {
    const baseValues = {
      createdBy: {
        id: "createdBy",
        label: "createdBy",
        value: row.createdByFullName || row.createdBy,
      },
      createdAt: {
        id: "createdAt",
        label: "createdAt",
        value: format(new Date(row.completedAt), "yyyy-MM-dd HH:mm"),
      },
    };

    return {
      ...row,
      values: {
        ...baseValues,
        ...items.reduce((obj, current) => {
          return {
            ...obj,
            [current.id]: current,
          };
        }, {}),
      },
    };
  });

  return (
    <div
      className="container is-fluid"
      style={{ display: "flex", flexDirection: "column" }}
    >
      {/* <div
    className="title"
    style={{
      display: "flex",
      flexDirection: "row",
      color: globalTheme === "high-contrast" && "#fff200",
    }}
  >
    <div>Formularz "{form ? form.name : "..."}" </div>
  </div> */}
      <div>
        {completedAt && (
          <span>
            Data wypełnienia:
            <span>
              {" "}
              <strong
                style={{ color: globalTheme === "high-contrast" && "#fff200" }}
              >
                {format(new Date(completedAt), "yyyy-MM-dd HH:mm")}
              </strong>
            </span>
          </span>
        )}
        <br />
        <span>
          Wypełniający:
          <span>
            {" "}
            <strong
              style={{ color: globalTheme === "high-contrast" && "#fff200" }}
            >
              {filledBy}
            </strong>
          </span>
        </span>
      </div>{" "}
      {color && (
        <div>
          <br />
          <ColorBox
            color={color}
            title={text}
            maxChars={Number.MAX_SAFE_INTEGER}
          ></ColorBox>

          {totalWeight
            ? !isJustUser && (
                <span>
                  {" - "}
                  Całkowita liczba punktów:{" "}
                  <strong style={{ color: color || "" }}>
                    {totalWeight}
                  </strong>{" "}
                  pkt
                </span>
              )
            : ""}
        </div>
      )}{" "}
      {form && form.description && (
        <>
          <br />
          <b>Opis</b>
          <small>{form.description}</small>
        </>
      )}
      <hr />
      {rows.length && (
        <FormDetails
          rows={rows}
          values={locationValues}
          filledValuesDetails={filledValuesDetails}
          notesData={notesData}
          filesData={filesData}
          alertDisplaying={alertDisplaying}
          textColorFilling={textColorFilling}
          // match={match}
          form={form}
        />
      )}
      <br />
    </div>
  );
  // const pageOrReload = redirectToMain
  //   ? () => <Redirect to="/forms/active" />
  //   : page;

  // const redirect = () => <Redirect to="/login" />;

  // return (
  //   <Can permission="form-fill-details:page" ok={pageOrReload} not={redirect} />
  // );
};

export default FilledFormDetails;
