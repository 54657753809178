export const EXAMINATIONS_MAP = {
  AP: "Audiometria Przesiewowa",
  GC: "Audiometria Progowa",
  AS: "Audiometria Słowna",
  TDW: "Test Dyskryminacji Wysokości",
  TSW: "Test Sekwencji Wysokości",
  TSD: "Test Sekwencji Długości",
  TPR: "Test Przerw Randomizowanych",
  TRC: "Test Rozdzielnouszny Cyfr",
  TRS: "Test Rozdzielnouszny Słów",
  TRMS: "Test Rozumienia Mowy w Szumie",
  TMS: "Test Mowy Skompresowanej",
  TMF: "Test Mowy Filtrowanej",
};
