import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader";
import { getAxiosInstance } from "../../redux/common";
import {
  Box,
  Button,
  makeStyles,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
// import TestStandModal from "./modal/test-stand-modal";
import { Alert } from "@material-ui/lab";
import FilterProperty, {
  FilterPropertySelect,
} from "../../components/filter-property/filter-property";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";
import PackageRow from "./package-row";
import PackageModal from "./package-modal";
import PriceListModal from "../screening-tests/modal/PriceListModal";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { Paper } from "../organizations";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  table: {
    minWidth: 650,
  },
  stickyRow: {
    position: "sticky",
    top: "0px",
    background: "white",
    zIndex: "100",
  },
  contextFlexContainer: {
    display: "flex",
    gap: "15px",
  },
  tableContainer: {
    flexGrow: 1,
    minHeight: "1px",
  },
}));

const PackagesAdminPanel = () => {
  const classes = useStyles();

  const [packages, setPackages] = useState([]);
  const [commercialPackages, setCommercialPackages] = useState([]);
  const [missingPricelist, setMissingPricelist] = useState([]);
  const [fetchingPackages, setFetchingPackages] = useState(false);

  const [openPackageModal, setOpenPackageModal] = useState(false);
  const [packageHandlerType, setPackageHandlerType] = useState("edition");
  const [packageToEdit, setPackageToEdit] = useState({});

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("label");

  const [savedPackage, setSavedPackage] = useState(false);

  const [filterQueries, setFilterQueries] = useState({});
  const [displaySearchButton, setDisplaySearchButton] = useState(false);

  const [packagePriceListToEdit, setPackagePriceListToEdit] = useState("");
  const [openPriceListPanel, setOpenPriceListPanel] = useState(false);

  const tenantsContext = useSelector((s) => s.tenantsContext || "");

  useEffect(() => {
    loadPackages();
  }, [order]);

  useEffect(() => {
    setDisplaySearchButton(true);
  }, [filterQueries]);

  useEffect(() => {
    if (savedPackage) {
      loadPackages();
    }
  }, [savedPackage]);
  // }, [pageNr, rowsPerPage, orderBy, order]);

  const loadPackages = async (areFilterQueriesEmpty = false) => {
    try {
      setFetchingPackages(true);
      let q = null;
      if (Object.keys(filterQueries).length > 0 && !areFilterQueriesEmpty) {
        q = Object.entries(filterQueries)
          .map(([key, value]) => {
            return {
              key: key,
              value: value,
            };
          })
          .reduce(
            (obj, item) => ({
              ...obj,
              [item.key]:
                item.key === "allowDoctorDescription"
                  ? { is: item.value === "true" ? true : false }
                  : { contains: item.value },
            }),
            {}
          );
      }
      const response = await getAxiosInstance().post(
        "/api/examination_package/find",
        {
          ...(orderBy
            ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
            : {}),
          ...(q && { q }),
        }
      );
      const responseScreenings = await getAxiosInstance().get(
        "/screening/v1/owner/all",
        {
          params: {
            ...(tenantsContext.length > 0 && {
              tenantIds: tenantsContext.map((org) => org.tenantId).join(","),
            }),
          },
        }
      );
      setCommercialPackages(
        responseScreenings.data.filter((f) => f.screeningType === "Commercial")
      );
      const responseMissingPriceList = await getAxiosInstance().get(
        "/api/product_price/owner/packages_with_missing_prices"
      );
      setMissingPricelist(responseMissingPriceList.data);
      setFetchingPackages(false);
      setPackages(response.data.content);
    } catch (e) {
      setFetchingPackages(false);
      setPackages([]);
    }
  };
  const searchFilterHandler = () => {
    setDisplaySearchButton(false);
    // setPageNr(0);
    loadPackages();
  };

  const clearAllFilterHandler = () => {
    setFilterQueries({});
    setDisplaySearchButton(false);
    // setPageNr(0);
    loadPackages(true);
  };

  const headerCells = [
    {
      title: "Nazwa",
      key: "label",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Identyfikator",
      key: "hearBoxTestId",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Dostępne badania",
      key: "examinations",
      sortable: false,
      filter: (key) => null,
    },
    {
      title: "Opis",
      key: "description",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Możliwość opisu badań",
      key: "allowDoctorDescription",
      sortable: true,
      filter: (key) => (
        <FilterPropertySelect
          label={"Możliwość opisu badań"}
          listItems={[
            { value: "true", label: "Tak" },
            { value: "false", label: "Nie" },
          ]}
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Opcje",
      key: "options",
      sortable: false,
      filter: (key) => (
        <Box>
          {displaySearchButton && (
            <Button
              color="primary"
              // size="large"
              startIcon={<SearchIcon />}
              onClick={searchFilterHandler}
            >
              Szukaj
            </Button>
          )}
          {Object.keys(filterQueries).length !== 0 && (
            <Button
              color="primary"
              // size="large"
              startIcon={<ClearIcon />}
              onClick={clearAllFilterHandler}
            >
              <Tooltip title="Czyści wszystkie pola filtracji">
                <span>Wyczyść</span>
              </Tooltip>
            </Button>
          )}
        </Box>
      ),
    },
  ];

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const orgAdditionButtonHandler = () => {
    setPackageHandlerType("add");
    setOpenPackageModal(true);
  };

  return fetchingPackages ? (
    <Loader loading={true} text="Pobieranie pakietów" />
  ) : (
    <Box className={classes.rootContainer}>
      <Button
        style={{ alignSelf: "baseline" }}
        variant={"contained"}
        onClick={orgAdditionButtonHandler}
      >
        Dodaj nowy pakiet
      </Button>
      <Box className={classes.tableContainer} pt={3}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="Test stands list">
            <TableHead>
              <TableRow className={classes.stickyRow}>
                {headerCells.map((headCell) =>
                  headCell.sortable ? (
                    <TableCell
                      key={headCell.key}
                      // sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <Tooltip title="Sortuj">
                        <TableSortLabel
                          active={orderBy === headCell.key}
                          direction={orderBy === headCell.key ? order : "asc"}
                          onClick={() => {
                            handleSortRequest(headCell.key);
                          }}
                        >
                          {headCell.title}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  ) : (
                    <TableCell>{headCell.title}</TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                {headerCells.map((headCell) => (
                  <TableCell key={headCell.key}>
                    {headCell.filter(headCell.key)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {packages.map((singlePackage, index) => (
                <PackageRow
                  key={index}
                  singlePackage={singlePackage}
                  setOpenPackageModal={setOpenPackageModal}
                  setPackageToEdit={setPackageToEdit}
                  setPackageHandlerType={setPackageHandlerType}
                  setPackagePriceListToEdit={setPackagePriceListToEdit}
                  setOpenPriceListPanel={setOpenPriceListPanel}
                  missingPricelist={missingPricelist}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {openPriceListPanel && (
        <PriceListModal
          openPriceListPanel={openPriceListPanel}
          packagePriceListToEdit={packagePriceListToEdit}
          // screeningTestsHandlerType={screeningTestsHandlerType}
          handleClose={() => {
            setOpenPriceListPanel(false);
            loadPackages();
          }}
          commercialPackages={commercialPackages[0]}
        />
      )}
      {openPackageModal && (
        <PackageModal
          openPackageModal={openPackageModal}
          packageHandlerType={packageHandlerType}
          handleClose={() => {
            setOpenPackageModal(false);
          }}
          packageToEdit={packageHandlerType === "add" ? {} : packageToEdit}
          setSavedPackage={setSavedPackage}
        />
      )}
      {/* <ConfirmDialogMUI
        handleClose={() => {
          setOpenPackageRemovePanel(false);
        }}
        open={openPackageRemovePanel}
        text={"Czy na pewno usunąć pakiet " + packageToRemove.label + "?"}
        yesAction={packageRemoveHandler}
        noAction={() => {
          setPackageToRemove({});
        }}
      /> */}
    </Box>
  );
};

export default PackagesAdminPanel;
