import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

export const ConfirmDialogMUI = ({
  text,
  yesAction,
  noAction,
  busy,
  handleClose,
  open,
  yesLabel = "",
  noLabel = "",
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div>
          <Typography variant="h6" gutterBottom color="textPrimary">
            {text}
          </Typography>
        </div>
      </DialogTitle>
      {/*<DialogContent>*/}
      {/*  <DialogContentText id="alert-dialog-description">*/}
      {/*  </DialogContentText>*/}
      {/*</DialogContent>*/}
      <DialogActions>
        <Button
          onClick={() => {
            noAction();
            handleClose();
          }}
          color="primary"
          // autoFocus
        >
          {noLabel === "" ? "NIE" : noLabel}
        </Button>
        <Button
          onClick={() => {
            yesAction();
            handleClose();
          }}
          color="primary"
        >
          {yesLabel === "" ? "TAK" : yesLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
