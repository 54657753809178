import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import { getAxiosInstance } from "../../../redux/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FilterList } from "@material-ui/icons";
import Loader from "../../../components/loader";
import { Alert } from "@material-ui/lab";
import { Tooltip } from "@material-ui/core";

function getModalStyle() {
  const top = 50;
  const left = 0;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const titleMap = {};

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  paper: {
    width: "100vh",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.alternative,
  },
  innerPaper: {
    padding: theme.spacing(2),
    margin: "auto",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width: 600px)": {
      padding: "20px",
    },
    // "@media (max-width: 600px)": {
    //   height: "100vh",
    // },
    // height: "70vh",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const PriceListModal = ({
  openPriceListPanel,
  handleClose,
  packagePriceListToEdit,
  commercialPackages,
}) => {
  // const { examinationPackages } = screeningTestToEdit;
  const classes = useStyles();

  const theme = useTheme();
  const [busy, setBusy] = useState(false);
  const [fetchingPrice, setFetchingPrice] = useState(false);

  const [priceList, setPriceList] = useState([]);
  const [priceListLength, setPriceListLength] = useState(0);
  const [valid, setValid] = useState(false);
  const [price, setPrice] = useState("");
  const [doctorDescriptionPrices, setDoctorDescriptionPrices] = useState([
    {
      title: "lek",
      description: "Opis badania przez osobę z tytułem lekarza",
    },
    {
      title: "dr",
      description: "Opis badania przez osobę z tytułem doktora",
    },
    {
      title: "prof",
      description: "Opis badania przez osobę z tytułem profesora",
    },
  ]);

  const [errorMessage, setErrorMessage] = useState("");

  const isCommercialPackage =
    commercialPackages?.examinationPackages?.find(
      (p) => p === packagePriceListToEdit.hearBoxTestId
    ) || false;

  useEffect(() => {
    setFetchingPrice(true);
    fetchPacakgePriceList();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (errorMessage) {
        setErrorMessage("");
      }
    }, [5000]);
  }, [errorMessage]);

  useEffect(() => {
    if (priceList.length === 1) {
      setDoctorDescriptionPrices(priceList[0].doctorDescriptionPrices);
      setPrice(priceList[0].price);
    }
  }, [priceList]);

  useEffect(() => {
    const regex = /^[0,]+$/;

    if (isCommercialPackage) {
      setValid(
        !doctorDescriptionPrices
          .map((p) => ({
            price: p.price ? p.price : false,
          }))
          .some(({ price }) => !price || price.match(regex)) &&
          !price.match(regex) &&
          price
      );
    } else {
      setValid(
        !doctorDescriptionPrices
          .map((p) => ({
            price: p.price ? p.price : false,
          }))
          .some(({ price }) => !price || price.match(regex))
      );
    }
  }, [doctorDescriptionPrices, price]);

  const fetchPacakgePriceList = async () => {
    try {
      const response = await getAxiosInstance().get(
        `/api/product_price/owner/by_hear_box_test_id/${packagePriceListToEdit.hearBoxTestId}`
      );

      setPriceListLength(response.data.length);
      setPriceList(
        response.data.map((p) => ({
          ...p,
          price: (p.price / 100).toFixed(2).toString().replaceAll(".", ","),
          doctorDescriptionPrices: p.doctorDescriptionPrices.map((desc) => ({
            ...desc,
            price: (desc.price / 100)
              .toFixed(2)
              .toString()
              .replaceAll(".", ","),
          })),
        }))
      );
      setFetchingPrice(false);
    } catch (e) {
      console.log(e);
      setFetchingPrice(false);
    }
  };

  const fillCurrencyValue = (price) => {
    if (price) {
      if (!price.split(",")[1]) {
        return `${price},00`;
      } else if (price.split(",")[1].length === 1) {
        return `${price}0`;
      } else {
        return price;
      }
    } else {
      return "0";
    }
  };

  const saveHandler = async () => {
    try {
      const response = await getAxiosInstance().post(
        `/api/product_price/owner`,
        [
          {
            hearBoxTestId: packagePriceListToEdit.hearBoxTestId,
            price: parseInt(fillCurrencyValue(price)?.replaceAll(",", "")),
            doctorDescriptionPrices: doctorDescriptionPrices.map((desc) => ({
              ...desc,
              price: parseInt(
                fillCurrencyValue(desc?.price)?.replaceAll(",", "")
              ),
            })),
          },
        ]
      );
      if (response) {
        handleClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const removeHandler = async () => {
    try {
      const packageIdToRemove = packagePriceListToEdit.hearBoxTestId;
      const response = await getAxiosInstance().delete(
        `/api/product_price/owner/${packageIdToRemove}`
      );
      if (response) {
        handleClose();
      }
    } catch (e) {
      if (
        e.response.status === 400 &&
        e.response.data.message ===
          "cannot-remove-price-it-is-assigned-to-screening"
      ) {
        setErrorMessage(
          "Nie można usunąć cennika, który jest przypisany do przesiewu"
        );
      } else {
        setErrorMessage("Wystąpił błąd - cennik nie został usunięty");
      }
      // console.log(e);
    }
  };

  const title = (
    <Box mt={1} style={{ display: "flex" }}>
      <Typography variant="h6" gutterBottom>
        {`Zarządzanie cennikiem`}
      </Typography>
    </Box>
  );

  const foot = (
    <Grid>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant={"contained"}
          //   disabled={savingTest}
          onClick={() => handleClose()}
        >
          Anuluj
        </Button>
        {valid ? (
          <Button variant={"contained"} color="primary" onClick={saveHandler}>
            {busy && <CircularProgress size={14} />}
            Zapisz
          </Button>
        ) : (
          <Tooltip title="Wszystkie ceny muszą zostać uzupełnione">
            <span>
              <Button
                variant={"contained"}
                color="primary"
                disabled={!valid}
                onClick={saveHandler}
              >
                {busy && <CircularProgress size={14} />}
                Zapisz
              </Button>
            </span>
          </Tooltip>
        )}
        &nbsp;&nbsp;&nbsp;{" "}
      </Box>
    </Grid>
  );

  return (
    <Modal open={openPriceListPanel} className={classes.modalStyle}>
      <Grid container>
        <Grid item xs={12} className={classes.grid}>
          <Paper className={classes.paper}>
            {" "}
            {title} <hr />
            {fetchingPrice ? (
              <Loader loading={true} text="Pobieranie cen..." />
            ) : (
              <Box>
                <Box mt={1}>
                  {errorMessage && (
                    <Alert severity={"error"}>{errorMessage}</Alert>
                  )}
                </Box>
                <Box mt={1}>
                  <TextField
                    label="Nazwa pakietu"
                    variant="outlined"
                    value={packagePriceListToEdit.hearBoxTestId}
                    fullWidth
                    disabled
                  />
                </Box>
                <Box mt={1}>
                  <TextField
                    label="Identyfikator pakietu"
                    variant="outlined"
                    value={packagePriceListToEdit.label}
                    fullWidth
                    disabled
                  />
                </Box>
                <Box mt={1}>
                  <TextField
                    label="Cena pakietu"
                    variant="outlined"
                    value={price}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">PLN</InputAdornment>
                      ),
                    }}
                    onChange={({ target: { value: price } }) => {
                      if (price.match(/^[0-9,]*$/)) {
                        if (price.split(",")[1]) {
                          if (price.split(",")[1].length < 3) {
                            setPrice(price);
                          }
                        } else {
                          setPrice(price);
                        }
                      }
                    }}
                    inputProps={{
                      "aria-label": "Nazwa przesiewu",
                      maxLength: 255,
                    }}
                  />
                </Box>
                <Box mt={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Cena"
                        variant="outlined"
                        value={
                          doctorDescriptionPrices.find(
                            ({ title }) => title === "lek"
                          ).price || ""
                        }
                        fullWidth
                        onChange={({ target: { value: price } }) => {
                          if (price.match(/^[0-9,]*$/)) {
                            if (price.split(",")[1]) {
                              if (price.split(",")[1].length < 3) {
                                setDoctorDescriptionPrices(
                                  doctorDescriptionPrices.map((item) =>
                                    item.title === "lek"
                                      ? { ...item, ["price"]: price }
                                      : item
                                  )
                                );
                              }
                            } else {
                              setDoctorDescriptionPrices(
                                doctorDescriptionPrices.map((item) =>
                                  item.title === "lek"
                                    ? { ...item, ["price"]: price }
                                    : item
                                )
                              );
                            }
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              PLN
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          "aria-label": "Nazwa przesiewu",
                          maxLength: 255,
                        }}
                        helperText={
                          "Opis badania przez osobę z tytułem lekarza"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              PLN
                            </InputAdornment>
                          ),
                        }}
                        label="Cena"
                        variant="outlined"
                        value={
                          doctorDescriptionPrices.find(
                            ({ title }) => title === "dr"
                          ).price || ""
                        }
                        fullWidth
                        onChange={({ target: { value: price } }) => {
                          if (price.match(/^[0-9,]*$/)) {
                            if (price.split(",")[1]) {
                              if (price.split(",")[1].length < 3) {
                                setDoctorDescriptionPrices(
                                  doctorDescriptionPrices.map((item) =>
                                    item.title === "dr"
                                      ? { ...item, ["price"]: price }
                                      : item
                                  )
                                );
                              }
                            } else {
                              setDoctorDescriptionPrices(
                                doctorDescriptionPrices.map((item) =>
                                  item.title === "dr"
                                    ? { ...item, ["price"]: price }
                                    : item
                                )
                              );
                            }
                          }
                        }}
                        inputProps={{
                          "aria-label": "Nazwa przesiewu",
                          maxLength: 255,
                        }}
                        helperText={
                          "Opis badania przez osobę z tytułem doktora"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              PLN
                            </InputAdornment>
                          ),
                        }}
                        label="Cena"
                        variant="outlined"
                        value={
                          doctorDescriptionPrices.find(
                            ({ title }) => title === "prof"
                          ).price || ""
                        }
                        fullWidth
                        onChange={({ target: { value: price } }) => {
                          if (price.match(/^[0-9,]*$/)) {
                            if (price.split(",")[1]) {
                              if (price.split(",")[1].length < 3) {
                                setDoctorDescriptionPrices(
                                  doctorDescriptionPrices.map((item) =>
                                    item.title === "prof"
                                      ? { ...item, ["price"]: price }
                                      : item
                                  )
                                );
                              }
                            } else {
                              setDoctorDescriptionPrices(
                                doctorDescriptionPrices.map((item) =>
                                  item.title === "prof"
                                    ? { ...item, ["price"]: price }
                                    : item
                                )
                              );
                            }
                          }
                        }}
                        inputProps={{
                          "aria-label": "Nazwa przesiewu",
                          maxLength: 255,
                        }}
                        helperText={
                          "Opis badania przez osobę z tytułem profesora"
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Typography className={classes.root}>
                    <Link href="#" onClick={removeHandler}>
                      Usuń cennik
                    </Link>
                  </Typography>
                </Box>
                <hr />
              </Box>
            )}
            {foot}
          </Paper>
        </Grid>
      </Grid>
      {/* 
       
      </Box> */}
    </Modal>
  );
};
export default PriceListModal;
