import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { recoverPassword } from "../../redux/actions";
import { Link, NavLink } from "react-router-dom";
import Loader from "../../components/loader";
import Grid from "@material-ui/core/Grid";
import { Box, Paper, Typography } from "@material-ui/core";
// import { LockOpen } from "@material-ui/icons";
import { ReactComponent as LockOpen } from "../../assets/lock-open.svg";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Alert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";

export const CssTextField = withStyles((theme) => ({
  root: {
    "& label": {
      color: theme.palette.text.hintText,
    },
    "& label.Mui-focused": {
      borderColor: theme.palette.primary.main,
      marginRight: "10px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
  },
  formHint1: {
    color: theme.palette.text.item,
    marginRight: "5px",
    letterSpacing: "0.7px",
  },
  lock: {
    color: theme.palette.lockIcon.primary,
    marginRight: "5px",
  },
  card: {
    minWidth: 250,
    borderRadius: "1rem",
    border: `0.5px solid ${theme.palette.hr.primary}`,
    boxShadow: "none",
  },
  mainGrid: {
    justifyContent: "center",
  },
  hr: {
    opacity: "0.5",
    margin: "2.5em 20px 2.5em 60px",
    border: `0.5px solid ${theme.palette.text.whiteHeader}`,
    [theme.breakpoints.down("lg")]: {
      margin: "1.5em 0 1.5em 0",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  locationButton: {
    marginTop: "3em",
    backgroundColor: theme.palette.background.orderButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `190px`,
    height: "50px",
  },
  paper: {
    border: `0.5px solid ${theme.palette.hr.primary}`,
    padding: "32px",
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  text: {
    color: theme.palette.passwordIcon2.primary,
    fontSize: theme.typography.paragraph,
    letterSpacing: "1.5px",
    fontWeight: "700",
  },
  aMain: {
    color: theme.palette.passwordIcon.primary,
    "&:hover": {
      color: theme.palette.passwordIcon.primary,
      textDecoration: "underline",
    },
    letterSpacing: "1px",
    fontWeight: "600",
  },
}));

const PasswordResetPage = ({
  recoverPassword,
  recoveringPassword,
  history,
}) => {
  const globalTheme = useSelector((s) => s.globalTheme);
  const [userLogin, setUserLogin] = useState("");
  const [invalidUserLogin, setInvalidUserLogin] = useState(false);
  const [preventClick, setPreventClick] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [valid, setValid] = useState(false);
  const [sent, setSent] = useState(false);
  const [sentMessage, setSentMessage] = useState("");
  const input = useRef();

  const classes = useStyles();

  const [phoneDisabled, setPhoneDisabled] = useState(false);
  const [phoneLostFocus, setPhoneLostFocus] = useState(false);

  const [emailDisabled, setEmailDisabled] = useState(false);

  useEffect(() => {
    if (input.current) {
      input.current.focus();
    }
  });

  useEffect(() => {
    const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(userLogin).toLowerCase()
    );

    if (emailValid) {
      setPreventClick(false);
      setInvalidUserLogin(false);
    }
  }, [userLogin]);

  const validateUserLogin = (e) => {
    const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(userLogin).toLowerCase()
    );

    if (emailValid) {
      setPreventClick(false);
      setInvalidUserLogin(false);
    } else {
      setInvalidUserLogin(true);
    }
  };

  const reset = async (e) => {
    e.preventDefault();

    let response = {};

    let isEmailProvided = false;

    const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(userLogin).toLowerCase()
    );

    if (emailValid) {
      isEmailProvided = true;
    }

    setSent(true);
    await recoverPassword({
      ...(isEmailProvided ? { email: userLogin } : {}),
      role: "patient",
    });
    if (isEmailProvided) {
      setSentMessage(`Wiadomość z nowym hasłem została wysłana na
        email ${userLogin}`);
    } else {
      setSentMessage(`Wiadomość z nowym hasłem nie została wysłana `);
    }
  };

  return (
    <Box className={classes.root}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
          <Paper className={classes.paper}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LockOpen style={{ marginRight: "5px", height: "25px" }} />
              <Typography className={classes.text}>Odzyskaj hasło</Typography>
            </Box>
            <Box m={1}>
              <CssTextField
                error={invalidUserLogin}
                helperText={
                  invalidUserLogin
                    ? "Nieprawidłowy format wprowadzonego adresu e-mail"
                    : ""
                }
                InputProps={{
                  inputProps: {
                    style: { textAlign: "center" },
                  },
                }}
                fullWidth
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => setUserLogin(e.target.value)}
                onBlur={validateUserLogin}
                placeholder={"Podaj adres email"}
              />
            </Box>
            <Box
              mt={2}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box mb={1}>
                <Button
                  variant={"contained"}
                  className={classes.locationButton}
                  onClick={reset}
                  disabled={
                    !preventClick && (invalidUserLogin || userLogin === "")
                  }
                >
                  ZRESETUJ HASŁO
                </Button>
              </Box>
              <Box m={1}>
                <Link className={classes.aMain} to="/login">
                  Powrót do logowania
                </Link>
              </Box>
            </Box>
            {sent && !recoveringPassword && (
              <Alert severity="success">
                {sentMessage}
                {/* Wiadomość z nowym hasłem została wysłana na{" "}
                    {phone ? `telefon ${phone}` : `email ${email}`} */}
              </Alert>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  recoveringPassword: state.recoveringPassword,
});

const mapDispatchToProps = (dispatch) => ({
  recoverPassword: (args) => dispatch(recoverPassword(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage);
