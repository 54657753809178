import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { CircularProgress, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import * as moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { addProtege, alertAdd } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ArrowDownIcon } from "../../../assets/arrowDownIcon.svg";
import { ReactComponent as HBLogo } from "../../../assets/hb-tmp.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar.svg";
import { ReactComponent as CalendarIconYellow } from "../../../assets/calendar-yellow.svg";
import StopIcon from "@material-ui/icons/Stop";
import { ReactComponent as ArrowDownIconYellow } from "../../../assets/arrowDownIcon-yellow.svg";
import Alert from "@material-ui/lab/Alert";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modalStyle1: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  paper: {
    width: "80vh",
    borderRadius: "0px",
    boxShadow: "13px 18px 29px -9px rgba(82, 82, 92, 1)",
    [theme.breakpoints.down("md")]: {
      margin: "32px 16px",
    },
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width: 600px)": {
      padding: "20px",
    },
    // "@media (max-width: 600px)": {
    //   height: "100vh",
    // },
    // height: "70vh",
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.text.item,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.item,
    },
  },
  selectText: {
    letterSpacing: "1px",
    lineHeight: `${theme.typography.formLabel}px`,
    fontSize: theme.typography.footer,
    color: theme.palette.text.hintText,
    textTransform: "uppercase",
    borderBottom: `1px solid ${theme.palette.background.menuItemBorderBottom}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  paddingMedium: {
    padding: "12px 64px 64px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  paddingSmall: {
    padding: "12px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
  },
  boxLoginTitle: {
    borderTop: "1px solid #AFAEAE",
    background: theme.palette.background.loginBackground,
  },
  formHint: {
    fontSize: theme.typography.hintText || 12,
    color: theme.palette.text.hintText,
    letterSpacing: "0.7px",
  },
  aMain: {
    color: theme.palette.passwordIcon.primary,
    "&:hover": {
      color: theme.palette.passwordIcon.primary,
      textDecoration: "underline",
    },
    letterSpacing: "1px",
    fontWeight: "600",
  },
}));

const genderTypes = [
  {
    type: "Female",
    description: "Kobieta",
  },
  {
    type: "Male",
    description: "Mężczyzna",
  },
];

const ProtegesModal = ({
  openProtegesModal,
  protegeHandlerType,
  handleClose,
  protegeToEdit = {},
  editProtege,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [modalStyle] = useState(getModalStyle);

  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";

  const dispatch = useDispatch();

  const [busy, setBusy] = useState(false);

  const [firstName, setFirstName] = useState(protegeToEdit?.firstName || "");
  const [surname, setSurname] = useState(protegeToEdit?.surname || "");
  const [birth, setBirth] = useState(
    protegeToEdit?.dateOfBirth?.toString() || ""
  );
  const [gender, setGender] = useState(protegeToEdit?.gender || "");

  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => setIsValid(firstName && surname && birth && gender), [
    firstName,
    surname,
    birth,
    gender,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (errorMessage) {
        setErrorMessage("");
      }
    }, [5000]);
  }, [errorMessage]);

  return (
    <Modal open={openProtegesModal} className={classes.modalStyle1}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} md={8} lg={4} className={classes.grid}>
          <Paper className={classes.paper}>
            <Box>
              <Paper className={classes.paperContainer}>
                <Box
                  display="flex"
                  justifyContent="center"
                  className={classes.paddingLarge}
                >
                  <HBLogo />
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
                >
                  <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                  <Typography className={classes.loginTitle}>
                    {protegeHandlerType === "add"
                      ? "Dodaj osobę"
                      : `Edytuj osobę`}
                  </Typography>
                  <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
                </Box>
                <Box className={classes.paddingSmall}>
                  <Box mt={1}>
                    <TextField
                      label="Imię"
                      required
                      inputProps={{ maxLength: 255 }}
                      value={firstName}
                      fullWidth
                      onChange={({ target: { value: firstName } }) => {
                        setFirstName(firstName);
                      }}
                      inputProps={{ "aria-label": "Imię" }}
                    />
                  </Box>
                  <Box mt={1}>
                    <TextField
                      label="Nazwisko"
                      required
                      inputProps={{ maxLength: 255 }}
                      value={surname}
                      fullWidth
                      onChange={({ target: { value: lastName } }) => {
                        setSurname(lastName);
                      }}
                      inputProps={{ "aria-label": "Nazwisko" }}
                    />
                  </Box>
                  <Box mt={1}>
                    <MuiPickersUtilsProvider
                      locale={plLocale}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disableToolbar
                        id="start-date-picker-dialog"
                        label="Rok urodzenia"
                        required
                        fullWidth
                        format="MM/yyyy"
                        views={["year", "month"]}
                        maxDate={new Date()}
                        helperText={""}
                        value={birth ? birth : null}
                        onChange={(value) => {
                          setBirth(value);
                        }}
                        okLabel="Zatwierdź"
                        clearLabel="Wyczyść"
                        cancelLabel="Anuluj"
                        KeyboardButtonProps={{
                          "aria-label": "Zmień datę urodzenia",
                        }}
                        keyboardIcon={
                          isLightGlobalTheme ? (
                            <CalendarIcon />
                          ) : (
                            <CalendarIconYellow />
                          )
                        }
                        inputProps={{ "aria-label": "Rok urodzenia" }}
                        disable
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                  <Box mt={1}>
                    <FormControl fullWidth>
                      <InputLabel id="select-outlined-label" required>
                        Płeć
                      </InputLabel>
                      <Select
                        className={classes.select}
                        labelId="select-outlined-label"
                        id="select-outlined"
                        value={gender}
                        fullWidth
                        onChange={(event) => setGender(event.target.value)}
                        label="Płeć *"
                        IconComponent={(props) => (
                          <i
                            {...props}
                            className={`material-icons ${props.className}`}
                            style={{ top: "calc(50% - 20px)", right: "15px" }}
                          >
                            {isLightGlobalTheme ? (
                              <ArrowDownIcon />
                            ) : (
                              <ArrowDownIconYellow />
                            )}
                          </i>
                        )}
                      >
                        {genderTypes.map((genderType, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={genderType.type}
                              className={classes.selectText}
                            >
                              {genderType.description}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mt={1}>
                    <span className={classes.formHint}>* Pola wymagane</span>
                  </Box>
                </Box>
                <Box className={classes.paddingMedium}>
                  <Box display="flex" justifyContent={"center"}>
                    <Button
                      type="submit"
                      variant={"contained"}
                      color="primary"
                      style={{
                        borderRadius: "0px",
                        height: "50px",
                        width: "250px",
                      }}
                      onClick={async () => {
                        setBusy(true);
                        if (protegeHandlerType === "add") {
                          const dateOfBirth = new Date(birth);
                          dateOfBirth.setDate("1");
                          try {
                            const response = await dispatch(
                              addProtege({
                                firstName,
                                surname,
                                dateOfBirth: new Date(
                                  dateOfBirth.getTime() +
                                    Math.abs(
                                      dateOfBirth.getTimezoneOffset() * 60000
                                    )
                                )
                                  .toISOString()
                                  .split("T")[0],
                                gender,
                              })
                            );
                            if (response) {
                              handleClose();
                            }
                          } catch (e) {
                            setErrorMessage("Podopieczny nie został dodany");
                          }
                        } else {
                          const dateOfBirth = new Date(birth);
                          dateOfBirth.setDate("1");
                          editProtege(protegeToEdit.id, {
                            firstName,
                            surname,
                            dateOfBirth: new Date(
                              dateOfBirth.getTime() +
                                Math.abs(
                                  dateOfBirth.getTimezoneOffset() * 60000
                                )
                            )
                              .toISOString()
                              .split("T")[0],
                            gender,
                          });
                          handleClose();
                        }

                        setBusy(false);
                      }}
                      disabled={!isValid}
                    >
                      {protegeHandlerType === "add"
                        ? "Dodaj profil"
                        : `Zapisz zmiany`}
                    </Button>
                  </Box>
                  <Box mt={2} display="flex" justifyContent={"center"}>
                    <a className={classes.aMain} onClick={() => handleClose()}>
                      Anuluj
                    </a>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default ProtegesModal;
