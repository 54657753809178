import React from "react";
import {
  Box,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  useTheme,
} from "@material-ui/core";
import ThresholdAudiometryResults from "./threshold-audiometry-results";
import { Grid } from "@material-ui/core";
import { Audiogram } from "./audiogram";
import { useStyles } from "./results-per-package-table";
import { CombinedResult } from "./results-per-package-table";
import DateAndResultGrid from "./DateAndResultGrid";

function resultToText(result) {
  switch (result) {
    case "OK":
      return "PRAWIDŁOWY";
    case "NOK":
      return "NIEPRAWIDŁOWY";
    case "none":
      return "NIEPRAWIDŁOWY";
    case "FP":
      return "fałszywe kliknięcia Pacjenta";
    case "FANC":
      return "wystąpiły zakłócenia";
    case "":
      return "";
    case null:
      return "";
    default:
      var toDisplay = "";

      if (!isNaN(result)) {
        toDisplay = "PRAWIDŁOWY";
      }

      return toDisplay;
  }
}

function clicksCount(audiometryDetailsList, forKids) {
  var allClicksCountSum = 0;
  var correctClicksCount = 0;
  var falseClicksCountSum = 0;

  audiometryDetailsList.forEach(
    ({ soundsClicksTimes, falseClicksCount, allClicksCount }) => {
      if (!forKids) {
        //w audiometrii przesiewowej dla dorosłych
        soundsClicksTimes.forEach(({ clicks }) => {
          clicks.forEach(({ isRight }) => {
            allClicksCountSum++;
            isRight === true && correctClicksCount++;
            isRight === false && falseClicksCountSum++;
          });
        });
      } else {
        //w audiometrii przesiewowej dla dzieci
        if (falseClicksCount !== undefined) {
          falseClicksCountSum += falseClicksCount;
        }
        if (allClicksCount !== undefined) {
          allClicksCountSum += allClicksCount;
        }
      }
    }
  );
  const rate =
    allClicksCountSum !== 0 ? falseClicksCountSum / allClicksCountSum : 0;

  return { allClicksCountSum, correctClicksCount, falseClicksCountSum, rate };
}

const ClicksInfo = ({ audiometryDetailsList, forKids }) => {
  const classes = useStyles();
  const {
    allClicksCountSum,
    correctClicksCount,
    falseClicksCountSum,
    rate,
  } = clicksCount(audiometryDetailsList, forKids);
  const fontsize = useTheme().typography.footerMobile;

  return (
    <Box style={{ display: "inline-block" }}>
      <Typography align="left" className={classes.tableHeaderPerExamination}>
        Dodatkowe informacje
      </Typography>
      <Typography
        align="left"
        className={classes.grey}
        style={{ fontSize: fontsize }}
      >{`Liczba wszystkich kliknięć: ${allClicksCountSum}`}</Typography>
      <Typography
        align="left"
        className={classes.grey}
        style={{ fontSize: fontsize }}
      >{`Liczba fałszywych kliknięć: ${falseClicksCountSum}`}</Typography>
      {!forKids && (
        <Typography
          align="left"
          className={classes.grey}
          style={{ fontSize: fontsize }}
        >{`Liczba prawidłowych kliknięć: ${correctClicksCount}`}</Typography>
      )}
      <Typography
        align="left"
        className={classes.grey}
        style={{ fontSize: fontsize }}
      >{`Odsetek fałszywych kliknięć [%]: ${
        isNaN(rate) ? 0 : (rate * 100).toFixed()
      }`}</Typography>
    </Box>
  );
};

const ResultsPerEar = ({ ear, audiometry }) => {
  const classes = useStyles();
  const fontsize = useTheme().typography.footerMobile;

  return (
    <Box className={`${classes.boxLoginTitle} ${classes.paddingMedium}`}>
      <Typography
        align="center"
        style={{
          color: (theme) => theme.palette.text.alternative2,
        }}
      >{`UCHO ${ear === "right" ? "PRAWE" : "LEWE"}`}</Typography>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="flex-start"
        style={{ mb: 2 }}
      >
        <Grid item>
          <TableContainer style={{ display: "inline-block" }}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography className={classes.tableHeaderPerExamination}>
                      Częstotliwość [Hz]
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.tableHeaderPerExamination}>
                      Wynik
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[
                  ...(ear === "right"
                    ? audiometry.frequenciesResultRight
                    : audiometry.frequenciesResultLeft),
                ]
                  .sort((a, b) => (a.frequency > b.frequency ? 1 : -1))
                  .map(({ frequency, answer }) => (
                    <TableRow key={frequency}>
                      <TableCell className={classes.grey}>
                        <Typography style={{ fontSize: fontsize }}>
                          {frequency}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.grey}>
                        <Typography style={{ fontSize: fontsize }}>
                          {resultToText(answer)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item>
          <ClicksInfo
            audiometryDetailsList={
              ear === "right"
                ? audiometry.audiometryDetailsListRight
                : audiometry.audiometryDetailsListLeft
            }
            forKids={audiometry.forKids}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const AudiometryResults = ({ test, audiometry }) => {
  const classes = useStyles();
  if (!test.data) {
    return null;
  }
  return test.data.testEnum === "AP" ? (
    <Box>
      <DateAndResultGrid startDate={test.startDate} endDate={test.endDate}>
        <CombinedResult
          text="Wynik ucha prawego:"
          result={test.data.resultRight}
          capd={false}
        />
        <CombinedResult
          text="Wynik ucha lewego:"
          result={test.data.resultLeft}
          capd={false}
        />
        {test.data.earlyFinishedEnum && (
          <Typography component="span" align="center" className={classes.grey}>
            Badanie niedokończone
          </Typography>
        )}
        {test.failureReason === "FANC" && (
          <Typography component="span" align="center" className={classes.grey}>
            Podczas badania wystąpiły zakłócenia
          </Typography>
        )}
      </DateAndResultGrid>
      <br />
      <Grid
        container
        // spacing={2}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Audiogram audiometry={test.data} />
        </Grid>
        <Grid
          item
          container
          justify="space-evenly"
          //  spacing={2}
          direction="row"
        >
          <Grid item>
            <ResultsPerEar ear="right" audiometry={test.data} />
          </Grid>
          <Grid item>
            <ResultsPerEar ear="left" audiometry={test.data} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <ThresholdAudiometryResults
      audiometry={test}
      basicAudiometry={audiometry}
    />
  );
};

export default AudiometryResults;
