import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import Can from "../../components/can";
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import VouchersActivated from "../vouchers-activated";
import TestsPurchased from "../tests-purchased";
import { fetchAssignedScreenings, fetchProteges } from "../../redux/actions";
import Loader from "../../components/loader";
import StopIcon from "@material-ui/icons/Stop";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "16px",
  },
  paper: {
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  boxLoginTitle: {
    background: theme.palette.background.loginBackground,
    borderTop: "1px solid #AFAEAE",
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingMedium: {
    padding: "16px 32px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  table: {
    minWidth: 1250,
  },
  tableHeader: {
    color: theme.palette.text.alternative2,
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    borderBottom: "none",
    letterSpacing: "0.7px",
  },
  tableBodyCell: {
    color: theme.palette.text.hintText,
    borderBottom: `0.5px solid ${theme.palette.lockIcon.primary}`,
    fontFamily: "Montserrat",
  },
}));
export const CenteredTabs = withStyles({
  flexContainer: {
    textAlign: "center",
    display: "inherit",
  },
})(Tabs);

const ActiveTestsPage = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const page = () => (
    <Box className={classes.root}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} md={10}>
          <Paper className={classes.paper}>
            <Box
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
              className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
            >
              <StopIcon style={{ height: "8px", color: "#3458A4" }} />
              <Typography className={classes.loginTitle}>
                Kupione badania
              </Typography>
              <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
            </Box>
            <Box className={`${classes.paddingMedium}`}>
              <TestsPurchased />
            </Box>
            <Box
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
              className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
            >
              <StopIcon style={{ height: "8px", color: "#3458A4" }} />
              <Typography className={classes.loginTitle}>
                Przypisane badania
              </Typography>
              <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
            </Box>
            <Box className={`${classes.paddingMedium}`}>
              <VouchersActivated />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  const redirect = () => <Redirect to="/" />;

  return <Can permission="active-tests:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveTestsPage);
