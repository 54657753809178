import React from "react";
import {
  Button,
  ButtonGroup,
  makeStyles,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { format } from "date-fns";
import QRCode from "qrcode";
import { getAxiosInstance } from "../../redux/common";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import { EXAMINATIONS_MAP } from "../packages/examinations";

const useStyles = makeStyles((theme) => ({
  spacing: {
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
    boxShadow: "none",
  },
}));

const ScreeningTestRow = ({
  screeningTestData,
  organization,
  setOpenModal,
  setScreeningTestToEdit,
  setScreeningTestsHandlerType,
  loadScreeningTests,
  testStands,
  packages,
  setOpenScreeningTestRemovePanel,
  setScreeningTestToRemove,
  setOpenPriceListPanel,
  missingPricelist,
  forms,
}) => {
  const {
    id,
    label,
    // location,
    // organization,
    screeningType,
    startDate,
    endDate,
    quantity,
    completedScreenings,
    screeningId,
    voucherId,
    hearBoxStations,
    examinationPackages,
  } = screeningTestData;
  const classes = useStyles();

  const testTypeMap = {
    Free: "Darmowy",
    Commercial: "Komercyjny",
  };

  const editionHandler = () => {
    setScreeningTestsHandlerType("edition");
    setScreeningTestToEdit(screeningTestData);
    setOpenModal(true);
  };

  const qrLinkGenerate = async () => {
    try {
      const response = await getAxiosInstance().post("/api/voucher", {
        tenantId: organization.tenantId,
        screeningId,
      });
      if (response) {
        loadScreeningTests();
      }
    } catch (e) {
      if (e.response.data.message.includes("already-exists")) {
        window.location.reload();
      }
    }
  };

  const qrLinkDownload = async () => {
    try {
      if (voucherId) {
        const domain =
          window.location.hostname === "localhost"
            ? `${window.location.hostname}:${window.location.port}`
            : window.location.hostname;
        const link = `https://${domain}/voucher/${voucherId}`;
        const res = await QRCode.toDataURL(link);
        const downloadLink = document.createElement("a");
        downloadLink.href = res;
        downloadLink.download = "qr link";
        downloadLink.click();
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const missingPrice = examinationPackages.filter((hearBoxTestId) =>
    missingPricelist.some(
      ({ hearBoxTestId: hearBoxTestIdPriceList }) =>
        hearBoxTestId === hearBoxTestIdPriceList
    )
  );

  return (
    <TableRow>
      <TableCell>{label || "---"}</TableCell>
      {/* <TableCell>{location || "---"}</TableCell> */}
      <TableCell>{organization?.orgName || "---"}</TableCell>
      <TableCell>
        {testStands
          .filter((t) => hearBoxStations.includes(t.hearBoxId))
          .map((testStand) => (
            <p
              style={{
                borderBottom:
                  testStands.filter((t) =>
                    hearBoxStations.includes(t.hearBoxId)
                  ).length > 1 && "0.5px solid #d9d9d9",
              }}
            >
              {testStand.label}
            </p>
          )) || "---"}
      </TableCell>
      <TableCell>
        {packages
          .filter((p) => examinationPackages.includes(p.hearBoxTestId))
          .map((packg) => (
            <p
              style={{
                borderBottom:
                  packages.filter((p) =>
                    examinationPackages.includes(p.hearBoxTestId)
                  ).length > 1 && "0.5px solid #d9d9d9",
              }}
            >
              {packg.label}
            </p>
          )) || "---"}
      </TableCell>
      <TableCell>{testTypeMap[screeningType] || "---"}</TableCell>
      <TableCell>{startDate || "---"}</TableCell>
      <TableCell>{endDate || "---"}</TableCell>
      <TableCell>{quantity || "<bez limitu>"}</TableCell>
      <TableCell>{completedScreenings}</TableCell>
      <TableCell>
        <ButtonGroup
          className={classes.spacing}
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          {missingPrice.length > 0 && screeningType === "Commercial" && (
            <Tooltip title="Należy uzupełnić braki w cenniku">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <PriorityHighIcon style={{ fill: "red" }} />
              </div>
            </Tooltip>
          )}
          <Button
            variant={"contained"}
            color="primary"
            onClick={editionHandler}
          >
            Konfiguruj
          </Button>
          {/* {screeningType === "Commercial" && (
            <Button
              variant={"contained"}
              color="primary"
              onClick={() => {
                setScreeningTestToEdit(screeningTestData);
                setOpenPriceListPanel(true);
              }}
            >
              Cennik
            </Button>
          )} */}
          {screeningType === "Free" && (
            <Button
              variant={"contained"}
              color="primary"
              disabled={new Date(screeningTestData.startDate) < new Date()}
              onClick={() => {
                setScreeningTestToRemove(screeningTestData);
                setOpenScreeningTestRemovePanel(true);
              }}
            >
              Usuń
            </Button>
          )}
          {screeningType === "Free" && (
            <Tooltip
              title={
                voucherId
                  ? `Kod: ${voucherId}`
                  : "Naciśnij przycisk, aby wygenerować kod"
              }
            >
              <Button
                variant="contained"
                color="primary"
                onClick={voucherId ? qrLinkDownload : qrLinkGenerate}
              >
                <span style={{ whiteSpace: "nowrap" }}>
                  {voucherId ? `Pobierz QR LINK` : `Generuj QR Link`}
                </span>
              </Button>
            </Tooltip>
          )}
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
};

export default ScreeningTestRow;
