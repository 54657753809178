import React, { useEffect, useState } from "react";
import { DictionaryProperties } from "../property-creators/dictionary-properties";
import { LabelProperties } from "../property-creators/label-properties";
import s from "./index.module.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { NumberProperties } from "../property-creators/number-properties";
import { TextProperties } from "../property-creators/text-properties";
import { FileProperties } from "../property-creators/file-properties";

// model:
// const sampleModel = {
//   size: 10,
//   text: 'laksdjflksdjf',
//   alignment: 'left',
//   fontSize: '20px',
//   bold: true,
//   italic: false,
//   label: 'some label',
//   labelPosition: 'left',
//   required: true,
//   border: true,
//   header: 'some header',
// }

export const defaultState = {
  size: null,
  text: "",
  alignment: "left",
  fontSize: "20",
  bold: false,
  italic: false,
  label: "",
  labelPosition: "left",
  required: false,
  noteEnabled: false,
  border: false,
  header: "",
  showInTableView: false,
};

const getDataTypeText = (dataType) => {
  switch (dataType) {
    case "TEXT":
      return "pole tekstowe";
    case "TEXTAREA":
      return "pole tekstowe";
    case "NUMBER":
      return "pole liczbowe";
    case "FILE":
      return "plik";
    case "SIGN":
      return "podpis";
    case "LOCATION":
      return "lokalizacja";
    case "DICTIONARY":
      return "słownik";
    case "TIME":
      return "czas";
    case "DATE":
      return "data";
    case "DATETIME":
      return "data i czas";
    case "GROUP":
      return "grupa";
    case "REPEATING_GROUP":
      return "grupa";
    case "BOOL":
      return "prawda/fałsz";
    case "PESEL":
      return "pesel";
    case "QRCODE":
      return "kod QR";
    case "LABEL":
      return "etykieta";
  }
};

const ControlProperties = ({
  displayMode,
  controlId,
  dataType,
  configuration: {
    size = null,
    label = "",
    text = "",
    required = false,
    noteEnabled = false,
    numberType = "",
    decimalDigitNumber = "",
    fieldRules,
    allowMultipleAnswers = false,
    allowAlternativeAnswer = false,
    saveDictionaryGlobally = false,
    maxValue = "",
    minValue = "",
    showInTableView = false,
    dictionary,
    maxRows = "1",
    maxNumberOfFiles = null,
    fileType = "Any",
    addFirstDictValueDisplay = true,
    ...rest
  },
  update,
  formEdited,
  addMode,
}) => {
  const disabled = ""; //configuration ? "" : "disabled"

  // const [label, setLabel] = useState('');

  // useEffect(() => {
  //   setLabel(initialLabel);
  // }, [initialLabel])

  // const updateConfig = config => {
  //   setState({
  //     ...state,
  //     ...config
  //   })
  // if (update) {
  //   update({
  //     ...configuration,
  //     ...config,
  //   })
  //
  // }

  const [state, setState] = useState({
    ...defaultState,
    maxRows,
    maxNumberOfFiles,
    fileType,
    text,
    size,
    label,
    required,
    noteEnabled,
    numberType,
    decimalDigitNumber,
    fieldRules,
    maxValue,
    minValue,
    showInTableView,
  });

  const [dataTypeText, setDataTypeText] = useState("");

  // const [extraValues, setExtraValues] = useState(state)
  //
  // const setExtraValues2 = values => {
  //   // setExtraValues(values)
  //
  //   setState({
  //     ...state,
  //     ...values
  //   })
  // }

  useEffect(() => {
    setState((state) => ({
      ...state,
      label,
      size,
      text,
      required,
      noteEnabled,
      numberType,
      decimalDigitNumber,
      fieldRules,
      allowMultipleAnswers,
      allowAlternativeAnswer,
      saveDictionaryGlobally,
      maxValue,
      minValue,
      showInTableView,
      dictionary,
      maxRows,
      maxNumberOfFiles,
      fileType,
      addFirstDictValueDisplay,
      ...rest,
    }));
    // }, [label, text, dictionary]);
  }, [
    label,
    size,
    text,
    required,
    noteEnabled,
    numberType,
    decimalDigitNumber,
    fieldRules,
    allowMultipleAnswers,
    allowAlternativeAnswer,
    saveDictionaryGlobally,
    maxValue,
    minValue,
    dictionary,
    maxRows,
    maxNumberOfFiles,
    fileType,
    addFirstDictValueDisplay,
    showInTableView,
  ]);

  useEffect(() => {
    update(state);
  }, [state]);

  // useEffect(() => {
  //   if (controlId) {
  //     update(state)
  //   }
  // }, [state, controlId])

  useEffect(() => {
    setDataTypeText(getDataTypeText(dataType));
  }, [dataType]);

  return (
    <div className={s.scroll}>
      <p className="menu-label">Właściwości</p>

      {
        <ul>
          {/*<li>*/}
          {/*  Maksymalna długość:*/}
          {/*  <input*/}
          {/*    disabled={disabled} type="number"*/}
          {/*    className="input is-small"*/}
          {/*    value={state.size}*/}
          {/*    onChange={({target: {value: size}}) => setState(*/}
          {/*      {...state, size})}/>*/}
          {/*</li>*/}

          {/*<li>*/}
          {/*  Położenie:*/}
          {/*  <input*/}
          {/*    disabled={disabled} type="text"*/}
          {/*    className="input is-small"*/}
          {/*    value={state.alignment}*/}
          {/*    onChange={({target: {value: alignment}}) => setState({...state, alignment})}/>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  Rozmiar czcionki:*/}
          {/*  <input disabled={disabled} type="number"*/}
          {/*         className="input is-small"*/}
          {/*         value={state.fontSize}*/}
          {/*         onChange={({target: {value: fontSize}}) => setState({...state, fontSize})}/>*/}
          {/*</li>*/}
          <li>
            Typ: {dataTypeText}
            {typeof state.dictionary !== "undefined" &&
              state.dictionary !== null &&
              ` typu tekstowego`}
          </li>
          <li>
            Etykieta:
            <input
              disabled={disabled || displayMode}
              type="text"
              className="input is-small"
              value={state.label || ""}
              onChange={({ target: { value: label } }) => setState({ label })}
            />
          </li>
          <li>
            Podpowiedź:
            <input
              disabled={disabled || displayMode}
              type="text"
              className="input is-small"
              value={state.text || ""}
              onChange={({ target: { value: text } }) =>
                setState({ ...state, text })
              }
            />
          </li>
          <li style={{ display: "flex" }}>
            <FormControlLabel
              disabled={displayMode}
              control={
                <Checkbox
                  checked={state.required || false}
                  onChange={({ target: { checked: required } }) =>
                    setState({ ...state, required })
                  }
                  name="checkedB"
                  color="primary"
                />
              }
              label="Pole wymagane"
            />
          </li>
          <li>
            <FormControlLabel
              disabled={displayMode}
              control={
                <Checkbox
                  checked={state.noteEnabled || false}
                  onChange={({ target: { checked: noteEnabled } }) =>
                    setState({ ...state, noteEnabled })
                  }
                  name="checkedB"
                  color="primary"
                />
              }
              label="Notatka przy polu"
            />
          </li>
          {/* <li>
            <FormControlLabel
              disabled={displayMode}
              control={
                <Checkbox
                  checked={state.showInTableView || false}
                  onChange={({ target: { checked: showInTableView } }) =>
                    setState({ ...state, showInTableView })
                  }
                  name="checkedB"
                  color="primary"
                />
              }
              label="Wartość na liście wyników ogólnych"
            />
          </li> */}

          {dataType === "DICTIONARY" && (
            <DictionaryProperties
              displayMode={displayMode}
              values={state}
              setValues={setState}
              formEdited={formEdited}
              addMode={addMode}
            />
          )}
          {dataType === "LABEL" && (
            <LabelProperties
              displayMode={displayMode}
              values={state}
              setValues={setState}
            />
          )}
          {dataType === "NUMBER" && (
            <NumberProperties
              displayMode={displayMode}
              values={state}
              setValues={setState}
              formEdited={formEdited}
            />
          )}

          {dataType === "TEXT" && (
            <TextProperties
              displayMode={displayMode}
              values={state}
              setValues={setState}
              formEdited={formEdited}
            />
          )}

          {dataType === "FILE" && (
            <FileProperties
              displayMode={displayMode}
              values={state}
              setValues={setState}
              formEdited={formEdited}
            />
          )}
          {/*{propertyCreators && propertyCreators({values: state, setValues: setState})}*/}

          {/* <li>
              Nagłówek:
              <input disabled={disabled} type="text"
                     className="input is-small"/>
            </li>
            <li>
              Położenie etykiety:
              <input disabled={disabled} type="text"
                     className="input is-small"/>
            </li>

            <li>
              <label className="checkbox">
                Wymagane <input disabled={disabled} type="checkbox"/>
              </label>
            </li>
            <li>
              <label className="checkbox">
                Ramka <input disabled={disabled} type="checkbox"/>
              </label>
            </li>
            <li>
              <label className="checkbox">
                Kursywa <input disabled={disabled} type="checkbox"/>
              </label>
            </li>
            <li>
              <label className="checkbox">
                Pogrubienie
                <input
                  disabled={disabled}
                  type="checkbox"
                  value={state.bold}
                  onChange={({target: {checked: bold}}) => setState({...state, bold})}/>
              </label>
            </li> */}
        </ul>
      }
    </div>
  );
};

export default ControlProperties;
