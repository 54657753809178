import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import {
  CircularProgress,
  Button,
  FormControlLabel,
  Checkbox,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import * as moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getAxiosInstance } from "../../../redux/common";
import ClearIcon from "@material-ui/icons/Clear";
import EventIcon from "@material-ui/icons/Event";
import { alertAdd, register_organization } from "../../../redux/actions";
import { Alert } from "@material-ui/lab";
import { Visibility } from "@material-ui/icons";
import s from "./index.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { useDispatch } from "react-redux";

const OrangeButton = styled(Button)`
  background-color: orange;
  color: white;
`;

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modalStyle1: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  paper: {
    width: "80vh",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.alternative,
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width: 600px)": {
      padding: "20px",
    },
    // "@media (max-width: 600px)": {
    //   height: "100vh",
    // },
    // height: "70vh",
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.text.item,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.item,
    },
  },
}));

const OrganizationModal = ({
  openOrganizationModal,
  organizationHandlerType,
  handleClose,
  orgToEdit = {},
  loadOrganizations,
  setRegisterCompleted,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [editionFormValid, setEditionFormValid] = useState(true);
  const [agreementName, setAgreementName] = useState(
    orgToEdit.agreement?.name || ""
  );
  const [agreementSignedAt, setAgreementSignedAt] = useState(
    orgToEdit.agreement?.signedAt || ""
  );
  const [contactPersonEmail, setContactPersonEmail] = useState(
    orgToEdit.contactPerson?.email || ""
  );
  const [contactPersonFirstName, setContactPersonFirstName] = useState(
    orgToEdit.contactPerson?.firstName || ""
  );
  const [contactPersonSurname, setContactPersonSurname] = useState(
    orgToEdit.contactPerson?.surname || ""
  );
  const [canViewStatistics, setCanViewStatistics] = useState(
    orgToEdit.canViewStatistics || false
  );
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (contactPersonEmail) {
      setEditionFormValid(testEmailPattern(contactPersonEmail));
    } else {
      setEditionFormValid(true);
    }
  }, [contactPersonEmail]);

  const handlerSave = async () => {
    try {
      const response = await getAxiosInstance().put(
        `/api/organizations/${orgToEdit.id}`,
        {
          agreement: {
            name: agreementName,
            signedAt: agreementSignedAt,
          },
          contactPerson: {
            firstName: contactPersonFirstName,
            surname: contactPersonSurname,
            email: contactPersonEmail,
          },
          canViewStatistics,
        }
      );
      if (response) {
        loadOrganizations();
        handleClose();
      }
    } catch (e) {}
    handleClose();
  };

  // ORG CREATE //
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [formIsValid, setFormIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordMissingElements, setPasswordMissingElements] = useState([]);
  const [typingTimer, setTypingTimer] = useState(null);

  const [isRegisterInProgress, setIsRegisterInProgress] = useState(false);

  const [organizationCreated, setOrganizationCreated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [invalidPasswordInputChar, setInvalidPasswordInputChar] = useState(
    false
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setFormIsValid(
      name &&
        testEmailPattern(mail) &&
        password &&
        password2 &&
        passwordsMatch &&
        recaptchaValid &&
        passwordScore > 4
    );
  }, [
    name,
    mail,
    passwordsMatch,
    password,
    password2,
    recaptchaValid,
    passwordScore,
  ]);

  useEffect(() => {
    setPasswordsMatch(password === password2);
  }, [password2, password]);

  const testEmailPattern = (mail) => {
    let polishChars1 = String(mail)
      .toLowerCase()
      .normalize("NFD")
      .search(/[\u0300-\u036f]/g);
    let polishChars2 = String(mail)
      .toLowerCase()
      .normalize("NFD")
      .search(/\u0142/g);

    return (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(mail).toLowerCase()
      ) &&
      (polishChars2 !== 0
        ? polishChars1 + polishChars2 < 0
        : polishChars1 + polishChars2 < -1)
    );
  };

  const onChange = (value) => {
    setRecaptchaValid(!!value);
  };

  const passwordMissingElementFilter = (
    tmpPasswordMissingElements,
    element
  ) => {
    return tmpPasswordMissingElements.filter(
      (missingElement) => missingElement !== element
    );
  };

  const testStrengthPassword = (e) => {
    let pass = e.target.value;
    let tmpPasswordScore = 0;
    let tmpPasswordMissingElements = [
      "Przynajmniej jedną małą literę",
      "Przynajmniej jedną wielką literę",
      "Przynajmniej jeden znak specjalny: ! @ # $ % & * _ + = ^",
      "Przynajmniej jedeną cyfrę",
      "Długość znaków między 8, a 32.",
    ];
    if (pass.length > 7 && pass.length < 33) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Długość znaków między 8, a 32."
      );
    }
    if (/[a-z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną małą literę"
      );
    }
    if (/[A-Z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną wielką literę"
      );
    }
    if (/[0-9]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedeną cyfrę"
      );
    }
    if (/[!@#$%^&*+=_]+/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jeden znak specjalny: ! @ # $ % & * _ + = ^"
      );
    }
    clearTimeout(typingTimer);
    if (tmpPasswordScore === 0) {
      setPasswordScore(tmpPasswordScore);
    }

    setTypingTimer(
      setTimeout(() => {
        if (tmpPasswordScore) {
          setPasswordScore(tmpPasswordScore);
          setPasswordMissingElements(tmpPasswordMissingElements);
        }
      }, 1000)
    );
  };

  const submit = async (e) => {
    e.preventDefault();

    if (formIsValid) {
      try {
        // const domain = window.location.hostname;
        const domain = "hearbox.it-learn.pl";
        const ws = new WebSocket(
          `wss://${domain}/tenants/v1/ws/registration?email=${mail.toLowerCase()}`
        );
        ws.onopen = async function (e) {
          setIsRegisterInProgress(true);
          const response = await dispatch(
            register_organization({
              name,
              mail: mail.toLowerCase(),
              password,
            })
          );

          if (response.status === 200) {
            setErrorMessage("");
            setOrganizationCreated(true);
            setName("");
            setMail("");
            setPassword("");
            setPassword2("");
            setRecaptchaValid(false);
            setPasswordScore(0);
            setPasswordMissingElements([]);
            setTypingTimer(null);
            loadOrganizations();
            setRegisterCompleted(true);
            handleClose();
            setTimeout(() => {
              setOrganizationCreated(false);
            }, 3000);
          } else {
            const message = response.data.message;

            if (message.indexOf("email-not-unique") !== -1) {
              setErrorMessage("Email jest już używany w systemie");
            } else if (message === "organization-name-not-unique") {
              setErrorMessage("Nazwa organizacji nie jest unikalna");
            }

            setIsRegisterInProgress(false);
          }
        };
        ws.onmessage = function (e) {
          setIsRegisterInProgress(
            e.data.includes("TenantCreatedWsPayload") && false
          );
          setRegisterCompleted(e.data.includes("TenantCreatedWsPayload"));
        };
      } catch (e) {
        let errorMessage1;
        if (e.response.status === 409) {
          if (e.response.data.message.includes("Invalid email address")) {
            setErrorMessage("Niepoprawny format adresu email");
          }
          if (e.response.data.message === "organization-name-not-unique") {
            setErrorMessage("Nazwa organizacji nie jest unikalna");
          } else {
            setErrorMessage("Email jest już używany w systemie");
          }
        } else {
          setErrorMessage(e.message);
        }

        alertAdd({
          text: errorMessage1,
          isError: true,
        });
      }
    }
  };

  const title = (
    <Box mt={1} style={{ display: "flex" }}>
      <Typography variant="h6" gutterBottom>
        {organizationHandlerType === "register"
          ? "Zarejestruj Organizację"
          : `Edytuj Organizację: ${orgToEdit.orgName}`}
      </Typography>
    </Box>
  );
  const body = (
    <Box>
      <Box mt={2}>
        <Box mt={1}>
          <TextField
            label="Numer umowy"
            variant="outlined"
            value={agreementName}
            fullWidth
            onChange={({ target: { value: agreementName } }) => {
              setAgreementName(agreementName);
            }}
            inputProps={{
              "aria-label": "Numer umowy",
              maxLength: 255,
            }}
          />
        </Box>
        <Box mt={1}>
          <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              fullWidth
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              value={agreementSignedAt ? agreementSignedAt : null}
              inputVariant="outlined"
              label="Data podpisania umowy"
              onChange={(date) => {
                setAgreementSignedAt(date);
              }}
              keyboardIcon={<EventIcon />}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setAgreementSignedAt(null);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              InputAdornmentProps={{
                position: "start",
              }}
              inputProps={{ readOnly: true }}
              invalidDateMessage=""
              minDateMessage="Data wykracza poza zakres"
              maxDateMessage="Data wykracza poza zakres"
              cancelLabel="Anuluj"
              okLabel="Zatwierdź"
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box mt={1}>
          <TextField
            label="Imię osoby do kontaktu"
            variant="outlined"
            value={contactPersonFirstName}
            fullWidth
            onChange={({ target: { value: firstName } }) => {
              setContactPersonFirstName(firstName);
            }}
            inputProps={{
              "aria-label": "Imię osoby do kontaktu",
              maxLength: 255,
            }}
          />
        </Box>
        <Box mt={1}>
          <TextField
            label="Nazwisko osoby do kontaktu"
            variant="outlined"
            value={contactPersonSurname}
            fullWidth
            onChange={({ target: { value: surname } }) => {
              setContactPersonSurname(surname);
            }}
            inputProps={{
              "aria-label": "Nazwisko osoby do kontaktu",
              maxLength: 255,
            }}
          />
        </Box>
        <Box mt={1}>
          <TextField
            label="Email osoby do kontaktu"
            variant="outlined"
            value={contactPersonEmail}
            fullWidth
            onChange={({ target: { value: email } }) => {
              setContactPersonEmail(email);
            }}
            inputProps={{
              "aria-label": "Nazwisko osoby do kontaktu",
              maxLength: 255,
            }}
            error={!editionFormValid}
            helperText={!editionFormValid && "Niepoprawny adres email"}
          />
        </Box>
        <Box mt={1} style={{ display: "flex", justifyContent: "left" }}>
          <FormControlLabel
            style={{ marginLeft: "5px" }}
            control={
              <Checkbox
                checked={canViewStatistics}
                onChange={(e) => setCanViewStatistics(e.target.checked)}
                name="canViewStatistics"
                color="primary"
              />
            }
            labelPlacement="start"
            label="Dostęp do statystyk"
          />
        </Box>
      </Box>
    </Box>
  );

  const bodyRegister = (
    <Box>
      {organizationCreated && <Alert>Organizacja została założona</Alert>}
      {errorMessage && (
        <Alert severity="error">Błąd w rejestracji ({errorMessage})</Alert>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box p={1}>
            <Box mt={1}>
              <TextField
                label="Imię i nazwisko"
                variant="outlined"
                value={name}
                required
                fullWidth
                onChange={(e) => setName(e.target.value)}
              />
            </Box>

            <Box mt={1}>
              <TextField
                error={!(mail === "" || testEmailPattern(mail))}
                helperText={
                  !(mail === "" || testEmailPattern(mail)) &&
                  "Nieprawidłowy adres email"
                }
                label={"Email właściciela"}
                variant="outlined"
                required
                value={mail}
                fullWidth
                onChange={(e) => setMail(e.target.value)}
              />
            </Box>
            <Box mt={1} display="flex">
              <TextField
                label="Hasło"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                value={password}
                error={invalidPasswordInputChar}
                helperText={
                  invalidPasswordInputChar ? "Spacja nie jest dozwolona." : ""
                }
                required
                onChange={(e) => {
                  if (e.target.value.includes(" ")) {
                    setInvalidPasswordInputChar(true);
                    setTimeout(() => setInvalidPasswordInputChar(false), 3000);
                  } else {
                    setInvalidPasswordInputChar(false);
                  }
                  setPassword(e.target.value.split(" ").join(""));
                  testStrengthPassword(e);
                }}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        aria-label="toggle password visibility"
                      >
                        <Visibility />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                error={password2 && !passwordsMatch}
                helperText={
                  password2 && !passwordsMatch
                    ? "Powtórzone hasło nie jest zgodne"
                    : ""
                }
                label="Powtórz hasło"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                value={password2}
                required
                onChange={(e) =>
                  setPassword2(e.target.value.split(" ").join(""))
                }
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        aria-label="toggle password visibility"
                      >
                        <Visibility />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box mt={1} style={{ textAlign: "left" }}>
              <span className={s.formHint}>* Pola wymagane</span>
            </Box>
            <Box mt={1}>
              <span className={s.formHint}>
                Aby spełnić zalożenie polityki Silnego Hasła prosi się o podanie
                conajmniej jednej wielkiej litery, małej litery, cyfry oraz
                znaku specjanlego. Hasło powinno być dłuższe niż 7 znaków.
              </span>
            </Box>
            {passwordScore > 0 && (
              <div>
                <label className={s.labelStrengthPassword}>Siła hasła:</label>
                <Box mt={1} display="flex">
                  <span
                    className={s.strengthPassword}
                    dataScore={passwordScore}
                  />
                </Box>
                {passwordMissingElements.length > 0 && (
                  <label
                    className={s.labelStrengthPassword}
                    style={{ marginTop: "10px" }}
                  >
                    Aby hasło było silne, należy zawrzeć:
                  </label>
                )}
                <span className={s.formHint}>
                  {passwordMissingElements.map((el, index) => {
                    return <li key={index}>{el}</li>;
                  })}
                </span>
              </div>
            )}

            <Box mt={1}>
              <ReCAPTCHA
                style={{ transform: "scale(0.77)", transformOrigin: "0 0" }}
                sitekey="6LcDZv0pAAAAANRDhkLcoavTotAsRukQzKLZd06k"
                onChange={onChange}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const foot = (
    <Grid>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button variant={"contained"} onClick={() => handleClose()}>
          Anuluj
        </Button>
        &nbsp;&nbsp;&nbsp;{" "}
        <Button
          variant={"contained"}
          color="primary"
          onClick={handlerSave}
          disabled={!editionFormValid}
        >
          {busy && <CircularProgress size={14} />}
          Zapisz
        </Button>
      </Box>
    </Grid>
  );

  const footRegister = (
    <Grid>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button variant={"contained"} onClick={() => handleClose()}>
          Anuluj
        </Button>
        &nbsp;&nbsp;&nbsp;{" "}
        <OrangeButton
          type="submit"
          variant={"contained"}
          disabled={!formIsValid}
          onClick={submit}
        >
          Zarejestruj organizację
        </OrangeButton>
      </Box>
    </Grid>
  );

  return (
    <Modal open={openOrganizationModal} className={classes.modalStyle1}>
      <Grid container>
        <Grid item xs={12} className={classes.grid}>
          <Paper className={classes.paper}>
            {" "}
            {title}
            {organizationHandlerType === "register" ? bodyRegister : body}
            <hr
              style={{
                margin: "24px 0px",
              }}
            />
            {organizationHandlerType === "register" ? footRegister : foot}
          </Paper>
        </Grid>
      </Grid>
      {/* 
       
      </Box> */}
    </Modal>
  );
};
export default OrganizationModal;
