import { getAxiosInstance } from "../common";
import {
  DONE,
  FAILED,
  FETCH_CART_ITEMS,
  REMOVE_CART_ITEM_DONE,
  REMOVE_CART_ITEM_FAILED,
  REMOVE_CART_ITEM_START,
  START,
  BUY_CART_ITEM,
  FETCH_PRICE_LIST,
} from "../../constants";

export const fetchCartItems = (payload) => async (dispatch) => {
  dispatch(fetchCartItemsStart());
  try {
    const reponse = await getAxiosInstance().get(`/api/cart`);

    dispatch(fetchCartItemsDone(reponse.data));
    return true;
  } catch (e) {
    dispatch(fetchCartItemsFailed(e));
    return false;
  }
};

export const fetchCartItemsStart = (payload) => ({
  type: FETCH_CART_ITEMS + START,
  payload,
});
export const fetchCartItemsDone = (payload) => ({
  type: FETCH_CART_ITEMS + DONE,
  payload,
});
export const fetchCartItemsFailed = (payload) => ({
  type: FETCH_CART_ITEMS + FAILED,
  payload,
});

export const buyCartItem = (payload) => async (dispatch) => {
  dispatch({ type: BUY_CART_ITEM + START, payload });
  const { deliveryInfo, billingInfo, provideFVData } = payload;

  try {
    let response = null;
    if (!provideFVData) {
      response = await getAxiosInstance().post(`/api/cart/buy`, {
        invoiceNeeded: false,
        // billingInfo: {
        //   name: deliveryInfo.name,
        //   street: deliveryInfo.street,
        //   zipCode: deliveryInfo.zip,
        //   city: deliveryInfo.city,
        //   // post: deliveryInfo.post,
        //   notes: deliveryInfo.notes,
        //   phone: deliveryInfo.phone,
        // },
        deliveryInfo: {
          ...deliveryInfo,
        },
      });
    } else {
      response = await getAxiosInstance().post(`/api/cart/buy`, {
        invoiceNeeded: true,
        billingInfo: {
          name: billingInfo.nameFV,
          street: billingInfo.streetFV,
          zipCode: billingInfo.zipFV,
          city: billingInfo.cityFV,
          // post: billingInfo.postFV,
          taxId: billingInfo.taxID,
          // notes: "test",
          // notes: billingInfo.zipFV,
        },
        deliveryInfo: {
          ...deliveryInfo,
        },
      });
    }

    dispatch({ type: BUY_CART_ITEM + DONE, payload });
    return response.data;
  } catch (e) {
    dispatch({ type: BUY_CART_ITEM + FAILED, e });
    return false;
  }
};

export const removeCartItem = (payload) => async (dispatch) => {
  dispatch(removeCartItemStart());
  try {
    await getAxiosInstance().delete(`/api/cart/${payload}`);

    dispatch(removeCartItemDone(payload));
    return true;
  } catch (e) {
    dispatch(removeCartItemFailed(e));
    return false;
  }
};

export const removeCartItemStart = (payload) => ({
  type: REMOVE_CART_ITEM_START,
  payload,
});
export const removeCartItemDone = (payload) => ({
  type: REMOVE_CART_ITEM_DONE,
  payload,
});
export const removeCartItemFailed = (payload) => ({
  type: REMOVE_CART_ITEM_FAILED,
  payload,
});

export const fetchPriceList = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_PRICE_LIST + START });
  try {
    const result = await getAxiosInstance().get(`/api/product_price`);

    dispatch({ type: FETCH_PRICE_LIST + DONE, payload: result.data });
  } catch (e) {
    dispatch({ type: FETCH_PRICE_LIST + FAILED });
  }
};
