import React, { useEffect, useState } from "react";
import Can from "../../components/can";
import { useDispatch, useSelector } from "react-redux";
import {
  alertAdd,
  deactivateForm,
  fetchOldFormEditions,
  removeFormFromFormGroup,
} from "../../redux/actions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PersonIcon from "@material-ui/icons/Person";
import { NavLink } from "react-router-dom";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FormToGroup from "../form-to-project";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import FormPermissionsPanel from "../../components/form-permissions";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";

import VisibilityIcon from "@material-ui/icons/Visibility";
const NO_ID_TO_REMOVE = -1;

const statusToText = (status) => {
  switch (status) {
    // case 'ZERO': return 'ZERO';
    // case 'TODO': return 'TODO';
    case "PAUSED":
      return "Wstrzymany";
    case "DRAFT":
      return "Szkic";
    case "SENT":
      return "Wysłany";
    case "ACTIVE":
      return "Aktywny";
    case "ARCHIVE":
      return "Zarchiwizowany";
    case "DELETED":
      return "Usunięty";
  }
};

const statusToTip = (status) => {
  switch (status) {
    // case 'ZERO':
    //   return 'nieuzywany w widoku';
    // case 'TODO':
    //   return 'nieuzywany w widoku';
    case "PAUSED":
      return "Aktywny formularz został zatrzymany i obecnie nie jest dostępny do wypełnienia";
    case "DRAFT":
      return "Formularz jest w trakcie tworzenia lub edycji";
    // case 'SENT':
    //   return 'nieuzywany w widoku'
    case "ACTIVE":
      return "Formularz jest dostępny do wypełnienia dla wskazanych osób";
    case " PREVIOUS_VERSION":
      return "Poprzednia wersja formularza";
    // case 'ARCHIVE':
    //   return 'nieuzywany w widoku'
    // case 'DELETED':
    //   return 'nieuzywany w widoku'
  }
};

const useStyles = makeStyles((theme) => ({
  nonCurrentRow: {
    backgroundColor: "#e4e4e4",
  },
  tableCell: {
    fontSize: theme.typography.body1.fontSize,
    border: theme.palette.primary.main === "#fff200" && "1px solid #fff200",
  },
  icon: {
    color: theme.palette.text.primary,
  },
  iconSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.iconSize,
  },
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  grey: {
    color: "grey",
  },
}));

const SingleVersionRow = ({
  scheduledForm,
  form,
  setShowOtherVersions,
  showOtherVersions,
  setOpenPermissionsPanel,
  openPermissionsPanel,
  fill,
  isActivated,
  dispatch,
  activateForm,
  duplicateForm,
  edit,
  display,
  schedule,
  askToRemove,
  remove,
  isCurrent = false,
}) => {
  const classes = useStyles();

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const globalTheme = useSelector((s) => s.globalTheme);
  const [openModalPanel, setOpenModalPanel] = useState(false);
  const [openRemovingPanel, setOpenRemovingPanel] = useState(false);
  const [currentOptionValue, setCurrentOptionValue] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenuItem = (option) => {
    if (option.key !== undefined && option.key !== "remove")
      setOpenModalPanel(true);
    else if (option.key !== undefined && option.key === "remove")
      setOpenRemovingPanel(true);
    // dispatch(removeFormFromFormGroup(form.id, form.formGroup.id));

    setCurrentOptionValue(option.key);
    setAnchorEl(null);
  };

  const doActivateForm = async (form) => {
    try {
      await activateForm(form);
    } catch (e) {
      if (
        e.response.status === 400 &&
        e.response.data.message === "template-is-empty"
      ) {
        dispatch(
          alertAdd({
            text: "Błąd aktywacji formularza - formularz jest pusty",
            isError: true,
          })
        );
      }
    }
  };

  const options = [
    {
      key: "copy",
      value: "Skopiuj do projektu",
    },
    {
      key: "moveTo",
      value: "Przenieś do projektu",
    },
    {
      key: "remove",
      value: "Usuń z projektu",
    },
  ];

  const numberToText = (number) => {
    var text;
    switch (number) {
      case 1:
        text = "osoba jest przypisana";
        break;
      case 2:
      case 3:
      case 4:
        text = "osoby są przypisane";
        break;
      default:
        text = "osób jest przypisanych";
        break;
    }
    return `${number} ${text}`;
  };

  return (
    <TableRow className={isCurrent ? undefined : classes.nonCurrentRow}>
      {form ? (
        <TableCell component="th" scope="row" className={classes.tableCell}>
          <ConfirmDialogMUI
            handleClose={() => {
              setOpenRemovingPanel(false);
            }}
            open={openRemovingPanel}
            text={
              "Czy na pewno usunąć formularz " +
              form?.name +
              " z projektu " +
              form?.formGroup?.name +
              "?"
            }
            yesAction={() => {
              dispatch(removeFormFromFormGroup(form.id, form.formGroup.id));
            }}
            noAction={() => {}}
          />
          {form.name}
          {isCurrent && form.edition > 1 && (
            <Tooltip title="Dostępnych jest kilka wersji formularza. Kliknij aby pokazać dostępne opcje.">
              <Link
                onClick={(e) => {
                  setShowOtherVersions(!showOtherVersions);
                }}
              >
                {" "}
                (v.{form.edition})
              </Link>
            </Tooltip>
          )}
          {!isCurrent && <span> (v.{form.edition})</span>}
        </TableCell>
      ) : (
        <TableCell component="th" scope="row" className={classes.tableCell}>
          ---
        </TableCell>
      )}

      {/* <TableCell align="right" className={classes.tableCell}>
        {form.formGroup && form.formGroup.name}
      </TableCell> */}

      <TableCell align="right" className={classes.tableCell}>
        {/* <NavLink
          className={
            globalTheme === "high-contrast"
              ? classes.aalertHighContrast
              : undefined
          }
          to={`/formdata/all/${form.id}`}
        >
          {form.filledFormCount}
        </NavLink> */}
        {<span>{form.filledFormCount}</span>}
      </TableCell>
      <TableCell align="right" className={classes.tableCell}>
        <Tooltip
          placement="top"
          title={
            form.sealed
              ? "Nie możesz aktualnie duplikować ankiety, ponieważ jest przypisana do przesiewu."
              : "Duplikuj ankietę"
          }
        >
          <span>
            <IconButton
              size="small"
              onClick={() => duplicateForm(form.id)}
              disabled={form.sealed}
            >
              <FileCopyIcon
                className={`${
                  globalTheme === "high-contrast" && classes.icon
                } ${classes.iconSize}`}
              />
            </IconButton>
          </span>
        </Tooltip>
        <>
          <Tooltip
            placement="top"
            title={
              form.sealed
                ? "Nie możesz aktualnie edytować ankiety, ponieważ jest przypisana do przesiewu."
                : "Edytuj ankietę"
            }
          >
            <span>
              <IconButton
                onClick={() => edit(form.id)}
                size="small"
                disabled={form.sealed}
              >
                <EditIcon
                  className={`${
                    globalTheme === "high-contrast" && classes.icon
                  } ${classes.iconSize}`}
                />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip placement="top" title={"Podgląd ankiety"}>
            <span>
              <IconButton onClick={() => display(form.id)} size="small">
                <VisibilityIcon
                  className={`${
                    globalTheme === "high-contrast" && classes.icon
                  } ${classes.iconSize}`}
                />
              </IconButton>
            </span>
          </Tooltip>
          {/* <Tooltip
            placement="top"
            title="Zarządzanie uprawnieniami użytkowników mogących wypełnić ten ankietę"
          >
            <IconButton
              size="small"
              onClick={() => {
                setOpenPermissionsPanel(true);
              }}
            >
              <PersonIcon
                className={`${
                  globalTheme === "high-contrast" && classes.icon
                } ${classes.iconSize}`}
              />
            </IconButton>
          </Tooltip> */}
          <Can
            permission="form:add-to-project"
            ok={() => (
              <>
                <Tooltip
                  placement="top"
                  title={
                    !(form?.status === "ACTIVE")
                      ? "Zarządzanie przynależnością formularza do projektu"
                      : "Wstrzymaj formularz, aby zarządzać przynależnością do projektu"
                  }
                >
                  <span>
                    <IconButton
                      disabled={form?.status === "ACTIVE"}
                      size="small"
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleClose}
                    >
                      <MoreVertIcon
                        className={`${
                          globalTheme === "high-contrast" && classes.icon
                        } ${classes.iconSize}`}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClickMenuItem}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "30ch",
                    },
                  }}
                >
                  {options.map((option) => (
                    <MenuItem
                      key={option.key}
                      onClick={() => handleClickMenuItem(option)}
                      disabled={option.key === "remove" && !form.formGroup}
                    >
                      <ListItemIcon>
                        {option.key === "copy" && (
                          <FileCopyIcon
                            fontSize="small"
                            className={`${
                              globalTheme === "high-contrast" && classes.icon
                            } ${classes.iconSize}`}
                          />
                        )}
                        {option.key === "moveTo" && (
                          <FlipToFrontIcon
                            fontSize="small"
                            className={`${
                              globalTheme === "high-contrast" && classes.icon
                            } ${classes.iconSize}`}
                          />
                        )}
                        {option.key === "remove" && (
                          <DeleteIcon
                            fontSize="small"
                            className={`${
                              globalTheme === "high-contrast" && classes.icon
                            } ${classes.iconSize}`}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={option.value} />
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          />
          <>
            <span
              style={{
                color: "#7c7d7c",
                borderLeft: "1.5px solid #000",
                height: "20px",
              }}
            ></span>
            &nbsp;&nbsp;&nbsp;
            <Tooltip
              placement="top"
              title={
                form.sealed
                  ? "Nie możesz aktualnie usunąć ankiety - jest przypisana do przesiewu"
                  : "Usuń ankietę"
              }
            >
              <span>
                <IconButton
                  onClick={() => {
                    askToRemove(form.id);
                  }}
                  size="small"
                  disabled={form.sealed}
                >
                  <DeleteIcon
                    className={`${
                      globalTheme === "high-contrast" && classes.icon
                    } ${classes.iconSize}`}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </>
        </>
      </TableCell>
      <FormToGroup
        form={form}
        currentFormProject={form.formGroup}
        option={currentOptionValue}
        open={openModalPanel}
        setOpen={setOpenModalPanel}
      />
    </TableRow>
  );
};

const SingleForm = ({
  scheduledForm,
  form,
  fill,
  remove,
  edit,
  display,
  schedule,
  filledForms,
  updateForm,
  activateForm,
  history,
  duplicateForm,
}) => {
  const [openPermissionsPanel, setOpenPermissionsPanel] = useState(false);
  const [idToRemove, setIdToRemove] = useState(NO_ID_TO_REMOVE);
  const [showOtherVersions, setShowOtherVersions] = useState(false);
  const [otherVersions, setOtherVersions] = useState(null);
  const dispatch = useDispatch();
  const oldVersions = useSelector((s) =>
    s.orgOldFromEditions.filter(
      (e) => form && form.bag && e.bag.id === form.bag.id && e.id !== form.id
    )
  );
  const [openFormRemovingPanel, setOpenFormRemovingPanel] = useState(false);

  useEffect(() => {
    idToRemove !== NO_ID_TO_REMOVE && setOpenFormRemovingPanel(true);
  }, [idToRemove]);

  useEffect(() => {
    if (showOtherVersions && otherVersions === null) {
      dispatch(fetchOldFormEditions(form.id));
    }
  }, [showOtherVersions, otherVersions, form]);

  const askToRemove = (formId) => {
    setIdToRemove(formId);
  };

  const isActivated = form ? form.status === "ACTIVE" : false;

  return (
    <>
      <ConfirmDialogMUI
        handleClose={() => {
          setOpenFormRemovingPanel(false);
          setIdToRemove(NO_ID_TO_REMOVE);
        }}
        open={openFormRemovingPanel}
        text={"Czy na pewno usunąć formularz " + form.name + "?"}
        yesAction={() => {
          remove(idToRemove);
          setIdToRemove(NO_ID_TO_REMOVE);
        }}
        noAction={() => setIdToRemove(NO_ID_TO_REMOVE)}
      />

      <SingleVersionRow
        activateForm={activateForm}
        askToRemove={askToRemove}
        dispatch={dispatch}
        duplicateForm={duplicateForm}
        edit={edit}
        display={display}
        schedule={schedule}
        fill={fill}
        form={form}
        scheduledForm={scheduledForm}
        isActivated={form.sealed}
        remove={remove}
        setShowOtherVersions={setShowOtherVersions}
        // setShowPermissions={setShowPermissions}
        setOpenPermissionsPanel={setOpenPermissionsPanel}
        showOtherVersions={showOtherVersions}
        openPermissionsPanel={openPermissionsPanel}
        // showPermissions={showPermissions}
        isCurrent={true}
      />

      {showOtherVersions &&
        oldVersions &&
        oldVersions.length > 0 &&
        oldVersions.map((f, index) => (
          <SingleVersionRow
            key={index}
            activateForm={activateForm}
            askToRemove={askToRemove}
            dispatch={dispatch}
            duplicateForm={duplicateForm}
            edit={edit}
            display={display}
            schedule={schedule}
            fill={fill}
            scheduledForm={scheduledForm}
            form={f}
            isActivated={form.sealed}
            setShowOtherVersions={() => {}}
            setOpenPermissionsPanel={() => {}}
            showOtherVersions={false}
            openPermissionsPanel={() => {}}
          />
        ))}

      {openPermissionsPanel && (
        <FormPermissionsPanel
          form={form}
          hide={() => setOpenPermissionsPanel(false)}
          open={openPermissionsPanel}
        />
      )}
    </>
  );
};

export default SingleForm;
