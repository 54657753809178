import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchProteges,
  addProtege,
  assignPerson,
  editProtege,
  removeProtege,
  assignPurchasedTest,
} from "../../redux/actions";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import Loader from "../../components/loader";
import { saveQRCode, alertAdd } from "../../redux/actions";
import ProtegesModal from "../../pages/proteges/proteges-handler-modal";
import { ReactComponent as ArrowDownIcon } from "../../assets/arrowDownIcon.svg";
import { ReactComponent as ArrowDownIconYellow } from "../../assets/arrowDownIcon-yellow.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `0.5px solid ${theme.palette.text.borderTopLine}`,
    padding: "16px",
  },
  mainGrid: {
    justifyContent: "center",
  },
  hr: {
    opacity: "0.5",
    margin: "2.5em 20px 2.5em 60px",
    border: `0.5px solid ${theme.palette.text.whiteHeader}`,
    [theme.breakpoints.down("lg")]: {
      margin: "1.5em 0 1.5em 0",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  editButton: {
    backgroundColor: theme.palette.background.orderButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `${theme.typography.assignButtonWidth}px`,
    height: "45px",
  },
  addButton: {
    backgroundColor: theme.palette.testIcon.primary,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `${theme.typography.assignButtonWidth}px`,
    height: "45px",
  },
  paper: {
    border: `0.5px solid ${theme.palette.hr.primary}`,
    padding: "32px 48px",
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
    [theme.breakpoints.down("xs")]: {
      padding: "32px 16px",
    },
  },
  text: {
    color: theme.palette.text.alternative2,
    fontSize: theme.typography.text,
    letterSpacing: "1.5px",
    fontWeight: "700",
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.testIcon.primary,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "45px",
      top: "-10px",
      color: theme.palette.testIcon.primary,
    },
  },
  selectText: {
    letterSpacing: "1px",
    lineHeight: `${theme.typography.formLabel}px`,
    fontSize: theme.typography.footer,
    color: theme.palette.text.hintText,
    textTransform: "uppercase",
    borderBottom: `1px solid ${theme.palette.background.menuItemBorderBottom}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
}));

const TestAssignPage = ({ match, editProtege, addProtege, assignPerson }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const orderId = match.params.orderId;
  const orderItemId = match.params.orderItemId;

  const classes = useStyles();

  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";
  const my = useSelector((s) => s.my);
  const [codeVerification, setCodeVerifycation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [patientID, setPatientID] = useState("");
  const [meToAssign, setMeToAssign] = useState(false);

  const [protegeToEdit, setProtegeToEdit] = useState({});

  const [openProtegesModal, setOpenProtegesModal] = useState(false);
  const [protegeHandlerType, setProtegeHandlerType] = useState("");

  const proteges = useSelector((s) => s.proteges);
  const protegeAdded = useSelector((s) => s.protegeAdded);
  const protegeEdited = useSelector((s) => s.protegeEdited);

  useEffect(() => {
    dispatch(fetchProteges({ page: 0, pageSize: 1000 }));
  }, [protegeAdded, protegeEdited]);

  useEffect(() => {
    if (patientID) {
      setProtegeToEdit(proteges.find((p) => p.id === patientID));
    }
  }, [proteges]);

  const handleProtegeChange = (event) => {
    const target = event.target;
    const value = target.value;

    if (value === my.id) {
      setMeToAssign(true);
      setPatientID(value);
    } else if (value) {
      setMeToAssign(false);
      setPatientID(value);
      setProtegeToEdit(proteges.find((p) => p.id === value));
    }
  };

  const assignHandler = async () => {
    let r = null;
    setCodeVerifycation(true);
    try {
      if (meToAssign) {
        r = await dispatch(assignPurchasedTest({ orderId, orderItemId }));
      } else {
        r = await dispatch(
          assignPurchasedTest({
            orderId,
            orderItemId,
            subaccountId: patientID,
          })
        );
      }
      if (r.status == 200) {
        try {
          const domain =
            window.location.hostname === "localhost"
              ? `${window.location.hostname}:${window.location.port}`
              : window.location.hostname;

          const secure = window.location.hostname === "localhost" ? `` : "s";
          let ws = new WebSocket(
            `ws${secure}://${domain}/patient/v1/websocket/connect`
          );
          ws.onmessage = function (e) {
            if (
              e.data.includes("ScreeningAddedToPatientPatientWebSocketMessage")
            ) {
              if (!errorMessage) {
                dispatch(
                  alertAdd({
                    text: "Pomyślnie przypisano pacjenta do badania.",
                    customAlert: true,
                    isSuccess: true,
                    timeout: 7000,
                  })
                );
                history.push({
                  pathname: `/active-test/${orderId}`,
                });
              }
              ws.close();
            }
          };
          ws.onerror = function (e) {};
          ws.onclose = function (e) {};
        } catch (e) {
          console.log(e, "e");
        }
      }
    } catch (e) {
      if (e.response.data.code == 404) {
        if (e.response.data.message === "Not found : voucher") {
          setErrorMessage("Nieprawidłowy kod vouchera");
        }
        if (e.response.data.message === "Not found : screening") {
          setErrorMessage("Przesiew o podanym kodzie vouchera nie istnieje");
        }
      } else if (e.response.data.code == 400) {
        if (e.response.data.message === "screening-not-active") {
          setErrorMessage(
            "Przesiew o podanym kodzie vouchera nie jest aktywny"
          );
        }
        if (e.response.data.message === "screening-has-no-capacity") {
          setErrorMessage("Limit badań w przesiewie został osiągnięty");
        }
        if (e.response.data.message === "examination-packages-empty") {
          setErrorMessage(
            "Przesiew o podanym kodzie vouchera nie posiada badań"
          );
        }
      } else if (e.response.data.code == 409) {
        if (e.response.data.message === "voucher-already-used") {
          setErrorMessage(
            "Nie można zostać przypisanym więcej, niż jeden raz do przesiewu"
          );
        }
      } else {
        setErrorMessage("Wystąpił problem z przypisaniem do badania");
      }
      setCodeVerifycation(false);
    }
  };

  return codeVerification ? (
    <Box className={classes.root}>
      <Loader
        loading={true}
        text="Trwa przypisanie do badania - proszę czekać."
      />
    </Box>
  ) : (
    <Box className={classes.root}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} sm={10} md={6} lg={5} xl={3}>
          <Paper className={classes.paper}>
            <Typography className={classes.text}>
              Przypisz badanie do osoby
            </Typography>
            <Box mt={5} mb={5}>
              <FormControl fullWidth error={errorMessage}>
                <InputLabel id="sub-account-select-label">
                  * wybierz osobę do badania
                </InputLabel>
                <Select
                  labelId="sub-account-select-label"
                  name="sub-account"
                  required
                  value={patientID}
                  onChange={handleProtegeChange}
                  label="Osoba do badania *"
                  className={classes.select}
                  IconComponent={(props) => (
                    <i
                      {...props}
                      className={`material-icons ${props.className}`}
                      style={{ top: "calc(50% - 20px)" }}
                    >
                      {isLightGlobalTheme ? (
                        <ArrowDownIcon />
                      ) : (
                        <ArrowDownIconYellow />
                      )}
                    </i>
                  )}
                >
                  <MenuItem
                    key={0}
                    value={my.id}
                    className={classes.selectText}
                  >
                    {`${my.firstName} ${my.surname}`}
                  </MenuItem>
                  {proteges?.length > 0 && (
                    <MenuItem
                      disabled
                      className={classes.selectText}
                      style={{ opacity: "0.5" }}
                    >
                      DLA INNEJ OSOBY
                    </MenuItem>
                  )}
                  {proteges?.map((protege, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={protege.id}
                        className={classes.selectText}
                      >
                        {`${protege.firstName} ${protege.surname}`}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errorMessage}</FormHelperText>
              </FormControl>
            </Box>
            <Box
              mt={5}
              mb={5}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <Button
                variant="contained"
                className={classes.addButton}
                onClick={() => {
                  setProtegeHandlerType("add");
                  setOpenProtegesModal(true);
                }}
              >
                Dodaj nowy profil
              </Button>{" "}
              <Button
                variant="contained"
                size="small"
                className={classes.editButton}
                disabled={!patientID || patientID < 0 || meToAssign}
                onClick={() => {
                  setProtegeHandlerType("edit");
                  setOpenProtegesModal(true);
                }}
              >
                Edytuj profil
              </Button>
            </Box>
            {openProtegesModal && (
              <ProtegesModal
                openProtegesModal={openProtegesModal}
                protegeHandlerType={protegeHandlerType}
                handleClose={() => {
                  setOpenProtegesModal(false);
                  // setProtegeRemoving(true);
                  // setTimeout(() => {
                  //   setProtegeRemoving(false);
                  // }, 3000);
                }}
                protegeToEdit={
                  protegeHandlerType === "add" ? {} : protegeToEdit
                }
                addProtege={addProtege}
                editProtege={editProtege}
              />
            )}
            <Box mt={5}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                disabled={patientID === ""}
                onClick={assignHandler}
              >
                Przypisz osobę
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addProtege: (payload) => dispatch(addProtege(payload)),
  assignPurchasedTest: (payload) => dispatch(assignPurchasedTest(payload)),
  editProtege: (protegeId, payload) =>
    dispatch(editProtege(protegeId, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestAssignPage);
