import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  makeStyles,
  withStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ReactComponent as PersonImg } from "../../assets/person1.svg";
import { ReactComponent as PersonImg2 } from "../../assets/person2.svg";
import { ReactComponent as Play } from "../../assets/play.svg";
import { ReactComponent as Ear } from "../../assets/ear.svg";
import { ReactComponent as Exp } from "../../assets/expierience.svg";
import { ReactComponent as Heart } from "../../assets/heart.svg";
import { ReactComponent as Dollar } from "../../assets/dollar.svg";
import { ReactComponent as Person } from "../../assets/person.svg";
import { ReactComponent as Persons } from "../../assets/footer-persons.svg";
import { ReactComponent as Cloud } from "../../assets/footer-cloud.svg";
import { ReactComponent as Camera } from "../../assets/footer-camera.svg";
import hbLogo1 from "../../assets/login-img.svg";
import euImg from "../../assets/ue.svg";
import feImg from "../../assets/fe.svg";
import ncbrImg from "../../assets/ncbr.svg";
import rpImg from "../../assets/rp.svg";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { ReactComponent as HBIcon } from "../../assets/hb-tmp.svg";
import s from "./index.module.css";
import { useSelector } from "react-redux";

export const StepTypography = withStyles((theme) => ({
  root: {
    display: "inline",
    borderRadius: "23px",
    padding: "5px 10px",
    color: theme.palette.text.default,
    background: theme.palette.background.step,
    letterSpacing: "2px",
    fontSize: theme.typography.footerMobile,
    fontWeight: "600",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(6),
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(8),
    },
    [theme.breakpoints.up("xl")]: {
      marginRight: theme.spacing(10),
    },
  },
}))(Typography);

export const StepDescription = withStyles((theme) => ({
  root: {
    color: theme.palette.background.filterBorder,
    fontSize: theme.typography.TEXT3,
  },
}))(Typography);

export const StepDescriptionVoucher = withStyles((theme) => ({
  root: {
    color: theme.palette.text.hintText,
    fontSize: theme.typography.TEXT3,
  },
}))(Typography);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  mainGrid: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    padding: "36px 12px",
    [theme.breakpoints.down("xs")]: {
      padding: "24px 16px",
    },
    flexDirection: "column",
    alignItems: "center",
    flexWrap: "nowrap",
    margin: "0px",
  },
  mainInstructionGrid: {
    backgroundColor: theme.palette.background.secondary,
  },
  mainOpinionGrid: {
    backgroundColor: theme.palette.background.opinionGrid,
  },
  mainFreeTestGrid: {
    backgroundColor: theme.palette.background.secondary,
  },
  mainMovieGrid: {
    backgroundColor: theme.palette.background.movie,
  },

  footerGrid: {
    backgroundColor: theme.palette.background.movie,
    padding: "12px 12px",
  },
  locationButton: {
    backgroundColor: theme.palette.locationIcon.primary,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `162px`,
    height: "45px",
  },
  testButton: {
    backgroundColor: theme.palette.testIcon.primary,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `${theme.typography.buttonWidth}px`,
    height: "45px",
  },
  mainTitle: {
    color: theme.palette.background.movie2,
    fontSize: theme.typography.mainTitle,
    fontWeight: "700",
    textAlign: "center",
    lineHeight: `${theme.typography.mainTitle}px`,
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.typography.mainTitleMobile,
      lineHeight: `${theme.typography.mainTitleMobile}px`,
      wordBreak: "break-word",
    },
  },
  mainTitleWhite: {
    color: theme.palette.text.whiteHeader,
  },
  mainTitleHearZone: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.mainTitle,
    fontWeight: "700",
    textAlign: "center",
    lineHeight: `${theme.typography.mainTitle}px`,
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.typography.mainTitleMobile,
      lineHeight: `${theme.typography.mainTitleMobile}px`,
      wordBreak: "break-word",
    },
  },
  mainTitleMovie: {
    color: theme.palette.text.whiteHeader,
    fontSize: theme.typography.mainTitle,
    fontWeight: "700",
    textAlign: "center",
    lineHeight: `${theme.typography.mainTitle}px`,
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.typography.mainTitleMobile,
      lineHeight: `${theme.typography.mainTitleMobile}px`,
      wordBreak: "break-word",
    },
  },
  secondTitle: {
    color: theme.palette.text.primary3,
    fontSize: theme.typography.header,
    fontWeight: "300",
    textAlign: "center",
    lineHeight: `${theme.typography.header}px`,
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      fontWeight: "600",
      fontFamily: "Raleway",
    },
  },
  euTitle: {
    color: theme.palette.text.movie,
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    textAlign: "center",
    lineHeight: `${theme.typography.formLabel}px`,
  },
  opinion: {
    color: theme.palette.text.whiteHeader,
    fontSize: theme.typography.mainTitle,
    fontWeight: "600",
    textAlign: "center",
    lineHeight: `${theme.typography.mainTitle2}px`,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "12px",
      fontSize: theme.typography.mainTitleMobile,
      lineHeight: `${theme.typography.mainTitleMobile}px`,
    },
  },
  paragraph: {
    color: theme.palette.text.item,
    fontSize: theme.typography.text2,
    fontWeight: "300",
    textAlign: "center",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      fontFamily: "Raleway",
      fontWeight: "400",
      fontSize: theme.typography.paragraph,
    },
  },
  line: {
    backgroundColor: theme.palette.testIcon.primary,
  },
  opinionText: {
    color: theme.palette.background.badgeBackground,
    textTransform: "uppercase",
    fontFamily: "Raleway",
    fontSize: theme.typography.text2,
    // lineHeight: `${theme.typography.text2}px`,
    fontWeight: "300",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.typography.paragraph,
    },
  },
  movieParagraph: {
    textAlign: "center",
    color: theme.palette.text.default2,
    fontFamily: "Raleway",
    fontSize: theme.typography.header,
    lineHeight: `${theme.typography.header}px`,
    fontWeight: "400",
    opacity: "0.7",
  },
  hearZoneParagraph: {
    textAlign: "center",
    color: theme.palette.text.primary2,
    fontFamily: "Raleway",
    fontSize: theme.typography.header,
    lineHeight: `${theme.typography.header}px`,
    fontWeight: "600",
  },
  footerParagraph: {
    color: theme.palette.text.default2,
    fontFamily: "Raleway",
    fontSize: theme.typography.formLabel,
    lineHeight: `${theme.typography.formLabel}px`,
    fontWeight: "500",
    opacity: "0.4",
  },
  freeTestText: {
    color: theme.palette.text.item,
    fontFamily: "Raleway",
    fontSize: theme.typography.text2,
    // lineHeight: `${theme.typography.redirectCardTitle}px`,
    fontWeight: "400",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      fontSize: theme.typography.paragraph,
    },
  },
  opinionAuthor: {
    color: theme.palette.text.whiteHeader,
    fontSize: theme.typography.text2,
    // lineHeight: `${theme.typography.text2}px`,
    fontWeight: "300",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.typography.paragraph,
    },
  },
  opinionPosition: {
    color: theme.palette.text.whiteHeader,
    fontFamily: "Raleway",
    fontSize: theme.typography.fontSize,
    // lineHeight: `${theme.typography.fontSize}px`,
    fontWeight: "300",
    opacity: "0.7",
    marginTop: "5px",
  },
  navIcon: {
    color: theme.palette.text.whiteHeader,
    opacity: "0.5",
  },
  navButton: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  centerText: {
    textAlign: "center",
  },
  hearZoneTitle: {
    fontSize: theme.typography.footer,
    fontWeight: "700",
    letterSpacing: "2px",
  },
  hearZoneText: {
    fontSize: theme.typography.formLabel,
    fontFamily: "Raleway",
    fontWeight: "700",
    color: theme.palette.text.primary2,
    lineHeight: `${theme.typography.formLabel}px`,
  },
  ulList: {
    listStyleType: "none",
    // paddingLeft: "5px",
  },
  listItem: {
    marginTop: "3px",
    // marginLeft: "5px",
  },
  stepContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "left",
    textAlign: "center",
    padding: "5px 10px",
    marginTop: "5px",
  },
}));

const opinions = [
  {
    text: "Przesiewowe badania słuchu. Ich celem jest: ",
    subtext: [
      {
        text: "ocena Twojego słuchu",
      },
      {
        text:
          " wyznaczenie progów słyszenia, czyli najcichszych, słyszalnych przez Ciebie dźwięków dla różnych częstotliwości",
      },
      {
        text:
          "sprawdzenie rozumienia mowy, które służy do oceny zdolności komunikowania się w codziennym życiu",
      },
    ],
  },
  {
    text:
      "Badania przesiewowe oceniające procesy przetwarzania słuchowego. Ich celem jest:",
    subtext: [
      {
        text:
          "sprawdzenie Twoich umiejętności słuchowych, czyli tego w jaki sposób przetwarzasz usłyszaną informację dźwiękową",
      },
    ],
  },
];

const hearZoneItems = [
  {
    icon: () => <Exp />,
    title: "EXPERIENCE",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing.",
  },
  {
    icon: () => <Heart />,
    title: "OUR FUTURE",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing.",
  },
  {
    icon: () => <Dollar />,
    title: "ACADEMIC TALENTS",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing.",
  },
  {
    icon: () => <Person />,
    title: "BRILLIANT MINDS",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing.",
  },
];

const HearZoneElement = ({ item: { icon, title, text } }) => {
  const classes = useStyles();

  return (
    <Grid item xs={6} md={3} container spacing={1}>
      <Grid item xs={12} className={classes.centerText}>
        {icon()}
      </Grid>
      <Grid item xs={12} className={`${classes.centerText} `}>
        <Typography className={classes.hearZoneTitle}>{title}</Typography>
      </Grid>
      <Grid item xs={12} className={`${classes.centerText}`}>
        <Typography className={classes.hearZoneText}>{text}</Typography>
      </Grid>
    </Grid>
  );
};
const Opinion = ({ opinion: { text, subtext } }) => {
  const classes = useStyles();

  return (
    <Box className={s.article}>
      <Box>
        <Typography
          className={classes.opinionText}
          style={{ margin: "10px 0px" }}
        >
          {text}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.opinionAuthor}>
          <ul
            className={classes.ulList}
            style={{ listStyle: "disc", marginLeft: "1em" }}
          >
            {subtext.map(({ text }, id) => (
              <li key={id} className={classes.listItem}>
                {text}
              </li>
            ))}
          </ul>
        </Typography>
      </Box>
      <br />
    </Box>
  );
};

const StartPageLower = ({}) => {
  const classes = useStyles();

  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const ssmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isNormalFontSize = useSelector((s) => s.fontSize) === "normal";

  const [firstIndexMobile, setFirstIndexMobile] = useState(0);
  const backIndexHandler = () => {
    if (firstIndexMobile === 0) {
      setFirstIndexMobile(opinions.length - 1);
    } else {
      setFirstIndexMobile(firstIndexMobile - 1);
    }
  };
  const forwardIndexHandler = () => {
    if (firstIndexMobile === opinions.length - 1) {
      setFirstIndexMobile(0);
    } else {
      setFirstIndexMobile(firstIndexMobile + 1);
    }
  };
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        className={`${classes.mainGrid} ${classes.mainInstructionGrid}`}
        spacing={3}
      >
        <br />
        <Grid item sm={12} md={10} lg={8}>
          {" "}
          <Typography className={classes.mainTitle}>
            Co to jest Hear Box
          </Typography>
        </Grid>
        <br />

        <Grid
          item
          xs={12}
          md={11}
          // lg={10}
          xl={9}
          container
          spacing={ssmallScreen ? 1 : 3}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={12} sm={10} md={6}>
            <Typography className={classes.paragraph}>
              Hear Box to urządzenie pozwalające na wykonywanie badań
              przesiewowych, które sprawdzają Twój słuch i/lub umiejętności
              słuchowe.
            </Typography>
            <br />
            <Typography className={classes.paragraph}>
              To innowacyjne rozwiązanie umożliwia przeprowadzenie badań
              przesiewowych <b>samodzielnie</b> na stanowisku Hear Box,{" "}
              <b>bez konieczności wizyty w placówce medycznej.</b>
            </Typography>
            <br />
            <Typography className={classes.paragraph}>
              Dedykowane jest użytkownikom niemal w każdym wieku – dzieciom od{" "}
              <b>
                7 roku życia, młodzieży, osobom dorosłym oraz osobom w wieku
                senioralnym.
              </b>
            </Typography>
            <br />
            <Typography className={classes.paragraph}>
              Naszym celem jest ułatwienie dostępu do profilaktyki słuchu i
              zaburzeń przetwarzania słuchowego.
            </Typography>
          </Grid>
          {ssmallScreen && (
            <Grid item xs={12} sm={10} md={6}>
              <br></br>
            </Grid>
          )}
          <Grid item xs={12} sm={10} md={6}>
            <Typography className={classes.paragraph}>
              Dane Światowej Organizacji Zdrowia i krajowych instytutów
              wskazują, że w Polsce już 6% społeczeństwa ma niedosłuch. Problem
              ten dotyczy nie tylko seniorów, lecz coraz częściej młodych osób,
              w tym dzieci. Wiele osób rezygnuje z badań, a co za tym idzie
              również leczenia. To pogarsza problem i sprawia, że choroba
              postępuje.
            </Typography>
            <br />
            <Typography className={classes.paragraph}>
              W przypadku zaburzeń przetwarzania słuchowego objawy występują u
              3% do 5% uczniów pierwszych klas szkoły podstawowej. Niewykryte i
              nierehabilitowane w znacznym stopniu mogą ograniczyć możliwości
              nauki i rozwoju.
            </Typography>
            <br />
            <Typography className={classes.paragraph}>
              <b>
                Badania wykonane na stanowisku Hear Box stanowią zatem pierwszy
                krok w profilaktyce.
              </b>{" "}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {!ssmallScreen && (
        <Grid
          item
          xs={12}
          container
          className={`${classes.mainGrid}`}
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={11}
            // lg={10}
            xl={9}
          >
            {" "}
            <Typography className={classes.euTitle}>
              Projekt pt.: „Adaptacyjny system przesiewowo-diagnostyczny Hear
              Box” współfinansowany jest przez Unię Europejską ze środków
              Europejskiego Funduszu Rozwoju Regionalnego w ramach Programu
              Inteligentny Rozwój 2014-2020 w ramach konkursu Narodowego Centrum
              Badań i Rozwoju; Działanie 1.1 / Poddziałanie 1.1.1 „Badania
              przemysłowe i prace rozwojowe realizowane przez przedsiębiorstwa”.
              Projekt realizowany w konsorcjum przez Centrum Słuchu i Mowy Sp. z
              o. o. oraz Instytut Fizjologii i Patologii Słuchu na podstawie
              umowy o dofinansowanie POIR.01.01.01-00-1110/20.
            </Typography>
          </Grid>

          <Grid
            item
            md={12}
            lg={10}
            xl={8}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <img alt="Logotyp Europejskiego Funduszu Rozwoju" src={feImg} />
            <img alt="Flaga Rzeczpospolita Polska" src={rpImg} />
            <img
              alt="Logotyp Narodowego Centrum Badań i Rozwoju"
              src={ncbrImg}
            />
            <img alt="Logotyp Unii Europejskiej" src={euImg} />
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        container
        className={`${classes.mainGrid} ${classes.mainOpinionGrid}`}
        spacing={smallScreen ? 1 : 3}
      >
        {/* {!smallScreen && (
          <Grid item xs={12}>
            <br />
          </Grid>
        )} */}
        <Grid item xs={12} md={11} lg={10} xl={8}>
          <Typography className={classes.opinion}>
            Jakie badania przesiewowe możesz wykonać na stanowisku Hear Box?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <br></br>
        </Grid>
        <Grid
          item
          xs={12}
          md={11}
          // lg={10}
          xl={9}
        >
          {opinions.map((opinion, i) => (
            <Opinion key={i} opinion={opinion} />
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        className={`${classes.mainGrid} ${classes.mainFreeTestGrid}`}
        spacing={smallScreen ? 1 : 3}
      >
        <Grid
          item
          xs={12}
          md={11}
          // lg={10}
          xl={9}
          container
          spacing={1}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <br />
          <Grid
            item
            xs={12}
            sm={10}
            md={5}
            lg={4}
            style={{ textAlign: !ssmallScreen ? "left" : "center" }}
          >
            <PersonImg
              style={{
                maxWidth: smallScreen ? "229" : null,
                maxHeight: smallScreen ? "221px" : null,
              }}
            ></PersonImg>
          </Grid>
          {ssmallScreen && (
            <Grid item xs={12}>
              <br></br>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={10}
            md={7}
            lg={8}
            container
            style={{
              display: ssmallScreen ? "flex" : "inline",
              justifyContent: ssmallScreen ? "center" : null,
            }}
            // spacing={smallScreen ? 2 : 0}
          >
            <Grid item xs={10}>
              <Typography
                className={classes.mainTitle}
                style={{ textAlign: !smallScreen ? "left" : "center" }}
              >
                Badania na stanowisku Hear Box
              </Typography>
            </Grid>
            {
              <Grid item xs={12}>
                <br></br>
              </Grid>
            }
            <Grid item xs={12}>
              <Typography className={classes.freeTestText}>
                Stanowisko Hear Box wyposażone jest w dotykowy ekran i
                specjalnie zaprojektowane słuchawki.
              </Typography>
            </Grid>{" "}
            {!ssmallScreen && (
              <Grid item xs={12}>
                <br></br>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography className={classes.freeTestText}>
                Tekst na ekranie urządzenia oraz instrukcje przekazywane przez
                lektora będą prowadzić Cię krok po kroku przez kolejne badania
                przesiewowe.
              </Typography>
            </Grid>{" "}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container className={`${classes.mainGrid}`}>
        <Grid
          item
          xs={12}
          md={11}
          // lg={10}
          xl={9}
          container
          spacing={3}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <br />

          <Grid item xs={12} sm={10} md={6} container>
            {ssmallScreen && (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                style={{
                  textAlign: !ssmallScreen ? "left" : "center",
                }}
              >
                <PersonImg2
                  style={{ height: "319px", width: "319px" }}
                ></PersonImg2>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography
                className={classes.mainTitle}
                style={{ textAlign: !smallScreen ? "left" : "center" }}
              >
                Badania dla dziecka
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <br></br>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.freeTestText}>
                Urządzenie HearBox umożliwia wykonanie badań przesiewowych u
                dzieci od 7 roku życia.
              </Typography>
            </Grid>{" "}
            {!smallScreen && (
              <Grid item xs={12}>
                <br></br>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography className={classes.freeTestText}>
                Aby to zrobić zarejestruj lub zaloguj się na stronie
                internetowej hearbox.pl, a następnie stwórz profil innej osoby.
              </Typography>
            </Grid>{" "}
            {!smallScreen && (
              <Grid item xs={12}>
                <br></br>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography className={classes.freeTestText}>
                Po zakupie badania przesiewowego przypisz je osobie, która
                będzie je wykonywała na stanowisku HearBox, np. Twojemu dziecku
                lub podopiecznemu.
              </Typography>
            </Grid>{" "}
            {!smallScreen && (
              <Grid item xs={12}>
                <br></br>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography className={classes.freeTestText}>
                Jeżeli otrzymałeś voucher na badanie przesiewowe postępuj tak
                samo. Po wczytaniu kodu QR lub danych do logowania również
                przypisz badanie osobie, która będzie je wykonywała na
                stanowisku HearBox.
              </Typography>
            </Grid>{" "}
          </Grid>
          {!ssmallScreen && (
            <Grid
              item
              xs={12}
              sm={10}
              md={6}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <PersonImg2></PersonImg2>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        className={`${classes.mainGrid} ${classes.mainMovieGrid}`}
        id="how-it-works"
      >
        <Grid
          item
          xs={12}
          md={11}
          // lg={10}
          xl={9}
        >
          {" "}
          <Typography
            className={`${classes.mainTitle} ${classes.mainTitleWhite}`}
          >
            Jak to działa?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={11}
          // lg={10}
          xl={9}
          spacing={ssmallScreen ? 1 : 3}
        >
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              <StepTypography>KROK 1.</StepTypography>
            </Box>

            <Box>
              <StepDescription>
                Zaloguj się lub zarejestruj na stronie internetowej hearbox.pl
              </StepDescription>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              <StepTypography>KROK 2.</StepTypography>
            </Box>

            <Box>
              <StepDescription>
                Kup badania przesiewowe, które chcesz wykonać
              </StepDescription>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              <StepTypography>KROK 3.</StepTypography>
            </Box>

            <Box>
              <StepDescription>
                Dokonaj płatności za zakupione badania przesiewowe
              </StepDescription>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              <StepTypography>KROK 4.</StepTypography>
            </Box>

            <Box>
              <StepDescription>
                Przypisz osobę, która będzie wykonywać badania przesiewowe na
                stanowisku Hear Box
              </StepDescription>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              <StepTypography>KROK 5.</StepTypography>
            </Box>

            <Box>
              <StepDescription>
                Na podany przez Ciebie adres mailowy otrzymasz wiadomość z kodem
                QR i danymi niezbędnymi do zalogowania się na stanowisku Hear
                Box
              </StepDescription>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              <StepTypography>KROK 6.</StepTypography>
            </Box>

            <Box>
              <StepDescription>
                Znajdź na pomocą wyszukiwarki na stronie internetowej hearbox.pl
                stanowisko Hear Box w dogodnej dla Ciebie lokalizacji
              </StepDescription>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              <StepTypography>KROK 7.</StepTypography>
            </Box>

            <Box>
              <StepDescription>
                Udaj się do wybranego stanowiska Hear Box i wykonaj badania
                przesiewowe
              </StepDescription>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              <StepTypography>KROK 8.</StepTypography>
            </Box>
            <Box>
              <StepDescription>
                Wyniki badań przesiewowych sprawdź na swoim profilu na stronie
                internetowej hearbox.pl
              </StepDescription>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        item
        xs={12}
        container
        className={`${classes.mainGrid} ${classes.mainMovieGrid}`}
      >
        <Grid item sm={12} md={10} lg={4} xl={4}>
          <br />
        </Grid>
        <Grid item sm={12} md={10} lg={4} xl={4}>
          <Typography className={classes.mainTitleMovie}>
            Film instruktażowy
          </Typography>
        </Grid>

        <Grid item sm={12} md={10} lg={4} xl={4} style={{ margin: "24px 0" }}>
          <IconButton>
            <Play></Play>
          </IconButton>
        </Grid>

        <Grid item xs={12} md={10} lg={4} xl={4}>
          <Typography className={classes.movieParagraph}>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </Typography>
        </Grid>
        <Grid item sm={12} md={10} lg={4} xl={4}>
          <br />
        </Grid>
      </Grid> */}
      <Grid
        item
        xs={12}
        container
        className={`${classes.mainGrid}`}
        id="how-it-works-voucher"
      >
        <Grid
          item
          xs={12}
          md={11}
          // lg={10}
          xl={9}
        >
          {" "}
          <Typography className={classes.mainTitle}>
            Mam voucher – jak to działa?
          </Typography>
        </Grid>{" "}
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={11}
          // lg={10}
          xl={9}
          spacing={ssmallScreen ? 1 : 3}
        >
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              <StepTypography>KROK 1.</StepTypography>
            </Box>

            <Box>
              <StepDescriptionVoucher>
                Zaloguj się lub zarejestruj na stronie internetowej hearbox.pl
              </StepDescriptionVoucher>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              {" "}
              <StepTypography>KROK 2.</StepTypography>
            </Box>

            <Box>
              <StepDescriptionVoucher>
                {`Wczytaj kod QR otrzymanego vouchera (zakładka BADANIA -> KUP BADANIE; następnie w miejscu „MAM VOUCHER” wpisz numer otrzymanego vouchera)`}
              </StepDescriptionVoucher>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              {" "}
              <StepTypography>KROK 3.</StepTypography>
            </Box>

            <Box>
              <StepDescriptionVoucher>
                Przypisz osobę, która będzie wykonywać badania przesiewowe na
                stanowisku Hear Box
              </StepDescriptionVoucher>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              <StepTypography>KROK 4.</StepTypography>
            </Box>

            <Box>
              <StepDescriptionVoucher>
                Na podany przez Ciebie adres mailowy otrzymasz wiadomość z kodem
                QR i danymi niezbędnymi do zalogowania się na stanowisku Hear
                Box
              </StepDescriptionVoucher>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              <StepTypography>KROK 5.</StepTypography>
            </Box>

            <Box>
              <StepDescriptionVoucher>
                Znajdź na pomocą wyszukiwarki na stronie internetowej hearbox.pl
                stanowisko Hear Box w dogodnej dla Ciebie lokalizacji
              </StepDescriptionVoucher>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              <StepTypography>KROK 6.</StepTypography>
            </Box>

            <Box>
              <StepDescriptionVoucher>
                Udaj się do wybranego stanowiska Hear Box i wykonaj badania
                przesiewowe
              </StepDescriptionVoucher>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Box>
              {" "}
              <StepTypography>KROK 7.</StepTypography>
            </Box>

            <Box>
              <StepDescriptionVoucher>
                Wyniki badań przesiewowych sprawdź na swoim profilu na stronie
                internetowej hearbox.pl
              </StepDescriptionVoucher>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        item
        xs={12}
        container
        className={`${classes.mainGrid}`}
        spacing={3}
      >
        <Grid item sm={12} md={10} lg={4} xl={4}>
          <br />
        </Grid>

        <Grid item sm={12} md={10} lg={4} xl={4} style={{ margin: "24px 0" }}>
          <Ear></Ear>
        </Grid>
        <Grid item sm={12} md={10} lg={4} xl={4}>
          <Typography className={classes.mainTitleHearZone}>
            Strefa słuchu
          </Typography>
        </Grid>

        <Grid item xs={12} md={10} lg={6} xl={4}>
          <Typography className={classes.hearZoneParagraph}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor.
          </Typography>
        </Grid>
        <Grid item sm={12} md={10} lg={4} xl={4}>
          <br />
        </Grid>
        <Grid
          item
          container
          md={12}
          lg={10}
          xl={8}
          spacing={!smallScreen ? 3 : 1}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {hearZoneItems.map((item, index) => (
            <HearZoneElement key={index} item={item} />
          ))}
        </Grid>
        <Grid item sm={12} md={10} lg={4} xl={4}>
          <br />
        </Grid>
      </Grid> */}
      <Grid
        item
        xs={12}
        container
        className={`${classes.mainGrid} ${classes.footerGrid}`}
        spacing={3}
      >
        <Grid item container md={12} lg={10} xl={10} spacing={2}>
          <Grid
            item
            xs={12}
            md={2}
            style={{
              display: smallScreen && "flex",
              justifyContent: smallScreen && "center",
            }}
          >
            {smallScreen ? (
              <HBIcon
                style={{ marginBottom: "15px" }}
                //
                // change when original svg will be restored
                // style={{ height: "50px", cursor: "pointer" }}
              />
            ) : (
              <HBIcon style={{ height: "70px" }} />
              // change when original svg will be restored
              // <img src={hbLogo1} style={{ height: "70px" }}></img>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: smallScreen && "center",
              flexDirection: "column",
            }}
          >
            {" "}
            <Box mb={2}>
              <Typography
                className={classes.footerParagraph}
                style={{ textAlign: smallScreen && "center" }}
              >
                Kajetany 1
              </Typography>{" "}
              <Typography
                className={classes.footerParagraph}
                style={{ marginTop: "5px" }}
              >
                ul.Mokra 7, Nadarzyn
              </Typography>
            </Box>{" "}
            <Typography className={classes.footerParagraph}>
              info@hearbox.pl
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: smallScreen ? "center" : "flex-end",
              flexDirection: "column",
            }}
          >
            <Box mb={1}>
              {" "}
              <IconButton>
                <Persons></Persons>
              </IconButton>
              <IconButton>
                <Cloud></Cloud>
              </IconButton>
              <IconButton>
                <Camera></Camera>
              </IconButton>
            </Box>
            <Typography className={classes.footerParagraph}>
              © 2022 HEAR BOX. All rights reserved
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StartPageLower;
